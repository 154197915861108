import React, { useContext, useState } from "react";
import { LanguageContext } from "../../../context/LanguageContext";
import VerificarResultadoIconCinza from '../../../assets/icons/verificar-resultado-icon-cinza.svg';
import { IFormulario } from "../interfaces/IFormulario";
import { formatarDataEHoraDoBanco } from "../../../utils/formatarDataEHoraDoBanco";
import { Link } from "react-router-dom";
import { Tooltip } from 'react-tippy';
import ConteudoDoTooltipDeInformacaoDaEmpresa from "./ConteudoDoTooltipDeInformacaoDaEmpresa";
import MenuArrowIcon from "../../../assets/icons/menu-arrow.svg";
import NameAndCompanySection from "./NameAndCompanySection";
import SectionForDateAndButtons from "./SectionForDateAndButtons";
import HolderButtons from "./HolderButtons";

interface IProps {
    formulario: IFormulario;
    id: string;
}

const TAMANHO_DO_ICONE = 1.5;
const ESPACO_PARA_ICONES = `${TAMANHO_DO_ICONE * 2 + 1}rem`;
const TAMANHO_DO_ESPACO_DE_TEMPO_E_BOTOES = '32.5rem';
const ESTILO_TEXTO_SEM_QUEBRAR_LINHA: React.CSSProperties = { textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' };
const ESTILO_TEXTO_QUEBRANDO_LINHA: React.CSSProperties = { whiteSpace: 'normal', wordBreak: 'break-word' };

const ItemDeFormulario = ({ formulario, id }: IProps) => {
    const [exibirConteudoCompleto, setExibirConteudoCompleto] = useState(false);
    const [mouseEntered, setMouseEntered] = useState(false);
    const { getLanguageResource } = useContext(LanguageContext);
    const oForlularioFoiCompartilhado = formulario.compartilhado;
    const oFormularioNaoFoiIniciado = formulario.perguntasRespondidas === 0 && formulario.totalDePerguntas !== 0;
    const oFormularioFoiIniciado = formulario.perguntasRespondidas > 0 && formulario.perguntasRespondidas < formulario.totalDePerguntas;
    const oFormularioTeveTodasAsPerguntasRespondidas = formulario.perguntasRespondidas === formulario.totalDePerguntas && formulario.totalDePerguntas !== 0;
    const oFormularioPossuiAcaoPendente = oFormularioTeveTodasAsPerguntasRespondidas && formulario.possuiAcaoPendente;
    const oFormularioTeveTodasAsPerguntasEAcoesRespondidas = oFormularioTeveTodasAsPerguntasRespondidas && !formulario.possuiAcaoPendente;

    return (
        <div
        className='d-flex flex-column border rounded p-2 px-1 mt-1 d-flex' id={id}
        >
            <div className="progress mb-1 d-md-none">
                <div className="progress-bar" role="progressbar" style={{ width: `${formulario.porcentagemRespondido}%` }} aria-valuenow={formulario.porcentagemRespondido} aria-valuemin="0" aria-valuemax="100">{formulario.porcentagemRespondido}%</div>
            </div>
            <div className="d-flex w-100" onClick={() => setExibirConteudoCompleto(!exibirConteudoCompleto)}>
                <div className='d-none d-md-flex justify-content-center mr-3' style={{ minWidth: ESPACO_PARA_ICONES }}>
                    {
                        oFormularioNaoFoiIniciado && <img style={{ width: '2rem' }} src={process.env.PUBLIC_URL + '/images/icons/nao-iniciados.svg'} alt="Não iniciados" />
                    }
                    {
                        oFormularioFoiIniciado && <img style={{ width: '2rem' }} src={process.env.PUBLIC_URL + '/images/icons/em-andamento.svg'} alt="Em andamento" />
                    }
                    {
                        oFormularioTeveTodasAsPerguntasEAcoesRespondidas && <img style={{ width: '2rem' }} src={process.env.PUBLIC_URL + '/images/icons/aguardando-finalizar.svg'} alt="Aguardando finalizar" />
                    }
                    {
                        oForlularioFoiCompartilhado && <img style={{ width: '2rem' }} src={process.env.PUBLIC_URL + '/images/icons/compartilhados.svg'} alt="Compartilhados" />
                    }
                    {
                        oFormularioPossuiAcaoPendente && <img style={{ width: '2rem' }} src={process.env.PUBLIC_URL + '/images/icons/acoes-pendentes.svg'} alt="Ações pendentes" />
                    }
                </div>
                <HolderButtons>
                    {
                        formulario.perguntasRespondidas > 0 && formulario.podeVisualizarResultadoParcial && (
                            <button
                                style={{ height: '2.75rem' }}
                                className="btn btn-outline-secondary mr-2 pull-right d-block"
                                title='Resultado Parcial'
                                onClick={() => window.location.href = `/formulario/respondido/${formulario.hash}`}
                            >
                                <img style={{ width: '1.25rem', height: '1.25rem' }} src={VerificarResultadoIconCinza} alt='Resultado Parcial' />
                            </button>
                        )
                    }
                    {
                        formulario.podeResponder && (
                            <button
                                style={{ height: '2.75rem' }}
                                className="btn btn-outline-info mr-2 pull-right d-block"
                                onClick={() => window.location.href = `/formulario/responder/${formulario.formularioId}/${formulario.hash}`}
                            >
                                <i className="bi bi-play-fill" />
                            </button>
                        )
                    }
                </HolderButtons>

                <div className="d-flex flex-column flex-grow-1" style={{ minWidth: 0 }}>
                    <small style={exibirConteudoCompleto ? ESTILO_TEXTO_QUEBRANDO_LINHA : ESTILO_TEXTO_SEM_QUEBRAR_LINHA}>
                        <Tooltip
                            html={
                                <ConteudoDoTooltipDeInformacaoDaEmpresa
                                    dadosDaEmpresa={{
                                        nomeFantasia: formulario.nomeDaEmpresa,
                                        cnpj: formulario.cnpj,
                                        razaoSocial: formulario.razaoSocial,
                                    }}
                                />
                            }
                            arrow
                            interactive
                            delay={500}
                            position="top"
                        >
                            <span style={{ cursor: 'pointer' }}>{formulario.nomeDaEmpresa} </span>
                        </Tooltip>
                        | <Link className="text-dark" to={`/marketPlace/detail/${formulario.formularioId}`}>{formulario.nomeDoFormulario}</Link>
                    </small>
                    <span style={exibirConteudoCompleto ? ESTILO_TEXTO_QUEBRANDO_LINHA : ESTILO_TEXTO_SEM_QUEBRAR_LINHA} title={formulario.nomeDoFormularioIniciado}>{formulario.nomeDoFormularioIniciado}</span>
                </div>
                
                <div style={{ flexShrink: 0 }} className="d-flex ml-2">
                    <div className="d-md-flex justify-content-center align-items-center flex-nowrap text-nowrap d-none" style={{ width: '16rem' }}>
                        <span>{formatarDataEHoraDoBanco(formulario.ultimaModificacao, 'DH')}</span>
                    </div>

                    <div className="d-md-flex d-none flex-column h-100 align-items-center justify-content-start" style={{ minWidth: '4rem' }}>
                        <strong>{formulario.porcentagemRespondido}%</strong>
                        <small>{ getLanguageResource("answered") }</small>
                    </div>
                </div>

                <div className="d-md-flex d-none ml-2" style={{ minWidth: '6.5rem' }}>
                    {
                        formulario.perguntasRespondidas > 0 && formulario.podeVisualizarResultadoParcial && (
                            <button
                                style={{ height: '2.75rem' }}
                                className="btn btn-outline-secondary mr-2 pull-right d-block"
                                title='Resultado Parcial'
                                onClick={() => window.location.href = `/formulario/respondido/${formulario.hash}`}
                            >
                                <img style={{ width: '1.25rem', height: '1.25rem' }} src={VerificarResultadoIconCinza} alt='Resultado Parcial' />
                            </button>
                        )
                    }
                    {
                        formulario.podeResponder && (
                            <button
                                style={{ height: '2.75rem' }}
                                className="btn btn-outline-info pull-right d-block"
                                onClick={() => window.location.href = `/formulario/responder/${formulario.formularioId}/${formulario.hash}`}
                            >
                                <i className="bi bi-play-fill" />
                            </button>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default ItemDeFormulario;