import * as React from 'react';
import { ResponderFormularioContext } from '../../contexts/ResponderFormularioContext';
import { criarArrayDePonteirosDasPerguntas } from '../../utils/geradoresDeArrayDePonteiros';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';

const { useState, useContext, useEffect } = React;

interface IProps {
    idDaPergunta: number;
    permitirEditar: boolean;
}

const GrupoDeBotoesRadio = ({ idDaPergunta, permitirEditar }: IProps) => {
    const { responderFormulario, setResponderFormulario, ultimaTentativaDeFinalizar } = useContext(ResponderFormularioContext);
    const [classesDeFeedbackDePreenchimento, setClassesDeFeedbackDePreenchimento] = useState({
        outline: 'btn-outline-primary botao-de-resposta-desmarcado',
        solid: 'btn-primary botao-de-resposta-marcado',
    });

    const responderFormularioAtualizado = { ...responderFormulario };
    const ponteirosDasPerguntasDeResponderFormulario = criarArrayDePonteirosDasPerguntas(responderFormularioAtualizado);
    const ponteiroDaPergunta = ponteirosDasPerguntasDeResponderFormulario.find((pergunta) => pergunta.id === idDaPergunta);
    const respostasPossiveis = ponteiroDaPergunta ? ponteiroDaPergunta.respostas : [];

    const handleChange = (event) => {
        if(!permitirEditar) return;

        const respostasAtualizadas = respostasPossiveis.map((resposta) => {

            // remover a resposta caso clique em uma resposta já selecionada
            if (resposta.respostaFoiSelecionada && `${idDaPergunta}-${resposta.id}` === event.target.id && ponteiroDaPergunta) {
                resposta.respostaFoiSelecionada = false;
                ponteiroDaPergunta.respostaSelecionada = null;
                ponteiroDaPergunta.respostaSelecionadaId = 0;
                return resposta;
            } else {
                resposta.respostaFoiSelecionada = false;
            }

            // preencher a resposta caso clique em uma resposta não selecionada
            if (`${idDaPergunta}-${resposta.id}` === event.target.id && ponteiroDaPergunta) {
                resposta.respostaFoiSelecionada = true;
                ponteiroDaPergunta.respostaSelecionada = event.target.value;
                ponteiroDaPergunta.respostaSelecionadaId = resposta.id;
            } else {
                resposta.respostaFoiSelecionada = false;
            }

            return resposta;
        });

        if (ponteiroDaPergunta) ponteiroDaPergunta.respostas = respostasAtualizadas;
        setClassesDeFeedbackDePreenchimento({
            outline: 'btn-outline-primary botao-de-resposta-desmarcado',
            solid: 'btn-primary botao-de-resposta-marcado',
        });
        setResponderFormulario(responderFormularioAtualizado);
    }

    useDidUpdateEffect(() => {
        const respostaSelecionada = respostasPossiveis.find((resposta) => resposta.respostaFoiSelecionada);
        if (!respostaSelecionada) {
            setClassesDeFeedbackDePreenchimento({
                outline: 'btn-outline-danger botao-de-resposta-desmarcado',
                solid: 'btn-danger botao-de-resposta-marcado',
            });
        }
    }, [ultimaTentativaDeFinalizar]);

    useEffect(() => {
        const respostaSelecionada = respostasPossiveis.find((resposta) => resposta.respostaFoiSelecionada);
        if (ponteiroDaPergunta && respostaSelecionada) {
            ponteiroDaPergunta.respostaSelecionadaId = respostaSelecionada.id;
        }
    }, [])

    return (
        <>
            <div
                className='d-md-flex d-none btn-group btn-group-toggle w-100 grupo-de-respostas'
                role='group'
                aria-label={`arialabel-${idDaPergunta}`}
                id={`respostas-da-pergunta-${idDaPergunta}`}
            >
                {
                    respostasPossiveis.map((resposta) => (
                        <React.Fragment>
                            <label
                                className={`btn ${resposta.respostaFoiSelecionada ? classesDeFeedbackDePreenchimento.solid : classesDeFeedbackDePreenchimento.outline} ${!permitirEditar ? 'disabled' : ''}`}
                                htmlFor={`${idDaPergunta}-${resposta.id}`}
                                key={`${idDaPergunta}-${resposta.id}`}
                            >
                                <input
                                    type="radio"
                                    className="btn-check"
                                    name={`${idDaPergunta}`}
                                    id={`${idDaPergunta}-${resposta.id}`}
                                    checked={resposta.respostaFoiSelecionada}
                                    autoComplete='off'
                                    value={resposta.nomeDaResposta}
                                    onClick={handleChange}
                                    disabled={!permitirEditar}
                                />
                                {resposta.nomeDaResposta}
                            </label>
                        </React.Fragment>
                    ))
                }
            </div>
            <div
                className='d-flex d-md-none btn-group-vertical btn-group-toggle w-100 grupo-de-respostas'
                role='group'
                aria-label={`arialabel-${idDaPergunta}`}
                id={`respostas-da-pergunta-${idDaPergunta}`}
            >
                {
                    respostasPossiveis.map((resposta) => (
                        <React.Fragment>
                            <label
                                className={`btn ${resposta.respostaFoiSelecionada ? classesDeFeedbackDePreenchimento.solid : classesDeFeedbackDePreenchimento.outline} ${!permitirEditar ? 'disabled' : ''}`}
                                htmlFor={`${idDaPergunta}-${resposta.id}`}
                                key={`${idDaPergunta}-${resposta.id}`}
                            >
                                <input
                                    type="radio"
                                    className="btn-check"
                                    name={`${idDaPergunta}`}
                                    id={`${idDaPergunta}-${resposta.id}`}
                                    checked={resposta.respostaFoiSelecionada}
                                    autoComplete='off'
                                    value={resposta.nomeDaResposta}
                                    onClick={handleChange}
                                    disabled={!permitirEditar}
                                />
                                {resposta.nomeDaResposta}
                            </label>
                        </React.Fragment>
                    ))
                }
            </div>
        </>
    )
}

export default GrupoDeBotoesRadio;
