import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { Layout } from '../../Layout';
import { HttpService } from '../../Shared/HttpService';
import { Loading } from '../../Shared/Loading';
import { AddResposta } from './AddResposta';
import { ModalDelete } from '../../Shared/ModalDelete';
import { Resource } from '../../../index';
import ModalWinSet from '../../Shared/components/ModalWinSet';

interface FetchRespostaState {
    respostaList: RespostaData[];
    loading: boolean;
    gruporespostaid: number;
    modalDeDeletarEstaAberto: boolean;
    itemSelecionadoParaDeletar: RespostaData | null;
}

export class RespostaData {
    id: number = 0;
    reactid: number = 0;
    ko: boolean = false;
    permitirobservacaoobrigatoria: boolean = false;
    permitiranexarevidenciaobrigatoria: boolean = false;
    permitirplanoacaoobrigatoria: boolean = false;
    permitiranexarfotoappobrigatoria: boolean = false;
    empresasid: number = 0;
    nome: string = "";
    valor?: number;
    valorporcentagem: number = 0;
    ordenacao: number = 0;
    gruporespostaid: number = 0;
    error: Array<any> = [];
}

export class FetchResposta extends React.Component<any, FetchRespostaState> {
    constructor(props) {
        super(props);
        
        this.state = {
            respostaList: [],
            loading: true,
            gruporespostaid: props.gruporespostaid,
            modalDeDeletarEstaAberto: false,
            itemSelecionadoParaDeletar: null,
        };

        this.updateRespostas();

        this.handleDelete = this.handleDelete.bind(this);
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;
        return (
            <React.Fragment>
                {
                    this.props.modalAdicionarRespostaEstaAberto && (
                        <ModalWinSet
                            fecharModal={() => this.props.handleModalAdicionarRespostaState(false)}
                        >
                            <AddResposta key="addResposta" fecharModal={() => this.props.handleModalAdicionarRespostaState(false)} gruporespostaid={this.state.gruporespostaid} id="addResposta" callBack={this} />
                        </ModalWinSet>
                    )
                }
                {this.renderUserTable(this.state.respostaList)}
            </React.Fragment>
        );
    }

    public updateRespostas() {
        Loading.showLoading(this);
        HttpService.fetch('api/RespostaApi/All/' + this.state.gruporespostaid, {})
            .then(response => response.json() as Promise<RespostaData[]>)
            .then(data => {
                this.setState({ respostaList: data, loading: false });
                Loading.hideLoading(this);
            });
    }

    private handleDelete(id: number) {
            var url = `api/RespostaApi/${id}`;
            Loading.showLoading(this);
            HttpService.fetch(url, { method: 'DELETE' })
                .then(response => response.json() as Promise<boolean>)
                .then(data => {
                    Loading.hideLoading(this);
                    this.updateRespostas();
                }).catch(() => {
                    Loading.hideLoading(this);
                });
    }

    // Returns the HTML table to the render() method.  
    private renderUserTable(respostaList: RespostaData[]) {
        return <table className='table  table-hover'>
            <thead>
                <tr>
                    <th>{<Resource keyString="reply" />}</th>
                    <th>{<Resource keyString="points" />}</th>
                    <th>{<Resource keyString="ordination" />}</th>
                    <th>{<Resource keyString="percentage" />} (%)</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {respostaList.map(doc =>
                    <tr key={doc.id}>
                        <td className='align-middle'>{doc.nome}</td>
                        <td className='align-middle'>{doc.valor != null ? doc.valor.toString() : "Sem Valor"}</td>
                        <td className='align-middle'>{doc.ordenacao}</td>
                        <td className='align-middle'>{doc.valorporcentagem.toString()}</td>
                        <td className='align-middle'>
                        <button
                                className="btn btn-outline-danger mr-2 pull-right"
                                onClick={() => this.setState({ modalDeDeletarEstaAberto: true, itemSelecionadoParaDeletar: doc })}
                                style={{ width: '2.75rem', height: '2.75rem' }}
                            >
                                <i className='fa fa-trash'></i>
                            </button>
                            {
                                (this.state.modalDeDeletarEstaAberto && this.state.itemSelecionadoParaDeletar && (this.state.itemSelecionadoParaDeletar.id === doc.id)) && (
                                    <ModalWinSet
                                        fecharModal={() => this.setState({ modalDeDeletarEstaAberto: false, itemSelecionadoParaDeletar: null })}
                                    >
                                        <div className='p-3'>
                                            <header className='d-flex w-100 mb-2 justify-content-center'>
                                                <h4><Resource keyString="do_you_want_to_remove_the_group" />?</h4>
                                            </header>
                                            <hr />
                                            <main>
                                                <p>{doc.nome}</p>
                                            </main>
                                            <footer className='d-flex w-100 justify-content-end'>
                                                <button className="btn mr-2 ws-outline-blue" onClick={() => this.handleDelete(doc.id)}><Resource keyString="yes" /></button>
                                                <button className="btn btn-outline-danger" onClick={() => this.setState({ modalDeDeletarEstaAberto: false, itemSelecionadoParaDeletar: null })}><Resource keyString="no" /></button>
                                            </footer>
                                        </div>
                                    </ModalWinSet>
                                    
                                )
                            }
                        </td>
                    </tr>
                )}
            </tbody>
        </table>;
    }
}   