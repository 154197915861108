import React, { useState, useContext } from 'react';
import CardDeResultado from '../CardDeResultado';
import ContainerDeGruposDePerguntas from '../ContainerDeGruposDePerguntas';
import HeaderDeGrupo from '../HeaderDeGrupo';
import { FormularioRespondidoContext } from '../../../../../context/FormularioRespondidoContext';
import useDidUpdateEffect from '../../../../../hooks/useDidUpdateEffect';

const CollapseDeGrupoDePerguntas = ({ grupo, resultadoDoFormulario }) => {
	const { mostrarTodosOsCollapses } = useContext(FormularioRespondidoContext);
    const [isOpened, setIsOpened] = useState(false);

    useDidUpdateEffect(() => setIsOpened(mostrarTodosOsCollapses), [mostrarTodosOsCollapses])

    return (
        <div className='mb-2 mt-2'>
            <HeaderDeGrupo grupo={grupo} setIsOpened={setIsOpened} isOpened={isOpened} />
            <div className='ml-4' style={{ maxHeight: isOpened ? '5000rem' : '0rem', overflow: 'hidden' }} >
                {
                    grupo.listResultadoFormulario.map(resultado => (
                        <CardDeResultado
                            key={`card_de_resultado_${resultado.id}`}
                            obj={resultado}
                            resultadoFormulario={resultadoDoFormulario}
                        />
                    ))
                }
                <ContainerDeGruposDePerguntas listaDeGruposDePerguntas={grupo.listaDeGrupoDePerguntaFilho} resultadoDoFormulario={resultadoDoFormulario} />
            </div>
        </div>
    );
};

export default CollapseDeGrupoDePerguntas;
