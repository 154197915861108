import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { DocumentoData } from './FetchDocumentos';
import { HttpService } from '../Shared/HttpService';
import { Loading } from '../Shared/Loading';
import $ from 'jquery';
import { ValidationLabelMessage } from '../Shared/ValidationMessage';
import { Resource } from '../../index';
import { LanguageContext } from '../../context/LanguageContext';
import CaixaDeConteudo from '../Shared/CaixaDeConteudo';
import { DiretorioData } from '../Diretorios/FetchDiretorio';
import { SelectDeDiretorio } from '../Shared/components/SelectDeDiretorio';

interface AddDocumentoDataState {
    loading: boolean;
    empData: DocumentoData;
    diretorios: DiretorioData[];
}

export class AddDocumentos extends React.Component<RouteComponentProps<{}>, AddDocumentoDataState> {
    static contextType = LanguageContext;
    private _pathname;
    private _regexPaginaDeEdicao;
    private _estouNaPaginaDeEdicao;
    constructor(props) {
        super(props);
        this._pathname = window.location.pathname;
        this._regexPaginaDeEdicao = new RegExp(/documento\/edit/);
        this._estouNaPaginaDeEdicao = this._regexPaginaDeEdicao.test(this._pathname);

        var documentoid = this.props.match.params["documentoid"];

        var documento = new DocumentoData;
        documento.diretoriosid = this.props.match.params["repositorioid"] > 0 ? this.props.match.params["repositorioid"] : 0;

        this.state = {
            loading: true,
            empData: documento,
            diretorios: [],
        };

        // This will set state for Edit User  
        if (documentoid > 0) {

            Loading.showLoading(this);

            HttpService.fetch('api/Documentos/' + documentoid, { method: 'GET' })
                .then(response => response.json() as Promise<DocumentoData>)
                .then(data => {
                    this.setState({ loading: false, empData: data });
                    Loading.hideLoading(this);
                }).catch(() => {
                    Loading.hideLoading(this);
                    props.history.push(`/repositorio`);
                });
        }

        // This will set state for Add User  
        else {
            this.state = { loading: false, empData: documento, diretorios: [] };
        }


        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    private botoesDoCantoSuperiorDireito() {
        return (
            <React.Fragment>
                <button className="btn bg-white shadow-sm mr-2" onClick={this.handleCancel}>{<Resource keyString="back" />}</button>
            </React.Fragment>
        );
    }

    componentDidMount(): void {
        Loading.showLoading(this);
        HttpService.fetch('api/Repositorio/TodosOsDiretorios', { method: 'GET' })
            .then(response => response.json() as Promise<any[]>)
            .then(data => {
                this.setState({ loading: false, diretorios: data });
                Loading.hideLoading(this);
            });
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;

        return (
            <CaixaDeConteudo
                tituloDoHeader={this._estouNaPaginaDeEdicao ? this.context.getLanguageResource("edit_document") : this.context.getLanguageResource("create_document")}
                botoes={this.botoesDoCantoSuperiorDireito()}
                itensParaBreadcrumb={[
                    { nome: this._estouNaPaginaDeEdicao ? this.context.getLanguageResource("edit_document") : this.context.getLanguageResource("create_document") }
                ]}
                breadcrumbDinamico={{ id: this._estouNaPaginaDeEdicao ? this.state.empData.id : this.state.empData.diretoriosid, tipo: "documento" }}
            >
                <main className='p-4'>
                    {this.renderCreateForm()}
                </main>
            </CaixaDeConteudo>
        );
    }

    // This will handle the submit form event.  
    private handleSave(event) {
        event.preventDefault();

        let documentoId = this.state.empData.id;
        const payload = HttpService.formatData(event.target) as DocumentoData;
        payload.isactive = this.state.empData.isactive;

        Loading.showLoading(this);
        // PUT request for Edit User.  
        if (documentoId) {

            HttpService.fetch('api/Documentos/' + documentoId, {
                method: 'PUT',
                body: JSON.stringify(payload),
            }).then((response) => {
                return response.json() as Promise<DocumentoData>
            }).then((data) => {

                if ($.isEmptyObject(data.error)) {
                    this.redirectPage();
                }
                
                if (data.id === 0) {
                    this.setState({ empData: { ...this.state.empData, error: data.error } });
                    Loading.hideLoading(this);
                    return;
                }
                
                this.setState({ empData: data })
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            })

        } else {

            HttpService.fetch('api/Documentos', {
                method: 'POST',
                body: JSON.stringify(payload),
            }).then((response) => {
                return response.json() as Promise<DocumentoData>
            }).then((data) => {

                this.setState({ empData: data })
                if ($.isEmptyObject(data.error)) {
                    this.redirectPage();
                }
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            })
        }
    }

    // This will handle Cancel button click event.  
    private handleCancel(e) {
        e.preventDefault();
        this.redirectPage();
    }

    private redirectPage() {
        if (this.state.empData.id > 0)
            this.props.history.push(`/documentos/detail/${this.state.empData.id}`);
        else
            this.props.history.push(`/repositorio/${this.state.empData.diretoriosid}`);
    }

    handleSelectChange = (event) => {
        this.setState({ empData: { ...this.state.empData, diretoriosid: event.target.value } });
    }

    // Returns the HTML Form to the render() method.  
    private renderCreateForm() {
        return (<form onSubmit={this.handleSave} >
            <div className="form-group row" >
                <input type="hidden" name="userId" value={this.state.empData.id} />
                <input type="hidden" name="isactive" />
            </div>
            <div className="form-group row">
                <div className="col-md-6">
                    <label className=" control-label col-md-12 required" htmlFor="Name">{<Resource keyString="document_name" />}</label>
                    <div className="col-md-12">
                        <ValidationLabelMessage errors={this.state.empData.error} name="Nome" >
                            <input className="form-control" type="text" name="Nome" defaultValue={this.state.empData.nome} />
                        </ValidationLabelMessage>
                    </div>
                </div >
                <div className="col-md-6">
                    <label className=" control-label col-md-12 required" htmlFor="Name">{<Resource keyString="description_name" />}</label>
                    <div className="col-md-12">
                        <ValidationLabelMessage errors={this.state.empData.error} name="Descricao" >
                            <input className="form-control" type="text" name="Descricao" defaultValue={this.state.empData.descricao} />
                        </ValidationLabelMessage>
                    </div>
                </div >
            </div >
            <div className="form-group row">
                <div className="col-md-6">
                    <label className=" control-label col-md-12" htmlFor="Tags">{<Resource keyString="tags_keyword" />}</label>
                    <div className="col-md-12">
                        <ValidationLabelMessage errors={this.state.empData.error} name="Tags" >
                            <input className="form-control" type="text" name="Tags" defaultValue={this.state.empData.tags} />
                        </ValidationLabelMessage>
                    </div>
                </div >
            </div >
            <div className="form-group row">
                <div className="col-md-6">
                    <label className=" control-label col-md-12" htmlFor="Diretoriospaiid">{<Resource keyString="directory" />}</label>
                    <div className="col-md-12">
                        <SelectDeDiretorio
                            diretorios={this.state.diretorios}
                            diretorioSelecionado={this.state.empData.diretoriosid}
                            name={"diretoriosid"}
                        />
                    </div>
                </div>
            </div>
            <div className="form-group col-md-12 d-flex justify-content-center">
                <button type="submit" className="btn font-weight-bold" style={{ backgroundColor: 'var(--ws-blue)', width: '17.5rem' }}>{<Resource keyString="save" />}</button>
            </div>
        </form >)
    }
}
