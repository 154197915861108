import React, { useState, useEffect, useContext } from 'react';
import { GlobalContext } from '../../context/GlobalContext';

export enum Plano {
    Free = 0,
    Premium = 1,
}

interface IProps {
    plano: Plano;
    children: React.ReactNode;
}

const PlanoAuth = ({ plano, children }: IProps) => {
    const { tipodeconta, setTipodeconta, verificarTipoDeConta, deveDeslogar } = useContext(GlobalContext);

    useEffect(() => {
        verificarTipoDeConta(plano);
    }, [tipodeconta]);

    deveDeslogar();

    if (tipodeconta === 99999) return <React.Fragment />;
    if (tipodeconta !== plano) return <React.Fragment />;

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
};

export default PlanoAuth;
