import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { Layout } from '../../Layout';
import { HttpService } from '../../Shared/HttpService';
import { Loading } from '../../Shared/Loading';
import { ModalDelete } from '../../Shared/ModalDelete';
import { FormatoPerguntaData } from '../FormatoPergunta/FetchFormatoPergunta';
import { GrupoRespostaData } from '../GrupoResposta/FetchGrupoResposta';
import { VisualizacaoPerguntaCadastro } from './VisualizacaoPerguntaCadastro';
import { RespostaData } from '../Resposta/FetchResposta';
import { Resource } from '../../../index';
import { LanguageContext } from '../../../context/LanguageContext';

interface FetchPerguntaState {
    PerguntaList: PerguntaData[];
    formularioid: number;
    grupoperguntaid: number;
    loading: boolean;
}

export class PerguntaData {
    id: number = 0;
    formatoperguntaid: number = 0;
    gruporespostaid: number = 0;
    formularioid: number = 0;
    grupoperguntaid: number = 0;
    ordenacao: number = 0;
    titulo: string = "";
    descricao: string = "";
    ajuda: string = "";
    formatoPergunta: FormatoPerguntaData = new FormatoPerguntaData;
    //grupoResposta: GrupoRespostaData = new GrupoRespostaData;
    respostas: Array<RespostaData> = [];
    permitirobservacao: boolean = false;
    //permitirobservacaoobrigatoria: boolean = false;
    permitiranotacao: boolean = false;
    permitiranexarevidencia: boolean = false;
    //permitiranexarevidenciaobrigatoria: boolean = true;
    permitircriaracao: boolean = false;
    permitiranexarfotoapp: boolean = false;
    modeloPlanoAcaoId: number = 0;
    error: Array<any> = [];
}

export class FetchPergunta extends React.Component<any, FetchPerguntaState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        this.state = {
            PerguntaList: props.pergunta, loading: false,
            formularioid: props.formularioId, grupoperguntaid: props.grupoRespostaId
        };

        /*Loading.showLoading(this);;
        HttpService.fetch(`api/PerguntaApi/All/${this.state.formularioid}/${this.state.grupoperguntaid}`, {})
            .then(response => response.json() as Promise<PerguntaData[]>)
            .then(data => {
                this.setState({ PerguntaList: data, loading: false });
                Loading.hideLoading(this);
            });*/

        this.handleDelete = this.handleDelete.bind(this);

    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;
        return this.renderUserTable(this.state.PerguntaList);
    }

    // Handle Delete request for an user  
    private handleDelete(id: number) {
        Loading.showLoading(this);;
        HttpService.fetch('api/PerguntaApi/' + id, {
            method: 'delete'
        }).then(data => {
            this.setState(
                {
                    PerguntaList: this.state.PerguntaList.filter((rec) => {
                        return (rec.id != id);
                    })
                });
            Loading.hideLoading(this);
        }).catch(() => {
            Loading.hideLoading(this);
        });
    }

    // Returns the HTML table to the render() method.  
    private renderUserTable(PerguntaList: PerguntaData[]) {
        return <React.Fragment>
            {PerguntaList.map(doc =>
                <div className="bg-light border-top p-3" key={doc.id}>
                        <VisualizacaoPerguntaCadastro pergunta={doc} />
                        <div className='w-100 d-flex justify-content-end'>
                            <button className="btn ws-outline-green-brown mr-2" style={{ width: '2.5rem', height: '2.5rem' }} onClick={() => window.location.href = `/Pergunta/edit/${doc.formularioid}/${doc.grupoperguntaid}/${doc.id}`}>
                                <i className='fa fa-edit'></i>
                            </button>
                            <button className="btn btn-outline-danger" style={{ width: '2.5rem', height: '2.5rem' }} data-toggle="modal" data-target={`#delete${doc.id}`}>
                                <i className='fa fa-trash'></i>
                            </button>
                        </div>
                    <ModalDelete id={doc.id} callBack={this} message={`${this.context.getLanguageResource("do_you_want_to_remove_the_question")}: ${doc.titulo}?`} />
                </div>
            )}
        </React.Fragment>;
    }
}   