import React, { useEffect, useState } from "react";
import MenuArrowIcon from "../../../assets/icons/menu-arrow.svg";

interface IProps {
    children?: React.ReactNode;
    icone?: string;
    texto?: string;
    aBarraLateralEstaAberta: boolean;
    alterarEstadoDaBarraLateralPara: (estado: boolean) => void;
}

const GrupoDeLinksDeNavegacao = ({ children, icone, texto, aBarraLateralEstaAberta, alterarEstadoDaBarraLateralPara }: IProps) => {
    const [isOpened, setIsOpened] = useState(false);
    const [mouseEntered, setMouseEntered] = useState(false);

    useEffect(() => {
        if (!aBarraLateralEstaAberta) setIsOpened(false);
    }, [aBarraLateralEstaAberta]);

    return (
        <div
            className='w-100'
            style={{ listStyleType: "none", cursor: "pointer" }}
            onClick={() => {
                setIsOpened(!isOpened);
                alterarEstadoDaBarraLateralPara(true);
            }}
            >
            <div
                className={`
                    d-flex
                    align-items-center
                    justify-content-${aBarraLateralEstaAberta ? 'between' : 'center'}
                    w-100
                    p-2
                `}
                style={{ backgroundColor: (mouseEntered && aBarraLateralEstaAberta) ? "var(--ws-blue-highlight-menu)" : "" }}
                onMouseEnter={() => setMouseEntered(true)}
                onMouseLeave={() => setMouseEntered(false)}
            >
                <div className='d-flex align-items-center' style={{ gap: "14px" }}>
                    {
                        icone && (
                            <div
                                className={`
                                    d-flex
                                    align-items-center
                                    justify-content-center
                                    p-1
                                    ${aBarraLateralEstaAberta ? '' : 'bg-white rounded shadow'}
                                `}
                                style={{ height: "2.125rem", width: "2.125rem" }}
                            >
                                {
                                    icone && <img src={icone} alt={`Ícone ${texto}`} />
                                }
                            </div>
                        )
                    }
                    {
                        aBarraLateralEstaAberta && <span className='text-dark'>{ texto }</span>
                    }
                </div>
                {
                    aBarraLateralEstaAberta && (
                        <div>
                            <img src={MenuArrowIcon} alt="Seta do collapse" className='px-2' style={{ transform: (isOpened && aBarraLateralEstaAberta) ? 'rotate(180deg)' : '', transition: 'transform 550ms ease' }} />
                        </div>
                    )
                }
            </div>
            { (isOpened && aBarraLateralEstaAberta) && (
                <React.Fragment>
                    <div
                        className="d-flex flex-column ml-3"
                    >
                        { children }
                    </div>
                    <React.Fragment>
                        {
                            aBarraLateralEstaAberta && <hr className="border justify-self-center" />
                        }
                    </React.Fragment>
                </React.Fragment>
            )}
        </div>
    );
};

export default GrupoDeLinksDeNavegacao;