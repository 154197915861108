import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { GrupoRespostaData } from './FetchGrupoResposta';
import { HttpService } from '../../Shared/HttpService';
import { Loading } from '../../Shared/Loading';
import $ from 'jquery';
import { ValidationLabelMessage, ValidationAlertMessage } from '../../Shared/ValidationMessage';
import { FetchResposta } from '../Resposta/FetchResposta';
import { Resource } from '../../../index';
import { LanguageContext } from '../../../context/LanguageContext';
import CaixaDeConteudo from '../../Shared/CaixaDeConteudo';

interface AddGrupoRespostaDataState {
    loading: boolean;
    grupoResposta: GrupoRespostaData;
    modalAdicionarRespostaEstaAberto: boolean;
}

export class AddGrupoResposta extends React.Component<RouteComponentProps<{}>, AddGrupoRespostaDataState> {
    static contextType = LanguageContext;
    private _pathname;
    private _regexPaginaDeEdicao;
    private _estouNaPaginaDeEdicao;
    constructor(props) {
        super(props);
        this._pathname = window.location.pathname;
        this._regexPaginaDeEdicao = new RegExp(/grupoResposta\/edit/);
        this._estouNaPaginaDeEdicao = this._regexPaginaDeEdicao.test(this._pathname);

        var grupoRespostaid = this.props.match.params["grupoRespostaid"];

        var grupoResposta = new GrupoRespostaData;

        this.state = { loading: true, grupoResposta: grupoResposta, modalAdicionarRespostaEstaAberto: false };

        // This will set state for Edit User  
        if (grupoRespostaid > 0) {

            Loading.showLoading(this);

            HttpService.fetch('api/GrupoRespostaAPI/' + grupoRespostaid, { method: 'GET' })
                .then(response => response.json() as Promise<GrupoRespostaData>)
                .then(data => {
                    this.setState({ loading: false, grupoResposta: data });
                    Loading.hideLoading(this);
                }).catch(() => {
                    Loading.hideLoading(this);
                    props.history.push(`/repositorio`);
                });
        }

        // This will set state for Add User  
        else {
            this.state = { loading: false, grupoResposta: grupoResposta, modalAdicionarRespostaEstaAberto: false };
        }


        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleModalAdicionarRespostaState = this.handleModalAdicionarRespostaState.bind(this);
    }

    handleModalAdicionarRespostaState(boolean) {
        this.setState({ modalAdicionarRespostaEstaAberto: boolean });
    }

    private botoesDoCantoSuperiorDireito() {
        return (
            <React.Fragment>
                <button className="btn bg-white shadow-sm mr-2" style={{ whiteSpace: 'nowrap' }} onClick={() => this.handleModalAdicionarRespostaState(true)} disabled={!this._estouNaPaginaDeEdicao}>{<Resource keyString="add_reply" />}</button>
                <button className="btn btn-outline-dark shadow-sm mr-2" onClick={this.handleCancel}>{<Resource keyString="back" />}</button>
            </React.Fragment>
        );
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;

        return (
            <CaixaDeConteudo
                tituloDoHeader={this._estouNaPaginaDeEdicao ? this.context.getLanguageResource("edit_answer_group") : this.context.getLanguageResource("create_answer_group")}
                botoes={this.botoesDoCantoSuperiorDireito()}
                itensParaBreadcrumb={[
                    { url: "/", nome: this.context.getLanguageResource("home") },
                    { url: "/grupoResposta", nome: this.context.getLanguageResource("response_model") },
                    { nome: this._estouNaPaginaDeEdicao ? this.context.getLanguageResource("edit_answer_group") : this.context.getLanguageResource("create_answer_group") }
                ]}
            >
                <main className='p-4'>
                    {this.renderCreateForm()}
                    {
                        this.state.grupoResposta.id > 0
                            ?   <FetchResposta gruporespostaid={this.state.grupoResposta.id} handleModalAdicionarRespostaState={this.handleModalAdicionarRespostaState} modalAdicionarRespostaEstaAberto={this.state.modalAdicionarRespostaEstaAberto} />
                            :   <React.Fragment />
                    }
                </main>
                {

                }
            </CaixaDeConteudo>
        );
    }

    // This will handle the submit form event.  
    private handleSave(event) {
        event.preventDefault();

        let grupoRespostaId = this.state.grupoResposta.id;

        Loading.showLoading(this);

        // PUT request for Edit User.  
        if (grupoRespostaId) {
            HttpService.fetch('api/GrupoRespostaAPI/' + grupoRespostaId, {
                method: 'PUT',
                body: HttpService.formToJson(event.target),
            }).then((response) => {
                return response.json() as Promise<GrupoRespostaData>
            }).then((data) => {

                this.setState({ grupoResposta: data })
                if ($.isEmptyObject(data.error)) {
                    this.redirectPage();
                }
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            })

        } else {
            HttpService.fetch('api/GrupoRespostaAPI', {
                method: 'POST',
                body: HttpService.formToJson(event.target),
            }).then((response) => {
                return response.json() as Promise<GrupoRespostaData>
            }).then((data) => {

                this.setState({ grupoResposta: data })
                if ($.isEmptyObject(data.error)) {
                    this.redirectPage();
                }
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            })
        }
    }

    // This will handle Cancel button click event.  
    private handleCancel(e) {
        e.preventDefault();
        this.redirectPage();
    }

    private redirectPage() {
        //this.props.history.push(`/grupoResposta/edit/${this.state.grupoResposta.id}`);
        this.props.history.push(`/grupoResposta`);
    }

    // Returns the HTML Form to the render() method.  
    private renderCreateForm() {
        return (<form onSubmit={this.handleSave} >
            <div className="form-group row" >
                <input type="hidden" name="userId" value={this.state.grupoResposta.id} />
            </div>
            < div className="form-group row" >
                <label className=" control-label col-md-12 required" htmlFor="Name">{<Resource keyString="default_response_model_name" />}</label>
                <div className="col-md-4">
                    <ValidationLabelMessage errors={this.state.grupoResposta.error} name="Nome" >
                        <input className="form-control" type="text" name="Nome" defaultValue={this.state.grupoResposta.nome} />
                    </ValidationLabelMessage>
                </div>
            </div >
            < div className="form-group row" >
                <label className=" control-label col-md-12 required" htmlFor="Name">{<Resource keyString="default_response_model_description" />}</label>
                <div className="col-md-4">
                    <ValidationLabelMessage errors={this.state.grupoResposta.error} name="Descricao" >
                        <input className="form-control" type="text" name="Descricao" defaultValue={this.state.grupoResposta.descricao} />
                    </ValidationLabelMessage>
                </div>
            </div >
            <div className="form-group col-12 d-flex justify-content-center">
                <button type="submit" className="btn font-weight-bold" style={{ backgroundColor: 'var(--ws-blue)', width: '17.5rem' }}>{<Resource keyString="save" />}</button>
            </div >
        </form >)
    }

}  