import React, { useEffect } from "react";
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { HttpService } from "./Shared/HttpService";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);

const Payment = ({ clientSecret, formularioid }) => {
    console.log(clientSecret);
    const options = { clientSecret: clientSecret.client_secret };
    
    useEffect(() => {
      const verificarStatus = async () => {
        HttpService.fetch(`VerificarCompra/${formularioid}`, { method: 'GET' })
            .then(response => response.json() as Promise<any>)
            .then(data => {
              if (data.status === 'succeeded') {
                window.location.href = `/formulario/responder/${formularioid}/${data.hash}`;
              } else {
                setTimeout(verificarStatus, 5000); 
              }
            })
            .catch(error => {
              console.error('There was an error!', error);
            });
      };

      verificarStatus();
  }, [formularioid]);

    return (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={options}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
    );
};

export default Payment;
