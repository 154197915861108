import * as React from 'react';
import { HttpService } from '../../../Shared/HttpService';
import { IResponderFormulario } from '../interfaces/IResponderFormularioResponse';
import { ICargaResponderFormulario } from '../interfaces/ICargaResponderFormulario';
import { montarCargaDeCabecalho, montarCargaDeGrupoDePerguntas, montarCargaDeRodape } from './helpers/montagemDeCarga';
import { set } from 'date-fns';

const { createContext, useState, useEffect } = React;

interface IContext {
    responderFormulario: IResponderFormulario;
    setResponderFormulario: (responderFormulario: IResponderFormulario) => void;
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;
    ultimaTentativaDeFinalizar: Date;
    setUltimaTentativaDeFinalizar: (ultimaTentativaDeFinalizar: Date) => void;
    salvarFormulario: () => void;
    finalizarFormulario: () => void;
    listaDeMensagensDeAlerta: string[];
    setListaDeMensagensDeAlerta: (listaDeMensagensDeAlerta: string[]) => void;
    mostrarTodosOsCollapses: boolean;
    setMostrarTodosOsCollapses: (mostrarTodosOsCollapses: boolean) => void;
    idsDosCollapsesQueVaoSerAbertos: Number[];
    setIdsDosCollapsesQueVaoSerAbertos: (idDosCollapsesQueVaoSerAbertos: Number[]) => void;
    responderFormularioOriginal: IResponderFormulario;
    salvarFormularioPosImportacao: (executarPosImportacao: () => void) => void;
}

const valorInicialResponderFormulario: IResponderFormulario = {
    id: 0,
    formularioId: 0,
    empresasid: 0,
    permitirCompartilhar: false,
    permitirEditarTitulo: false,
    permitirEditarCabecalhos: false,
    permitirEditarRespostas: false,
    permitirEditarAcoes: false,
    nome: '',
    titulo: '',
    hash: '',
    descricao: '',
    rascunho: true,
    cabecalhos: [],
    grupoDePerguntas: [],
    rodapes: [],
    formularioXEmpresaId: 0,
};

export const ResponderFormularioContext = createContext<IContext>({
    responderFormulario: valorInicialResponderFormulario,
    setResponderFormulario: () => { },
    isLoading: false,
    setIsLoading: () => { },
    ultimaTentativaDeFinalizar: new Date(),
    setUltimaTentativaDeFinalizar: () => { },
    salvarFormulario: () => { },
    finalizarFormulario: () => { },
    listaDeMensagensDeAlerta: [],
    setListaDeMensagensDeAlerta: () => { },
    mostrarTodosOsCollapses: false,
    setMostrarTodosOsCollapses: () => { },
    idsDosCollapsesQueVaoSerAbertos: [],
    setIdsDosCollapsesQueVaoSerAbertos: () => { },
    responderFormularioOriginal: valorInicialResponderFormulario,
    salvarFormularioPosImportacao: () => { },
});

export const ResponderFormularioContextProvider = ({ children, params }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [listaDeMensagensDeAlerta, setListaDeMensagensDeAlerta] = useState<string[]>([]);
    const [responderFormulario, setResponderFormulario] = useState<IResponderFormulario>(valorInicialResponderFormulario);
    const [ultimaTentativaDeFinalizar, setUltimaTentativaDeFinalizar] = useState<Date>(new Date());
    const [mostrarTodosOsCollapses, setMostrarTodosOsCollapses] = useState(false);
    const [idsDosCollapsesQueVaoSerAbertos, setIdsDosCollapsesQueVaoSerAbertos] = useState<Number[]>([]);
    const [responderFormularioOriginal, setResponderFormularioOriginal] = useState<IResponderFormulario>(valorInicialResponderFormulario);
    const auth = localStorage.getItem('auth');
    const tipoDeConta = auth ? JSON.parse(auth).tipoDeConta : 0;


    const montarCargaParaApi = (): ICargaResponderFormulario => {
        const { id, formularioId, hash, rascunho, titulo, cabecalhos, grupoDePerguntas, rodapes, empresasid } = responderFormulario;
        
        return {
            id,
            formularioId,
            empresasid,
            hash,
            rascunho,
            titulo,
            cabecalhos: montarCargaDeCabecalho(cabecalhos),
            grupoDePerguntas: montarCargaDeGrupoDePerguntas(grupoDePerguntas),
            rodapes: montarCargaDeRodape(rodapes),
        }
    }

    const salvarFormularioPosImportacao = (executarPosImportacao) => {
        setIsLoading(_state => true);

        HttpService.fetch('api/ResultadoNovoApi/ResponderFormulario', {
            method: 'POST',
            body: JSON.stringify(montarCargaParaApi()),
        })
            .then(response => {
                return new Promise<any>((resolve) => response.json()
                    .then((json) => resolve({
                        status: response.status,
                        ok: response.ok,
                        json,
                    })));
            })
            .then((data) => {
                if (data.status === 200) {
                    setListaDeMensagensDeAlerta(['salvoComSucesso']);
                    executarPosImportacao();
                } else {
                    setListaDeMensagensDeAlerta(['ocorreuUmErroAoSalvar']);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
                setIsLoading(_state => false);
            })
            .catch((error) => {
                setIsLoading(_state => false);
                console.log("Erro ao salvar o formulário: ", error)
            });
    };

    const salvarFormulario = (finalizando?: string) => {
        setIsLoading(_state => true);
        
        HttpService.fetch('api/ResultadoNovoApi/ResponderFormulario', {
            method: 'POST',
            body: JSON.stringify(montarCargaParaApi()),
        })
            .then(response => {
                return new Promise<any>((resolve) => response.json()
                    .then((json) => resolve({
                        status: response.status,
                        ok: response.ok,
                        json,
                    })));
            })
            .then((data) => {
                if (data.status === 200) {
                    if (finalizando === 'finalizando') return window.location.href = tipoDeConta === 1 ? `/marketPlace/detail/${responderFormulario.formularioId}` : `/`;
                    setListaDeMensagensDeAlerta(['salvoComSucesso']);
                    window.location.reload();
                } else {
                    setListaDeMensagensDeAlerta(['ocorreuUmErroAoSalvar']);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
                setIsLoading(_state => false);
            })
            .catch((error) => {
                setIsLoading(_state => false);
                console.log("Erro ao salvar o formulário: ", error)
            });
    }

    const finalizarFormulario = () => {
        setResponderFormulario(() => ({ ...responderFormulario, rascunho: false }));
    };

    const fetchFormulario = () => {
        setIsLoading(_state => true);
        HttpService.fetch(`api/FormularioIniciadoApi/ObterFormulario/${params.hash}`, { method: 'GET' })
            .then(response => response.json() as Promise<any>)
            .then(data => {
                setResponderFormulario(data);
                setResponderFormularioOriginal(JSON.parse(JSON.stringify(data)));
                setIsLoading(_state => false);
            })
            .catch((error) => {
                setIsLoading(_state => false);
                console.log("Erro ao obter o formulário: ", error)
            });
    }

    useEffect(() => {
        setIsLoading(_state => true);
        fetchFormulario();
    }, []);

    useEffect(() => {
        if (responderFormulario.rascunho === false) {
            salvarFormulario('finalizando');
        }
    }, [responderFormulario.rascunho]);

    return (
        <ResponderFormularioContext.Provider value={{
            responderFormulario,
            setResponderFormulario,
            isLoading,
            setIsLoading,
            ultimaTentativaDeFinalizar,
            setUltimaTentativaDeFinalizar,
            salvarFormulario,
            finalizarFormulario,
            listaDeMensagensDeAlerta,
            setListaDeMensagensDeAlerta,
            mostrarTodosOsCollapses,
            setMostrarTodosOsCollapses,
            idsDosCollapsesQueVaoSerAbertos,
            setIdsDosCollapsesQueVaoSerAbertos,
            responderFormularioOriginal,
            salvarFormularioPosImportacao,
        }}>
            {children}
        </ResponderFormularioContext.Provider>
    );
};
