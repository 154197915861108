import React, { useContext } from 'react';
import { NavMenu } from '../Shared/NavMenu';
import Header from '../Shared/Header';
import { AuthService } from '../UserAuth/AuthService';
import { Resource } from '../..';
import { GlobalContext } from '../../context/GlobalContext';
import RolarPaginaParaOTopoIcon from '../../assets/icons/rolar-pagina-para-o-topo-icon.svg';
import { AceiteDeTermos } from '../Shared/AceiteDeTermos';
import { LoggedInProvider } from '../../context/LoggedInContext';
import { LoggedGlobalProvider } from '../../context/LoggedGlobalContext';
import MainHolder from './components/MainHolder';
import SidebarMenu from '../SidebarMenu';

export interface LayoutProps {
    children?: React.ReactNode;
}

export const Layout: React.FunctionComponent<LayoutProps> = ({ children }) => {
    const { isSidebarOpened } = useContext(GlobalContext);
    const [isMobileSidebarOpened, setIsMobileSidebarOpened] = React.useState(false);

    window.onscroll = function () {
        scrollFunction();
    };
    
    function scrollFunction() {
        const btnTopo = document.getElementById("btn-topo");
        if (!btnTopo) return console.warn('Elemento btn-topo não encontrado');

        if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
            btnTopo.style.display = "block";
        } else {
            btnTopo.style.display = "none";
        }
    }

    const topFunction = () => window.scrollTo({ top: 0, behavior: 'smooth' });

    if (!AuthService.loggedIn()) {
        window.location.href = '/login';
        return <React.Fragment />;
    }

    return (
        <LoggedGlobalProvider>
            <LoggedInProvider>
                <div className="row mx-0">
                    <Header isMobileSidebarOpened={isMobileSidebarOpened} setIsMobileSidebarOpened={setIsMobileSidebarOpened} />
                    <NavMenu />
                    <SidebarMenu isMobileSidebarOpened={isMobileSidebarOpened} setIsMobileSidebarOpened={setIsMobileSidebarOpened} />
                    <AceiteDeTermos />
                    
                    <MainHolder isSidebarOpened={isSidebarOpened}>
                        <div>{children}</div>
                        <footer className="mt-5 d-flex align-items-center justify-content-center w-100">
                            <p>
                                Copyright &copy; 2023 win.win. {<Resource keyString="all_rights_reserved" />}.
                            </p>
                        </footer>
                    </MainHolder>
                    <button
                        className="btn p-3 d-none d-md-flex align-items-center justify-content-center _IMPRIMIR_OMITIR_ shadow ws-btn-white-outline"
                        onClick={topFunction}
                        id="btn-topo"
                        style={{
                            position: 'fixed',
                            bottom: '20px',
                            right: '220px',
                            width: '2.5rem',
                            height: '2.5rem',
                            borderRadius: '0.625rem 0.625rem 0 0.625rem',
                            zIndex: 100,
                        }}
                        title="Rolar página para o topo"
                    >
                        <img
                            src={RolarPaginaParaOTopoIcon}
                            alt="Ícone representando a rolagem da página para o topo"
                        />
                    </button>
                </div>
            </LoggedInProvider>
        </LoggedGlobalProvider>
    );
};
