import { useEffect, useRef } from 'react';

// hook customizado criado para executar uma função apenas quando o componente for atualizado,
// mas não quando o componente for montado.
const useDidUpdateEffect = (callback, dependencias) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      callback();
    } else {
      didMount.current = true;
    }
  }, dependencias);
}

export default useDidUpdateEffect;