import 'bootstrap';
import 'animsition';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './routes';
import dotenv from 'dotenv';
import { LanguageContext, LanguageProvider } from './context/LanguageContext';
import { GlobalProvider } from './context/GlobalContext';
import './css/bootstrap.min.css';
import './css/bootstrap-grid.min.css';
// import './css/bootstrap-reboot.min.css';
import './css/bootstrap-select.css';
// import './css/style.css';
import './css/win.set.css';
import './css/win.set.print.css';
import 'react-tippy/dist/tippy.css';

dotenv.config();

require("react-hot-loader/patch");

export const Resource = ({ keyString }) => {
    const { getLanguageResource } = React.useContext(LanguageContext);
    
    const resource = getLanguageResource(keyString);
    
    if (!resource) return keyString;
    return resource;
};

// Aceleragora Google Tag Manager
const ACELERAGORA_INSERIR_GOOGLE_TAG_MANAGER_NO_HEAD_E_BODY = () => {
    const firstChildOfHead = document.head.firstChild;
    const firstChildOfBody = document.body.firstChild;
    
    if (firstChildOfHead) {
        const script = document.createElement('script');
        script.innerHTML = `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-TQF374TK');
        `;

        document.head.insertBefore(script, firstChildOfHead);
    }

    if (firstChildOfBody) {
        const noscript = document.createElement('noscript');
        noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TQF374TK"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

        document.body.insertBefore(noscript, firstChildOfBody);
    }
    
    console.log('Aceleragora Google Tag Manager inserido no head e body');
    return null;
}
// Fim Aceleragora Google Tag Manager

function renderApp() {
    // This code starts up the React app when it runs in a browser. It sets up the routing
    // configuration and injects the app into a DOM element.
    ReactDOM.render(
        <>
            {
                window.location.href.includes('aceleragora.') && <ACELERAGORA_INSERIR_GOOGLE_TAG_MANAGER_NO_HEAD_E_BODY />
            }
            <AppContainer>
                <GlobalProvider>
                    <LanguageProvider>
                        <BrowserRouter basename="/">
                            <AppRoutes />
                        </BrowserRouter>
                    </LanguageProvider>
                </GlobalProvider>
            </AppContainer>
        </>,
        document.getElementById('root')
    );
}

renderApp();
