import styled from 'styled-components';

const Contagens = styled.section`
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 1rem;

    section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        width: calc(20% - 1rem);

        div {
            width: 100%;
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 1rem;

        section {
            width: 100%;
        }
    }
`;

export default Contagens;
