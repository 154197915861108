import React, { createContext, useEffect, useState } from 'react';
import { HttpService } from '../components/Shared/HttpService';
import { Loading } from '../components/Shared/Loading';

interface IGlobalContext {
    isSidebarOpened: boolean;
    setIsSidebarOpened: (isSidebarOpened: boolean) => void;
    tipodeconta: number;
    setTipodeconta: (tipodeconta: number) => void;
    verificarTipoDeConta: (plano: number) => void;
    deveDeslogar: () => void;
    preferencias: IPreferencias;
    setPreferencias: (preferencias: IPreferencias) => void;
    recuperarPreferencias: () => void;
    afiliacao: number | null | undefined;
    afiliados: Array<any>;
    verificarAfiliados: () => void;
}

interface IPreferencias {
    corDoTopoDaAplicacao: string;
    corPrincipal: string;
}

export const GlobalContext = createContext<IGlobalContext>({
    isSidebarOpened: false,
    setIsSidebarOpened: () => { },
    tipodeconta: 99999,
    setTipodeconta: () => { },
    verificarTipoDeConta: () => { },
    deveDeslogar: () => { },
    preferencias: {
        corDoTopoDaAplicacao: "#08ABD9",
        corPrincipal: "#22C9F7",
    },
    setPreferencias: () => { },
    recuperarPreferencias: () => { },
    afiliacao: undefined,
    afiliados: [],
    verificarAfiliados: () => { },
});

export const GlobalProvider = ({ children }) => {
    const [isSidebarOpened, setIsSidebarOpened] = useState(false);
    const [afiliacao, setAfiliacao] = useState<number | null | undefined>(undefined);
    const [afiliados, setAfiliados] = useState<Array<any>>([]); // [Aceleragora] - Adicionando a lista de afiliados
    const [loadingList, setLoadingList] = useState<Array<string | React.Component>>([]);
    const [tipodeconta, setTipodeconta] = useState(99999);
    const [preferencias, setPreferencias] = useState<IPreferencias>({
        corDoTopoDaAplicacao: "#08ABD9",
        corPrincipal: "#22C9F7",
    });

    const ajustarCorDoWSBlueDoCSS = () => { // altera a cor do css em todo o documento onde a variável --ws-blue é utilizada
        if (window.location.href.includes("aceleragora")) {
            document.documentElement.style.setProperty('--ws-blue', "#123add");
            return;
        };

        document.documentElement.style.setProperty('--ws-blue', preferencias.corPrincipal);
    }

    const deveDeslogar = () => {
        HttpService.fetch("api/Authentication/DeveDeslogar", { method: "GET" })
            .then((response) => {
                if (response.status === 401) {
                    localStorage.removeItem('auth');
                    window.location.href = "/login";
                }
            });
    };

    const verificarTipoDeConta = (plano) => {
        Loading.showLoading("VerificarTipoDeConta");
        HttpService.fetch("api/Authentication/GetTipoDeAcesso", { method: "GET" })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                plano !== tipodeconta && setTipodeconta(Number(data));
                Loading.hideLoading("VerificarTipoDeConta");
            });
    };

    const recuperarPreferencias = () => {
        Loading.showLoading("RecuperarPreferencias");
        HttpService.fetch("api/Authentication/RecuperarPreferencias", { method: "GET" })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data) {
                    const preferencias = localStorage.getItem('preferencias');
                    setPreferencias({ ...JSON.parse(preferencias as any), ...data });
                    localStorage.setItem('preferencias', JSON.stringify({ ...JSON.parse(preferencias as any), ...data }));
                    ajustarCorDoWSBlueDoCSS();
                }
                Loading.hideLoading("RecuperarPreferencias");
            });
    }

    const verificarAfiliacao = () => {
        Loading.showLoading("VerificarAfiliacao");
        HttpService.fetch("api/Authentication/VerificarAfiliacao", { method: "GET" })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setAfiliacao(data.afiliacao);
                Loading.hideLoading("VerificarAfiliacao");
            });
    }

    const verificarAfiliados = () => {
        Loading.showLoading("VerificarAfiliados");
        HttpService.fetch("api/Authentication/VerificarAfiliados", { method: "GET" })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setAfiliados(data.afiliacao);
                Loading.hideLoading("VerificarAfiliados");
            });
    }

    useEffect(() => {
        ajustarCorDoWSBlueDoCSS();
    }, [preferencias]);

    useEffect(() => {
        if (localStorage.getItem('auth') && afiliacao === undefined) {
            verificarAfiliacao();
        }
    }, [afiliacao]);

    const value = {
        isSidebarOpened,
        setIsSidebarOpened,
        loadingList,
        setLoadingList,
        tipodeconta,
        setTipodeconta,
        verificarTipoDeConta,
        deveDeslogar,
        preferencias,
        setPreferencias,
        recuperarPreferencias,
        afiliacao,
        verificarAfiliados,
        afiliados,
    };

    return (
        <GlobalContext.Provider value={value}>
            {children}
        </GlobalContext.Provider>
    );
};