import React, { useState, useEffect } from "react";
import LogoWinset from '../../assets/svg/logo.svg'
import { Resource } from "../..";
import { HttpService } from "../../components/Shared/HttpService";

const PaginaDeDownload = () => {
    const [referencia] = useState<string>(window.location.pathname.split("/")[2]);
    const [linkExpirado, setLinkExpirado] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    
    useEffect(() => {
        const verificarLinkExpirado = async () => {
            HttpService.fetch(`api/ArquivosApi/VerificarLinkExpirado/${referencia}`, 'GET')
                .then((response) => {
                    if (response.status === 410) {
                        setLinkExpirado(true);
                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                    }
                })
        };
        verificarLinkExpirado();
    }, [referencia]);

    if (isLoading) return <React.Fragment />;

    return (
        <main className='d-flex flex-column justify-content-center align-items-center' style={{ height: '100vh', width: '100vw' }}>
            <div
                className='d-flex flex-column justify-content-center align-items-center bg-white shadow rounded m-5'
                style={{ maxWidth: '41.25rem', overflow: 'hidden' }}
            >
                <div className='w-100' style={{ background: linkExpirado ? '#d72638' : 'var(--ws-blue)', height: "1rem" }} />
                <img
                    src={LogoWinset}
                    alt="Logo win.set"
                    style={{ width: "300px", height: "auto" }}
                    className="my-5"
                />
                {
                    linkExpirado
                        ?   (
                                <main className='w-100 d-flex flex-column align-items-center justify-content-center px-5 pb-5 pt-2'>
                                    <p className='text-center'><Resource keyString='download_link_expired_message' /></p>
                                </main>
                            )
                        :   (
                                <main className='w-100 d-flex flex-column align-items-center justify-content-center px-5 pb-5 pt-2'>
                                    <p className='text-center'><Resource keyString='download_avaliable_message' /></p>
                                    <button onClick={() => window.open(`${process.env.REACT_APP_URL_API}api/ArquivosApi/DownloadCompartilhado/${referencia}`, '_blank')} id="link_de_download" className='btn ws-outline-blue mt-4'>Download</button>
                                </main>
                            )
                }
            </div>
            <footer>
                <p>
                    Copyright &copy; 2020 win.win. {<Resource keyString="all_rights_reserved" />}.
                </p>
            </footer>
        </main>
    );
};

export default PaginaDeDownload;