import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { Layout } from '../../Layout';
import { HttpService } from '../../Shared/HttpService';
import { Loading } from '../../Shared/Loading';
import { HeadLayout } from '../../Shared/HeadLayout';
import { ModalPlanoAcao } from './ModalPlanoAcao';
import { ModalDelete } from '../../Shared/ModalDelete';
import * as ReactDOM from 'react-dom';
import $ from 'jquery';
import { Resource } from '../../../index';
import { PerguntaData } from '../Pergunta/FetchPergunta';
import { LanguageContext } from '../../../context/LanguageContext';

interface FetchPlanoAcaoState {
    planoAcaoList: PlanoAcaoData[];
    loading: boolean;
}

export class PlanoAcaoData {
    id: number = 0;
    oque: string = "";
    porque: string = "";
    onde: string = "";
    quando: string = "";
    quem: string = "";
    como: string = "";
    quantocusta: string = "";
    formularioid: number = 0;
    perguntaid: number = 0;
    pergunta: PerguntaData = new PerguntaData;
    error: Array<any> = [];
    hash: string = "";
    formularionome: string = "";
    formularioiniciadonome: string = "";
    campomodeloacaoXValor: Array<CampoModeloAcaoXValorData> = [];
}

export class CampoModeloAcaoXValorData {
    id: number = 0;
    valor: string = "";
    campo: string = "";
    formatomodeloacaoid: number = 0;
    planoacaoid: number = 0;
    campomodeloacao_id: number = 0;
}

export class FetchPlanoAcao extends React.Component<RouteComponentProps<{}>, FetchPlanoAcaoState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = { planoAcaoList: [], loading: true };

        this.updatePlanoAcao();

        this.handleDelete = this.handleDelete.bind(this);
        this.handleEdit = this.handleEdit.bind(this);

    }

    public updatePlanoAcao() {
        Loading.showLoading(this);;
        HttpService.fetch('api/PlanoAcaoApi', {})
            .then(response => response.json() as Promise<PlanoAcaoData[]>)
            .then(data => {
                this.setState({ planoAcaoList: data, loading: false });
                Loading.hideLoading(this);
            });
    }

    public render() {
        let contents = Loading.isLoading(this)
            ? <p><em></em></p>
            : this.renderUserTable(this.state.planoAcaoList);

        return <div className="col-lg-12">
            <HeadLayout title={this.context.getLanguageResource("my_actions")} />
            <div className="card">
                <div className="card-header"><a href="/">{<Resource keyString="home" />}</a> / {<Resource keyString="my_actions" />}</div>
                <div className="card-body">
                    <div className="card-title">
                        <h3 className="text-center title-2">{<Resource keyString="my_actions" />}
                            {/*<Link to="/planoAcao/add" className="action btn btn-primary pull-right" data-loading-text="<i className='fa fa-spinner fa-spin '></i> Processing Order">+ Adicionar</Link>*/}
                        </h3>
                    </div>
                    <hr />
                    {contents}
                </div>
            </div>
        </div>;
    }

    // Handle Delete request for an user  
    private handleDelete(id: number) {
        Loading.showLoading(this);;
        HttpService.fetch('api/PlanoAcaoApi/' + id, {
            method: 'delete'
        }).then(data => {
            this.setState(
                {
                    planoAcaoList: this.state.planoAcaoList.filter((rec) => {
                        return (rec.id != id);
                    })
                });
            Loading.hideLoading(this);
        }).catch(() => {
            Loading.hideLoading(this);
        });
    }

    private handleEdit(id: number) {
        this.props.history.push("/planoAcao/edit/" + id);
    }

    private ModalEdit(planoAcao: PlanoAcaoData) {
        var id = "modalPlanoAcao" + planoAcao.id;
        var acao = <ModalPlanoAcao key={id} id={id} callBack={this} formularioid={planoAcao.formularioid} perguntaid={planoAcao.perguntaid} planoAcao={planoAcao} />;

        $('body').append(`<div id="div${id}"></div>`);
        ReactDOM.render(acao, document.getElementById('div' + id));
    }

    // Returns the HTML table to the render() method.  
    private renderUserTable(planoAcaoList: PlanoAcaoData[]) {
        return <table className='table'>
            <tbody>
                {planoAcaoList.map(doc =>
                    <tr key={doc.id} style={{ "borderWidth": "3px", 'borderStyle': 'solid' }} >
                        <td>
                            <strong>{<Resource keyString="my-action_question" /> }:</strong>
                            <br />
                            {doc.pergunta.titulo}
                            <table className='table'>
                                <tr>
                                    <th>{<Resource keyString="form" />}</th>
                                    <th>{<Resource keyString="what" />}</th>
                                    <th colSpan={2}>{<Resource keyString="how" />}</th>
                                </tr>
                                <tr>
                                    <td>{doc.formularioiniciadonome == null || doc.formularioiniciadonome == "" ? doc.hash : doc.formularioiniciadonome} ({doc.formularionome})</td>
                                    <td>{doc.oque}</td>
                                    <td>{doc.como}</td>
                                    <td rowSpan={2} style={{ paddingLeft: "12px", width: "224px" }}>
                                        {this.ModalEdit(doc)}
                                        <button className="btn btn-outline-danger mr-2 pull-right" data-toggle="modal" data-target={`#delete${doc.id}`}><i className='fa fa-trash'></i> {<Resource keyString="delete" />}</button>
                                        <ModalDelete id={doc.id} callBack={this} message={this.context.getLanguageResource("myAction_deleteMessage")} />
                                        <button className='btn btn-outline-success mr-2 pull-right' data-toggle="modal" data-target={"#modalPlanoAcao" + doc.id} type="button"><i className='fa fa-edit'></i> {<Resource keyString="edit" />}</button>
                                        {/*<button className="btn btn-outline-success mr-2" onClick={(id) => this.handleEdit(doc.id)}>Editar</button>*/}
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>;
    }
}   