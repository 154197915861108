import React, { useEffect, useState } from 'react';
import CaixaDeConteudo from '../../components/Shared/CaixaDeConteudo';
// import FilterMenu from './components/FilterMenu';
import { HttpService } from '../../components/Shared/HttpService';
import { Loading } from '../../components/Shared/Loading';
import { format } from 'date-fns';

// interface IValor {
//     pago: boolean;
//     gratuito: boolean;
// }

// export interface IFiltrosSelecionados {
//     status: string[],
//     valor: IValor,
// }

const Compras = () => {
    const [compras, setCompras] = useState<any[]>([]);
    // const [menuDeFiltrosEstaSendoExibido, setMenuDeFiltrosEstaSendoExibido] = useState<boolean>(false);
    // const [filtrosSelecionados, setFiltrosSelecionados] = useState<IFiltrosSelecionados>({
    //     status: [],
    //     valor: {
    //         pago: false,
    //         gratuito: false,
    //     }
    // });

    useEffect(() => {
        Loading.showLoading("Compras");
        HttpService.fetch("api/Vendas/LogDeCompras", { method: "GET" })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setCompras(data);
                Loading.hideLoading("Compras");
            });
    }, []);

    return (
        <CaixaDeConteudo
            tituloDoHeader='Compras'
            botoes={[]}
        >
            <div className='col-12'>
                {/* <section className="row py-2">
                    <div className="col-12 col-md-6 pb-2">
                        <input type="text" className="form-control" placeholder="Pesquisa rápida" />
                    </div>
                    <div className="col-12 col-md-6">
                        <div className='d-flex flex-column align-items-end w-100 position-relative'>
                            <button type="button" className="btn ws-outline-blue" onClick={() => setMenuDeFiltrosEstaSendoExibido(!menuDeFiltrosEstaSendoExibido)}>
                                <i className="bi bi-funnel-fill mr-2" />
                                <span>Filtrar</span>
                            </button>
                            {
                                menuDeFiltrosEstaSendoExibido && <FilterMenu filtrosSelecionados={filtrosSelecionados} />
                            }
                        </div>
                    </div>
                </section> */}
                <section className='row py-2'>
                    <div className="col-12 overflow-auto">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Nome do formulário</th>
                                    <th scope="col">Vendedor</th>
                                    <th scope="col">Data/Hora</th>
                                    <th scope="col">Custo</th>
                                    <th scope="col">Desconto</th>
                                    <th scope="col">Valor total</th>
                                    {/* <th scope="col"></th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    compras.map((compra, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{compra.nomeDoFormulario}</td>
                                                <td>{compra.nomeDaEmpresaVendedora}</td>
                                                <td>{format(new Date(compra.dataEHoraDaVenda), 'dd/MM/yyyy HH:mm')}</td>
                                                <td>{compra.custo.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                <td>{compra.desconto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                <td>{compra.valorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                {/* <td>
                                                    <div className='d-flex justify-content-end'>
                                                        <button className='btn'><i className="bi bi-three-dots" /></button>
                                                    </div>
                                                </td> */}
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </section>
            </div>
        </CaixaDeConteudo>
    );
}

export default Compras;
