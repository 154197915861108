import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { PerguntaData } from './FetchPergunta';
import { HttpService } from '../../Shared/HttpService';
import { Loading } from '../../Shared/Loading';
import { ValidationLabelMessage, ValidationAlertMessage } from '../../Shared/ValidationMessage';
import $ from 'jquery';
import { FormatoPerguntaData } from '../FormatoPergunta/FetchFormatoPergunta';
import { GrupoRespostaData } from '../GrupoResposta/FetchGrupoResposta';
import { VisualizacaoPerguntaCadastro } from './VisualizacaoPerguntaCadastro';
import { GrupoPerguntaData } from '../GrupoPergunta/FetchGrupoPergunta';
import { RespostaData } from '../Resposta/FetchResposta';
import { Resource } from '../../../index';
import { ModeloPlanoAcaoData } from '../../ModelosPlanoAcao/FetchModeloPlanoAcao';
import { LanguageContext } from '../../../context/LanguageContext';
import CaixaDeConteudo from '../../Shared/CaixaDeConteudo';

interface AddPerguntaDataState {
    pergunta: PerguntaData;
    formatos: FormatoPerguntaData[];
    grupoPergunta: GrupoPerguntaData[];
    gruporespostas: GrupoRespostaData[];
    grupoperguntaid: number;
    modeloPlanoAcaoList: ModeloPlanoAcaoData[];
    isCheckedCriarPlanoAcao: boolean;
    comprimentoDoTextoDeDescricao: number;
    comprimentoDoTextoDeAjuda: number;
}

export class AddPergunta extends React.Component<RouteComponentProps<{}>, AddPerguntaDataState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        var perguntaId = this.props.match.params["perguntaid"];
        var formularioid = this.props.match.params["formularioid"];
        var grupoperguntaid = this.props.match.params["grupoperguntaid"];
        var pergunta = new PerguntaData;
        pergunta.formularioid = formularioid;

        this.state = {
            grupoperguntaid: grupoperguntaid,
            pergunta: pergunta,
            formatos: [],
            gruporespostas: [],
            grupoPergunta: [],
            modeloPlanoAcaoList: [],
            isCheckedCriarPlanoAcao: false,
            comprimentoDoTextoDeDescricao: pergunta.descricao ? pergunta.descricao.length : 0,
            comprimentoDoTextoDeAjuda: pergunta.ajuda ? pergunta.ajuda.length : 0,
        };

        Loading.showLoading(this);
        HttpService.fetch('api/FormatoPerguntaApi', {})
            .then(response => response.json() as Promise<FormatoPerguntaData[]>)
            .then(data => {
                this.setState({ formatos: data });
                Loading.hideLoading(this);
            });

        Loading.showLoading(this);
        HttpService.fetch('api/GrupoRespostaApi', {})
            .then(response => response.json() as Promise<GrupoRespostaData[]>)
            .then(data => {
                this.setState({ gruporespostas: data });
                Loading.hideLoading(this);
            });

        Loading.showLoading(this);
        HttpService.fetch('api/GrupoPerguntaApi/GetNames/' + this.state.pergunta.formularioid, {})
            .then(response => response.json() as Promise<GrupoPerguntaData[]>)
            .then(data => {
                this.setState({ grupoPergunta: data });
                Loading.hideLoading(this);
            });

        Loading.showLoading(this);
        HttpService.fetch('api/ModeloPlanoAcaoApi/SelectModeloPlanoAcaoDaListagemDaPergunta', {})
            .then(response => response.json() as Promise<ModeloPlanoAcaoData[]>)
            .then(data => {
                this.setState({ modeloPlanoAcaoList: data });
                Loading.hideLoading(this);
            });

        // This will set state for Edit User  
        if (perguntaId > 0) {
            Loading.showLoading(this);

            HttpService.fetch('api/PerguntaApi/' + perguntaId, { method: 'GET' })
                .then(response => response.json() as Promise<PerguntaData>)
                .then(data => {
                    this.setState({
                        pergunta: data,
                        comprimentoDoTextoDeAjuda: data.ajuda ? data.ajuda.length : 0,
                        comprimentoDoTextoDeDescricao: data.descricao ? data.descricao.length : 0,
                        isCheckedCriarPlanoAcao: data.permitircriaracao
                    });
                    Loading.hideLoading(this);
                }).catch(() => {
                    Loading.hideLoading(this);
                });
        }

        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.addResposta = this.addResposta.bind(this);
        this.handleResposta = this.handleResposta.bind(this);
        this.handleDeleteResposta = this.handleDeleteResposta.bind(this);
        this.handleSelectModeloPlanoAcao = this.handleSelectModeloPlanoAcao.bind(this);
    }

    
    // This will handle Cancel button click event.  
    private handleCancel(e) {
        e.preventDefault();
        this.props.history.push(`/formulario/edit/${this.state.pergunta.formularioid}`);
    }

    private addResposta(e, self) {
        e.preventDefault();
        var pergunta: PerguntaData = self.state.pergunta;
        var resposta = new RespostaData();
        var respostas = pergunta.respostas.sort((a, b) => (b.reactid - a.reactid));
        resposta.reactid = respostas.length > 0 && respostas[0].reactid > 0 ? (respostas[0].reactid + 1) : 1;
        pergunta.respostas.push(resposta);
        self.setState({ pergunta: pergunta });
    }

    private handleResposta(self, index, prop, obj) {
        var pergunta: PerguntaData = self.state.pergunta;
        if (prop == "ko") {
            pergunta.respostas.filter(x => x.reactid + "-" + x.id == index)[0][prop] = obj.target.checked;
        } else if (prop == "permitirobservacaoobrigatoria") {
            pergunta.respostas.filter(x => x.reactid + "-" + x.id == index)[0][prop] = obj.target.checked;
        } else if (prop == "permitiranexarevidenciaobrigatoria") {
            pergunta.respostas.filter(x => x.reactid + "-" + x.id == index)[0][prop] = obj.target.checked;
        } else if (prop == "permitirplanoacaoobrigatoria") {
            pergunta.respostas.filter(x => x.reactid + "-" + x.id == index)[0][prop] = obj.target.checked;
        } else if (prop == "permitiranexarfotoappobrigatoria") {
            pergunta.respostas.filter(x => x.reactid + "-" + x.id == index)[0][prop] = obj.target.checked;
        } else {
            var valor = obj.target.value;
            if (prop == "valorporcentagem")
                valor = valor.length > 0 ? valor : "0";
            pergunta.respostas.filter(x => x.reactid + "-" + x.id == index)[0][prop] = valor;
        }
        self.setState({ pergunta: pergunta });
    }

    private handleDeleteResposta(self: AddPergunta, index, e) {
        e.preventDefault();
        var pergunta = self.state.pergunta;
        pergunta.respostas = pergunta.respostas.filter((o, i) => o.reactid + "-" + o.id != index);
        self.setState({ pergunta: pergunta });
    }

    private handleChangeGrupoResposta(self: AddPergunta, e) {
        if (self.state.pergunta.respostas.length == 0) {
            var pergunta = self.state.gruporespostas;
            var respostas = pergunta.filter((o, i) => o.id == e.target.value)[0].resposta;
            respostas.map((o, i) => {
                o.reactid = i;
                o.id = 0;
            });
            self.state.pergunta.respostas = respostas;
            self.setState({ pergunta: self.state.pergunta });
        }
    }

    private handleUncheckObservacaoObrigatoria(event) {
        const pergunta = this.state.pergunta;

        if (event == true) {
            pergunta.permitirobservacao = event;
        }
        else {
            pergunta.respostas.map(x => {
                x.permitirobservacaoobrigatoria = false;
            });
            pergunta.permitirobservacao = event;
        }

        this.setState({ pergunta: pergunta })
    }

    private handleUncheckAnexarEvidenciaObrigatoria(event) {
        const pergunta = this.state.pergunta;
        
        if (event == true) {
            pergunta.permitiranexarevidencia = event;
        }
        else {
            pergunta.respostas.map(x => {
                x.permitiranexarevidenciaobrigatoria = false;
            });
            pergunta.permitiranexarevidencia = event;
        }

        this.setState({ pergunta: pergunta })
    }

    private handleUncheckPlanoAcaoObrigatoria(event) {
        const pergunta = this.state.pergunta;

        if (event == true) {
            pergunta.permitircriaracao = event;
        }
        else {
            pergunta.respostas.map(x => {
                x.permitirplanoacaoobrigatoria = false;
            });
            pergunta.permitircriaracao = event;
        }

        this.setState({ pergunta: pergunta })
    }
    
    private handleUncheckAnexarFotoAppObrigatoria(event) {
        const pergunta = this.state.pergunta;

        if (event == true) {
            pergunta.permitiranexarfotoapp = event;
        }
        else {
            pergunta.respostas.map(x => {
                x.permitiranexarfotoappobrigatoria = false;
            });
            pergunta.permitiranexarfotoapp = event;
        }

        this.setState({ pergunta: pergunta })
    }

    private handleSelectModeloPlanoAcao(event) {
        this.handleUncheckPlanoAcaoObrigatoria(event);
        
        if (event == true) {
            this.setState({ isCheckedCriarPlanoAcao: true });
        }
        else {
            this.setState({ isCheckedCriarPlanoAcao: false });
        }
    }

    // This will handle the submit form event.  
    private handleSave(event) {
        event.preventDefault();
    
        var json: any = HttpService.formatData(event.target);
        var respostas = new Array<any>();
        this.state.pergunta.respostas.map(resp => {
            respostas.push({
                Id: resp.id,
                Nome: resp.nome,
                Valor: resp.valor,
                Valorporcentagem: resp.valorporcentagem,
                Ordenacao: resp.ordenacao,
                Ko: resp.ko,
                Permitirobservacaoobrigatoria: resp.permitirobservacaoobrigatoria,
                Permitiranexarevidenciaobrigatoria: resp.permitiranexarevidenciaobrigatoria,
                Permitirplanoacaoobrigatoria: resp.permitirplanoacaoobrigatoria,
                Permitiranexarfotoappobrigatoria: resp.permitiranexarfotoappobrigatoria
            });
        });
        json.Respostas = respostas;
    
        Loading.showLoading(this);
    
        // PUT request for Edit User.  
        if (this.state.pergunta.id > 0) {
    
            HttpService.fetch('api/PerguntaApi/' + this.state.pergunta.id, {
                method: 'PUT',
                body: JSON.stringify(json),
            }).then((response) => response.json() as Promise<PerguntaData>)
                .then((data) => {
                    this.setState({ pergunta: data })
    
                    if ($.isEmptyObject(this.state.pergunta.error)) {
                        this.props.history.push(`/formulario/edit/${this.state.pergunta.formularioid}`);
                    }
                    Loading.hideLoading(this);
                }).catch((e) => {
    
                    console.log(e);
                    Loading.hideLoading(this);
                })
    
        } else {
    
            HttpService.fetch('api/PerguntaApi', {
                method: 'POST',
                body: JSON.stringify(json),
            }).then((response) => response.json() as Promise<PerguntaData>)
                .then((data) => {
                    this.setState({ pergunta: data })
    
                    if ($.isEmptyObject(this.state.pergunta.error)) {
                        this.props.history.push(`/formulario/edit/${this.state.pergunta.formularioid}`);
                    }
                    Loading.hideLoading(this);
                }).catch((e) => {
    
                    console.log(e);
                    Loading.hideLoading(this);
                })
        }
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;
    
        return (
            <CaixaDeConteudo
                tituloDoHeader={this.state.pergunta.id > 0 ? this.context.getLanguageResource('edit_question') : this.context.getLanguageResource('create_question')}
                itensParaBreadcrumb={[
                    { url: '/', nome: this.context.getLanguageResource('home') },
                    // TODO - Puxar nome do formulário para utilizar no breadcrumb na linha abaixo. No momento está fixo o resource "form"
                    { url: `/formulario/edit/${this.state.pergunta.formularioid}`, nome: this.context.getLanguageResource('form') },
                    { url: '', nome: this.state.pergunta.id > 0 ? this.context.getLanguageResource('edit_question') : this.context.getLanguageResource('create_question') }
                ]}
                botoes={
                    <button className='btn bg-white shadow-sm mr-2' onClick={this.handleCancel}>{this.context.getLanguageResource('back')}</button>
                }
            >
                <main className='p-4'>
                    <div className="w-100 d-flex justify-content-center py-4">
                        <h3>{this.state.pergunta.id > 0 ? this.context.getLanguageResource('edit_question') : this.context.getLanguageResource('create_question')}</h3>
                    </div>
                    <form onSubmit={this.handleSave} >
                        <div>
                            <input type="hidden" name="id" value={this.state.pergunta.id} />
                            <input type="hidden" name="formularioid" value={this.state.pergunta.formularioid} />
    
                            <div className="form-group col-md-12">
                                <label className="required">{<Resource keyString="question_title" />}</label>
                                <ValidationLabelMessage errors={this.state.pergunta.error} name="Titulo">
                                    <input className="form-control" type="text" name="Titulo" placeholder={this.context.getLanguageResource("question_title")} defaultValue={this.state.pergunta.titulo} />
                                </ValidationLabelMessage>
                            </div>
                            <div className="form-group col-md-12">
                                <label>{<Resource keyString="description" />}</label>
                                <ValidationLabelMessage errors={this.state.pergunta.error} name="Descricao">
                                    <textarea className="form-control" value={this.state.pergunta.descricao} name="Descricao" onChange={(event) => this.setState({ comprimentoDoTextoDeDescricao: event.target.value.length, pergunta: { ...this.state.pergunta, descricao: event.target.value } })} placeholder={this.context.getLanguageResource("description")} />
                                </ValidationLabelMessage>
                                <div className="w-100 d-flex justify-content-end mt-1">
                                    <span className={`${this.state.comprimentoDoTextoDeDescricao > 250 && 'badge-danger'} badge`}>{ `${this.state.comprimentoDoTextoDeDescricao}/250` }</span>
                                </div>
                            </div>
                            <div className="form-group col-md-12">
                                <label>{<Resource keyString="help" />}</label>
                                <ValidationLabelMessage errors={this.state.pergunta.error} name="ajuda">
                                    <textarea className="form-control" value={this.state.pergunta.ajuda} name="ajuda" onChange={(event) => this.setState({ comprimentoDoTextoDeAjuda: event.target.value.length, pergunta: { ...this.state.pergunta, ajuda: event.target.value } })} placeholder={this.context.getLanguageResource("help")} />
                                </ValidationLabelMessage>
                                <div className='w-100 d-flex justify-content-end mt-1'>
                                    <span className={`${this.state.comprimentoDoTextoDeAjuda > 250 && 'badge-danger'} badge`}>{ `${this.state.comprimentoDoTextoDeAjuda}/250` }</span>
                                </div>
                            </div>
                            <div className="form-group col-md-12">
                                <label className="required">{<Resource keyString="ordination" />}</label>
                                <ValidationLabelMessage errors={this.state.pergunta.error} name="Ordenacao">
                                    <input className="form-control" type="text" name="Ordenacao" placeholder={this.context.getLanguageResource("ordination")} defaultValue={this.state.pergunta.ordenacao.toString()} />
                                </ValidationLabelMessage>
                            </div>
    
                            <div className="form-group col-md-12">
                                <label className="required">{<Resource keyString="group_questions" />}</label>
                                <ValidationLabelMessage errors={this.state.pergunta.error} name="Grupoperguntaid">
                                    <select className="form-control col-md-12" data-val="true" id="Grupoperguntaid" name="Grupoperguntaid" defaultValue={this.state.pergunta.grupoperguntaid > 0 ? this.state.pergunta.grupoperguntaid.toString() : this.state.grupoperguntaid.toString()} >
                                        <option value="">-- {this.context.getLanguageResource("select")} --</option>
                                        {this.state.grupoPergunta.map(x =>
                                            <option key={x.id} value={x.id}>{x.nome}</option>
                                        )}
                                    </select>
                                </ValidationLabelMessage>
                            </div>
    
                            <div className="form-group col-md-12">
                                <label className="required">{<Resource keyString="response_group" />}</label>
                                <ValidationLabelMessage errors={this.state.pergunta.error} name="Gruporespostaid">
                                    <select className="form-control col-md-12" data-val="true" id="Gruporespostaid"
                                        name="Gruporespostaid" defaultValue={this.state.pergunta.gruporespostaid + ""}
                                        onChange={e => this.handleChangeGrupoResposta(this, e)}
                                        disabled={this.state.pergunta.respostas.length > 0}>
                                        <option value="">-- {this.context.getLanguageResource("select")} --</option>
                                        {this.state.gruporespostas.map(x =>
                                            <option key={x.id} value={x.id}>{x.nome}</option>
                                        )}
                                    </select>
                                </ValidationLabelMessage>
                            </div>
    
                            <div className="form-group col-md-12" style={{ paddingLeft: 31 }}>
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="PermitirObservacao" id="PermitirObservacao" onChange={e => this.handleUncheckObservacaoObrigatoria(e.target.checked)} defaultChecked={this.state.pergunta.permitirobservacao} />
                                    <label className="custom-control-label" htmlFor="PermitirObservacao">{<Resource keyString="allow_observation" />}</label>
                                </div>
                            </div>
                            <div className="form-group col-md-12" style={{ paddingLeft: 31 }}>
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="PermitirAnotacao" id="PermitirAnotacao" defaultChecked={this.state.pergunta.permitiranotacao} />
                                    <label className="custom-control-label" htmlFor="PermitirAnotacao">{<Resource keyString="allow_annotation" />}</label>
                                </div>
                            </div>
                            <div className="form-group col-md-12" style={{ paddingLeft: 31 }}>
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="PermitirAnexarEvidencia" id="PermitirAnexarEvidencia" onChange={e => this.handleUncheckAnexarEvidenciaObrigatoria(e.target.checked)} defaultChecked={this.state.pergunta.permitiranexarevidencia} />
                                    <label className="custom-control-label" htmlFor="PermitirAnexarEvidencia">{<Resource keyString="allow_attach_evidence" />}</label>
                                </div>
                            </div>
                            <div className="form-group col-md-12" style={{ paddingLeft: 31 }}>
                                <div className="custom-control custom-checkbox mb-3">
                                    <input type="checkbox" className="custom-control-input" name="PermitirCriarAcao" id="PermitirCriarAcao" onChange={e => this.handleSelectModeloPlanoAcao(e.target.checked)} defaultChecked={this.state.pergunta.permitircriaracao} />
                                    <label className="custom-control-label" htmlFor="PermitirCriarAcao">{<Resource keyString="allow_create_action" />}</label>
                                </div>
                                {this.state.isCheckedCriarPlanoAcao 
                                    ?   (
                                            <div className="form-group col-md-12">
                                                <select className="form-control col-md-12" data-val="true" id="modeloPlanoAcaoId" name="modeloPlanoAcaoId" defaultValue={this.state.pergunta.modeloPlanoAcaoId.toString()} >
                                                    <option value="">-- Selecione o Plano de Acao --</option>
                                                    {this.state.modeloPlanoAcaoList.map(x =>
                                                        <option key={x.id} value={x.id}>{x.nome}</option>
                                                    )}
                                                </select>
                                            </div>
                                        )
                                    :   <React.Fragment />
                                }
                            </div>
                            <div className="form-group col-md-12" style={{ paddingLeft: 31 }}>
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="PermitirAnexarFotoApp" id="PermitirAnexarFotoApp" onChange={e => this.handleUncheckAnexarFotoAppObrigatoria(e.target.checked)} defaultChecked={this.state.pergunta.permitiranexarfotoapp} />
                                    <label className="custom-control-label" htmlFor="PermitirAnexarFotoApp">Permitir Anexar Foto Pelo Aplicativo</label>
                                </div>
                            </div>
    
                            <div className="w-100 pt-4 border-bottom mb-3">
                                <p className="required font-weight-bold">{<Resource keyString="reply" />}</p>
                            </div>
    
                            <div className='w-100' style={{ overflowY: 'auto' }}>
                                <div className='w-100'>
    
                                    <header className='row py-2 px-0 m-0'>
                                        <div className='d-flex align-items-end col-2'><small className='font-weight-bold'><Resource keyString="name" /></small></div>
                                        <div className='d-flex align-items-end col'><small className='font-weight-bold'><Resource keyString="value" /></small></div>
                                        <div className='d-flex align-items-end col'><small className='font-weight-bold'><Resource keyString="ordination" /></small></div>
                                        <div className='d-flex justify-content-center align-items-end col'><small className='font-weight-bold'>KO?</small></div>
                                        <div className='d-flex justify-content-center align-items-end col'><small className='font-weight-bold'>Obrigar observação</small></div>
                                        <div className='d-flex justify-content-center align-items-end col'><small className='font-weight-bold'>Obrigar anexo de evidência</small></div>
                                        <div className='d-flex justify-content-center align-items-end col'><small className='font-weight-bold'>Obrigar ação</small></div>
                                        <div className='d-flex justify-content-center align-items-end col'><small className='font-weight-bold'>Obrigar anexo de foto pelo aplicativo</small></div>
                                        <div className='d-flex justify-content-end align-items-end col'>
                                            <button className="btn ws-outline-blue" style={{ width: '2.75rem', height: '2.75rem' }} onClick={(e) => this.addResposta(e, this)}>
                                                <i className="fa fa-plus"></i>
                                            </button>
                                        </div>
                                    </header>
    
                                    <main>
                                        {
                                            this.state.pergunta.respostas.sort((a, b) => a.id - b.id).sort((a, b) => b.reactid - a.reactid).map((resposta, index) => (
                                                <div className='row p-0 mx-0 mt-1' key={resposta.id}>
                                                    <div className='col-2'>
                                                        <input
                                                            className="form-control required"
                                                            type="text"
                                                            onChange={(obj) => this.handleResposta(this, resposta.reactid + "-" + resposta.id, "nome", obj)}
                                                            placeholder={this.context.getLanguageResource("name")}
                                                            value={resposta.nome}
                                                            required
                                                        />
                                                    </div>
                                                    <div className='col d-flex align-items-center justify-content-center'>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            onChange={(obj) => this.handleResposta(this, resposta.reactid + "-" + resposta.id, "valor", obj)}
                                                            value={resposta.valor != null ? resposta.valor.toString() : ''}
                                                        />
                                                    </div>
                                                    <div className='col d-flex align-items-center justify-content-center'>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            onChange={(obj) => this.handleResposta(this, resposta.reactid + "-" + resposta.id, "ordenacao", obj)}
                                                            value={resposta.ordenacao.toString()}
                                                        />
                                                    </div>
                                                    <div className='col d-flex align-items-center justify-content-center'>
                                                        <input
                                                            type="checkbox"
                                                            onChange={(obj) => this.handleResposta(this, resposta.reactid + "-" + resposta.id, "ko", obj)}
                                                            checked={resposta.ko == true}
                                                        />
                                                    </div>
                                                    <div className='col d-flex align-items-center justify-content-center'>
                                                        <input
                                                            className="observacaoobrigatoria"
                                                            type="checkbox"
                                                            disabled={!this.state.pergunta.permitirobservacao}
                                                            onChange={(obj) => this.handleResposta(this, resposta.reactid + "-" + resposta.id, "permitirobservacaoobrigatoria", obj)}
                                                            checked={resposta.permitirobservacaoobrigatoria == true}
                                                        />
                                                    </div>
                                                    <div className='col d-flex align-items-center justify-content-center'>
                                                        <input
                                                            className="anexarevidenciaobrigatoria"
                                                            type="checkbox"
                                                            disabled={!this.state.pergunta.permitiranexarevidencia}
                                                            onChange={(obj) => this.handleResposta(this, resposta.reactid + "-" + resposta.id, "permitiranexarevidenciaobrigatoria", obj)}
                                                            checked={resposta.permitiranexarevidenciaobrigatoria == true}
                                                        />
                                                    </div>
                                                    <div className='col d-flex align-items-center justify-content-center'>
                                                        <input
                                                            className="planoacaoobrigatoria"
                                                            type="checkbox"
                                                            disabled={!this.state.pergunta.permitircriaracao}
                                                            onChange={(obj) => this.handleResposta(this, resposta.reactid + "-" + resposta.id, "permitirplanoacaoobrigatoria", obj)}
                                                            checked={resposta.permitirplanoacaoobrigatoria == true}
                                                        />
                                                    </div>
                                                    <div className='col d-flex align-items-center justify-content-center'>
                                                        <input
                                                            className="anexarfotoappobrigatoria"
                                                            type="checkbox"
                                                            disabled={!this.state.pergunta.permitiranexarfotoapp}
                                                            onChange={(obj) => this.handleResposta(this, resposta.reactid + "-" + resposta.id, "permitiranexarfotoappobrigatoria", obj)}
                                                            checked={resposta.permitiranexarfotoappobrigatoria == true}
                                                        />
                                                    </div>
                                                    <div className='d-flex justify-content-end align-items-end col'>
                                                        <button
                                                            className="btn btn-outline-danger"
                                                            onClick={(e) => this.handleDeleteResposta(this, resposta.reactid + "-" + resposta.id, e)}
                                                            style={{ width: '2.75rem', height: '2.75rem' }}
                                                        >
                                                            <i className='fa fa-trash'></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </main>
                                
                                </div>
                            </div>
    
                        </div >
                        <div className="d-flex w-100 justify-content-center mt-4">
                            <ValidationAlertMessage errors={this.state.pergunta.error} name="Summary" />
                            <button type="submit" className="btn mr-2" style={{ backgroundColor: 'var(--ws-blue)', width: '17.5rem' }}>{<Resource keyString="save" />}</button>
                        </div >
                    </form >
                </main>
            </CaixaDeConteudo>
        );
    }
}