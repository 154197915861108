import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { UsuarioData } from './FetchUsuarios';
import { Layout } from '../Layout';
import { HttpService } from '../Shared/HttpService';
import { HeadLayout } from '../Shared/HeadLayout';
import { Loading } from '../Shared/Loading';
import { AuthService } from '../UserAuth/AuthService';
import { ValidationLabelMessage, ValidationAlertMessage } from '../Shared/ValidationMessage';
import { UserData } from '../User/FetchUser';
import { AddRegra } from './AddRegra';
import $ from 'jquery';
import { ListaDeRegrasVinculadasMinhaConta } from './ListaDeRegrasVinculadasMinhaConta';
import { ChangePassword } from '../UserAuth/ChangePassword';
import { Resource } from '../../index';
import { RegraNotificacaoPorEmail, RegraNotificacaoPorEmailData } from './RegraNotificacaoPorEmail';
import { LanguageContext } from '../../context/LanguageContext';
import CaixaDeConteudo from '../Shared/CaixaDeConteudo';
import ModalWinSet from '../Shared/components/ModalWinSet';

interface AddUserDataState {
    title: string;
    loading: boolean;
    empData: UsuarioData;
    usuarioxRegraNotificacaoPorEmail: RegraNotificacaoPorEmailData;
    modalAlterarSenhaEstaAberto: boolean;
}

export class MinhaConta extends React.Component<RouteComponentProps<{}>, AddUserDataState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        this.state = { title: "", loading: true, empData: new UsuarioData, usuarioxRegraNotificacaoPorEmail: new RegraNotificacaoPorEmailData, modalAlterarSenhaEstaAberto: false };

        Loading.showLoading(this);
        HttpService.fetch('api/UsuariosxRegraReceberNotificacaoPorEmailApi/GetVinculoRegraReceberNotificacaoEmail', { method: 'GET' })
            .then(response => response.json() as Promise<RegraNotificacaoPorEmailData>)
            .then(data => {
                this.setState({ usuarioxRegraNotificacaoPorEmail: data });
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
                props.history.push("/");
            });

        // This will set state for Edit User  
        Loading.showLoading(this);
        HttpService.fetch('api/UsuariosApi/MinhaConta', { method: 'GET' })
            .then(response => response.json() as Promise<UsuarioData>)
            .then(data => {
                this.setState({ title: this.context.getLanguageResource("my_account"), loading: false, empData: data });
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
                props.history.push("/");
            });


        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);
        this.handleModalAlterarSenha = this.handleModalAlterarSenha.bind(this);
    }

    private botoesDoCantoSuperiorDireito() {
        return (
            <React.Fragment>
                <button className="btn btn-light shadow-sm mr-2 text-nowrap" style={{ whiteSpace: 'nowrap' }} onClick={() => this.handleModalAlterarSenha(true)}><i className='fa fa-lock'></i> {<Resource keyString="change_password" />}</button>
                <button className='btn btn-outline-dark mr-2 text-nowrap' onClick={() => window.location.href = '/'}><Resource keyString='back' /></button>
            </React.Fragment>
        );
    }

    handleModalAlterarSenha (boolean) {
        this.setState({ modalAlterarSenhaEstaAberto: boolean });
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;
        return (
            <CaixaDeConteudo
                itensParaBreadcrumb={[
                    { url: "/", nome: this.context.getLanguageResource("home") },
                    { nome: this.context.getLanguageResource("my_account") }
                ]}
                botoes={ this.botoesDoCantoSuperiorDireito() }
                tituloDoHeader='Minha Conta'
            >
                {
                        this.state.modalAlterarSenhaEstaAberto && (
                            <ModalWinSet
                                fecharModal={() => this.handleModalAlterarSenha(false)}
                            >
                                <ChangePassword usuariosId={this.state.empData.id} fecharModal={() => this.handleModalAlterarSenha(false)} />
                            </ModalWinSet>
                        )
                    }
                <div>
                    <input type="hidden" name="Id" value={this.state.empData.id} />
                    <input type="hidden" name="Empresasid" value={this.state.empData.id} />
                </div>

                <div className="form-group row" >
                    <div className="col-6 p-5">
                        <section className='d-flex flex-column align-items-center h-100'>
                            <header className='my-3'>
                                <h4 className="font-weight-light m-0 p-0">Dados Pessoais</h4>
                            </header>
                            <form className='w-100 h-100 d-flex flex-column justify-content-between align-items-between' onSubmit={this.handleSave} >
                                <div className='w-100'>
                                    <label className="control-label col-md-12 required" htmlFor="Nome">{<Resource keyString="name" />}</label>
                                    <div className="col-md-12">
                                        <ValidationLabelMessage errors={this.state.empData.error} name="Nome">
                                            <input className="form-control" type="text" name="Nome" id="Nome" defaultValue={this.state.empData.nome} />
                                        </ValidationLabelMessage>
                                    </div>
                                </div>
                                <div className='w-100 mt-4'>
                                    <label className="control-label col-md-12" htmlFor="Documento" >{<Resource keyString="register_cpf" />}</label>
                                    <div className="col-md-12">
                                        <input className="form-control cpf" type="text" name="Documento" id="Documento" defaultValue={this.state.empData.documento} />
                                    </div>
                                </div>
                                <div className='w-100 mt-4'>
                                    <label className="control-label col-md-12 required" htmlFor="Email" >{<Resource keyString="email" />}</label>
                                    <div className="col-md-12">
                                        <ValidationLabelMessage errors={this.state.empData.error} name="Email">
                                            <input className="form-control" type="email" name="Email" id="Email" defaultValue={this.state.empData.email} />
                                        </ValidationLabelMessage>
                                    </div>
                                </div>
                                <div className="form-group w-100 col-md-12">
                                    <div className="custom-control custom-checkbox mt-4">
                                        <input type="checkbox" className="custom-control-input" name="Isactive" id="Isactive" defaultChecked={this.state.empData.isactive} />
                                        <label className="custom-control-label" htmlFor="Isactive">{<Resource keyString="active_account" />}</label>
                                    </div>
                                    <button type="submit" className="btn mr-2 mt-4" style={{ background: 'var(--ws-blue)' }}>{<Resource keyString="save" />}</button>
                                </div>
                            </form>
                        </section>
                    </div>
                    <div className="col-6 p-5">
                        <RegraNotificacaoPorEmail
                            usuariosId={this.state.empData.id}
                            usuarioxRegraNotificacaoPorEmail={this.state.usuarioxRegraNotificacaoPorEmail}
                        />
                    </div>
                </div>
                <div className='p-4'>
                    <h4 className='font-weight-light m-0 p-0'>{this.context.getLanguageResource("rules")}</h4>
                </div>
                <ListaDeRegrasVinculadasMinhaConta usuariosId={this.state.empData.id} minhaConta={true} />
            </CaixaDeConteudo>
        );
    }

    // This will handle the submit form event.  
    private handleSave(event) {
        event.preventDefault();

        var id = this.state.empData.id;

        Loading.showLoading(this);;
        // PUT request for Edit User.  
        if (id > 0) {
            HttpService.fetch('api/UsuariosApi/MinhaConta', {
                method: 'PUT',
                body: HttpService.formToJson(event.target),
            }).then((response) => {

                return response.json() as Promise<UsuarioData>

            }).then((data) => {
                this.setState({ empData: data });
                if ($.isEmptyObject(this.state.empData.error)) {
                    this.props.history.push("/minhaConta");
                }
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            })
        }
    }
}  