import React, { useState, useContext } from 'react';
import { Resource } from '../../../../../index';
import MenuArrow from '../../../../../assets/icons/menu-arrow.svg';
import useDidUpdateEffect from '../../../../../hooks/useDidUpdateEffect';
import { FormularioRespondidoContext } from '../../../../../context/FormularioRespondidoContext';

interface IProps {
	grupo: any;
	setIsOpened: (isOpened: boolean) => void;
	isOpened: boolean;
}

const HeaderDeGrupo: React.FC<IProps> = ({ grupo, isOpened, setIsOpened }) => {
	const { mostrarTodosOsCollapses } = useContext(FormularioRespondidoContext);

	const {
		pontuacao = undefined,
		pontuacaoMaxima = undefined,
		setorNome = undefined,
		setorId = undefined,
	} = grupo.resultadoAgrupadoPorGrupo === null ? {} : grupo.resultadoAgrupadoPorGrupo;

	const pontuacaoFinal = ((100 * pontuacao) / pontuacaoMaxima).toFixed(2)

	useDidUpdateEffect(() => setIsOpened(mostrarTodosOsCollapses), [mostrarTodosOsCollapses]);

	return (
		<div
			className="py-2 px-4 rounded shadow-sm border d-flex justify-content-between w-100"
			style={{ cursor: 'pointer' }}
			id={`heading-${grupo.id}`}
			onClick={() => setIsOpened(!isOpened)}
		>
			{
				grupo.resultadoAgrupadoPorGrupo !== null
					? <React.Fragment>
						<div className="d-flex flex-row justify-content-between w-100">
							<div className='d-flex align-items-center justify-content-center'>
								<p className='mb-0'>{setorNome !== null && setorId > 0 ? `[${setorNome}]` : ""}</p>
								<p className='mb-0'>{`${grupo.nome}`}</p>
							</div>
							<div className='d-flex align-items-center justify-content-center'>
								{
									pontuacaoMaxima == 0 || pontuacao == null
										? <p className='mr-3 mb-0'>(NA)</p>
										: (pontuacao == 0)
											? ''
											: <p className='mr-3 mb-0'>{<Resource keyString="punctuation" />} <strong>{pontuacaoFinal}%</strong></p>
								}
							</div>
						</div>
					</React.Fragment>
					: <p className='mb-0'>{grupo.nome}</p>
			}
			<img
				className='_IMPRIMIR_OMITIR_'
				src={MenuArrow}
				alt="arrow-sidebar-button"
				style={{
					transform: isOpened ? 'rotate(180deg)' : '',
					transition: 'transform 550ms ease',
				}}
			/>
		</div>
	);
};

export default HeaderDeGrupo;
