import React, { useContext, useEffect, useState } from 'react';
import CaixaDeConteudo from '../Shared/CaixaDeConteudo';
import { LanguageContext } from '../../context/LanguageContext';
import ItemDeFormulario from './components/ItemDeFormulario';
import { HttpService } from '../Shared/HttpService';
import { IFormulario, IQuantidadeDeFormulariosPorMomentoNoCicloDeAuditoria } from './interfaces/IFormulario';
import { LoggedGlobalContext } from '../../context/LoggedGlobalContext';
import FormulariosNavbarIcon from '../../assets/icons/formularios-navbar-icon.svg';
import { AuthService } from '../UserAuth/AuthService';
import Contagens from './components/Contagens';
import TextoDosCardsDeContagem from './components/TextoDosCardsDeContagem';
import { aRotaPertenceAoAcelerAgora } from 'utils/acelerAgoraUtils';
import { FileCheck2, X } from 'lucide-react';
import { GlobalContext } from 'context/GlobalContext';

enum Ordenacao {
    ultimaModificacaoDecrescente,
    ultimaModificacaoCrescente,
}

enum Filtro {
    naoIniciados,
    emAndamento,
    aguardandoFinalizar,
    compartilhados,
    comAcoesPendentes,
    finalizados,
}

interface IFormularioOption {
    value: string;
    label: string;
}

const Inicio = () => {
    const [listaDeFormulariosIniciados, setListaDeFormulariosIniciados] = useState([] as IFormulario[]);
    const [quantidadeDeFormulariosPorMomentoDoCicloDeAuditoria, setQuantidadeDeFormulariosPorMomentoDoCicloDeAuditoria] = useState({} as IQuantidadeDeFormulariosPorMomentoNoCicloDeAuditoria);
    const [filtrosSelecionados, setFiltrosSelecionados] = useState([] as Filtro[]);
    const [isLoading, setIsLoading] = useState(true);
    const [ordenacao, setOrdenacao] = useState(Ordenacao.ultimaModificacaoDecrescente);
    const [formulariosCriadosPelaEmpresa, setFormulariosCriadosPelaEmpresa] = useState([] as IFormularioOption[]);
    const [filtroDeFormularioCriaodPelaEmpresa, setFiltroDeFormularioCriaodPelaEmpresa] = useState(0);
    const { getLanguageResource } = useContext(LanguageContext);
    const { nomeDoUsuario, razaoSocialDaEmpresa } = useContext(LoggedGlobalContext);
    const { afiliacao, afiliados, verificarAfiliados } = useContext(GlobalContext);

    const ordenacaoDosFormularios = (a, b) => {
        switch (ordenacao) {
            case Ordenacao.ultimaModificacaoDecrescente:
                return new Date(a.ultimaModificacao) > new Date(b.ultimaModificacao) ? -1 : 1;
            case Ordenacao.ultimaModificacaoCrescente:
                return new Date(a.ultimaModificacao) < new Date(b.ultimaModificacao) ? -1 : 1;
            default:
                return new Date(a.ultimaModificacao) > new Date(b.ultimaModificacao) ? -1 : 1;
        }
    };

    const alterarOrdenacaoPelaData = () => {
        switch (ordenacao) {
            case Ordenacao.ultimaModificacaoDecrescente:
                setOrdenacao(Ordenacao.ultimaModificacaoCrescente);
                break;
            case Ordenacao.ultimaModificacaoCrescente:
                setOrdenacao(Ordenacao.ultimaModificacaoDecrescente);
                break;
        }
    };

    const buscarPreferencias = () => {
        const preferencias = localStorage.getItem("preferencias");
        if (preferencias) {
            return JSON.parse(preferencias);
        }
        return {};
    }

    const contagemDeFormulariosEmCadaMomentoDoCicloDeAuditoria = (dados: IFormulario[]) => {
        const contagem: IQuantidadeDeFormulariosPorMomentoNoCicloDeAuditoria = {
            naoIniciados: 0,
            emAndamento: 0,
            aguardandoFinalizar: 0,
            compartilhados: 0,
            comAcoesPendentes: 0,
            finalizados: 0,
        };

        dados.forEach((formulario: IFormulario) => {
            const oForlularioFoiCompartilhado = formulario.compartilhado;
            const oFormularioNaoFoiIniciado = formulario.perguntasRespondidas === 0 && formulario.totalDePerguntas !== 0;
            const oFormularioFoiIniciado = formulario.perguntasRespondidas > 0 && formulario.perguntasRespondidas < formulario.totalDePerguntas;
            const oFormularioTeveTodasAsPerguntasRespondidas = formulario.perguntasRespondidas === formulario.totalDePerguntas && formulario.totalDePerguntas !== 0;
            const oFormularioPossuiAcaoPendente = oFormularioTeveTodasAsPerguntasRespondidas && formulario.possuiAcaoPendente;
            const oFormularioTeveTodasAsPerguntasEAcoesRespondidas = oFormularioTeveTodasAsPerguntasRespondidas && !formulario.possuiAcaoPendente;
            const oFormularioFoiFinalizado = oFormularioTeveTodasAsPerguntasEAcoesRespondidas && !formulario.rascunho;
        
            if (oFormularioNaoFoiIniciado) {
                contagem.naoIniciados += 1;
            }
            
            if (oFormularioFoiIniciado) {
                contagem.emAndamento += 1;
            }
            
            if (oFormularioTeveTodasAsPerguntasEAcoesRespondidas && formulario.rascunho) {
                contagem.aguardandoFinalizar += 1;
            }
            
            if (oForlularioFoiCompartilhado) {
                contagem.compartilhados += 1;
            }
            
            if (oFormularioPossuiAcaoPendente) {
                contagem.comAcoesPendentes += 1;
            }

            if (oFormularioFoiFinalizado) {
                contagem.finalizados += 1;
            }
        });

        setQuantidadeDeFormulariosPorMomentoDoCicloDeAuditoria(_prevState => contagem);
    };

    const filtrarFormularios = (
        formularios: IFormulario[]
    ): IFormulario[] => {
        let formulariosFiltrados: IFormulario[] = [];

        if (filtrosSelecionados.length === 0) {
            return formularios;
        }

        filtrosSelecionados.forEach((filtro: Filtro) => {
            switch (filtro) {
                case Filtro.naoIniciados:
                    const filtragemDeNaoIniciados = formularios.filter((formulario: IFormulario) => {
                        const oFormularioNaoFoiIniciado = formulario.perguntasRespondidas === 0 && formulario.totalDePerguntas !== 0;
                        return oFormularioNaoFoiIniciado;
                    });
                    formulariosFiltrados = [...formulariosFiltrados, ...filtragemDeNaoIniciados];
                    break;
                case Filtro.emAndamento:
                    const filtragemDosEmAndamento = formularios.filter((formulario: IFormulario) => {
                        const oFormularioFoiIniciado = formulario.perguntasRespondidas > 0 && formulario.perguntasRespondidas < formulario.totalDePerguntas;
                        return oFormularioFoiIniciado;
                    });
                    formulariosFiltrados = [...formulariosFiltrados, ...filtragemDosEmAndamento];
                    break;
                case Filtro.aguardandoFinalizar:
                    const filtragemDosAguardandoFinalizar = formularios.filter((formulario: IFormulario) => {
                        const oFormularioTeveTodasAsPerguntasRespondidas = formulario.perguntasRespondidas === formulario.totalDePerguntas && formulario.totalDePerguntas !== 0 && !formulario.possuiAcaoPendente && formulario.rascunho;
                        return oFormularioTeveTodasAsPerguntasRespondidas;
                    });
                    formulariosFiltrados = [...formulariosFiltrados, ...filtragemDosAguardandoFinalizar];
                    break;
                case Filtro.compartilhados:
                    const filtragemDosCompartilhados = formularios.filter((formulario: IFormulario) => {
                        return formulario.compartilhado;
                    });
                    formulariosFiltrados = [...formulariosFiltrados, ...filtragemDosCompartilhados];
                    break;
                case Filtro.comAcoesPendentes:
                    const filtragemDosComAcoesPendentes = formularios.filter((formulario: IFormulario) => {
                        const oFormularioTeveTodasAsPerguntasRespondidas = formulario.perguntasRespondidas === formulario.totalDePerguntas && formulario.totalDePerguntas !== 0;
                        const oFormularioPossuiAcaoPendente = oFormularioTeveTodasAsPerguntasRespondidas && formulario.possuiAcaoPendente;
                        return oFormularioPossuiAcaoPendente;
                    });
                    formulariosFiltrados = [...formulariosFiltrados, ...filtragemDosComAcoesPendentes];
                    break;
                case Filtro.finalizados:
                    const filtragemDosFinalizados = formularios.filter((formulario: IFormulario) => {
                        const oFormularioTeveTodasAsPerguntasRespondidas = formulario.perguntasRespondidas === formulario.totalDePerguntas && formulario.totalDePerguntas !== 0;
                        const oFormularioFoiFinalizado = oFormularioTeveTodasAsPerguntasRespondidas && !formulario.rascunho;
                        return oFormularioFoiFinalizado;
                    });
                    formulariosFiltrados = [...formulariosFiltrados, ...filtragemDosFinalizados];
                    break;
            }
        });

        // Remove os formulários duplicados
        formulariosFiltrados = formulariosFiltrados.filter((formulario: IFormulario, index: number, self: IFormulario[]) => {
            return self.findIndex((f: IFormulario) => f.formularioIniciadoId === formulario.formularioIniciadoId) === index;
        });

        return formulariosFiltrados;
    };

    const selecionarFiltro = (prevState: Filtro[], filtroSelecionado: Filtro): Filtro[] => {
        if (prevState.includes(filtroSelecionado)) {
            return prevState.filter(filtro => filtro !== filtroSelecionado);
        } else {
            return [...prevState, filtroSelecionado];
        }
    };

    const getFormulariosCriadosPelaEmpresa = () => {
        HttpService.fetch("api/Inicio/GetFormulariosCriadosPelaEmpresa", { method: "GET" })
            .then(response => response.json() as Promise<any>)
            .then(data => {
                setFormulariosCriadosPelaEmpresa(data.map(form => ({ label: form.nome, value: form.id })) as IFormularioOption[]);
            });
    }

    useEffect(() => {
        HttpService.fetch("api/Inicio/GetAllData", { method: "GET" })
            .then(response => response.json() as Promise<IFormulario[]>)
            .then(data => {
                if (data.length === 0) {
                    window.location.href = '/marketPlace';
                    return;
                }
                setListaDeFormulariosIniciados((_prevState) => data);
                contagemDeFormulariosEmCadaMomentoDoCicloDeAuditoria(data);
                if (aRotaPertenceAoAcelerAgora()) {
                    getFormulariosCriadosPelaEmpresa();
                    verificarAfiliados();
                }
                setIsLoading(false);
            });
    }, []);

    return (
        <CaixaDeConteudo
            tituloDaPagina={ getLanguageResource("home") }
            tituloDoHeader={ getLanguageResource("home") }
            botoes={
                <React.Fragment>
                    {
                        parseInt(AuthService.getStorage('tipodeconta')) === 1 &&
                            (
                                <React.Fragment>
                                    <button
                                        className="btn bg-white mx-2 d-flex align-items-center justify-content-center"
                                        style={{ width: '2.5rem', height: '2.5rem', fontSize: '1.5rem', cursor: 'pointer' }}
                                        onClick={() => window.location.href = '/meusFormularios'}
                                    >
                                        <img src={FormulariosNavbarIcon} alt="Exibir detalhes" />
                                    </button> 
                                    <button
                                        className="btn bg-white mx-2 d-flex align-items-center justify-content-center"
                                        style={{ width: '2.5rem', height: '2.5rem', fontSize: '1.5rem', cursor: 'pointer' }}
                                        onClick={() => window.location.href = '/marketPlaceInterno'}
                                    >
                                        <i className="bi bi-cart mb-0"></i>
                                    </button>
                                </React.Fragment> 
                            )
                    }
                    <button
                        className="btn bg-white mx-2 d-flex align-items-center justify-content-center"
                        style={{ width: '2.5rem', height: '2.5rem', fontSize: '1.5rem', cursor: 'pointer' }}
                        onClick={() => window.location.href = '/formularioCompartilhado'}
                    >
                        <i className="bi bi-share mb-0"></i>
                    </button>    
                </React.Fragment>
            }
            itensParaBreadcrumb={[ { nome: getLanguageResource("home"), url: "/" } ]}
        >
            <div className='d-flex flex-column w-100 py-3'>
                <section id="saudacoes" className='px-3'>
                    <h3>{getLanguageResource('hello')} {nomeDoUsuario.split(' ')[0]}, {getLanguageResource('see_how_the_audits_are_in')} {razaoSocialDaEmpresa}...</h3>
                </section>

                <Contagens>
                    <section id="espaco-nao-iniciadas">
                        <div
                            id="nao-iniciadas" 
                            className='d-flex flex-column rounded shadow align-items-center p-3 h-100 w-100 ws-hover-scale-change'
                            style={{ cursor: 'pointer' ,backgroundColor: filtrosSelecionados.includes(Filtro.naoIniciados) ? 'var(--ws-blue-background)' : 'white' }}
                            onClick={() => setFiltrosSelecionados(prevState => selecionarFiltro(prevState, Filtro.naoIniciados))}
                        >
                            <div className='w-100 d-flex justify-content-end'>
                                <img src={process.env.PUBLIC_URL + '/images/icons/nao-iniciados.svg'} alt="Não iniciados" />
                            </div>
                            {
                                isLoading
                                    ?   (
                                            <div className='d-flex justify-content-center' style={{ height: '3.5rem' }}>
                                                <div className="spinner-border text-primary" style={{ width: '2rem', height: '2rem' }} role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        )
                                    :   (
                                            <TextoDosCardsDeContagem quantidade={quantidadeDeFormulariosPorMomentoDoCicloDeAuditoria.naoIniciados} texto={getLanguageResource('not_started')} />
                                        )
                            }
                        </div>
                    </section>
                    <section id="espaco-em-andamento">
                        <div
                            id="em-andamento" 
                            className='d-flex flex-column rounded shadow align-items-center p-3 h-100 w-100 ws-hover-scale-change'
                            style={{ cursor: 'pointer' ,backgroundColor: filtrosSelecionados.includes(Filtro.emAndamento) ? 'var(--ws-blue-background)' : 'white' }}
                            onClick={() => setFiltrosSelecionados(prevState => selecionarFiltro(prevState, Filtro.emAndamento))}
                        >
                            <div className='w-100 d-flex justify-content-end'>
                                <img src={process.env.PUBLIC_URL + '/images/icons/em-andamento.svg'} alt="Em andamento" />
                            </div>
                            {
                                isLoading
                                    ?   (
                                            <div className='d-flex justify-content-center' style={{ height: '3.5rem' }}>
                                                <div className="spinner-border text-primary" style={{ width: '2rem', height: '2rem' }} role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        )
                                    :   (
                                            <TextoDosCardsDeContagem quantidade={quantidadeDeFormulariosPorMomentoDoCicloDeAuditoria.emAndamento} texto={getLanguageResource('in_progress')} />
                                        )
                            }
                        </div>
                    </section>
                    <section id="espaco-aguardando-finalizar">
                        <div
                            id="aguardando-finalizar" 
                            className='d-flex flex-column rounded shadow align-items-center p-3 h-100 w-100 ws-hover-scale-change'
                            style={{ cursor: 'pointer' ,backgroundColor: filtrosSelecionados.includes(Filtro.aguardandoFinalizar) ? 'var(--ws-blue-background)' : 'white' }}
                            onClick={() => setFiltrosSelecionados(prevState => selecionarFiltro(prevState, Filtro.aguardandoFinalizar))}
                        >
                            <div className='w-100 d-flex justify-content-end'>
                                <img src={process.env.PUBLIC_URL + '/images/icons/aguardando-finalizar.svg'} alt="Aguardando finalizar" />
                            </div>
                            {
                                isLoading
                                    ?   (
                                            <div className='d-flex justify-content-center' style={{ height: '3.5rem' }}>
                                                <div className="spinner-border text-primary" style={{ width: '2rem', height: '2rem' }} role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        )
                                    :   (
                                            <TextoDosCardsDeContagem quantidade={quantidadeDeFormulariosPorMomentoDoCicloDeAuditoria.aguardandoFinalizar} texto={getLanguageResource('waiting_to_finish')} />
                                        )
                            }
                        </div>
                    </section>
                    <section id="espaco-compartilhadas">
                        <div
                            id="compartilhadas" 
                            className='d-flex flex-column rounded shadow align-items-center p-3 h-100 w-100 ws-hover-scale-change'
                            style={{ cursor: 'pointer' ,backgroundColor: filtrosSelecionados.includes(Filtro.compartilhados) ? 'var(--ws-blue-background)' : 'white' }}
                            onClick={() => setFiltrosSelecionados(prevState => selecionarFiltro(prevState, Filtro.compartilhados))}
                        >
                            <div className='w-100 d-flex justify-content-end'>
                                <img src={process.env.PUBLIC_URL + '/images/icons/compartilhados.svg'} alt="Compartilhados" />
                            </div>
                            {
                                isLoading
                                    ?   (
                                            <div className='d-flex justify-content-center' style={{ height: '3.5rem' }}>
                                                <div className="spinner-border text-primary" style={{ width: '2rem', height: '2rem' }} role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        )
                                    :   (
                                            <TextoDosCardsDeContagem quantidade={quantidadeDeFormulariosPorMomentoDoCicloDeAuditoria.compartilhados} texto={getLanguageResource('shared')} />
                                        )
                            }
                        </div>
                    </section>
                    {
                        !aRotaPertenceAoAcelerAgora() && (
                            <section id="espaco-acoes-pendentes">
                                <div
                                    id="acoes-pendentes" 
                                    className='d-flex flex-column rounded shadow align-items-center p-3 h-100 w-100 ws-hover-scale-change'
                                    style={{ cursor: 'pointer' ,backgroundColor: filtrosSelecionados.includes(Filtro.comAcoesPendentes) ? 'var(--ws-blue-background)' : 'white' }}
                                    onClick={() => setFiltrosSelecionados(prevState => selecionarFiltro(prevState, Filtro.comAcoesPendentes))}
                                >
                                    <div className='w-100 d-flex justify-content-end'>
                                        <img src={process.env.PUBLIC_URL + '/images/icons/acoes-pendentes.svg'} alt="Ações pendentes" />
                                    </div>
                                    {
                                        isLoading
                                            ?   (
                                                    <div className='d-flex justify-content-center' style={{ height: '3.5rem' }}>
                                                        <div className="spinner-border text-primary" style={{ width: '2rem', height: '2rem' }} role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    </div>
                                                )
                                            :   (
                                                    <TextoDosCardsDeContagem quantidade={quantidadeDeFormulariosPorMomentoDoCicloDeAuditoria.comAcoesPendentes} texto={getLanguageResource('pending_actions')} />
                                                )
                                    }
                                </div>
                            </section>
                        )
                    }
                    {
                        aRotaPertenceAoAcelerAgora() && afiliacao !== null && (
                                <section id="espaco-acoes-pendentes">
                                    <div
                                        id="acoes-pendentes" 
                                        className='d-flex flex-column rounded shadow align-items-center p-3 h-100 w-100 ws-hover-scale-change'
                                        style={{ cursor: 'pointer' ,backgroundColor: filtrosSelecionados.includes(Filtro.comAcoesPendentes) ? 'var(--ws-blue-background)' : 'white' }}
                                        onClick={() => setFiltrosSelecionados(prevState => selecionarFiltro(prevState, Filtro.comAcoesPendentes))}
                                    >
                                        <div className='w-100 d-flex justify-content-end'>
                                            <img src={process.env.PUBLIC_URL + '/images/icons/acoes-pendentes.svg'} alt="Ações pendentes" />
                                        </div>
                                        {
                                            isLoading
                                                ?   (
                                                        <div className='d-flex justify-content-center' style={{ height: '3.5rem' }}>
                                                            <div className="spinner-border text-primary" style={{ width: '2rem', height: '2rem' }} role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div>
                                                        </div>
                                                    )
                                                :   (
                                                        <TextoDosCardsDeContagem quantidade={quantidadeDeFormulariosPorMomentoDoCicloDeAuditoria.comAcoesPendentes} texto={getLanguageResource('pending_actions')} />
                                                    )
                                        }
                                    </div>
                                </section>
                        )
                    }
                    {
                        // #region [Aceleragora] - Card de vendidos respondidos na tela de início
                        afiliacao === null && afiliados && afiliados.length > 0 && (
                            <section id="espaco-vendidos-respondidos">
                                <div
                                    id="vendidos-respondidos" 
                                    className='d-flex flex-column rounded shadow align-items-center p-3 h-100 w-100 ws-hover-scale-change'
                                    style={{ cursor: 'pointer' ,backgroundColor: filtrosSelecionados.includes(Filtro.finalizados) ? 'var(--ws-blue-background)' : 'white' }}
                                    onClick={() => setFiltrosSelecionados(prevState => selecionarFiltro(prevState, Filtro.finalizados))}
                                >
                                    <div className='w-100 d-flex justify-content-end'>
                                        <FileCheck2 />
                                    </div>
                                    {
                                        isLoading
                                            ?   (
                                                    <div className='d-flex justify-content-center' style={{ height: '3.5rem' }}>
                                                        <div className="spinner-border text-primary" style={{ width: '2rem', height: '2rem' }} role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    </div>
                                                )
                                            :   (
                                                    <TextoDosCardsDeContagem quantidade={quantidadeDeFormulariosPorMomentoDoCicloDeAuditoria.finalizados} texto="Vendidos finalizados" />
                                                )
                                    }
                                </div>
                            </section>
                            // #endregion
                        )
                    }
                </Contagens>

                {
                /* #region [Aceleragora] - Filtro de formulários criados pela empresa */
                    aRotaPertenceAoAcelerAgora() && (
                        <section className='mt-4 px-3'>
                            {
                                !isLoading && (
                                    <div className='d-flex justify-content-end align-items-center'>
                                        <label className='font-weight-bold text-nowrap mb-0 mr-3'>Filtro por formulário:</label>
                                        <select className='form-control' onChange={(e) => setFiltroDeFormularioCriaodPelaEmpresa(Number(e.target.value))} value={filtroDeFormularioCriaodPelaEmpresa}>
                                            <option value={0}>{getLanguageResource('select')}</option>
                                            {
                                                formulariosCriadosPelaEmpresa.map(form => (
                                                    <option key={form.value} value={form.value}>{form.label}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                )
                            }
                        </section>
                    )
                /* #endregion */
                }

                <section id="listagem" className='mt-4 px-3'>
                    <div className='shadow-sm' style={{ overflow: 'hidden', borderRadius: '1rem' }}>
                        { /* @ts-ignore */ }
                        <div className='py-1 px-3' style={{ background: buscarPreferencias().corPrincipal }}>
                            <span>{getLanguageResource('lets_get_to_work')}!</span>
                        </div>
                        <div className='py-2 px-1'>
                            <div className="w-100">

                                {
                                    isLoading
                                        ?   (
                                                <div className="d-flex justify-content-center align-items-center text-primary" style={{ height: '10rem' }}>
                                                    <div className="spinner-border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                            )
                                        :   <React.Fragment>
                                                {
                                                    listaDeFormulariosIniciados.length === 0
                                                        ?   (
                                                                <div className='d-flex align-items-center justify-content-center py-4'>
                                                                    <p className='mb-0'>{getLanguageResource('no_audits')}</p>
                                                                </div>
                                                            )
                                                        :   <React.Fragment>
                                                                <div className="row d-md-flex d-none">
                                                                    <div className="col" />
                                                                    <div style={{ width: '28.5rem' }}>
                                                                        <div
                                                                            className='font-weight-bold'
                                                                            style={{ width: '11.25rem', cursor: 'pointer' }}
                                                                            onClick={alterarOrdenacaoPelaData}
                                                                        >
                                                                            <span className='mr-2'>{getLanguageResource('last_modified')}</span>
                                                                            {
                                                                                ordenacao === Ordenacao.ultimaModificacaoDecrescente
                                                                                    ?   <i className='bi bi-arrow-up' />
                                                                                    :   <i className='bi bi-arrow-down' />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='pr-2 barra_de_rolagem_estilizada' style={{ maxHeight: '25rem', overflowY: 'auto' }}>
                                                                    <div className='d-flex flex-column justify-content-stretch' style={{ justifyContent: 'stretch' }}>
                                                                        {
                                                                            filtrarFormularios(listaDeFormulariosIniciados)
                                                                                .sort(ordenacaoDosFormularios)
                                                                                .filter(form => {
                                                                                    if (filtroDeFormularioCriaodPelaEmpresa === 0) return true;
                                                                                    return form.formularioId === filtroDeFormularioCriaodPelaEmpresa;
                                                                                })
                                                                                .map(form => (
                                                                                    <ItemDeFormulario
                                                                                        key={`f${form.formularioId}fi${form.formularioIniciadoId}`}
                                                                                        id={`f${form.formularioId}fi${form.formularioIniciadoId}`}
                                                                                        formulario={form}
                                                                                    />
                                                                                ))
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                }
                                            </React.Fragment>
                                }
                                
                            </div>
                        </div>
                    </div>
                </section>
                
                <section id="vigencias">
                    
                </section>
            </div>
        </CaixaDeConteudo>
    );
};

export default Inicio;