import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { UsuarioData } from './FetchUsuarios';
import { Layout } from '../Layout';
import { HttpService } from '../Shared/HttpService';
import { Loading } from '../Shared/Loading';
import { AuthService } from '../UserAuth/AuthService';
import { ValidationLabelMessage, ValidationAlertMessage } from '../Shared/ValidationMessage';
import { SetorData } from '../Setores/FetchSetor';
import { FuncaoData } from '../Funcoes/FetchFuncao';
import { PerfilData } from '../Perfil/FetchPerfil';
import $ from 'jquery';
import { Resource } from '../../index';
import { LanguageContext } from '../../context/LanguageContext';

interface RegraDataState {
    loading: number;
    regra: RegraData;
    setores: SetorData[];
    funcoes: FuncaoData[];
    perfis: PerfilData[];
    callBack: any;
}

export class RegraData {
    id: number = 0;
    usuariosid: number = 0;
    funcaoid: number = 0;
    setorid: number = 0;
    perfilid: number = 0;
    error: Array<any> = [];
    setor: SetorData = new SetorData;
    perfil: PerfilData = new PerfilData;
    funcao: FuncaoData = new FuncaoData;
}

export class AddRegra extends React.Component<any, RegraDataState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        var regra = new RegraData;
        regra.usuariosid = props.usuariosid;

        this.state = { loading: 3, regra: regra, setores: [], funcoes: [], perfis: [], callBack: props.callBack };

        Loading.showLoading(this);;

        //LISTA DE SETORES
        HttpService.fetch('api/SetorApi/ListSetores', { method: 'GET' })
            .then(response => { this.hideLoading(); return response.json() as Promise<SetorData[]> })
            .then(data => {

                this.setState({ setores: data });
            }).catch(() => {
                Loading.hideLoading(this);
            });

        //LISTA DE FUNCOES
        HttpService.fetch('api/FuncaoApi', { method: 'GET' })
            .then(response => { this.hideLoading(); return response.json() as Promise<FuncaoData[]> })
            .then(data => {

                this.setState({ funcoes: data });
            }).catch(() => {
                Loading.hideLoading(this);
            });

        //LISTA DE PERFIS
        HttpService.fetch('api/PerfilApi', { method: 'GET' })
            .then(response => { this.hideLoading(); return response.json() as Promise<PerfilData[]> })
            .then(data => {

                this.setState({ perfis: data });
            }).catch(() => {
                Loading.hideLoading(this);
            });

        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);
    }

    private hideLoading() {
        Loading.hideLoading(this);
    }

    // This will handle the submit form event.  
    private handleSave(event) {
        event.preventDefault();

        Loading.showLoading(this);
        HttpService.fetch('api/UsuarioxFuncaoxSetorxPerfilApi', {
            method: 'POST',
            body: HttpService.formToJson(event.target),
        }).then((response) => {
            return response.json() as Promise<RegraData>
        }).then((data) => {

            if ($.isEmptyObject(data.error)) {
                //assinatura aprovado
                this.setState({ regra: data })
                this.state.callBack.updateRegras();
                $('#vincularRegra [data-dismiss="modal"]').click();
                this.props.fecharModal();
                //alert('assinado');
            } else {
                this.setState({ regra: data })
                this.forceUpdate();
                this.props.fecharModal()
            }
            Loading.hideLoading(this);

        }).catch(() => {
            Loading.hideLoading(this);
        });
    }

    // Returns the HTML Form to the render() method.  
    public render() {
        return (
            <form className='w-100 d-flex flex-column p-4' onSubmit={this.handleSave} >
                <header>
                    <h4 className="text-center">{<Resource keyString="link_rule" />}</h4>
                </header>

                <main>
                    <input type="hidden" name="usuariosid" value={this.state.regra.usuariosid} />
                    
                    <div className="form-group">
                        <label>{<Resource keyString="sector" />}</label>
                        <ValidationLabelMessage errors={this.state.regra.error} name="Setorid">
                            <select className="form-control" data-val="true" name="setorId">
                                <option value="">{this.context.getLanguageResource("select_sector")}</option>
                                {this.state.setores.map(item =>
                                    <option key={item.id} value={item.id}>{item.nome}</option>
                                )}
                            </select>
                        </ValidationLabelMessage>
                    </div>
                    <div className="form-group">
                        <label>{<Resource keyString="function" />}</label>
                        <ValidationLabelMessage errors={this.state.regra.error} name="Funcaoid">
                            <select className="form-control" data-val="true" name="funcaoid">
                                <option value="">{this.context.getLanguageResource("select_function")}</option>
                                {this.state.funcoes.map(item =>
                                    <option key={item.id} value={item.id}>{item.nome}</option>
                                )}
                            </select>
                        </ValidationLabelMessage>
                    </div>
                    <div className="form-group">
                        <label className="required">{<Resource keyString="profile" />}</label>
                        <ValidationLabelMessage errors={this.state.regra.error} name="Perfilid">
                            <select className="form-control" data-val="true" name="Perfilid">
                                <option value="">{this.context.getLanguageResource("select_profile")}</option>
                                {this.state.perfis.map(item =>
                                    <option key={item.id} value={item.id}>{item.nome}</option>
                                )}
                            </select>
                        </ValidationLabelMessage>
                    </div>
                    <ValidationAlertMessage errors={this.state.regra.error} name="Summary" />
                </main>

                <footer className='d-flex justify-content-between'>
                    <button className="btn" style={{ backgroundColor: 'var(--ws-blue)' }} type="submit"><Resource keyString="save" /></button>
                    <button type="button" className="btn btn-outline-danger" data-dismiss="modal" onClick={() => this.props.fecharModal()}><i className='fa fa-times'></i> {<Resource keyString="close" />}</button>
                </footer>
            </form>
        )
    }

}  