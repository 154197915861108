import * as React from 'react';

type onClickType = (event: any) => any;

interface IProps {
  classeDeIcone?: string;
  children?: any;
  onClick?: onClickType;
  type: "button" | "submit" | "reset" | undefined;
  corDoIcone?: string;
  title: string;
  dataToggle?: string;
  dataTarget?: string;
  dataDismiss?: string;
  disabled?: boolean;
  componenteDeIcone?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const BotaoComIconePadrao = ({
  children,
  type,
  classeDeIcone,
  corDoIcone,
  onClick,
  title,
  dataTarget,
  dataToggle,
  dataDismiss,
  disabled,
  componenteDeIcone,
  style,
  className,
}: IProps) => {
  if (classeDeIcone && componenteDeIcone) {
    throw new Error('Não é possível passar classeDeIcone e componenteDeIcone ao mesmo tempo');
  } else if (classeDeIcone) {
    componenteDeIcone = false;
  } else if (componenteDeIcone) {
    classeDeIcone = '';
  } else {
    classeDeIcone = '';
  }
  const classeDoElementoI = `${classeDeIcone}`;
  const classeDoElementoSpan = children && 'ml-2';

  return (
    <button
      className={ `btn ${className}`}
      type={ type }
      onClick={ onClick }
      title={ title }
      style={ { height: '2.75rem', minWidth: '2.75rem', ...style } }
      data-toggle={ dataToggle }
      data-target={ dataTarget }
      disabled={ disabled }
      data-dismiss={ dataDismiss }
    >
      <p className='mb-0' style={ { color: corDoIcone ? corDoIcone : 'auto', lineHeight: '1.2rem', whiteSpace: 'nowrap' } }>
        { componenteDeIcone }
        <i className={ classeDoElementoI } style={ { fontSize: '1.2rem' } } />
        <span
          className={ classeDoElementoSpan }
        >
          { children }
        </span>
      </p>
    </button>
  );
}

export default BotaoComIconePadrao;
