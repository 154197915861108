import * as React from 'react';
import { ArquivoData } from './FetchArquivo';
import { HttpService } from '../Shared/HttpService';
import { Loading } from '../Shared/Loading';
import { LoginData } from '../UserAuth/Login';
import $ from 'jquery';
import { ValidationLabelMessage, ValidationAlertMessage } from '../Shared/ValidationMessage';
import { Resource } from '../../index';
import { LanguageContext } from '../../context/LanguageContext';

interface CompartilharArquivoDataState {
    id: string;
    arquivo: ArquivoData;
    login: LoginData;
    callBack: any;
}

export class CompartilharArquivo extends React.Component<any, CompartilharArquivoDataState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        // This will set state for Add User  
        this.state = {
            arquivo: props.arquivo,
            id: props.id,
            login: new LoginData,
            callBack: props.callBack,
        };

        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);
    }

    // This will handle the submit form event.  
    private handleSave(event) {
        event.preventDefault();

        var url = `api/ArquivosApi/Compartilhar/${this.state.arquivo.id}/${event.target.emailTo.value}`;

        Loading.showLoading(this);;

        HttpService.fetch(url, {
            method: 'POST',
            body: HttpService.formToJson(event.target),

        }).then((response) => {

            return response.json() as Promise<LoginData>;

        }).then((data) => {

            if ($.isEmptyObject(data.error)) {
                //assinatura aprovado
                this.setState({ login: new LoginData })
                this.state.callBack.updateArquivos();
                $('#' + this.state.id + ' [data-dismiss="modal"]').click();
                //alert('assinado');
            } else {
                this.setState({ login: data })
            }
            Loading.hideLoading(this);
            this.props.fecharModal();

        }).catch((responseJson) => {
            Loading.hideLoading(this);

        })
    }

    // Returns the HTML Form to the render() method.  
    public render() {
        const { getLanguageResource } = this.context;
        return (
            <form onSubmit={this.handleSave} >
                <div className="modal-header">
                    <h4 className="modal-title">{<Resource keyString="share" />}: {this.state.arquivo.versao}</h4>
                </div>

                <div className="modal-body">
                    <div className="form-group">
                        <label>{<Resource keyString="email" />}</label>
                        <ValidationLabelMessage errors={this.state.login.error} name="Email">
                            <input className="form-control" type="email" name="email" placeholder={getLanguageResource("email")} />
                        </ValidationLabelMessage>
                    </div>
                    <div className="form-group">
                        <label>{<Resource keyString="password" />}</label>
                        <ValidationLabelMessage errors={this.state.login.error} name="Senha" >
                            <input className="form-control" type="password" name="senha" placeholder={getLanguageResource("password")} />
                        </ValidationLabelMessage>
                    </div>
                    <div className="form-group">
                        <label>{<Resource keyString="send_email_to" />}:</label>
                        <input className="form-control" type="email" name="emailTo" placeholder="Enviar e-mail para" />
                    </div>
                    <ValidationAlertMessage errors={this.state.login.error} name="Summary" />
                </div>

                <div className="modal-footer">
                    <button className="btn ws-outline-blue" style={{ width: '2.75rem', height: '2.75rem' }} type="submit"><i className='fa fa-save'></i></button>
                    <button type="button" className="btn btn-outline-danger" style={{ width: '2.75rem', height: '2.75rem' }} onClick={this.props.fecharModal}><i className='fa fa-times'></i></button>
                </div>
            </form>
        )
    }

}  