import * as React from 'react';
import { HttpService } from '../Shared/HttpService';
import { Loading } from '../Shared/Loading';
import { SetorData } from '../Setores/FetchSetor';
import { VincularSetor } from './VincularSetor';
import { Resource } from '../../index';
import ModalWinSet from '../Shared/components/ModalWinSet';

interface ListaSetorVinculadoState {
    setorList: SetorData[];
    documentoid: number;
    diretorioid: number;
    modalDeVincularSetorEstaAberto: boolean;
    modalDeDeletarSetorEstaAberto: boolean;
    setorSelecionadoParaRemocao: SetorData | null;
}

export class ListaSetorVinculado extends React.Component<any, ListaSetorVinculadoState> {
    constructor(props) {
        super(props);
        
        this.state = { setorList: [], documentoid: props.documentoid, diretorioid: props.diretorioid, modalDeVincularSetorEstaAberto: false, modalDeDeletarSetorEstaAberto: false, setorSelecionadoParaRemocao: null };

        this.updateVinculos();

        this.handleDelete = this.handleDelete.bind(this);
    }

    public render() {
        const _auth = JSON.parse(localStorage.getItem('auth') as string);

        return (
            <div className='w-50 pr-2'>
                <header className='d-flex justify-content-between p-2 align-items-center' style={{ backgroundColor: 'var(--ws-blue-light)' }}>
                    <p className='mb-0'><Resource keyString="linked_sectors" /></p>
                    {
                        _auth.tipodeconta === 0
                            ?   (
                                    <button className="btn btn-outline-dark mr-2" title='Faça um upgrade para poder vincular setores' disabled><i className="fa fa-link"></i> {<Resource keyString="link" />}</button>
                                )
                            :   (
                                    <button className="btn btn-outline-dark mr-2" onClick={() => this.setState({ modalDeVincularSetorEstaAberto: true })}><i className="fa fa-link"></i> {<Resource keyString="link" />}</button>
                                )
                    }
                {
                    this.state.modalDeVincularSetorEstaAberto
                        ?   (
                                <ModalWinSet
                                    fecharModal={() => this.setState({ modalDeVincularSetorEstaAberto: false })}
                                >
                                    <VincularSetor documentoid={this.state.documentoid} diretorioid={this.state.diretorioid} callBack={this} fecharModal={() => this.setState({ modalDeVincularSetorEstaAberto: false })} />
                                </ModalWinSet>
                            )
                        :   <React.Fragment />
                }
                </header>
                {
                    this.state.setorList.length > 0
                        ?   this.renderUserTable(this.state.setorList)
                        :   <div className='w-100 py-4 text-center'>Não há setores vinculados</div>
                }
            </div>
        );
    }

    public updateVinculos() {
        Loading.showLoading(this);;
        var url = 'api/SetorApi/Documento/' + this.state.documentoid;
        if (this.state.diretorioid > 0) {
            url = 'api/SetorApi/Diretorio/' + this.state.diretorioid;
        }

        HttpService.fetch(url, {})
            .then(response => response.json() as Promise<SetorData[]>)
            .then(data => {
                this.setState({ setorList: data });
                Loading.hideLoading(this);
            });
    }

    private handleDelete(setorid: number, documentoid: number | undefined, diretorioid: number | undefined) {
        Loading.showLoading(this);
        var url = `api/DocumentosxSetoresApi/${setorid}/${documentoid}`;
        if (diretorioid && diretorioid > 0) {
            url = `api/DiretoriosxSetoresApi/${setorid}/${diretorioid}`;
        }
        HttpService.fetch(url, { method: 'DELETE' })
            .then(response => response.json() as Promise<boolean>)
            .then(data => {
                Loading.hideLoading(this);
                this.updateVinculos();
                this.setState({ modalDeDeletarSetorEstaAberto: false });
            }).catch(() => {
                Loading.hideLoading(this);
            });
    }

    private handleCallback(setorid, documentoid, diretorioid) {
        if (diretorioid != null &&  documentoid != null) {
            this.handleDelete(setorid, documentoid, diretorioid);
        }
        else if (documentoid != null) {
            this.handleDelete(setorid, documentoid, undefined);
        }
        else {
            this.handleDelete(setorid, undefined, diretorioid);
        }
    }

    // Returns the HTML table to the render() method.  
    private renderUserTable(setorList: SetorData[]) {
        return (
            <React.Fragment>
                <table className='table mt-3'>
                    <thead>
                        <tr>
                            <th className='border-0'>{<Resource keyString="sector_name" />}</th>
                            <th className='border-0'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {setorList.map(doc =>
                            <tr key={doc.id}>
                                <td className='align-middle'>{doc.nome}</td>
                                <td className='align-middle'>
                                    <button className="btn btn-outline-danger mr-2 pull-right"  style={{ width: '2.75rem', height: '2.75rem' }} onClick={() => this.setState({ modalDeDeletarSetorEstaAberto: true, setorSelecionadoParaRemocao: doc })}><i className='fa fa-trash'></i></button>
                                    {
                                        this.state.modalDeDeletarSetorEstaAberto && this.state.setorSelecionadoParaRemocao
                                            ?   (
                                                    <ModalWinSet
                                                        fecharModal={() => this.setState({ modalDeDeletarSetorEstaAberto: false, setorSelecionadoParaRemocao: null })}
                                                    >
                                                        <div className="modal-header">
                                                            <h4 className="modal-title"><Resource keyString="do_you_want_to_remove_the_linked_sector" /></h4>
                                                        </div>
                                                        <main className='px-3 mt-2'>
                                                            <p>{this.state.setorSelecionadoParaRemocao.nome}</p>
                                                        </main>
                                                        <div className="modal-footer border-top-0">
                                                            <button className="btn ws-outline-blue" style={{ width: '2.75rem', height: '2.75rem' }} type="button" onClick={() => this.handleCallback(this.state.setorSelecionadoParaRemocao?.id, this.state.documentoid, this.state.diretorioid)}><i className='fa fa-trash'></i></button>
                                                            <button type="button" style={{ width: '2.75rem', height: '2.75rem' }} className="btn btn-outline-danger" onClick={() => this.setState({ modalDeDeletarSetorEstaAberto: false, setorSelecionadoParaRemocao: null })}><i className='fa fa-times'></i></button>
                                                        </div>
                                                    </ModalWinSet>
                                                )
                                            :   <React.Fragment />
                                    }
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </React.Fragment>
        );
    }
}   