import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Layout } from '../Layout';
import axios from 'axios';
import { AuthService } from '../UserAuth/AuthService';

const URL_API = process.env.REACT_APP_URL_API;

export class HttpService {

    static fetch(url, options) {
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }

        if (AuthService.loggedIn()) {
            headers['Authorization'] = 'Bearer ' + AuthService.getStorage('token')
        }

        return fetch(URL_API + url, {
            headers,
            ...options
        });
    }

    static fetchFile(url, data) {
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        if (AuthService.loggedIn()) {
            config.headers['Authorization'] = 'Bearer ' + AuthService.getStorage('token')
        }

        return axios.post(URL_API + url, data, config);
    }

    static formToJson(data) {
        return JSON.stringify(HttpService.formatData(data));
    }

    static formToJsonWithAllData(data) {
        return JSON.stringify(HttpService.formatData(data, false));
    }

    static formatData(data, shouldValidateElement = true) {
        //https://code.lengstorf.com/get-form-values-as-json/
        //Converte um forma para o formato de JSON
        const isValidElement = element => {
            return element.name && ((shouldValidateElement && element.value) || !shouldValidateElement);
        };

        //BUG do VS? diz que n&#227;o existe include no tipo arr de string - o React tb n&#227;o compila
        //const isValidValue = element => {
        //    return (!['checkbox', 'radio'].includes(element.type) || element.checked);
        //};

        const isCheckbox = element => element.type === 'checkbox';
        const isRadioButton = element => element.type === 'radio';

        const isMultiSelect = element => element.options && element.multiple;

        const getSelectValues = options => [].reduce.call(options, (values: any, option: any) => {
            return option.selected ? values.concat(option.value) : values;
        }, []);

        const formToJSON = elements => {
            return [].reduce.call(elements, (data:any, element:any) => {

                // Make sure the element has the required properties and should be added.
                //if (isValidElement(element) && isValidValue(element)) {
                if (isValidElement(element)) {

                    /*
                     * Some fields allow for more than one value, so we need to check if this
                     * is one of those fields and, if so, store the values as an array.
                     */

                    if (isCheckbox(element)) {
                        data[element.name] = element.checked;
                    } else if (isRadioButton(element)) {
                        if (element.checked)
                            data[element.name] = element.value;
                    } else if (isMultiSelect(element)) {
                        data[element.name] = getSelectValues(element);
                    } else if (element.getAttribute("data-converterdecimal") != null) {
                        data[element.name] = element.value.toString().replace(".", "").replace(",", ".");
                    } else {
                        data[element.name] = element.value;
                    }
                }

                return data;
            }, {})
        };

        return formToJSON(data);
    }

    /*fetch(url, options){
      // performs api calls sending the required authentication headers
      const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
  
      if (this.loggedIn()){
        headers['Authorization'] = 'Bearer ' + this.getToken()
      }
  
      return fetch(url, {
        headers,
        ...options
      })
      .then(this._checkStatus)
      .then(response => response.json())
    }*/
}
