import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { HttpService } from '../../Shared/HttpService';
import { HeadLayout } from '../../Shared/HeadLayout';
import { Loading } from '../../Shared/Loading';
import { ModalDelete } from '../../Shared/ModalDelete';
import { ResultadoData } from './ResponderFormulario';
import { ResultadoXCabecalho, ResultadoXArquivo } from './FormularioRespondido';
import { LanguageContext } from '../../../context/LanguageContext';
import CaixaDeConteudo from '../../Shared/CaixaDeConteudo';
import { Resource } from '../../..';
import ModalWinSet from '../../Shared/components/ModalWinSet';
import { CupomData } from './AddFormulario';

interface FetchFormularioState {
    formularioList: FormularioData[];
    loading: boolean;
    modalDeDeletarEstaAberto: boolean;
    formularioSelecionadoParaDeletar: FormularioData | null;
}

export class FormularioData {
    id: number = 0;
    empresaid: number = 0;
    nome: string = "";
    descricao: string = "";
    valor: number = 0;
    toleranciapontos: number = 0;
    toleranciaporcentagem: number = 0;
    prazo: number = 0;
    datecreated: string = "";
    datemodified: string = '';
    distribuir: boolean = false;
    distribuirInterno: boolean = false;
    formularioXEmpresaId: number = 0;
    empresaQuePossuiOFormularioId: number = 0;
    showFormularioResultadoComFormula: boolean = false;
    respostaPadrao: string = "";
    modeloacao_Id: number = 0;
    createddatetime: string = "";
    usermodifiedid: number = 0;
    pontuacaoTotal: number = 0;
    resultados: Array<ResultadoData> = [];
    resultadoXArquivos: Array<ResultadoXArquivo> = [];
    resultadoXCabecalhos: Array<ResultadoXCabecalho> = [];
    error: Array<any> = [];
    permitiralterarnomedoformulario: boolean = false;
    cupons: Array<CupomData> = [];
    possuiesseformulario: boolean = false;
}

interface IPropsUserTableComponent {
    formularioList: FormularioData[];
    handleEdit: (id: number) => void;
    classe: FetchFormulario;
}

const SECONDS_POSITION_IN_TIME_STRING = -3;

export class FetchFormulario extends React.Component<RouteComponentProps<{}>, FetchFormularioState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = { formularioList: [], loading: true, modalDeDeletarEstaAberto: false, formularioSelecionadoParaDeletar: null };

        Loading.showLoading(this);;
        HttpService.fetch('api/FormularioAPI', {})
            .then(response => response.json() as Promise<FormularioData[]>)
            .then(data => {
                this.setState({ formularioList: data, loading: false });
                Loading.hideLoading(this);
            });

        this.handleDelete = this.handleDelete.bind(this);
        this.handleEdit = this.handleEdit.bind(this);

    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;

        return (
            <CaixaDeConteudo
                tituloDoHeader={this.context.getLanguageResource("forms")}
                botoes={[
                    <button className='btn bg-white shadow-sm mr-2' onClick={() => window.location.href = '/formulario/add'}><Resource keyString="add" /></button>
                ]}
                itensParaBreadcrumb={[
                    { nome: this.context.getLanguageResource("home"), url: "/" },
                    { nome: this.context.getLanguageResource("forms") }
                ]}
            >
                <div className='table-responsive-md px-4 py-2'>
                    <table className='table'>
                        <thead>
                            <tr className='d-flex'>
                                <th className='border-top-0 py-3 col-2'>{ this.context.getLanguageResource("form_name") }</th>
                                <th className='border-top-0 py-3 col-4'>{ this.context.getLanguageResource("description") }</th>
                                <th className='border-top-0 py-3 text-center col-2'>{ this.context.getLanguageResource('creation') }</th>
                                <th className='border-top-0 py-3 text-center col-2'>{ this.context.getLanguageResource('last_modified') }</th>
                                <th className='border-top-0 py-3 text-center col-2'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.formularioList.map((doc, indexPosition) => (
                                    <tr
                                        key={doc.id}
                                        className='d-flex'
                                    >
                                        <td className='align-middle col-2'>{doc.nome}</td>
                                        <td className='align-middle col-4'>{doc.descricao}</td>
                                        <td className='align-middle text-center col-2'>{ doc.datecreated.slice(0, SECONDS_POSITION_IN_TIME_STRING) }</td>
                                        <td className='align-middle text-center col-2'>{ doc.datemodified.slice(0, SECONDS_POSITION_IN_TIME_STRING) }</td>
                                        <td className='align-middle flex-row justify-content-end d-flex col-2'>
                                            <button
                                                className="btn btn-outline-primary mr-2"
                                                style={{ width: '2.75rem', height: '2.75rem' }}
                                                onClick={() => this.handleEdit(doc.id)}
                                            >
                                                <i className='fa fa-edit' />
                                            </button>
                                            <button
                                                className="btn btn-outline-danger"
                                                style={{ width: '2.75rem', height: '2.75rem' }}
                                                onClick={() => this.setState({ modalDeDeletarEstaAberto: true, formularioSelecionadoParaDeletar: doc })}
                                            >
                                                <i className='fa fa-trash' />
                                            </button>
                                            {
                                                this.state.modalDeDeletarEstaAberto && this.state.formularioSelecionadoParaDeletar != null && this.state.formularioSelecionadoParaDeletar.id == doc.id
                                                    ?   (
                                                            <ModalWinSet
                                                                fecharModal={() => this.setState({ modalDeDeletarEstaAberto: false, formularioSelecionadoParaDeletar: null })}
                                                            >
                                                                <div className='p-4'>
                                                                    <header>
                                                                        <h5>{this.context.getLanguageResource("do_you_want_to_remove_the_form")}:</h5>
                                                                    </header>
                                                                    <hr />
                                                                    <main className='mt-3'>
                                                                        <p>{this.state.formularioSelecionadoParaDeletar.nome}</p>
                                                                    </main>
                                                                    <footer className='d-flex justify-content-end'>
                                                                        <button className='btn mr-2' style={{ backgroundColor: 'var(--ws-blue)' }} onClick={() => this.handleDelete(doc.id)}>{this.context.getLanguageResource('yes')}</button>
                                                                        <button className='btn btn-outline-danger' onClick={() => this.setState({ modalDeDeletarEstaAberto: false, formularioSelecionadoParaDeletar: null })}>{this.context.getLanguageResource('no')}</button>
                                                                    </footer>
                                                                </div>
                                                            </ModalWinSet>
                                                        )
                                                    :   <React.Fragment />
                                            }
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </CaixaDeConteudo>
        );
    }

    // Handle Delete request for an user  
    private handleDelete(id: number) {
            Loading.showLoading(this);;
            HttpService.fetch('api/FormularioAPI/' + id, {
                method: 'delete'
            }).then(data => {
                this.setState(
                    {
                        formularioList: this.state.formularioList.filter((rec) => {
                            return (rec.id != id);
                        })
                    });
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            });
    }

    private handleEdit(id: number) {
        this.props.history.push("/formulario/edit/" + id);
    }
}   