import { HttpService } from 'components/Shared/HttpService';
import EmpresasERotas from '../assets/json/empresasERotas.json';

export const aRotaPertenceAoAcelerAgora = () => {
    // verificar o que está escrito entre http:// ou https:// e .winset.com.br
    const url = window.location.href as string;
    const regex = /https?:\/\/([^.]*)/;
    // @ts-ignore
    const match = url.match(regex) ? url.match(regex)[1] : null;

    // transformar o json em array
    const empresasERotas = JSON.parse(JSON.stringify(EmpresasERotas)) as Array<any>;
    if (empresasERotas.length === 0) return false;
    return empresasERotas.some(empresa => match?.includes(empresa.nome));
};
