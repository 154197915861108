interface IEstruturaDaMensagem {
	codigoParaResourceDeIdioma: string;
	padraoDeCorBootstrap: 'success' | 'danger' | 'warning' | 'info' | 'primary' | 'secondary' | 'light' | 'dark';
}

interface IMensagensDeAlerta {
	[key: string]: IEstruturaDaMensagem;
}

const MSG: IMensagensDeAlerta = {
	salvoComSucesso: {
		codigoParaResourceDeIdioma: 'saved_successfully',
		padraoDeCorBootstrap: 'success',
	},
	ocorreuUmErroAoSalvar: {
		codigoParaResourceDeIdioma: 'an_error_occurred_while_trying_to_save_contact_support',
		padraoDeCorBootstrap: 'danger',
	},
	respondaTodasAsPerguntasAntesDeFinalizar: {
		codigoParaResourceDeIdioma: 'please_answer_all_questions_before_completing_the_form',
		padraoDeCorBootstrap: 'warning'
	},
	respondaTodasAsEvidenciasDeAuditoria: {
		codigoParaResourceDeIdioma: 'please_answer_all_audit_evidence',
		padraoDeCorBootstrap: 'danger'
	},
	respondaOsPlanosDeAcao: {
		codigoParaResourceDeIdioma: 'please_answer_all_required_action_plan_fields',
		padraoDeCorBootstrap: 'danger'
	},
	respondaOCabecalho: {
		codigoParaResourceDeIdioma: 'please_answer_the_mandatory_header',
		padraoDeCorBootstrap: 'danger'
	},
	respondaORodape: {
		codigoParaResourceDeIdioma: 'please_answer_the_mandatory_footer',
		padraoDeCorBootstrap: 'danger'
	},
	anexeAsEvidenciasObrigatorias: {
		codigoParaResourceDeIdioma: 'please_attach_evidence_in_fields_where_it_is_mandatory',
		padraoDeCorBootstrap: 'danger'
	},
	respondaOsCamposObrigatoriosDasAcoesIniciadas: {
		codigoParaResourceDeIdioma: 'please_answer_the_mandatory_fields_of_the_started_actions',
		padraoDeCorBootstrap: 'danger'
	},
}

export default MSG;
