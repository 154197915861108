import React from 'react';
import { AuthService } from '../UserAuth/AuthService';
import { Authorize } from './Authorize';
import Logo from '../../assets/svg/logo.svg';
import SideMenuIcon from '../../assets/icons/side-menu-icon.svg';
import CaixaDeIniciais from './components/CaixaDeIniciais';
import GrupoDeLinksDeNavegacao from './components/GrupoDeLinksDeNavegacao';
import LinkDeNavegacao from './components/LinkDeNavegacao';
import { LanguageContext } from '../../context/LanguageContext';
import ChatIcon from '../../assets/icons/chat-icon.svg';
import PapelIcon from '../../assets/icons/papel.svg';
import AnalisarIcon from '../../assets/icons/analisar-icon.svg';
import DocumentarIcon from '../../assets/icons/documentar-icon.svg';
import CadastrarIcon from '../../assets/icons/cadastrar-icon.svg';
import UpgradeIcon from '../../assets/icons/upgrade-icon.svg';
import ArrowSidebarButton from '../../assets/icons/arrow-sidebar-button.svg';
import ModeloPlanoAcaoIcon from '../../assets/icons/modelo-plano-acao-icon.svg';
import ModeloRespostaIcon from '../../assets/icons/modelo-resposta-icon.svg';
import PerfilIcon from '../../assets/icons/perfil-icon.svg';
import UsuariosIcon from '../../assets/icons/usuarios-icon.svg';
import FormularioNavbarIcon from '../../assets/icons/formulario-navbar-icon.svg';
import FormulariosNavbarIcon from '../../assets/icons/formularios-navbar-icon.svg';
import SetorIcon from '../../assets/icons/setor-icon.svg';
import CargoIcon from '../../assets/icons/cargo-icon.svg';
import LojaIcon from '../../assets/icons/loja-icon.svg';
import CompartilharIcon from '../../assets/icons/compartilhar-icon.svg';
import DashboarIcon from '../../assets/icons/dashboard-icon.svg';
import RelatorioIcon from '../../assets/icons/relatorio-icon.svg';
import EmpresasERotas from '../../assets/json/empresasERotas.json';
import { HttpService } from './HttpService';
import { Loading } from './Loading';
import { RegraData } from '../Usuarios/AddRegra';
import { GlobalContext } from '../../context/GlobalContext';
import { IVerificarPremiumResponse } from '../../interfaces/IVerificarPremiumResponse';
import ModalWinSet from './components/ModalWinSet';

interface IProps {
    aBarraLateralEstaAberta: boolean;
    perfis: string[];
    mouseEstaSobreOBotaoDeUsuario: boolean;
    modalDeAssinaturaEstaAberto: boolean;
    modalDeConvidarAmigoEstaAberto: boolean;
    statusPremium: IVerificarPremiumResponse;
    momentoDaRequisicaoDeAssinatura: MomentoDaRequisicaoDeAssinatura,
    textoDeStatusDaRequisicaoDeAssinatura: string;
    imagensDinamicas: { logo: string, icon: string };
}

enum MomentoDaRequisicaoDeAssinatura {
    Pendente,
    EmAndamento,
    Concluida,
    Falha,
}

const URLS_PERSONALIZADAS = {
    46: 'rcaceleragora',
    682: 'hmlaceleragora',
    742: 'aceleragora',
}

export class NavMenu extends React.Component<{}, IProps> {
    static contextType?: React.Context<any> = LanguageContext;
    constructor(props) {
        super(props);
        this.state = {
            aBarraLateralEstaAberta: false,
            perfis: [],
            mouseEstaSobreOBotaoDeUsuario: false,
            modalDeAssinaturaEstaAberto: false,
            modalDeConvidarAmigoEstaAberto: false,
            momentoDaRequisicaoDeAssinatura: MomentoDaRequisicaoDeAssinatura.Pendente,
            textoDeStatusDaRequisicaoDeAssinatura: '',
            imagensDinamicas: { logo: '', icon: '' },
            statusPremium: {
                possuiPeriodoDeTestesExtra: false,
                estaUsandoOPeriodoDeTestesExtra: false,
                temAssinaturaPaga: false,
                usouTodosOsTestesENaoPossuiAssinaturaPaga: false,
                primeiroLogin: '',
                dataDeUsoDoPeriodoDeTestesExtra: '',
                diasRestantesDePremium: 0,
            }
        };

        Loading.showLoading(this);
        
        const localStorageContent = localStorage.getItem('auth')
        if (!localStorageContent) return;
    
        const { id } = JSON.parse(localStorageContent);
    
        HttpService.fetch(`api/UsuarioxFuncaoxSetorxPerfilApi/Lista/${id}`, {})
            .then(response => response.json() as Promise<RegraData[]>)
            .then(data => {
                const listaDePerfis = data.map((regra) => regra.perfil.nome);
                this.setState({ perfis: listaDePerfis });
                Loading.hideLoading(this);
            }).catch((_e)=> {
                Loading.hideLoading(this);
            });
    }

    solicitarPeriodoDeTestesExtra = (): void => {
        this.setState({ momentoDaRequisicaoDeAssinatura: MomentoDaRequisicaoDeAssinatura.EmAndamento });
        HttpService.fetch('api/Authentication/Ativar15DiasExtraDeTeste', { method: 'GET' })
            .then(response => {
                if (response.status === 200) {
                    this.setState({ momentoDaRequisicaoDeAssinatura: MomentoDaRequisicaoDeAssinatura.Concluida, textoDeStatusDaRequisicaoDeAssinatura: this.context.getLanguageResource("extra_trial_period_successfully_activated") });
                    const timer = setTimeout(() => window.location.reload(), 1000);
                    return () => clearTimeout(timer);
                } else {
                    throw new Error();
                }
            })
            .catch((_e)=> {
                this.setState({ momentoDaRequisicaoDeAssinatura: MomentoDaRequisicaoDeAssinatura.Falha, textoDeStatusDaRequisicaoDeAssinatura: this.context.getLanguageResource("error_activating_extra_trial_period") });
            });
    }
    
    solicitarAtendimentoDoComercial = (): void => {
        this.setState({ momentoDaRequisicaoDeAssinatura: MomentoDaRequisicaoDeAssinatura.EmAndamento });
        HttpService.fetch('api/Authentication/SolicitarAssinatura', { method: 'GET' })
            .then(response => {
                if (response.status === 200) {
                    this.setState({ momentoDaRequisicaoDeAssinatura: MomentoDaRequisicaoDeAssinatura.Concluida, textoDeStatusDaRequisicaoDeAssinatura: this.context.getLanguageResource("message_sent_to_sales_team") });
                    const timer = setTimeout(() => window.location.reload(), 1000);
                    return () => clearTimeout(timer);
                } else {
                    throw new Error();
                }
            })
            .catch((_e)=> {
                this.setState({ momentoDaRequisicaoDeAssinatura: MomentoDaRequisicaoDeAssinatura.Falha, textoDeStatusDaRequisicaoDeAssinatura: this.context.getLanguageResource("error_requesting_sales_support") });
            });
    }

    montarUrlDeRegistroAfiliado = (): string => {
        if (URLS_PERSONALIZADAS[AuthService.getStorage('empresasid')]) return `${window.location.protocol}//${window.location.host}/` + "registergrj";
        return `${window.location.protocol}//${window.location.host}/` + "registergrj?empresasid=" + AuthService.getStorage('empresasid');
    }

    private aRotaEstaListadaNoJson() {
        // verificar o que está escrito entre http:// ou https:// e .winset.com.br
        const url = window.location.href as string;
        const regex = /https?:\/\/([^.]*)/;
        const match = url.match(regex) ? url.match(regex)[1] : null;

        // transformar o json em array
        const empresasERotas = JSON.parse(JSON.stringify(EmpresasERotas)) as Array<any>;
        if (empresasERotas.length === 0) return false;
        return empresasERotas.some(empresa => match?.includes(empresa.nome));
    }

    private retornaRotasDasImagensDaEmpresa() {
        const outraEmpresa = this.aRotaEstaListadaNoJson();
        const rotaAtual = window.location.href.match(/https?:\/\/([^.]*)/)[1];
        
        if (outraEmpresa) {
            const empresasERotas = JSON.parse(JSON.stringify(EmpresasERotas)) as Array<any>;
            const empresa = empresasERotas.find(empresa => rotaAtual.includes(empresa.nome));
            this.setState({ imagensDinamicas: { logo: empresa.logo, icon: empresa.icon } });
        }
    }

    componentDidMount(): void {
        this.retornaRotasDasImagensDaEmpresa();
        HttpService.fetch('api/Authentication/VerificarPremium', { method: 'GET' })
            .then(response => {
                if (response.status === 200) {
                    return response.json() as Promise<IVerificarPremiumResponse>;
                } else if (response.status === 401) {
                    window.location.href = '/login';
                    return Promise.reject();
                } else {
                    throw new Error();
                }
            })
            .then(data => this.setState({ statusPremium: data }))
            .catch((_e)=> console.log("Ocorreu um erro na verificação de plano da conta"));
    }
    
    render () {
        return (
            <GlobalContext.Consumer>
                {
                    ({ isSidebarOpened, setIsSidebarOpened, tipodeconta, afiliacao }) => (
                        <LanguageContext.Consumer>
                            {
                                ({ getLanguageResource }) => (
                                    <React.Fragment>
                                        <div
                                            className='d-none d-md-flex align-items-center h-100 _IMPRIMIR_OMITIR_'
                                            style={{
                                                position: 'fixed',
                                                top: '0',
                                                left: '0',
                                                zIndex: 59
                                            }}
                                        >
                                            <aside
                                                className="h-100 d-flex flex-column align-items-center shadow"
                                                style={{
                                                    width: isSidebarOpened ? 'var(--ws-navbar-width-aberta)' : 'var(--ws-navbar-width-fechada)',
                                                    backgroundColor: '#EFEFEF',
                                                    transition: 'width 0.3s ease-in-out'
                                                }}
                                            >
                                                <div className='w-100 px-4'>
                                                    <header
                                                        className={`w-100 d-flex align-items-center justify-content-center ${isSidebarOpened ? 'mx-2' : ''}`}
                                                        style={{ minHeight: '64px', gap: '10px' }}
                                                    >
                                                        <img
                                                            src={SideMenuIcon}
                                                            alt="Ícone de menu"
                                                            style={{ height: '20px', cursor: 'pointer' }}
                                                            onClick={() => setIsSidebarOpened(!isSidebarOpened)}
                                                        />
                                                        <div
                                                            className={`w-100 justify-content-center ${isSidebarOpened ? 'd-flex' : 'd-none'}`}
                                                        >
                                                            <img
                                                                src={this.aRotaEstaListadaNoJson() ? `${process.env.PUBLIC_URL}${this.state.imagensDinamicas.logo}` : Logo}
                                                                alt="Logo"
                                                                style={{ height: '41px', cursor: 'pointer' }}
                                                                onClick={() => window.location.href = '/'}
                                                            />
                                                        </div>
                                                    </header>

                                                    <hr className='border w-100 m-0' />

                                                    <section
                                                        className={`w-100 py-1 px-3 rounded d-flex align-items-center justify-content-${isSidebarOpened ? 'start' : 'center'} my-3`}
                                                        style={{
                                                            gap: "14px",
                                                            transition: 'background-color 0.3s ease-in-out',
                                                            cursor: 'pointer',
                                                            backgroundColor: this.state.mouseEstaSobreOBotaoDeUsuario ? 'var(--ws-blue-background)' : 'transparent',
                                                        }}
                                                        onClick={() => window.location.href = '/minhaConta'}
                                                        onMouseEnter={() => this.setState({ mouseEstaSobreOBotaoDeUsuario: true })}
                                                        onMouseLeave={() => this.setState({ mouseEstaSobreOBotaoDeUsuario: false })}
                                                    >
                                                        <CaixaDeIniciais nome={AuthService.getStorage('nome')} />
                                                        <div
                                                            className={`${isSidebarOpened ? '' : 'd-none'}`}
                                                        >
                                                            <p className='m-0 text-muted font-weight-bold'>{AuthService.getStorage('nome')}</p>
                                                            <span className='text-muted'><small className='font-weight-light'>{ this.state.perfis.join(', ') }</small></span>
                                                        </div>
                                                    </section>

                                                    <hr className='border w-100 m-0' />
                                                </div>

                                                <nav className='w-100 h-100 barra_de_rolagem_estilizada pt-3 px-4' style={{ overflowY: "auto" }}>
                                                    <div className='w-100'>
                                                        <LinkDeNavegacao
                                                            aBarraLateralEstaAberta={isSidebarOpened}
                                                            alterarEstadoDaBarraLateralPara={setIsSidebarOpened}
                                                            icone={<i className='bi bi-house text-dark' style={{ fontSize: '1.5rem' }} />}
                                                            texto={getLanguageResource("home")}
                                                            destino="/"
                                                        />
                                                        {
                                                            parseInt(AuthService.getStorage('id')) === 4
                                                                ?   <LinkDeNavegacao
                                                                        aBarraLateralEstaAberta={isSidebarOpened}
                                                                        alterarEstadoDaBarraLateralPara={setIsSidebarOpened}
                                                                        icone={DashboarIcon}
                                                                        texto="Dashboard"
                                                                        destino="/dashboard"
                                                                    />
                                                                :   <React.Fragment />
                                                        }

                                                        <GrupoDeLinksDeNavegacao
                                                            aBarraLateralEstaAberta={isSidebarOpened}
                                                            alterarEstadoDaBarraLateralPara={setIsSidebarOpened}
                                                            icone={ChatIcon}
                                                            texto={getLanguageResource("answerButton")}
                                                        >
                                                            <Authorize role="responder">
                                                                <LinkDeNavegacao
                                                                    aBarraLateralEstaAberta={isSidebarOpened}
                                                                    alterarEstadoDaBarraLateralPara={setIsSidebarOpened}
                                                                    icone={FormulariosNavbarIcon}
                                                                    texto={getLanguageResource("forms")}
                                                                    destino='/meusFormularios'
                                                                />
                                                                {
                                                                    afiliacao === null && (
                                                                        <>
                                                                            <LinkDeNavegacao
                                                                                aBarraLateralEstaAberta={isSidebarOpened}
                                                                                alterarEstadoDaBarraLateralPara={setIsSidebarOpened}
                                                                                icone={CompartilharIcon}
                                                                                texto={getLanguageResource("shareds")}
                                                                                destino='/formularioCompartilhado'
                                                                            />
                                                                            <LinkDeNavegacao
                                                                                aBarraLateralEstaAberta={isSidebarOpened}
                                                                                alterarEstadoDaBarraLateralPara={setIsSidebarOpened}
                                                                                icone={LojaIcon}
                                                                                texto={getLanguageResource("internal_store")}
                                                                                destino='/marketPlaceInterno'
                                                                            />
                                                                        </>
                                                                    )
                                                                }
                                                                <LinkDeNavegacao
                                                                    aBarraLateralEstaAberta={isSidebarOpened}
                                                                    alterarEstadoDaBarraLateralPara={setIsSidebarOpened}
                                                                    icone={LojaIcon}
                                                                    texto={getLanguageResource("store")}
                                                                    destino='/marketPlace'
                                                                />
                                                            </Authorize>
                                                        </GrupoDeLinksDeNavegacao>
                                                        
                                                        {
                                                            tipodeconta === 1
                                                                ?   afiliacao === null && (
                                                                        <GrupoDeLinksDeNavegacao
                                                                            aBarraLateralEstaAberta={isSidebarOpened}
                                                                            alterarEstadoDaBarraLateralPara={setIsSidebarOpened}
                                                                            icone={PapelIcon}
                                                                            texto={getLanguageResource("create")}
                                                                        >
                                                                            <Authorize role="manterformulario">
                                                                                <LinkDeNavegacao
                                                                                    aBarraLateralEstaAberta={isSidebarOpened}
                                                                                    alterarEstadoDaBarraLateralPara={setIsSidebarOpened}
                                                                                    icone={FormularioNavbarIcon}
                                                                                    texto={getLanguageResource("form")}
                                                                                    destino='/formulario'
                                                                                />
                                                                            </Authorize>

                                                                            <Authorize role="manterresposta">
                                                                                <LinkDeNavegacao
                                                                                    aBarraLateralEstaAberta={isSidebarOpened}
                                                                                    alterarEstadoDaBarraLateralPara={setIsSidebarOpened}
                                                                                    icone={ModeloRespostaIcon}
                                                                                    texto={getLanguageResource("response_model")}
                                                                                    destino='/grupoResposta'
                                                                                />
                                                                            </Authorize>

                                                                            <LinkDeNavegacao
                                                                                aBarraLateralEstaAberta={isSidebarOpened}
                                                                                alterarEstadoDaBarraLateralPara={setIsSidebarOpened}
                                                                                icone={ModeloPlanoAcaoIcon}
                                                                                texto={getLanguageResource("action_model")}
                                                                                destino='/modeloPlanoAcao'
                                                                            />
                                                                            <LinkDeNavegacao
                                                                                aBarraLateralEstaAberta={isSidebarOpened}
                                                                                alterarEstadoDaBarraLateralPara={setIsSidebarOpened}
                                                                                icone={ModeloPlanoAcaoIcon}
                                                                                texto={"Modelo de Cadastro"}
                                                                                destino='/modeloCadastro'
                                                                            />
                                                                        </GrupoDeLinksDeNavegacao>
                                                                    )
                                                                :   <React.Fragment />
                                                        }

                                                        {
                                                            tipodeconta === 1
                                                                ?   afiliacao === null && (

                                                                        <GrupoDeLinksDeNavegacao
                                                                            aBarraLateralEstaAberta={isSidebarOpened}
                                                                            alterarEstadoDaBarraLateralPara={setIsSidebarOpened}
                                                                            icone={AnalisarIcon}
                                                                            texto={getLanguageResource("analyze")}
                                                                        >
                                                                            <LinkDeNavegacao
                                                                                aBarraLateralEstaAberta={isSidebarOpened}
                                                                                alterarEstadoDaBarraLateralPara={setIsSidebarOpened}
                                                                                icone={RelatorioIcon}
                                                                                texto={getLanguageResource("sales_report")}
                                                                                destino='/vendas'
                                                                            />
                                                                            <LinkDeNavegacao
                                                                                aBarraLateralEstaAberta={isSidebarOpened}
                                                                                alterarEstadoDaBarraLateralPara={setIsSidebarOpened}
                                                                                icone={RelatorioIcon}
                                                                                texto={getLanguageResource("purchase_report")}
                                                                                destino='/compras'
                                                                            />
                                                                            <LinkDeNavegacao
                                                                                aBarraLateralEstaAberta={isSidebarOpened}
                                                                                alterarEstadoDaBarraLateralPara={setIsSidebarOpened}
                                                                                icone={RelatorioIcon}
                                                                                texto={getLanguageResource("response_report")}
                                                                                destino='/relatorioGeral'
                                                                            />
                                                                            <LinkDeNavegacao
                                                                                aBarraLateralEstaAberta={isSidebarOpened}
                                                                                alterarEstadoDaBarraLateralPara={setIsSidebarOpened}
                                                                                icone={RelatorioIcon}
                                                                                texto={getLanguageResource("actions_report")}
                                                                                destino='/relatorioAcoes'
                                                                            />
                                                                            <LinkDeNavegacao
                                                                                aBarraLateralEstaAberta={isSidebarOpened}
                                                                                alterarEstadoDaBarraLateralPara={setIsSidebarOpened}
                                                                                icone={RelatorioIcon}
                                                                                texto={getLanguageResource("sharing_report")}
                                                                                destino='/RelatorioFormularioCompartilhado'
                                                                            />

                                                                            { AuthService.getStorage('empresasid') == 260 && (
                                                                                <LinkDeNavegacao
                                                                                    aBarraLateralEstaAberta={isSidebarOpened}
                                                                                    alterarEstadoDaBarraLateralPara={setIsSidebarOpened}
                                                                                    icone={RelatorioIcon}
                                                                                    texto={"Rel. da Transportadora JBS"}
                                                                                    destino='/relatorioGeralTransportadora'
                                                                                />
                                                                            )}

                                                                            {
                                                                                AuthService.getStorage('id') === 274
                                                                                    ?   <LinkDeNavegacao
                                                                                            aBarraLateralEstaAberta={isSidebarOpened}
                                                                                            alterarEstadoDaBarraLateralPara={setIsSidebarOpened}
                                                                                            icone={DashboarIcon}
                                                                                            texto={"Dashboard"}
                                                                                            destino='/dashboardBiMachine'
                                                                                        />
                                                                                    :   AuthService.getStorage('id') === 887
                                                                                        ?   <LinkDeNavegacao
                                                                                                aBarraLateralEstaAberta={isSidebarOpened}
                                                                                                alterarEstadoDaBarraLateralPara={setIsSidebarOpened}
                                                                                                icone={DashboarIcon}
                                                                                                texto={"Dashboard"}
                                                                                                destino='/dashboardBiMachine'
                                                                                            />
                                                                                        :   AuthService.getStorage('id') === 44
                                                                                            ?   <LinkDeNavegacao
                                                                                                    aBarraLateralEstaAberta={isSidebarOpened}
                                                                                                    alterarEstadoDaBarraLateralPara={setIsSidebarOpened}
                                                                                                    icone={DashboarIcon} texto={"Dashboard"}
                                                                                                    destino='/dashboardBiMachine'
                                                                                                />
                                                                                            :   <React.Fragment />
                                                                            }

                                                                            { AuthService.getStorage('empresasid') === 4 && (
                                                                                <LinkDeNavegacao
                                                                                    aBarraLateralEstaAberta={isSidebarOpened}
                                                                                    alterarEstadoDaBarraLateralPara={setIsSidebarOpened}
                                                                                    icone={DashboarIcon}
                                                                                    texto={"Dashboard"}
                                                                                    destino='/dashboardBiPowerBI'
                                                                                />
                                                                            )}

                                                                            {
                                                                                AuthService.getStorage('empresasid') === 608 
                                                                                    ?   <LinkDeNavegacao
                                                                                            aBarraLateralEstaAberta={isSidebarOpened}
                                                                                            alterarEstadoDaBarraLateralPara={setIsSidebarOpened}
                                                                                            icone={DashboarIcon}
                                                                                            texto={"Dashboard"}
                                                                                            destino='/dashboardBiMachineComercialGRX'
                                                                                        />
                                                                                    :   AuthService.getStorage('empresasid') === 611
                                                                                        ?   <LinkDeNavegacao
                                                                                                aBarraLateralEstaAberta={isSidebarOpened}
                                                                                                alterarEstadoDaBarraLateralPara={setIsSidebarOpened}
                                                                                                icone={DashboarIcon}
                                                                                                texto={"Dashboard"}
                                                                                                destino='/dashboardBiMachineComercialGRX'
                                                                                            />
                                                                                        :   AuthService.getStorage('empresasid') === 615
                                                                                            ?   <LinkDeNavegacao
                                                                                                    aBarraLateralEstaAberta={isSidebarOpened}
                                                                                                    alterarEstadoDaBarraLateralPara={setIsSidebarOpened}
                                                                                                    icone={DashboarIcon}
                                                                                                    texto={"Dashboard"}
                                                                                                    destino='/dashboardBiMachineComercialGRX'
                                                                                                />
                                                                                            :   AuthService.getStorage('empresasid') === 627
                                                                                                ?   <LinkDeNavegacao
                                                                                                        aBarraLateralEstaAberta={isSidebarOpened}
                                                                                                        alterarEstadoDaBarraLateralPara={setIsSidebarOpened}
                                                                                                        icone={DashboarIcon}
                                                                                                        texto={"Dashboard"}
                                                                                                        destino='/dashboardBiMachineComercialGRX'
                                                                                                    />
                                                                                                :   <React.Fragment />
                                                                            }

                                                                            { AuthService.getStorage('empresasid') === 397 && (
                                                                                <LinkDeNavegacao
                                                                                    aBarraLateralEstaAberta={isSidebarOpened}
                                                                                    alterarEstadoDaBarraLateralPara={setIsSidebarOpened}
                                                                                    icone={DashboarIcon}
                                                                                    texto={"Dashboard"}
                                                                                    destino='/dashboardOperacao'
                                                                                    />
                                                                                )}
                                                                        </GrupoDeLinksDeNavegacao>
                                                                    )
                                                                :   <React.Fragment />
                                                        }

                                                        {
                                                            afiliacao === null && (
                                                                <LinkDeNavegacao
                                                                    aBarraLateralEstaAberta={isSidebarOpened}
                                                                    alterarEstadoDaBarraLateralPara={setIsSidebarOpened}
                                                                    icone={DocumentarIcon}
                                                                    texto={getLanguageResource("to_document")}
                                                                    destino='/repositorio/0'
                                                                />
                                                            )
                                                        }

                                                        <GrupoDeLinksDeNavegacao
                                                            aBarraLateralEstaAberta={isSidebarOpened}
                                                            alterarEstadoDaBarraLateralPara={setIsSidebarOpened}
                                                            icone={CadastrarIcon}
                                                            texto={getLanguageResource("register")}
                                                        >
                                                            {
                                                                tipodeconta === 1
                                                                    ?   afiliacao === null && (
                                                                            <React.Fragment>
                                                                                <Authorize role="manterperfil">
                                                                                    <LinkDeNavegacao
                                                                                        aBarraLateralEstaAberta={isSidebarOpened}
                                                                                        alterarEstadoDaBarraLateralPara={setIsSidebarOpened}
                                                                                        icone={PerfilIcon}
                                                                                        texto={getLanguageResource("profile")}
                                                                                        destino='/perfil'
                                                                                    />
                                                                                </Authorize>

                                                                                <Authorize role="mantersetor">
                                                                                    <LinkDeNavegacao
                                                                                        aBarraLateralEstaAberta={isSidebarOpened}
                                                                                        alterarEstadoDaBarraLateralPara={setIsSidebarOpened}
                                                                                        icone={SetorIcon}
                                                                                        texto={getLanguageResource("sector")}
                                                                                        destino='/setor'
                                                                                    />
                                                                                </Authorize>

                                                                                <Authorize role="manterfuncao">
                                                                                    <LinkDeNavegacao
                                                                                        aBarraLateralEstaAberta={isSidebarOpened}
                                                                                        alterarEstadoDaBarraLateralPara={setIsSidebarOpened}
                                                                                        icone={CargoIcon}
                                                                                        texto={getLanguageResource("office")}
                                                                                        destino='/funcao'
                                                                                    />
                                                                                </Authorize>
                                                                            </React.Fragment>
                                                                        )
                                                                    :   <React.Fragment />
                                                            }

                                                            <Authorize role="manterusuario">
                                                                <LinkDeNavegacao
                                                                    aBarraLateralEstaAberta={isSidebarOpened}
                                                                    alterarEstadoDaBarraLateralPara={setIsSidebarOpened}
                                                                    icone={UsuariosIcon}
                                                                    texto={getLanguageResource("user")}
                                                                    destino='/usuarios'
                                                                />
                                                            </Authorize>
                                                        </GrupoDeLinksDeNavegacao>
                                                    </div>
                                                    <footer className={`d-flex flex-column align-items-center w-100`}>
                                                        {
                                                            !this.state.statusPremium.temAssinaturaPaga && (
                                                                <section className='w-100'>
                                                                    <button
                                                                        className='d-flex justify-content-center align-items-center mt-5 p-3 w-100 border-0'
                                                                        style={{ backgroundColor: "var(--ws-blue)", cursor: "pointer", outline: "none" }}
                                                                        type='button'
                                                                        onClick={() => this.setState({ modalDeAssinaturaEstaAberto: true })}
                                                                    >
                                                                        <img src={UpgradeIcon} alt='Ícone de upgrade' />
                                                                        {
                                                                            isSidebarOpened && <span className='ml-3' style={{ fontWeight: "400", fontSize: "22px" }}>Upgrade</span>
                                                                        }
                                                                    </button>
                                                                </section>
                                                            )
                                                        }
                                                        
                                                        {
                                                            this.state.statusPremium.diasRestantesDePremium > 0 && (
                                                                <section className='mt-3 mb-5'>
                                                                    {
                                                                        isSidebarOpened
                                                                            ?   <small className='text-dark'><strong>{this.state.statusPremium.diasRestantesDePremium} dias</strong> restantes de teste</small>
                                                                            :   <small className='text-dark'><strong>{this.state.statusPremium.diasRestantesDePremium} dias</strong></small>
                                                                    }
                                                                </section>
                                                            )
                                                        }

                                                        <section className='w-100'>
                                                            <button
                                                                className='d-flex justify-content-center align-items-center mt-5 p-3 w-100 border-0'
                                                                style={{ backgroundColor: "#f3ba0c", cursor: "pointer", outline: "none" }}
                                                                type='button'
                                                                onClick={() => this.setState({ modalDeConvidarAmigoEstaAberto: true })}
                                                            >
                                                                <i className="bi bi-chat-right-quote-fill" style={{ fontSize: '1.2rem' }} />
                                                                {
                                                                    isSidebarOpened && <span className='ml-3' style={{ fontWeight: "400", fontSize: "1.2rem" }}>{getLanguageResource("invite_a_friend")}</span>
                                                                }
                                                            </button>
                                                        </section>
                                                    </footer>
                                                </nav>
                                                <div>
                                                    <small>{process.env.REACT_APP_VERSAO}</small>
                                                </div>
                                            </aside>
                                            <button
                                                className='bg-dark p-1 border-0'
                                                style={{ height: "84px", borderRadius: "0 14px 14px 0", outline: "none" }}
                                                onClick={() => setIsSidebarOpened(!isSidebarOpened)}
                                            >
                                                <img src={ArrowSidebarButton} alt="Seta" style={{ transform: isSidebarOpened ? 'rotate(180deg)' : '', transition: 'transform 550ms ease' }} />
                                            </button>
                                        </div>
                                        
                                        <div
                                            style={{ transition: 'width 0.3s ease-in-out', width: isSidebarOpened ? 'var(--ws-navbar-width-aberta)' : 'var(--ws-navbar-width-fechada)', }}
                                            className='mr-3 _IMPRIMIR_OMITIR_'
                                        />
                                        {
                                            this.state.modalDeConvidarAmigoEstaAberto && (
                                                <ModalWinSet fecharModal={() => this.setState({ modalDeConvidarAmigoEstaAberto: false })} modalStyle={{ minWidth: '1rem' }}>
                                                    <div className='d-flex flex-column align-items-center justify-content-center p-4'>
                                                        <img src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${this.montarUrlDeRegistroAfiliado()}`} alt='QR Code' />
                                                        { /* Colocar link em uma div com botão para copiar para a área de transferência */ }
                                                        <p className='m-4'>{this.montarUrlDeRegistroAfiliado()}</p>
                                                        <div className='d-flex align-items-center justify-content-center mt-3'>
                                                            <button
                                                                className='btn ws-outline-blue ml-2'
                                                                onClick={() => {
                                                                    navigator.clipboard.writeText(this.montarUrlDeRegistroAfiliado());
                                                                }}
                                                            >
                                                                {getLanguageResource("copy_link")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </ModalWinSet>
                                            )
                                        }
                                        {
                                            this.state.modalDeAssinaturaEstaAberto && (
                                                <ModalWinSet
                                                    fecharModal={() => this.setState({ modalDeAssinaturaEstaAberto: false })}
                                                >
                                                    <div className='d-flex flex-column align-items-center justify-content-center p-4'>
                                                        <div className="d-flex align-itens-center justify-content-center w-100">
                                                            {
                                                                this.state.statusPremium.possuiPeriodoDeTestesExtra && this.state.statusPremium.diasRestantesDePremium === 0 && (
                                                                    <button
                                                                        className='d-flex flex-column align-items-center justify-content-around w-50 btn ws-outline-blue m-2'
                                                                        style={{ minHeight: '20vh' }}
                                                                        onClick={() => this.solicitarPeriodoDeTestesExtra()}
                                                                    >
                                                                        <i className="bi bi-chevron-double-up" style={{ fontSize: '3rem', color: 'black' }} />
                                                                        <p className='mt-2 mb-0'>{this.context.getLanguageResource("activate_extra_trial_period")}</p>
                                                                    </button>
                                                                )
                                                            }
                                                            <button
                                                                className='d-flex flex-column align-items-center justify-content-around w-50 btn ws-outline-blue m-2'
                                                                style={{ minHeight: '20vh' }}
                                                                onClick={() => this.solicitarAtendimentoDoComercial()}
                                                            >
                                                                <i className="bi bi-cart4" style={{ fontSize: '3rem', color: 'black' }} />
                                                                <p className='mt-2 mb-0'>{this.context.getLanguageResource("request_sales_support")}</p>
                                                            </button>
                                                        </div>
                                                        <div className='d-flex align-items-center justify-content-center' style={{ minHeight: '3rem' }}>
                                                            {
                                                                this.state.momentoDaRequisicaoDeAssinatura === MomentoDaRequisicaoDeAssinatura.EmAndamento && (
                                                                    <div className="spinner-border text-primary" role="status">
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                this.state.momentoDaRequisicaoDeAssinatura === MomentoDaRequisicaoDeAssinatura.Concluida && (
                                                                    <p className='text-success'>{this.state.textoDeStatusDaRequisicaoDeAssinatura}</p>
                                                                )
                                                            }
                                                            {
                                                                this.state.momentoDaRequisicaoDeAssinatura === MomentoDaRequisicaoDeAssinatura.Falha && (
                                                                    <p className='text-danger'>{this.state.textoDeStatusDaRequisicaoDeAssinatura}</p>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </ModalWinSet>
                                            )
                                        }
                                    </React.Fragment>
                                )
                            }
                        </LanguageContext.Consumer>
                    )
                }
            </GlobalContext.Consumer>
        );
    }
}