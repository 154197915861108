import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink, Redirect } from 'react-router-dom';
import { Layout } from '../../Layout';
import { HttpService } from '../../Shared/HttpService';
import { HeadLayout } from '../../Shared/HeadLayout';
import { Loading } from '../../Shared/Loading';
import { ModalDelete } from '../../Shared/ModalDelete';
import { Resource } from '../../../index';
import { LanguageContext } from '../../../context/LanguageContext';
import CaixaDeConteudo from '../../Shared/CaixaDeConteudo';
import ModalWinSet from '../../Shared/components/ModalWinSet';

interface FetchVisualizarDadosModeloCadastroState {
    modeloCadastroVisualizarDadosList: ModeloCadastroVisualizarDadosData[];
    loading: boolean;
    modeloCadastroId: number;
    modeloCadastroColuna: Array<any>;
    modalDeDeletarEstaAberto: boolean;
    itemSelecionadoParaDeletar: number | null;
}

export class ModeloCadastroVisualizarDadosData {
    id: number = 0;
    empresasid: number = 0;
    agrupamentoid: number = 0;
    modelocadastroid: number = 0;
    modelocadastrocolunaid: number = 0;
    valor: string = "";
    isactive: boolean = true;
    datecreated: string = "";
    usercreatedid: number = 0;
    datemodified: string = "";
    usermodifiedid: number = 0;
    error: Array<any> = [];
}

export class FetchVisualizarDadosModeloCadastro extends React.Component<RouteComponentProps<{}>, FetchVisualizarDadosModeloCadastroState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        var modeloCadastroId = this.props.match.params["modeloCadastroId"];

        this.state = {
            modeloCadastroVisualizarDadosList: [],
            loading: true,
            modeloCadastroId: modeloCadastroId,
            modeloCadastroColuna: [],
            modalDeDeletarEstaAberto: false,
            itemSelecionadoParaDeletar: null,
        };

        Loading.showLoading(this);
        HttpService.fetch('api/ModeloCadastroVisualizarDadosApi/Listar/' + modeloCadastroId, { method: 'GET' })
            .then(response => response.json())
            .then(data => {
                this.setState({ modeloCadastroVisualizarDadosList: data, loading: false });
                this.forceUpdate();
                Loading.hideLoading(this);
            });

        this.handleEdit = this.handleEdit.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.getListarColunas(modeloCadastroId);
    }

    private getListarColunas(modeloCadastroId) {
        Loading.showLoading(this);
        HttpService.fetch('api/ModeloCadastroVisualizarDadosApi/ListarColunas/' + modeloCadastroId, { method: 'GET' })
            .then(response => response.json())
            .then(data => {
                this.setState({ modeloCadastroColuna: data, loading: false });
                this.forceUpdate();
                Loading.hideLoading(this);
            });
    }

    private handleEdit(agrupamentoid: number) {
        this.props.history.push("/modeloCadastroVisualizarDados/edit/" + agrupamentoid + "/" + this.state.modeloCadastroId);
    }

    private handleAdd(modeloCadastroId: number) {
        this.props.history.push("/modeloCadastroVisualizarDados/" + modeloCadastroId + "/add");
    }

    private handleDelete(agrupamentoid: number) {
        Loading.showLoading(this);
        HttpService.fetch('api/ModeloCadastroVisualizarDadosApi/' + agrupamentoid, { method: 'Delete' })
            .then(response => response.json())
            .then(data => {
                this.setState(
                    {
                        modeloCadastroVisualizarDadosList: this.state.modeloCadastroVisualizarDadosList.filter((rec) => {
                            return (rec.agrupamentoid != agrupamentoid);
                        })
                    });
                Loading.hideLoading(this);
            });
    }

    private handleCancel(e) {
        e.preventDefault();
        this.redirectPage();
    }

    private redirectPage() {
        this.props.history.push("/modeloCadastro");
    }

    private botoesDoCantoSuperiorDireito() {
        return (
            <button className='btn bg-white shadow-sm mr-2' onClick={() => this.handleAdd(this.state.modeloCadastroId)}>
                <Resource keyString="add" />
            </button>
        );
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;
        return (
            <CaixaDeConteudo
                tituloDoHeader='Modelos de Cadastros'
                botoes={this.botoesDoCantoSuperiorDireito()}
                itensParaBreadcrumb={[
                    { nome: this.context.getLanguageResource('home'), url: "/" },
                    { nome: 'Modelos de Cadastros' },
                ]}
            >
                <React.Fragment>
                    <header className='d-flex align-items-center justify-content-center w-100 p-4'>
                        <h3>Cadastros</h3>
                    </header>
                    <main className='p-4'>
                        {this.renderUserTable(this.state.modeloCadastroVisualizarDadosList)}
                    </main>
                </React.Fragment>
            </CaixaDeConteudo>
        );
    }

    private renderUserTable(modeloCadastroVisualizarDadosList: ModeloCadastroVisualizarDadosData[]) {
        return (
            <div style={{ overflow: 'auto' }}>
                <table className='table'>
                    <thead>
                        <tr>
                            {this.state.modeloCadastroColuna.map(doc =>
                                <th>{doc.nome}</th>
                            )}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.from(new Set(modeloCadastroVisualizarDadosList.map(x => x.agrupamentoid))).map(agrupamentoid =>
                                <tr>
                                    {this.state.modeloCadastroColuna.map(colunas =>
                                        this.montaValorDasColunas(modeloCadastroVisualizarDadosList,colunas, agrupamentoid)
                                    )}
                                    
                                    <td className='align-middle'>
                                        <div className='d-flex'>
                                            <button className="btn ws-outline-green-brown mr-2 pull-right" style={{ width: '2.75rem', height: '2.75rem' }} onClick={() => this.handleEdit(agrupamentoid)}><i className='fa fa-edit'></i></button>
                                            <button
                                                className="btn btn-outline-danger mr-2 pull-right"
                                                onClick={() => this.setState({ modalDeDeletarEstaAberto: true, itemSelecionadoParaDeletar: agrupamentoid })}
                                                style={{ width: '2.75rem', height: '2.75rem' }}
                                            >
                                                <i className='fa fa-trash'></i>
                                            </button>
                                        </div>
                                        
                                        {
                                            (this.state.modalDeDeletarEstaAberto && this.state.itemSelecionadoParaDeletar && (this.state.itemSelecionadoParaDeletar === agrupamentoid)) && (
                                                <ModalWinSet
                                                    fecharModal={() => this.setState({ modalDeDeletarEstaAberto: false, itemSelecionadoParaDeletar: null })}
                                                >
                                                    <div className='p-3'>
                                                        <header className='d-flex w-100 mb-2 justify-content-center'>
                                                            <h4>Você realmente deseja excluir o cadastro preenchido?</h4>
                                                        </header>
                                                        <hr />
                                                        <footer className='d-flex w-100 justify-content-end'>
                                                            <button className="btn mr-2 ws-outline-blue" onClick={() => this.handleDelete(agrupamentoid)}><Resource keyString="yes" /></button>
                                                            <button className="btn btn-outline-danger" onClick={() => this.setState({ modalDeDeletarEstaAberto: false, itemSelecionadoParaDeletar: null })}><Resource keyString="no" /></button>
                                                        </footer>
                                                    </div>
                                                </ModalWinSet>
                                            )
                                        }
                                    </td>
                                </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }

    public montaValorDasColunas(modeloCadastroVisualizarDadosList, colunas, agrupamentoid) {
        var listaPorAgrupamento = modeloCadastroVisualizarDadosList.filter(value => value.agrupamentoid == agrupamentoid);
        var valorColuna = listaPorAgrupamento.filter(value => value.modelocadastrocolunaid == colunas.id);

        if (valorColuna && valorColuna.length > 0) {
            return <td className='align-middle'>{valorColuna[0].valor}</td>
        }
        else {
            return <td className='align-middle'></td>
        }
    }
}
