function validarCNPJ(cnpj: string): boolean {
	// Remover caracteres não numéricos
	cnpj = cnpj.replace(/[^\d]/g, '');

	// Verificar se o CNPJ tem 14 dígitos
	if (cnpj.length !== 14) {
		return false;
	}

	// Verificar se todos os dígitos são iguais
	if (/^(\d)\1+$/.test(cnpj)) {
		return false;
	}

	// Calcular o primeiro dígito verificador
	let soma = 0;
	let peso = 5;
	for (let i = 0; i < 12; i++) {
		soma += parseInt(cnpj.charAt(i)) * peso;
		peso = peso === 2 ? 9 : peso - 1;
	}
	let resto = soma % 11;
	let digito1 = resto < 2 ? 0 : 11 - resto;

	// Calcular o segundo dígito verificador
	soma = 0;
	peso = 6;
	for (let i = 0; i < 13; i++) {
		soma += parseInt(cnpj.charAt(i)) * peso;
		peso = peso === 2 ? 9 : peso - 1;
	}
	resto = soma % 11;
	let digito2 = resto < 2 ? 0 : 11 - resto;

	// Verificar se os dígitos verificadores estão corretos
	if (cnpj.charAt(12) !== digito1.toString() || cnpj.charAt(13) !== digito2.toString()) {
		return false;
	}

	// CNPJ válido
	return true;
}

export default validarCNPJ;