import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { HttpService } from '../../Shared/HttpService';
import { HeadLayout } from '../../Shared/HeadLayout';
import { Loading } from '../../Shared/Loading';
import { RespostaData } from '../Resposta/FetchResposta';
import { ModalDelete } from '../../Shared/ModalDelete';
import { Resource } from '../../../index';
import { LanguageContext } from '../../../context/LanguageContext';
import CaixaDeConteudo from '../../Shared/CaixaDeConteudo';
import ModalWinSet from '../../Shared/components/ModalWinSet';

interface FetchGrupoRespostaState {
    grupoRespostaList: GrupoRespostaData[];
    loading: boolean;
    modalDeDeletarEstaAberto: boolean;
    itemSelecionadoParaDeletar: GrupoRespostaData | null;
}

export class GrupoRespostaData {
    id: number = 0;
    empresaid: number = 0;
    nome: string = "";
    descricao: string = "";
    resposta: Array<RespostaData> = new Array<RespostaData>();
    error: Array<any> = [];
}

export class FetchGrupoResposta extends React.Component<RouteComponentProps<{}>, FetchGrupoRespostaState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = { grupoRespostaList: [], loading: true, modalDeDeletarEstaAberto: false, itemSelecionadoParaDeletar: null };

        Loading.showLoading(this);;
        HttpService.fetch('api/GrupoRespostaAPI', {})
            .then(response => response.json() as Promise<GrupoRespostaData[]>)
            .then(data => {
                this.setState({ grupoRespostaList: data, loading: false });
                Loading.hideLoading(this);
            });

        this.handleDelete = this.handleDelete.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.botoesDoCantoSuperiorDireito = this.botoesDoCantoSuperiorDireito.bind(this);

    }

    // Handle Delete request for an user  
    private handleDelete(id: number) {
        Loading.showLoading(this);;
        HttpService.fetch('api/GrupoRespostaAPI/' + id, {
            method: 'delete'
            }).then(data => {
                this.setState(
                    {
                        grupoRespostaList: this.state.grupoRespostaList.filter((rec) => {
                            return (rec.id != id);
                        })
                    });
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            });
    }

    private handleEdit(id: number) {
        this.props.history.push("/grupoResposta/edit/" + id);
    }

    private botoesDoCantoSuperiorDireito() {
        return (
            <React.Fragment>
                <button
                    onClick={() => this.props.history.push('/grupoResposta/add')}
                    className="btn bg-white mr-2 shadow"
                >
                    {<Resource keyString="add" />}
                </button>
            </React.Fragment>
        );
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;

        return (
            <CaixaDeConteudo
                botoes={this.botoesDoCantoSuperiorDireito()}
                tituloDoHeader={this.context.getLanguageResource("response_model")}
                itensParaBreadcrumb={[
                    { url: "/", nome: this.context.getLanguageResource("home") },
                    { nome: this.context.getLanguageResource("response_model") },
                ]}
            >
                <div className='bg-white h-100'>
                    <h3 className="text-center p-4">{<Resource keyString="response_model" />}</h3>
                    <div className='mx-4'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>{<Resource keyString="default_response_model_name" />}</th>
                                    <th>{<Resource keyString="default_response_model_description" />}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.grupoRespostaList.map(doc =>
                                    <tr key={doc.id}>
                                        <td className='align-middle'>{doc.nome}</td>
                                        <td className='align-middle'>{doc.descricao}</td>
                                        <td>
                                            <button
                                                className="btn btn-outline-danger mr-2 pull-right"
                                                onClick={() => this.setState({ modalDeDeletarEstaAberto: true, itemSelecionadoParaDeletar: doc })}
                                                style={{ width: '2.75rem', height: '2.75rem' }}><i className='fa fa-trash'></i></button>
                                            {
                                                (this.state.modalDeDeletarEstaAberto && this.state.itemSelecionadoParaDeletar && (this.state.itemSelecionadoParaDeletar.id === doc.id)) && (
                                                    <ModalWinSet
                                                        fecharModal={() => this.setState({ modalDeDeletarEstaAberto: false, itemSelecionadoParaDeletar: null })}
                                                    >
                                                        <div className='p-3'>
                                                            <header className='d-flex w-100 mb-2 justify-content-center'>
                                                                <h4><Resource keyString="do_you_want_to_remove_the_group" />?</h4>
                                                            </header>
                                                            <hr />
                                                            <main>
                                                                <p>{doc.nome}</p>
                                                            </main>
                                                            <footer className='d-flex w-100 justify-content-end'>
                                                                <button className="btn mr-2 ws-outline-blue" onClick={() => this.handleDelete(doc.id)}><Resource keyString="yes" /></button>
                                                                <button className="btn btn-outline-danger" onClick={() => this.setState({ modalDeDeletarEstaAberto: false, itemSelecionadoParaDeletar: null })}><Resource keyString="no" /></button>
                                                            </footer>
                                                        </div>
                                                    </ModalWinSet>
                                                )
                                            }
                                            <button className="btn ws-outline-green-brown mr-2 pull-right" style={{ width: '2.75rem', height: '2.75rem' }} onClick={() => this.handleEdit(doc.id)}><i className='fa fa-edit'></i></button>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </CaixaDeConteudo>
        );
    }
}   