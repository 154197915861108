const retornarInteriorRecursivo = (array, chaveContendoFilhos) => {
    if (!array || array.length === 0) return [];

    const listaInterna = array.map((doc) => {
        const arrayInterno = doc[chaveContendoFilhos];
        const elementosInternosDoArray = retornarInteriorRecursivo(arrayInterno, chaveContendoFilhos);
        return [doc, ...elementosInternosDoArray];
    });

    return listaInterna.flat(Infinity);
}

export default retornarInteriorRecursivo;
