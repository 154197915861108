import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { HttpService } from '../Shared/HttpService';
import { Loading } from '../Shared/Loading';
import {  DocumentoData } from '../Documentos/FetchDocumentos';
import { DiretorioData } from '../Diretorios/FetchDiretorio';
import { Resource } from '../../index';
import { LanguageContext } from '../../context/LanguageContext';
import CaixaDeConteudo from '../Shared/CaixaDeConteudo';
import ArquivoIcon from '../../assets/icons/arquivo-icon.svg';
import PastaIcon from '../../assets/icons/pasta-icon.svg';

interface RepositorioDataState {
    repositorio: RepositorioData;
    loading: boolean;
    exibirArquivados: boolean;
    exibirMenuContexto: boolean;
    eventoDoMenuContexto: any;
}

export class RepositorioData {
    diretorios: DiretorioData[] = [];
    documentos: DocumentoData[] = [];
    diretorio: DiretorioData = new DiretorioData;
}

export class Repositorio extends React.Component<RouteComponentProps<{}>, RepositorioDataState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = {
            repositorio: new RepositorioData,
            loading: true,
            exibirArquivados: false,
            exibirMenuContexto: false,
            eventoDoMenuContexto: null,
        };

    }
    
    componentDidMount(): void {
        Loading.showLoading(this);
        var repositorioid = this.props.match.params["repositorioid"] > 0 ? this.props.match.params["repositorioid"] : 0;
    
        HttpService.fetch(`api/Repositorio/${repositorioid}`, {})
            .then(response => response.json() as Promise<RepositorioData>)
            .then(data => {
                this.setState({
                    repositorio: data, loading: false
                });
                Loading.hideLoading(this);
            });
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;
        
        const diretorios = this.state.repositorio.diretorios.filter(d => this.state.exibirArquivados ? !d.isactive : d.isactive);
        const documentos = this.state.repositorio.documentos.filter(d => this.state.exibirArquivados ? !d.isactive : d.isactive);

        return (
            <CaixaDeConteudo
                breadcrumbDinamico={{ id: this.state.repositorio.diretorio.id, tipo: 'diretorio' }}
                botoes={[
                    <button key='botao_add_document' className='btn bg-white shadow-sm mr-2' style={{ whiteSpace: 'nowrap' }} onClick={() => window.location.href = `/documentos/add/${this.state.repositorio.diretorio.id}`}><Resource keyString="add_document" /></button>,
                    <button key='botao_add_directory' className='btn btn-outline-dark shadow-sm mr-2' style={{ whiteSpace: 'nowrap' }} onClick={() => window.location.href = `/diretorio/add/${this.state.repositorio.diretorio.id}`}><Resource keyString="add_directory" /></button>,
                    <button key='botao_back' className='btn btn-outline-dark shadow-sm mr-2' style={{ whiteSpace: 'nowrap' }} onClick={() => window.history.back() }><Resource keyString="back" /></button>,
                ]}
                tituloDoHeader={this.context.getLanguageResource("to_document")}
            >
                <div className='d-flex justify-content-start p-2 border-bottom'>
                    <div className="custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input" id="exibirArquivados" checked={this.state.exibirArquivados} onChange={() => this.setState({ exibirArquivados: !this.state.exibirArquivados })} />
                        <label className="custom-control-label mb-0" htmlFor="exibirArquivados"><Resource keyString="show_archived" /></label>
                    </div>
                </div>
                {
                    this.state.repositorio.diretorio.id > 0
                        ?   (
                                <React.Fragment>
                                    <section className='p-4 border-bottom'>
                                        <h4 className="text-center">
                                            {this.state.repositorio.diretorio.nome}
                                        </h4>
                                    </section>
                                    <section className='p-4 border-bottom d-flex justify-content-end'>
                                        {
                                            this.state.repositorio.diretorio.id > 0
                                                ?   <button className='btn ws-outline-blue' onClick={() => window.location.href = `/diretorio/detail/${this.state.repositorio.diretorio.id}`}><i className='fa fa-folder'></i> {<Resource keyString="directory_details" />}</button>
                                                :   <React.Fragment />
                                        }
                                    </section>
                                </React.Fragment>
                            )
                        :   <React.Fragment />
                }
                <div>
                <div className="w-100 d-flex flex-wrap">
                        {
                            diretorios.map(dir =>
                                <button
                                    key={`dir${dir.id}`}
                                    onClick={() => window.location.href = `/repositorio/${dir.id}`}
                                    style={{ width: 'calc(33.33% - 1rem)', overflow: 'hidden' }} // 1rem = m-2, se alterar m-2, alterar aqui também
                                    className='p-2 m-2 btn rounded d-flex justify-content-start align-items-center ws-blue-highlight-menu-hover'
                                >
                                    <img className="mr-2" src={PastaIcon} alt={dir.nome} style={{ float: "left" }} />
                                    <p className='mb-0' style={{ whiteSpace: 'nowrap' }}>{dir.nome}</p>
                                </button>
                            )
                        }
                        {   documentos.map(doc =>
                                <button
                                    key={`doc${doc.id}`}
                                    onClick={() => window.location.href = `/documentos/detail/${doc.id}`}
                                    style={{ width: 'calc(33.33% - 1rem)', overflow: 'hidden' }} // 1rem = m-2, se alterar m-2, alterar aqui também
                                    className='p-2 m-2 btn rounded d-flex justify-content-start align-items-center ws-blue-highlight-menu-hover'
                                >
                                    <img className="mr-2" src={ArquivoIcon} alt={doc.nome} style={{ float: "left" }} />
                                    <p className='mb-0' style={{ whiteSpace: 'nowrap' }}>{doc.nome}</p>
                                </button>
                            )
                        }
                        {
                            diretorios.length === 0 && documentos.length === 0
                                ?   <div className='w-100 d-flex justify-content-center align-items-center p-4'>
                                        <p className='text-center'>Não há nada aqui</p>
                                    </div>
                                :   <React.Fragment />
                        }
                    </div>
                </div>
            </CaixaDeConteudo>
        );
    }

}   