import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { ModeloCadastroData } from './FetchModeloCadastro';
import { HeadLayout } from '../Shared/HeadLayout';
import { Layout } from '../Layout';
import { HttpService } from '../Shared/HttpService';
import $ from 'jquery';
import { Loading } from '../Shared/Loading';
import { Resource } from '../../index';
import { ValidationLabelMessage } from '../Shared/ValidationMessage';
import { FetchModeloCadastroColuna } from './ModeloCadastroColuna/FetchModeloCadastroColuna';
import { LanguageContext } from '../../context/LanguageContext';
import CaixaDeConteudo from '../Shared/CaixaDeConteudo';

interface AddModeloCadastroState {
    title: string;
    loading: boolean;
    empData: ModeloCadastroData;
}

export class AddModeloCadastro extends React.Component<RouteComponentProps<{}>, AddModeloCadastroState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        this.state = { title: "Editar Modelo de Cadastro", loading: true, empData: new ModeloCadastroData };

        var modeloCadastroId = this.props.match.params["modeloCadastroId"];

        // This will set state for Edit User  
        if (modeloCadastroId > 0) {

            Loading.showLoading(this);

            HttpService.fetch('api/ModeloCadastroApi/' + modeloCadastroId, { method: 'GET' })
                .then(response => response.json() as Promise<ModeloCadastroData>)
                .then(data => {
                    this.setState({ title: "Editar Modelo de Cadastro", loading: false, empData: data });
                    Loading.hideLoading(this);
                }).catch(() => {
                    Loading.hideLoading(this);
                    props.history.push("/modeloPlanoAcao");
                });
        }

        // This will set state for Add User  
        else {
            this.state = { title: "Criar Modelo de Cadastro", loading: false, empData: new ModeloCadastroData };
        }


        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    private handleCancel(e) {
        e.preventDefault();
        this.redirectPage();
    }

    private redirectPage() {
        this.props.history.push("/modeloCadastro");
    }

    private handleSave(event) {
        event.preventDefault();

        let modeloCadastroId = this.state.empData.id;

        Loading.showLoading(this);;

        // PUT request for Edit User.  
        if (modeloCadastroId) {

            HttpService.fetch('api/ModeloCadastroApi/' + modeloCadastroId, {
                method: 'PUT',
                body: HttpService.formToJson(event.target),
            }).then((response) => {
                return response.json() as Promise<ModeloCadastroData>
            }).then((data) => {

                this.setState({ empData: data })
                if ($.isEmptyObject(data.error)) {
                    this.redirectPage();
                }
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            })

        } else {

            HttpService.fetch('api/ModeloCadastroApi/Salvar', {
                method: 'POST',
                body: HttpService.formToJson(event.target),
            }).then((response) => {
                return response.json() as Promise<ModeloCadastroData>
            }).then((data) => {

                this.setState({ empData: data })
                if ($.isEmptyObject(data.error)) {
                    this.redirectPage();
                }
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            })
        }
    }

    private botoesDoCantoSuperiorDireito() {
        return (
            <React.Fragment>
                <button
                    className='btn bg-white shadow-sm mr-2'
                    onClick={() => window.location.href = `/modeloCadastroColuna/add/${this.state.empData.id}`}
                    disabled={!this.state.empData.nome}
                    title={this.state.empData.nome ? this.context.getLanguageResource("add") : this.context.getLanguageResource("saving_is_required_before_you_can_add_fields")}
                >
                    <Resource keyString="add" />
                </button>
                <button className="btn btn-outline-dark shadow-sm mr-2" onClick={this.handleCancel}>{<Resource keyString="back" />}</button>
            </React.Fragment>
        );
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;

        return (
            <CaixaDeConteudo
                tituloDoHeader="Modelo de Cadastro"
                botoes={this.botoesDoCantoSuperiorDireito()}
                itensParaBreadcrumb={[
                    { url: "/", nome: this.context.getLanguageResource("home") },
                    { url: "/modeloCadastro", nome: 'Modelo de Cadastro' },
                    { nome: this.state.empData.nome ? this.state.empData.nome : 'Novo Modelo de Cadastro' }
                ]}
            >
                <main className='p-4'>
                    {this.renderCreateForm()}
                    {
                        this.state.empData.id > 0
                            ?   <FetchModeloCadastroColuna modeloCadastroid={this.state.empData.id} />
                            :   <React.Fragment />
                    }
                </main>
            </CaixaDeConteudo>
        );
    }

    private renderCreateForm() {
        return (<form onSubmit={this.handleSave} >
            <div className="form-group row" >
                <input type="hidden" name="id" value={this.state.empData.id} />
            </div>
            <div className="form-group row">
                <div className="col-md-6">
                    <label className="control-label col-md-12 required" htmlFor="nome" >Nome do Modelo de Cadastro</label>
                    <div className="col-md-12">
                        <ValidationLabelMessage errors={this.state.empData.error} name="nome">
                            <input className="form-control" type="text" id="nome" name="nome" defaultValue={this.state.empData.nome} />
                        </ValidationLabelMessage>
                    </div>
                </div>
            </div >
            <div className="form-group col-md-12" >
                <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" name="isactive" id="isactive" defaultChecked={this.state.empData.isactive} />
                    <label className="custom-control-label" htmlFor="isactive">{<Resource keyString="active" />}</label>
                </div>
            </div>
            <div className="form-group col-12 d-flex justify-content-center">
                <button type="submit" className="btn font-weight-bold" style={{ backgroundColor: 'var(--ws-blue)', width: '17.5rem' }}>{<Resource keyString="save" />}</button>
            </div >
        </form >)
    }
}