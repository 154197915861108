import React, { useState, useRef, useEffect } from 'react';
import { Resource } from '../../..';
import { DiretorioData } from '../../Diretorios/FetchDiretorio';

/**
 * Propriedades do componente SelectDeDiretorio 
**/
interface IProps {
    diretorios: DiretorioData[];
    diretorioSelecionado: number;
    name: string;

    /**
     * O id do diretorio que está sendo editado. Só deve ser setado se o select estiver sendo usado em um form de edição de DIRETÓRIO, caso
     * seja um DOCUMENTO não é necessário utilizar essa propriedade.
     * 
     * @default null
     * @type {number}
     * @memberof IProps
     * @example
     * 
     *  <SelectDeDiretorio
     *      diretorios={this.state.diretorios}
     *      diretorioSelecionado={this.state.empData.diretoriospaiid}
     *      name={"diretoriospaiid"}
     *      meuId={this.state.empData.id}
     *  />
    **/
    meuId?: number;
}

/**
 * Componente que simula um select de diretórios, ele recebe um array de diretórios e renderiza recursivamente, exibindo a hierarquia de diretórios.
 * 
 * @param {IProps} props
 * @returns {JSX.Element}
 * @example
 * 
 * <SelectDeDiretorio
 *      diretorios={this.state.diretorios}
 *      diretorioSelecionado={this.state.empData.diretoriospaiid}
 *      name={"diretoriospaiid"}
 *      meuId={this.state.empData.id}
 * />
**/
export const SelectDeDiretorio = ({ diretorios, diretorioSelecionado, name, meuId }: IProps) => {
    const [itemSelecionado, setItemSelecionado] = useState<DiretorioData>(diretorios.find(diretorio => diretorio.id === diretorioSelecionado) || new DiretorioData());
    const [listaEstaAberta, setListaEstaAberta] = useState(false);
    const [widthDaLista, setWidthDaLista] = useState(0);
    const divRef = useRef<HTMLDivElement>(null);

    const handleChange = (event, diretorio: DiretorioData) => {
        event.stopPropagation();
        setItemSelecionado(diretorio);
        setListaEstaAberta(false);
    }

    const renderizarDiretoriosRecursivamente = (diretorios: DiretorioData[], idDoDiretorioPai: number | null) => {
        return (
            <React.Fragment>
                {
                    diretorios
                        .filter(diretorio => diretorio.diretoriospaiid === idDoDiretorioPai)
                        .map(diretorio => {
                            if (meuId === diretorio.id) return null;
                            return (
                                <li
                                    id={`diretorio-id-${diretorio.id}`}
                                    key={diretorio.id}
                                    className={`list-group-item border-0 ${diretorio.diretoriospaiid !== null ? "pl-4 pr-0 py-1" : "px-0 py-0"}`}
                                    onClick={(e) => handleChange(e, diretorio)}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <div
                                        className='text-truncate px-2 py-1 rounded'
                                        onMouseEnter={(e) => {
                                            e.currentTarget.style.backgroundColor = diretorio.id === itemSelecionado.id ? "var(--ws-blue-background)" : "#f7f7f7";
                                        }}
                                        onMouseLeave={(e) => {
                                            e.currentTarget.style.backgroundColor = diretorio.id === itemSelecionado.id ? "var(--ws-blue-background)" : "#fff";
                                        }}
                                        style={{
                                            backgroundColor: diretorio.id === itemSelecionado.id ? "var(--ws-blue-background)" : "#fff",
                                        }}
                                    >
                                        {
                                            diretorio.id === itemSelecionado.id
                                                ?   <i className="bi bi-folder-fill mr-2" />
                                                :   <i className="bi bi-folder mr-2" />
                                        }
                                        {
                                            diretorio.id === itemSelecionado.id
                                                ?   <strong>{diretorio.nome}</strong>
                                                :   <span>{diretorio.nome}</span>
                                        }
                                    </div>
                                    {
                                        diretorios.filter(d => d.diretoriospaiid === diretorio.id).length > 0
                                            ?   (
                                                    <React.Fragment>
                                                        {
                                                            renderizarDiretoriosRecursivamente(diretorios, diretorio.id)
                                                        }
                                                    </React.Fragment>
                                                )
                                            :   <React.Fragment />
                                    }
                                </li>
                            );
                        })
                }
            </React.Fragment>
        )
    }

    useEffect(() => {
        // Esse useEffect é para que a lista tenha o mesmo width da div que simula um select
        // ele atualiza o widthDaLista sempre que o width do divRef muda
        // acompanhando cada frame de animação

        let animationFrameId: null | number = null;
    
        const handleResize = () => {
          if (divRef.current) {
            const newClientWidth = divRef.current.clientWidth;
            if (newClientWidth !== widthDaLista) {
                setWidthDaLista(newClientWidth);
            }
          }
          animationFrameId = requestAnimationFrame(handleResize);
        };
    
        handleResize();
    
        return () => {
          cancelAnimationFrame(animationFrameId as number);
        };
    }, []);

    return (
        <div className='w-100'>
            <input type="hidden" name={name} value={itemSelecionado.id} />
            <div
                className='d-flex p-2 align-items-center border rounded w-100 text-truncate'
                ref={divRef}
                style={{
                    cursor: "pointer",
                    position: 'relative'
                }}
                onClick={() => setListaEstaAberta(!listaEstaAberta)}
            >
                {itemSelecionado.nome ? itemSelecionado.nome : <Resource keyString="none" />}
                <i className={`bi bi-chevron-${listaEstaAberta ? "up" : "down"} ml-auto`} />
            </div>
            <div
                className={`${listaEstaAberta ? "d-flex flex-column" : "d-none"} border rounded`}
                style={{
                    position: "absolute",
                    backgroundColor: "#fff",
                    zIndex: 10,
                    maxHeight: "12rem",
                    overflowY: "auto",
                    width: widthDaLista,
                }}
            >
                <ul className="list-group p-0 p-2">
                    <li
                        id={`diretorio-id-${null}`}
                        key={0}
                        className={`list-group-item border-0 px-0 py-0`}
                        onClick={(e) => handleChange(e, new DiretorioData())}
                        style={{
                            cursor: "pointer",
                        }}
                    >
                        <div
                            className='text-truncate px-2 py-1 rounded'
                            onMouseEnter={(e) => {
                                e.currentTarget.style.backgroundColor = 0 === itemSelecionado.id ? "var(--ws-blue-background)" : "#f7f7f7";
                            }}
                            onMouseLeave={(e) => {
                                e.currentTarget.style.backgroundColor = 0 === itemSelecionado.id ? "var(--ws-blue-background)" : "#fff";
                            }}
                            style={{
                                backgroundColor: itemSelecionado.id === 0 ? "var(--ws-blue-background)" : "#fff",
                            }}
                        >
                            <i className="bi bi-folder-x mr-2" />
                            {
                                0 === itemSelecionado.id
                                    ?   <strong><Resource keyString="none" /></strong>
                                    :   <Resource keyString="none" />
                            }
                        </div>
                    </li>
                    {
                        renderizarDiretoriosRecursivamente(diretorios, null)
                    }
                </ul>
            </div>
        </div>
    );
};
