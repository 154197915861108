import * as React from 'react';
import { Resource } from '../../../../../index';

const PlanoDeAcaoModeloPadrao = ({ acao }) => {
  return (
    <div className="mt-2 col-sm-12">
      <div className="row justify-content-center">
          <div className="col-sm-11 border">
              <div className="row">
                  <div className="col-sm-6 border">{<Resource keyString="what" />}:<strong> {acao.oque}</strong></div>
                  <div className="col-sm-6 border">{<Resource keyString="why" />}:<strong> {acao.porque}</strong></div>
              </div>
              <div className="row">
                  <div className="col-sm-6 border">{<Resource keyString="where" />}:<strong> {acao.onde}</strong></div>
                  <div className="col-sm-6 border">{<Resource keyString="how" />}:<strong> {acao.como}</strong></div>
              </div>
              <div className="row">
                  <div className="col-sm-4 border">{<Resource keyString="who" />}:<strong> {acao.quem}</strong></div>
                  <div className="col-sm-4 border">{<Resource keyString="when" />}:<strong> {acao.quando}</strong></div>
                  <div className="col-sm-4 border">{<Resource keyString="how_much" />}:<strong> {acao.quantocusta}</strong></div>
              </div>
          </div>
      </div>
  </div>
  );
};

export default PlanoDeAcaoModeloPadrao;
