import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { HttpService } from '../../Shared/HttpService';
import { Loading } from '../../Shared/Loading';
import { FormularioData } from './FetchFormulario';
import { LanguageContext } from '../../../context/LanguageContext';
import CaixaDeConteudo from '../../Shared/CaixaDeConteudo';
import FormularioIcon from '../../../assets/icons/formulario-icon.svg';
import MostrarIconeIcon from '../../../assets/icons/mostrar-icone-icon.svg';
import MostrarListaComDetalhesIcon from '../../../assets/icons/mostrar-lista-com-detalhes-icon.svg';
import { LayoutGrid, List } from 'lucide-react';

interface MeusFormulariosState {
    formularioList: FormularioData[];
    loading: boolean;
    formaDeExibicao: string;
    formularioSelecionado: FormularioData | null;
}

const ALTURA_DO_COPYRIGHT_SOMADO_A_MARGEM_SUPERIOR = 77;
const DISTANCIA_DO_TOPO_PARA_A_CAIXA_DE_CONTEUDO = 120;

export class MeusFormularios extends React.Component<RouteComponentProps<{}>, MeusFormulariosState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = {
            formularioList: [],
            loading: true,
            formaDeExibicao: 'icones_grandes',
            formularioSelecionado: null,
        };

        Loading.showLoading(this);;
        HttpService.fetch('api/FormularioXEmpresaApi/FormularioComprado', {})
            .then(response => response.json() as Promise<FormularioData[]>)
            .then(data => {
                this.setState({ formularioList: data, loading: false });
                Loading.hideLoading(this);
            });

        this.handleDetail = this.handleDetail.bind(this);
        this.renderizarPelaFormaDeExibicao = this.renderizarPelaFormaDeExibicao.bind(this);
    }

    private transformarDataParaFormatoBrasileiro(data: string) {
        var dataFormatada = new Date(data);
        return dataFormatada.toLocaleDateString();
    }

    private botoesDoCantoSuperiorDireito() {
        return (
            <React.Fragment>
                {
                    this.state.formaDeExibicao === 'lista_com_detalhes'
                        ?   (
                                <button
                                    className="btn bg-white mx-3 d-flex align-items-center justify-content-center"
                                    style={{ width: '2.8rem', height: '2.8rem', color: "var(--ws-blue)" }}
                                    onClick={() => this.setState({ formaDeExibicao: 'icones_grandes' })}
                                >
                                    <LayoutGrid size={24} />
                                </button>
                            )
                        :   (
                                <button
                                    className="btn bg-white mx-3 d-flex align-items-center justify-content-center"
                                    style={{ width: '2.8rem', height: '2.8rem', color: "var(--ws-blue)" }}
                                    onClick={() => this.setState({ formaDeExibicao: 'lista_com_detalhes' })}
                                >
                                    <List size={24} />
                                </button>
                            )
                }
            </React.Fragment>
        );
    }

    private handleBackgroundColorOfListItems(event, itemID) {
        const elemento = document.getElementById(itemID);
        if (!elemento) return;

        if (event.type === 'mouseenter' && `formulario-${this.state.formularioSelecionado?.id}` !== itemID) {
            elemento.style.backgroundColor = 'var(--ws-blue-highlight-menu)';
        } else if (event.type === 'mouseleave' && `formulario-${this.state.formularioSelecionado?.id}` !== itemID) {
            elemento.style.backgroundColor = 'var(--ws-white)';
        }
    }

    private renderizarPelaFormaDeExibicao() {
        switch (this.state.formaDeExibicao) {
            case 'lista_com_detalhes':
                return (
                    <div className='p-4 d-flex w-100' style={{  maxHeight: 'calc(100% - 54px)' }}>
                        <section className='w-50 d-flex flex-column align-items-center'>
                            <h4>{ this.context.getLanguageResource("my_forms") }</h4>
                            <div
                                className='w-100 barra_de_rolagem_estilizada'
                                style={{ overflowY: 'auto', direction: 'rtl' }}
                            >
                                <ul className='d-flex flex-column align-items-start w-100 px-4 pt-2 m-0 border-top' style={{ listStyle: 'none', direction: 'ltr' }}>
                                    {this.state.formularioList.sort((a, b) => Date.parse(b.datemodified) - Date.parse(a.datemodified)).map((formulario, index) =>
                                        <li
                                            className='d-flex align-items-center w-100 p-2 m-1 rounded'
                                            key={index}
                                            id={`formulario-${formulario.id.toString()}`}
                                            onMouseEnter={(event) => this.handleBackgroundColorOfListItems(event, `formulario-${formulario.id}`)}
                                            onMouseLeave={(event) => this.handleBackgroundColorOfListItems(event, `formulario-${formulario.id}`)}
                                            onClick={() => this.setState({ formularioSelecionado: formulario })}
                                            style={{ cursor: 'pointer', transition: 'background-color 0.2s', backgroundColor: this.state.formularioSelecionado?.id === formulario.id ? 'var(--ws-blue-highlight-menu)' : 'var(--ws-white)' }}
                                        >
                                            <img src={FormularioIcon} style={{ height: '2rem' }} alt={formulario.nome} />
                                            <p
                                                className='m-0 ml-2'
                                                style={{
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                { formulario.nome }
                                            </p>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </section>
                        <section className='w-50 d-flex flex-column align-items-center'>
                            <h4>{ this.context.getLanguageResource("description") }</h4>
                            <div className='w-100' style={{ maxHeight: '100%' }}>
                                <header className='w-100 border-top d-flex justify-content-center' >
                                    {
                                        this.state.formularioSelecionado
                                            ?   (
                                                    <h4 className='my-3'>
                                                        { this.state.formularioSelecionado && this.state.formularioSelecionado.nome }
                                                    </h4>
                                                )
                                            :   <p className='m-5 text-muted'>Selecione um formulário para visualizar seus detalhes</p>
                                    }
                                </header>
                                {
                                    this.state.formularioSelecionado && (
                                        <article className='border-top p-3 h-75 barra_de_rolagem_estilizada' style={{ overflowY: 'auto' }}>
                                            <p>
                                                { this.state.formularioSelecionado && this.state.formularioSelecionado.descricao }
                                            </p>
                                        </article>
                                    )
                                }
                                {
                                    this.state.formularioSelecionado && (
                                        <footer className='w-100 d-flex justify-content-end mt-4'>
                                            <button
                                                className='btn rounded'
                                                style={{ backgroundColor: 'var(--ws-blue)' }}
                                                onClick={() => this.handleDetail(this.state.formularioSelecionado?.id)}
                                            >
                                                Abrir formulário
                                            </button>
                                        </footer>
                                    )
                                }
                            </div>
                        </section>
                    </div>
                );
            default:
                return (
                    <div className='row p-4'>
                        {this.state.formularioList.sort((a, b) => Date.parse(b.datemodified) - Date.parse(a.datemodified)).map((formulario, index) =>
                            <div
                                className="col-md-2 btn btn-outline-light"
                                data-placement="bottom"
                                title={formulario.nome}
                                key={`form${formulario.id}`}
                                onClick={() => this.handleDetail(formulario.id)}
                            >
                                <img className="mx-auto mb-1" width="64" src={FormularioIcon} alt={formulario.nome} />
                                <div
                                    className="text-center"
                                >
                                    <p
                                        className="text-center text-secondary mb-0"
                                        style={{
                                            overflowWrap: 'break-word',
                                        }}
                                    >
                                        {formulario.nome}
                                    </p>
                                    <small className="text-muted" style={{ fontSize: '0.75rem' }}>Modificado em {this.transformarDataParaFormatoBrasileiro(formulario.datemodified)}</small>
                                </div>
                            </div>
                        )}
                    </div>
                );
        }
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;
        
        return (
            <CaixaDeConteudo
                itensParaBreadcrumb={[
                    { nome: this.context.getLanguageResource("home") },
                ]}
                tituloDoHeader={ this.context.getLanguageResource("my_forms") }
                botoes={ this.botoesDoCantoSuperiorDireito() }
                style={
                    this.state.formaDeExibicao === 'lista_com_detalhes'
                        ?   { height: `calc(100vh - ${ALTURA_DO_COPYRIGHT_SOMADO_A_MARGEM_SUPERIOR + DISTANCIA_DO_TOPO_PARA_A_CAIXA_DE_CONTEUDO}px` }
                        :   {}
                }
            >
                {this.renderizarPelaFormaDeExibicao()}
            </CaixaDeConteudo>
        );
    }

    private handleDetail(id) {
        this.props.history.push(`/marketPlace/detail/${id}`);
    }
}   