import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { GrupoPerguntaData } from './FetchGrupoPergunta';
import { Layout } from '../../Layout';
import { HttpService } from '../../Shared/HttpService';
import { AuthService } from '../../UserAuth/AuthService';
import { Loading } from '../../Shared/Loading';
import { Login, LoginData } from '../../UserAuth/Login';
import $ from 'jquery';
import { ValidationLabelMessage, ValidationAlertMessage } from '../../Shared/ValidationMessage';
import { Resource } from '../../../index';
import { LanguageContext } from '../../../context/LanguageContext';

interface AddGrupoPerguntaDataState {
    id: string;
    edicao: boolean;
    grupoPergunta: GrupoPerguntaData;
    todosGruposPergunta: GrupoPerguntaData[];
    formularioId: number;
    callBack: any;
}

export class AddGrupoPergunta extends React.Component<any, AddGrupoPerguntaDataState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        var grupoPergunta = new GrupoPerguntaData;

        if (props.grupopergunta != null) {
            grupoPergunta = props.grupopergunta;
        }

        // This will set state for Add User  
        this.state = { todosGruposPergunta: props.todosGruposPergunta, grupoPergunta: grupoPergunta, id: props.id, edicao: props.edicao, callBack: props.callBack, formularioId: props.formularioId };

        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);
    }

    // This will handle the submit form event.  
    private handleSave(event) {
        event.preventDefault();


        Loading.showLoading(this);;
        if (this.state.edicao && this.state.grupoPergunta != null && this.state.grupoPergunta.id > 0) {

            HttpService.fetch(`api/GrupoPerguntaApi/${this.state.grupoPergunta.id}`, {
                method: 'PUT',
                body: HttpService.formToJson(event.target),
            }).then((response) => {
                return response.json() as Promise<GrupoPerguntaData>;
            }).then((data) => {
                this.setState({ grupoPergunta: data })

                if ($.isEmptyObject(data.error)) {
                    this.state.callBack.updateGrupoPerguntas();
                    this.forceUpdate();
                    //alert('assinado');
                }
                Loading.hideLoading(this);
                this.props.fecharModal();
            }).catch((responseJson) => {
                console.log(responseJson);
                Loading.hideLoading(this);
            })
        } else {

            HttpService.fetch(`api/GrupoPerguntaApi`, {
                method: 'POST',
                body: HttpService.formToJson(event.target),
            }).then((response) => {
                return response.json() as Promise<GrupoPerguntaData>;
            }).then((data) => {
                this.setState({ grupoPergunta: data })

                if ($.isEmptyObject(data.error)) {
                    this.setState({ grupoPergunta: new GrupoPerguntaData })
                    this.state.callBack.updateGrupoPerguntas();
                    this.forceUpdate();
                    //alert('assinado');
                }
                Loading.hideLoading(this);
                this.props.fecharModal();
            }).catch((responseJson) => {
                console.log(responseJson);
                Loading.hideLoading(this);
            })
        }
    }

    // Returns the HTML Form to the render() method.  
    public render() {
        return (
            <form onSubmit={this.handleSave} >
                <h4 className="text-center mt-4">{<Resource keyString="add_question_group" />}</h4>
                <div className="modal-body">
                    <input type="hidden" name="formularioId" value={this.state.formularioId} />
                    <input type="hidden" name="id" value={this.state.edicao ? this.state.grupoPergunta.id : 0} />
                    <input type="hidden" name="grupoperguntapaiid" value={this.state.edicao ? this.state.grupoPergunta.grupoperguntapaiid : this.state.grupoPergunta.id} />
                    <div className="form-group">
                        <label>{<Resource keyString="name" />}</label>
                        <ValidationLabelMessage errors={this.state.grupoPergunta.error} name="Nome">
                            <input className="form-control" type="text" name="Nome" placeholder={this.context.getLanguageResource("name")} defaultValue={this.state.grupoPergunta.nome} />
                        </ValidationLabelMessage>
                    </div>
                    <div className="form-group">
                        <label>{<Resource keyString="tolerance" />}</label>
                        <ValidationLabelMessage errors={this.state.grupoPergunta.error} name="Tolerancia">
                            <input className="form-control" type="text" name="Tolerancia" placeholder={this.context.getLanguageResource("tolerance")} defaultValue={this.state.grupoPergunta.tolerancia.toString()} />
                        </ValidationLabelMessage>
                    </div>
                    <div className="form-group">
                        <label>{<Resource keyString="parent_question_group" />}</label>
                        <select className="form-control col-md-12" data-val="true" id="grupoperguntapaiid"
                            name="grupoperguntapaiid" defaultValue={(this.state.edicao ? this.state.grupoPergunta.grupoperguntapaiid : this.state.grupoPergunta.id)+""} >
                            <option value="">-- {this.context.getLanguageResource("select")} --</option>
                            {
                                this.state.todosGruposPergunta
                                    .filter(x => x.id != this.state.grupoPergunta.id)
                                    .map(x =>
                                        <option key={x.id} value={x.id}>{x.nome}</option>
                                    )
                            }
                        </select>
                    </div>
                    <ValidationAlertMessage errors={this.state.grupoPergunta.error} name="Summary" />
                </div>

                <div className="modal-footer">
                    <button className="btn ws-outline-green-brown" style={{ width: '2.75rem', height: '2.75rem' }} type="submit"><i className='fa fa-save'></i></button>
                    <button type="button" className="btn btn-outline-danger" style={{ width: '2.75rem', height: '2.75rem' }} onClick={this.props.fecharModal}><i className='fa fa-times'></i></button>
                </div>
            </form>
        )
    }

}  