import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { Layout } from '../Layout';
import { HeadLayout } from '../Shared/HeadLayout';
import { HttpService } from '../Shared/HttpService';
import { Loading } from '../Shared/Loading';
import { ModalDelete } from '../Shared/ModalDelete';
import { Util } from '../Shared/Util';
import { Resource } from '../../index';
import { LanguageContext } from '../../context/LanguageContext';
import CaixaDeConteudo from '../Shared/CaixaDeConteudo';
import ModalWinSet from '../Shared/components/ModalWinSet';
import Payment from '../Payment';

interface FetchPerfilState {
    perfilList: PerfilData[];
    loading: boolean;
    modalDeDeletarEstaAberto: boolean;
    itemSelecionadoParaDeletar: PerfilData | null;
}

export class PerfilData {
    id: number = 0;
    nome: string = "";
    leitura: boolean = true;
    escrita: boolean = false;
    revisao: boolean = false;
    aprovacao: boolean = false;
    exclusao: boolean = false;
    compartilhar: boolean = false;
    email: boolean = false;
    manterusuario: boolean = false;
    manterperfil: boolean = false;
    manterfuncao: boolean = false;
    mantersetor: boolean = false;
    manterempresa: boolean = false;
    manterresposta: boolean = false;
    responder: boolean = false;
    manteracao: boolean = false;
    manterformatopergunta: boolean = false;
    manterformulario: boolean = false;
    mantervigenteaosubirarquivo: boolean = false;
    mostrarlojaexterna: boolean = false;
    reativarformulario: boolean = false;
    error: Array<any> = [];
}

export class FetchPerfil extends React.Component<RouteComponentProps<{}>, FetchPerfilState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = { perfilList: [], loading: true, modalDeDeletarEstaAberto: false, itemSelecionadoParaDeletar: null};

        Loading.showLoading(this);;
        HttpService.fetch('api/PerfilApi', {})
            .then(response => response.json() as Promise<PerfilData[]>)
            .then(data => {
                this.setState({ perfilList: data, loading: false });
                Loading.hideLoading(this);
            });

        this.handleDelete = this.handleDelete.bind(this);
        this.handleEdit = this.handleEdit.bind(this);

    }

    private botoesDoCantoSuperiorDireito() {
        return (
            <button className='btn bg-white shadow-sm mr-2' onClick={() => this.props.history.push('/perfil/add')}>
                <Resource keyString="add" />
            </button>
        );
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;

        return (
            <CaixaDeConteudo
                tituloDoHeader={this.context.getLanguageResource('profile')}
                botoes={this.botoesDoCantoSuperiorDireito()}
                itensParaBreadcrumb={[
                    { nome: this.context.getLanguageResource('home'), url: "/" },
                    { nome: this.context.getLanguageResource('profile') },
                ]}
            >
                <React.Fragment>
                    <header className='d-flex align-items-center justify-content-center w-100 p-4'>
                        <h3>{<Resource keyString="access_profiles" />}</h3>
                    </header>
                    <main className='p-4'>
                        {this.renderUserTable(this.state.perfilList)}
                    </main>
                </React.Fragment>
            </CaixaDeConteudo>
        );
    }

    // Handle Delete request for an user  
    private handleDelete(id: number) {
            Loading.showLoading(this);;
            HttpService.fetch('api/PerfilApi/' + id, {
                method: 'delete'
            }).then(data => {
                this.setState(
                    {
                        perfilList: this.state.perfilList.filter((rec) => {
                            return (rec.id != id);
                        })
                    });
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            });
    }

    private handleEdit(id: number) {
        this.props.history.push("/perfil/edit/" + id);
    }

    // Returns the HTML table to the render() method.  
    private renderUserTable(perfilList: PerfilData[]) {
        return <table className='table'>
            <thead>
                <tr>
                    <th>{<Resource keyString="access_profile_name" />}</th>
                    <th>{<Resource keyString="reading" />}</th>
                    <th>{<Resource keyString="writing" />}</th>
                    <th>{<Resource keyString="review" />}</th>
                    <th>{<Resource keyString="approval" />}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {perfilList.map(obj =>
                    <tr key={obj.id}>
                        <td className='align-middle text-break'>{obj.nome}</td>
                        <td className='align-middle text-break'>{Util.AtivadoDesativado(obj.leitura)}</td>
                        <td className='align-middle text-break'>{Util.AtivadoDesativado(obj.escrita)}</td>
                        <td className='align-middle text-break'>{Util.AtivadoDesativado(obj.revisao)}</td>
                        <td className='align-middle text-break'>{Util.AtivadoDesativado(obj.aprovacao)}</td>
                        <td className='align-middle'>
                            <div className='d-flex justify-content-end'>
                                <button
                                    className="btn btn-outline-danger mr-2 pull-right"
                                    onClick={() => this.setState({ modalDeDeletarEstaAberto: true, itemSelecionadoParaDeletar: obj })}
                                    style={{ width: '2.75rem', height: '2.75rem' }}
                                >
                                    <i className='fa fa-trash'></i>
                                </button>
                                {
                                    (this.state.modalDeDeletarEstaAberto && this.state.itemSelecionadoParaDeletar && (this.state.itemSelecionadoParaDeletar.id === obj.id)) && (
                                        <ModalWinSet
                                            fecharModal={() => this.setState({ modalDeDeletarEstaAberto: false, itemSelecionadoParaDeletar: null })}
                                        >
                                            <div className='p-3'>
                                                <header className='d-flex w-100 mb-2 justify-content-center'>
                                                    <h4><Resource keyString="do_you_want_to_remove_the_group" />?</h4>
                                                </header>
                                                <hr />
                                                <main>
                                                    <p>{obj.nome}</p>
                                                </main>
                                                <footer className='d-flex w-100 justify-content-end'>
                                                    <button className="btn mr-2 ws-outline-blue" onClick={() => this.handleDelete(obj.id)}><Resource keyString="yes" /></button>
                                                    <button className="btn btn-outline-danger" onClick={() => this.setState({ modalDeDeletarEstaAberto: false, itemSelecionadoParaDeletar: null })}><Resource keyString="no" /></button>
                                                </footer>
                                            </div>
                                        </ModalWinSet>
                                        
                                    )
                                }
                                <button className="btn ws-outline-green-brown mr-2 pull-right" style={{ width: '2.75rem', height: '2.75rem' }} onClick={() => this.handleEdit(obj.id)}><i className='fa fa-edit'></i></button>
                            </div>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>;
    }
}   