import React, { useContext, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { Layout } from '../../Layout';
import { HttpService } from '../../Shared/HttpService';
import { HeadLayout } from '../../Shared/HeadLayout';
import { AuthService } from '../../UserAuth/AuthService';
import { Loading } from '../../Shared/Loading';
import $ from 'jquery';
import { ValidationLabelMessage, ValidationAlertMessage } from '../../Shared/ValidationMessage';
import { FetchPergunta } from '../Pergunta/FetchPergunta';
import { Resource } from '../../../index';
import { LanguageContext } from '../../../context/LanguageContext';
import { GlobalContext } from '../../../context/GlobalContext';
import CaixaDeConteudo from '../../Shared/CaixaDeConteudo';

interface AddCabecalhoDataState {
    loading: boolean;
    formularioid: number;
    cabecalhoData: CabecalhoData[];
    acabouDeSalvar: boolean;
}

enum CabecalhoCampoMultiplaEscolhaDataSet {
    Texto = 1,
    Data = 2,
    Numerico = 3,
    Hora = 4,
    MultiplaEscolha = 5,
    Ajuda = 6
}

export class CabecalhoData {
    id: number = 0;
    empresaid: number = 0;
    nome: string = "";
    ordenacao: number = 1;
    formularioid: number = 0;
    error: any = null;
    isactive: boolean = true;
    tipoCabecalhoId: number = 0;
    camposCabecalhoMultiplaEscolha: CabecalhoCampoMultiplaEscolhaData[] = [];
    rodape: boolean = false;
    limitarParaDataAtual: boolean = false;
    cabecalhoObrigatorio: boolean = false;
    ajuda: string = "";
}

export class CabecalhoCampoMultiplaEscolhaData {
    id: number = 0;
    nome: string = "";
    isactive: boolean = true;
}

export class AddCabecalho extends React.Component<RouteComponentProps<{}>, AddCabecalhoDataState> {
    static contextType = LanguageContext;
    private _pathname;
    private _regexPaginaDeCabecalhoNaEdicao;
    private _estouNaPaginaDeCabecalhoDaEdicao;
    constructor(props) {
        super(props);
        this._pathname = window.location.pathname;
        this._regexPaginaDeCabecalhoNaEdicao = new RegExp(/formulario\/cabecalho\/[1-9]\d*$/);
        this._estouNaPaginaDeCabecalhoDaEdicao = this._regexPaginaDeCabecalhoNaEdicao.test(this._pathname);

        var formularioid = this.props.match.params["formularioid"];

        this.state = { loading: true, formularioid: formularioid, cabecalhoData: [], acabouDeSalvar: false };

        // This will set state for Edit User  
        if (formularioid > 0) {

            Loading.showLoading(this);

            HttpService.fetch('api/CabecalhoApi/All/' + formularioid, { method: 'GET' })
                .then(response => response.json() as Promise<Array<CabecalhoData>>)
                .then(data => {
                    this.setState({ cabecalhoData: data })
                    Loading.hideLoading(this);
                }).catch(() => {
                    Loading.hideLoading(this);
                    props.history.push(`/repositorio`);
                });
        }

        // This will set state for Add User  
        else {
            this.state = { loading: false, formularioid: formularioid, cabecalhoData: [], acabouDeSalvar: false };
        }


        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleNewCabecalho = this.handleNewCabecalho.bind(this);
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;

        return (
            <CaixaDeConteudo
                itensParaBreadcrumb={[
                    { nome: this.context.getLanguageResource("home"), url: "/" },
                    { nome: this.context.getLanguageResource("forms"), url: "/formulario" },
                    { nome: this.context.getLanguageResource("edit_form"), url: "/formulario/edit/" + this.state.formularioid },
                    { nome: 'Editar cabeçalhos' },
                ]}
                tituloDoHeader={this._estouNaPaginaDeCabecalhoDaEdicao ? this.context.getLanguageResource("edit_form") : this.context.getLanguageResource("create_form")}
                botoes={[
                    <button className="btn bg-white shadow-sm mr-2" onClick={this.handleCancel}><Resource keyString="back" /></button>
                ]}
            >
                <div className='p-4'>
                    <header>
                        <h4 className="text-center">Editar cabeçalhos</h4>
                    </header>
                    {
                        this.state.acabouDeSalvar && (
                            <div className="alert alert-success alert-dismissible fade show" role="alert">
                                <strong>{this.context.getLanguageResource("saved_successfully")}</strong>
                                <span className="close" style={{ cursor: 'pointer' }} onClick={() => this.setState({ acabouDeSalvar: false })}>&times;</span>
                            </div>    
                        )
                    }
                    {this.renderCreateForm()}
                </div>
            </CaixaDeConteudo>
        );
    }

    private handleNewCabecalho() {
        var newCabecalho = new CabecalhoData();
        newCabecalho.tipoCabecalhoId = 1;
        newCabecalho.formularioid = this.state.formularioid;
        newCabecalho.error = null;

        var listaCabecalho = this.state.cabecalhoData;
        listaCabecalho.push(newCabecalho);
        this.setState({ cabecalhoData: listaCabecalho });
        this.forceUpdate();
    }

    private handleEditCabecalho(e, index, valor) {
        var listaCabecalho = this.state.cabecalhoData;
        if (e.target.type == "checkbox") {
            listaCabecalho[index][valor] = e.target.checked;
        } else {
            listaCabecalho[index][valor] = e.target.value;
        }
        this.setState({ cabecalhoData: listaCabecalho });
        this.forceUpdate();
    }

    private handleDeleteCabecalho(index) {
        var listaCabecalho = this.state.cabecalhoData;
        if (listaCabecalho[index].id != 0) {
            listaCabecalho[index].isactive = false;
        } else {
            listaCabecalho.splice(index, 1);
        }
        this.setState({ cabecalhoData: listaCabecalho });
        this.forceUpdate();
    }

    // This will handle the submit form event.  
    private handleSave(event) {
        event.preventDefault();

        var listaCabecalho = this.state.cabecalhoData;

        listaCabecalho.map((o, i) => {
            if (o.ordenacao.toString() == "") {
                o.ordenacao = 1;
            }
        });

        Loading.showLoading(this);

        HttpService.fetch('api/CabecalhoApi/', {
            method: 'POST',
            body: JSON.stringify(listaCabecalho),
        }).then((response) => {
            return response.json() as Promise<Array<CabecalhoData>>
        }).then((data) => {
            this.setState({ cabecalhoData: data });
            Loading.hideLoading(this);
            this.setState({ acabouDeSalvar: true });
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }).catch(() => {
            Loading.hideLoading(this);
        })

    }

    // This will handle Cancel button click event.  
    private handleCancel(e) {
        this.props.history.push('/formulario/edit/' + this.state.formularioid);
    }

    private handleCustomCamposCabecalho(obj, indexObj) {
        if (obj.tipoCabecalhoId == CabecalhoCampoMultiplaEscolhaDataSet.MultiplaEscolha) {
            return this.handleItensMultiplaEscolhaCabecalho(obj, indexObj);
        } else if (obj.tipoCabecalhoId == CabecalhoCampoMultiplaEscolhaDataSet.Ajuda) {
            return this.handleCampoAjuda(obj, indexObj);
        }
    }

    private handleCampoAjuda(obj, indexObj) {
        return (<div className="row">
            <div className="col-md-12">
                <ValidationLabelMessage name="Ajuda">
                    <label className="control-label col-md-12 mt-2" htmlFor="Ajuda" >{<Resource keyString="help" />}</label>
                    <div className="col-md-12 d-flex pb-2">
                        <textarea className="form-control" name="ajuda"
                            onChange={(e) => this.handleEditCabecalho(e, indexObj, "ajuda")}
                            value={obj.ajuda}>
                        </textarea>
                    </div>
                </ValidationLabelMessage>
            </div>
        </div>);
    }

    private handleItensMultiplaEscolhaCabecalho(obj, indexObj) {
        return (<div className="row">
            <div className="col-md-12">
                <ValidationLabelMessage name="Opcoes">
                    <label className="control-label col-md-12 mt-2" htmlFor={"nomeOpcaoMultiplaEscolha" + indexObj} >{<Resource keyString="options" />}</label>
                    <div className="col-md-12 d-flex pb-2">
                        <input className="form-control mr-2" type="text" id={"nomeOpcaoMultiplaEscolha" + indexObj} name={"nomeOpcaoMultiplaEscolha" + indexObj} />
                        <button className="btn btn-primary pull-right" type="button"
                            onClick={(e) => {
                                const nomeOpcaoMultiplaEscolha = document.getElementById("nomeOpcaoMultiplaEscolha" + indexObj) as HTMLInputElement;
                                this.handleEditCabecalhoMultiplaEscolha(e, indexObj);

                                nomeOpcaoMultiplaEscolha.value = "";
                            }}
                        >
                            <i className='fa fa-plus'></i>
                        </button>
                    </div>
                </ValidationLabelMessage>
            </div>
            {obj.camposCabecalhoMultiplaEscolha != null ?
                <div className="col-md-12 px-4">
                    <table className='table border'>
                        <tbody>
                            {obj.camposCabecalhoMultiplaEscolha
                                .filter((x) => { return x.isactive == true })
                                .map((doc: any, index) =>
                                    <tr key={index}>
                                        <td className=" p-1">{doc.nome}</td>
                                        <td className=" p-1">
                                            <button className="btn btn-outline-danger mr-2 pull-right" type="button"
                                                onClick={() => this.handleDeleteCabecalhoMultiplaEscolha(obj, index)}>
                                                <i className='fa fa-trash'></i>
                                            </button>
                                        </td>
                                    </tr>
                                )}
                        </tbody>
                    </table>
                </div>
                :
                ""
            }
        </div>);
    }

    private handleEditCabecalhoMultiplaEscolha(e, index) {
        const nomeOpcao: any = $("#nomeOpcaoMultiplaEscolha" + index).val();
        var opcao: CabecalhoCampoMultiplaEscolhaData = new CabecalhoCampoMultiplaEscolhaData();
        opcao.nome = nomeOpcao;
        if (nomeOpcao != "") {
            var listaCabecalho = this.state.cabecalhoData;
            if (listaCabecalho[index].camposCabecalhoMultiplaEscolha != null) {
                listaCabecalho[index].camposCabecalhoMultiplaEscolha.push(opcao);
            } else {
                listaCabecalho[index].camposCabecalhoMultiplaEscolha = [opcao];
            }
            this.setState({ cabecalhoData: listaCabecalho });
            this.forceUpdate();
        }
    }

    private handleDeleteCabecalhoMultiplaEscolha(obj, index) {
        obj.camposCabecalhoMultiplaEscolha.filter((x) => { return x.isactive }).map((obj, objIndex) => {
            if (objIndex == index) {
                obj.isactive = false;
            }
        })
        this.setState({ cabecalhoData: this.state.cabecalhoData });
        this.forceUpdate();
    }

    // Returns the HTML Form to the render() method.  
    private renderCreateForm() {
        
        return (
            <form onSubmit={this.handleSave}>
                <div className="form-group row" >
                    <input type="hidden" name="id" value={this.state.formularioid} />
                </div>
                <div className="card-title">
                    <h4>{<Resource keyString="header_fields" />}</h4>
                </div>
                <div className="form-group row">
                    <div className="col-md-12" >
                        <button type="button" className="btn mr-2" style={{ backgroundColor: 'var(--ws-blue)' }} onClick={this.handleNewCabecalho}>
                            <i className='fa fa-plus'></i> {<Resource keyString="add_header" />}
                        </button>
                    </div>
                </div>
                {this.state.cabecalhoData
                    .sort((a, b) => (a.rodape === b.rodape) ? 0 : a.rodape ? 1 : -1)
                    .map((obj, indexObj) =>
                        <div className={"border-bottom py-3 my-3" + (obj.isactive == true ? "" : "d-none")} key={indexObj}>
                            <div className='d-flex w-100'>
                                <div className="w-100 mr-4">
                                    <div>
                                        <label className="control-label required" htmlFor="Nome">{<Resource keyString="header_name" />}</label>
                                        <div>
                                            <ValidationLabelMessage errors={obj.error} name="Nome" >
                                                <input className="form-control" type="text" name="nome" value={obj.nome}
                                                    onChange={(e) => this.handleEditCabecalho(e, indexObj, "nome")} />
                                            </ValidationLabelMessage>
                                        </div>
                                    </div>
                                    <div>
                                        <label className="control-label mt-2 required" htmlFor="TipoCabecalhoId">{<Resource keyString="header_field_type" />}</label>
                                        <div>
                                            <select className="form-control" name="tipoCabecalhoId" onChange={(e) => this.handleEditCabecalho(e, indexObj, "tipoCabecalhoId")} value={obj.tipoCabecalhoId}>
                                                <option value={CabecalhoCampoMultiplaEscolhaDataSet.Texto}>{this.context.getLanguageResource("text")}</option>
                                                <option value={CabecalhoCampoMultiplaEscolhaDataSet.Data}>{this.context.getLanguageResource("date")}</option>
                                                <option value={CabecalhoCampoMultiplaEscolhaDataSet.Numerico}>{this.context.getLanguageResource("numeric")}</option>
                                                <option value={CabecalhoCampoMultiplaEscolhaDataSet.Hora}>{this.context.getLanguageResource("hour")}</option>
                                                <option value={CabecalhoCampoMultiplaEscolhaDataSet.MultiplaEscolha}>{this.context.getLanguageResource("multiple_choice")}</option>
                                                <option value={CabecalhoCampoMultiplaEscolhaDataSet.Ajuda}>{this.context.getLanguageResource("help")}</option>
                                            </select>
                                        </div>
                                    </div>
                                    {this.handleCustomCamposCabecalho(obj, indexObj)}
                                    {obj.tipoCabecalhoId == CabecalhoCampoMultiplaEscolhaDataSet.Data &&
                                        <div className="form-check">
                                            <div>
                                                <input className="form-check-input" type="checkbox"
                                                    onChange={(e) => this.handleEditCabecalho(e, indexObj, "limitarParaDataAtual")}
                                                    defaultChecked={obj.limitarParaDataAtual == true} checked={obj.limitarParaDataAtual == true} />
                                            <label className="form-check-label" htmlFor="LimitarParaDataAtual">
                                                {<Resource keyString="limit_to_current_date" />}
                                                </label>
                                            </div>
                                        </div>
                                    }
                                    <div>
                                        <label className="control-label mt-2" htmlFor="Ordenacao">{<Resource keyString="ordination" />}</label>
                                        <div>
                                            <input className="form-control" min="1" type="number" name="ordenacao" value={obj.ordenacao}
                                                onChange={(e) => this.handleEditCabecalho(e, indexObj, "ordenacao")} />
                                        </div>
                                    </div>
                                    <div className="form-check mt-4">
                                        <div>
                                            <input className="form-check-input" type="checkbox"
                                                onChange={(e) => this.handleEditCabecalho(e, indexObj, "rodape")}
                                                defaultChecked={obj.rodape == true} checked={obj.rodape == true} />
                                            <label className="form-check-label" htmlFor="Rodape">
                                                {<Resource keyString="show_in_footer" />}
                                        </label>
                                        </div>
                                    </div>
                                    {obj.tipoCabecalhoId != CabecalhoCampoMultiplaEscolhaDataSet.Ajuda &&
                                        <div className="form-check mt-2">
                                            <div>
                                                <input className="form-check-input" type="checkbox"
                                                    onChange={(e) => this.handleEditCabecalho(e, indexObj, "cabecalhoObrigatorio")}
                                                    defaultChecked={obj.cabecalhoObrigatorio == true} checked={obj.cabecalhoObrigatorio == true} />
                                                <label className="form-check-label" htmlFor="CabecalhoObrigatorio">
                                                    {<Resource keyString="mandatory_completion" />}
                                                </label>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div>
                                    <label className="control-label">&nbsp;</label>
                                    <button type="button" style={{ width: '2.813rem', height: '2.813rem' }} className="btn btn-block btn-outline-danger"
                                        onClick={() => this.handleDeleteCabecalho(indexObj)}>
                                        <i className='fa fa-minus'></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                <div className="form-group">
                    <button type="submit" style={{ width: '2.813rem', height: '2.813rem' }} className="btn btn-outline-success mr-2"><i className='fa fa-save'></i></button>
                </div >
            </form >
        )
    }
}