export class EmpresaData {
    id: number = 0;
    nome: string = "";
    descricao: string = "";
    nomefantasia: string = "";
    razaosocial: string = "";
    documento: string = "";
    email: string = "";
    telefone: string = "";
    cep: string = "";
    endereco: string = "";
    numero: string = "";
    complemento: string = "";
    bairro: string = "";
    cidade: string = "";
    uf: string = "";
    nomePropriedade: string = "";
    error: Array<any> = [];
    cepError: string = "";
    celular: string = "";
    cnpjError: string = "";
    seguimento: string = "";
    cargo: string = "";
    departamento: string = "";
    tipoetamanho: string = "";
    emailconfirmado: string | null = null;
    celularconfirmado: string | null = null;
    corDoTopoDaAplicacao: string = "#08ABD9";
    corPrincipal: string = "#22C9F7";
    afiliado: number = 0;
}