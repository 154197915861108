import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { Layout } from '../../Layout';
import { HttpService } from '../../Shared/HttpService';
import { HeadLayout } from '../../Shared/HeadLayout';
import { Loading } from '../../Shared/Loading';
import { FormularioData } from './FetchFormulario';
import $ from 'jquery';
import { Compartilhar } from './CompartilharFormulario';
import { Resource } from '../../../index';
import { LanguageContext } from '../../../context/LanguageContext';
import CaixaDeConteudo from '../../Shared/CaixaDeConteudo';

interface IState {
    formulariosCompartilhados: Array<any>;
    search: "";
    dataInicial: any;
    dataFinal: any;
    isFormularioChecked: boolean;
    isUsuarioChecked: boolean;
    nomeUsuario: any;
    nomeFormulario: any;
    hiddenTable: boolean;
}

export class DadosRelatorioFormularioCompartilhadoData{
    NomeCompartilhado: any;
    DataCriacao: any;
    NomeFormulario: any;
    NomeUsuario: any;
}

export class RelatorioFormularioCompartilhado extends React.Component<RouteComponentProps<{}>, IState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        this.state = {
            formulariosCompartilhados: [],
            search: "",
            dataInicial: "",
            dataFinal: "",
            isFormularioChecked: true,
            isUsuarioChecked: false,
            nomeUsuario: "",
            nomeFormulario: "",
            hiddenTable: true
        }; 

        this.Pesquisar = this.Pesquisar.bind(this);
        this.FormatarData = this.FormatarData.bind(this);  
        this.handleDataIncio = this.handleDataIncio.bind(this); 
        this.handleDataFinal = this.handleDataFinal.bind(this); 
        this.handleNomeFormulario = this.handleNomeFormulario.bind(this); 
        this.handleNomeUsuario = this.handleNomeUsuario.bind(this); 
        this.dataPradraoParaInputDate = this.dataPradraoParaInputDate.bind(this); 
    }

    Pesquisar(event) {
        event.preventDefault();
        Loading.showLoading(this);;

        HttpService.fetch('api/RelatorioFormulariosCompartilhadosApi/ListarRelatorioFormulariosCompartilhados', {
            method: 'Post',
            body: HttpService.formToJson(event.target)
        })
            .then((response) => {
                return response.json();
            }) 
            .then(data => {
                this.setState({ formulariosCompartilhados: data, hiddenTable: false });
                Loading.hideLoading(this);
            } 
        );

       
    }

    componentDidMount() {
       // this.dataPradraoParaInputDate();
    }
     
    FormatarData(data: any) {
        var dataFormatada = data.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1');
        return dataFormatada;
    }

    dataPradraoParaInputDate() {
        var data = new Date(),
            dia = data.getDate().toString(),
            diaF = (dia.length == 1) ? '0' + dia : dia,
            mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
            mesF = (mes.length == 1) ? '0' + mes : mes,
            anoF = data.getFullYear(),
            dataFormatada = anoF + "-" + mesF + "-" + diaF;

        this.setState({ dataInicial: dataFormatada, dataFinal: dataFormatada });

        return dataFormatada
    }
 
    handleDataIncio(e: any) {
        var dateInicial = new Date(e.target.value.split('/').reverse().join('/'));
        var dateFinal = new Date(this.state.dataFinal.split('/').reverse().join('/'));

        if (dateInicial > dateFinal) {
            this.setState({ dataInicial: this.state.dataFinal }); //A dataInicial vai receber a dataFinal se a dataInicial for maior que dataFinal.
        }
        else {
            this.setState({ dataInicial: e.target.value });
        }

       
    }

    handleDataFinal(e: any) {

        var dateFinal = new Date(e.target.value.split('/').reverse().join('/'));
        var dateInicial = new Date(this.state.dataFinal.split('/').reverse().join('/'));

        if (dateFinal < dateInicial) {
            this.setState({ dataFinal: this.state.dataInicial }); //A dataFinal vai receber a dataInicail se a dataFinal for menor que dataInicial.
        }
        else {
            this.setState({ dataFinal: e.target.value });
        }
        
    }
     
    handleNomeFormulario(e:any) {
        this.setState({ nomeFormulario: e.target.value });
    }

    handleNomeUsuario(e: any) {
        this.setState({ nomeUsuario: e.target.value });
    }

    public render() {
        return (
            <CaixaDeConteudo
                tituloDoHeader={this.context.getLanguageResource("report_shared_form")}
                itensParaBreadcrumb={[
                    { url: "/", nome: this.context.getLanguageResource("home") },
                    { nome: this.context.getLanguageResource("report_shared_form") }
                ]}
                botoes={[]}
            >
                <div className='p-4'>
                    <form onSubmit={this.Pesquisar}>
                        <div className='row'>
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="m-0" id="LabelDataIncio">{<Resource keyString="start_date" />}:</label>
                                    <input className="form-control rounded" type="date" id="dataIncio" name="DataInicial" value={this.state.dataInicial == "" ? this.dataPradraoParaInputDate() : this.state.dataInicial} onChange={(e) => this.handleDataIncio(e)} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group "> 
                                    <label className="m-0" id="LabelDataFim">{<Resource keyString="end_date" />}:</label>
                                    <input className="form-control rounded" id="DataFim" name="dataFinal" type="date" value={this.state.dataFinal == "" ? this.dataPradraoParaInputDate() : this.state.dataFinal} onChange={(e) => this.handleDataFinal(e)} />
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group  ">
                                    <label className="form-check-label" id="labelFormulario">{<Resource keyString="form" />}</label>
                                    <input type="text" className="form-control" id="Formulario" placeholder={this.context.getLanguageResource("form_name")} name="NomeFormulario"  onChange={(e) => this.handleNomeFormulario(e)} />
                                </div>
                            </div> 
                            <div className="col">
                                <div className="form-group  ">
                                    <label className="form-check-label" id="labelFormulario">{<Resource keyString="user" />}</label>
                                    <input type="text" className="form-control" id="Usuario" placeholder={this.context.getLanguageResource("username")} name="NomeUsuario"   onChange={(e) => this.handleNomeUsuario(e)} />
                                </div>
                            </div>
                            <div className="w-100 d-flex justify-content-center">
                                <div className="form-group pt-4 px-0">
                                    <button type="submit" className="btn font-weight-bold" style={{ backgroundColor: 'var(--ws-blue)', width: '17.5rem' }}>{<Resource keyString="search" />}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div hidden={this.state.hiddenTable}>
                    {
                        this.state.formulariosCompartilhados.length > 0
                            ?   (
                                    <table className="table table-striped text-center w-100">

                                        <thead>
                                            <tr >
                                                <th scope="col">{<Resource keyString="username" />}</th>
                                                <th scope="col">{<Resource keyString="form_name" />}</th>
                                                <th scope="col">{<Resource keyString="creation_date" />}</th>
                                                <th scope="col">{<Resource keyString="it_was_shared_with" />}</th>
                                            </tr>
                                        </thead>
                                        <tbody>  
                                            {this.state.formulariosCompartilhados.map((item, i) =>  
                                                <tr key={i}>
                                                    <td className='align-middle'>{item.nomeUsuario}</td>
                                                    <td className='align-middle'>{item.nomeFormulario} - {item.tituloFormulario == null ? item.hashDoFormularioIniciado : item.tituloFormulario }</td>
                                                    <td className='align-middle'>{this.FormatarData(item.dataCriacao)}</td>
                                                    <td className='align-middle'>{item.usuarioCompartilhado}</td>
                                                </tr>
                                                )}  
                                        </tbody>
                                    </table>
                                )
                            :   (
                                    <div className="alert alert-info" role="alert">
                                        {<Resource keyString="no_data_was_found_for_this_result" />}
                                    </div>
                                )
                    }
                    </div>
                </div>
            </CaixaDeConteudo>
        );
    }
}
  