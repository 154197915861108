type FormatoDataEHora = 'DH' | 'D' | 'H';

/**
* Utilidade Win.set
*
* Formata a data e hora do banco de dados para o formato local do usuário.
* @param data: string - Data e hora do banco de dados.
* @param formato: FormatoDataEHora - Formato da data e hora que será retornado.
* @returns string - Data e hora formatada.
* @example
* formatarDataEHoraDoBanco('2021-01-01T00:00:00', 'DH');
* // Retorna 01/01/2021 00:00
*/
export const formatarDataEHoraDoBanco = (data: string, formato: FormatoDataEHora) => {
    const dataFormatada = new Date(data);
    const locale = localStorage.getItem('pagedLanguage') ? localStorage.getItem('pagedLanguage') as string : window.navigator.language;

    switch (formato) {
        case 'DH':
            return dataFormatada.toLocaleString(locale, { dateStyle: 'short', timeStyle: 'short' }).replace(',', '');
        case 'D':
            return dataFormatada.toLocaleString(locale, { dateStyle: 'short' }).replace(',', '');
        case 'H':
            return dataFormatada.toLocaleString(locale, { timeStyle: 'short' }).replace(',', '');
    }    
}
