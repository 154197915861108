import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { FuncaoData } from './FetchFuncao';
import { HeadLayout } from '../Shared/HeadLayout';
import { Layout } from '../Layout';
import { HttpService } from '../Shared/HttpService';
import { Loading } from '../Shared/Loading';
import $ from 'jquery';
import { Resource } from '../../index';
import { ValidationLabelMessage } from '../Shared/ValidationMessage';
import { LanguageContext } from '../../context/LanguageContext';
import CaixaDeConteudo from '../Shared/CaixaDeConteudo';

interface AddFuncaoDataState {
    loading: boolean;
    empData: FuncaoData;
}

export class AddFuncao extends React.Component<RouteComponentProps<{}>, AddFuncaoDataState> {
    static contextType = LanguageContext;
    private _pathname;
    private _regexPaginaDeEdicao;
    private _estouNaPaginaDeEdicao;
    constructor(props) {
        super(props);
        this._pathname = window.location.pathname;
        this._regexPaginaDeEdicao = new RegExp(/funcao\/edit/);
        this._estouNaPaginaDeEdicao = this._regexPaginaDeEdicao.test(this._pathname);

        this.state = { loading: true, empData: new FuncaoData };

        var funcaoid = this.props.match.params["funcaoid"];

        // This will set state for Edit User  
        if (funcaoid > 0) {

            Loading.showLoading(this);;

            HttpService.fetch('api/FuncaoApi/' + funcaoid, { method: 'GET' })
                .then(response => response.json() as Promise<FuncaoData>)
                .then(data => {
                    this.setState({ loading: false, empData: data });
                    Loading.hideLoading(this);
                }).catch(() => {
                    Loading.hideLoading(this);
                    props.history.push("/funcao");
                });
        }

        // This will set state for Add User  
        else {
            this.state = { loading: false, empData: new FuncaoData };
        }


        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    private botoesDoCantoSuperiorDireito() {
        return (
            <React.Fragment>
                <button className="btn bg-white shadow-sm mr-2" onClick={this.handleCancel}>{<Resource keyString="back" />}</button>
            </React.Fragment>
        );
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;

        return (
            <CaixaDeConteudo
                tituloDoHeader={this._estouNaPaginaDeEdicao ? this.context.getLanguageResource("edit_function") : this.context.getLanguageResource("create_function")}
                botoes={this.botoesDoCantoSuperiorDireito()}
                itensParaBreadcrumb={[
                    { url: "/", nome: this.context.getLanguageResource("home") },
                    { url: "/funcao", nome: this.context.getLanguageResource("registration_functions") },
                    { nome: this._estouNaPaginaDeEdicao ? this.context.getLanguageResource("edit_function") : this.context.getLanguageResource("create_function") }
                ]}
            >
                <main className='p-4'>
                    {this.renderCreateForm()}
                </main>
            </CaixaDeConteudo>
        );
    }

    // This will handle the submit form event.  
    private handleSave(event) {
        event.preventDefault();

        let funcaoId = this.state.empData.id;

        Loading.showLoading(this);;

        // PUT request for Edit User.  
        if (funcaoId) {

            HttpService.fetch('api/FuncaoApi/' + funcaoId, {
                method: 'PUT',
                body: HttpService.formToJson(event.target),
            }).then((response) => {
                return response.json() as Promise<FuncaoData>
            }).then((data) => {

                this.setState({ empData: data })
                if ($.isEmptyObject(data.error)) {
                    this.redirectPage();
                }
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            })

        } else {

            HttpService.fetch('api/FuncaoApi', {
                method: 'POST',
                body: HttpService.formToJson(event.target),
            }).then((response) => {
                return response.json() as Promise<FuncaoData>
            }).then((data) => {

                this.setState({ empData: data })
                if ($.isEmptyObject(data.error)) {
                    this.redirectPage();
                }
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            })
        }
    }

    // This will handle Cancel button click event.  
    private handleCancel(e) {
        e.preventDefault();
        this.redirectPage();
    }

    private redirectPage() {
        this.props.history.push("/funcao");
    }

    // Returns the HTML Form to the render() method.  
    private renderCreateForm() {
        return (<form onSubmit={this.handleSave} >
            <div className="form-group row" >
                <input type="hidden" name="id" value={this.state.empData.id} />
            </div>
            <div className="form-group row">
                <div className="col-md-6">
                    <label className="control-label col-md-12 required" htmlFor="Nome" >{<Resource keyString="office_name" />}</label>
                    <div className="col-md-12">
                        <ValidationLabelMessage errors={this.state.empData.error} name="Nome">
                            <input className="form-control" type="text" id="Nome" name="Nome" defaultValue={this.state.empData.nome} />
                        </ValidationLabelMessage>
                    </div>
                </div>
            </div >
            <div className="form-group col-md-12 d-flex justify-content-center">
                <button type="submit" className="btn font-weight-bold" style={{ backgroundColor: 'var(--ws-blue)', width: '17.5rem' }}>{<Resource keyString="save" />}</button>
            </div >
        </form >)
    }

}  