import * as React from 'react';
import $ from 'jquery';
import { Resource } from '../../index';

interface ModalDeleteState {
    id: string;
    id2: string;
    id3: string;
    message: string;
    callBack: any;
}

export class ModalDelete extends React.Component<any, ModalDeleteState> {
    constructor(props) {
        super(props);
        // This will set state for Add User  
        this.state = {
            id: props.id,
            id2: props.id2,
            id3: props.id3,
            message: props.message,
            callBack: props.callBack
        };

        // This binding is necessary to make "this" work in the callback  
        this.handleCallback = this.handleCallback.bind(this);
    }

    // This will handle the submit form event.  
    private handleCallback() {
        if (this.state.id3 != null &&  this.state.id2 != null)
            this.state.callBack.handleDelete(this.state.id, this.state.id2, this.state.id3);
        else if (this.state.id2 != null)
            this.state.callBack.handleDelete(this.state.id, this.state.id2);
        else
            this.state.callBack.handleDelete(this.state.id, undefined, this.state.id3);
        $('#delete' + this.state.id + ' [data-dismiss="modal"]').click();
    }

    // Returns the HTML Form to the render() method.  
    public render() {
        return (
            <div className="modal" id={`delete${this.state.id}`}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{this.state.message}</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>

                        <div className="modal-footer">
                            <button className="btn btn-danger" type="button" onClick={() => this.handleCallback()}><i className='fa fa-trash'></i> {<Resource keyString="delete" />}</button>
                            <button type="button" className="btn btn-link" data-dismiss="modal"><i className='fa fa-times'></i> {<Resource keyString="cancel" />}</button>
                        </div>

                    </div>
                </div>
            </div>)
    }

}  