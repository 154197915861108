import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { HttpService } from '../Shared/HttpService';
import { Loading } from '../Shared/Loading';
import { Resource } from '../../index';
import { LanguageContext } from '../../context/LanguageContext';
import CaixaDeConteudo from '../Shared/CaixaDeConteudo';
import ModalWinSet from '../Shared/components/ModalWinSet';

interface FetchFuncaoState {
    funcaoList: FuncaoData[];
    loading: boolean;
    modalDeDeletarEstaAberto: boolean;
    itemSelecionadoParaDeletar: FuncaoData | null;
}

export class FuncaoData {
    id: number = 0;
    nome: string = "";
    funcaopaiid: string = "";
    error: Array<any> = [];
}

export class FetchFuncao extends React.Component<RouteComponentProps<{}>, FetchFuncaoState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = { funcaoList: [], loading: true, modalDeDeletarEstaAberto: false, itemSelecionadoParaDeletar: null };

        Loading.showLoading(this);;
        HttpService.fetch('api/FuncaoApi', {})
            .then(response => response.json() as Promise<FuncaoData[]>)
            .then(data => {
                this.setState({ funcaoList: data, loading: false });
                Loading.hideLoading(this);
            });

        this.handleDelete = this.handleDelete.bind(this);
        this.handleEdit = this.handleEdit.bind(this);

    }

    private botoesDoCantoSuperiorDireito() {
        return (
            <button className='btn bg-white shadow-sm mr-2' onClick={() => this.props.history.push('/funcao/add')}>
                <Resource keyString="add" />
            </button>
        );
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;

        return (
            <CaixaDeConteudo
                tituloDoHeader={this.context.getLanguageResource('office')}
                botoes={this.botoesDoCantoSuperiorDireito()}
                itensParaBreadcrumb={[
                    { nome: this.context.getLanguageResource('home'), url: "/" },
                    { nome: this.context.getLanguageResource('office') },
                ]}
            >
                <React.Fragment>
                    <header className='d-flex align-items-center justify-content-center w-100 p-4'>
                        <h3>{<Resource keyString="office" />}</h3>
                    </header>
                    <main className='p-4'>
                        {this.renderUserTable(this.state.funcaoList)}
                    </main>
                </React.Fragment>
            </CaixaDeConteudo>
        );
    }

    // Handle Delete request for an user  
    private handleDelete(id: number) {
        Loading.showLoading(this);;
        HttpService.fetch('api/FuncaoApi/' + id, {
            method: 'delete'
        }).then(data => {
            this.setState(
                {
                    funcaoList: this.state.funcaoList.filter((rec) => {
                        return (rec.id != id);
                    })
                });
            Loading.hideLoading(this);
        }).catch(() => {
            Loading.hideLoading(this);
        });
    }

    private handleEdit(id: number) {
        this.props.history.push("/funcao/edit/" + id);
    }

    // Returns the HTML table to the render() method.  
    private renderUserTable(funcaoList: FuncaoData[]) {
        return <table className='table'>
            <thead>
                <tr>
                    <th>{<Resource keyString="office_name" />}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {funcaoList.map(doc =>
                    <tr key={doc.id}>
                        <td className='align-middle text-break'>{doc.nome}</td>
                        <td className='align-middle'>
                            <div className='d-flex justify-content-end'>
                                <button
                                    className="btn btn-outline-danger mr-2 pull-right"
                                    onClick={() => this.setState({ modalDeDeletarEstaAberto: true, itemSelecionadoParaDeletar: doc })}
                                    style={{ width: '2.75rem', height: '2.75rem' }}
                                >
                                    <i className='fa fa-trash'></i>
                                </button>
                                {
                                    (this.state.modalDeDeletarEstaAberto && this.state.itemSelecionadoParaDeletar && (this.state.itemSelecionadoParaDeletar.id === doc.id)) && (
                                        <ModalWinSet
                                            fecharModal={() => this.setState({ modalDeDeletarEstaAberto: false, itemSelecionadoParaDeletar: null })}
                                        >
                                            <div className='p-3'>
                                                <header className='d-flex w-100 mb-2 justify-content-center'>
                                                    <h4><Resource keyString="do_you_want_to_remove_the_function" />?</h4>
                                                </header>
                                                <hr />
                                                <main>
                                                    <p>{doc.nome}</p>
                                                </main>
                                                <footer className='d-flex w-100 justify-content-end'>
                                                    <button className="btn mr-2 ws-outline-blue" onClick={() => this.handleDelete(doc.id)}><Resource keyString="yes" /></button>
                                                    <button className="btn btn-outline-danger" onClick={() => this.setState({ modalDeDeletarEstaAberto: false, itemSelecionadoParaDeletar: null })}><Resource keyString="no" /></button>
                                                </footer>
                                            </div>
                                        </ModalWinSet>
                                    )
                                }
                                <button className="btn ws-outline-green-brown mr-2 pull-right" style={{ width: '2.75rem', height: '2.75rem' }} onClick={() => this.handleEdit(doc.id)}><i className='fa fa-edit'></i></button>
                            </div>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>;
    }
}   