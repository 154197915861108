import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { ArquivoData } from './FetchArquivo';
import { Layout } from '../Layout';
import { HttpService } from '../Shared/HttpService';
import { HeadLayout } from '../Shared/HeadLayout';
import { AuthService } from '../UserAuth/AuthService';
import { Loading } from '../Shared/Loading';
import $ from 'jquery';
import { ValidationLabelMessage, ValidationAlertMessage } from '../Shared/ValidationMessage';
import { Resource } from '../../index';
import { LanguageContext } from '../../context/LanguageContext';
import CaixaDeConteudo from '../Shared/CaixaDeConteudo';
import ModalWinSet from '../Shared/components/ModalWinSet';

interface AddArquivoDataState {
    empData: ArquivoData;
    disable: boolean;
    hidden: boolean;
    uploadFileName: string;
    modalDeConfirmacaoEstaAberto: boolean;
    botaoDeSalvarEstaInativo: boolean;
}

export class AddArquivo extends React.Component<RouteComponentProps<{}>, AddArquivoDataState> {
    static contextType = LanguageContext;
    constructor(props, context) {
        super(props);

        var documentoid = this.props.match.params["documentoid"];

        var arquivo = new ArquivoData;
        arquivo.documentosid = documentoid;

        // This will set state for Add User  
        this.state = {
            empData: arquivo,
            disable: false,
            hidden: true,
            uploadFileName: context.getLanguageResource("select_file"),
            modalDeConfirmacaoEstaAberto: false,
            botaoDeSalvarEstaInativo: false,
        };

        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    private botoesDoCantoSuperiorDireito() {
        return (
            <React.Fragment>
                <button className="btn bg-white shadow-sm mr-2" onClick={this.handleCancel}>{<Resource keyString="back" />}</button>
            </React.Fragment>
        );
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;

        return (
            <CaixaDeConteudo
                tituloDoHeader={this.context.getLanguageResource("create_file")}
                botoes={this.botoesDoCantoSuperiorDireito()}
                itensParaBreadcrumb={[
                    { url: "/", nome: this.context.getLanguageResource("home") },
                    { url: "/repositorio", nome: this.context.getLanguageResource("files") },
                    { nome: this.context.getLanguageResource("create_file") }
                ]}
            >
                <main className='p-4'>
                    {this.renderCreateForm()}
                </main>
            </CaixaDeConteudo>
        );
    }

    // This will handle the submit form event.  
    private handleSave(event) {
        event.preventDefault();

        const data = new FormData(event.target);

        Loading.showLoading(this);
        return HttpService.fetchFile('api/ArquivosApi', data)
            .then((data) => {
                this.setState({ empData: data.data });

                if ($.isEmptyObject(this.state.empData.error)) {
                    if (this.state.empData.error == null || (this.state.empData.error != null) && this.state.empData.error[0] == null) {
                        this.props.history.push(`/documentos/detail/${this.state.empData.documentosid}`);
                    }
                }
                Loading.hideLoading(this);
            }).catch((responseJson) => {
                Loading.hideLoading(this);
            });
    }

    // This will handle Cancel button click event.  
    private handleCancel(e) {
        e.preventDefault();
        this.redirectPage();
    }

    private redirectPage() {
        this.props.history.push(`/documentos/detail/${this.state.empData.documentosid}`);
    }

    uploadFile = (e) => {

        this.setState({ uploadFileName: e.target.files[0].name });

        var file = e.target.files[0];
        if (file.size > 29939152) {
            this.setState({ disable: true, hidden: false, botaoDeSalvarEstaInativo: true });
        }
        else {
            this.setState({ disable: false, hidden: true, botaoDeSalvarEstaInativo: false });
        }
    }

    dateMinGen = () => {
        const today = new Date();
        today.setDate(today.getDate() + 1);

        return today.toISOString().split('T')[0];
    }

    // Returns the HTML Form to the render() method.  
    private renderCreateForm() {
        const dataDeVencimento = new Date(`${this.state.empData.datavencimento}T00:00:00`);
        const dataDeVencimentoFormatada = dataDeVencimento.toLocaleDateString();

        return (
            <>
                <form onSubmit={this.handleSave} >
                    <div className="form-group row" >
                        <input type="hidden" name="userId" value={this.state.empData.id} />
                        <input type="hidden" name="documentosid" value={this.state.empData.documentosid} />
                    </div>
                    <div className="alert alert-danger" role="alert" hidden={this.state.hidden}>
                        {<Resource keyString="added_file_contains_size_larger_than_28MB" />}
                    </div>
                    < div className="form-group row" >
                        <label className=" control-label col-md-12 required" htmlFor="Versao">{<Resource keyString="version" />}</label>
                        <div className="col-md-4">
                            <ValidationLabelMessage errors={this.state.empData.error} name="Versao">
                                <input className="form-control" type="text" name="Versao" defaultValue={this.state.empData.versao} />
                            </ValidationLabelMessage>
                        </div>
                    </div >
                    <div className="form-group row">
                        <label className='control-label col-md-12 required' htmlFor="datavencimento"><Resource keyString="expiry_date" /></label>
                        <div className='col-md-4'>
                            <ValidationLabelMessage errors={this.state.empData.error} name="Datavencimento">
                                <input
                                    className='form-control'
                                    type="date"
                                    id="datavencimento"
                                    name="Datavencimento"
                                    value={(this.state.empData.datavencimento)}
                                    min={this.dateMinGen()}
                                    onChange={(e) => { this.setState({ empData: { ...this.state.empData, datavencimento: e.target.value } }) }}
                                    required
                                />
                            </ValidationLabelMessage>
                        </div>
                    </div>
                    < div className="form-group row" >
                        <label className=" control-label col-md-12 required" htmlFor="File">{<Resource keyString="file" />}</label>
                        <div className="col-md-4">
                            <ValidationLabelMessage errors={this.state.empData.error} name="File">
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input" id="customFile" name="File" onChange={(e) => this.uploadFile(e)} required />
                                    <label className="custom-file-label" htmlFor="customFile">{this.state.uploadFileName}</label>
                                </div>
                            </ValidationLabelMessage>
                            <label><i>{<Resource keyString="maximum_file_size_28MB" />}</i></label>
                        </div>
                    </div >
                    <div className="form-group col-12 d-flex justify-content-center">
                        <button
                            type="button"
                            onClick={(e) => { e.preventDefault(); this.setState({ modalDeConfirmacaoEstaAberto: true }); }}
                            className="btn font-weight-bold"
                            style={{ backgroundColor: 'var(--ws-blue)', width: '17.5rem' }}
                            disabled={this.state.botaoDeSalvarEstaInativo}
                        >
                            {<Resource keyString="save" />}
                        </button>
                    </div>
                    {
                        this.state.modalDeConfirmacaoEstaAberto && (
                            <ModalWinSet
                                fecharModal={() => this.setState({ modalDeConfirmacaoEstaAberto: false })}
                            >
                                <div className='d-flex flex-column p-3 justify-content-center w-100' style={{ maxWidth: '40rem' }}>
                                    <i className="bi bi-exclamation-triangle-fill text-center text-warning pb-3" style={{ fontSize: '2.5rem' }}></i>
                                    <h5 className='mb-3 text-center'><Resource keyString="attention" />!</h5>
                                    <p className='mb-0 text-center'><Resource keyString="file_effective_date_is_set_to" /> <strong>{ dataDeVencimentoFormatada }</strong>.</p>
                                    <p className='text-center'><Resource keyString="by_clicking_save_this_date_can_no_longer_be_changed_and_it_is_necessary_to_create_a_new_version_for_this_document" />!</p>
                                    <div className='d-flex justify-content-center'>
                                        <button className='btn ws-outline-blue mr-2' style={{ background: 'var(--ws-red)' }} onClick={(e) => { e.preventDefault(); this.setState({ modalDeConfirmacaoEstaAberto: false }); }}><Resource keyString="cancel" /></button>
                                        <button className='btn' type='submit' style={{ background: 'var(--ws-blue)' }}><Resource keyString="save" /></button>
                                    </div>
                                </div>
                            </ModalWinSet>
                        )
                    }
                </form >
            </>
        )
    }

}  