import * as React from 'react';
import BotaoDeArquivo from '../BotaoDeArquivo';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import { ResponderFormularioContext } from '../../contexts/ResponderFormularioContext';
import { criarArrayDePonteirosDasPerguntas } from '../../utils/geradoresDeArrayDePonteiros';
import ExclamationCircleIcon from '../../icons/ExclamationCircleIcon';
import ChevronDownIcon from '../../icons/ChevronDownIcon';
import ChevronUpIcon from '../../icons/ChevronUpIcon';
import ModalDeAnexoDeEvidencia from '../ModalDeAnexoDeEvidencia';

const { useContext, useEffect, useState } = React;

const ESPACO_OCUPADO_POR_BOTAO_DE_ANEXO = 54;
const CLASSE_DO_CARD = 'card';
const CLASSE_DO_CARD_NAO_PREEENCHIDO = 'card border-danger';
const CLASSE_DO_BOTAO_DE_ANEXO = 'btn btn-outline-primary mb-3 w-100';
const CLASSE_DO_BOTAO_DE_ANEXO_NAO_PREEENCHIDO = 'btn btn-outline-danger mb-3 w-100';

const CampoDeAnexoDeEvidencia = ({ evidenciasAnexadas, idDaPergunta }) => {
    const [modalDeAnexoDeEvidenciaAberto, setModalDeAnexoDeEvidenciaAberto] = useState(false);
    const [isOpen, setIsOpen] = useState(true);
    const [classesDeFeedbackDePreenchimento, setClassesDeFeedbackDePreenchimento] = useState({
        cardDeAnexoDeEvidencia: CLASSE_DO_CARD,
        botaoDeAnexarEvidencia: CLASSE_DO_BOTAO_DE_ANEXO,

    });
    const { responderFormulario, ultimaTentativaDeFinalizar, mostrarTodosOsCollapses } = useContext(ResponderFormularioContext);
    const perguntas = criarArrayDePonteirosDasPerguntas(responderFormulario);
    const pergunta = perguntas && perguntas.find((pergunta) => pergunta.id === idDaPergunta);

    const esteCampoEObrigatorioENaoEstaPreenchido = (): boolean => {
        const respostaSelecionada = pergunta && pergunta.respostas.find((resposta) => resposta.respostaFoiSelecionada);
        const campoNaoEstaPreenchido = evidenciasAnexadas.filter((evidencia) => evidencia.status !== "Removido").length === 0;
        if (pergunta !== undefined) {
            if (respostaSelecionada === undefined) return false;
            return respostaSelecionada.anexarEvidenciaObrigatoria && campoNaoEstaPreenchido;
        }
        return false;
    }

    const handleModalDeAnexoDeEvidenciaState = (boolean) => setModalDeAnexoDeEvidenciaAberto(boolean);

    useDidUpdateEffect(() => {
        const pergunta = perguntas.find(pergunta => pergunta.id === idDaPergunta);
        const respostaSelecionada = pergunta && pergunta.respostas.find((resposta) => resposta.respostaFoiSelecionada);

        if (pergunta?.evidenciaAnexada.length === 0 && respostaSelecionada?.anexarEvidenciaObrigatoria) {
            setClassesDeFeedbackDePreenchimento({
                cardDeAnexoDeEvidencia: CLASSE_DO_CARD_NAO_PREEENCHIDO,
                botaoDeAnexarEvidencia: CLASSE_DO_BOTAO_DE_ANEXO_NAO_PREEENCHIDO,
            });
        } else if (!respostaSelecionada?.anexarEvidenciaObrigatoria) {
            setClassesDeFeedbackDePreenchimento({
                cardDeAnexoDeEvidencia: CLASSE_DO_CARD,
                botaoDeAnexarEvidencia: CLASSE_DO_BOTAO_DE_ANEXO,
            });
        }
    }, [ultimaTentativaDeFinalizar]);

    useEffect(() => {
        if (evidenciasAnexadas.length > 0) {
            setClassesDeFeedbackDePreenchimento({
                cardDeAnexoDeEvidencia: CLASSE_DO_CARD,
                botaoDeAnexarEvidencia: CLASSE_DO_BOTAO_DE_ANEXO,
            });
        }
    }, [evidenciasAnexadas.length]);

    useDidUpdateEffect(() => setIsOpen(mostrarTodosOsCollapses), [mostrarTodosOsCollapses]);

    return (
        <div className='col-12 mb-4' style={ { maxHeight: '350px' } }>
            <div className={`${classesDeFeedbackDePreenchimento.cardDeAnexoDeEvidencia} ${isOpen && 'h-100'}`}  style={{ border: '1px solid var(--ws-blue-border)' }}>
                {/* <div
                    className={`d-flex justify-content-between p-2 ${isOpen ? 'rounded-top' : 'rounded'}`}
                    onClick={() => setIsOpen(!isOpen)}
                    style={{ cursor: 'pointer', backgroundColor: 'var(--ws-blue-light)' }}
                >
                    <div>
                        <span>Anexar evidência</span>
                        {
                            esteCampoEObrigatorioENaoEstaPreenchido()
                                ? <ExclamationCircleIcon className='ml-2' fill='#ef0000' />
                                : <React.Fragment />
                        }
                    </div>
                    <div>
                        {
                            isOpen
                                ? <ChevronDownIcon className='ml-2' fill='#000' />
                                : <ChevronUpIcon className='ml-2' fill='#000' />
                        }
                    </div>
                </div> */}
                <div className={`card-body ${isOpen ? '' : 'd-none'}`}>
                    <button
                        className={classesDeFeedbackDePreenchimento.botaoDeAnexarEvidencia}
                        onClick={() => setModalDeAnexoDeEvidenciaAberto(true)}
                        data-target={ `#telaDeAnexoDeEvidencia${idDaPergunta}` }
                        data-toggle='modal'
                        type='button'
                    >
                        <i className='fa fa-paperclip mr-3' />
                        Anexar evidências
                    </button>
                    {
                        modalDeAnexoDeEvidenciaAberto
                            ?   <ModalDeAnexoDeEvidencia
                                    id={idDaPergunta}
                                    evidenciasJaAnexadas={evidenciasAnexadas.filter((evidencia) => evidencia.status !== "Removido")}
                                    setModalState={handleModalDeAnexoDeEvidenciaState}
                                />
                            :   <React.Fragment />
                    }
                    <div className='barra_de_rolagem_estilizada' style={{ overflowY: 'auto', height: `calc(100% - ${ESPACO_OCUPADO_POR_BOTAO_DE_ANEXO}px)` }}>
                        <div>
                            {
                                evidenciasAnexadas.length > 0
                                    ? evidenciasAnexadas.map(evidencia => {
                                        if (evidencia.status !== 'Removido') {
                                            return <BotaoDeArquivo evidencia={evidencia} idDaPergunta={idDaPergunta} />;
                                        } else {
                                            return <React.Fragment />;
                                        }
                                    })
                                    : <div className='text-center'>Nenhuma evidência anexada</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CampoDeAnexoDeEvidencia;