import * as React from 'react';
import { Resource } from '../../index';

export class Util {

    public static AtivadoDesativado(valor) {
        return valor ? <Resource keyString="activated" /> : <Resource keyString="disabled" />;
    }

    public static toggleCollapse(seletor) {
        var elements = document.querySelectorAll(seletor);
       
        elements.forEach(function (element) {
            element.classList.toggle("d-none");
        });
    }
}
