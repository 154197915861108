import React, { useEffect, useRef } from "react";

interface IProps {
    children: React.ReactNode;
    fecharModal: () => void;
    zIndex?: number;
    aoMontarModal?: () => void;
    impedirFechamentoAoClicarFora?: boolean;
    modalStyle?: React.CSSProperties;
}

const ModalWinSet = ({ children, fecharModal, zIndex = 1000, aoMontarModal, impedirFechamentoAoClicarFora, modalStyle }: IProps) => {
    const overlayRef = useRef<HTMLDivElement>(null);

    const handleFecharModal = () => {
        fecharModal();
    };

    const handleClickOutside = (event: any) => {
        if (impedirFechamentoAoClicarFora) return;
        if (overlayRef.current && event.target === overlayRef.current) {
            handleFecharModal();
        }
    };

    const buscarPreferencias = () => {
        const preferencias = localStorage.getItem("preferencias");
        if (preferencias) {
            return JSON.parse(preferencias);
        }
        return {};
    }

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                handleFecharModal();
            }
        };

        if (aoMontarModal) {
            aoMontarModal();
        }
        
        document.body.style.overflow = "hidden";
        window.addEventListener("keydown", handleKeyDown);

        return () => {
            document.body.style.overflow = "auto";
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [aoMontarModal, fecharModal]);

    return (
        <React.Fragment>
            <div
                ref={overlayRef}
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(0, 0, 0, 0.5)",
                    zIndex: zIndex,
                    overflowY: "auto",
                }}
                onClick={handleClickOutside}
                className="d-flex justify-content-center align-items-center overlay"
            >
                <div
                    className='d-flex flex-column justify-content-start bg-white shadow rounded'
                    style={{
                        position: "absolute",
                        minWidth: "400px",
                        minHeight: "100px",
                        zIndex: zIndex + 1,
                        overflow: "hidden",
                        maxHeight: "calc(100% - 32px)",
                        maxWidth: "calc(100vw - 48px)",
                        ...modalStyle,
                    }}
                >
                    { /* @ts-ignore */  }
                    <div className='w-100' style={{ background: buscarPreferencias().corPrincipal, height: "1rem" }} />
                    <div className='w-100 barra_de_rolagem_estilizada' style={{ height: 'calc(100% - 1rem)', overflow: 'auto' }}>
                        { children }
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ModalWinSet;

