import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { OpcaoCampoMultiplaEscolhaData } from './FetchModeloCadastroColuna';
import { HeadLayout } from '../../Shared/HeadLayout';
import { Layout } from '../../Layout';
import { HttpService } from '../../Shared/HttpService';
import { Loading } from '../../Shared/Loading';
import $ from 'jquery';
import { Resource } from '../../../index';
import { ValidationLabelMessage } from '../../Shared/ValidationMessage';
import { ModeloCadastroColunaData } from './FetchModeloCadastroColuna';
import { LanguageContext } from '../../../context/LanguageContext';
import CaixaDeConteudo from '../../Shared/CaixaDeConteudo';

interface AddModeloCadastroColunaDataState {
    id: string;
    loading: boolean;
    empData: ModeloCadastroColunaData;
    callBack: any;
    valorSelecionado: any;
    modeloCadastroid: any;
    formatoCampoSelecionado: any;
}

export enum ModeloCadastroTipoCampo {
    Texto = 1,
    Numerico,
    Data,
    Hora,
    Flag,
    MultiplaEscolha
}

export class AddModeloCadastroColuna extends React.Component<RouteComponentProps<{}>, AddModeloCadastroColunaDataState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            empData: new ModeloCadastroColunaData,
            id: props.id,
            modeloCadastroid: "",
            callBack: props.callBack,
            valorSelecionado: "",
            formatoCampoSelecionado: ""
        };

        var modeloCadastroColunaId = this.props.match.params["modeloCadastroColunaid"];
        var modeloCadastroId = this.props.match.params["modeloCadastroId"];

        // This will set state for Edit User  
        if (modeloCadastroColunaId > 0) {
            Loading.showLoading(this);
            HttpService.fetch('api/ModeloCadastroColunaApi/' + modeloCadastroColunaId, { method: 'GET' })
                .then(response => response.json() as Promise<ModeloCadastroColunaData>)
                .then(data => {
                    this.setState({
                        loading: false,
                        empData: data,
                        modeloCadastroid: modeloCadastroId,
                    });

                    Loading.hideLoading(this);
                }).catch(() => {
                    Loading.hideLoading(this);

                });
        }

        // This will set state for Add User  
        else {
            this.state = {
                loading: false,
                empData: new ModeloCadastroColunaData,
                id: props.id,
                callBack: props.callBack,
                valorSelecionado: "",
                modeloCadastroid: modeloCadastroId,
                formatoCampoSelecionado: ""
            };
        }

        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleFormatoCampoSelecionado = this.handleFormatoCampoSelecionado.bind(this);
        this.handleMontarObjetoOpcoes = this.handleMontarObjetoOpcoes.bind(this);
        this.handleRemoverElementoDoObjetoOpcoes = this.handleRemoverElementoDoObjetoOpcoes.bind(this);
    }

    private handleCancel(e) {
        e.preventDefault();
        this.redirectPage();
    }

    private redirectPage() {
        var id = this.state.empData.modelocadastroid > 0 ? this.state.empData.modelocadastroid : this.state.modeloCadastroid;
        this.props.history.push("/modeloCadastro/edit/" + id);
    }

    private handleSave(event) {
        event.preventDefault();

        let modeloCadastroColunaId = this.state.empData.id;

        Loading.showLoading(this);

        var obj: any = HttpService.formatData(event.target);
        obj['modeloCadastroCamposMultiplaEscolha'] = this.state.empData.modeloCadastroCamposMultiplaEscolha;

        // PUT request for Edit User.  
        if (modeloCadastroColunaId) {

            HttpService.fetch('api/ModeloCadastroColunaApi/' + modeloCadastroColunaId, {
                method: 'PUT',
                body: JSON.stringify(obj),
            }).then((response) => {
                return response.json() as Promise<ModeloCadastroColunaData>
            }).then((data) => {

                this.setState({ empData: data })
                if ($.isEmptyObject(data.error)) {
                    this.redirectPage();
                }
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            })

        } else {
            HttpService.fetch('api/ModeloCadastroColunaApi/Salvar', {
                method: 'POST',
                body: JSON.stringify(obj),
            }).then((response) => {
                return response.json() as Promise<ModeloCadastroColunaData>
            }).then((data) => {

                this.setState({ empData: data })
                if ($.isEmptyObject(data.error)) {
                    this.redirectPage();
                }
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            });
        }
    }

    private handleFormatoCampoSelecionado(event) {
        event.preventDefault();
        
        this.state.empData.modelocadastrotipocampoid = event.target.value;
        this.setState({ empData: this.state.empData });
        this.forceUpdate();
    }

    private handleMontarObjetoOpcoes(event) {
        event.preventDefault();
        const campoNome = document.getElementById("nomeOpcoes") as HTMLInputElement;
        var opcao: OpcaoCampoMultiplaEscolhaData = new OpcaoCampoMultiplaEscolhaData();
        opcao.nome = campoNome.value;
        campoNome.value = "";
        this.state.empData.modeloCadastroCamposMultiplaEscolha.push(opcao as any);
        this.setState({ empData: this.state.empData });
    }

    private handleRemoverElementoDoObjetoOpcoes(index) {
        this.state.empData.modeloCadastroCamposMultiplaEscolha
            .filter((x) => { return x.isactive == true })
            .map((item, oindex) => {
                if (oindex == index) {
                    item.isactive = false;
                }
            });
        this.setState({ empData: this.state.empData });
    }

    private botoesDoCantoSuperiorDireito() {
        return (
            <React.Fragment>
                <button className="btn bg-white shadow-sm mr-2" onClick={this.handleCancel}>{<Resource keyString="back" />}</button>
            </React.Fragment>
        );
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;

        return (
            <CaixaDeConteudo
                tituloDoHeader='Campos Modelo de Cadastro'
                botoes={this.botoesDoCantoSuperiorDireito()}
                itensParaBreadcrumb={[
                    { url: "/", nome: this.context.getLanguageResource("home") },
                    { nome: 'Campos Modelo de Cadastro' }
                ]}
            >
                <main className='p-4'>
                    {this.renderUserTable()}
                </main>
            </CaixaDeConteudo>
        );
    }

    private renderUserTable() {
        var itensMultiplaEscolha =
            this.state.empData.modelocadastrotipocampoid == 6 ?
                <div className="form-group row">
                    <div className="col-md-12">
                        <ValidationLabelMessage errors={this.state.empData.error} name="Opcoes">
                            <label className="control-label col-md-12 " htmlFor="nomeOpcoes" >{<Resource keyString="options" />}</label>
                            <div className="col-md-12 d-flex pb-2">
                                <input className="form-control mr-2" type="text" id="nomeOpcoes" name="nomeOpcoes" />
                                <button className="btn btn-primary pull-right" type="button"
                                    onClick={this.handleMontarObjetoOpcoes}>
                                    <i className='fa fa-plus'></i>
                                </button>
                            </div>
                        </ValidationLabelMessage>
                    </div>
                    <div className="col-md-12 px-4">
                        <table className='table border'>
                            <tbody >
                                {this.state.empData.modeloCadastroCamposMultiplaEscolha
                                    .filter((x) => { return x.isactive == true })
                                    .map((doc: any, index) =>
                                        <tr key={index}>
                                            <td className=" p-1">{doc.nome}</td>
                                            <td className=" p-1">
                                                <button className="btn btn-outline-danger mr-2 pull-right" type="button"
                                                    onClick={() => this.handleRemoverElementoDoObjetoOpcoes(index)} >
                                                    <i className='fa fa-trash'></i>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                :
                "";

        return <form onSubmit={this.handleSave} >
            <div className="form-group row" >
                <input type="hidden" name="id" value={this.state.empData.id} />
                <input type="hidden" name="modelocadastroid" value={this.state.empData.modelocadastroid > 0 ? this.state.empData.modelocadastroid : this.state.modeloCadastroid} />
            </div>
            <div className="form-group row">
                <div className="col-md-12">
                    <label className="control-label col-md-12 required" htmlFor="nome" >{<Resource keyString="field_name" />}</label>
                    <div className="col-md-12">
                        <ValidationLabelMessage errors={this.state.empData.error} name="Nome">
                            <input className="form-control" type="text" id="nome" name="nome" defaultValue={this.state.empData.nome} />
                        </ValidationLabelMessage>
                    </div>
                </div>
            </div >
            <div className="form-group row">
                <div className="col-md-12">
                    <label className="control-label col-md-12" htmlFor="modelocadastrotipocampoid">{<Resource keyString="field_format" />}</label>
                    <div className="col-md-12">
                        <select className="form-control col-md-12" name="modelocadastrotipocampoid" id="modelocadastrotipocampoid"
                        value={(this.state.empData.modelocadastrotipocampoid) ? this.state.empData.modelocadastrotipocampoid.toString() : ''}
                        onChange={this.handleFormatoCampoSelecionado}>
                            <option value={ModeloCadastroTipoCampo.Texto}>{this.context.getLanguageResource("text")}</option>
                            <option value={ModeloCadastroTipoCampo.Numerico}>Numérico</option>
                            <option value={ModeloCadastroTipoCampo.Data}>{this.context.getLanguageResource("date")}</option>
                            <option value={ModeloCadastroTipoCampo.Hora}>Hora</option>
                            <option value={ModeloCadastroTipoCampo.MultiplaEscolha}>Multipla Escolha</option>
                        </select>
                    </div>
                </div>
            </div >
            {itensMultiplaEscolha}
            <div className="form-group row">
                <div className="col-md-12">
                    <label className="control-label col-md-12" htmlFor="ordenacao" >{<Resource keyString="ordination" />}</label>
                    <div className="col-md-12">
                        <ValidationLabelMessage errors={this.state.empData.error} name="ordenacao">
                            <input className="form-control" type="number" id="ordenacao" name="ordenacao" defaultValue={this.state.empData.ordenacao} />
                        </ValidationLabelMessage>
                    </div>
                </div>
            </div >
            <div className="form-group col-md-12 d-none" >
                <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" name="isactive" id="isactive" defaultChecked={this.state.empData.isactive} />
                    <label className="custom-control-label" htmlFor="isactive">{<Resource keyString="active" />}</label>
                </div>
            </div>
            <div className="form-group col-12 d-flex justify-content-center">
                <button type="submit" className="btn font-weight-bold" style={{ backgroundColor: 'var(--ws-blue)', width: '17.5rem' }}>{<Resource keyString="save" />}</button>
            </div >
        </form >
    }
}