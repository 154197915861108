import * as React from "react";
import { Resource } from "../../../../../index";
import HeaderDeCollapseEspecifico from "../HeaderDeCollapseEspecifico";
import ContagemDeResposta from "../ContagemDeResposta";
import CabecalhoResultado from "../CabecalhoResultado";
import ContainerDeGruposDePerguntas from "../ContainerDeGruposDePerguntas";
import PlanoAcaoFormulario from "../PlanoAcaoFormulario";

const ConteudoDeResultadoComFormula = ({ resultadoDoFormulario }) => {
	return (
		<React.Fragment>
			<div className="w-100">

				<div className="border rounded my-3">
					<div className="d-flex justify-content-between p-4">
						<section>
							<p className="mb-0">{resultadoDoFormulario.formulario.nome}</p>
							<small>
								{
									resultadoDoFormulario.formularioIniciado.titulo != null
										? resultadoDoFormulario.formularioIniciado.titulo
										: resultadoDoFormulario.formularioIniciado.hash
								}
							</small>
						</section>

						<section className="d-flex">
							<div className="mx-4 p-3 d-flex align-items-center justify-content-center flex-column rounded" style={{ boxShadow: '0 0 0.4rem var(--ws-blue-soft)' }}>
								<h3>
									{
										resultadoDoFormulario.resultadoFormulario.pontuacaototal > 0
											? (
												<React.Fragment>
													{resultadoDoFormulario.resultadoFormulario.valor}/
													{resultadoDoFormulario.resultadoFormulario.pontuacaototal}
												</React.Fragment>
											)
											: 0
									}
								</h3>
								<p className="mb-0">
									{<Resource keyString="score_consolidated" />}
								</p>
							</div>

							<div className="mx-4 p-3 d-flex align-items-center justify-content-center flex-column rounded" style={{ boxShadow: '0 0 0.4rem var(--ws-blue-soft)' }}>
								<h3>
									{
										resultadoDoFormulario.resultadoFormulario.valorporcentagem.toFixed(2) + "%"
									}
								</h3>
								<p className="mb-0">
									{<Resource keyString="consolidated_percentage" />}
								</p>
							</div>
						</section>

						{
							resultadoDoFormulario.resultadoFormulario.notaPelaFormulaResultado && (
								<section>
									<div className="mx-2 p-2 d-flex align-items-center justify-content-center flex-column rounded">
										<h3 className="text-center col-sm-12">{resultadoDoFormulario.resultadoFormulario.notaPelaFormulaResultado}</h3>
										<p className="col-sm-12 text-center mt-0 pt-0 mb-0 pb-0">
											<Resource keyString="result" />
										</p>
									</div>
								</section>
							)
						}
					</div>

				</div>
			</div>

			<div className={`d-flex align-items-stretch border flex-column rounded p-4 my-3 ${resultadoDoFormulario.resultadoXCabecalho.filter(cabecalho => !cabecalho.rodape) <= 0 ? '_IMPRIMIR_OMITIR_' : ''}`}>
				<CabecalhoResultado
					resultadoComCabecalho={resultadoDoFormulario.resultadoXCabecalho.filter(cabecalho => !cabecalho.rodape)}
				/>
			</div>

			<div>
				<HeaderDeCollapseEspecifico alvo="collapse-contagem-resposta" resource="response_count" />
				<ContagemDeResposta respostas={resultadoDoFormulario.resultadoFormulario.consolidadoResposta} id="collapse-contagem-resposta" />
			</div>

			<hr />

			<ContainerDeGruposDePerguntas
				listaDeGruposDePerguntas={resultadoDoFormulario.listaGrupoPergunta}
				resultadoDoFormulario={resultadoDoFormulario}
			/>

			{
				resultadoDoFormulario.resultadoPlanoAcao != null && resultadoDoFormulario.resultadoPlanoAcao.some((planoAcao) => planoAcao.perguntaid === null)
					? (
						<PlanoAcaoFormulario
							resultadoPlanoAcao={resultadoDoFormulario.resultadoPlanoAcao.filter(planoAcao => planoAcao.perguntaid === null)}
						/>
					)
					: <React.Fragment />
			}

			<hr />

			<div className={`d-flex align-items-stretch border flex-column rounded p-4 my-3 ${resultadoDoFormulario.resultadoXCabecalho.filter(cabecalho => cabecalho.rodape) <= 0 ? '_IMPRIMIR_OMITIR_' : ''}`}>
				<CabecalhoResultado
					resultadoComCabecalho={resultadoDoFormulario.resultadoXCabecalho.filter(cabecalho => cabecalho.rodape)}
					rodape
				/>
			</div>
		</React.Fragment>
	);
};

export default ConteudoDeResultadoComFormula;
