import * as React from 'react';

export class ValidationAlertMessage extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = { errors: this.props.errors, name: this.props.name };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ errors: nextProps.errors, name: nextProps.name });
    }

    public render() {
        const children = this.props.children != null
            ?   <div className="border border-danger rounded">
                    {this.props.children}
                </div>
            :   <React.Fragment />;

        if (this.state.errors != null && this.state.errors[this.state.name] != null) {
            return (
                <div className="text-danger" role="alert">
                    {children}
                    <div className="alert alert-danger">
                        <i className='fa fa-exclamation-circle'></i> {this.state.errors[this.state.name]}
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    {this.props.children}
                </div>
            );
        }
    }
}


export class ValidationLabelMessage extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = { errors: this.props.errors, name: this.props.name };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ errors: nextProps.errors, name: nextProps.name });
    }

    public render() {
        const children = this.props.children != null
            ?   <div className="border border-danger rounded">
                    {this.props.children}
                </div>
            :   <React.Fragment />;

        if (this.state.errors != null && this.state.errors[this.state.name] != null) {
            return  (
                <div className="text-danger" role="alert">
                    {children}
                    {this.state.errors[this.state.name]}
                </div>
            );
        } else {
            return (
                <div>
                    {this.props.children}
                </div>
            );
        }
    }
}

