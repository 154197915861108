import React, { useState } from "react";
import styled from "styled-components";

interface IProps {
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    checked?: boolean;
    id: string;
    widthEmRem?: string;
}

export const ToggleSwitch = ({ onChange, checked, id, widthEmRem }: IProps) => {
    const [isChecked, setIsChecked] = useState(checked);

    const handleOnChange = (event) => {
        setIsChecked(event.target.checked);
        onChange && onChange(event);
    };

    return (
        <ToggleWrapper width={widthEmRem ? parseFloat(widthEmRem) : 4}>
            <input type="checkbox"onChange={handleOnChange} id={id} name={id} checked={isChecked} />
            <span></span>
        </ToggleWrapper>
    )
};

interface IStyledProp {
    width: string;
}

const ToggleWrapper = styled.label<IStyledProp>`
    background: #eee;
    width: ${props => props.width + 'rem'};
    height: ${props => (props.width / 2).toString() + 'rem'};
    border-radius: ${props => (props.width / 4).toString() + 'rem'};
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    input {
        height: 100%;
        width: 100%;
        opacity: 0;
    }

    span {
        position: absolute;
        width: ${props => ((props.width / 2) * .7).toString() + 'rem'};
        height: ${props => ((props.width / 2) * .7).toString() + 'rem'};
        border-radius: ${props => (props.width / 4).toString() + 'rem'};
        background: lightgray;
        left: ${props => ((props.width / 4) * .3).toString() + 'rem'};
        box-shadow: 2px 2px 4px gray;
        cursor: pointer;
        transition: .25s;
    }

    input:checked + span {
        left: calc(100% - ${props => (props.width / 2).toString() + 'rem'});
        background: var(--ws-blue);
    } 
`;
