import React, { useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import { FormularioRespondidoContext } from '../../context/FormularioRespondidoContext';

const MostrarFecharCollapse = () => {
    const { mostrarTodosOsCollapses, setMostrarTodosOsCollapses } = useContext(FormularioRespondidoContext);
    const { getLanguageResource } = useContext(LanguageContext);

    return (
        <button
            type="button"
            className="btn ws-outline-blue mx-1"
            id="btnAbrirFecharTodosCollapse"
            onClick={() => setMostrarTodosOsCollapses(!mostrarTodosOsCollapses)}
            style={{ height: '3rem', minWidth: '3rem' }}
        >
            <i className={mostrarTodosOsCollapses ? 'fa fa-window-minimize' : 'fa fa-window-maximize'} style={{ fontSize: '1.2rem', lineHeight: '1.2rem' }} aria-hidden="true" />
            <span className='ml-2'>{mostrarTodosOsCollapses ? getLanguageResource("hide_all") : getLanguageResource("show_all")}</span>
        </button>
    );
}

export default MostrarFecharCollapse;
