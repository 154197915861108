import * as React from 'react';
import { HttpService } from '../Shared/HttpService';
import { Loading } from '../Shared/Loading';
import { AssinarArquivo } from './AssinarArquivo';
import { DocumentoData } from '../Documentos/FetchDocumentos';
import { CompartilharArquivo } from './CompartilharArquivo';
import { Resource } from '../../index';
import { LanguageContext } from '../../context/LanguageContext';
import ModalWinSet from '../Shared/components/ModalWinSet';
import VisualizadorDeArquivos from '../Shared/components/VisualizadorDeArquivos';

interface IFile {
    url: string;
    type: string;
}

interface FetchArquivoState {
    arquivoList: ArquivoData[];
    loading: boolean;
    documento: DocumentoData;
    modalDeCompartilhamentoEstaAberto: boolean;
    arquivoSelecionadoParaCompartilhamento: ArquivoData | null;
    visualizadorDeArquivoEstaAberto: boolean;
    file: IFile | null;
    arquivoSelecionadoParaVisualizar: number | null;
}

export class ArquivoData {
    id: number = 0;
    empresasid: number = 0;
    versao: string = "";
    caminhovirtual: string = "";
    datavencimento: string = "";
    documentosid: number = 0;
    file: any = {};
    error: Array<any> = [];

    elaboradorid: number = 0;
    revisorid: number = 0;
    auditorid: number = 0;

    arquivado: boolean = false;
    vigente: boolean = false;
}

export class FetchArquivo extends React.Component<any, FetchArquivoState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        this.state = {
            arquivoList: [],
            loading: true,
            documento: props.documento,
            modalDeCompartilhamentoEstaAberto: false,
            arquivoSelecionadoParaCompartilhamento: null,
            visualizadorDeArquivoEstaAberto: false,
            file: null,
            arquivoSelecionadoParaVisualizar: null,
        };
        this.updateArquivos();
        this.handleDownload = this.handleDownload.bind(this);

    }
    
    openViewer = async (arquivo) => {
        if (!arquivo) return;
        this.setState({ visualizadorDeArquivoEstaAberto: true, arquivoSelecionadoParaVisualizar: arquivo.arquivoId });

        const urlRequest = `api/ArquivosApi/Visualizar/${arquivo.arquivoId}/${arquivo.empresaId}`;
        
        try {
            const response = await HttpService.fetch(urlRequest, { method: 'GET' });
            if (!response.ok) {
                throw new Error("Erro ao fazer download do arquivo");
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            this.setState({ file: { url, type: blob.type } });
        } catch (error) {
            console.log(error);
        }
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;
        const _auth = JSON.parse(localStorage.getItem('auth') as string);

        return (
            <React.Fragment>
                <div className='w-50 pr-2'>
                    <header className='d-flex justify-content-between p-2 align-items-center' style={{ backgroundColor: 'var(--ws-blue-light)' }}>
                        <p className='mb-0'><Resource keyString="files_version" /></p>
                        <button className="btn btn-outline-dark mr-2" onClick={() => window.location.href = `/arquivo/add/${this.state.documento.id}`}><i className='fa fa-plus'></i> <Resource keyString="add" /></button>
                    </header>
                    {
                        this.state.arquivoList.length > 0
                            ?   (
                                    <table className='table mt-3'>
                                        <thead>
                                            <tr>
                                                <th className='border-0'>{<Resource keyString="version" />}</th>
                                                <th className='border-0'>{<Resource keyString="situation" />}</th>
                                                <th className='border-0'></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.arquivoList.map(doc =>
                                                <tr key={doc.id} className={doc.vigente == true ? "table-success" : ""}>
                                                    <td className='border-0 align-middle'>{doc.versao}</td>
                                                    <td className='border-0 align-middle'>{this.situacaoArquivo(doc)}</td>
                                                    <td className='border-0 align-middle d-flex justify-content-end'>
                                                        <button className="btn ws-outline-blue mr-2" title="Visualizar" style={{ width: '2.75rem', height: '2.75rem' }} onClick={(_id) => this.openViewer({ arquivoId: doc.id, empresaId: doc.empresasid })}><i className="bi bi-search"></i></button>
                                                        {
                                                            _auth.tipodeconta === 0
                                                                ?   (
                                                                        <button className="btn mr-2" style={{ width: '2.75rem', height: '2.75rem', border: '1px solid var(--ws-green-brown)', color: 'var(--ws-green-brown)' }} title="É necessário realizar um upgrade no plano contratado para habilitar esse recurso" disabled><i className="fa fa-share"></i></button>
                                                                    )
                                                                :   (
                                                                        <button className="btn ws-outline-green-brown mr-2" style={{ width: '2.75rem', height: '2.75rem' }} title={this.context.getLanguageResource("share")} onClick={() => this.setState({ modalDeCompartilhamentoEstaAberto: true, arquivoSelecionadoParaCompartilhamento: doc })}><i className="fa fa-share"></i></button>
                                                                    )
                                                        }
                                                        {this.mostrarBotaoResponsavel(doc)}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                )
                            :   <div className='w-100 py-4 text-center'>Não há arquivos vinculados</div>
                    }
                </div>
                {
                    this.state.visualizadorDeArquivoEstaAberto && (
                        <VisualizadorDeArquivos
                            fecharModal={() => this.setState({ visualizadorDeArquivoEstaAberto: false, arquivoSelecionadoParaVisualizar: null, file: null })}
                            tipo={this.state.file !== null ? this.state.file.type.split('/')[1] : null}
                            url={this.state.file !== null ? this.state.file.url : null}
                            impedirFechamentoAoClicarFora
                            extensao={this.state.arquivoList.filter(x => x.id === this.state.arquivoSelecionadoParaVisualizar)[0].caminhovirtual.split('.')[this.state.arquivoList.filter(x => x.id === this.state.arquivoSelecionadoParaVisualizar)[0].caminhovirtual.split('.').length - 1]}
                        />
                    )
                }
            </React.Fragment>
        );
    }

    public updateArquivos() {
        Loading.showLoading(this);
        HttpService.fetch('api/ArquivosApi/All/' + this.state.documento.id, {})
            .then(response => response.json() as Promise<ArquivoData[]>)
            .then(data => {
                this.setState({ arquivoList: data, loading: false });
                Loading.hideLoading(this);
            });
    }

    private handleDownload(id: number) {
        Loading.showLoading(this);
        HttpService.fetch('api/ArquivosApi/Download/' + id, {
            method: 'get'
        })
            .then(response => response.json() as Promise<ArquivoData>)
            .then(data => {
                window.location.href = `${document.location.protocol}//${process.env.REACT_APP_HOST_API}/api/ArquivosApi/Download/${data.id}/${data.empresasid}`;
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            });
    }

    private situacaoArquivo(doc: ArquivoData) {
        let situacao = <Resource keyString="awaiting_review" />;
        if (doc.auditorid > 0) {
            situacao = <Resource keyString="approved" />;
        } else if (doc.revisorid > 0) {
            situacao = <Resource keyString="waiting_for_auditor" />;
        }
        return situacao;
    }

    private mostrarBotaoResponsavel(doc: ArquivoData): any {

        let button = <React.Fragment />;
        let assinar = <React.Fragment />;
        let arquivar = <React.Fragment />;
        let tornarVigente = <React.Fragment />;

        if (!(doc.revisorid > 0) && this.state.documento.perfil.revisao == true) {
            button = <button className="btn ws-outline-blue mr-2" style={{ width: '2.75rem', height: '2.75rem' }} title="Tornar vigente" data-toggle="modal" data-target={"#assinar" + doc.id}><i className="fa fa-check"></i></button>;
        } else if (doc.revisorid > 0 && !(doc.auditorid > 0) && this.state.documento.perfil.aprovacao == true) {
            button = <button className="btn ws-outline-blue mr-2" style={{ width: '2.75rem', height: '2.75rem' }} title="Tornar vigente" data-toggle="modal" data-target={"#assinar" + doc.id}><i className="fa fa-check"></i></button>;
        }

        if (doc.revisorid > 0 && doc.auditorid > 0) {
            if (!doc.vigente && this.state.documento.perfil.aprovacao == true) {
                button = <React.Fragment>{button}<button className="btn ws-outline-blue mr-2" style={{ width: '2.75rem', height: '2.75rem' }} title="Tornar vigente" data-toggle="modal" data-target={"#tornarVigente" + doc.id}><i className="fa fa-check-square"></i></button></React.Fragment>;
                tornarVigente = <AssinarArquivo tornarVigente={true} key={"tornarVigente" + doc.id} arquivo={doc} id={"tornarVigente" + doc.id} callBack={this} />
            }
        } else if (this.state.documento.perfil.revisao == true || this.state.documento.perfil.aprovacao == true) {
            assinar = <AssinarArquivo assinar={true} key={"assinar" + doc.id} arquivo={doc} id={"assinar" + doc.id} callBack={this} />
        }

        if (this.state.documento.perfil.exclusao == true || this.state.documento.perfil.aprovacao == true) {
            button = doc.vigente ? button : <React.Fragment>{button}<button className="btn btn-outline-danger mr-2" style={{ width: '2.75rem', height: '2.75rem' }} title="Arquivar" data-toggle="modal" data-target={"#arquivar" + doc.id}><i className="fa fa-archive"></i></button></React.Fragment>;
            arquivar = <AssinarArquivo arquivar={true} key={"arquivar" + doc.id} arquivo={doc} id={"arquivar" + doc.id} callBack={this} />
        }

        return <React.Fragment>
            {button}
            {assinar}
            {tornarVigente}
            {arquivar}
            {
                this.state.modalDeCompartilhamentoEstaAberto && this.state.arquivoSelecionadoParaCompartilhamento
                    ?   (
                            <ModalWinSet
                                fecharModal={() => this.setState({ modalDeCompartilhamentoEstaAberto: false, arquivoSelecionadoParaCompartilhamento: null })}
                            >
                                <CompartilharArquivo arquivar={true} key={"compartilhar" + this.state.arquivoSelecionadoParaCompartilhamento.id} arquivo={this.state.arquivoSelecionadoParaCompartilhamento} id={"compartilhar" + this.state.arquivoSelecionadoParaCompartilhamento.id} callBack={this} fecharModal={() => this.setState({ modalDeCompartilhamentoEstaAberto: false, arquivoSelecionadoParaCompartilhamento: null })} />
                            </ModalWinSet>
                        )
                    :   <React.Fragment />
            }
        </React.Fragment>;
    }
}
