import React, { useState, useRef, useEffect } from "react";

export const CardDeImagemAnexada = ({ urlDaImagem }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [mouseIsEntered, setMouseIsEntered] = useState(false);

    useEffect(() => {
        if (ref.current) {
            ref.current.style.opacity = mouseIsEntered ? '1' : '0';
        }
    }, [mouseIsEntered]);

    return (
        <div
            className='p-1 border rounded shadow-sm d-flex justify-content-center align-items-center _IMPRIMIR_OMITIR_'
            style={{ position: 'relative', width: '33.33%' }}
        >
            <div
                className="w-100"
                onMouseEnter={() => setMouseIsEntered(true)}
                onMouseLeave={() => setMouseIsEntered(false)}
                style={{
                    height: '25rem',
                    backgroundImage: `url(${urlDaImagem})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}
            />
            <div
                ref={ref}
                onMouseEnter={(e) => { setMouseIsEntered(true); e.currentTarget.style.fontSize = '3.5rem'; }}
                onMouseLeave={(e) => { setMouseIsEntered(false); e.currentTarget.style.fontSize = '3rem'; }}
                onClick={() => window.open(urlDaImagem, '_blank')}
                className='d-flex justify-content-center align-items-center'
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    color: 'white',
                    fontSize: '3rem',
                    cursor: 'pointer',
                    transform: 'translate(-50%, -50%)',
                    transition: 'font-size 0.2s ease-in-out',
                }}
            >
                <i className="bi bi-arrow-down-square-fill text-white" />
            </div>
        </div>
    );
};