import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { SetorData } from './FetchSetor';
import { Layout } from '../Layout';
import { HttpService } from '../Shared/HttpService';
import { HeadLayout } from '../Shared/HeadLayout';
import { Loading } from '../Shared/Loading';
import $ from 'jquery';
import { ValidationLabelMessage, ValidationAlertMessage } from '../Shared/ValidationMessage';
import { Resource } from '../../index';
import { LanguageContext } from '../../context/LanguageContext';
import CaixaDeConteudo from '../Shared/CaixaDeConteudo';

interface AddSetorDataState {
    loading: boolean;
    empData: SetorData;
    setores: SetorData[];
}

export class AddSetor extends React.Component<RouteComponentProps<{}>, AddSetorDataState> {
    static contextType = LanguageContext;
    private _pathname;
    private _regexPaginaDeEdicao;
    private _estouNaPaginaDeEdicao;
    constructor(props) {
        super(props);
        this._pathname = window.location.pathname;
        this._regexPaginaDeEdicao = new RegExp(/setor\/edit/);
        this._estouNaPaginaDeEdicao = this._regexPaginaDeEdicao.test(this._pathname);

        this.state = { setores: [], loading: true, empData: new SetorData };

        var setorid = this.props.match.params["setorid"];
        //busca lista de setores
        HttpService.fetch('api/SetorApi/ListSetores', {})
            .then(response => response.json() as Promise<Array<any>>)
            .then(data => {
                this.setState({ setores: data });
                
                // This will set state for Edit User  
                if (setorid > 0) {

                    Loading.showLoading(this);;

                    HttpService.fetch('api/SetorApi/' + setorid, { method: 'GET' })
                        .then(response => response.json() as Promise<SetorData>)
                        .then(data => {
                            this.setState({ loading: false, empData: data });
                            this.forceUpdate();
                            Loading.hideLoading(this);
                        }).catch(() => {
                            Loading.hideLoading(this);
                            props.history.push("/setor");
                        });
                }

                // This will set state for Add User  
                else {
                    this.setState({ setores: data, loading: false, empData: new SetorData });
                }
                this.forceUpdate();

            })
            .catch((responseJson) => {

                console.log(responseJson);
                Loading.hideLoading(this);

            })
            ;

        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    private botoesDoCantoSuperiorDireito() {
        return (
            <React.Fragment>
                <button className="btn bg-white shadow-sm mr-2" onClick={this.handleCancel}>{<Resource keyString="back" />}</button>
            </React.Fragment>
        );
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;

        return (
            <CaixaDeConteudo
                tituloDoHeader={this._estouNaPaginaDeEdicao ? this.context.getLanguageResource("edit_sector") : this.context.getLanguageResource("create_sector")}
                botoes={this.botoesDoCantoSuperiorDireito()}
                itensParaBreadcrumb={[
                    { url: "/", nome: this.context.getLanguageResource("home") },
                    { url: "/setor", nome: this.context.getLanguageResource("sector_registration") },
                    { nome: this._estouNaPaginaDeEdicao ? this.context.getLanguageResource("edit_sector") : this.context.getLanguageResource("create_sector") }
                ]}
            >
                <main className='p-4'>
                    {this.renderCreateForm(this.state.setores)}
                </main>
            </CaixaDeConteudo>
        );
    }

    // This will handle the submit form event.  
    private handleSave(event) {
        event.preventDefault();

        let setorId = this.state.empData.id;

        Loading.showLoading(this);;

        // PUT request for Edit User.  
        if (setorId) {

            HttpService.fetch('api/SetorApi/' + setorId, {
                method: 'PUT',
                body: HttpService.formToJson(event.target),
            }).then((response) => {
                return response.json() as Promise<SetorData>
            }).then((data) => {

                this.setState({ empData: data })
                if ($.isEmptyObject(data.error)) {
                    this.redirectPage();
                }
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            })

        } else {

            HttpService.fetch('api/SetorApi/', {
                method: 'POST',
                body: HttpService.formToJson(event.target),
            }).then((response) => {
                return response.json() as Promise<SetorData>
            }).then((data) => {

                this.setState({ empData: data })
                if ($.isEmptyObject(data.error)) {
                    this.redirectPage();
                }
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            })
        }
    }

    // This will handle Cancel button click event.  
    private handleCancel(e) {
        e.preventDefault();
        this.redirectPage();
    }

    private redirectPage() {
        this.props.history.push("/setor");
    }

    // Returns the HTML Form to the render() method.  
    private renderCreateForm(setores) {
        return (<form onSubmit={this.handleSave} >
            <div className="form-group row" >
                <ValidationLabelMessage errors={this.state.empData.error} name="id">
                    <input type="hidden" name="id" value={this.state.empData.id} />
                </ValidationLabelMessage>
            </div>
            <div className="form-group row">
                <div className="col-md-6">
                    <label className="control-label col-md-12 required" htmlFor="Nome" >{<Resource keyString="sector_name" />}</label>
                    <div className="col-md-12">
                        <ValidationLabelMessage errors={this.state.empData.error} name="Nome">
                            <input className="form-control" type="text" id="Nome" name="Nome" defaultValue={this.state.empData.nome} />
                        </ValidationLabelMessage>
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="control-label col-md-12" htmlFor="setorpaiid">{<Resource keyString="sector_father" />}</label>
                    <div className="col-md-12">
                        <ValidationLabelMessage errors={this.state.empData.error} name="setorpaiid">
                            <select className="form-control" data-val="true" id="setorpaiid" name="setorpaiid" defaultValue={this.state.empData.setorpaiid}>
                                <option value="">-- {this.context.getLanguageResource("select")} --</option>
                                {setores.map(x =>
                                    <option key={x.id} value={x.id}>{x.nome}</option>
                                )}
                            </select>
                        </ValidationLabelMessage>
                    </div>
                </div>
            </div >
            <div className="form-group col-md-12 d-flex justify-content-center">
                <ValidationAlertMessage errors={this.state.empData.error} name="Summary" />
                <button type="submit" className="btn font-weight-bold" style={{ backgroundColor: 'var(--ws-blue)', width: '17.5rem' }}>{<Resource keyString="save" />}</button>
            </div >
        </form >)
    }

}  