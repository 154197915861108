import React, { useState, useContext, useEffect, useRef } from 'react';
import { ResponderFormularioContext } from '../../contexts/ResponderFormularioContext';
import { LanguageContext } from '../../../../../context/LanguageContext';
import { IAnotacao, IPergunta } from '../../interfaces/IResponderFormularioResponse';
import { criarArrayDePonteirosDasPerguntas } from '../../utils/geradoresDeArrayDePonteiros';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import ChevronDownIcon from '../../icons/ChevronDownIcon';
import ChevronUpIcon from '../../icons/ChevronUpIcon';
import { HttpService } from '../../../../Shared/HttpService';
import ListaDeAnotacoes from './ListaDeAnotacoes';

interface IProps {
    idDaPergunta: number;
    anotacoes: IAnotacao[];
    anotacao: string;
}

const CampoDeAnotacao = ({ anotacao, anotacoes, idDaPergunta }: IProps) => {
    const { responderFormulario, setResponderFormulario, mostrarTodosOsCollapses } = useContext(ResponderFormularioContext);
    const { getLanguageResource } = useContext(LanguageContext);

    const [isOpen, setIsOpen] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const responderFormularioAtualizado = { ...responderFormulario };
    const ponteirosDasPerguntasDeResponderFormulario = criarArrayDePonteirosDasPerguntas(responderFormularioAtualizado);
    const pergunta = ponteirosDasPerguntasDeResponderFormulario.find(pergunta => pergunta.id === idDaPergunta);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (!pergunta) return;
        pergunta[event.target.name] = event.target.value;
        setResponderFormulario(responderFormularioAtualizado);
    };

    const sendNotation = (anotacao: string): void => {
        setIsLoading(true);
        HttpService.fetch('api/ResultadoNovoApi/EnviarAnotacao', {
            body: JSON.stringify({
                perguntaid: idDaPergunta,
                grupoperguntaid: pergunta?.grupoPerguntaId,
                hash: responderFormulario.hash,
                mensagem: anotacao,
                formularioiniciadoid: responderFormulario.id,
            }),
            method: 'POST'
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            if (pergunta) {
                pergunta.anotacoes = data;
                pergunta.anotacao = '';
            }
            setResponderFormulario(responderFormularioAtualizado);
            setIsLoading(false);
        });
    };

    useDidUpdateEffect(() => setIsOpen(mostrarTodosOsCollapses), [mostrarTodosOsCollapses]);

    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const div = divRef.current;
        if (div) div.scrollTop = div.scrollHeight;
    }, []);

    return (
        <div className='col-12 mb-4 border border-top-0 px-0' style={ { maxHeight: '350px' } }>
            <div className={`card ${isOpen && 'h-100'}`}>
                {/* <div
                    className={`d-flex justify-content-between p-2 ${isOpen ? 'rounded-top' : 'rounded'}`}
                    onClick={() => setIsOpen(!isOpen)}
                    style={{ cursor: 'pointer', backgroundColor: 'var(--ws-blue-light)' }}
                >
                    <div>
                        <span>{getLanguageResource('annotation')}</span>
                    </div>
                    <div>
                        {
                            isOpen
                                ? <ChevronDownIcon className='ml-2' fill='#000' />
                                : <ChevronUpIcon className='ml-2' fill='#000' />
                        }
                    </div>
                </div> */}
                <div className={`card-body p-0 ${isOpen ? 'd-flex' : 'd-none'} flex-column justify-content-end`}>
                    <ListaDeAnotacoes
                        anotacoes={pergunta?.anotacoes as IAnotacao[]}
                        formularioIniciadoId={responderFormulario.id}
                        pergunta={pergunta as IPergunta}
                        responderFormulario={responderFormulario}
                        setResponderFormulario={setResponderFormulario}
                        hash={responderFormulario.hash}
                    />
                    <div className='d-flex'>
                        <input
                            type='text'
                            className='form-control border-0'
                            name="anotacao"
                            placeholder='Insira aqui sua anotação'
                            value={anotacao}
                            id={`anotacao-${idDaPergunta}`}
                            onChange={handleChange}
                        />
                        <button
                            type='button'
                            className='btn'
                            onClick={() => sendNotation(anotacao)}
                            style={{ backgroundColor: 'var(--ws-blue-light)', borderRadius: '0 0 0.25rem 0' } }
                            onMouseEnter={(e) => (e.target as HTMLButtonElement).style.backgroundColor = 'var(--ws-blue)'}
                            onMouseLeave={(e) => (e.target as HTMLButtonElement).style.backgroundColor = 'var(--ws-blue-light)'}
                            disabled={isLoading || anotacao === null || anotacao.length === 0}
                        >
                            <div
                                style={{ rotate: '45deg' }}
                            >
                                <i className='bi bi-send' style={{ fontSize: '1.2rem' }} />
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CampoDeAnotacao;