import * as React from 'react';

const Carregando = () => {
	return (
		<div
			className="d-flex justify-content-center align-items-center"
			style={{
				width: '100vw',
				height: '100vh',
				zIndex: 10000,
				position: 'fixed',
				background: '#ffffffa8',
				top: 0,
				left: 0,
			}}
		>
			<div className="spinner-border text-primary" role="status" />
		</div>
	);
}

export default Carregando;
