import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { PerfilData } from './FetchPerfil';
import { Layout } from '../Layout';
import { HttpService } from '../Shared/HttpService';
import { Loading } from '../Shared/Loading';
import $ from 'jquery';
import { HeadLayout } from '../Shared/HeadLayout';
import { ValidationLabelMessage } from '../Shared/ValidationMessage';
import { Resource } from '../../index';
import { LanguageContext } from '../../context/LanguageContext';
import CaixaDeConteudo from '../Shared/CaixaDeConteudo';

interface AddPerfilDataState {
    loading: boolean;
    empData: PerfilData;
}

export class AddPerfil extends React.Component<RouteComponentProps<{}>, AddPerfilDataState> {
    static contextType = LanguageContext;
    private _pathname;
    private _regexPaginaDeEdicao;
    private _estouNaPaginaDeEdicao;
    constructor(props) {
        super(props);
        this._pathname = window.location.pathname;
        this._regexPaginaDeEdicao = new RegExp(/perfil\/edit/);
        this._estouNaPaginaDeEdicao = this._regexPaginaDeEdicao.test(this._pathname);

        this.state = { loading: true, empData: new PerfilData };

        var perfilid = this.props.match.params["perfilid"];


        // This will set state for Edit User  
        if (perfilid > 0) {

            Loading.showLoading(this);;

            HttpService.fetch('api/PerfilApi/' + perfilid, { method: 'GET' })
                .then(response => response.json() as Promise<PerfilData>)
                .then(data => {
                    this.setState({ loading: false, empData: data });
                    Loading.hideLoading(this);
                }).catch(() => {
                    Loading.hideLoading(this);
                    props.history.push("/perfil");
                });
        }

        // This will set state for Add User  
        else {
            this.state = { loading: false, empData: new PerfilData };
        }


        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    private botoesDoCantoSuperiorDireito() {
        return (
            <React.Fragment>
                <button className="btn bg-white shadow-sm mr-2" onClick={this.handleCancel}>{<Resource keyString="back" />}</button>
            </React.Fragment>
        );
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;

        return (
            <CaixaDeConteudo
                tituloDoHeader={this._estouNaPaginaDeEdicao ? this.context.getLanguageResource("edit_profile") : this.context.getLanguageResource("create_access_profiles")}
                botoes={this.botoesDoCantoSuperiorDireito()}
                itensParaBreadcrumb={[
                    { url: "/", nome: this.context.getLanguageResource("home") },
                    { url: "/perfil", nome: this.context.getLanguageResource("access_profile_registration") },
                    { nome: this._estouNaPaginaDeEdicao ? this.context.getLanguageResource("edit_profile") : this.context.getLanguageResource("create_access_profiles") }
                ]}
            >
                <main className='p-4'>
                    {this.renderCreateForm()}
                </main>
            </CaixaDeConteudo>
        );
    }

    // This will handle the submit form event.  
    private handleSave(event) {
        event.preventDefault();

        let perfilId = this.state.empData.id;

        Loading.showLoading(this);;

        // PUT request for Edit User.  
        if (perfilId) {

            HttpService.fetch('api/PerfilApi/' + perfilId, {
                method: 'PUT',
                body: HttpService.formToJson(event.target),
            }).then((response) => {
                return response.json() as Promise<PerfilData>
            }).then((data) => {

                this.setState({ empData: data })
                if ($.isEmptyObject(data.error)) {
                    this.redirectPage();
                }
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            })

        } else {

            HttpService.fetch('api/PerfilApi', {
                method: 'POST',
                body: HttpService.formToJson(event.target),
            }).then((response) => {
                return response.json() as Promise<PerfilData>
            }).then((data) => {

                this.setState({ empData: data })
                if ($.isEmptyObject(data.error)) {
                    this.redirectPage();
                }
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            })
        }
    }

    // This will handle Cancel button click event.  
    private handleCancel(e) {
        e.preventDefault();
        this.redirectPage();
    }

    private redirectPage() {
        this.props.history.push("/perfil");
    }

    // Returns the HTML Form to the render() method.  
    private renderCreateForm() {
        return (<form onSubmit={this.handleSave} >
            <div className="form-group row" >
                <ValidationLabelMessage errors={this.state.empData.error} name="id">
                    <input type="hidden" name="id" value={this.state.empData.id} />
                </ValidationLabelMessage>
            </div>
            < div className="form-group row d-flex flex-column align-items-center" >
                <label className=" control-label col-md-4 text-center required" htmlFor="Name">{<Resource keyString="access_profile_name" />}</label>
                <div className="col-md-4">
                    <ValidationLabelMessage errors={this.state.empData.error} name="Nome">
                        <input className="form-control" type="text" name="Nome" defaultValue={this.state.empData.nome} />
                    </ValidationLabelMessage>
                </div>
            </div >
            <hr />
            <div className="form-group" >
                <ValidationLabelMessage errors={this.state.empData.error} name="Leitura">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" name="Leitura" id="Leitura" defaultChecked={this.state.empData.leitura} />
                        <label className="custom-control-label" htmlFor="Leitura">{<Resource keyString="allow_read_download_documents" />}</label>
                    </div>
                </ValidationLabelMessage>
            </div>
            <div className="form-group" >
                <ValidationLabelMessage errors={this.state.empData.error} name="Escrita">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" name="Escrita" id="Escrita" defaultChecked={this.state.empData.escrita} />
                        <label className="custom-control-label" htmlFor="Escrita">{<Resource keyString="allow_writing_upload_documents" />}</label>
                    </div>
                </ValidationLabelMessage>
            </div>
            <div className="form-group" >
                <ValidationLabelMessage errors={this.state.empData.error} name="Revisao">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" name="Revisao" id="Revisao" defaultChecked={this.state.empData.revisao} />
                        <label className="custom-control-label" htmlFor="Revisao">{<Resource keyString="allow_review_of_files" />}</label>
                    </div>
                </ValidationLabelMessage>
            </div>
            <div className="form-group" >
                <ValidationLabelMessage errors={this.state.empData.error} name="Aprovacao">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" name="Aprovacao" id="Aprovacao" defaultChecked={this.state.empData.aprovacao} />
                        <label className="custom-control-label" htmlFor="Aprovacao">{<Resource keyString="allow_approve_archive_file_version" />}</label>
                    </div>
                </ValidationLabelMessage>
            </div>
            <div className="form-group" >
                <ValidationLabelMessage errors={this.state.empData.error} name="Exclusao">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" name="Exclusao" id="Exclusao" defaultChecked={this.state.empData.exclusao} />
                        <label className="custom-control-label" htmlFor="Exclusao">{<Resource keyString="allow_reject_archive_file_version" />}</label>
                    </div>
                </ValidationLabelMessage>
            </div>
            <div className="form-group" >
                <ValidationLabelMessage errors={this.state.empData.error} name="Compartilhar">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" name="Compartilhar" id="Compartilhar" defaultChecked={this.state.empData.compartilhar} />
                        <label className="custom-control-label" htmlFor="Compartilhar">{<Resource keyString="allow_documents_to_be_shared_by_LINK" />}<label style={{ color: "#FF8C00" }}><i className='fa fa-exclamation-triangle'></i> {<Resource keyString="in_building" />}</label></label>
                    </div>
                </ValidationLabelMessage>
            </div>
            <div className="form-group" >
                <ValidationLabelMessage errors={this.state.empData.error} name="Email">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" name="Email" id="Email" defaultChecked={this.state.empData.email} />
                        <label className="custom-control-label" htmlFor="Email">{<Resource keyString="allow_documents_to_be_shared_by_EMAIL" />}<label style={{
                            color: "#FF8C00"
                        }}><i className='fa fa-exclamation-triangle'></i> {<Resource keyString="in_building" />}</label></label>
                    </div>
                </ValidationLabelMessage>
            </div>
            <div className="form-group" >
                <ValidationLabelMessage errors={this.state.empData.error} name="Manterusuario">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" name="Manterusuario" id="Manterusuario" defaultChecked={this.state.empData.manterusuario} />
                        <label className="custom-control-label" htmlFor="Manterusuario">{<Resource keyString="allow_to_perform_operations_with_user" />}</label>
                    </div>
                </ValidationLabelMessage>
            </div>
            <div className="form-group" >
                <ValidationLabelMessage errors={this.state.empData.error} name="Manterperfil">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" name="Manterperfil" id="Manterperfil" defaultChecked={this.state.empData.manterperfil} />
                        <label className="custom-control-label" htmlFor="Manterperfil">{<Resource keyString="allow_to_perform_operations_with_profiles" />}</label>
                    </div>
                </ValidationLabelMessage>
            </div>
            <div className="form-group" >
                <ValidationLabelMessage errors={this.state.empData.error} name="Manterfuncao">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" name="Manterfuncao" id="Manterfuncao" defaultChecked={this.state.empData.manterfuncao} />
                        <label className="custom-control-label" htmlFor="Manterfuncao">{<Resource keyString="allow_to_perform_operations_with_function" />}</label>
                    </div>
                </ValidationLabelMessage>
            </div>
            <div className="form-group" >
                <ValidationLabelMessage errors={this.state.empData.error} name="Mantersetor">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" name="Mantersetor" id="Mantersetor" defaultChecked={this.state.empData.mantersetor} />
                        <label className="custom-control-label" htmlFor="Mantersetor">{<Resource keyString="allow_to_perform_operations_with_sector" />}</label>
                    </div>
                </ValidationLabelMessage>
            </div>
            <div className="form-group" >
                <ValidationLabelMessage errors={this.state.empData.error} name="Manterempresa">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" name="Manterempresa" id="Manterempresa" defaultChecked={this.state.empData.manterempresa} />
                        <label className="custom-control-label" htmlFor="Manterempresa">{<Resource keyString="allow_to_make_changes_in_the_company" />}</label>
                    </div>
                </ValidationLabelMessage>
            </div>
            <div className="form-group" >
                <ValidationLabelMessage errors={this.state.empData.error} name="Responder">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" name="Responder" id="Responder" defaultChecked={this.state.empData.responder} />
                        <label className="custom-control-label" htmlFor="Responder">{<Resource keyString="allow_responding_form_and_access_information_from_answered_forms" />}</label>
                    </div>
                </ValidationLabelMessage>
            </div>
            <div className="form-group" >
                <ValidationLabelMessage errors={this.state.empData.error} name="Manteracao">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" name="Manteracao" id="Manteracao" defaultChecked={this.state.empData.manteracao} />
                        <label className="custom-control-label" htmlFor="Manteracao">{<Resource keyString="allow_view_to_perform_operations_after_actions_are_created" />}</label>
                    </div>
                </ValidationLabelMessage>
            </div>
            <div className="form-group" >
                <ValidationLabelMessage errors={this.state.empData.error} name="Manterresposta">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" name="Manterresposta" id="Manterresposta" defaultChecked={this.state.empData.manterresposta} />
                        <label className="custom-control-label" htmlFor="Manterresposta">{<Resource keyString="allow_to_perform_operations_with_answers" />}</label>
                    </div>
                </ValidationLabelMessage>
            </div>
            <div className="form-group" >
                <ValidationLabelMessage errors={this.state.empData.error} name="Manterformatopergunta">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" name="Manterformatopergunta" id="Manterformatopergunta" defaultChecked={this.state.empData.manterformatopergunta} />
                        <label className="custom-control-label" htmlFor="Manterformatopergunta">{<Resource keyString="allow_to_perform_operations_with_question_format" />}</label>
                    </div>
                </ValidationLabelMessage>
            </div>
            <div className="form-group" >
                <ValidationLabelMessage errors={this.state.empData.error} name="Manterformulario">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" name="Manterformulario" id="Manterformulario" defaultChecked={this.state.empData.manterformulario} />
                        <label className="custom-control-label" htmlFor="Manterformulario">{<Resource keyString="allow_to_perform_operations_with_question_forms" />}</label>
                    </div>
                </ValidationLabelMessage>
            </div>
            <div className="form-group" >
                <ValidationLabelMessage errors={this.state.empData.error} name="ManterVigenteAoSubirArquivo">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" name="ManterVigenteAoSubirArquivo" id="ManterVigenteAoSubirArquivo" defaultChecked={this.state.empData.mantervigenteaosubirarquivo} />
                        <label className="custom-control-label" htmlFor="ManterVigenteAoSubirArquivo">{<Resource keyString="allow_to_make_a_current_file_automatically" />}</label>
                    </div>
                </ValidationLabelMessage>
            </div>
            <div className="form-group" >
                <ValidationLabelMessage errors={this.state.empData.error} name="MostrarLojaExterna">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" name="MostrarLojaExterna" id="MostrarLojaExterna" defaultChecked={this.state.empData.mostrarlojaexterna} />
                        <label className="custom-control-label" htmlFor="MostrarLojaExterna">{<Resource keyString="show_external_store" />}</label>
                    </div>
                </ValidationLabelMessage>
            </div>
            <div className="form-group" >
                <ValidationLabelMessage errors={this.state.empData.error} name="ReativarFormulario">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" name="ReativarFormulario" id="ReativarFormulario" defaultChecked={this.state.empData.reativarformulario} />
                        <label className="custom-control-label" htmlFor="ReativarFormulario">{<Resource keyString="reopen_form" />}</label>
                    </div>
                </ValidationLabelMessage>
            </div>
            <div className="form-group col-12 d-flex justify-content-center">
                <button type="submit" className="btn font-weight-bold" style={{ backgroundColor: 'var(--ws-blue)', width: '17.5rem' }}>{<Resource keyString="save" />}</button>
            </div >
        </form >)
    }

}  