import * as React from 'react';
import { Resource } from '../../../../../index';

const ContagemDeResposta = ({ respostas, id }) => {
  const handleBackgroundColor = (index) => index % 2 === 0 ? '#efefef' : '#ffffff';

  return (
    <table id={id} className="table collapseContent border-0 p-0 m-0 d-none">
      <thead>
        <tr>
          <th className='border-0'>{ <Resource keyString="answer" /> }</th>
          <th className='border-0'>{ <Resource keyString="weight" /> }</th>
          <th className='border-0'>{ <Resource keyString="quantity" /> }</th>
        </tr>
      </thead>
      <tbody>
        {
          respostas
            .filter(resposta => resposta.nome !== null)
            .map((resposta, index) => {
            return (
              <tr key={ index } style={{ backgroundColor: handleBackgroundColor(index) }}>
                <td className='border-0'>{ resposta.nome }</td>
                <td className='border-0'>{ resposta.peso }</td>
                <td className='border-0'>{ resposta.quantidade }</td>
              </tr>
            )
          })
        }
      </tbody>
    </table>
  );
}

export default ContagemDeResposta;
