import React, { useState, useRef } from "react";

interface IProps {
    icone: string | React.ReactNode;
    posicaoEsquerdaDoMenu?: number;
    altDoIcone?: string;
    classNameDaCaixa?: string;
    children: any;
    widthDaCaixa?: number;
    heightDaCaixa?: number;
    minWidthDaCaixa?: number;
    minHeightDaCaixa?: number;
    backgroundColorDaCaixa?: string;
    styleDaCaixa?: React.CSSProperties;
    classeDoComponenteInteiro?: string;
}

const IconeComMenu = ({
        icone,
        posicaoEsquerdaDoMenu,
        altDoIcone,
        classNameDaCaixa,
        children,
        widthDaCaixa,
        heightDaCaixa,
        minWidthDaCaixa,
        minHeightDaCaixa,
        backgroundColorDaCaixa,
        styleDaCaixa,
        classeDoComponenteInteiro,
    }: IProps) => {
    const [isOpened, setIsOpened] = useState(false);
    const imgRef = useRef<HTMLImageElement>(null);
    const contextoRef = useRef<HTMLDivElement>(null);

    document.addEventListener('click', (e) => {
        if (contextoRef.current
            && !contextoRef.current.contains(e.target as Node)
            && imgRef.current
            && !imgRef.current.contains(e.target as Node))
        {
            setIsOpened(false);
        }
    }, false);

    return (
        <div className={`mx-2 ${classeDoComponenteInteiro}`} style={{ position: 'relative' }}>
            <div className="p-1" style={{ cursor: 'pointer' }} ref={imgRef}>
                {
                    typeof icone === 'string'
                        ?   (
                                <img
                                    src={icone}
                                    alt={altDoIcone}
                                    onClick={() => setIsOpened(!isOpened)}
                                />
                            )
                        :   (
                                <div
                                    onClick={() => setIsOpened(!isOpened)}
                                >
                                    {icone}
                                </div>
                            )
                }
                
            </div>
            {
                isOpened && (
                    <div style={{ position: 'absolute' }} ref={contextoRef}>
                        <div
                            className='bg-white'
                            style={{
                                clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)',
                                width: '22px',
                                height: '14px',
                                position: 'absolute',
                                top: '0',
                                left: `${imgRef.current?.offsetWidth ? (imgRef.current?.offsetWidth / 2 - 11) : 0}px`,
                            }}
                        />
                        <div
                            className={classNameDaCaixa ? classNameDaCaixa : 'bg-white rounded p-1'}
                            style={{
                                position: 'absolute',
                                top: '13px',
                                left: posicaoEsquerdaDoMenu ? `${posicaoEsquerdaDoMenu}px` : '-4px',
                                width: widthDaCaixa,
                                height: heightDaCaixa,
                                minWidth: minWidthDaCaixa,
                                minHeight: minHeightDaCaixa,
                                overflow: 'hidden',
                                backgroundColor: backgroundColorDaCaixa,
                                ...styleDaCaixa,
                            }}
                        >
                            { children }
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default IconeComMenu;