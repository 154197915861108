import React, { useState, useEffect, useContext } from 'react';
import { AuthService } from '../UserAuth/AuthService';
import { Loading } from '../Shared/Loading';
import { HttpService } from './HttpService';
import { Resource } from '../../index';
import $ from 'jquery';
import TarjaDeAlertas from './TarjaDeAlertas';
import { LanguageContext } from '../../context/LanguageContext';
import IconeComMenu from './components/IconeComMenu';
import ChatIcon from '../../assets/icons/chat-icon.svg';
import BandeiraDoBrasil from '../../assets/svg/bandeira-do-brasil.svg';
import BandeiraDosEstadosUnidos from '../../assets/svg/bandeira-dos-estados-unidos.svg';
import BandeiraDaEspanha from '../../assets/svg/bandeira-da-espanha.svg';
import CaixaDeIniciais from './components/CaixaDeIniciais';
import LogoBranca from '../../assets/img/winset-logo-branca.png';
import { GlobalContext } from '../../context/GlobalContext';
import EmpresasERotas from '../../assets/json/empresasERotas.json';
import { Bell, Globe, Search } from 'lucide-react';

function Header(props) {
    const { getLanguageResource } = useContext(LanguageContext);
    const { recuperarPreferencias } = useContext(GlobalContext);

    const [pesquisa, setPesquisa] = useState('');
    const [notificacao, setNotificacao] = useState([]);
    const [mensagensDeAlerta, setMensagensDeAlerta] = useState([]);
    const [imagensDinamicas, setImagensDinamicas] = useState({ logo: '', icon: '', whitelogo: '' });

    useEffect(() => {
        const intervalNotificacao = setInterval(atualizarNotificacoes, 30000);
        const intervalVerificaConexao = setInterval(verificaConexao, 5000);
        
        recuperarPreferencias();
        pegarListagemDeAlertas();
        retornaRotasDasImagensDaEmpresa();

        return () => {
            clearInterval(intervalNotificacao);
            clearInterval(intervalVerificaConexao);
        };
    }, []);

    const handleOpen = (event) => {
        event.preventDefault();
        if (event.target["pesquisa"].value.length > 0) {
            Loading.showLoading("Pesquisando");
            window.location.href = `/repositorio/pesquisar/${event.target["pesquisa"].value}`;
        }
    };

    const pegarListagemDeAlertas = () => {
        HttpService.fetch('api/NotificacaoApi/MensagemNotificacao', { method: 'GET' })
            .then(response => response.json())
            .then(data => {
                setMensagensDeAlerta(data);
            })
            .catch(() => {
                console.log("Erro ao recuperar mensagens de alerta");
            });
    };

    const atualizarNotificacoes = () => {
        HttpService.fetch('api/NotificacaoApi/', { method: 'get' })
            .then(response => response.json())
            .then(data => {
                setNotificacao(data);
            }).catch(() => {
            });
    };

    const verificaConexao = () => {
        HttpService.fetch('api/NotificacaoApi/VerificaConexao', { method: 'GET' })
            .then(response => {
                if (response.ok) {
                    $("#message").addClass("d-none");
                } else {
                    $("#message").removeClass("d-none");
                }
            });
    };

    const mostrarNotificacao = (notificacao) => {
        const alterarCorDoElemento = (id, cor) => {
            const elemento = document.getElementById(`item-de-notificacao-${id.toString()}`);
            if (elemento !== null) elemento.style.backgroundColor = cor;
        }

        return (
            <div
                className='d-flex p-2'
                id={`item-de-notificacao-${notificacao.id.toString()}`}
                key={notificacao.id}
                style={{ cursor: notificacao.url !== '#' ? 'pointer' : 'default' }}
                onClick={() => notificacao.url !== '#' ? window.location.href = notificacao.url : null}
                onMouseEnter={() => alterarCorDoElemento(notificacao.id, 'var(--ws-blue-light)')}
                onMouseLeave={() => alterarCorDoElemento(notificacao.id, 'var(--ws-red-soft)')}
            >
                <section className='align-items-center justify-content-center d-flex p-3'>
                    <img src={ChatIcon} alt="Ícone de chat" style={{ width: '26px', maxWidth: 'none' }} />
                </section>
                <section style={{ whiteSpace: 'pre-wrap' }}>
                    <p style={{ fontSize: '16px', lineHeight: '20px' }}>{notificacao.mensagem}</p>
                    <span className="date">
                        <small>
                            {notificacao.datecreated}
                        </small>
                    </span>
                </section>
            </div>
        );
    };

    const aRotaEstaListadaNoJson = () => {
        // verificar o que está escrito entre http:// ou https:// e .winset.com.br
        const url = window.location.href as string;
        const regex = /https?:\/\/([^.]*)/;
        const match = url.match(regex) ? url.match(regex)[1] : null;

        // transformar o json em array
        const empresasERotas = JSON.parse(JSON.stringify(EmpresasERotas)) as Array<any>;
        if (empresasERotas.length === 0) return false;
        return empresasERotas.some(empresa => match?.includes(empresa.nome));
    }

    const retornaRotasDasImagensDaEmpresa = () => {
        const outraEmpresa = aRotaEstaListadaNoJson();
        const rotaAtual = window.location.href.match(/https?:\/\/([^.]*)/)[1];
        
        if (outraEmpresa) {
            const empresasERotas = JSON.parse(JSON.stringify(EmpresasERotas)) as Array<any>;
            const empresa = empresasERotas.find(empresa => rotaAtual.includes(empresa.nome));
            setImagensDinamicas({ logo: empresa.logo, icon: empresa.icon, whitelogo: empresa.whitelogo});
        }
    }


    return (
        <header
            className="w-100 d-flex align-items-center justify-content-between fixed-top shadow _IMPRIMIR_OMITIR_"
            /* @ts-ignore */
            style={{ backgroundColor: JSON.parse(localStorage.getItem("preferencias")).corDoTopoDaAplicacao, height: '4rem', zIndex: 20 }}
        >
            <div className="d-none" id="message" style={{ zIndex: 9998, backgroundColor: 'rgba(0,0,0,0.5)', position: 'absolute', top: '0px', right: '0px', bottom: '0px', left: '0px', overflow: 'hidden', height: '100vh' }}>
                <div style={{ display: 'block', width: '60%', paddingTop: '15%', zIndex: 9999, margin: 'auto' }}>
                    <div className="alert alert-danger alert-dismissable" style={{ margin: '0 auto', boxShadow: '1px 1px 5px black', textAlign: 'center' }}>
                        <strong>{<Resource keyString="network_error" />}</strong><br />{<Resource keyString="your_internet_connection_is_slow_or_out_of_order" />}
                    </div>
                </div>
            </div>

            <section>
                <img
                    src={aRotaEstaListadaNoJson() ? `${process.env.PUBLIC_URL}${imagensDinamicas.whitelogo}` : LogoBranca}
                    alt="Logo"
                    style={{ height: '41px', cursor: 'pointer', marginLeft: '9.3rem' }}
                    onClick={() => window.location.href = '/'}
                    className='d-none d-md-block'
                />
            </section>

            <section className='d-flex align-items-center justify-content-end mr-4'>
                <TarjaDeAlertas mensagensDeAlerta={mensagensDeAlerta} />

                <IconeComMenu
                    icone={<Search color={aRotaEstaListadaNoJson() ? 'white' : 'black'} />}
                    posicaoEsquerdaDoMenu={-100}
                    classNameDaCaixa="d-flex shadow bg-white p-1 rounded"

                >
                    <form
                        onSubmit={handleOpen}
                        className="d-flex m-0 px-2"
                    >
                        <input
                            name="pesquisa"
                            className='p-1 border-0'
                            type="text"
                            placeholder="Pesquisar"
                            style={{ outline: 'none' }}
                        />
                    </form>
                </IconeComMenu>

                <IconeComMenu
                    icone={<Bell color={aRotaEstaListadaNoJson() ? 'white' : 'black'} />}
                    classNameDaCaixa="d-flex flex-column rounded shadow barra_de_rolagem_estilizada"
                    posicaoEsquerdaDoMenu={-300}
                    widthDaCaixa={360}
                    backgroundColorDaCaixa='var(--ws-red-soft)'
                    styleDaCaixa={{ overflowY: 'scroll', maxHeight: '400px' }}
                >
                    { 
                        notificacao.map(doc => {
                            return mostrarNotificacao(doc)
                        })
                    }
                </IconeComMenu>

                <IconeComMenu
                    icone={<Globe color={aRotaEstaListadaNoJson() ? 'white' : 'black'} />}
                    classNameDaCaixa="d-flex flex-column bg-white rounded shadow"
                    classeDoComponenteInteiro='d-none d-md-block'
                    widthDaCaixa={150}
                    posicaoEsquerdaDoMenu={-110}
                >
                    <div
                        className='d-flex align-items-center p-1'
                        onClick={() => window.location.href = '?lang=pt-br'}
                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--ws-blue-highlight-menu)'}
                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                        style={{ cursor: 'pointer' }}
                    >
                        <img className="m-2" alt='Ícone da bandeira do Brasil' width="20" src={BandeiraDoBrasil} />
                        <Resource keyString="portuguese" />
                    </div>
                    <div
                        className='d-flex align-items-center p-1'
                        onClick={() => window.location.href = '?lang=en-us'}
                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--ws-blue-highlight-menu)'}
                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                        style={{ cursor: 'pointer' }}
                    >
                        <img className="m-2" alt='Ícone da bandeira dos Estados Unidos' width="20" src={BandeiraDosEstadosUnidos} />
                        <Resource keyString="english" />
                    </div>
                    <div
                        className='d-flex align-items-center p-1'
                        onClick={() => window.location.href = '?lang=es-es'}
                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--ws-blue-highlight-menu)'}
                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                        style={{ cursor: 'pointer' }}
                    >
                        <img className="m-2" alt='Ícone da bandeira da Espanha' width="20" src={BandeiraDaEspanha} />
                        <Resource keyString="spanish" />
                    </div>
                </IconeComMenu>

                <IconeComMenu
                    icone={<CaixaDeIniciais nome={AuthService.getStorage('nome')} />}
                    classNameDaCaixa="d-flex flex-column bg-white rounded shadow"
                    classeDoComponenteInteiro='d-none d-md-block'
                    posicaoEsquerdaDoMenu={-90}
                >
                    <div
                        className='d-flex align-items-center p-2'
                        onClick={() => window.location.href = '/minhaConta'}
                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--ws-blue-highlight-menu)'}
                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                        style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                    >
                        <Resource keyString="my_account" />
                    </div>
                    <div
                        className='d-flex align-items-center p-2'
                        onClick={() => window.location.href = '/minhaEmpresa'}
                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--ws-blue-highlight-menu)'}
                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                        style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                    >
                        <Resource keyString="my_company" />
                    </div>
                    <div
                        className='d-flex align-items-center p-2'
                        onClick={() => window.location.href = '/login'}
                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--ws-blue-highlight-menu)'}
                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                        style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                    >
                        <Resource keyString="log_out" />
                    </div>
                </IconeComMenu>
                
                <button
                    className="d-md-none d-block btn"
                    onClick={() => props.setIsMobileSidebarOpened(true)}
                >
                    <i className="bi bi-list" style={{ fontSize: '2rem' }} />
                </button>
            </section>
        </header>
    );
}

export default Header;
