import React, { useState, useContext } from "react";
import { AuthService } from '../../../../UserAuth/AuthService';
import { BotaoImprimir } from '../../../../Shared/BotaoImprimir';
import MostrarFecharCollapse from '../../../../Shared/MostraFecharCollapse';
import { CompartilharFormulario } from '../../CompartilharFormulario';
import { ModalPlanoAcao } from '../../../PlanoAcao/ModalPlanoAcao';
import { LanguageContext } from "../../../../../context/LanguageContext";
import ModalWinSet from "../../../../Shared/components/ModalWinSet";
import ModalDeCompartilhamento from "../ModalDeCompartilhamento";

const AgrupamentoDeBotoesSuperiores = ({ resultadoDoFormulario, hash, modalDePlanoDeAcao, classeAlvoModal }) => {
  const empresaAutorizada = AuthService.getStorage('empresasid') == resultadoDoFormulario.formulario.empresaQuePossuiOFormularioId;
  const { getLanguageResource } = useContext(LanguageContext);
  const [modalDeCompartilhamento, setModalDeCompartilhamento] = useState(false);
  const [modalDePlanoDeAcaoAberto, setModalDePlanoDeAcaoAberto] = useState(false);

  return (
    <div className="_IMPRIMIR_OMITIR_">
      <BotaoImprimir />

      {
        empresaAutorizada
          ? <React.Fragment>
            <button
              className="btn ws-outline-blue mx-1"
              title={getLanguageResource("share")}
              style={{ height: '3rem', minWidth: '3rem' }}
              onClick={() => setModalDeCompartilhamento(true)}
            >
              <i className="fa fa-share-alt" style={{ fontSize: '1.2rem', lineHeight: '1.2rem' }} aria-hidden="true" />
            </button>

            {
              modalDeCompartilhamento
                ? (
                  <ModalWinSet
                    fecharModal={() => setModalDeCompartilhamento(false)}
                  >
                    <ModalDeCompartilhamento
                      fecharModal={() => setModalDeCompartilhamento(false)}
                      hash={hash}
                      formularioXEmpresaId={resultadoDoFormulario.formulario.formularioXEmpresaId}
                      nome={resultadoDoFormulario.formulario.nome}
                    />
                  </ModalWinSet>
                )
                : <React.Fragment />
            }

            {
              resultadoDoFormulario.formulario.modeloacao_Id > 0
                ? <React.Fragment>
                  <button
                    className="btn ws-outline-blue float-right"
                    style={{ height: '2.75rem', minWidth: '2.75rem' }}
                    onClick={() => setModalDePlanoDeAcaoAberto(true)}
                    type="button"
                  >
                    <i className="fa fa-plus" style={{ fontSize: '1.2rem', lineHeight: '1.2rem' }}></i>
                    {` ${getLanguageResource("create_action")}`}
                  </button>
                  {
                    modalDePlanoDeAcaoAberto
                      ? <ModalWinSet
                        fecharModal={() => setModalDePlanoDeAcaoAberto(false)}
                      >
                        <ModalPlanoAcao
                          classe={classeAlvoModal}
                          id="modalPlanoAcao"
                          hash={resultadoDoFormulario.formularioIniciado.hash}
                          formularioid={resultadoDoFormulario.formulario.id}
                          fecharModal={() => setModalDePlanoDeAcaoAberto(false)}
                        />
                      </ModalWinSet>
                      : <React.Fragment />
                  }
                </React.Fragment>
                : <React.Fragment />
            }

          </React.Fragment>
          : <React.Fragment />
      }

      <MostrarFecharCollapse />
    </div>
  );
};

export default AgrupamentoDeBotoesSuperiores;
