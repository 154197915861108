import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { FormularioData } from './FetchFormulario';
import { HttpService } from '../../Shared/HttpService';
import { Loading } from '../../Shared/Loading';
import $ from 'jquery';
import { PagamentoData } from '../../Pagamentos/Pagamento';
import { Resource } from '../../../index';
import { Authorize } from '../../Shared/Authorize';
import { RealizarFormulario } from './RealizarFormulario';
import { LanguageContext } from '../../../context/LanguageContext';
import CaixaDeConteudo from '../../Shared/CaixaDeConteudo';
import FiltrarIcon from '../../../assets/icons/filtrar-icon.svg';
import LixeiraIcon from '../../../assets/icons/lixeira-icon.svg';
import VerificarResultadoIcon from '../../../assets/icons/verificar-resultado-icon.svg';
import VerificarResultadoIconCinza from '../../../assets/icons/verificar-resultado-icon-cinza.svg';
import ModalWinSet from '../../Shared/components/ModalWinSet';
import MenuArrowIcon from "../../../assets/icons/menu-arrow.svg";
import Payment from '../../Payment';
import { th } from 'date-fns/locale';
import { CupomData } from './AddFormulario';
import { ChevronLeft, ChevronRight, ChevronRightIcon } from 'lucide-react';
import { GlobalContext } from 'context/GlobalContext';

interface MarketPlaceDetailDataState {
    comprado: boolean;
    formulario: FormularioData;
    datavencimento: string;
    formularioXUsuario: FormularioXUsuarioData;
    formularioIniciado: FormularioIniciadoData[];
    configForms: ConfiguracaoFormularioData[];
    configuracaoFormularioDataId: number;
    dataInicial: string;
    dataFinal: string;
    countFormularioParcial: number;
    countFormularioFinalizado: number;
    statusSelecionado: string;
    statusArquivar: string;
    countFormularioParcialEFinalizado: number;
    paginationNumber: number;
    paginationCount: number;
    listaRender: Array<any>[];
    modalRealizarFormulariosAberto: boolean;
    modalDeConfirmacaoDeReabrirFormularioAberto: boolean;
    hashDoFormularioParaReabrir: string;
    camposDeFiltroEstaoOcultos: boolean;
    mensagemCasoNaoHajaResultado: string;
    clientSecret: string | null;
    modalDeCompra: boolean;
    inputDeCupom: string;
    cupomInserido: CupomData | null;
}

export class FormularioXUsuarioData {
    id: number = 0;
    formularioid: number = 0;
    preco: number = 0;
    error: Array<any> = [];
}

export class FormularioXResultadoData {
    hash: string = "";
    data: string = "";
    rascunho: boolean = true;
    formularioid: number = 0;
}

export class FormularioIniciadoData {
    hash: string = "";
    titulo: string = "";
    formularioid: number = 0;
    configuracaoformularioid: number = 0;
    rascunho: boolean = true;
    datecreated: Date = new Date();
    isresultadoparcial: boolean = false;
}

export class ConfiguracaoFormularioData {
    id: number = 0;
    nome: string = "";
    formularioid: number = 0;
    error: Array<any> = [];
}

export enum FilterStatusOptionSelect {
    Rascunho = "1",
    Finalizado = "2"
}

export enum FilterOderByOptionSelect {
    FormName = 1,
    FormDate = 2
}

export class MarketPlaceDetail extends React.Component<RouteComponentProps<{}>, MarketPlaceDetailDataState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        var formularioid = this.props.match.params["formularioid"];

        var formulario = new FormularioData;
        formulario.id = formularioid;

        this.state = {
            formulario: formulario,
            comprado: false,
            formularioXUsuario: new FormularioXUsuarioData,
            formularioIniciado: [],
            datavencimento: "",
            configForms: [],
            configuracaoFormularioDataId: 0,
            dataInicial: "",
            dataFinal: "",
            countFormularioParcial: 0,
            countFormularioFinalizado: 0,
            statusSelecionado: "",
            statusArquivar: "",
            countFormularioParcialEFinalizado: 0,
            paginationNumber: 1,
            paginationCount: 10,
            listaRender: [],
            modalRealizarFormulariosAberto: false,
            modalDeConfirmacaoDeReabrirFormularioAberto: false,
            hashDoFormularioParaReabrir: "",
            camposDeFiltroEstaoOcultos: true,
            mensagemCasoNaoHajaResultado: "",
            clientSecret: null,
            modalDeCompra: false,
            inputDeCupom: "",
            cupomInserido: null,
        };

        // This will set state for Edit User  
        Loading.showLoading(this);
        HttpService.fetch('api/FormularioAPI/' + formularioid, { method: 'GET' })
            .then(response => response.json() as Promise<FormularioData>)
            .then(data => {
                this.setState({ formulario: data });
                Loading.hideLoading(this);
            }).catch((e) => {

                Loading.hideLoading(this);
                props.history.push(`/marketPlace`);
            });

        Loading.showLoading(this);
        HttpService.fetch('api/FormularioXEmpresaApi/FormularioComprado/' + formularioid, { method: 'GET' })
            .then(response => response.json() as Promise<string>)
            .then(data => {
                this.setState({ comprado: data.length > 0, datavencimento: data });
                this.listarResultados(formularioid);
            }).catch((e) => {
                Loading.hideLoading(this);
                props.history.push(`/marketPlace`);
            });

        Loading.showLoading(this);
        HttpService.fetch('api/ConfiguracaoFormularioApi?formularioId=' + formularioid, { method: 'GET' })
            .then(response => response.json() as Promise<ConfiguracaoFormularioData[]>)
            .then(data => {
                this.setState({ configForms: data });
                Loading.hideLoading(this);
            });

        // This binding is necessary to make "this" work in the callback  
        this.handleComprar = this.handleComprar.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.buscarResultadosPeloFiltro = this.buscarResultadosPeloFiltro.bind(this);
    }

    private listarResultados(formularioid) {
        HttpService.fetch(`api/FormularioIniciadoApi/PorFormulario/${formularioid}`, { method: 'GET' })
            .then(response => response.json() as Promise<FormularioIniciadoData[]>)
            .then(data => {
                this.setState({ formularioIniciado: data, countFormularioParcialEFinalizado: data.length });
                this.countResultadoParcialEFinalizado(data);
                Loading.hideLoading(this);
            }).catch((e) => {
                Loading.hideLoading(this);
            });
    }

    private buscarResultadosPeloFiltro(event) {
        event.preventDefault();
        Loading.showLoading(this);
        HttpService.fetch(`api/FormularioIniciadoApi/ListByFilter/`,
            {
                method: 'POST',
                body: HttpService.formToJson(event.target)
            })
            .then(response => response.json() as Promise<FormularioIniciadoData[]>)
            .then(data => {
                this.setState({
                    formularioIniciado: data,
                    countFormularioParcialEFinalizado: data.length,
                    paginationNumber: 1,
                    mensagemCasoNaoHajaResultado: this.state.statusArquivar === "1"
                        ? this.context.getLanguageResource('there_are_no_forms_filed')
                        : this.context.getLanguageResource('there_are_no_forms_started_with_filters_applied')
                });
                this.countResultadoParcialEFinalizado(data);
                if (data.length === 0) {
                    this.handleClearDateFields();
                }
                Loading.hideLoading(this);
            }).catch((e) => {
                Loading.hideLoading(this);
            });
    }

    handleClearDateFields = () => {
        this.setState({
            dataInicial: "",
            dataFinal: ""
        });
    };

    carregarInformacoesDeCompra = () => {
        this.setState({ modalDeCompra: true });
        HttpService.fetch('api/Pagamentos/Pagar', {
            method: 'POST',
            body: JSON.stringify({
                CupomDeDesconto: this.state.cupomInserido !== null ? this.state.cupomInserido.codigo : "",
                FormularioId: this.state.formulario.id,
                QuantidadeDeFormularios: 1,
            })
        })
            .then(response => response.json())
            .then(data => {
                if (data.hash !== undefined) {
                    window.location.href = `/formulario/responder/${this.state.formulario.id}/${data.hash}`;
                } else {
                    this.setState({ ...this.state, clientSecret: data });
                }
            });
    }

    botoesDoCantoSuperiorDireito = () => {
        return (
            <React.Fragment>
                {
                    this.state.comprado
                        ?   (
                                <React.Fragment>
                                    <button
                                        className="btn btn-success shadow mx-3 text-nowrap"
                                        type="button"
                                        onClick={() => this.setState({ modalRealizarFormulariosAberto: true })}
                                    >
                                        <i className='fa fa-check-circle'></i> {<Resource keyString="accomplish" />}
                                    </button>
                                    {
                                        this.state.modalRealizarFormulariosAberto && (
                                            <ModalWinSet fecharModal={() => this.setState({ modalRealizarFormulariosAberto: false })}>
                                                <RealizarFormulario formulario={this.state.formulario} configuracaoFormularioDataId={this.state.configuracaoFormularioDataId} fecharModal={() => this.setState({ modalRealizarFormulariosAberto: false })} history={this.props.history}></RealizarFormulario>
                                            </ModalWinSet>
                                        )
                                    }
                                </React.Fragment>
                            )
                        :   (
                                <React.Fragment>
                                        <button className='btn btn-success shadow mx-3 text-nowrap' onClick={this.carregarInformacoesDeCompra}><i className='fa fa-shopping-cart'></i> <Resource keyString="buy" /></button>
                                </React.Fragment>
                            )
                }

                <button
                    className="btn btn-outline-dark mx-3"
                    type="button"
                    onClick={() => this.props.history.goBack()}
                >
                    {<Resource keyString="back" />}
                </button>

            </React.Fragment>
        );
    }
    private handleCancel(e) {
        this.redirectPage();
    }

    private handleComprar(event) {
        event.preventDefault();
        console.log('event target', event.target);
        Loading.showLoading(this);

        if (!(this.state.formulario.valor > 0)) {
            HttpService.fetch('api/FormularioXEmpresaApi', {
                method: 'POST',
                body: HttpService.formToJson(event.target),
            }).then((response) => {
                return response.json() as Promise<FormularioXUsuarioData>
            }).then((data) => {
                console.log('formulario x empresa api', data);
                this.setState({ formularioXUsuario: data })
                if ($.isEmptyObject(data.error)) {
                    window.location.href = `/marketPlace/detail/${data.formularioid}`;
                }
                Loading.hideLoading(this);
            }).catch((e) => {

                Loading.hideLoading(this);
            })
        } else {
            HttpService.fetch(`api/PagamentosApi/ComprarFormulario/${this.state.formulario.id}`, {
                method: 'POST',
                body: null,
            }).then((response) => {
                return response.json() as Promise<PagamentoData>
            }).then((data) => {
                console.log('comprar formulario', data);
                if ($.isEmptyObject(data.error)) {
                    window.location.href = data.url;
                    //this.props.history.push("/");
                } else {
                    Loading.hideLoading(this);
                }
            }).catch(() => {
                Loading.hideLoading(this);
            })
        }
    }

    private handleReabrirFormulario(hash) {
        $("#modalConfirm").modal('hide');
        Loading.showLoading(this);
        HttpService.fetch(`api/ResultadoApi/ReabrirFormulario/${hash}`, { method: 'GET' })
            .then((response) => {
                return response.json() as Promise<FormularioIniciadoData>
            })
            .then((data) => {
                window.location.href = `/formulario/responder/${data.formularioid}/${data.hash}`;
                Loading.hideLoading(this);
            }).catch((e) => {
                console.log(e);
                Loading.hideLoading(this);
            })
    }

    private redirectPage() {
        this.props.history.goBack();
    }

    changeOrder(event) {
        if (event == FilterOderByOptionSelect.FormName) {
            this.state.formularioIniciado.sort(function (a, b) {
                var tempA = (a.titulo != null && a.titulo.length > 0 ? a.titulo : a.hash).toUpperCase();
                var tempB = (b.titulo != null && b.titulo.length > 0 ? b.titulo : b.hash).toUpperCase();

                if (tempA > tempB) {
                    return 1;
                }
                else {
                    return -1
                }
            });
            this.setState({ formularioIniciado: this.state.formularioIniciado });
        } else if (event == FilterOderByOptionSelect.FormDate) {
            this.state.formularioIniciado.sort(function (a, b) {
                if (a.datecreated > b.datecreated) {
                    return 1;
                }
                else {
                    return -1
                }
            });
            this.setState({ formularioIniciado: this.state.formularioIniciado });
        }
    }

    public countResultadoParcialEFinalizado(formularioIniciado) {
        var countFormParcial = 0;
        var countFormFinalizado = 0;

        formularioIniciado.map(o => o.rascunho == true ? countFormParcial++ : countFormFinalizado++)

        this.setState({ countFormularioParcial: countFormParcial, countFormularioFinalizado: countFormFinalizado })
    }

    filterByPaginate = () => {
        var paginationNumber = this.state.paginationNumber;
        var paginationCount = this.state.paginationCount;
        var dataRender = this.state.formularioIniciado;

        if (this.state.statusSelecionado == FilterStatusOptionSelect.Finalizado) {
            dataRender = dataRender.filter(doc => doc.rascunho == false);
            this.state.listaRender.push(dataRender);
            this.getPaginationNumber(dataRender);
        }
        else if (this.state.statusSelecionado == FilterStatusOptionSelect.Rascunho) {
            dataRender = dataRender.filter(doc => doc.rascunho);
            this.state.listaRender.push(dataRender);
            this.getPaginationNumber(dataRender);
        }
        else {
            this.state.listaRender.push(dataRender);
            this.getPaginationNumber(dataRender);
        }

        var listaFiltrada: Array<any> = [];
        var initial = ((paginationNumber - 1) * paginationCount);
        var max = (((paginationNumber - 1) * paginationCount) + paginationCount);
        for (var i = initial; i < max; i++) {
            if (dataRender[i] != undefined) {
                listaFiltrada.push(dataRender[i]);
            }
        }

        return this.montarLinhasFormularioIniciado(listaFiltrada)
    }

    handleChangePaginationNumber = (number) => {
        this.setState({ paginationNumber: number });
    }

    nextHandler = () => {
        var paginationCount = this.state.paginationCount;
        var countDataRender = this.state.formularioIniciado.length
        var total = Math.ceil(countDataRender / paginationCount);

        if (this.state.paginationNumber < total) {
            let active = this.state.paginationNumber;
            this.setState({ paginationNumber: active + 1 });
        }
    };

    previousHandler = () => {
        if (this.state.paginationNumber > 1) {
            let active = this.state.paginationNumber;
            this.setState({ paginationNumber: active - 1 });
        }
    };

    getPaginationNumber = (dataRender) => {
        var elements: Array<any> = [];
        var paginationCount = this.state.paginationCount;
        var countDataRender = 0;

        if (dataRender != undefined) {
            if (dataRender.length > 0) {
                countDataRender = dataRender.length;
            }
        }
        else {
            countDataRender = this.state.formularioIniciado.length;
        }

        var quantidadeElements = Math.ceil(countDataRender / paginationCount);

        var posicaoInicial = this.state.paginationNumber;
        var numeroExibidoPorVez = quantidadeElements >= 10 ? 10 : quantidadeElements;

        var quantidadeElementosPaginacao = 0;

        if (posicaoInicial > (quantidadeElements - numeroExibidoPorVez)) {
            quantidadeElementosPaginacao = quantidadeElements + 1;
            posicaoInicial = (quantidadeElements - numeroExibidoPorVez) + 1;
        }
        else {
            quantidadeElementosPaginacao = numeroExibidoPorVez + posicaoInicial;
        }

        if (countDataRender > 0) {
            for (let i = posicaoInicial; i < quantidadeElementosPaginacao; i++) {
                if (i == this.state.paginationNumber) {
                    elements.push(<button className="btn rounded ml-1 d-flex align-items-center justify-content-center btn-primary" onClick={() => this.handleChangePaginationNumber(i)}>{i}</button>);
                }
                else {
                    elements.push(<button className="btn rounded ml-1 d-flex align-items-center justify-content-center" onClick={() => this.handleChangePaginationNumber(i)}>{i}</button>);
                }
            }
        }

        return elements
    }

    montarListagemFormularios(formularioIniciado) {
        return (
            formularioIniciado.length > 0 ?
                <div className="btn-toolbar float-left py-3" role="toolbar" aria-label="Toolbar with button groups">
                    <div className="btn-group mr-2" role="group" aria-label="First group">
                        <button className="btn" onClick={() => this.previousHandler()}><ChevronLeft /></button>
                        {this.getPaginationNumber(this.state.listaRender[this.state.listaRender.length - 1])}
                        <button className="btn" onClick={() => this.nextHandler()}><ChevronRight /></button>
                    </div>
                </div>
                :
                <div className="alert alert-primary" role="alert">
                    {this.context.getLanguageResource("there_is_no_data_for_the_selected_period")}
                </div>
        )
    }

    private handleArquivar(hash) {
        var hashTemporaria = hash;
        var listaTemporaria = this.state.formularioIniciado;
        HttpService.fetch(`api/FormularioIniciadoApi/ArquivarFormularioIniciado/${hash}`, { method: 'POST' })
            .then(response => response.json())
            .then(data => {
                var listaFiltrada = listaTemporaria.filter((obj) => {
                    return (obj.hash != hashTemporaria)
                });
                this.setState({ formularioIniciado: listaFiltrada });
                Loading.hideLoading(this);
            }).catch((e) => {
                Loading.hideLoading(this);
            });
    }

    private handleDesarquivar(hash) {
        var hashTemporaria = hash;
        var listaTemporaria = this.state.formularioIniciado;
        HttpService.fetch(`api/FormularioIniciadoApi/DesarquivarFormularioIniciado/${hash}`, { method: 'POST' })
            .then(response => response.json())
            .then(data => {
                var listaFiltrada = listaTemporaria.filter((obj) => {
                    return (obj.hash != hashTemporaria)
                });
                this.setState({ formularioIniciado: listaFiltrada });
                Loading.hideLoading(this);
            }).catch((e) => {
                Loading.hideLoading(this);
            });
    }

    montarLinhasFormularioIniciado(listaFiltrada) {
        return (
            <React.Fragment>
                <GlobalContext.Consumer>
                    {
                        (globalContext) => (
                            <>
                                {listaFiltrada.map(doc =>
                                    <tr key={doc.hash} style={doc.rascunho ? { 'backgroundColor': 'white' } : { 'backgroundColor': '#eff0f1' }}>
                                        <td className='align-middle'>{(doc.titulo != null && doc.titulo.length > 0 ? doc.titulo : doc.hash)}</td>
                                        <td className='d-flex flex-row-reverse justify-content-start'>
                                            {
                                                doc.rascunho
                                                    ? (
                                                        <React.Fragment>
                                                            {
                                                                !doc.arquivado
                                                                    ? (
                                                                        <React.Fragment>
                                                                            <button style={{ width: '2.75rem', height: '2.75rem' }} className="btn btn-outline-danger mr-2 pull-right" onClick={() => this.handleArquivar(doc.hash)}>
                                                                                <img style={{ width: '1.25rem', height: '1.25rem' }} src={LixeiraIcon} alt='Ícone de lixeira' />
                                                                            </button>
                                                                            <button style={{ height: '2.75rem' }} className="btn btn-outline-info mr-2 pull-right"
                                                                                onClick={(x) => this.props.history.push(`/formulario/responder/${this.state.formulario.id}/${doc.hash}`)}>
                                                                                {<Resource keyString="continue" />}
                                                                            </button>
                                                                            {
                                                                                doc.isresultadoparcial && !globalContext.afiliacao && (
                                                                                    <button style={{ height: '2.75rem' }} className="btn btn-outline-secondary mr-2 pull-right" title='Resultado Parcial'
                                                                                        onClick={(x) => this.props.history.push(`/formulario/respondido/${doc.hash}`)}>
                                                                                        <img style={{ width: '1.25rem', height: '1.25rem' }} src={VerificarResultadoIconCinza} alt='Resultado Parcial' />
                                                                                    </button>
                                                                                )
                                                                            }
                                                                        </React.Fragment>
                                                                    )
                                                                    : <button style={{ height: '2.75rem' }} className="btn btn-outline-success mr-2 pull-right" onClick={() => this.handleDesarquivar(doc.hash)}>{<Resource keyString="unarchived" />}</button>
                                                            }
                                                        </React.Fragment>
                                                    )
                                                    : (
                                                        <React.Fragment>
                                                            {
                                                                !doc.arquivado
                                                                    ? (
                                                                        <React.Fragment>
                                                                            <button style={{ width: '2.75rem', height: '2.75rem' }} className="btn btn-outline-danger mr-2 pull-right" onClick={() => this.handleArquivar(doc.hash)}>
                                                                                <img style={{ width: '1.25rem', height: '1.25rem' }} src={LixeiraIcon} alt='Ícone de lixeira' />
                                                                            </button>
                                                                            <Authorize role="reativarformulario">
                                                                                <button style={{ height: '2.75rem' }} className="btn btn-outline-danger mr-2 pull-right" onClick={() => this.setState({ modalDeConfirmacaoDeReabrirFormularioAberto: true, hashDoFormularioParaReabrir: doc.hash })}>{<Resource keyString="reopen" />}</button>
                                                                            </Authorize>
                                                                            {
                                                                                !globalContext.afiliacao && (
                                                                                    <button style={{ width: '2.75rem', height: '2.75rem' }} className="btn btn-outline-info mr-2 pull-right" onClick={(x) => this.props.history.push(`/formulario/respondido/${doc.hash}`)} title='Resultado'>
                                                                                        <img style={{ width: '1.25rem', height: '1.25rem' }} src={VerificarResultadoIcon} alt='Resultado' />
                                                                                    </button>
                                                                                )
                                                                            }
                                                                        </React.Fragment>
                                                                    )
                                                                    : <button style={{ height: '2.75rem' }} className="btn btn-outline-success mr-2 pull-right" onClick={() => this.handleDesarquivar(doc.hash)}>{<Resource keyString="unarchived" />}</button>
                                                            }

                                                            {
                                                                this.state.modalDeConfirmacaoDeReabrirFormularioAberto && this.state.hashDoFormularioParaReabrir === doc.hash && (
                                                                    <ModalWinSet fecharModal={() => this.setState({ modalDeConfirmacaoDeReabrirFormularioAberto: false })}>
                                                                        <div className='p-4'>
                                                                            <main className='w-100 d-flex justify-content-center my-3'>
                                                                                <p><Resource keyString="do_you_want_to_reopen_the_form" /></p>
                                                                            </main>
                                                                            <footer className='w-100 d-flex justify-content-end'>
                                                                                <button type="button" onClick={() => this.handleReabrirFormulario(doc.hash)} className="btn mr-2" style={{ backgroundColor: 'var(--ws-blue)' }}>{<Resource keyString="yes" />}</button>
                                                                                <button type="button" className="btn btn-outline-dark" onClick={() => this.setState({ modalDeConfirmacaoDeReabrirFormularioAberto: false })}>{<Resource keyString="no" />}</button>
                                                                            </footer>
                                                                        </div>
                                                                    </ModalWinSet>
                                                                )
                                                            }
                                                        </React.Fragment>
                                                    )
                                            }
                                        </td>
                                    </tr>
                                )}
                            </>
                        )
                    }
                </GlobalContext.Consumer>
            </React.Fragment>
        );
    }

    verificarSeOCupomExiste = () => {
        HttpService.fetch('api/Cupons/VerificarCupom', {
            method: 'POST',
            body: JSON.stringify({
                codigo: this.state.inputDeCupom,
                formularioid: this.state.formulario.id
            })
        })
            .then(response => {
                if (response.status === 204) {
                    return null;
                } else {
                    return response.json() as Promise<CupomData>;
                }
            })
            .then(data => {
                if (data !== null) {
                    document.getElementById("campo_de_cupom_de_desconto")?.classList.remove("is-invalid");
                    this.setState({ cupomInserido: data });
                } else {
                    document.getElementById("campo_de_cupom_de_desconto")?.classList.add("is-invalid");
                    this.setState({ cupomInserido: null });
                }
            });
    }

    calcularDesconto = (): number => {
        if (this.state.cupomInserido?.tipo === "D") {
            const valor = this.state.formulario.valor - this.state.cupomInserido.valor;
            return valor < 0 ? 0 : valor;
        } else if (this.state.cupomInserido?.tipo === "P") {
            const valor = this.state.formulario.valor - (this.state.formulario.valor * (this.state.cupomInserido.valor / 100));
            return valor < 0 ? 0 : valor;
        }
        
        return this.state.formulario.valor;
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;

        return (
            <CaixaDeConteudo
                botoes={this.botoesDoCantoSuperiorDireito()}
                itensParaBreadcrumb={[
                    { nome: 'Início', url: '/' },
                    { nome: 'Loja de Formulário', url: '/' },
                    { nome: this.state.formulario.nome }
                ]}
                tituloDoHeader={ this.context.getLanguageResource('details') }
                tituloDaPagina={this.state.formulario.nome}
            >
                <div>
                    <div className='w-100 p-3 d-flex'>
                        <section className='w-100 ml-2 mr-3'>
                            <header>
                                <section>
                                    <h5>{this.state.formulario.nome}</h5>
                                    <p className='text-muted'>{this.state.formulario.descricao}</p>
                                </section>
                                {
                                    !this.state.comprado && (
                                        <div className='d-flex justify-content-between border-top mt-2'>
                                            <div>
                                                <label><strong><Resource keyString="price" />:</strong></label>
                                                {
                                                    this.state.cupomInserido !== null
                                                        ?   <>
                                                                <h3 className='text-success'>R${this.calcularDesconto().toFixed(2).replace(".", ",")}</h3>
                                                                <small className='text-muted'>Desconto aplicado. Valor anterior: {this.state.formulario.valor.toFixed(2).replace(".", ",")}</small>
                                                            </>
                                                        :   <h3>R${this.state.formulario.valor.toFixed(2).replace(".", ",")}</h3>
                                                }
                                            </div>
                                            <div>
                                                <label>Inserir cupom de desconto</label>
                                                <div className='d-flex flex-column'>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="campo_de_cupom_de_desconto"
                                                        value={this.state.inputDeCupom}
                                                        onChange={(e) => {
                                                            document.getElementById("campo_de_cupom_de_desconto")?.classList.remove("is-invalid");
                                                            this.setState({ inputDeCupom: e.target.value })
                                                        }}
                                                    />
                                                    {
                                                        document.getElementById("campo_de_cupom_de_desconto")?.classList.contains("is-invalid")
                                                            ?   <div className="invalid-feedback">O cupom não existe ou expirou</div>
                                                            :   <div className="valid-feedback">Cupom válido</div>
                                                    }
                                                    <button className='mt-2 btn ws-outline-blue' onClick={this.verificarSeOCupomExiste}>Inserir</button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </header>
                            {
                                this.state.comprado && (
                                    <>
                                        <form className='mb-0 border-top pb-1' onSubmit={this.buscarResultadosPeloFiltro} style={{ transition: 'max-height 550ms ease-in-out', maxHeight: this.state.camposDeFiltroEstaoOcultos ? '0px' : '1000px', overflow: 'hidden' }}>
                                            <input className="d-none" name="FormularioId" value={this.state.formulario.id} />
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="mt-2">
                                                        <label style={{ fontSize: '0.875rem' }} className="m-0">Nome do Formulário</label>
                                                        <input className="form-control rounded" placeholder="Digite o nome do formulário" type="text" name="FormularioNome" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="mt-2">
                                                        <label style={{ fontSize: '0.875rem' }} className="m-0">{<Resource keyString="initial_date" />}</label>
                                                        <input className="form-control rounded" type="date" name="DataInicial" id="DataInicial" onChange={(e) => this.setState({ dataInicial: e.target.value })} defaultValue={this.state.dataInicial} />
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="mt-2">
                                                        <label style={{ fontSize: '0.875rem' }} className="m-0">{<Resource keyString="final_date" />}</label>
                                                        <input className="form-control rounded" type="date" name="DataFinal" id="DataFinal" onChange={(e) => this.setState({ dataFinal: e.target.value })} defaultValue={this.state.dataFinal} />
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="mt-2">
                                                        <label style={{ fontSize: '0.875rem' }} className="m-0">{<Resource keyString="status" />}</label>
                                                        <select className="form-control" name="StatusSelecionado" onChange={(e) => this.setState({ statusSelecionado: e.target.value })} defaultValue={this.state.statusSelecionado}>
                                                            <option value="">{this.context.getLanguageResource("all_results") + " (" + this.state.countFormularioParcialEFinalizado + ")"}</option>
                                                            <option value="1">{this.context.getLanguageResource("not_finished") + " (" + this.state.countFormularioParcial + ")"}</option>
                                                            <option value="2">{this.context.getLanguageResource("finished") + " (" + this.state.countFormularioFinalizado + ")"}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="mt-2">
                                                        <label style={{ fontSize: '0.875rem' }} className="m-0">{<Resource keyString="filed" />}</label>
                                                        <select className="form-control" name="Arquivado_Id" onChange={(e) => this.setState({ statusArquivar: e.target.value })} defaultValue={this.state.statusArquivar}>
                                                            <option value="0">{this.context.getLanguageResource("not_filed")}</option>
                                                            <option value="1">{this.context.getLanguageResource("filed")}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-12" style={{ marginTop: "25px" }}>
                                                    <div className="d-flex align-items-end justify-content-end">
                                                        <button type="submit" className="btn shadow" style={{ backgroundColor: 'var(--ws-blue)' }}>
                                                            <img src={FiltrarIcon} className='mr-2' alt="Filtrar" />
                                                            <Resource keyString="filter" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <div className='w-100 d-flex justify-content-center rounded-bottom py-2 ws-background-hover-cinza' style={{ cursor: 'pointer' }} onClick={() => this.setState({ camposDeFiltroEstaoOcultos: !this.state.camposDeFiltroEstaoOcultos })}>
                                            <img src={MenuArrowIcon} alt="Seta do collapse" className='px-2' style={{ transform: (this.state.camposDeFiltroEstaoOcultos) ? '' : 'rotate(180deg)', transition: 'transform 550ms ease' }} />
                                        </div>
                                    </>
                                )
                            }
                        </section>
                    </div>
                    {
                        this.state.comprado && (
                                this.state.formularioIniciado.length > 0
                                    ?   (
                                            <table className='table table-hover'>
                                                <thead>
                                                    <tr>
                                                        <th className='align-middle'>
                                                            <div className='d-flex align-items-center h-100' style={{ whiteSpace: 'nowrap' }}>
                                                                <Resource keyString="started_forms" />
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className='d-flex align-items-center justify-content-end' style={{ whiteSpace: 'nowrap' }}>
                                                                <span className='mr-2'>
                                                                    <Resource keyString="order_by" />:
                                                                </span>
                                                                <select className="form-control" style={{ maxWidth: '200px' }} onChange={(val) => this.changeOrder(val.target.value)}>
                                                                    <option value="">{this.context.getLanguageResource("select")}</option>
                                                                    <option value="1">{this.context.getLanguageResource("form_name")}</option>
                                                                    <option value="2">{this.context.getLanguageResource("form_date")}</option>
                                                                </select>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.filterByPaginate()}
                                                </tbody>
                                            </table>
                                        )
                                    :   <div className='w-100 py-4 text-center'>
                                            {
                                                this.state.mensagemCasoNaoHajaResultado === ""
                                                    ?   this.context.getLanguageResource("there_are_no_forms_started")
                                                    :   this.state.mensagemCasoNaoHajaResultado
                                            }
                                        </div>
                        )
                    }
                    <div id="paginacao" className="d-flex justify-content-center align-items-center">
                        {
                            this.montarListagemFormularios(this.state.formularioIniciado)
                        }
                    </div>
                </div>
                {
                    this.state.modalDeCompra && (
                        <ModalWinSet fecharModal={() => this.setState({ modalDeCompra: false })}>
                            <div className='p-5'>
                                {
                                    this.state.clientSecret
                                        ?   <Payment clientSecret={this.state.clientSecret} formularioid={this.state.formulario.id} />
                                        :   (
                                                <div className='d-flex align-items-center justify-content-center p-5'>
                                                    Carregando informações de pagamento...
                                                </div>
                                            )
                                }
                            </div>
                        </ModalWinSet>
                    )
                }
            </CaixaDeConteudo>
        );
    }


}  