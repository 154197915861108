function formatarCPF(cpf: string): string {
    // Remover caracteres não numéricos
    cpf = cpf.replace(/[^\d]/g, '');

    if (cpf.length <= 3) {
        // Não aplicar máscara
    } else if (cpf.length <= 6) {
        cpf = cpf.replace(/^(\d{3})(\d{0,3})?/, '$1.$2');
    } else if (cpf.length <= 9) {
        cpf = cpf.replace(/^(\d{3})(\d{0,3})?(\d{0,3})?/, '$1.$2.$3');
    } else if (cpf.length <= 11) {
        cpf = cpf.replace(/^(\d{3})(\d{0,3})?(\d{0,3})?(\d{0,2})?/, '$1.$2.$3-$4');
    }

    return cpf;
};

export default formatarCPF;
