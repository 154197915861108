import React from 'react';
import CollapseDeGrupoDePerguntas from '../CollapseDeGrupoDeRespostas';

interface IProps {
	listaDeGruposDePerguntas: Array<any>;
	resultadoDoFormulario: any;
}

const ContainerDeGruposDePerguntas = ({ listaDeGruposDePerguntas, resultadoDoFormulario }: IProps) => {
	return (
		<div>
			{
				listaDeGruposDePerguntas
					.sort((a, b) => a.nome.localeCompare(b.nome))
					.map((grupo) => <CollapseDeGrupoDePerguntas grupo={grupo} resultadoDoFormulario={resultadoDoFormulario} />)
			}
		</div>
	);
};

export default ContainerDeGruposDePerguntas;
