import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { HttpService } from '../../Shared/HttpService';
import { HeadLayout } from '../../Shared/HeadLayout';
import { AuthService } from '../AuthService';
import { NavLink } from 'react-router-dom';
import { Loading } from '../../Shared/Loading';
import { ValidationLabelMessage, ValidationAlertMessage } from '../../Shared/ValidationMessage';
import { UsuarioData } from '../../Usuarios/FetchUsuarios';
import { Resource } from '../../../index';
import { LanguageContext } from '../../../context/LanguageContext';
import Logo from '../../../assets/img/winset-logo.png';
import ModalWinSet from '../../Shared/components/ModalWinSet';
import formatarCNPJ from '../../../helpers/mascaraCNPJ';
import validarCNPJ from '../../../helpers/validarCNPJ';
import { ValidadorDeSenha } from '../../../helpers/validadorDeSenha';
import formatarTokenConfirmacaoDeEmail from '../../../helpers/mascaraTokenConfirmacaoEmail';
import PhoneInput from 'react-phone-input-2';
import formatarTokenConfirmacaoDeCelular from '../../../helpers/mascaraTokenConfirmacaoCelular';
import { TextoDeTermosDeAceite } from '../../Shared/components/TextoDeTermosDeAceite';
import { ToggleSwitch } from '../../Shared/components/ToggleSwitch';
import { ToggleSwitchWithTextInside } from '../../Shared/components/ToggleSwitchWithTextInside';
import Select, { OptionsType, SingleValueProps, ValueType } from 'react-select';
import formatarCPF from '../../../helpers/mascaraCPF';
import validarCPF from '../../../helpers/validarCPF';
import { RegisterWrapper } from './components/RegisterWraper';
import EmpresasERotas from '../../../assets/json/empresasERotas.json';

interface RegisterDataState {
    register: RegisterData;
    emailCadastrado: boolean;
    modalAberto: boolean;
    semNumeroMarcado: boolean;
    esconderSenha: boolean;
    esconderConfirmarSenha: boolean;
    estruturaDaSenha: IEstruturaDaSenha;
    momentoDeCadastro: MomentoDeCadastro;
    momentoDeConfirmacaoDeEmail: MomentoDeConfirmacaoDeEmail;
    momentoDeConfirmacaoDeCelular: MomentoDeConfirmacaoDeCelular;
    momentoDaTelaDeConfirmacaoDeCelular: MomentoDaTelaDeConfirmacaoDeCelular;
    valorDoInputDeTokenDeConfirmacaoDeEmail: string;
    valorDoInputDeTokenDeConfirmacaoDeCelular: string;
    verificandoEmail: boolean;
    verificandoCNPJ: boolean;
    modalDeEmailDemonstrouInteresseAberto: boolean;
    carregandoDadosDaPessoaInteressada: boolean;
    tipoDeAuditor: TipoDeAuditor;
    codigoDeEmpresaInternacional: boolean;
    possuiCNPJ: boolean;
    imagensDinamicas: { logo: string, icon: string, whitelogo: string };
}

interface IEstruturaDaSenha {
    oitoCaracteres: boolean;
    umaLetraMaiuscula: boolean;
    umaLetraMinuscula: boolean;
    umNumero: boolean;
    umCaractereEspecial: boolean;
}

interface IEmailCheck {
    emailEstaCadastrado: boolean;
    emailDemonstrouInteresse: boolean;
}

interface ICNPJCheck {
    cnpjEstaCadastrado: boolean;
}

enum MomentoDeCadastro {
    Usuario,
    Empresa,
    ConfirmarEmail,
    ConfirmarCelular,
    Finalizar,
}

enum MomentoDeConfirmacaoDeEmail {
    Pendente,
    EmAndamento,
    Sucesso,
    Falha,
}

enum MomentoDeConfirmacaoDeCelular {
    Pendente,
    EmAndamento,
    Sucesso,
    Falha,
}

enum MomentoDaTelaDeConfirmacaoDeCelular {
    SolicitarCodigo,
    FalhaAoSolicitarCodigo,
    CodigoSolicitado,
}

enum TipoDeAuditor {
    Empresa,
    Independente,
}

interface OptionType {
    value: string;
    label: string;
}

export class RegisterData {
    nome: string = "";
    email: string = "";
    senha: string = "";
    documento: string = "";
    confirmarSenha: string = "";
    cliente_NomeFantasia: string = "";
    cliente_RazaoSocial: string = "";
    cliente_Documento: string = "";
    cliente_NomePropriedade: string = "";
    cliente_Telefone: string = "";
    cliente_Ramal: string = "";
    cliente_Cep: string = "";
    cliente_Endereco: string = "";
    cliente_Numero: string = "";
    cliente_Complemento: string = "";
    cliente_Bairro: string = "";
    cliente_Cidade: string = "";
    cliente_Uf: string = "";
    cliente_Celular: string = "";
    cliente_Cargo: string = "";
    cliente_Tipoetamanho: string = "";
    cliente_Seguimento: string = "";
    cliente_Departamento: string = "";
    emailConfirmadoEm: string | null = null;
    celularConfirmadoEm: string | null = null;
    realizarCadastro: boolean = false;
    usuario: UsuarioData = new UsuarioData();
    error: Object = {};
    cepError: string = "";
}

export class RegisterPecuarista extends React.Component<RouteComponentProps<{}>, RegisterDataState> {
    static contextType = LanguageContext;
    formularioRef = React.createRef<HTMLFormElement>();

    constructor(props) {
        super(props);
        this.state = {
            register: new RegisterData,
            emailCadastrado: false,
            modalAberto: false,
            semNumeroMarcado: false,
            esconderSenha: true,
            esconderConfirmarSenha: true,
            momentoDeCadastro: MomentoDeCadastro.Usuario,
            momentoDeConfirmacaoDeEmail: MomentoDeConfirmacaoDeEmail.Pendente,
            momentoDeConfirmacaoDeCelular: MomentoDeConfirmacaoDeCelular.Pendente,
            momentoDaTelaDeConfirmacaoDeCelular: MomentoDaTelaDeConfirmacaoDeCelular.SolicitarCodigo,
            valorDoInputDeTokenDeConfirmacaoDeEmail: "",
            valorDoInputDeTokenDeConfirmacaoDeCelular: "",
            verificandoEmail: false,
            verificandoCNPJ: false,
            modalDeEmailDemonstrouInteresseAberto: false,
            carregandoDadosDaPessoaInteressada: false,
            tipoDeAuditor: TipoDeAuditor.Independente,
            codigoDeEmpresaInternacional: false,
            possuiCNPJ: false,
            imagensDinamicas: { logo: '', icon: '', whitelogo: '' },
            estruturaDaSenha: {
                oitoCaracteres: false,
                umaLetraMaiuscula: false,
                umaLetraMinuscula: false,
                umNumero: false,
                umCaractereEspecial: false,
            }
        };

        this.handleRegister = this.handleRegister.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleDocumentoChange = this.handleDocumentoChange.bind(this);
        this.momentoUsuarioEstaDevidamentePreenchido = this.momentoUsuarioEstaDevidamentePreenchido.bind(this);
        this.momentoEmpresaEstaDevidamentePreenchido = this.momentoEmpresaEstaDevidamentePreenchido.bind(this);
        this.solicitarCodigoDeConfirmacaoDeEmail = this.solicitarCodigoDeConfirmacaoDeEmail.bind(this);
        this.handleInputConfirmarEmailChange = this.handleInputConfirmarEmailChange.bind(this);
        this.retornaRotasDasImagensDaEmpresa = this.retornaRotasDasImagensDaEmpresa.bind(this);
        this.aRotaEstaListadaNoJson = this.aRotaEstaListadaNoJson.bind(this);
    }

    componentDidMount() {
        this.retornaRotasDasImagensDaEmpresa();
        if (AuthService.loggedIn()) {
            this.props.history.push("/");
        }
    }

    private handleRegister(event) {
        event.preventDefault();

        const formData = this.formularioRef.current;
        if (this.state.register.celularConfirmadoEm) formData?.querySelector('input[name="Cliente_CelularConfirmado"]')?.setAttribute('value', this.state.register.celularConfirmadoEm);
        if (this.state.register.emailConfirmadoEm) formData?.querySelector('input[name="Cliente_EmailConfirmado"]')?.setAttribute('value', this.state.register.emailConfirmadoEm);
        formData?.querySelector('input[name="TermosAceitos"]')?.setAttribute('value', this.state.register.realizarCadastro ? "1" : "0");

        Loading.showLoading(this);
        HttpService.fetch('api/Authentication/Register', {
            method: 'POST',
            body: HttpService.formToJson(formData),
        })
            .then((response) => response.json() as Promise<RegisterData>)
            .then((data) => {
                this.setState({ register: data });

                if (this.state.register.error === null || Object.keys(this.state.register.error).length === 0) {
                    window.location.href = "/login";
                }

                this.handleAceitarPoliticasETermos();
                Loading.hideLoading(this);
            })
    }

    handleSelectCNPJCountryChange = (event: ValueType<OptionType> | null) => {
        if (event && 'value' in event) {
            this.setState({ codigoDeEmpresaInternacional: event.value === 'world' });
        }
    }

    handleAceitarPoliticasETermos = () => {
        this.setState({ register: { ...this.state.register, realizarCadastro: !this.state.register.realizarCadastro } });
    };

    handleTextChange = (event) => {
        const error = this.state.register.error;
        delete error[event.target.name];

        const id = event.target.id;
        let value = event.target.value;
        this.setState({ register: { ...this.state.register, [id]: value, error } });
    };

    handleSelectChange = (event) => {
        const id = event.target.id;
        const value = event.target.value;

        this.setState({ register: { ...this.state.register, [id]: value } });
    };

    handleDocumentoChange(event): void {
        let documento = event.target.value.replace(/\D/g, '');

        if (Object.keys(this.state.register.error).includes("Cliente_Documento")) {
            delete this.state.register.error["Cliente_Documento"];
        }

        if (this.state.codigoDeEmpresaInternacional) {
            this.setState({ register: { ...this.state.register, cliente_Documento: documento } });
            return;
        }

        if (documento.length > 11 && this.state.possuiCNPJ) documento = documento.slice(0, 14);
        if (documento.length > 14 && !this.state.possuiCNPJ) documento = documento.slice(0, 11);
        
        const documentoFormatado = !this.state.possuiCNPJ ? formatarCPF(documento) : formatarCNPJ(documento);

        let representacaoDoObjetoDeErros = {};

        if (this.state.possuiCNPJ) {
            if (documento.length === 14 && !validarCNPJ(documento)) {
                representacaoDoObjetoDeErros = { ...this.state.register.error, Cliente_Documento: "CNPJ inválido" };
            }
        } else {
            if (documento.length === 11 && !validarCPF(documento)) {
                representacaoDoObjetoDeErros = { ...this.state.register.error, Cliente_Documento: "CPF inválido" };
            }
        }

        this.setState({ register: { ...this.state.register, cliente_Documento: documentoFormatado, error: representacaoDoObjetoDeErros } });
    }

    solicitarCodigoDeConfirmacaoDeEmail = () => {
        HttpService.fetch(`api/Authentication/SolicitarValidacaoDeEmail/Publico`, {
            method: 'POST',
            body: JSON.stringify({ email: this.state.register.email })
        })
    }

    confirmarEmail = (codigoDeConfirmacaoDeEmail: string) => {
        this.setState({ momentoDeConfirmacaoDeEmail: MomentoDeConfirmacaoDeEmail.EmAndamento });
        HttpService.fetch(`api/Authentication/ValidarEmail/Publico`, {
            method: 'POST',
            body: JSON.stringify({ token: codigoDeConfirmacaoDeEmail, email: this.state.register.email })
        })
            .then((response) => {
                if (response.ok) {
                    return response.json() as Promise<any>;
                } else {
                    this.setState({ momentoDeConfirmacaoDeEmail: MomentoDeConfirmacaoDeEmail.Falha });
                }
            }
            )
            .then((data) => {
                if (!data) return;
                this.setState({ momentoDeConfirmacaoDeEmail: MomentoDeConfirmacaoDeEmail.Sucesso, register: { ...this.state.register, emailConfirmadoEm: data } });
            });
    }

    solicitarCodigoDeConfirmacaoDeCelular = () => {
        HttpService.fetch(`api/Authentication/SolicitarValidacaoDeCelular/${this.state.register.cliente_Celular}`, { method: 'POST' })
            .then((response) => {
                if (response.ok) {
                    this.setState({ momentoDaTelaDeConfirmacaoDeCelular: MomentoDaTelaDeConfirmacaoDeCelular.CodigoSolicitado });
                } else {
                    this.setState({ momentoDaTelaDeConfirmacaoDeCelular: MomentoDaTelaDeConfirmacaoDeCelular.FalhaAoSolicitarCodigo });
                }
            })
    };

    confirmarCelular = (codigoDeConfirmacaoDeCelular: string) => {
        this.setState({ momentoDeConfirmacaoDeCelular: MomentoDeConfirmacaoDeCelular.EmAndamento });
        HttpService.fetch(`api/Authentication/ValidarTokenRecebidoPorSMSPublico/${this.state.register.cliente_Celular}/${codigoDeConfirmacaoDeCelular}`, { method: 'POST' })
            .then((response) => {
                if (response.ok) {
                    const dataDeHoje = new Date().toISOString().slice(0, 19).replace('T', ' ');
                    this.setState({ register: { ...this.state.register, celularConfirmadoEm: dataDeHoje }, momentoDeConfirmacaoDeCelular: MomentoDeConfirmacaoDeCelular.Sucesso });
                } else {
                    this.setState({ momentoDeConfirmacaoDeCelular: MomentoDeConfirmacaoDeCelular.Falha });
                }
            });
    }

    handleInputConfirmarEmailChange = (event) => {
        const codigoFormatado = formatarTokenConfirmacaoDeEmail(event.target.value);
        this.setState({ valorDoInputDeTokenDeConfirmacaoDeEmail: codigoFormatado });
    };

    handleChangeInputConfirmacaoDeCelular = (event) => {
        const codigoFormatado = formatarTokenConfirmacaoDeCelular(event.target.value);
        this.setState((_prevState) => {
            if (codigoFormatado.length === 7) {
                this.confirmarCelular(codigoFormatado);
            };
            return { valorDoInputDeTokenDeConfirmacaoDeCelular: codigoFormatado };
        });
    }

    momentoUsuarioEstaDevidamentePreenchido(): boolean {
        const { nome, email, senha, confirmarSenha } = this.state.register;
        return (
            nome !== ""
            && email !== ""
            && this.state.emailCadastrado === false
            && this.state.verificandoEmail === false
            && senha !== ""
            && senha === confirmarSenha
            && /\S+@\S+\.\S+/.test(email)
            && this.state.estruturaDaSenha.oitoCaracteres
            && this.state.estruturaDaSenha.umaLetraMaiuscula
            && this.state.estruturaDaSenha.umaLetraMinuscula
            && this.state.estruturaDaSenha.umNumero
            && this.state.estruturaDaSenha.umCaractereEspecial
        );
    };

    momentoEmpresaEstaDevidamentePreenchido(): boolean {
        const { cliente_NomeFantasia, cliente_Documento, cliente_RazaoSocial, cliente_Cargo, cliente_Departamento, cliente_Seguimento, cliente_Tipoetamanho, cliente_Telefone, cliente_Celular } = this.state.register;
        const documentoEhValido = this.state.possuiCNPJ
            ?   validarCNPJ(cliente_Documento.replace(/\D/g, ''))
            :   validarCPF(cliente_Documento.replace(/\D/g, ''))
        
        if (this.state.tipoDeAuditor === TipoDeAuditor.Independente && this.state.possuiCNPJ) {
            return (
                cliente_NomeFantasia !== ""
                && cliente_Documento !== ""
                && !this.state.codigoDeEmpresaInternacional && documentoEhValido
                && this.state.verificandoCNPJ === false
                && cliente_RazaoSocial !== ""
                && cliente_Celular !== ""
            );
        }

        if (this.state.tipoDeAuditor === TipoDeAuditor.Independente) {
            return (
                cliente_Documento !== ""
                && !this.state.codigoDeEmpresaInternacional && documentoEhValido
                && cliente_Celular !== ""
            );
        }

        return (
            cliente_NomeFantasia !== ""
            && cliente_Documento !== ""
            && this.state.verificandoCNPJ === false
            && validarCNPJ(cliente_Documento.replace(/\D/g, ''))
            && cliente_RazaoSocial !== ""
            && cliente_Cargo !== ""
            && cliente_Departamento !== ""
            && cliente_Seguimento !== ""
            && cliente_Tipoetamanho !== ""
            && cliente_Telefone !== ""
            && cliente_Celular !== ""
        );
    };

    checkEmail = (): void => {
        const regexEmail = /\S+@\S+\.\S+/;
        if (!regexEmail.test(this.state.register.email)) return;
        this.setState({ verificandoEmail: true });
        HttpService.fetch(`api/Authentication/VerificarEmail`, {
            method: 'POST',
            body: JSON.stringify({ email: this.state.register.email })
        })
            .then((response) => response.json())
            .then((data: IEmailCheck) => {
                this.setState({ verificandoEmail: false });
                if (data) {
                    if (data.emailEstaCadastrado) {
                        this.setState({ emailCadastrado: true, register: { ...this.state.register, error: { ...this.state.register.error, Email: "Email já cadastrado" } } })
                    } else {
                        this.setState({ emailCadastrado: false, register: { ...this.state.register, error: { ...this.state.register.error, Email: undefined } } })
                    }

                    if (data.emailDemonstrouInteresse) {
                        this.setState({ modalDeEmailDemonstrouInteresseAberto: true });
                    }
                }
            });
    }

    checkCNPJ = (): void => {
        const cnpj = this.state.register.cliente_Documento.replace(/\D/g, '');
        if (cnpj.length !== 14) return;
        this.setState({ verificandoCNPJ: true });
        HttpService.fetch(`api/Authentication/VerificarCNPJ`, {
            method: 'POST',
            body: JSON.stringify({ CNPJ: this.state.register.cliente_Documento })
        })
            .then((response) => response.json())
            .then((data: ICNPJCheck) => {
                this.setState({ verificandoCNPJ: false });
                if (data && data.cnpjEstaCadastrado) {
                    this.setState({ register: { ...this.state.register, error: { ...this.state.register.error, Cliente_Documento: "CNPJ já cadastrado" } } })
                }
            });
    };

    preencherCamposComDadosDoEmailQueDemonstrouInteresse = (): void => {
        this.setState({ carregandoDadosDaPessoaInteressada: true });
        HttpService.fetch(`api/Authentication/ObterDadosDoEmailQueDemonstrouInteresse`, {
            method: 'POST',
            body: JSON.stringify({ email: this.state.register.email })
        })
            .then((response) => response.json())
            .then((data) => {
                this.setState({
                    modalDeEmailDemonstrouInteresseAberto: false,
                    carregandoDadosDaPessoaInteressada: false,
                    register: {
                        ...this.state.register,
                        nome: data.nome,
                        email: data.email,
                        cliente_Celular: data.celular,
                        cliente_Cargo: data.cargo,
                        cliente_Departamento: data.departamento,
                        cliente_Seguimento: data.segmento,
                        cliente_Tipoetamanho: data.tipoETamanho,
                        cliente_NomeFantasia: data.empresa,
                    }
                });
            });
    };

    componentDidUpdate(_prevProps: Readonly<RouteComponentProps<{}>>, prevState: Readonly<RegisterDataState>, _snapshot?: any): void {
        if (prevState.register.senha !== this.state.register.senha) {
            this.setState({
                estruturaDaSenha: {
                    oitoCaracteres: ValidadorDeSenha.possuiTamanhoMinimo(this.state.register.senha),
                    umaLetraMaiuscula: ValidadorDeSenha.possuiLetraMaiuscula(this.state.register.senha),
                    umaLetraMinuscula: ValidadorDeSenha.possuiLetraMinuscula(this.state.register.senha),
                    umNumero: ValidadorDeSenha.possuiNumero(this.state.register.senha),
                    umCaractereEspecial: ValidadorDeSenha.possuiCaractereEspecial(this.state.register.senha),
                }
            });
        }

        if (prevState.valorDoInputDeTokenDeConfirmacaoDeEmail !== this.state.valorDoInputDeTokenDeConfirmacaoDeEmail && this.state.valorDoInputDeTokenDeConfirmacaoDeEmail.length === 9) {
            this.confirmarEmail(this.state.valorDoInputDeTokenDeConfirmacaoDeEmail);
        }

        if (prevState.momentoDeCadastro === MomentoDeCadastro.ConfirmarEmail && this.state.momentoDeCadastro === MomentoDeCadastro.ConfirmarCelular) {
            this.solicitarCodigoDeConfirmacaoDeCelular();
        }

        if (prevState.tipoDeAuditor === TipoDeAuditor.Independente && this.state.tipoDeAuditor === TipoDeAuditor.Empresa && !this.state.possuiCNPJ){
            this.setState({ possuiCNPJ: true });
        }
    }

    private aRotaEstaListadaNoJson() {
        // verificar o que está escrito entre http:// ou https:// e .winset.com.br
        const url = window.location.href as string;
        const regex = /https?:\/\/([^.]*)/;
        const match = url.match(regex) ? url.match(regex)[1] : null;

        // transformar o json em array
        const empresasERotas = JSON.parse(JSON.stringify(EmpresasERotas)) as Array<any>;
        if (empresasERotas.length === 0) return false;
        return empresasERotas.some(empresa => match?.includes(empresa.nome));
    }

    private retornaRotasDasImagensDaEmpresa() {
        const outraEmpresa = this.aRotaEstaListadaNoJson();
        
        if (outraEmpresa) {
            const rotaAtual = window.location.href.match(/https?:\/\/([^.]*)/)[1];
            debugger;
            const empresasERotas = JSON.parse(JSON.stringify(EmpresasERotas)) as Array<any>;
            const empresa = empresasERotas.find(empresa => rotaAtual.includes(empresa.nome));
            this.setState({ imagensDinamicas: { logo: empresa.logo, icon: empresa.icon, whitelogo: empresa.whitelogo } });
        }
    }


    public render() {
        return (
            <div className="w-100 d-flex justify-content-center p-4">
                <HeadLayout title={this.context.getLanguageResource("register_signUp")} />
                <RegisterWrapper>
                    <div className="d-flex align-items-center">
                        <a href="/" className="mx-auto d-block"><img src={this.aRotaEstaListadaNoJson() ? `${process.env.PUBLIC_URL}${this.state.imagensDinamicas.logo}` : Logo} alt='Logo' style={{ width: '22rem' }} /></a>
                    </div>
                    <form onSubmit={this.handleRegister} ref={this.formularioRef}>
                        <section>
                            <input type="hidden" name="Cliente_CelularConfirmado" />
                            <input type="hidden" name="Cliente_EmailConfirmado" />
                            <input type="hidden" name="TermosAceitos" />
                            <input type="hidden" name="TipoDeAuditor" value={this.state.tipoDeAuditor === TipoDeAuditor.Empresa ? "Empresa" : "Independente"} />
                            {
                                !this.state.possuiCNPJ && (
                                    <React.Fragment>
                                        <input type="hidden" name="Cliente_NomeFantasia" value="Não se aplica" />
                                        <input type="hidden" name="Cliente_RazaoSocial" value="Não se aplica" />
                                    </React.Fragment>
                                )
                            }
                        </section>

                        <div style={{ height: '4rem' }} className='d-flex align-items-center justify-content-center w-100'>
                            <div className="d-flex flex-column align-items-center justify-content-center p-2">
                                <i className={`bi bi-1-circle-fill ${this.state.momentoDeCadastro >= MomentoDeCadastro.Usuario ? "text-success" : "text-secondary"}`} />
                                <h6 className={`text-center mt-2 ${this.state.momentoDeCadastro >= MomentoDeCadastro.Usuario ? "" : "text-muted"}`}><Resource keyString="user" /></h6>
                            </div>
                            <div className="d-flex flex-column align-items-center justify-content-center p-2">
                                <i className={`bi bi-2-circle-fill ${this.state.momentoDeCadastro >= MomentoDeCadastro.Empresa ? "text-success" : "text-secondary"}`} />
                                <h6 className={`text-center mt-2 ${this.state.momentoDeCadastro >= MomentoDeCadastro.Empresa ? "" : "text-muted"}`}><Resource keyString="company" /></h6>
                            </div>
                            <div className="d-flex flex-column align-items-center justify-content-center p-2">
                                <i className={`bi bi-3-circle-fill ${this.state.momentoDeCadastro >= MomentoDeCadastro.ConfirmarEmail ? "text-success" : "text-secondary"}`} />
                                <h6 className={`text-center mt-2 ${this.state.momentoDeCadastro >= MomentoDeCadastro.ConfirmarEmail ? "" : "text-muted"}`}><Resource keyString="confirm_email" /></h6>
                            </div>
                            <div className="d-flex flex-column align-items-center justify-content-center p-2">
                                <i className={`bi bi-4-circle-fill ${this.state.momentoDeCadastro >= MomentoDeCadastro.ConfirmarCelular ? "text-success" : "text-secondary"}`} />
                                <h6 className={`text-center mt-2 ${this.state.momentoDeCadastro >= MomentoDeCadastro.ConfirmarCelular ? "" : "text-muted"}`}><Resource keyString="confirm_cell_phone" /></h6>
                            </div>
                            <div className="d-flex flex-column align-items-center justify-content-center p-2">
                                <i className={`bi bi-5-circle-fill ${this.state.momentoDeCadastro >= MomentoDeCadastro.Finalizar ? "text-success" : "text-secondary"}`} />
                                <h6 className={`text-center mt-2 ${this.state.momentoDeCadastro >= MomentoDeCadastro.Finalizar ? "" : "text-muted"}`}><Resource keyString="confirm_data" /></h6>
                            </div>
                        </div>
                        <div className={`${this.state.momentoDeCadastro === MomentoDeCadastro.Usuario ? "d-flex" : "d-none"} flex-column border p-4 rounded shadow`}>
                            <h4 className="p-3 mt-3 rounded" style={{ background: 'var(--ws-blue)', color: this.aRotaEstaListadaNoJson() ? 'white' : 'black' }}>{<Resource keyString="user_data" />}</h4>
                            <hr />
                            <div className="form-group row">
                                <div className="col-md-6">
                                    <label className="required">{<Resource keyString="name" />}</label>
                                    <ValidationLabelMessage errors={this.state.register.error} name="Nome">
                                        <input className="form-control" type="text" name="Nome" placeholder={this.context.getLanguageResource("name")} onChange={this.handleTextChange} id="nome" value={this.state.register.nome} />
                                    </ValidationLabelMessage>
                                </div>
                                <div className="col-md-6">
                                    <label className="required">{<Resource keyString="email" />}</label>
                                        <input className={`form-control ${this.state.register.error && this.state.register.error["Email"] !== undefined ? "is-invalid" : ""}`} key="input-de-email" onBlur={this.checkEmail} type="email" name="Email" placeholder="email@email.com" onChange={this.handleTextChange} id="email" value={this.state.register.email} />
                                        {
                                            this.state.verificandoEmail && (
                                                <div className='w-100 p-0 m-0' style={{ height: '0px' }}>
                                                    <i className="fa fa-spinner fa-spin fa-fw" onClick={() => this.setState({ esconderConfirmarSenha: !this.state.esconderConfirmarSenha })} style={{ cursor: 'pointer', position: 'relative', fontSize: '1.4rem', left: 'calc(100% - 2rem)', top: '-1.85rem' }} />
                                                </div>
                                            )
                                        }
                                    <ValidationLabelMessage errors={this.state.register.error} name="Email" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-6">
                                    <label className="required">{<Resource keyString="password" />}</label>
                                    <ValidationLabelMessage errors={this.state.register.error} name="Senha">
                                        <input className="form-control" type={this.state.esconderSenha ? "password" : "text"} name="Senha" placeholder={this.context.getLanguageResource("password")} onChange={this.handleTextChange} id="senha" value={this.state.register.senha} />
                                        <div className='w-100 p-0 m-0' style={{ height: '0px' }}>
                                            <span className={this.state.esconderSenha ? "bi bi-eye" : "bi bi-eye-slash"} onClick={() => this.setState({ esconderSenha: !this.state.esconderSenha })} style={{ cursor: 'pointer', position: 'relative', fontSize: '1.4rem', left: 'calc(100% - 2rem)', top: '-1.85rem' }}></span>
                                        </div>
                                        <div className='mt-2 ml-1'>
                                            <p className='mb-0'>{this.state.estruturaDaSenha.oitoCaracteres ? <i className='bi bi-check-circle-fill mr-2 text-success' /> : <i className='bi bi-x-circle-fill mr-2 text-danger' />}<Resource keyString="minimum_8_characters" /></p>
                                            <p className='mb-0'>{this.state.estruturaDaSenha.umaLetraMaiuscula ? <i className='bi bi-check-circle-fill mr-2 text-success' /> : <i className='bi bi-x-circle-fill mr-2 text-danger' />}<Resource keyString="at_least_one_uppercase_letter" /></p>
                                            <p className='mb-0'>{this.state.estruturaDaSenha.umaLetraMinuscula ? <i className='bi bi-check-circle-fill mr-2 text-success' /> : <i className='bi bi-x-circle-fill mr-2 text-danger' />}<Resource keyString="at_least_one_lowercase_letter" /></p>
                                            <p className='mb-0'>{this.state.estruturaDaSenha.umNumero ? <i className='bi bi-check-circle-fill mr-2 text-success' /> : <i className='bi bi-x-circle-fill mr-2 text-danger' />}<Resource keyString="at_least_one_number" /></p>
                                            <p className='mb-0'>{this.state.estruturaDaSenha.umCaractereEspecial ? <i className='bi bi-check-circle-fill mr-2 text-success' /> : <i className='bi bi-x-circle-fill mr-2 text-danger' />}<Resource keyString="at_least_one_special_character" /></p>
                                        </div>
                                    </ValidationLabelMessage>
                                </div>
                                <div className="col-md-6">
                                    <label className="required">{<Resource keyString="confirm_password" />}</label>
                                    <ValidationLabelMessage errors={this.state.register.error} name="ConfirmarSenha">
                                        <input className="form-control" type={this.state.esconderConfirmarSenha ? "password" : "text"} name="ConfirmarSenha" id="confirmarSenha" placeholder={this.context.getLanguageResource("confirm_password")} onChange={this.handleTextChange} value={this.state.register.confirmarSenha} />
                                        <div className='w-100 p-0 m-0' style={{ height: '0px' }}>
                                            <span className={this.state.esconderConfirmarSenha ? "bi bi-eye" : "bi bi-eye-slash"} onClick={() => this.setState({ esconderConfirmarSenha: !this.state.esconderConfirmarSenha })} style={{ cursor: 'pointer', position: 'relative', fontSize: '1.4rem', left: 'calc(100% - 2rem)', top: '-1.85rem' }}></span>
                                        </div>
                                        <div className='w-100' style={{ height: "2rem" }}>
                                            {
                                                this.state.register.senha !== this.state.register.confirmarSenha && (
                                                    <p className='mb-0 text-danger'>As senhas devem coincidir</p>
                                                )
                                            }
                                        </div>
                                    </ValidationLabelMessage>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end">
                                <button
                                    className='btn ws-outline-blue'
                                    type='button'
                                    disabled={!this.momentoUsuarioEstaDevidamentePreenchido()}
                                    onClick={() => {
                                        if (this.momentoUsuarioEstaDevidamentePreenchido()) {
                                            this.setState({ momentoDeCadastro: MomentoDeCadastro.Empresa });
                                        }
                                    }}
                                >
                                    <Resource keyString="next" />
                                </button>
                            </div>
                        </div>
                        <div className={`${this.state.momentoDeCadastro === MomentoDeCadastro.Empresa ? "d-flex" : "d-none"} flex-column border p-4 rounded shadow`}>
                            <h4 className="p-3 mt-3 rounded" style={{ background: 'var(--ws-blue)', color: this.aRotaEstaListadaNoJson() ? 'white' : 'black' }}>{<Resource keyString="company_data" />}</h4>
                            <div className="form-group row">
                                <div className='col-12 d-flex justify-content-center p-3'>
                                    <select className="form-control" name="TipoDeAuditor"  value={this.state.tipoDeAuditor} onChange={(event) => {this.setState({ tipoDeAuditor: event.target.value === TipoDeAuditor.Empresa.toString() ? TipoDeAuditor.Empresa : TipoDeAuditor.Independente })} }>
                                        <option value={TipoDeAuditor.Independente.toString()}>{this.context.getLanguageResource("i_am_an_entrepreneur")}</option>
                                        <option value={TipoDeAuditor.Empresa.toString()}>{this.context.getLanguageResource("i_am_a_company")}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-6">
                                    <label className="required">{this.state.possuiCNPJ ? <Resource keyString="register_ein" /> : "CPF"}</label>
                                    <ValidationLabelMessage errors={this.state.register.error} name="Cliente_Documento">
                                        <div className='d-flex flex-column'>
                                            <div className='d-flex'>
                                                <Select
                                                    styles={{ input: (provided) => ({ ...provided, width: '3rem', textAlign: 'center' })}}
                                                    options={[
                                                        { value: 'brazil', label: '🇧🇷' },
                                                        { value: 'world', label: '🌎' },
                                                    ]}
                                                    defaultValue={{ value: 'brazil', label: '🇧🇷' }}
                                                    onChange={this.handleSelectCNPJCountryChange}
                                                />
                                                <input className="form-control" type="text" style={{ height: '2.75rem' }} name="Cliente_Documento" placeholder={this.state.possuiCNPJ ?this.context.getLanguageResource("register_enterEin") : "CPF"} onBlur={this.checkCNPJ} onChange={this.handleDocumentoChange} value={this.state.register.cliente_Documento} />
                                            </div>
                                            {   this.state.tipoDeAuditor === TipoDeAuditor.Independente && (
                                                    <div className='mt-1 flex align-items-center'>
                                                        <input className='mr-2' type='checkbox' checked={this.state.possuiCNPJ} onChange={(e) => this.setState({ ...this.state, possuiCNPJ: !this.state.possuiCNPJ })} name="PessoaJuridica" id="PessoaJuridica" />
                                                        <label htmlFor="PessoaJuridica">Pessoa jurídica</label>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        {
                                            this.state.verificandoCNPJ && (
                                                <div className='w-100 p-0 m-0' style={{ height: '0px' }}>
                                                    <i className="fa fa-spinner fa-spin fa-fw" onClick={() => this.setState({ esconderConfirmarSenha: !this.state.esconderConfirmarSenha })} style={{ cursor: 'pointer', position: 'relative', fontSize: '1.4rem', left: 'calc(100% - 2rem)', top: '-1.85rem' }} />
                                                </div>
                                            )
                                        }
                                    </ValidationLabelMessage>
                                </div>
                                {
                                    (this.state.tipoDeAuditor === TipoDeAuditor.Empresa || this.state.possuiCNPJ) && (
                                        <div className="col-md-6">
                                            <label className="required">{<Resource keyString="fantasy_name" />}</label>
                                            <ValidationLabelMessage errors={this.state.register.error} name="Cliente_NomeFantasia">
                                                <input className="form-control" type="text" name="Cliente_NomeFantasia" placeholder={this.context.getLanguageResource("fantasy_name")} onChange={this.handleTextChange} id="cliente_NomeFantasia" value={this.state.register.cliente_NomeFantasia} />
                                            </ValidationLabelMessage>
                                        </div>
                                    )
                                }
                            </div>
                            {
                                (this.state.tipoDeAuditor === TipoDeAuditor.Empresa || this.state.possuiCNPJ) && (
                                    <div className="form-group">
                                        <label className="required">{<Resource keyString="business_name" />}</label>
                                        <ValidationLabelMessage errors={this.state.register.error} name="Cliente_RazaoSocial">
                                            <input className="form-control" type="text" name="Cliente_RazaoSocial" placeholder={this.context.getLanguageResource("business_name")} onChange={this.handleTextChange} id="cliente_RazaoSocial" value={this.state.register.cliente_RazaoSocial} />
                                        </ValidationLabelMessage>
                                    </div>
                                )
                            }
                            {
                                this.state.tipoDeAuditor === TipoDeAuditor.Empresa && (
                                    <React.Fragment>
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label className="control-label" htmlFor="Cliente_Cargo"><Resource keyString="job_position" /></label>
                                                <select className="form-control" name="Cliente_Cargo" id="cliente_Cargo" onChange={this.handleSelectChange} value={this.state.register.cliente_Cargo}>
                                                    <option value="">{this.context.getLanguageResource("select")}</option>
                                                    <option value="CEO ou Presidente">{this.context.getLanguageResource("ceo_or_president")}</option>
                                                    <option value="Vice-Presidente">{this.context.getLanguageResource("vice_president")}</option>
                                                    <option value="Head">{this.context.getLanguageResource("head")}</option>
                                                    <option value="Diretor">{this.context.getLanguageResource("director")}</option>
                                                    <option value="Gerente Geral">{this.context.getLanguageResource("general_manager")}</option>
                                                    <option value="Gerente Nacional">{this.context.getLanguageResource("national_manager")}</option>
                                                    <option value="Gerente Regional">{this.context.getLanguageResource("regional_manager")}</option>
                                                    <option value="Coordenador">{this.context.getLanguageResource("coordinator")}</option>
                                                    <option value="Supervisor">{this.context.getLanguageResource("supervisor")}</option>
                                                    <option value="Especialista">{this.context.getLanguageResource("specialist")}</option>
                                                    <option value="Trainee">{this.context.getLanguageResource("trainee")}</option>
                                                    <option value="Analista">{this.context.getLanguageResource("analyst")}</option>
                                                    <option value="Promotor de Vendas">{this.context.getLanguageResource("sales_promoter")}</option>
                                                    <option value="Estagiário">{this.context.getLanguageResource("intern")}</option>
                                                    <option value="Assistente">{this.context.getLanguageResource("assistant")}</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="control-label" htmlFor="Cliente_Tipoetamanho"><Resource keyString="type_and_size" /></label>
                                                <select className="form-control" name="Cliente_Tipoetamanho" id="cliente_Tipoetamanho" onChange={this.handleSelectChange} value={this.state.register.cliente_Tipoetamanho}>
                                                    <option value="">{this.context.getLanguageResource("select")}</option>
                                                    <option value="Microempresa">{this.context.getLanguageResource("micro_company")}</option>
                                                    <option value="Multinacional de grande porte">{this.context.getLanguageResource("large_multinational")}</option>
                                                    <option value="Multinacional de médio porte">{this.context.getLanguageResource("medium_sized_multinational")}</option>
                                                    <option value="Multinacional de pequeno porte">{this.context.getLanguageResource("small_sized_multinational")}</option>
                                                    <option value="Nacional de grande porte">{this.context.getLanguageResource("large_national")}</option>
                                                    <option value="Nacional de médio porte">{this.context.getLanguageResource("medium_sized_national")}</option>
                                                    <option value="Nacional de pequeno porte">{this.context.getLanguageResource("small_sized_national")}</option>
                                                    <option value="Start-up">{this.context.getLanguageResource("startup")}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label className="control-label" htmlFor="Cliente_Seguimento"><Resource keyString="company_segment" /></label>
                                                <select className="form-control" id="cliente_Seguimento" name="Cliente_Seguimento" onChange={this.handleSelectChange} value={this.state.register.cliente_Seguimento}>
                                                    <option value="">{this.context.getLanguageResource("select")}</option>
                                                    <option value="Advocacia">{this.context.getLanguageResource("law")}</option>
                                                    <option value="Agência de câmbio">{this.context.getLanguageResource("exchange_agency")}</option>
                                                    <option value="Agência de viagem">{this.context.getLanguageResource("travel_agency")}</option>
                                                    <option value="Agropecuários">{this.context.getLanguageResource("agropecuaries")}</option>
                                                    <option value="Área de saúde">{this.context.getLanguageResource("healthcare")}</option>
                                                    <option value="Artigos Esportivos">{this.context.getLanguageResource("sporting_goods")}</option>
                                                    <option value="Associações">{this.context.getLanguageResource("associations")}</option>
                                                    <option value="Atacarejo">{this.context.getLanguageResource("wholesale_retail")}</option>
                                                    <option value="Auditoria">{this.context.getLanguageResource("auditing")}</option>
                                                    <option value="Automóveis">{this.context.getLanguageResource("automobiles")}</option>
                                                    <option value="Bancos & Financeiras">{this.context.getLanguageResource("banks_financial")}</option>
                                                    <option value="Bens de Consumo">{this.context.getLanguageResource("consumer_goods")}</option>
                                                    <option value="Brinquedos">{this.context.getLanguageResource("toys")}</option>
                                                    <option value="Calçados">{this.context.getLanguageResource("footwear")}</option>
                                                    <option value="Cama, mesa e banho">{this.context.getLanguageResource("bed_bath_linen")}</option>
                                                    <option value="Caminhões">{this.context.getLanguageResource("trucks")}</option>
                                                    <option value="Construção Civil">{this.context.getLanguageResource("construction")}</option>
                                                    <option value="Consultoria">{this.context.getLanguageResource("consulting")}</option>
                                                    <option value="Corretora de Seguros">{this.context.getLanguageResource("insurance_brokerage")}</option>
                                                    <option value="Cosméticos">{this.context.getLanguageResource("cosmetics")}</option>
                                                    <option value="Decoração">{this.context.getLanguageResource("decoration")}</option>
                                                    <option value="Educação">{this.context.getLanguageResource("education")}</option>
                                                    <option value="Eletro-eletrônicos">{this.context.getLanguageResource("electro_electronics")}</option>
                                                    <option value="Energia">{this.context.getLanguageResource("energy")}</option>
                                                    <option value="Family Office">{this.context.getLanguageResource("family_office")}</option>
                                                    <option value="Farmácias">{this.context.getLanguageResource("pharmacies")}</option>
                                                    <option value="Gráfica">{this.context.getLanguageResource("printing")}</option>
                                                    <option value="Higiene e Limpeza">{this.context.getLanguageResource("hygiene_cleaning")}</option>
                                                    <option value="Hotelaria">{this.context.getLanguageResource("hospitality")}</option>
                                                    <option value="Imobiliária">{this.context.getLanguageResource("real_estate")}</option>
                                                    <option value="Máquinas & Equipamentos">{this.context.getLanguageResource("machinery_equipment")}</option>
                                                    <option value="Materiais Elétricos">{this.context.getLanguageResource("electrical_materials")}</option>
                                                    <option value="Médico-hospitalar">{this.context.getLanguageResource("medical_hospital")}</option>
                                                    <option value="Metalurgica">{this.context.getLanguageResource("metallurgy")}</option>
                                                    <option value="Minimercado ou mercearia">{this.context.getLanguageResource("minimarket_grocery")}</option>
                                                    <option value="Motos">{this.context.getLanguageResource("motorcycles")}</option>
                                                    <option value="Motos de Luxo">{this.context.getLanguageResource("luxury_motorcycles")}</option>
                                                    <option value="Móveis">{this.context.getLanguageResource("furniture")}</option>
                                                    <option value="Oficina Mecânica">{this.context.getLanguageResource("mechanical_workshop")}</option>
                                                    <option value="ônibus">{this.context.getLanguageResource("buses")}</option>
                                                    <option value="Operador de telecom">{this.context.getLanguageResource("telecom_operator")}</option>
                                                    <option value="Óticas">{this.context.getLanguageResource("optics")}</option>
                                                    <option value="Outros">{this.context.getLanguageResource("other")}</option>
                                                    <option value="Padarias">{this.context.getLanguageResource("bakery")}</option>
                                                    <option value="Papelaria&Presentes">{this.context.getLanguageResource("stationery_gifts")}</option>
                                                    <option value="Petcenter">{this.context.getLanguageResource("petcenter")}</option>
                                                    <option value="Petroquímica">{this.context.getLanguageResource("petrochemical")}</option>
                                                    <option value="Pneus">{this.context.getLanguageResource("tires")}</option>
                                                    <option value="Posto de Combustível">{this.context.getLanguageResource("fuel_station")}</option>
                                                    <option value="Química">{this.context.getLanguageResource("chemical")}</option>
                                                    <option value="Representante">{this.context.getLanguageResource("representative")}</option>
                                                    <option value="Restaurantes">{this.context.getLanguageResource("restaurants")}</option>
                                                    <option value="Roupas">{this.context.getLanguageResource("clothing")}</option>
                                                    <option value="Roupas de Luxo">{this.context.getLanguageResource("luxury_clothing")}</option>
                                                    <option value="Rural">{this.context.getLanguageResource("rural")}</option>
                                                    <option value="Seguradora">{this.context.getLanguageResource("insurance_company")}</option>
                                                    <option value="Software & T.I">{this.context.getLanguageResource("software_it")}</option>
                                                    <option value="Supermercados">{this.context.getLanguageResource("supermarkets")}</option>
                                                    <option value="T.I.">{this.context.getLanguageResource("it")}</option>
                                                    <option value="Telecom">{this.context.getLanguageResource("telecom")}</option>
                                                    <option value="Terceirização">{this.context.getLanguageResource("outsourcing")}</option>
                                                    <option value="Trader">{this.context.getLanguageResource("trader")}</option>
                                                    <option value="Transporte e Armazenagem">{this.context.getLanguageResource("transport_storage")}</option>
                                                    <option value="Utilidades Domésticas">{this.context.getLanguageResource("household_items")}</option>
                                                    <option value="Veterinário">{this.context.getLanguageResource("veterinary")}</option>
                                                    <option value="Viagem & Turismo">{this.context.getLanguageResource("travel_tourism")}</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="control-label" htmlFor="Cliente_Departamento"><Resource keyString="department" /></label>
                                                <select className="form-control" id="cliente_Departamento" name="Cliente_Departamento" onChange={this.handleSelectChange} value={this.state.register.cliente_Departamento}>
                                                    <option value="">{this.context.getLanguageResource("select")}</option>
                                                    <option value="Compras">{this.context.getLanguageResource("purchases")}</option>
                                                    <option value="Financeiro">{this.context.getLanguageResource("finance")}</option>
                                                    <option value="Logística">{this.context.getLanguageResource("logistics")}</option>
                                                    <option value="Marketing">{this.context.getLanguageResource("marketing")}</option>
                                                    <option value="Outros">{this.context.getLanguageResource("other")}</option>
                                                    <option value="Produção">{this.context.getLanguageResource("production")}</option>
                                                    <option value="Recursos Humanos">{this.context.getLanguageResource("human_resources")}</option>
                                                    <option value="Tecnologia da Informação">{this.context.getLanguageResource("information_technology")}</option>
                                                    <option value="Vendas">{this.context.getLanguageResource("sales")}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            }

                            <div className="form-group row" >
                                <div className="col-md-6">
                                    <label className="control-label" htmlFor="Cliente_Telefone" >{<Resource keyString="register_phone" />}</label>
                                    <PhoneInput
                                        country={'br'}
                                        value={this.state.register.cliente_Telefone}
                                        onChange={(phone) => this.setState({ register: { ...this.state.register, cliente_Telefone: phone } })}
                                        preferredCountries={['br', 'us', 'es']}
                                        inputClass='w-100'
                                    />
                                    <input type="hidden" name="Cliente_Telefone" value={this.state.register.cliente_Telefone} />
                                </div>
                                <div className="col-md-6">
                                    <label className="control-label" htmlFor="Cliente_Celular" >{<Resource keyString="mobile_phone" />}</label>
                                    <PhoneInput
                                        country={'br'}
                                        value={this.state.register.cliente_Celular}
                                        onChange={(phone) => this.setState({ register: { ...this.state.register, cliente_Celular: phone } })}
                                        preferredCountries={['br', 'us', 'es']}
                                        inputClass='w-100'
                                    />
                                    <input type="hidden" name="Cliente_Celular" value={this.state.register.cliente_Celular} />
                                </div>
                            </div>

                            <div className="d-flex justify-content-between">
                                <button className='btn ws-outline-blue' type='button' onClick={() => this.setState({ momentoDeCadastro: MomentoDeCadastro.Usuario })}><Resource keyString="back" /></button>
                                <button
                                    className='btn ws-outline-blue'
                                    type='button'
                                    onClick={() => {
                                        if (this.momentoEmpresaEstaDevidamentePreenchido()) {
                                            this.solicitarCodigoDeConfirmacaoDeEmail();
                                            this.setState({ momentoDeCadastro: MomentoDeCadastro.ConfirmarEmail });
                                        }
                                    }}
                                    disabled={!this.momentoEmpresaEstaDevidamentePreenchido()}
                                >
                                    <Resource keyString="next" />
                                </button>
                            </div>
                        </div>

                        <div className={`${this.state.momentoDeCadastro === MomentoDeCadastro.ConfirmarEmail ? "d-flex" : "d-none"} flex-column border p-4 rounded shadow`}>
                            <h4 className="p-3 mt-3 rounded" style={{ background: 'var(--ws-blue)', color: this.aRotaEstaListadaNoJson() ? 'white' : 'black' }}>{<Resource keyString="user_data" />}</h4>
                            <hr />
                            <div className="d-flex w-100 flex-column align-items-center">
                                <p className='mb-0'><Resource keyString="confirmation_code_sent_to_email" />:</p>
                                <p className='mb-0'><b>{this.state.register.email}</b></p>
                                <p className='mb-0'><Resource keyString="if_not_received_click_button_below_to_resend" /></p>
                                <button className='btn ws-outline-blue my-4' type='button' onClick={() => { this.solicitarCodigoDeConfirmacaoDeEmail() }}><Resource keyString="resend_code" /></button>
                                <input className='form-control text-center w-50' placeholder='Ex.: LS3J-907P' onChange={this.handleInputConfirmarEmailChange} value={this.state.valorDoInputDeTokenDeConfirmacaoDeEmail} type='text' />
                                <div className='d-flex w-100 align-items-center justify-content-center' style={{ height: '3rem' }}>
                                    
                                    {
                                        this.state.momentoDeConfirmacaoDeEmail === MomentoDeConfirmacaoDeEmail.EmAndamento && (
                                            <i className="fa fa-spinner fa-spin"></i>
                                        )
                                    }
                                    {
                                        this.state.momentoDeConfirmacaoDeEmail === MomentoDeConfirmacaoDeEmail.Sucesso && (
                                            <i className="fa fa-check-circle text-success"></i>
                                        )
                                    }
                                    {
                                        this.state.momentoDeConfirmacaoDeEmail === MomentoDeConfirmacaoDeEmail.Falha && (
                                            <i className="fa fa-times-circle text-danger"></i>
                                        )
                                    }
                                </div>
                            </div>

                            <div className="d-flex justify-content-between">
                                <button className='btn ws-outline-blue' type='button' onClick={() => this.setState({ momentoDeCadastro: MomentoDeCadastro.Empresa })}><Resource keyString="back" /></button>
                                {
                                    this.state.momentoDeConfirmacaoDeEmail === MomentoDeConfirmacaoDeEmail.Sucesso && (
                                        <button className='btn ws-outline-blue' type='button' onClick={() => this.setState({ momentoDeCadastro: MomentoDeCadastro.ConfirmarCelular })}><Resource keyString="next" /></button>
                                    )
                                }
                            </div>
                        </div>

                        <div className={`${this.state.momentoDeCadastro === MomentoDeCadastro.ConfirmarCelular ? "d-flex" : "d-none"} flex-column border p-4 rounded shadow`}>
                            <h4 className="p-3 mt-3 rounded" style={{ background: 'var(--ws-blue)', color: this.aRotaEstaListadaNoJson() ? 'white' : 'black' }}><Resource keyString="user_data" /></h4>
                            <hr />
                            <div className="d-flex w-100 flex-column align-items-center">
                                {
                                    this.state.momentoDaTelaDeConfirmacaoDeCelular === MomentoDaTelaDeConfirmacaoDeCelular.SolicitarCodigo && (
                                        <React.Fragment>
                                            <div className='w-75 text-center'>
                                                <p className='my-2'><Resource keyString="confirm_cell_number_if_correct_click_button_below_to_send_sms_with_confirmation_code" /></p>
                                                <p className='my-2'><b>{this.state.register.cliente_Celular}</b></p>
                                                <p className='my-2'><Resource keyString="you_can_skip_this_step" />.</p>
                                            </div>
                                            <button className='btn ws-outline-blue' type='button' onClick={() => this.solicitarCodigoDeConfirmacaoDeCelular()}><Resource keyString="send_code" /></button>
                                        </React.Fragment>
                                    )
                                }
                                {
                                    this.state.momentoDaTelaDeConfirmacaoDeCelular === MomentoDaTelaDeConfirmacaoDeCelular.CodigoSolicitado && (
                                        <React.Fragment>
                                            <p className='mb-0'><Resource keyString="confirmation_code_sent_to_number" />:</p>
                                            <p className='mb-0'><b>{this.state.register.cliente_Celular}</b></p>
                                            <p className='mb-0'><Resource keyString="if_not_received_click_button_below_to_resend" /></p>
                                            <button className='btn ws-outline-blue my-4' type='button' onClick={() => this.solicitarCodigoDeConfirmacaoDeCelular()}><Resource keyString="resend_code" /></button>
                                            <input className='form-control text-center w-50' onChange={this.handleChangeInputConfirmacaoDeCelular} value={this.state.valorDoInputDeTokenDeConfirmacaoDeCelular} placeholder='Ex.: 290-796' type='text' />
                                            <div className='d-flex w-100 align-items-center justify-content-center' style={{ height: '3rem' }}>
                                                {this.state.momentoDeConfirmacaoDeCelular === MomentoDeConfirmacaoDeCelular.EmAndamento && (
                                                    <i className="fa fa-spinner fa-spin"></i>
                                                )}
                                                {this.state.momentoDeConfirmacaoDeCelular === MomentoDeConfirmacaoDeCelular.Sucesso && (
                                                    <i className="fa fa-check-circle text-success"></i>
                                                )}
                                                {this.state.momentoDeConfirmacaoDeCelular === MomentoDeConfirmacaoDeCelular.Falha && (
                                                    <i className="fa fa-times-circle text-danger"></i>
                                                )}
                                            </div>
                                        </React.Fragment>
                                    )
                                }
                                {
                                    this.state.momentoDaTelaDeConfirmacaoDeCelular === MomentoDaTelaDeConfirmacaoDeCelular.FalhaAoSolicitarCodigo && (
                                        <React.Fragment>
                                            <i className="bi bi-x-circle-fill text-danger mb-3" style={{ fontSize: '2rem' }}></i>
                                            <p className='mb-0'><Resource keyString="unable_to_send_code_to_number" />:</p>
                                            <p className='mb-0'><b>{this.state.register.cliente_Celular}</b></p>
                                            <p className='mb-0'><Resource keyString="click_button_below_to_retry_or_skip_step" />.</p>
                                            <button className='btn ws-outline-blue my-4' type='button' onClick={() => this.setState({ momentoDaTelaDeConfirmacaoDeCelular: MomentoDaTelaDeConfirmacaoDeCelular.SolicitarCodigo })}><Resource keyString="try_again" /></button>
                                            <div className='d-flex w-100 align-items-center justify-content-center' style={{ height: '3rem' }}></div>
                                        </React.Fragment>
                                    )
                                }
                            </div>
                            <div className="d-flex justify-content-between">
                                <button className='btn ws-outline-blue' type='button' onClick={() => this.setState({ momentoDeCadastro: MomentoDeCadastro.ConfirmarEmail })}><Resource keyString="back" /></button>
                                {
                                    this.state.momentoDeConfirmacaoDeCelular !== MomentoDeConfirmacaoDeCelular.Sucesso
                                        ?   <button className='btn ws-outline-blue' type='button' onClick={() => this.setState({ momentoDeCadastro: MomentoDeCadastro.Finalizar })}><Resource keyString="skip_step" /></button>
                                        :   <button className='btn ws-outline-blue' type='button' onClick={() => this.setState({ momentoDeCadastro: MomentoDeCadastro.Finalizar })}><Resource keyString="next" /></button>
                                }
                            </div>
                        </div>


                        <div className={`${this.state.momentoDeCadastro === MomentoDeCadastro.Finalizar ? "d-flex" : "d-none"} flex-column border p-4 rounded shadow`}>
                            <h4 className="p-3 mt-3 rounded" style={{ background: 'var(--ws-blue)', color: this.aRotaEstaListadaNoJson() ? 'white' : 'black' }}>{<Resource keyString="user_data" />}</h4>
                            <hr />
                            <div className='d-flex justify-content-center my-2'>
                                <h5 className='mb-0'><Resource keyString="confirm_filled_data_before_finalizing" /></h5>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                                <div className='border rounded p-3 w-75 my-1'>
                                    <div className='d-flex'>
                                    <i className="bi bi-person-circle mr-2"></i>
                                    <p className='lead'><Resource keyString="user" /></p>
                                    </div>
                                    <p className='mb-0'><b><Resource keyString="name" />:</b> {this.state.register.nome}</p>
                                    <p className='mb-0'><b><Resource keyString="email" />:</b> {this.state.register.email}</p>
                                </div>
                                <div className='border rounded p-3 w-75 my-1'>
                                    {
                                        this.state.possuiCNPJ && (
                                            <div className='d-flex'>
                                                <i className="bi bi-building mr-2"></i>
                                                <p className='lead'><Resource keyString="company" /></p>
                                            </div>
                                        )
                                    }
                                    {
                                        this.state.possuiCNPJ && (
                                            <p className='mb-0'><b><Resource keyString="name" />:</b> {this.state.register.cliente_NomeFantasia}</p>
                                        )
                                    }
                                    <p className='mb-0'><b>{this.state.possuiCNPJ ? "CNPJ" : "CPF"}:</b> {this.state.register.cliente_Documento}</p>
                                    {
                                        this.state.possuiCNPJ && (
                                            <React.Fragment>
                                                <p className='mb-0'><b><Resource keyString="job_title" />:</b> {this.state.register.cliente_Cargo}</p>
                                                <p className='mb-0'><b><Resource keyString="department" />:</b> {this.state.register.cliente_Departamento}</p>
                                                <p className='mb-0'><b><Resource keyString="business_name" />:</b> {this.state.register.cliente_RazaoSocial}</p>
                                                <p className='mb-0'><b><Resource keyString="type_and_size" />:</b> {this.state.register.cliente_Tipoetamanho}</p>
                                                <p className='mb-0'><b><Resource keyString="segment" />:</b> {this.state.register.cliente_Seguimento}</p>
                                            </React.Fragment>
                                        )
                                    }
                                    <p className='mb-0'><b><Resource keyString="phone" />:</b> {this.state.register.cliente_Telefone}</p>
                                    <p className='mb-0'><b><Resource keyString="mobile_phone" />:</b> {this.state.register.cliente_Celular}</p>
                                </div>
                            </div>

                            <div className='form-group d-flex justify-content-center my-3'>
                                <input type="checkbox" name="aggree" ref="aceitarPoliticasETermos" id="aceptTerms" onChange={this.handleAceitarPoliticasETermos} />
                                <label className='mb-0 ml-2' htmlFor="aceptTerms" >
                                    <Resource keyString="i_have_read_and_accept_the" /> <a href='#' onClick={() => this.setState({ modalAberto: true })}><Resource keyString="terms_of_use" /></a>
                                </label>
                            </div>
                            <ValidationAlertMessage errors={this.state.register.error} name="Summary" />

                            <div className="d-flex justify-content-between">
                                <button className='btn ws-outline-blue' type='button' onClick={() => this.setState({ momentoDeCadastro: MomentoDeCadastro.ConfirmarCelular })}><Resource keyString="back" /></button>
                                <button
                                    className="btn ws-outline-blue"
                                    disabled={this.state.register.realizarCadastro != true}
                                    style={{ cursor: (!this.state.register.realizarCadastro) ? 'not-allowed' : 'pointer' }}
                                    ref="realizarCadastro"
                                    type="submit"
                                >
                                    <Resource keyString="create_account" />
                                </button>
                            </div>

                        </div>
                    </form>
                    <div className="w-100 text-center">
                        <p className='mb-0'><Resource keyString="already_have_an_account" /></p>
                        <NavLink className="mb-0" style={{ fontSize: ".8rem" }} to={'/login'} exact activeClassName='active'>
                            {<Resource keyString="sign_in_now" />}
                        </NavLink>
                    </div>
                </RegisterWrapper>
                {
                    this.state.modalAberto && (
                        <ModalWinSet
                            fecharModal={() => this.setState({ modalAberto: false })}
                        >
                            <div className="d-flex flex-column p-4" style={{ maxWidth: '40rem', minWidth: '30rem', maxHeight: '80vh' }}>
                                <section className="m-2 p-4 border rounded barra_de_rolagem_estilizada" style={{ overflowY: 'auto' }}>
                                    <TextoDeTermosDeAceite />
                                </section>
                                <section className='w-100 d-flex justify-content-center mb-2'>
                                    <button className='btn ws-outline-blue' onClick={() => this.setState({ modalAberto: false })}><Resource keyString="close" /></button>
                                </section>
                            </div>
                        </ModalWinSet>
                    )
                }
                {
                    this.state.modalDeEmailDemonstrouInteresseAberto && (
                        <ModalWinSet
                            fecharModal={() => this.setState({ modalDeEmailDemonstrouInteresseAberto: false })}
                        >
                            <div className="d-flex flex-column p-4" style={{ maxWidth: '40rem', minWidth: '30rem', maxHeight: '80vh' }}>
                                <p><Resource keyString='this_email_has_already_contacted_our_team' />. <Resource keyString="do_you_want_to_automatically_fill_in_the_fields_with_the_information_you_provided" /></p>
                                <div className='d-flex justify-content-center' style={{ height: '1.5rem' }}>
                                {
                                    this.state.carregandoDadosDaPessoaInteressada && (
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    )
                                }
                                </div>
                                <section className='w-100 d-flex justify-content-center mb-2'>
                                    {
                                        this.state.carregandoDadosDaPessoaInteressada === false && (
                                            <React.Fragment>
                                                <button className='btn mx-2 ws-outline-blue' onClick={() => this.setState({ modalDeEmailDemonstrouInteresseAberto: false })}><Resource keyString="no" /></button>
                                                <button className='btn mx-2 ws-outline-blue' onClick={() => this.preencherCamposComDadosDoEmailQueDemonstrouInteresse()}><Resource keyString="yes" /></button>
                                            </React.Fragment>
                                        )
                                    }
                                </section>
                            </div>
                        </ModalWinSet>
                    )
                }
            </div>
        )
    }
}
