import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { HttpService } from '../../Shared/HttpService';
import { Loading } from '../../Shared/Loading';
import { Resource } from '../../../index';
import { FormularioIniciadoData } from './RealizarFormulario';
import { FormularioData } from './FetchFormulario';
import { PerguntaXAnotacaoData } from './ResponderFormulario';
import { PlanoAcaoData } from '../PlanoAcao/FetchPlanoAcao';
import { DocumentoData } from '../../Documentos/FetchDocumentos';
import AgrupamentoDeBotoesSuperiores from './components/AgrupamentoDeBotoesSuperiores';
import ConteudoDeResultadoComFormula from './components/ConteudoDeResultadoComFormula';
import ConteudoDeResultadoPadrao from './components/ConteudoDeResultadoPadrao';
import { LanguageContext } from '../../../context/LanguageContext';
import CaixaDeConteudo from '../../Shared/CaixaDeConteudo';
import { FormularioRespondidoProvider } from '../../../context/FormularioRespondidoContext';

interface FormularioRespondidoDuplicadoState {
    resultadoFormulario: ResultadoFormularioAgrupado;
    hash: string;
}

class ResultadoFormularioAgrupado {
    grupopergunta: Array<GrupoPergunta> = new Array<GrupoPergunta>();
    formularioIniciado: FormularioIniciadoData = new FormularioIniciadoData;
    formulario: FormularioData = new FormularioData;
    resultadoFormulario: Resultado = new Resultado();
    resultadoXCabecalho: ResultadoXCabecalho = new ResultadoXCabecalho();
    perguntaXAnotacao: PerguntaXAnotacaoData[] = [];
    resultadoXArquivos: ResultadoXArquivo[] = [];
    resultadoPlanoAcao: PlanoAcaoData[] = [];
    documentos: DocumentoData[] = [];
    resultadoGrupoPergunta: Resultado[] = [];
}

class GrupoPergunta {
    empresasid: number = 0;
    formularioid: number = 0;
    grupoperguntapaiid: number = 0;
    nome: string = "";
    hash: string = "";
    tolerancia: number = 0;
    isactive: boolean = true;
}

class Resultado {
    id: number = 0;
    situacao: boolean = false;
    situacaopontos: boolean = false;
    valor: number = 0;
    toleranciapontos: number = 0;
    toleranciaporcentagem: number = 0;
    tolerancia: number = 0;
    valorporcentagem: number = 0;
    pontuacaototal: number = 0;
    hash: string = "";
    datecreated: string = "";
    notaPelaFormulaResultado: string = "";
    consolidadoResposta: ConsolidadoResposta[] = [];
}

class ConsolidadoResposta {
    quantidade: number = 0;
    peso: number = 0;
    nome: string = "";
}

export class ResultadoXCabecalho {
    id: number = 0;
    nome: string = "";
    valor: string = "";
    cabecalhoid: number = 0;
}

export class ResultadoXArquivo {
    id: number = 0;
    perguntaid: number = 0;
    caminhovirtual: string = "";
    setorid: number = 0;
}

export class FormularioRespondido extends React.Component<RouteComponentProps<{}>, FormularioRespondidoDuplicadoState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        var hash = this.props.match.params["formularioid"];

        this.state = {
            resultadoFormulario: new ResultadoFormularioAgrupado(),
            hash: hash
        }

        Loading.showLoading(this);
        HttpService.fetch(`api/ResultadoApi/FormularioRespondido/${hash}`, {})
            .then(response => response.json() as Promise<ResultadoFormularioAgrupado>)
            .then(data => {
                this.setState({
                    resultadoFormulario: data
                });
                Loading.hideLoading(this);
            }).catch((e) => {
                Loading.hideLoading(this);
                props.history.push(`/marketPlace`);
            });

        this.handleDownload = this.handleDownload.bind(this);
    }

    public handleDownload(arquivo: any) {
        if (arquivo != null) {
            window.location.href = `${window.location.protocol}//${window.location.host}/api/ArquivosApi/Download/${arquivo.id}/${arquivo.empresasid}`;
        }
    }

    public handleModalPlanoAcao: any = {};

    public botoesDoCantoSuperiorDireito() {
        return (
            <button className="btn bg-white mx-2 shadow" onClick={() => this.props.history.goBack()}>
                <Resource keyString="back" />
            </button>
        );
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;

        return (
            <CaixaDeConteudo
                tituloDoHeader={this.context.getLanguageResource("answered_form")}
                botoes={this.botoesDoCantoSuperiorDireito()}
                itensParaBreadcrumb={[
                    { nome: this.context.getLanguageResource("home"), url: "/" },
                    { nome: this.context.getLanguageResource("forms"), url: `/marketPlace/detail/${this.state.resultadoFormulario.formulario.id}` },
                    { nome: this.context.getLanguageResource("answered_form") }
                ]}
                    >
                <FormularioRespondidoProvider>
                    <div className='p-4'>
                        <div className="titulo-da-pagina">
                        <h3 className="text-center title-2">
                            {
                                    this.state.resultadoFormulario.formularioIniciado.rascunho
                                        ? <Resource keyString="partially_answered_form" />
                                        : <Resource keyString="answered_form" />
                            }
                        </h3>
                    </div>

                    <div className="accordion" id="accordion">
                        <AgrupamentoDeBotoesSuperiores
                            resultadoDoFormulario={this.state.resultadoFormulario}
                            hash={this.state.hash}
                            modalDePlanoDeAcao={this.handleModalPlanoAcao.handleRender}
                            classeAlvoModal={this}
                        />

                            {
                                this.state.resultadoFormulario.formulario.showFormularioResultadoComFormula
                                    ? <ConteudoDeResultadoComFormula resultadoDoFormulario={this.state.resultadoFormulario} />
                                    : <ConteudoDeResultadoPadrao resultadoDoFormulario={this.state.resultadoFormulario} />
                            }
                    </div>
                </div>
                </FormularioRespondidoProvider>
            </CaixaDeConteudo>
        );
    }
}   