import * as React from 'react';
import { Resource } from '../../../../..';
import { LanguageContext } from '../../../../../context/LanguageContext';
import AnotacaoInfo from '../AnotacaoInfo';
import DocumentoComponente from '../DocumentoComponente';
import { PlanoAcaoData } from '../../../PlanoAcao/FetchPlanoAcao';
import PlanoAcaoFormulario from '../PlanoAcaoFormulario';
import { CardDeImagemAnexada } from '../CardDeImagemAnexada';

interface IResultadoFormulario {
    resultadoPlanoAcao: PlanoAcaoData[];
    resultadoXArquivos: any;
    perguntaXAnotacao: any;
}

interface IObjProp {
    titulo: string;
    ko: any;
    grupoperguntaid: any;
    valor: any;
    resposta: string;
    usuarioNome: string;
    observacao: string;
    descricao: string;
    setorid: any;
    perguntaid: any;
    documentos: any;
}

interface IProps {
    obj: IObjProp;
    resultadoFormulario: IResultadoFormulario;
}

export default class CardDeResultado extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props);

        this.handleDownload = this.handleDownload.bind(this);
        this.renderImagens = this.renderImagens.bind(this);
    }

    handleDownload(arquivo: any) {
        const urlRequest = `${window.location.protocol}//${process.env.REACT_APP_HOST_API}/api/ArquivosApi/Download/${arquivo.id}/${arquivo.empresasid}`;
        if (arquivo != null) {
            window.open(urlRequest, '_blank');
        }
    }

    renderImagens(perguntaId, setorId) {
        const { resultadoFormulario } = this.props;
        let resultadoXArquivo = resultadoFormulario.resultadoXArquivos.map((resultado, index) => {
            if (resultado.perguntaid == perguntaId &&
                ((setorId > 0 && resultado.setorid == setorId)
                    || (!(setorId > 0) && !(resultado.setorid > 0)))) {
                return (
                    <React.Fragment>
                        <CardDeImagemAnexada
                            urlDaImagem={`${window.location.protocol}//${process.env.REACT_APP_HOST_API}/api/ResultadoXArquivosApi/DownloadFile/${resultado.caminhovirtual}`}
                        />
                        <div className='d-none _IMPRIMIR_MOSTRAR_ p-1' style={{ width: '33.33%' }}>
                            <img
                                width='100%'
                                alt='Imagem anexada'
                                src={`${window.location.protocol}//${process.env.REACT_APP_HOST_API}/api/ResultadoXArquivosApi/DownloadFile/${resultado.caminhovirtual}`}
                            />
                        </div>
                    </React.Fragment>
                )

            }
        });
        return resultadoXArquivo;
    }

    render() {
        const { obj, resultadoFormulario } = this.props;

        return (
            <div
                className={`w-100 p-3 border rounded mt-1 _IMPRIMIR_BREAK_INSIDE_AVOID_`}
                key={obj.grupoperguntaid}
            >
                <h5>{obj.titulo} {(obj.ko) ? <span className="text-danger font-weight-bold px-3">KO</span> : ""}</h5>
                <p className='w-100 border-bottom' />
                <p style={{ fontSize: '0.75rem' }} className='w-100'>{obj.descricao}</p>

                <section>
                    <p>
                        <strong><Resource keyString="response_value" />:</strong>
                        {
                            obj.resposta
                                ?   (
                                        <React.Fragment>
                                            <span style={{ fontWeight: '100', backgroundColor: 'var(--ws-blue-light)' }} className="ml-2 px-2 py-1 rounded shadow-sm">{obj.resposta}</span>
                                            {
                                                obj.valor != null
                                                    ? <span style={{ fontWeight: '100', backgroundColor: 'var(--ws-blue-light)' }} className="ml-2 px-2 py-1 rounded shadow-sm">({obj.valor})</span>
                                                    : ''
                                            }
                                        </React.Fragment>
                                    )
                                :   <React.Fragment />
                        }
                    </p>
                    <p><strong><Resource keyString="answered_by" />:</strong> <span style={{ fontWeight: '100' }}>{obj.usuarioNome}</span></p>
                    {
                        obj.observacao
                            ?   <p><strong><Resource keyString="observation" />:</strong> <span style={{ fontWeight: '100' }}>{obj.observacao}</span></p>
                            :   <React.Fragment />
                    }
                </section>

                <p className={ resultadoFormulario.perguntaXAnotacao.filter(objetoDeAnotacao => objetoDeAnotacao.perguntaid == obj.perguntaid).length <= 0 ? 'd-none' : 'w-100 border-bottom' } />

                <section>
                    {
                        resultadoFormulario.perguntaXAnotacao.filter(objetoDeAnotacao => objetoDeAnotacao.perguntaid == obj.perguntaid).length > 0
                            ?   <label className='text-muted'><Resource keyString="annotation" />:</label>
                            :   <React.Fragment />
                    }
                    {
                        resultadoFormulario.perguntaXAnotacao
                            .filter(objetoDeAnotacao => objetoDeAnotacao.perguntaid == obj.perguntaid)
                            .map((anotacaoFiltrada) =>
                                <AnotacaoInfo
                                    nome={anotacaoFiltrada.usercreated.nome}
                                    createdDateTime={anotacaoFiltrada.createddatetime}
                                    anotacao={anotacaoFiltrada.anotacao}
                                />
                            )
                    }
                </section>

                <section>
                    {
                        obj.documentos.length > 0
                            ? obj.documentos.map((documento, index) => (
                                <DocumentoComponente
                                    key={index}
                                    documento={documento}
                                    handleDownload={this.handleDownload}
                                />)
                            )
                            : <React.Fragment />
                    }
                </section>

                <p className={resultadoFormulario.resultadoXArquivos.length <= 0 ? 'd-none' : 'w-100 border-bottom'} />

                <section className='d-flex flex-wrap w-100'>
                    {this.renderImagens(obj.perguntaid, obj.setorid)}
                </section>

                {
                    resultadoFormulario.resultadoPlanoAcao != null && resultadoFormulario.resultadoPlanoAcao.some(e => e.perguntaid == obj.perguntaid)
                        ? <PlanoAcaoFormulario
                            resultadoPlanoAcao={resultadoFormulario.resultadoPlanoAcao
                                .filter((o, i) => o.perguntaid == obj.perguntaid)}
                        />
                        : <React.Fragment />
                }
            </div>
        );
    }
}
