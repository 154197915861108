import * as React from 'react';

const ResultadoAgrupadoPorGrupo = ({ doc, indexDoGrupo }) => {
  const { resultadoAgrupadoPorGrupo } = doc;
  const { pontuacao, pontuacaoMaxima } = resultadoAgrupadoPorGrupo;
  const resultadoDoGrupoEmPorcentagem = ((100 * pontuacao) / pontuacaoMaxima).toFixed(2);
  
  const handleBackgroundColor = (index) => index % 2 === 0 ? '#efefef' : '#ffffff';
  return (
    <React.Fragment>
      <tr style={{ backgroundColor: handleBackgroundColor(indexDoGrupo) }}>
        <td className='border-0'>{ doc.nome }</td>
        <td className='border-0'>
          {
            pontuacao === null
              ? 'NA'
              : pontuacao
          }
        </td>
        <td className='border-0'>{ pontuacaoMaxima }</td>
        <td className='border-0'>
          {
              resultadoDoGrupoEmPorcentagem === 'NaN'
                ? 'NA'
                : `${resultadoDoGrupoEmPorcentagem}%`
          }
        </td>
      </tr>
    </React.Fragment>
  );
}

export default ResultadoAgrupadoPorGrupo;
