import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { Resource } from '../../../../../index';
import { LanguageContext } from "../../../../../context/LanguageContext";

export const Container = styled.div`
    width: 25rem;
`;

export const InputFile = ({ onChange, enviarValores }) => {
    const { getLanguageResource } = useContext(LanguageContext);
    const [arquivoSelecionado, setArquivoSelecionado] = useState<string>(getLanguageResource('no_file_selected'));
    const [nomeDoDocumento, setNomeDoDocumento] = useState<string>('');
    const [versaoDoDocumento, setVersaoDoDocumento] = useState<string>(getLanguageResource('first_version'));
    const [dataVencimento, setValidadeDoArquivo] = useState<string>('');
    const [tamanhoDeArquivoInvalido, setTamanhoDeArquivoInvalido] = useState<boolean>(false);

    const maxFileSize = 28 * 1024 * 1024;

    const handleSelectFile = (_event) => {
        const input = document.querySelector("input[type=file]") as HTMLInputElement;
        input.click();
    };

    const handleUploadFile = (event) => {
        const file = event.target.files[0];
        setValidadeDoArquivo('');
        setTamanhoDeArquivoInvalido(file.size > maxFileSize);
        setArquivoSelecionado(event.target.files && event.target.files?.length > 0 ? file.name : getLanguageResource('no_file_selected'))
        onChange(event.target.files && event.target.files?.length > 0 ? event.target.files : undefined);
        setNomeDoDocumento(event.target.files && event.target.files?.length > 0 ? file.name : '');
    };

    const handleChange = (event, setInputState) => {
        event.target.classList.remove('is-invalid');
        setInputState(event.target.value);
    };

    useEffect(() => {
        enviarValores({
            nomeDoDocumento,
            versaoDoDocumento,
            dataVencimento,
        })
    }, [arquivoSelecionado, nomeDoDocumento, versaoDoDocumento, dataVencimento])

    const handleIconFile = (file) => {
        const bootstrapIconsExtensions = ["aac", "ai", "bmp", "cs", "css", "csv", "doc", "docx", "exe", "gif", "heic", "html", "java", "jpg", "js", "json", "jsx", "key", "m4p", "md", "mdx", "mov", "mp3", "mp4", "otf", "pdf", "php", "png", "ppt", "pptx", "psd", "py", "raw", "rb", "sass", "scss", "sh", "sql", "svg", "tiff", "tsx", "ttf", "txt", "wav", "woff", "xls", "xlsx", "xml", "yml"];
        const fileExtension = file.slice(file.lastIndexOf('.') + 1) as string;

        if (bootstrapIconsExtensions.includes(fileExtension)) {
            return `bi bi-filetype-${fileExtension}`;
        }

        return 'bi bi-file-earmark';
    };

    const dateMin = (): string => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        const year = tomorrow.getFullYear();
        const month = tomorrow.getMonth() + 1;
        const day = tomorrow.getDate();

        return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
    }
        

    return (
        <Container>
            <div className="d-flex align-itens-center flex-column w-100">
                {
                    tamanhoDeArquivoInvalido && (
                        <React.Fragment>
                            <div className="d-flex flex-column align-items-center m-4">
                                <i className="bi bi-exclamation-octagon text-warning" style={{ fontSize: '2rem' }}></i>
                                <h6 className='text-truncate mt-2'>{getLanguageResource('size_limit_exceeded')}</h6>
                            </div>
                        </React.Fragment>
                    )
                }
                {
                    (arquivoSelecionado !== getLanguageResource('no_file_selected') && !tamanhoDeArquivoInvalido) && (
                        <React.Fragment>
                            <label className='text-truncate mb-0'>{getLanguageResource('document_name')}</label>
							<input type="text" name='nomeDoDocumento' id='nomeDoDocumento' className='form-control' onChange={(e) => handleChange(e, setNomeDoDocumento)} value={nomeDoDocumento} required />

                            <label className='text-truncate mb-0 mt-3'>{getLanguageResource('version')}</label>
							<input type="text" name='versaoDoDocumento' id='versaoDoDocumento' className='form-control' onChange={(e) => handleChange(e, setVersaoDoDocumento)} value={versaoDoDocumento} required />
                            
                            <label className='text-truncate mb-0 mt-3'>{getLanguageResource('expiry_date')}</label>
                            <input type="date" name='dataVencimento' id='dataVencimento' min={dateMin()} max={'9999-12-31'} className='form-control' onChange={(e) => handleChange(e, setValidadeDoArquivo)} value={dataVencimento} required />
                            {
                                dataVencimento.length > 0 && new Date(dataVencimento) < new Date() && (
                                    <p className='text-danger'>{getLanguageResource('invalid_date')}</p>
                                )
                            }
                        </React.Fragment>
                    )
                }
                {
                    (arquivoSelecionado !== getLanguageResource('no_file_selected') && !tamanhoDeArquivoInvalido) && (
                        <i className={`${handleIconFile(arquivoSelecionado)} my-4 text-center`} style={{ fontSize: '4rem' }}></i>
                    )

                }
                {
                    !tamanhoDeArquivoInvalido && (
                        <i className="mb-4 text-truncate text-center">{arquivoSelecionado}</i>
                    )
                }
                <button type="button" className="btn ws-outline-blue mb-1" id='botaoDeUpload' onClick={handleSelectFile}>{getLanguageResource('select_file')}</button>
                <label><i>{<Resource keyString="maximum_file_size_28MB" />}</i></label>
            </div>
            <input type="file" className="d-none" onChange={handleUploadFile} />
        </Container>
    );
};
