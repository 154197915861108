import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { FormularioData } from './FetchFormulario';
import { Layout } from '../../Layout';
import { HttpService } from '../../Shared/HttpService';
import { HeadLayout } from '../../Shared/HeadLayout';
import { AuthService } from '../../UserAuth/AuthService';
import { Loading } from '../../Shared/Loading';
import $ from 'jquery';
import { ValidationLabelMessage, ValidationAlertMessage } from '../../Shared/ValidationMessage';
import { FetchPergunta } from '../Pergunta/FetchPergunta';
import { FetchGrupoPergunta } from '../GrupoPergunta/FetchGrupoPergunta';
import { Resource } from '../../../index';
import { ModeloPlanoAcaoData } from '../../ModelosPlanoAcao/FetchModeloPlanoAcao';
import { LanguageContext } from '../../../context/LanguageContext';
import BotaoComIconePadrao from './components/BotaoComIconePadrao';
import CollapseDeConteudo from './components/CollapseDeConteudo';
import IconeDeAjuda from './components/IconeDeAjuda';
import CaixaDeConteudo from '../../Shared/CaixaDeConteudo';
import ModalWinSet from '../../Shared/components/ModalWinSet';
import { format } from 'date-fns';

export interface CupomData {
    id: number;
    inicio: string;
    fim: string;
    quantidade: number;
    comprados: number;
    codigo: string;
    tipo: string;
    valor: number;
    formularioid: number;
}

interface CamposDeCriarCupom {
    inicio: string;
    fim: string;
    quantidade: number;
    codigo: string;
    tipo: string;
    valor: number;
    formularioid: number;
}

interface AddFormularioDataState {
    loading: boolean;
    modeloPlanoAcaoList: ModeloPlanoAcaoData[];
    formulario: FormularioData;
    modalCupomEstaAberto: boolean;
    modalCupomIsLoading: boolean;
    camposDeCriarCupom: CamposDeCriarCupom;
    deletingCupons: number[];
}

export class AddFormulario extends React.Component<RouteComponentProps<{}>, AddFormularioDataState> {
    static contextType = LanguageContext;
    private _pathname;
    private _regexPaginaDeEdicao;
    private _estouNaPaginaDeEdicao;
    constructor(props) {
        super(props);
        this._pathname = window.location.pathname;
        this._regexPaginaDeEdicao = new RegExp(/formulario\/edit/);
        this._estouNaPaginaDeEdicao = this._regexPaginaDeEdicao.test(this._pathname);

        var formularioid = this.props.match.params["formularioid"];

        var formulario = new FormularioData;

        this.state = {
            loading: true,
            formulario: formulario,
            modeloPlanoAcaoList: [],
            modalCupomEstaAberto: false,
            modalCupomIsLoading: false,
            camposDeCriarCupom: {
                inicio: '',
                fim: '',
                quantidade: 0,
                codigo: '',
                tipo: 'D',
                valor: 0,
                formularioid: 0,
            },
            deletingCupons: [],
        };

        if(!this._estouNaPaginaDeEdicao){
            this.state.formulario.distribuirInterno = true;
        }

        // This will set state for Edit User  
        if (formularioid > 0) {

            Loading.showLoading(this);

            HttpService.fetch('api/FormularioAPI/' + formularioid, { method: 'GET' })
                .then(response => response.json() as Promise<FormularioData>)
                .then(data => {
                    this.setState({ loading: false, formulario: data });
                    Loading.hideLoading(this);
                }).catch(() => {
                    Loading.hideLoading(this);
                    props.history.push(`/repositorio`);
                });
        }

        // This will set state for Add User  
        else {
            this.state = {
                loading: false,
                formulario: formulario,
                modeloPlanoAcaoList: [],
                modalCupomEstaAberto: false,
                modalCupomIsLoading: false,
                camposDeCriarCupom: {
                    inicio: '',
                    fim: '',
                    quantidade: 0,
                    codigo: '',
                    tipo: 'D',
                    valor: 0,
                    formularioid: 0,
                },
                deletingCupons: [],
            };
        }

        Loading.showLoading(this);
        HttpService.fetch('api/ModeloPlanoAcaoApi/SelectModeloPlanoAcaoDaListagemDaPergunta', {})
            .then(response => response.json() as Promise<ModeloPlanoAcaoData[]>)
            .then(data => {
                this.setState({ modeloPlanoAcaoList: data });
                Loading.hideLoading(this);
            });

        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleDuplicate = this.handleDuplicate.bind(this);
    }

    estouNaRotaDeEdicao = () => this.props.match.params["formularioid"] > 0;

    handlePriceInput = (value) => {
        value = value.replace(/\D/g, '');
        
        if (value.length < 3) {
            let zeros = 3 - value.length;
            for (let i = 0; i < zeros; i++) {
                value = '0' + value;
            }
        }

        value = value.replace(/(\d)(\d{2})$/, '$1,$2');
        
        value = value.replace(/,/g, '.');
        value = parseFloat(value);

        this.setState({
            formulario: {
                ...this.state.formulario,
                valor: value,
            }
        });
    }

    criarCupom = () => {
        this.setState({ modalCupomIsLoading: true });
        HttpService.fetch('api/Cupons', {
            method: 'POST',
            body: JSON.stringify({
                ...this.state.camposDeCriarCupom,
                formularioid: this.state.formulario.id,
            }),
        }).then((response) => {
            return response.json() as Promise<CupomData>
        }).then(async (data) => {
            await this.obterCupons();
            this.setState({ modalCupomIsLoading: false, modalCupomEstaAberto: false });
        }).catch(() => {
            this.setState({ modalCupomIsLoading: false });
        })
    }

    obterCupons = () => {
        HttpService.fetch('api/Cupons/' + this.state.formulario.id, {
            method: 'GET',
        }).then((response) => {
            return response.json() as Promise<CupomData[]>
        }).then((data) => {
            this.setState({ formulario: { ...this.state.formulario, cupons: data } });
        }).catch(() => { console.log("Erro ao obter cupons") })
    }

    deletarCupom = (id: number) => {
        this.setState({ deletingCupons: [ ...this.state.deletingCupons, id ] });
        HttpService.fetch('api/Cupons/' + id, {
            method: 'DELETE',
        }).then(async (response) => {
            await this.obterCupons();
            this.setState({ deletingCupons: this.state.deletingCupons.filter(x => x !== id) });
        }).catch(() => { console.log("Erro ao deletar cupom") })
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;

        return (
            <CaixaDeConteudo
                tituloDoHeader={this._estouNaPaginaDeEdicao ? this.context.getLanguageResource("edit_form") : this.context.getLanguageResource("create_form")}
                botoes={[
                    <button className='btn bg-white mr-2 shadow-sm' onClick={() => window.location.href = '/formulario'}>{this.context.getLanguageResource('back')}</button>
                ]}
                itensParaBreadcrumb={[
                    { url: '/', nome: this.context.getLanguageResource('home') },
                    { url: '/formulario', nome: this.context.getLanguageResource('forms') },
                    { nome: this._estouNaPaginaDeEdicao ? this.context.getLanguageResource("edit_form") : this.context.getLanguageResource("create_form") }
                ]}
            >
                <div className='p-4'>
                    <div>
                        <h3 className="text-center title-2">{this._estouNaPaginaDeEdicao ? this.context.getLanguageResource("edit_form") : this.context.getLanguageResource("create_form")}</h3>
                    </div>
                    {this.renderCreateForm()}
                    {
                        this.state.formulario.id > 0 && <FetchGrupoPergunta formularioid={this.state.formulario.id} />
                    }
                    {
                        this.state.modalCupomEstaAberto && (
                            <ModalWinSet
                                fecharModal={() => this.setState({ modalCupomEstaAberto: false })}
                            >
                                <div className='d-flex flex-column p-2'>
                                    <label>{ this.context.getLanguageResource('coupon_code') }</label>
                                    <input disabled={this.state.modalCupomIsLoading} type='text' className='form-control mb-2' placeholder={ this.context.getLanguageResource('coupon_code') } value={ this.state.camposDeCriarCupom.codigo } onChange={(e) => this.setState({ ...this.state, camposDeCriarCupom: { ...this.state.camposDeCriarCupom, codigo: e.target.value } })} />
                                    <label>{ this.context.getLanguageResource('start_date') }</label>
                                    <input disabled={this.state.modalCupomIsLoading} type='date' className='form-control mb-2' placeholder={ this.context.getLanguageResource('start_date') } value={ this.state.camposDeCriarCupom.inicio } onChange={(e) => this.setState({ ...this.state, camposDeCriarCupom: { ...this.state.camposDeCriarCupom, inicio: e.target.value } })} />
                                    <label>{ this.context.getLanguageResource('end_date') }</label>
                                    <input disabled={this.state.modalCupomIsLoading} type='date' className='form-control mb-2' placeholder={ this.context.getLanguageResource('end_date') } value={ this.state.camposDeCriarCupom.fim } onChange={(e) => this.setState({ ...this.state, camposDeCriarCupom: { ...this.state.camposDeCriarCupom, fim: e.target.value } })} />
                                    <label>{ this.context.getLanguageResource('quantity') }</label>
                                    <input disabled={this.state.modalCupomIsLoading} type='number' className='form-control mb-2' placeholder={ this.context.getLanguageResource('quantity') } value={ this.state.camposDeCriarCupom.quantidade } onChange={(e) => this.setState({ ...this.state, camposDeCriarCupom: { ...this.state.camposDeCriarCupom, quantidade: Number(e.target.value) } })} />
                                    <label>{ this.context.getLanguageResource('discount_value') }</label>
                                    <input disabled={this.state.modalCupomIsLoading} type='number' className='form-control mb-2' placeholder={ this.context.getLanguageResource('discount_value') } value={ this.state.camposDeCriarCupom.valor } onChange={(e) => this.setState({ ...this.state, camposDeCriarCupom: { ...this.state.camposDeCriarCupom, valor: Number(e.target.value) } })} />
                                    <label>{ this.context.getLanguageResource('discount_type') }</label>
                                    <select disabled={this.state.modalCupomIsLoading} className='form-control mb-2' value={ this.state.camposDeCriarCupom.tipo } onChange={(e) => this.setState({ ...this.state, camposDeCriarCupom: { ...this.state.camposDeCriarCupom, tipo: e.target.value } })}>
                                        <option value='P'>{ this.context.getLanguageResource('percentage') }</option>
                                        <option value='D'>{ this.context.getLanguageResource('fixed_discount') }</option>
                                    </select>
                                    <button
                                        disabled={this.state.modalCupomIsLoading}
                                        className='btn btn-primary'
                                        onClick={this.criarCupom}
                                    >
                                        {
                                            this.state.modalCupomIsLoading
                                                ?   (
                                                        <div className="spinner-border text-light" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    )
                                                :   this.context.getLanguageResource('add')
                                        }
                                    </button>
                                </div>
                            </ModalWinSet>
                        )
                    }
                </div>
            </CaixaDeConteudo>
        );
    }

    // This will handle the submit form event.  
    private handleSave(event) {
        event.preventDefault();

        let formularioId = this.state.formulario.id;

        Loading.showLoading(this);

        // PUT request for Edit User.  
        if (formularioId) {
            HttpService.fetch('api/FormularioAPI/' + formularioId, {
                method: 'PUT',
                body: HttpService.formToJson(event.target),
            }).then((response) => {
                return response.json() as Promise<FormularioData>
            }).then((data) => {
                this.setState({ formulario: data })
                if ($.isEmptyObject(data.error)) {
                    this.redirectPage();
                }
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            })

        } else {
            HttpService.fetch('api/FormularioAPI', {
                method: 'POST',
                body: HttpService.formToJson(event.target),
            }).then((response) => {
                return response.json() as Promise<FormularioData>
            }).then((data) => {
                this.setState({ formulario: data })
                if ($.isEmptyObject(data.error)) {
                    this.redirectPage();
                }
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            })
        }
    }

    // This will handle Duplicated the form.
    private handleDuplicate(e) {
        e.preventDefault();

        let formularioId = this.state.formulario.id;

        Loading.showLoading(this);

        HttpService.fetch('api/FormularioAPI/Duplicacao/' + formularioId, {
            method: 'GET',
        }).then((response) => {
            return response.json() as Promise<FormularioData>
        }).then((data) => {

            this.setState({ formulario: data })
            Loading.hideLoading(this);
            if ($.isEmptyObject(data.error)) {
                alert(this.context.getLanguageResource("being_redirected_to_the_duplicate_form"))
                this.props.history.push(`/formulario/edit/${data.id}`);
            }
        }).catch(() => {
            Loading.hideLoading(this);
        })
    }

    // This will handle Cancel button click event.  
    private handleCancel(e) {
        e.preventDefault();
        this.props.history.push(`/formulario`);
    }

    private redirectPage() {
        window.location.href = (`/formulario/edit/${this.state.formulario.id}`);
        //this.props.history.push(`/formulario`);
    }

    // Returns the HTML Form to the render() method.  
    private renderCreateForm() {
        return (<form onSubmit={this.handleSave} >
            <div className="form-group row" >
                <input type="hidden" name="id" value={this.state.formulario.id} />
            </div>
            <div className="card-title">
                <h3 className="title-section-paged">{this.context.getLanguageResource("general_information")}</h3>
            </div>
            <div className="form-group row">
                <div className="col-md-6">
                    <label className=" control-label col-md-12 required" htmlFor="Nome">{this.context.getLanguageResource("form_name")}</label>
                    <div className="col-md-12">
                        <ValidationLabelMessage errors={this.state.formulario.error} name="Nome" >
                            <input className="form-control" type="text" name="Nome" defaultValue={this.state.formulario.nome} />
                        </ValidationLabelMessage>
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="control-label col-md-12 required" htmlFor="Descricao">{this.context.getLanguageResource("form_description")}</label>
                    <div className="col-md-12">
                        <ValidationLabelMessage errors={this.state.formulario.error} name="Descricao" >
                            <input className="form-control" type="text" name="Descricao" defaultValue={this.state.formulario.descricao} />
                        </ValidationLabelMessage>
                    </div>
                </div>
            </div>
            <CollapseDeConteudo titulo={this.context.getLanguageResource('result_settings')}>
                <div className="form-group row">
                    <div className="col-md-6">
                        <label className=" control-label col-md-12" htmlFor="Valor">{this.context.getLanguageResource("point_percentage")}</label>
                        <div className="col-md-12">
                            <ValidationLabelMessage errors={this.state.formulario.error} name="toleranciaporcentagem" >
                                <input className="form-control" type="number" name="toleranciaporcentagem" defaultValue={this.state.formulario.toleranciaporcentagem + ""} />
                            </ValidationLabelMessage>
                        </div>
                    </div>
                    
                    <div className="col-md-6">
                    <label className="control-label col-md-12" htmlFor="Tolerancia">{this.context.getLanguageResource("point_tolerance")}</label>
                        <div className="col-md-12">
                            <ValidationLabelMessage errors={this.state.formulario.error} name="toleranciapontos" >
                                <input className="form-control" type="number" name="toleranciapontos" defaultValue={this.state.formulario.toleranciapontos + ""} />
                            </ValidationLabelMessage>
                        </div>
                    </div>
                </div>
                <div className="form-group row">
                    
                    <div className="col-md-6">
                        <label className="control-label col-md-12" htmlFor="RespostaPadrao">{this.context.getLanguageResource("standard_response")}</label>
                        <div className="col-md-12">
                            <input className="form-control" type="text" name="RespostaPadrao" defaultValue={this.state.formulario.respostaPadrao ? this.state.formulario.respostaPadrao : ""} />
                        </div>
                    </div>

                    <div className="d-flex col-md-6 justify-content-start align-items-end">
                        {
                            this.estouNaRotaDeEdicao()
                                ?   (
                                        <React.Fragment>
                                            <BotaoComIconePadrao
                                                classeDeIcone='fa fa-calculator'
                                                type='button'
                                                onClick={ () => this.props.history.push('/formulario/formularesultado/' + this.state.formulario.id) }
                                                title={ this.context.getLanguageResource('result_formula') }
                                                style={ { backgroundColor: 'var(--ws-blue)' } }
                                            >
                                                { this.context.getLanguageResource('result_formula') }
                                            </BotaoComIconePadrao>
                                            <IconeDeAjuda
                                                titulo={ this.context.getLanguageResource('result_formula') }
                                                textoDeAjuda={ this.context.getLanguageResource('result_formula_button_help_text') }
                                                posicionamento='superior-direito'
                                                style={ { margin: '0 0 2rem 0.5rem' } }
                                            />
                                        </React.Fragment>
                                    )
                                : <React.Fragment />
                        }
                    </div>
                </div>
            </CollapseDeConteudo>

            <CollapseDeConteudo titulo={this.context.getLanguageResource('distribution_settings')}>
                <div className="form-group row">

                    <div className="col-md-6">
                        <label className=" control-label col-md-12" htmlFor="Valor">{this.context.getLanguageResource("form_sale_price")}</label>
                        <div className="col-md-12">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">{this.context.getLanguageResource('currency_symbol')}</span>
                                </div>
                                <ValidationLabelMessage errors={this.state.formulario.error} name="Valor" >
                                    { /* o valor deve ser exibido com virgula para separar decimais e pontos para separar a cada 3 caracteres  */ }
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Valor"
                                        value={this.state.formulario.valor}
                                        onChange={(e) => this.handlePriceInput(e.target.value)}
                                    />
                                </ValidationLabelMessage>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <label className=" control-label col-md-12" htmlFor="Prazo">{this.context.getLanguageResource("term")}</label>
                        <div className="col-md-12">
                            <ValidationLabelMessage errors={this.state.formulario.error} name="Prazo" >
                                <input className="form-control" type="number" name="Prazo" defaultValue={this.state.formulario.prazo === null ? 0 : this.state.formulario.prazo} />
                            </ValidationLabelMessage>
                        </div>
                    </div>
                </div>
                {
                    this.state.formulario.id !== 0 && (
                        <div className="form-group row">
                            <div className="col-md-12" >
                                <div className='d-flex'>
                                    <div>
                                        <BotaoComIconePadrao
                                            type='button'
                                            title={ this.context.getLanguageResource('add_coupon') }
                                            style={ { backgroundColor: 'var(--ws-blue)' } }
                                            onClick={ () => this.setState({ modalCupomEstaAberto: true }) }
                                        >
                                            { this.context.getLanguageResource('add_coupon') }
                                        </BotaoComIconePadrao>
                                    </div>
                                    <div className='ml-2 rounded border p-2 w-100'>
                                        <p className='m-0'>{ this.context.getLanguageResource('active_coupons') }</p>
                                        {
                                            this.state.formulario.cupons.length > 0
                                                ?   (
                                                        this.state.formulario.cupons.map(x => (
                                                            <div className='d-flex justify-content-between my-2 p-2 rounded border'>
                                                                <div className='d-flex'>
                                                                    <div className='mr-2'>
                                                                        <h3 className='m-0'>{ x.codigo }</h3>
                                                                        <p className='m-0'>{ format(new Date(x.inicio), 'dd/MM/yyyy') } - { format(new Date(x.fim), 'dd/MM/yyyy') }</p>
                                                                    </div>
                                                                    <div className='border-left pl-2'>
                                                                        <p className='m-0'>{ x.valor }{ x.tipo === "P" ? "%" : " reais" } {this.context.getLanguageResource("of_discount")}</p>
                                                                        <p className='m-0'>{ x.quantidade - x.comprados } {this.context.getLanguageResource("remaining_of")} { x.quantidade }</p>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    {
                                                                        this.state.deletingCupons.includes(x.id)
                                                                            ?   (
                                                                                    <div className="spinner-border text-primary" role="status">
                                                                                        <span className="sr-only">Loading...</span>
                                                                                    </div>
                                                                                )
                                                                            :   <React.Fragment>
                                                                                    <BotaoComIconePadrao
                                                                                        classeDeIcone='fa fa-trash'
                                                                                        type='button'
                                                                                        title={ this.context.getLanguageResource('remove') }
                                                                                        style={ { backgroundColor: 'var(--ws-red)' } }
                                                                                        onClick={ () => this.deletarCupom(x.id) }
                                                                                    />
                                                                                </React.Fragment>
                                                                    }
                                                                </div>
                                                            </div>
                                                        
                                                        ))
                                                    )
                                                :   (
                                                        <div className='w-100 text-center'>
                                                            <p>{ this.context.getLanguageResource('no_active_coupons') }</p>
                                                        </div>
                                                    )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                <div className="form-group row">
                    <div className="col-md-6 ml-3" >
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" name="DistribuirInterno" id="DistribuirInterno" defaultChecked={this.state.formulario.distribuirInterno} />
                            <label className="custom-control-label" htmlFor="DistribuirInterno">{this.context.getLanguageResource("display_in_the_forms_store_internally")}</label>
                        </div>
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-md-6 ml-3" >
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" name="Distribuir" id="Distribuir" defaultChecked={this.state.formulario.distribuir} />
                            <label className="custom-control-label" htmlFor="Distribuir">{this.context.getLanguageResource("display_in_the_forms_store")}</label>
                        </div>
                    </div>
                </div>
            </CollapseDeConteudo>
            <div className="form-group row">
                <div className="col-md-12">
                    <div className='d-flex flex-row  col-md-12'>
                        <label className="control-label" htmlFor="Modeloacao_Id">{this.context.getLanguageResource('action_plan_template')}</label>
                        <IconeDeAjuda
                            titulo={ this.context.getLanguageResource('action_plan_template') }
                            textoDeAjuda={ this.context.getLanguageResource('action_plan_template_help_text') }
                            posicionamento='superior-direito'
                            style={ { marginLeft: '0.5rem' } }
                        />
                    </div>
                    <div className="col-md-12">
                        <ValidationLabelMessage errors={this.state.formulario.error} name="Modeloacao_Id" >
                            <select className="form-control col-md-12" data-val="true" id="Modeloacao_Id" name="Modeloacao_Id" defaultValue={"" + this.state.formulario.modeloacao_Id} >
                                <option value="">{this.context.getLanguageResource("none")}</option>
                                {this.state.modeloPlanoAcaoList.map(x =>
                                    <option key={x.id} value={x.id}>{x.nome}</option>
                                )}
                            </select>
                        </ValidationLabelMessage>
                    </div>
                </div>
            </div>
            <div className="form-group row justify-content-end">
                <div
                    className="col-md-6 mt-3 mr-3 d-flex justify-content-end"
                    style={ { fontWeight: 700, color: 'black', fontSize: '1.1rem' } }
                >
                    <label className="control-label" htmlFor="Pontuação Total">
                        { `${this.context.getLanguageResource("total_score")}: ` }
                    </label>
                    <strong className="ml-3">{ `${this.state.formulario.pontuacaoTotal || 0}` }</strong>
                    <IconeDeAjuda
                        titulo={ this.context.getLanguageResource('total_score') }
                        textoDeAjuda={ this.context.getLanguageResource('total_score_help_text') }
                        posicionamento='superior-esquerdo'
                        style={ { marginLeft: '0.5rem' } }
                    />
                </div>
            </div>
            <div className="form-group row">
                <div className='col-md-6'>
                    {
                        this.estouNaRotaDeEdicao()
                            ?   <BotaoComIconePadrao
                                    classeDeIcone='fa fa-header'
                                    type='button'
                                    onClick={ () => this.props.history.push('/formulario/cabecalho/' + this.state.formulario.id) }
                                    style={ { backgroundColor: 'var(--ws-blue)' } }
                                    title={ this.context.getLanguageResource('header') }
                                >
                                    { this.context.getLanguageResource('header') }
                                </BotaoComIconePadrao>
                            :   <React.Fragment />
                    }
                    
                </div>
                <div className="col-md-6 d-flex justify-content-end">
                    <BotaoComIconePadrao
                        classeDeIcone='fa fa-save'
                        className='btn-outline-success mr-2'
                        type='submit'
                        title={ this.context.getLanguageResource('save') }
                        />
                    {
                        this.estouNaRotaDeEdicao()
                            ?   <BotaoComIconePadrao
                                    classeDeIcone='fa fa-copy'
                                    className='btn-outline-warning'
                                    type='button'
                                    onClick={ this.handleDuplicate }
                                    title={ this.context.getLanguageResource('duplicate_form') }
                                />
                            :   <React.Fragment />
                    }
                </div>
            </div >
        </form >)
    }
}