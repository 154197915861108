import * as React from 'react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { RouteComponentProps } from 'react-router';
import { EmpresaData } from './FetchEmpresa';
import { HttpService } from '../Shared/HttpService';
import { Loading } from '../Shared/Loading';
import $ from 'jquery';
import { Resource } from '../../index';
import { ValidationLabelMessage } from '../Shared/ValidationMessage';
import { LanguageContext } from '../../context/LanguageContext';
import CaixaDeConteudo from '../Shared/CaixaDeConteudo';
import validarCNPJ from '../../helpers/validarCNPJ';
import formatarCNPJ from '../../helpers/mascaraCNPJ';
import ModalWinSet from '../Shared/components/ModalWinSet';
import formatarTokenConfirmacaoDeEmail from '../../helpers/mascaraTokenConfirmacaoEmail';
import { InputCNPJ } from './components/InputCNPJ';
import formatarTokenConfirmacaoDeCelular from '../../helpers/mascaraTokenConfirmacaoCelular';
import ExclamationCircleIcon from '../PA/Formulario/icons/ExclamationCircleIcon';

interface MInhaEmpresaState {
    loading: boolean;
    empData: EmpresaData;
    confirmacaoSolicitada: boolean;
    momentoDeConfirmacaoDoEmail: MomentoDeConfirmacao;
    momentoDeConfirmacaoDoCelular: MomentoDeConfirmacao;
    codigoDeConfirmacaoDeEmail: string;
    codigoDeConfirmacaoDeCelular: string;
    modalDeSolicitacaoDeConfirmacaoDeEmailAberto: boolean;
    modalDeSolicitacaoDeConfirmacaoDeCelularAberto: boolean;
    momentoDeCep: MomentoCEP;
    semNumeroEstaMarcado: boolean;
}

enum MomentoDeConfirmacao {
    Pendente,
    EmAndamento,
    Sucesso,
    Falha,
}

enum MomentoCEP {
    Pendente,
    EmAndamento,
    Sucesso,
    Falha,
}

export class MInhaEmpresa extends React.Component<RouteComponentProps<{}>, MInhaEmpresaState> {
    static contextType = LanguageContext;
    timerAposConfirmacaoDeEmail: NodeJS.Timeout | null;
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            empData: new EmpresaData,
            confirmacaoSolicitada: false,
            momentoDeConfirmacaoDoEmail: MomentoDeConfirmacao.Pendente,
            momentoDeConfirmacaoDoCelular: MomentoDeConfirmacao.Pendente,
            momentoDeCep: MomentoCEP.Pendente,
            codigoDeConfirmacaoDeEmail: "",
            codigoDeConfirmacaoDeCelular: "",
            modalDeSolicitacaoDeConfirmacaoDeEmailAberto: false,
            modalDeSolicitacaoDeConfirmacaoDeCelularAberto: false,
            semNumeroEstaMarcado: false,
        };

        this.timerAposConfirmacaoDeEmail = null;
        
        this.handleSave = this.handleSave.bind(this);
        this.handleCepChange = this.handleCepChange.bind(this);
        this.handleCnpjChange = this.handleCnpjChange.bind(this);
        this.handleChangeInputConfirmacaoDeEmail = this.handleChangeInputConfirmacaoDeEmail.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.confirmarEmail = this.confirmarEmail.bind(this);
        this.solicitarCodigoDeConfirmacaoDeEmail = this.solicitarCodigoDeConfirmacaoDeEmail.bind(this);
        this.emailEhValido = this.emailEhValido.bind(this);
        this.celularEhValido = this.celularEhValido.bind(this);
        this.handleSemNumeroChange = this.handleSemNumeroChange.bind(this);
        this.handleNumeroChange = this.handleNumeroChange.bind(this);
    }

    componentDidMount(): void {
        Loading.showLoading(this);
        HttpService.fetch('api/EmpresasApi/MinhaEmpresa', { method: 'GET' })
            .then(response => response.json() as Promise<EmpresaData>)
            .then(data => {
                this.setState({
                    loading: false,
                    empData: {
                        ...data,
                        cep: data.cep === null ? "" : data.cep,
                    },
                });
                Loading.hideLoading(this);
            });
    }

    private botoesDoCantoSuperiorDireito() {
        return (
            <button className="btn btn-light mx-3" onClick={() => window.location.href = '/'}>
                <Resource keyString="back" />
            </button>
        );
    }

    consultarCep = async (cep: string): Promise<void> => {
        // Remover tudo que não for número do CEP
        cep = cep.replace(/\D/g, '');
        
        // Verificar se o CEP contém a quantidade de caracteres correta
        if (cep.length !== 8) {
            // Aplicar a máscara de CEP e parar a função
            cep = cep.replace(/(\d{5})(\d)/, '$1-$2');
            this.setState({ empData: { ...this.state.empData, cep: cep, cepError: "", complemento: "", numero: "" } });
            return;
        }

        this.setState({ momentoDeCep: MomentoCEP.EmAndamento });
        // Fazer a requisição
        try {
            const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
            cep = cep.replace(/(\d{5})(\d)/, '$1-$2');
            const dados = await response.json();

            if (!("erro" in dados)) {
                this.setState({
                    empData: { 
                        ...this.state.empData,
                        cep: cep,
                        cepError: "",
                        endereco: dados.logradouro,
                        bairro: dados.bairro,
                        cidade: dados.localidade,
                        uf: dados.uf,
                    },
                    momentoDeCep: MomentoCEP.Sucesso
                });
            } else {
                // CEP pesquisado não foi encontrado.
                this.setState({
                    empData: {
                        ...this.state.empData,
                        cep: cep,
                        cepError: "CEP não encontrado.",
                        endereco: "",
                        bairro: "",
                        cidade: "",
                        uf: "",
                    }, 
                    momentoDeCep: MomentoCEP.Falha
                });
            }
        } catch (error) {
            // Erro na consulta ao webservice.
            this.setState({
                empData: {
                    ...this.state.empData,
                    cep: cep,
                    cepError: "Erro ao consultar CEP.",
                    endereco: "",
                    bairro: "",
                    cidade: "",
                    uf: "",
                }, 
                momentoDeCep: MomentoCEP.Falha
            });

        }
    }

    handleTextChange = (event) => {
        const target = event.target;
        const value = target.value;
        const id = target.id;

        this.setState({ empData: { ...this.state.empData, [id]: value } });
    };

    handleCepChange(event): void {
        this.consultarCep(event.target.value);
    }

    handleCnpjChange(event): void {
        // limpar o cnpj de tudo que não for número
        let CNPJ = event.target.value.replace(/\D/g, '');
        
        if (CNPJ.length > 14) CNPJ = CNPJ.slice(0, 14);

        const cnpjFormatado = formatarCNPJ(CNPJ);
    
        // verificar se o cnpj possui 14 caracteres e é válido
        let cnpjError = "";
        let erroDeValidacao;
        if (CNPJ.length === 14 && !validarCNPJ(CNPJ)) {
            cnpjError = "CNPJ inválido.";
            erroDeValidacao = { ...this.state.empData.error, Documento: cnpjError };
        }
    
        // atualizar o estado com o cnpj e o erro de validação
        this.setState({ empData: { ...this.state.empData, documento: cnpjFormatado, error: erroDeValidacao ? erroDeValidacao : null } });
    }

    handleSelectChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({ empData: { ...this.state.empData, [name]: value } });
    };

    handleChangeInputConfirmacaoDeEmail = (event) => {
        const codigoFormatado = formatarTokenConfirmacaoDeEmail(event.target.value);
        this.setState((_prevState) => {
            if (codigoFormatado.length === 9) {
                this.confirmarEmail(codigoFormatado);
            };
            return { codigoDeConfirmacaoDeEmail: codigoFormatado }
        });
    }

    handleChangeInputConfirmacaoDeCelular = (event) => {
        const codigoFormatado = formatarTokenConfirmacaoDeCelular(event.target.value);
        this.setState((_prevState) => {
            if (codigoFormatado.length === 7) {
                this.confirmarCelular(codigoFormatado);
            };
            return { codigoDeConfirmacaoDeCelular: codigoFormatado }
        });
    }

    handleChangeInputColor = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({ empData: { ...this.state.empData, [name]: value } });
    };

    confirmarCelular = (codigoDeConfirmacaoDeCelular: string) => {
        this.setState({ momentoDeConfirmacaoDoCelular: MomentoDeConfirmacao.EmAndamento });
        HttpService.fetch(`api/Authentication/ValidarTokenRecebidoPorSMS/${codigoDeConfirmacaoDeCelular}/${this.state.empData.celular}`, { method: 'POST' })
            .then((response) => {
                if (response.ok) {
                    const dataDeHoje = new Date().toISOString().slice(0, 19).replace('T', ' ');
                    this.setState({ empData: { ...this.state.empData, celularconfirmado: dataDeHoje }, momentoDeConfirmacaoDoCelular: MomentoDeConfirmacao.Sucesso });
                } else {
                    this.setState({ momentoDeConfirmacaoDoCelular: MomentoDeConfirmacao.Falha });
                }
            })
            .catch((error) => {
                this.setState({ momentoDeConfirmacaoDoCelular: MomentoDeConfirmacao.Falha });
            });
    }

    confirmarEmail = (codigoDeConfirmacaoDeEmail: string) => {
        this.setState({ momentoDeConfirmacaoDoEmail: MomentoDeConfirmacao.EmAndamento });
        HttpService.fetch(`api/Authentication/ValidarEmail`, {
            method: 'POST',
            body: JSON.stringify({ token: codigoDeConfirmacaoDeEmail, email: this.state.empData.email })
        })
            .then((response) => {
                if (response.ok) {
                    const dataDeHoje = new Date().toISOString().slice(0, 19).replace('T', ' ');
                    this.setState({ empData: { ...this.state.empData, emailconfirmado: dataDeHoje }, momentoDeConfirmacaoDoEmail: MomentoDeConfirmacao.Sucesso });
                } else {
                    this.setState({ momentoDeConfirmacaoDoEmail: MomentoDeConfirmacao.Falha });
                }
            }
        );
    }

    solicitarCodigoDeConfirmacaoDeEmail = () => {
        HttpService.fetch(`api/Authentication/SolicitarValidacaoDeEmail`, {
            method: 'POST',
            body: JSON.stringify({ email: this.state.empData.email })
        })
    }

    solicitarCodigoDeConfirmacaoDeCelular = () => HttpService.fetch(`api/Authentication/SolicitarValidacaoDeCelular/${this.state.empData.celular}`, { method: 'POST' });

    emailEhValido = () => {
        const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;
        return emailRegex.test(this.state.empData.email);
    };

    celularEhValido = () => {
        const celularRegex = /^\(\d{2}\)\s\d{5}-\d{4}$/;
        return celularRegex.test(this.state.empData.celular);
    };

    handleCelularChange = (event) => {
        const celularFormatado = event.target.value.replace(/\D/g, '').replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
        this.setState((_prevState) => {
            return { empData: { ...this.state.empData, celular: celularFormatado } }
        });
    };

    handleNumeroChange = (event) => {
        if (this.state.semNumeroEstaMarcado) return;
        const numero = event.target.value.replace(/\D/g, '');
        this.setState({ empData: { ...this.state.empData, numero: numero } });
    };

    handleSemNumeroChange = (event) => {
        if (event.target.checked) {
            this.setState({ empData: { ...this.state.empData, numero: "0" }, semNumeroEstaMarcado: true });
        } else {
            this.setState({ empData: { ...this.state.empData, numero: "" }, semNumeroEstaMarcado: false });
        }
    };

    componentDidUpdate(prevProps: Readonly<RouteComponentProps<{}>>, prevState: Readonly<MInhaEmpresaState>, snapshot?: any): void {
        if (this.state.momentoDeConfirmacaoDoEmail === MomentoDeConfirmacao.Sucesso) {
            this.timerAposConfirmacaoDeEmail = setTimeout(() => {
                this.setState({ modalDeSolicitacaoDeConfirmacaoDeEmailAberto: false })
            }, 1500);
        }

        if (!this.state.modalDeSolicitacaoDeConfirmacaoDeEmailAberto && this.timerAposConfirmacaoDeEmail) {
            clearTimeout(this.timerAposConfirmacaoDeEmail);
        }
    }


    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;

        return (
            <CaixaDeConteudo
                itensParaBreadcrumb={[
                    { url: "/", nome: this.context.getLanguageResource("home") },
                    { nome: this.context.getLanguageResource("my_company") }
                ]}
                tituloDoHeader={this.context.getLanguageResource("my_company")}
                botoes={this.botoesDoCantoSuperiorDireito()}
            >
                <form onSubmit={this.handleSave} className='w-100 h-100 d-flex flex-column justify-content-between align-items-between'>
                    <div className="form-group row" >
                        <input type="hidden" name="id" value={this.state.empData.id} />
                        <input type="hidden" name="Afiliado" id="afiliado" value={this.state.empData.afiliado?.toString()} />
                        {
                            this.state.empData.emailconfirmado !== null && <input type="hidden" name="Emailconfirmado" id="emailconfirmado" value={this.state.empData.emailconfirmado as string} />
                        }
                        {
                            this.state.empData.celularconfirmado !== null && <input type="hidden" name="Celularconfirmado" id="celularconfirmado" value={this.state.empData.celularconfirmado as string} />
                        }
                    </div>
                    <div className="card-title mx-3">
                        <h6 className="title-section-paged">{<Resource keyString="company_data" />}</h6>
                        <hr />
                    </div>
                    <div className="form-group row" >
                        <div className="col-md-6">
                            <label className="control-label col-md-12" htmlFor="Nomefantasia" >
                                {<Resource keyString="fantasy_name" />}
                                {
                                    this.state.empData.nomefantasia === null || this.state.empData.nomefantasia === "" &&
                                        <ExclamationCircleIcon fill='#ef0000' className='ml-2' />
                                }
                            </label>
                            <div className="col-md-12">
                                <ValidationLabelMessage errors={this.state.empData.error} name="Nomefantasia">
                                    <input className="form-control required" type="text" name="Nomefantasia" id="nomefantasia" onChange={this.handleTextChange} value={this.state.empData.nomefantasia} />
                                </ValidationLabelMessage>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label className="control-label col-md-12 " htmlFor="Razaosocial" >{<Resource keyString="company_name" />}
                                {
                                    this.state.empData.razaosocial === null || this.state.empData.razaosocial === "" &&
                                        <ExclamationCircleIcon fill='#ef0000' className='ml-2' />
                                }
                            </label>
                            <div className="col-md-12">
                                <ValidationLabelMessage errors={this.state.empData.error} name="Razaosocial">
                                    <input className="form-control" type="text" name="Razaosocial" id="razaosocial" onChange={this.handleTextChange} value={this.state.empData.razaosocial} />
                                </ValidationLabelMessage>
                            </div>
                        </div>
                    </div>
                    <div className="form-group row" >
                        <div className="col-md-6" >
                            <label className="control-label col-md-12 required" htmlFor="Documento">{<Resource keyString="documents" />} (CNPJ)
                                {
                                    this.state.empData.documento === null || this.state.empData.documento === "" &&
                                        <ExclamationCircleIcon fill='#ef0000' className='ml-2' />
                                }
                            </label>
                            <div className="col-md-12">
                                <InputCNPJ error={this.state.empData.error} onChange={this.handleCnpjChange} value={this.state.empData.documento} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label className="control-label col-md-12 required" htmlFor="Email" >{<Resource keyString="email" />}
                                {
                                    this.state.empData.email === null || this.state.empData.email === "" &&
                                        <ExclamationCircleIcon fill='#ef0000' className='ml-2' />
                                }
                            </label>
                            <div className="col-md-12">
                                <ValidationLabelMessage errors={this.state.empData.error} name="Email">
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="Email"
                                        id="Email"
                                        value={this.state.empData.email}
                                        onChange={(e) => {
                                            e.target.classList.remove('is-invalid')
                                            this.setState({ empData: { ...this.state.empData, email: e.target.value } })
                                        }}
                                    />
                                    <div className='d-flex flex-column'>
                                        {
                                            this.state.empData.emailconfirmado !== null
                                            ?   (
                                                    <div className='w-100 d-flex justify-content-between my-1'>
                                                        <div className='d-flex'>
                                                            <i className='mr-2 fa fa-check-circle text-success' />
                                                            <p className='text-success mb-0'><Resource keyString="email_confirmed" /></p>
                                                        </div>
                                                    </div>
                                                )
                                            :   (
                                                    <React.Fragment>
                                                        <div className='w-100 d-flex justify-content-between my-1'>
                                                            <div className='d-flex'>
                                                                <i className='mr-2 fa fa-exclamation-triangle text-warning' />
                                                                <p className='text-warning mb-0'><Resource keyString="email_not_confirmed" /></p>
                                                            </div>
                                                            <button
                                                                className='btn ws-outline-blue'
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    if (!this.emailEhValido()) {
                                                                        document.getElementById('Email')?.classList.add('is-invalid');
                                                                        return;
                                                                    }
                                                                    this.setState({ modalDeSolicitacaoDeConfirmacaoDeEmailAberto: true });
                                                                }}
                                                            >
                                                                <Resource keyString="confirm_email" />
                                                            </button>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                        }
                                    </div>
                                </ValidationLabelMessage>
                            </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-md-6">
                            <label className="control-label col-md-12" htmlFor="cargo"><Resource keyString="job_position" />
                                {
                                    this.state.empData.cargo === null || this.state.empData.cargo === "" &&
                                        <ExclamationCircleIcon fill='#ef0000' className='ml-2' />
                                }
                            </label>
                            <div className="col-md-12">
                                <ValidationLabelMessage errors={this.state.empData.error} name="Cargo">
                                    <select className="form-control" name="cargo" id="cargo" onChange={this.handleSelectChange} value={this.state.empData.cargo}>
                                        <option value="">{this.context.getLanguageResource("select")}</option>
                                        <option value="CEO ou Presidente">{this.context.getLanguageResource("ceo_or_president")}</option>
                                        <option value="Vice-Presidente">{this.context.getLanguageResource("vice_president")}</option>
                                        <option value="Head">{this.context.getLanguageResource("head")}</option>
                                        <option value="Diretor">{this.context.getLanguageResource("director")}</option>
                                        <option value="Gerente Geral">{this.context.getLanguageResource("general_manager")}</option>
                                        <option value="Gerente Nacional">{this.context.getLanguageResource("national_manager")}</option>
                                        <option value="Gerente Regional">{this.context.getLanguageResource("regional_manager")}</option>
                                        <option value="Coordenador">{this.context.getLanguageResource("coordinator")}</option>
                                        <option value="Supervisor">{this.context.getLanguageResource("supervisor")}</option>
                                        <option value="Especialista">{this.context.getLanguageResource("specialist")}</option>
                                        <option value="Trainee">{this.context.getLanguageResource("trainee")}</option>
                                        <option value="Analista">{this.context.getLanguageResource("analyst")}</option>
                                        <option value="Promotor de Vendas">{this.context.getLanguageResource("sales_promoter")}</option>
                                        <option value="Estagiário">{this.context.getLanguageResource("intern")}</option>
                                        <option value="Assistente">{this.context.getLanguageResource("assistant")}</option>
                                    </select>
                                </ValidationLabelMessage>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label className="control-label col-md-12" htmlFor="tipoetamanho"><Resource keyString="type_and_size" />
                                {
                                    this.state.empData.tipoetamanho === null || this.state.empData.tipoetamanho === "" &&
                                        <ExclamationCircleIcon fill='#ef0000' className='ml-2' />
                                }
                            </label>
                            <div className="col-md-12">
                                <ValidationLabelMessage errors={this.state.empData.error} name="Tipoetamanho">
                                    <select className="form-control" name="tipoetamanho" id="tipoetamanho" onChange={this.handleSelectChange} value={this.state.empData.tipoetamanho}>
                                        <option value="">{this.context.getLanguageResource("select")}</option>
                                        <option value="Microempresa">{this.context.getLanguageResource("micro_company")}</option>
                                        <option value="Multinacional de grande porte">{this.context.getLanguageResource("large_multinational")}</option>
                                        <option value="Multinacional de médio porte">{this.context.getLanguageResource("medium_sized_multinational")}</option>
                                        <option value="Multinacional de pequeno porte">{this.context.getLanguageResource("small_sized_multinational")}</option>
                                        <option value="Nacional de grande porte">{this.context.getLanguageResource("large_national")}</option>
                                        <option value="Nacional de médio porte">{this.context.getLanguageResource("medium_sized_national")}</option>
                                        <option value="Nacional de pequeno porte">{this.context.getLanguageResource("small_sized_national")}</option>
                                        <option value="Start-up">{this.context.getLanguageResource("startup")}</option>
                                    </select>
                                </ValidationLabelMessage>
                            </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-md-6">
                            <label className="control-label col-md-12" htmlFor="seguimento"><Resource keyString="company_segment" />
                                {
                                    this.state.empData.seguimento === null || this.state.empData.seguimento === "" &&
                                        <ExclamationCircleIcon fill='#ef0000' className='ml-2' />
                                }
                            </label>
                            <div className="col-md-12">
                                <ValidationLabelMessage errors={this.state.empData.error} name="Seguimento">
                                    <select className="form-control" id="seguimento" name="seguimento" onChange={this.handleSelectChange} value={this.state.empData.seguimento}>
                                        <option value="">{this.context.getLanguageResource("select")}</option>
                                        <option value="Advocacia">{this.context.getLanguageResource("law")}</option>
                                        <option value="Agência de câmbio">{this.context.getLanguageResource("exchange_agency")}</option>
                                        <option value="Agência de viagem">{this.context.getLanguageResource("travel_agency")}</option>
                                        <option value="Agropecuários">{this.context.getLanguageResource("agropecuaries")}</option>
                                        <option value="Área de saúde">{this.context.getLanguageResource("healthcare")}</option>
                                        <option value="Artigos Esportivos">{this.context.getLanguageResource("sporting_goods")}</option>
                                        <option value="Associações">{this.context.getLanguageResource("associations")}</option>
                                        <option value="Atacarejo">{this.context.getLanguageResource("wholesale_retail")}</option>
                                        <option value="Auditoria">{this.context.getLanguageResource("auditing")}</option>
                                        <option value="Automóveis">{this.context.getLanguageResource("automobiles")}</option>
                                        <option value="Bancos & Financeiras">{this.context.getLanguageResource("banks_financial")}</option>
                                        <option value="Bens de Consumo">{this.context.getLanguageResource("consumer_goods")}</option>
                                        <option value="Brinquedos">{this.context.getLanguageResource("toys")}</option>
                                        <option value="Calçados">{this.context.getLanguageResource("footwear")}</option>
                                        <option value="Cama, mesa e banho">{this.context.getLanguageResource("bed_bath_linen")}</option>
                                        <option value="Caminhões">{this.context.getLanguageResource("trucks")}</option>
                                        <option value="Construção Civil">{this.context.getLanguageResource("construction")}</option>
                                        <option value="Consultoria">{this.context.getLanguageResource("consulting")}</option>
                                        <option value="Corretora de Seguros">{this.context.getLanguageResource("insurance_brokerage")}</option>
                                        <option value="Cosméticos">{this.context.getLanguageResource("cosmetics")}</option>
                                        <option value="Decoração">{this.context.getLanguageResource("decoration")}</option>
                                        <option value="Educação">{this.context.getLanguageResource("education")}</option>
                                        <option value="Eletro-eletrônicos">{this.context.getLanguageResource("electro_electronics")}</option>
                                        <option value="Energia">{this.context.getLanguageResource("energy")}</option>
                                        <option value="Family Office">{this.context.getLanguageResource("family_office")}</option>
                                        <option value="Farmácias">{this.context.getLanguageResource("pharmacies")}</option>
                                        <option value="Gráfica">{this.context.getLanguageResource("printing")}</option>
                                        <option value="Higiene e Limpeza">{this.context.getLanguageResource("hygiene_cleaning")}</option>
                                        <option value="Hotelaria">{this.context.getLanguageResource("hospitality")}</option>
                                        <option value="Imobiliária">{this.context.getLanguageResource("real_estate")}</option>
                                        <option value="Máquinas & Equipamentos">{this.context.getLanguageResource("machinery_equipment")}</option>
                                        <option value="Materiais Elétricos">{this.context.getLanguageResource("electrical_materials")}</option>
                                        <option value="Médico-hospitalar">{this.context.getLanguageResource("medical_hospital")}</option>
                                        <option value="Metalurgica">{this.context.getLanguageResource("metallurgy")}</option>
                                        <option value="Minimercado ou mercearia">{this.context.getLanguageResource("minimarket_grocery")}</option>
                                        <option value="Motos">{this.context.getLanguageResource("motorcycles")}</option>
                                        <option value="Motos de Luxo">{this.context.getLanguageResource("luxury_motorcycles")}</option>
                                        <option value="Móveis">{this.context.getLanguageResource("furniture")}</option>
                                        <option value="Oficina Mecânica">{this.context.getLanguageResource("mechanical_workshop")}</option>
                                        <option value="ônibus">{this.context.getLanguageResource("buses")}</option>
                                        <option value="Operador de telecom">{this.context.getLanguageResource("telecom_operator")}</option>
                                        <option value="Óticas">{this.context.getLanguageResource("optics")}</option>
                                        <option value="Outros">{this.context.getLanguageResource("other")}</option>
                                        <option value="Padarias">{this.context.getLanguageResource("bakery")}</option>
                                        <option value="Papelaria&Presentes">{this.context.getLanguageResource("stationery_gifts")}</option>
                                        <option value="Petcenter">{this.context.getLanguageResource("petcenter")}</option>
                                        <option value="Petroquímica">{this.context.getLanguageResource("petrochemical")}</option>
                                        <option value="Pneus">{this.context.getLanguageResource("tires")}</option>
                                        <option value="Posto de Combustível">{this.context.getLanguageResource("fuel_station")}</option>
                                        <option value="Química">{this.context.getLanguageResource("chemical")}</option>
                                        <option value="Representante">{this.context.getLanguageResource("representative")}</option>
                                        <option value="Restaurantes">{this.context.getLanguageResource("restaurants")}</option>
                                        <option value="Roupas">{this.context.getLanguageResource("clothing")}</option>
                                        <option value="Roupas de Luxo">{this.context.getLanguageResource("luxury_clothing")}</option>
                                        <option value="Rural">{this.context.getLanguageResource("rural")}</option>
                                        <option value="Seguradora">{this.context.getLanguageResource("insurance_company")}</option>
                                        <option value="Software & T.I">{this.context.getLanguageResource("software_it")}</option>
                                        <option value="Supermercados">{this.context.getLanguageResource("supermarkets")}</option>
                                        <option value="T.I.">{this.context.getLanguageResource("it")}</option>
                                        <option value="Telecom">{this.context.getLanguageResource("telecom")}</option>
                                        <option value="Terceirização">{this.context.getLanguageResource("outsourcing")}</option>
                                        <option value="Trader">{this.context.getLanguageResource("trader")}</option>
                                        <option value="Transporte e Armazenagem">{this.context.getLanguageResource("transport_storage")}</option>
                                        <option value="Utilidades Domésticas">{this.context.getLanguageResource("household_items")}</option>
                                        <option value="Veterinário">{this.context.getLanguageResource("veterinary")}</option>
                                        <option value="Viagem & Turismo">{this.context.getLanguageResource("travel_tourism")}</option>
                                    </select>
                                </ValidationLabelMessage>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label className="control-label col-md-12" htmlFor="departamento"><Resource keyString="department" />
                                {
                                    this.state.empData.departamento === null || this.state.empData.departamento === "" &&
                                        <ExclamationCircleIcon fill='#ef0000' className='ml-2' />
                                }
                            </label>
                            <div className="col-md-12">
                                <ValidationLabelMessage errors={this.state.empData.error} name="Departamento">
                                    <select className="form-control" id="departamento" name="departamento" onChange={this.handleSelectChange} value={this.state.empData.departamento}>
                                        <option value="">{this.context.getLanguageResource("select")}</option>
                                        <option value="Compras">{this.context.getLanguageResource("purchases")}</option>
                                        <option value="Financeiro">{this.context.getLanguageResource("finance")}</option>
                                        <option value="Logística">{this.context.getLanguageResource("logistics")}</option>
                                        <option value="Marketing">{this.context.getLanguageResource("marketing")}</option>
                                        <option value="Outros">{this.context.getLanguageResource("other")}</option>
                                        <option value="Produção">{this.context.getLanguageResource("production")}</option>
                                        <option value="Recursos Humanos">{this.context.getLanguageResource("human_resources")}</option>
                                        <option value="Tecnologia da Informação">{this.context.getLanguageResource("information_technology")}</option>
                                        <option value="Vendas">{this.context.getLanguageResource("sales")}</option>
                                    </select>
                                </ValidationLabelMessage>
                            </div>
                        </div>
                    </div>



                    <div className="form-group row" >
                        <div className="col-md-6">
                            <label className="control-label col-md-12" htmlFor="NomePropriedade" >{<Resource keyString="property_name" />}</label>
                            <div className="col-md-12">
                                <input className="form-control" type="text" name="NomePropriedade" id="nomePropriedade" onChange={this.handleTextChange} value={this.state.empData.nomePropriedade} />
                            </div>
                        </div>
                        <div className="col-md-6">
                        </div>
                    </div>
                    <div className="card-title mx-3 mt-4">
                        <h6 className="title-section-paged">{<Resource keyString="company_contact" />}</h6>
                        <hr />
                    </div>
                    <div className="form-group row" >
                        <div className="col-md-6">
                            <label className="control-label col-md-12 " htmlFor="Telefone" >{<Resource keyString="phone" />}
                                {
                                    this.state.empData.telefone === null || this.state.empData.telefone === "" &&
                                        <ExclamationCircleIcon fill='#ef0000' className='ml-2' />
                                }
                            </label>
                            <PhoneInput
                                country={'br'}
                                value={this.state.empData.telefone}
                                onChange={(phone) => this.setState({ empData: { ...this.state.empData, telefone: phone } })}
                                preferredCountries={['br', 'us', 'es']}
                                inputClass='w-100'
                                containerClass='ml-3'
                            />
                            <input type="hidden" name="Telefone" id="Telefone" value={this.state.empData.telefone} />
                            <div className="ml-3">
                                <ValidationLabelMessage errors={this.state.empData.error} name="Telefone" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label className="control-label col-md-12 " htmlFor="Celular" >{<Resource keyString="mobile_phone" />}</label>
                            <div className="col-md-12">
                                <PhoneInput
                                    country={'br'}
                                    value={this.state.empData.celular}
                                    onChange={(phone) => this.setState({ empData: { ...this.state.empData, celular: phone } })}
                                    preferredCountries={['br', 'us', 'es']}
                                    inputClass='w-100'
                                />
                                <input type="hidden" name="Celular" id="Celular" value={this.state.empData.celular} />
                                <ValidationLabelMessage errors={this.state.empData.error} name="Celular" />
                                <div className='d-flex flex-column'>
                                    {
                                        this.state.empData.celularconfirmado !== null
                                        ?   (
                                                <div className='w-100 d-flex justify-content-between my-1'>
                                                    <div className='d-flex'>
                                                        <i className='mr-2 fa fa-check-circle text-success' />
                                                        <p className='text-success mb-0'><Resource keyString="confirmed_cell_phone" /></p>
                                                    </div>
                                                </div>
                                            )
                                        :   (
                                                <React.Fragment>
                                                    <div className='w-100 d-flex justify-content-between my-1'>
                                                        <div className='d-flex'>
                                                            <i className='mr-2 fa fa-exclamation-triangle text-warning' />
                                                            <p className='text-warning mb-0'><Resource keyString="unconfirmed_cell_phone" /></p>
                                                        </div>
                                                        <button
                                                            className='btn ws-outline-blue'
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                this.setState({ modalDeSolicitacaoDeConfirmacaoDeCelularAberto: true });
                                                            }}
                                                        >
                                                            <Resource keyString="confirm_mobile" />
                                                        </button>
                                                    </div>
                                                </React.Fragment>
                                            )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-title mx-3 mt-4">
                        <h6 className="title-section-paged">{<Resource keyString="address" />}</h6>
                        <hr />
                    </div>
                    <div className='d-flex w-100 p-3 align-items-end'>
                        <div>
                            <label className="control-label" htmlFor="Cep" >{<Resource keyString="register_zipcode" />}</label>
                            <input className="form-control cep" type="text" name="Cep" id="Cep" value={this.state.empData.cep === null ? "" : this.state.empData.cep} onChange={this.handleCepChange} />
                            <a href="http://www.buscacep.correios.com.br/sistemas/buscacep/" target="_blank" rel="noopener noreferrer" style={{ fontSize: '.8rem', textDecoration: 'underline' }}><Resource keyString="i_do_not_know_my_cep" /></a>
                        </div>
                        <div className='ml-2 mb-1'>
                            {
                                this.state.momentoDeCep === MomentoCEP.Falha && (
                                    <label className="control-label text-danger">{this.state.empData.cepError}</label>
                                )
                            }
                            {
                                this.state.momentoDeCep === MomentoCEP.EmAndamento && (
                                    <i className="fa fa-spinner fa-spin fa-fw" style={{ fontSize: '2rem' }}></i>
                                )
                            }
                            {
                                this.state.momentoDeCep === MomentoCEP.Sucesso && (
                                    <i className="fa fa-check-circle text-success" style={{ fontSize: '2rem' }}></i>
                                )
                            }
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-md-6">
                            <label className="control-label col-md-12 " htmlFor="Endereco" >{<Resource keyString="address" />}</label>
                            <div className="col-md-12">
                                <input className="form-control" type="text" name="Endereco" id="endereco" onChange={this.handleTextChange} value={this.state.empData.endereco} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label className="control-label col-md-12 " htmlFor="Numero" >{<Resource keyString="number" />}</label>
                            <div className="col-md-12">
                                <input className="form-control" type="text" name="Numero" id="Numero" onChange={this.handleNumeroChange} value={this.state.empData.numero} disabled={this.state.semNumeroEstaMarcado} />
                                <div className="d-flex align-items-center">
                                    <input type="checkbox" name="SemNumero" checked={this.state.semNumeroEstaMarcado} onChange={this.handleSemNumeroChange} id="SemNumero" className="mr-2" />
                                    <label className="control-label mb-0" htmlFor="SemNumero" >{<Resource keyString="without_number" />}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group row" >
                        <div className="col-md-6">
                            <label className="control-label col-md-12 " htmlFor="Complemento" >{<Resource keyString="complement" />}</label>
                            <div className="col-md-12">
                                <input className="form-control" type="text" name="Complemento" id="complemento" onChange={this.handleTextChange} value={this.state.empData.complemento} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label className="control-label col-md-12 " htmlFor="Bairro" >{<Resource keyString="neighborhood" />}</label>
                            <div className="col-md-12">
                                <input className="form-control" type="text" name="Bairro" id="bairro" onChange={this.handleTextChange} value={this.state.empData.bairro} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-md-6">
                            <label className="control-label col-md-12 " htmlFor="Cidade" >{<Resource keyString="city" />}</label>
                            <div className="col-md-12">
                                <input className="form-control letters" type="text" name="Cidade" id="cidade" onChange={this.handleTextChange} value={this.state.empData.cidade} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label className="control-label col-md-12 " htmlFor="UF">{<Resource keyString="federated_unit" />}</label>
                            <div className="col-md-12">
                                <input className="form-control letters" type="text" maxLength={2} name="Uf" id="uf" onChange={this.handleTextChange} value={this.state.empData.uf} />
                            </div>
                        </div>
                    </div>

                    
                    <div className="card-title mx-3 mt-4 d-none">
                        <h6 className="title-section-paged">Personalização</h6>
                        <hr />
                    </div>

                    <div className="form-group row d-none">
                        <div className="col-md-6 col-12">
                            <label className="control-label col-md-12 " htmlFor="CorDoWinset" ><Resource keyString="winset_colors" /></label>
                            <div className="col-md-12 d-flex mt-2 align-items-center">
                                <label className="control-label mb-0 col-4" htmlFor="CorDoTopoDaAplicacao" ><Resource keyString="top_of_the_application" /></label>
                                <input className="form-control col-8" type="color" name="CorDoTopoDaAplicacao" id="corDoTopoDaAplicacao" onChange={this.handleChangeInputColor} value={this.state.empData.corDoTopoDaAplicacao} />
                            </div>
                            <div className="col-md-12 d-flex mt-2 align-items-center">
                                <label className="control-label mb-0 col-4" htmlFor="CorPrincipal" ><Resource keyString="main_color" /></label>
                                <input className="form-control col-8" type="color" name="CorPrincipal" id="corPrincipal" onChange={this.handleChangeInputColor} value={this.state.empData.corPrincipal} />
                            </div>
                        </div>
                    </div>

                    <div className="form-group d-flex justify-content-center mb-3 mx-1">
                        <button
                            type="submit"
                            className="btn btn-default font-weight-bold mr-2"
                            style={{ backgroundColor: 'var(--ws-blue)', width: '17.5rem', borderRadius: '0.3rem' }}
                        >
                            <Resource keyString="save" />
                        </button>
                    </div >

                </form >
                {
                    this.state.modalDeSolicitacaoDeConfirmacaoDeEmailAberto && (
                        <ModalWinSet
                            fecharModal={() => this.setState({ modalDeSolicitacaoDeConfirmacaoDeEmailAberto: false })}
                            aoMontarModal={() => this.solicitarCodigoDeConfirmacaoDeEmail()}
                        >
                            <div className='w-100 p-4'>
                                <section>
                                    <h5 className='text-center'>Confirmação de e-mail</h5>
                                    <p className='text-center'><Resource keyString="we_send_an_email_to" /> {this.state.empData.email} <Resource keyString="with_a_confirmation_code" />.</p>
                                    <p className='text-center'><Resource keyString="if_you_have_not_received_the_email_click_the_button_below_to_resend" />.</p>
                                    <div className='d-flex justify-content-center'>
                                        <button
                                            className='btn ws-outline-blue'
                                            onClick={(e) => { e.preventDefault(); this.solicitarCodigoDeConfirmacaoDeEmail() }}
                                        >
                                            <Resource keyString="resend_code" />
                                        </button>
                                    </div>
                                    <hr />
                                </section>
                                <section className='d-flex justify-content-center my-2'>
                                    <input
                                        placeholder='Ex.: LS3J-907P'
                                        type='text'
                                        onChange={this.handleChangeInputConfirmacaoDeEmail}
                                        value={this.state.codigoDeConfirmacaoDeEmail}
                                        className='form-control text-center'
                                    />
                                </section>
                                <section className='d-flex justify-content-center'>
                                    {
                                        this.state.momentoDeConfirmacaoDoEmail === MomentoDeConfirmacao.EmAndamento
                                            ?   (
                                                    <div style={{ height: '3rem' }}>
                                                        <div className='spinner-border text-primary' role='status'>
                                                            <span className='sr-only'>Loading...</span>
                                                        </div>
                                                    </div>
                                                )
                                            :   (
                                                    <div style={{ height: '3rem', fontSize: '2rem' }}>
                                                        {
                                                            MomentoDeConfirmacao.Pendente === this.state.momentoDeConfirmacaoDoEmail && <React.Fragment />
                                                        }
                                                        {
                                                            MomentoDeConfirmacao.Sucesso === this.state.momentoDeConfirmacaoDoEmail && <i className='bi bi-check-circle-fill text-success' />
                                                        }
                                                        {
                                                            MomentoDeConfirmacao.Falha === this.state.momentoDeConfirmacaoDoEmail && <i className='bi bi-x-circle-fill text-danger' />
                                                        }
                                                    </div>
                                                )
                                    }
                                </section>
                            </div>
                        </ModalWinSet>
                    )
                }
                {
                    this.state.modalDeSolicitacaoDeConfirmacaoDeCelularAberto && (
                        <ModalWinSet
                            fecharModal={() => this.setState({ modalDeSolicitacaoDeConfirmacaoDeCelularAberto: false })}
                            aoMontarModal={() => this.solicitarCodigoDeConfirmacaoDeCelular()}
                        >
                            <div className='w-100 p-4'>
                                <section>
                                    <h5 className='text-center'><Resource keyString="cellphone_confirmation" /></h5>
                                    <p className='text-center'><Resource keyString="we_send_a_SMS_to" /> {this.state.empData.celular} <Resource keyString="with_a_confirmation_code" />.</p>
                                    <p className='text-center'><Resource keyString="if_you_have_not_received_the_sms_with_the_code_click_the_button_below_to_resend" />.</p>
                                    <div className='d-flex justify-content-center'>
                                        <button
                                            className='btn ws-outline-blue'
                                            onClick={(e) => { e.preventDefault(); this.solicitarCodigoDeConfirmacaoDeCelular() }}
                                        >
                                            <Resource keyString="resend_code" />
                                        </button>
                                    </div>
                                    <hr />
                                </section>
                                <section className='d-flex justify-content-center my-2'>
                                    <input
                                        placeholder='Ex.: 290-796'
                                        type='text'
                                        onChange={this.handleChangeInputConfirmacaoDeCelular}
                                        value={this.state.codigoDeConfirmacaoDeCelular}
                                        className='form-control text-center'
                                    />
                                </section>
                                <section className='d-flex justify-content-center'>
                                    {
                                        this.state.momentoDeConfirmacaoDoCelular === MomentoDeConfirmacao.EmAndamento
                                            ?   (
                                                    <div style={{ height: '3rem' }}>
                                                        <div className='spinner-border text-primary' role='status'>
                                                            <span className='sr-only'>Loading...</span>
                                                        </div>
                                                    </div>
                                                )
                                            :   (
                                                    <div style={{ height: '3rem', fontSize: '2rem' }}>
                                                        {
                                                            MomentoDeConfirmacao.Pendente === this.state.momentoDeConfirmacaoDoCelular && <React.Fragment />
                                                        }
                                                        {
                                                            MomentoDeConfirmacao.Sucesso === this.state.momentoDeConfirmacaoDoCelular && <i className='bi bi-check-circle-fill text-success' />
                                                        }
                                                        {
                                                            MomentoDeConfirmacao.Falha === this.state.momentoDeConfirmacaoDoCelular && <i className='bi bi-x-circle-fill text-danger' />
                                                        }
                                                    </div>
                                                )
                                    }
                                </section>
                            </div>
                        </ModalWinSet>
                    )
                }
            </CaixaDeConteudo>
        );
    }

    private handleSave(event) {
        event.preventDefault();
        if (!this.emailEhValido()) {
            const inputEmail = document.getElementById('Email');
            inputEmail?.focus();
            inputEmail?.classList.add('is-invalid');
            return;
        }

        const campoConfirmacaoDeEmail = document.getElementById('emailconfirmado') as HTMLInputElement;
        const campoConfirmacaoDeCelular = document.getElementById('celularconfirmado') as HTMLInputElement;

        if (campoConfirmacaoDeEmail !== null && campoConfirmacaoDeEmail.value.length !== 0) {
            campoConfirmacaoDeEmail.setAttribute('value', this.state.empData.emailconfirmado as string);
        }

        if (campoConfirmacaoDeCelular !== null && campoConfirmacaoDeEmail.value.length !== 0) {
            campoConfirmacaoDeCelular.setAttribute('value', this.state.empData.celularconfirmado as string);
        }
        
        HttpService.fetch('api/EmpresasApi/MinhaEmpresa', {
            method: 'PUT',
            body: HttpService.formToJson(event.target),
        }).then((response) => {
            return response.json() as Promise<EmpresaData>
        }).then((data) => {
            Loading.showLoading(this);

            this.setState({ empData: data })
            if ($.isEmptyObject(data.error)) {
                window.location.href = '/minhaEmpresa';
            }
            Loading.hideLoading(this);
        }).catch(() => {
            Loading.hideLoading(this);
        })
    }
}
