import * as React from 'react';
import { IPergunta } from '../../interfaces/IResponderFormularioResponse';
import CardDeImagemParaUpload from '../CardDeImagemParaUpload';
import { ResponderFormularioContext } from '../../contexts/ResponderFormularioContext';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import ChevronDownIcon from '../../icons/ChevronDownIcon';
import ChevronUpIcon from '../../icons/ChevronUpIcon';
import { LanguageContext } from '../../../../../context/LanguageContext';

const { useState, useContext } = React;

interface IProps {
	pergunta: IPergunta;
};

const CampoDeAnexoDeImagens = ({ pergunta }: IProps) => {
	const { mostrarTodosOsCollapses } = useContext(ResponderFormularioContext);
    const { getLanguageResource } = useContext(LanguageContext);

    const [isOpen, setIsOpen] = useState(true);

	useDidUpdateEffect(() => setIsOpen(mostrarTodosOsCollapses), [mostrarTodosOsCollapses]);

    return (
        <div className='col-12 mb-4' style={ { maxHeight: '350px' } }>
            <div className={`card ${isOpen && 'h-100'}`}  style={{ border: '1px solid var(--ws-blue-border)' }}>
                {/* <div
                    className={`d-flex justify-content-between p-2 ${isOpen ? 'rounded-top' : 'rounded'}`}
                    onClick={() => setIsOpen(!isOpen)}
                    style={{ cursor: 'pointer', backgroundColor: 'var(--ws-blue-light)' }}
                >
                    <div>
                        <span>{ getLanguageResource('photos_through_the_app') }</span>
                    </div>
                    <div>
                        {
                            isOpen
                                ? <ChevronDownIcon className='ml-2' fill='#000' />
                                : <ChevronUpIcon className='ml-2' fill='#000' />
                        }
                    </div>
                </div> */}
                <div className={`card-body barra_de_rolagem_estilizada ${isOpen ? '' : 'd-none'}`} style={{ overflowY: 'auto', height: '100%' }}>
                    <div className='row justify-content-around h-100'>
						{
							pergunta.imagens.filter(imagem => imagem.status !== 'Removido').length > 0
								? pergunta.imagens.filter(imagem => imagem.status !== 'Removido').map(imagem => <CardDeImagemParaUpload anexoDeImagem={imagem} idDaPergunta={pergunta.id} />)
								: <div className='text-center'>Nenhuma imagem anexada</div>
						}
					</div>
                </div>
            </div>
        </div>
    );
};

export default CampoDeAnexoDeImagens;
