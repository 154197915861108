import styled from 'styled-components';

const MainHolder = styled.main`
    margin-top: 4.75rem;
    width: ${props =>
        props.isSidebarOpened
            ? 'calc(100vw - var(--ws-navbar-width-aberta) - 3rem)'
            : 'calc(100vw - var(--ws-navbar-width-fechada) - 3rem)'};
    transition: width 0.3s ease-in-out;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

export default MainHolder;
