import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { Layout } from '../../Layout';
import { HttpService } from '../../Shared/HttpService';
import { HeadLayout } from '../../Shared/HeadLayout';
import { Loading } from '../../Shared/Loading';
import { ModalDelete } from '../../Shared/ModalDelete';
import { Resource } from '../../../index';
import { FormularioData } from '../Formulario/FetchFormulario';
import { LanguageContext } from '../../../context/LanguageContext';

interface FetchConfiguracaoFormularioState {
    configuracaoFormularioList: ConfiguracaoFormularioData[];
    formulario: FormularioData;
    formularioid: number;
    loading: boolean;
}

export class ConfiguracaoFormularioData {
    id: number = 0;
    nome: string = "";
    formularioid: number = 0;
    error: Array<any> = [];
}

export class FetchConfiguracaoFormulario extends React.Component<RouteComponentProps<{}>, FetchConfiguracaoFormularioState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        var formularioid = this.props.match.params["formularioid"];

        var formulario = new FormularioData;
        formulario.id = formularioid;

        this.state = { configuracaoFormularioList: [], loading: true, formulario: formulario, formularioid: formularioid };

        Loading.showLoading(this);
        HttpService.fetch('api/ConfiguracaoFormularioApi?formularioId=' + formularioid, { method: 'GET'})
            .then(response => response.json() as Promise<ConfiguracaoFormularioData[]>)
            .then(data => {
                this.setState({ configuracaoFormularioList: data, loading: false });
                Loading.hideLoading(this);
            });

        this.handleDelete = this.handleDelete.bind(this);
        this.handleEdit = this.handleEdit.bind(this);

    }

    public render() {
        let contents = Loading.isLoading(this)
            ? <p><em></em></p>
            : this.renderUserTable(this.state.configuracaoFormularioList);

        return <div className="col-lg-12">
            <HeadLayout title={this.context.getLanguageResource("form_configuration")} />
            <div className="card">
                <div className="card-header"><a href="/">{<Resource keyString="home" />}</a> / <a href="/marketPlace">{<Resource keyString="form_store" />}</a> / {<Resource keyString="form_configuration" />}</div>
                <div className="card-body">
                    <div className="card-title">
                        <button className="btn btn-link mr-2 pull-left"
                            onClick={(x) => this.props.history.push(`/marketPlace/detail/${this.state.formularioid}`)}>
                            <i className='fa fa-arrow-left'></i> {<Resource keyString="back" />}
                        </button>
                        <h3 className="text-center title-2">{<Resource keyString="form_configuration" />}
                            <Link to={(`/configuracaoFormulario/add/${this.state.formulario.id}`)} className="action btn btn-primary pull-right" data-loading-text="<i className='fa fa-spinner fa-spin '></i> Processing Order"><i className='fa fa-plus'></i> {<Resource keyString="add" />}</Link></h3>
                    </div>
                    <hr />
                    {contents}
                </div>
            </div>
        </div>;
    }

    // Handle Delete request for an user  
    private handleDelete(id: number) {
        Loading.showLoading(this);
        HttpService.fetch('api/ConfiguracaoFormularioApi/' + id, {
            method: 'delete'
        }).then(data => {
            this.setState(
                {
                    configuracaoFormularioList: this.state.configuracaoFormularioList.filter((rec) => {
                        return (rec.id != id);
                    })
                });
            Loading.hideLoading(this);
        }).catch(() => {
            Loading.hideLoading(this);
        });
    }

    private handleEdit(formularioid: number, id: number) {
        this.props.history.push("/configuracaoFormulario/edit/" + formularioid + "/" + id);
    }

    // Returns the HTML table to the render() method.  
    private renderUserTable(configuracaoFormularioList: ConfiguracaoFormularioData[]) {
        return <table className='table'>
            <thead>
                <tr>
                    <th></th>
                    <th>{<Resource keyString="name" />}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {configuracaoFormularioList.map(doc =>
                    <tr key={doc.id}>
                        <td></td>
                        <td>{doc.nome}</td>
                        <td>
                            <button className="btn btn-outline-danger mr-2 pull-right" data-toggle="modal" data-target={`#delete${doc.id}`}><i className='fa fa-trash'></i> {<Resource keyString="delete" />}</button>
                            <button className="btn btn-outline-success mr-2 pull-right" onClick={(id) => this.handleEdit(doc.formularioid, doc.id)}><i className='fa fa-edit'></i> {<Resource keyString="edit" />}</button>
                            <ModalDelete id={doc.id} callBack={this} message={`${this.context.getLanguageResource("do_you_want_to_remove_the_form_configuration")}: ${doc.nome}?`} />
                        </td>
                    </tr>
                )}
            </tbody>
        </table>;
    }
}   