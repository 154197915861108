import React from "react";
import styled from "styled-components";

interface IProps {
    quantidade: number;
    texto: string;
}

const StyledDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    width: calc(20% - 1rem);
    margin-top: -1.5rem;

    span:first-child {
        font-weight: 700;
        margin-right: .5rem;
    }

    span {
        font-size: 1rem;
    }

    @media (min-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: calc(20% - 1rem);
        margin-top: -1.5rem;
        text-align: center;

        span:first-child {
            font-size: 2rem;
            font-weight: 400;
        }
        
        span {
            margin-right: 0rem;
        }
    }
`;

const TextoDosCardsDeContagem = ({ quantidade, texto }: IProps) => {
    return (
        <StyledDiv>
            <span>{ quantidade }</span>
            <span>{ texto }</span>
        </StyledDiv>
    );
}

export default TextoDosCardsDeContagem;
