// esse input deve validar seu valor ao atualizar
import React, { useEffect } from "react";
import { ValidationLabelMessage } from "../../Shared/ValidationMessage";
import validarCNPJ from "../../../helpers/validarCNPJ";

interface IProps {
    error: any;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value: string;
}

export const InputCNPJ = ({error, onChange, value}: IProps) => {
    useEffect(() => {
        const input = document.getElementById("Documento") as HTMLInputElement;
        input.classList.remove("is-invalid");
        
        let CNPJ = value ? value.replace(/\D/g, '') : "";
        
        if (CNPJ.length > 14) CNPJ = CNPJ.slice(0, 14);
        if (CNPJ.length === 14 && !validarCNPJ(CNPJ)) {
            input.classList.add("is-invalid");
            input.focus();
        }
    }, [value]);

    return (
        <ValidationLabelMessage errors={error} name="Documento">
            <input className="form-control" type="text" name="Documento" id="Documento" value={value} onChange={onChange} />
        </ValidationLabelMessage>
    );
};