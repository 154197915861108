function formatarTokenConfirmacaoDeCelular(codigo: string): string {
    codigo = codigo.replace(/[^0-9]/g, '');
    codigo = codigo.toUpperCase();
    codigo = codigo.substring(0, 6);
    if (codigo.length >= 3) {
        codigo = codigo.substring(0, 3) + '-' + codigo.substring(3, 6);
    }
    return codigo;
}

export default formatarTokenConfirmacaoDeCelular;