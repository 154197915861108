import * as React from "react";
import { Resource } from "../../../../../index";
import HeaderDeCollapseEspecifico from "../HeaderDeCollapseEspecifico";
import ContagemDeResposta from "../ContagemDeResposta";
import CabecalhoResultado from "../CabecalhoResultado";
import ContainerDeGruposDePerguntas from "../ContainerDeGruposDePerguntas";
import PlanoAcaoFormulario from "../PlanoAcaoFormulario";
import ResultadoAgrupadoPorGrupo from "../ResultadoAgrupadoPorGrupo";
import retornarInteriorRecursivo from "./helpers/retornarInteriorRecursivo";

const ConteudoDeResultadoPadrao = ({ resultadoDoFormulario }) => {
    const gruposESubgrupos = retornarInteriorRecursivo(
        resultadoDoFormulario.listaGrupoPergunta,
        'listaDeGrupoDePerguntaFilho',
    );

    return (
        <React.Fragment>
            <div className="w-100">

                <div className="border rounded my-3">
                    <div className="d-flex flex-column flex-md-row justify-content-between p-4">
                        <section className="mb-4">
                            <p className="mb-0">{resultadoDoFormulario.formulario.nome}</p>
                            <small>
                                {
                                    resultadoDoFormulario.formularioIniciado.titulo != null
                                        ? resultadoDoFormulario.formularioIniciado.titulo
                                        : resultadoDoFormulario.formularioIniciado.hash
                                }
                            </small>
                        </section>

                        <section className="d-flex flex-column flex-md-row">
                            {
                                resultadoDoFormulario.resultadoFormulario.notaPelaFormulaResultado
                                    ? (
                                        <div className="m-4 p-3 d-flex align-items-center justify-content-center flex-column rounded" style={{ boxShadow: '0 0 0.4rem var(--ws-blue-soft)' }}>
                                            <h2>{resultadoDoFormulario.resultadoFormulario.notaPelaFormulaResultado}</h2>
                                            <p className="mb-0">Resultado Calculado</p>
                                        </div>
                                    )
                                    : <React.Fragment />
                            }

                            <div className="m-4 p-3 d-flex align-items-center justify-content-center flex-column rounded" style={{ boxShadow: '0 0 0.4rem var(--ws-blue-soft)' }}>
                                <h2>
                                    {
                                        resultadoDoFormulario.resultadoFormulario.pontuacaototal > 0
                                            ? (
                                                <React.Fragment>
                                                    {resultadoDoFormulario.resultadoFormulario.valor}/
                                                    {resultadoDoFormulario.resultadoFormulario.pontuacaototal}
                                                </React.Fragment>
                                            )
                                            : 0
                                    }
                                </h2>
                                <p className={`mb-0 ${resultadoDoFormulario.resultadoFormulario.situacaopontos ? "text-success" : "text-danger"}`}>
                                    <i
                                        className={resultadoDoFormulario.resultadoFormulario.situacaopontos
                                            ? "fa fa-check-circle text-success mr-2"
                                            : "fa fa-times-circle text-danger mr-2"
                                        }
                                    />
                                    {
                                        resultadoDoFormulario.resultadoFormulario.situacaopontos
                                            ? <Resource keyString="approved" />
                                            : <Resource keyString="disapproved" />
                                    }
                                </p>
                                <p className="mb-0">
                                    <Resource keyString="punctuation_tolerated" />: {resultadoDoFormulario.resultadoFormulario.toleranciapontos}
                                </p>
                            </div>

                            <div className="m-4 p-3 d-flex align-items-center justify-content-center flex-column rounded" style={{ boxShadow: '0 0 0.4rem var(--ws-blue-soft)' }}>
                                <h2>{resultadoDoFormulario.resultadoFormulario.valorporcentagem.toFixed(2)}%</h2>
                                <p className={`mb-0 ${resultadoDoFormulario.resultadoFormulario.situacao ? "text-success" : "text-danger"}`}>
                                    <i className={resultadoDoFormulario.resultadoFormulario.situacao ? "fa fa-check-circle text-success mr-2" : "fa fa-times-circle text-danger mr-2"} />
                                    {
                                        resultadoDoFormulario.resultadoFormulario.situacao
                                            ? <Resource keyString="approved" />
                                            : <Resource keyString="disapproved" />
                                    }
                                </p>
                                <p className="mb-0">
                                    <Resource keyString="percentage_tolerated" />: {resultadoDoFormulario.resultadoFormulario.toleranciaporcentagem}%
                                </p>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <hr />

			<div className={`d-flex align-items-stretch border flex-column rounded p-4 my-3 ${resultadoDoFormulario.resultadoXCabecalho.filter(cabecalho => !cabecalho.rodape) <= 0 ? '_IMPRIMIR_OMITIR_' : ''}`}>
                <CabecalhoResultado resultadoComCabecalho={resultadoDoFormulario.resultadoXCabecalho.filter(cabecalho => !cabecalho.rodape)} />
            </div>

            <hr />

            <div className="mb-3">
                <HeaderDeCollapseEspecifico alvo={"collapse-oi"} resource={"results_by_question_groups"} />
                <table className="table collapseContent border-0 p-0 m-0 d-none" id={`collapse-oi`}>
                    <thead>
                        <tr>
                            <th className='border-0'>{<Resource keyString="group_questions" />}</th>
                            <th className='border-0'>{<Resource keyString="punctuation" />}</th>
                            <th className='border-0'>{<Resource keyString="total_score" />}</th>
                            <th className='border-0'>{<Resource keyString="percentage" />}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            gruposESubgrupos
                                .filter(doc => doc.resultadoAgrupadoPorGrupo !== null)
                                .map(
                                    (doc, index) => (
                                        <ResultadoAgrupadoPorGrupo
                                            key={`resultado_agrupado_por_grupo_${doc.id}`}
                                            doc={doc}
                                            indexDoGrupo={index}
                                        />
                                    )
                                )
                        }
                    </tbody>
                </table>
            </div>

            <div className="mb-3">
                <HeaderDeCollapseEspecifico alvo="collapse-contagem-resposta" resource="response_count" />
                <ContagemDeResposta respostas={resultadoDoFormulario.resultadoFormulario.consolidadoResposta} id="collapse-contagem-resposta" />
            </div>

            <hr />

            <ContainerDeGruposDePerguntas
                listaDeGruposDePerguntas={resultadoDoFormulario.listaGrupoPergunta}
                resultadoDoFormulario={resultadoDoFormulario}
            />

            {
                resultadoDoFormulario.resultadoPlanoAcao != null && resultadoDoFormulario.resultadoPlanoAcao.some((planoAcao) => planoAcao.perguntaid === null)
                    ? (
                        <PlanoAcaoFormulario
                            resultadoPlanoAcao={resultadoDoFormulario.resultadoPlanoAcao.filter(planoAcao => planoAcao.perguntaid === null)}
                        />
                    )
                    : <React.Fragment />
            }

            <hr />

			<div className={`d-flex align-items-stretch border flex-column rounded p-4 my-3 ${resultadoDoFormulario.resultadoXCabecalho.filter(cabecalho => cabecalho.rodape) <= 0 ? '_IMPRIMIR_OMITIR_' : ''}`}>
                <CabecalhoResultado resultadoComCabecalho={resultadoDoFormulario.resultadoXCabecalho.filter(cabecalho => cabecalho.rodape)} rodape />
            </div>
        </React.Fragment>

    );
};

export default ConteudoDeResultadoPadrao;
