import React, { useState, useContext, useEffect } from 'react';
import CampoDeCabecalho from './components/CampoDeCabecalho';
import BotaoComIconePadrao from './components/BotaoComIconePadrao';
import QuestionarioRecursivo from './components/QuestionarioRecursivo';
import { ResponderFormularioContext, ResponderFormularioContextProvider } from './contexts/ResponderFormularioContext';
import { criarArrayDePonteirosDasPerguntas, criarArrayDePonteirosDosGruposDePerguntas } from './utils/geradoresDeArrayDePonteiros';
import { ICabecalho, ICampoDeAcao, IPergunta, IRespostaPossivel, IRodape } from './interfaces/IResponderFormularioResponse';
import ModalDeCompartilhamento from './components/ModalDeCompartilhamento';
import MensagensDeAlerta from './components/MensagensDeAlerta';
import Carregando from './components/Carregando';
import UiRadiosIcon from './icons/UiRadiosIcon';
import ModalDeConfirmacao from './components/ModalDeConfirmacao';
import { LanguageContext } from '../../../context/LanguageContext';
import CaixaDeConteudo from '../../Shared/CaixaDeConteudo';
import ModalWinSet from '../../Shared/components/ModalWinSet';
import { CircleCheck, Download, FileDown, FileUp, Import, TriangleAlert } from 'lucide-react';
import { HttpService } from 'components/Shared/HttpService';
import { Loading } from 'components/Shared/Loading';
import { set } from 'date-fns';
import { GlobalContext } from 'context/GlobalContext';

enum MomentoImportacaoExportacao {
    Decidir,
    Importar,
    Exportar,
}

const NovoResponderFormularioContent = ({ params }) => {
    const { getLanguageResource } = useContext(LanguageContext)
    const { afiliacao } = useContext(GlobalContext);
    const [modalDeCompartilhamentoAberto, setModalDeCompartilhamentoAberto] = useState(false);
    const [modalDeConfirmacaoAberto, setModalDeConfirmacaoAberto] = useState(false);
    const [elementoNaoRespondidoSelecionado, setElementoNaoRespondidoSelecionado] = useState(-1);
    const [errosDeImportacao, setErrosDeImportacao] = useState([] as string[]);
    const [arquivoXLSXSelecionadoParaImportacao, setArquivoXLSXSelecionadoParaImportacao] = useState(null as FormData | null);
    const [mensagemDeImportacaoConcluidaComSucesso, setMensagemDeImportacaoConcluidaComSucesso] = useState(false);
    const [modalDeImportacaoEExportacaoAberto, setModalDeImportacaoEExportacaoAberto] = useState(false);
    const [momentoDoModalDeImportacaoEExportacao, setMomentoDoModalDeImportacaoEExportacao] = useState(MomentoImportacaoExportacao.Decidir);
    const {
        responderFormulario,
        setResponderFormulario,
        setUltimaTentativaDeFinalizar,
        finalizarFormulario,
        salvarFormulario,
        setListaDeMensagensDeAlerta,
        isLoading,
        mostrarTodosOsCollapses,
        setMostrarTodosOsCollapses,
        setIdsDosCollapsesQueVaoSerAbertos,
        responderFormularioOriginal,
        salvarFormularioPosImportacao,
    } = useContext(ResponderFormularioContext);

    const verificarSeOFormularioFoiCompletamenteRespondido = () => {
        const perguntas = criarArrayDePonteirosDasPerguntas(responderFormulario);

        const cabecalhosNaoRespondidos: ICabecalho[] = [];
        const perguntasNaoRespondidas: IPergunta[] = [];
        const perguntasComObservacoesObrigatoriasNaoRespondidas: Array<[IPergunta, IRespostaPossivel]> = [];
        const perguntasComAnexoDeEvidenciaObrigatoriasSemAnexo: Array<[IPergunta, IRespostaPossivel]> = [];
        const perguntasComPlanoDeAcaoObrigatorioSemPreenchimento: Array<[IPergunta, IRespostaPossivel, ICampoDeAcao[]]> = [];
        const acoesComCamposObrigatoriosNaoPreenchidos: Array<[IPergunta, ICampoDeAcao[]]> = [];
        const rodapesNaoRespondidos: IRodape[] = [];

        responderFormulario.cabecalhos.forEach(cabecalho => {
            if (cabecalho.cabecalhoObrigatorio && !cabecalho.valor) {
                cabecalhosNaoRespondidos.push(cabecalho);
            }
        });

        perguntas.forEach(pergunta => {
            const respostaSelecionada = pergunta.respostas.find(respostaPossivel => respostaPossivel.respostaFoiSelecionada);
            if (!respostaSelecionada) perguntasNaoRespondidas.push(pergunta);
        });

        perguntas.forEach(pergunta => {
            const respostaSelecionada = pergunta.respostas.find(respostaPossivel => respostaPossivel.respostaFoiSelecionada);
            if (
                respostaSelecionada &&
                respostaSelecionada.observacaoObrigatoria &&
                (pergunta.observacao === "" && pergunta.observacao.length === 0)
            ) {
                perguntasComObservacoesObrigatoriasNaoRespondidas.push([pergunta, respostaSelecionada]);
            }
        });

        perguntas.forEach(pergunta => {
            const respostaSelecionada = pergunta.respostas.find(respostaPossivel => respostaPossivel.respostaFoiSelecionada);
            if (
                respostaSelecionada &&
                respostaSelecionada.anexarEvidenciaObrigatoria &&
                pergunta.evidenciaAnexada.filter(x => x.status != "Removido").length === 0
            ) {
                perguntasComAnexoDeEvidenciaObrigatoriasSemAnexo.push([pergunta, respostaSelecionada]);
            }
        });

        perguntas.forEach(pergunta => {
            const respostaSelecionada = pergunta && pergunta.respostas.find((resposta) => resposta.respostaFoiSelecionada);
            if (pergunta.acoes[0] === undefined) return;

            const algumCampoDeAcaoEstaPreenchido = pergunta.acoes[0].campoDasAcoes
                .filter(campoDeAcao => campoDeAcao.ativo)
                .some(campoDeAcao => campoDeAcao.valor);

            if (
                respostaSelecionada &&
                respostaSelecionada.planoDeAcaoObrigatoria &&
                !algumCampoDeAcaoEstaPreenchido &&
                pergunta.acoes[0].campoDasAcoes.length > 0
            ) {
                perguntasComPlanoDeAcaoObrigatorioSemPreenchimento.push([pergunta, respostaSelecionada, pergunta.acoes[0].campoDasAcoes]);
            }
        });

        perguntas.forEach(pergunta => {
            const acaoDaPergunta = pergunta.acoes[0];
            if (acaoDaPergunta === undefined) return;

            const algumCampoDeAcaoEstaPreenchido = acaoDaPergunta.campoDasAcoes.some((campoDeAcao) => !!campoDeAcao.valor);
            const algumCampoDeAcaoEObrigatorio = acaoDaPergunta.campoDasAcoes.some((campoDeAcao) => campoDeAcao.obrigatorio);

            acaoDaPergunta.campoDasAcoes.forEach((campoDeAcao) => {
                if (algumCampoDeAcaoEstaPreenchido && algumCampoDeAcaoEObrigatorio) {
                    if (!campoDeAcao.valor && campoDeAcao.ativo && campoDeAcao.obrigatorio) {
                        acoesComCamposObrigatoriosNaoPreenchidos.push([pergunta, acaoDaPergunta.campoDasAcoes]);
                    }
                }
            });
        });

        responderFormulario.rodapes.forEach(rodape => {
            if (rodape.cabecalhoObrigatorio && !rodape.valor) {
                rodapesNaoRespondidos.push(rodape);
            }
        });

        return {
            camposObrigatoriosSemPreenchimento:
                cabecalhosNaoRespondidos.length
                + perguntasNaoRespondidas.length
                + perguntasComObservacoesObrigatoriasNaoRespondidas.length
                + perguntasComAnexoDeEvidenciaObrigatoriasSemAnexo.length
                + perguntasComPlanoDeAcaoObrigatorioSemPreenchimento.length
                + acoesComCamposObrigatoriosNaoPreenchidos.length
                + rodapesNaoRespondidos.length,

            cabecalhosNaoRespondidos,
            perguntasNaoRespondidas,
            perguntasComObservacoesObrigatoriasNaoRespondidas,
            perguntasComAnexoDeEvidenciaObrigatoriasSemAnexo,
            perguntasComPlanoDeAcaoObrigatorioSemPreenchimento,
            acoesComCamposObrigatoriosNaoPreenchidos,
            rodapesNaoRespondidos,
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setResponderFormulario({ ...responderFormulario, [name]: value });
    };

    const handleBack = () => {
        const auth = localStorage.getItem("auth") as string;
        const tipoDeConta = JSON.parse(auth).tipodeconta;
        if (tipoDeConta === 1) {
            return window.location.href = `/marketPlace/detail/${responderFormulario.formularioId}`;
        }
        return window.location.href = '/';
    }

    const handleAplicarRespostaPadrao = () => {
        const responderFormularioAtualizado = { ...responderFormulario };
        const perguntas = criarArrayDePonteirosDasPerguntas(responderFormularioAtualizado);

        perguntas.forEach(pergunta => {
            const respostaJaSelecionada = pergunta.respostas.find(respostaPossivel => respostaPossivel.respostaFoiSelecionada);

            if (!respostaJaSelecionada) {
                pergunta.respostas.forEach(respostaPossivel => {
                    if (respostaPossivel.respostaPadrao === respostaPossivel.nomeDaResposta) {
                        respostaPossivel.respostaFoiSelecionada = true;
                        pergunta.respostaSelecionada = respostaPossivel.nomeDaResposta;
                        pergunta.respostaSelecionadaId = respostaPossivel.id;
                    }
                });
            }
        });

        setResponderFormulario(responderFormularioAtualizado);
    }

    const handleSave = async (event) => {
        event.preventDefault();
        setListaDeMensagensDeAlerta([]);
        salvarFormulario();
    };

    const handleFinish = async (event) => {
        event.preventDefault();
        setListaDeMensagensDeAlerta([]);
        const listaDeMensagensDeErro: string[] = [];
        const {
            camposObrigatoriosSemPreenchimento,
            cabecalhosNaoRespondidos,
            perguntasNaoRespondidas,
            perguntasComAnexoDeEvidenciaObrigatoriasSemAnexo,
            perguntasComObservacoesObrigatoriasNaoRespondidas,
            perguntasComPlanoDeAcaoObrigatorioSemPreenchimento,
            rodapesNaoRespondidos,
            acoesComCamposObrigatoriosNaoPreenchidos,
        } = verificarSeOFormularioFoiCompletamenteRespondido();

        if (cabecalhosNaoRespondidos.length > 0) listaDeMensagensDeErro.push('respondaOCabecalho');
        if (perguntasNaoRespondidas.length > 0) listaDeMensagensDeErro.push('respondaTodasAsPerguntasAntesDeFinalizar');
        if (perguntasComAnexoDeEvidenciaObrigatoriasSemAnexo.length > 0) listaDeMensagensDeErro.push('anexeAsEvidenciasObrigatorias');
        if (perguntasComObservacoesObrigatoriasNaoRespondidas.length > 0) listaDeMensagensDeErro.push('respondaTodasAsEvidenciasDeAuditoria');
        if (rodapesNaoRespondidos.length > 0) listaDeMensagensDeErro.push('respondaORodape');
        if (perguntasComPlanoDeAcaoObrigatorioSemPreenchimento.length > 0) listaDeMensagensDeErro.push('respondaOsPlanosDeAcao');
        if (acoesComCamposObrigatoriosNaoPreenchidos.length > 0) listaDeMensagensDeErro.push('respondaOsCamposObrigatoriosDasAcoesIniciadas');

        setUltimaTentativaDeFinalizar(new Date());
        setListaDeMensagensDeAlerta([...listaDeMensagensDeErro]);

        if (camposObrigatoriosSemPreenchimento > 0) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return;
        } else {
            setModalDeConfirmacaoAberto(true);
        }
    };

    const handleProximaPergunta = () => {
        const classeDeBotaoNaoMarcado = '.botao-de-resposta-desmarcado';
        const todosOsGruposDeRespostas = Array.from(document.querySelectorAll('.grupo-de-respostas.btn-group-vertical'));
        const gruposDeRespostasNaoRespondidos = todosOsGruposDeRespostas.filter(grupoDeRespostas => grupoDeRespostas.childElementCount === grupoDeRespostas.querySelectorAll(classeDeBotaoNaoMarcado).length);
        const arrayDeGrupoDePerguntasQueVaoTerSeuCollapseAberto: Number[] = [];

        const enviarIdDosGruposDePerguntasParaOContexto = (elGrupoPerguntas) => {
            const idDoGrupoDePerguntas = Number(elGrupoPerguntas?.id.slice(elGrupoPerguntas?.id.lastIndexOf('-') + 1));
            if (idDoGrupoDePerguntas) arrayDeGrupoDePerguntasQueVaoTerSeuCollapseAberto.push(idDoGrupoDePerguntas);
            const gruposDePerguntasExistentes = criarArrayDePonteirosDosGruposDePerguntas(responderFormulario);
            const grupoDePerguntas = gruposDePerguntasExistentes.find(gdp => gdp.id === idDoGrupoDePerguntas);

            if (grupoDePerguntas?.grupoPerguntaPaiId) {
                enviarIdDosGruposDePerguntasParaOContexto(elGrupoPerguntas?.parentElement?.parentElement);
            }
        };

        let proximoGrupoDeBotoesSemResposta = gruposDeRespostasNaoRespondidos[0];

        if (elementoNaoRespondidoSelecionado >= todosOsGruposDeRespostas.length - 1) setElementoNaoRespondidoSelecionado(todosOsGruposDeRespostas.findIndex(gdr => gdr === proximoGrupoDeBotoesSemResposta));

        for (let i = elementoNaoRespondidoSelecionado + 1; i < todosOsGruposDeRespostas.length; i++) {
            if (todosOsGruposDeRespostas[i].childElementCount === todosOsGruposDeRespostas[i].querySelectorAll(classeDeBotaoNaoMarcado).length) {
                setElementoNaoRespondidoSelecionado(_state => i);
                proximoGrupoDeBotoesSemResposta = todosOsGruposDeRespostas[i];
                break;
            }
        }

        const elementoDoCardDePergunta = proximoGrupoDeBotoesSemResposta?.parentElement?.parentElement;
        const elementoDoGrupoDePerguntas = elementoDoCardDePergunta?.parentElement?.parentElement;

        enviarIdDosGruposDePerguntasParaOContexto(elementoDoGrupoDePerguntas);

        setIdsDosCollapsesQueVaoSerAbertos(arrayDeGrupoDePerguntasQueVaoTerSeuCollapseAberto);

        setTimeout(() => {
            if (elementoDoCardDePergunta) {
                const y = elementoDoCardDePergunta.getBoundingClientRect().top + window.scrollY - 100;
                window.scrollTo({ top: y, behavior: 'smooth' });
            }
        });
    }

    const exportarFormulario = async () => {
        Loading.showLoading("ExportarFormulario");
        HttpService.fetch('api/ResultadoNovoApi/ExportarPlanilha/' + responderFormulario.hash, {
            method: 'POST',
            body: JSON.stringify({
                formularioId: responderFormulario.formularioId,
                hash: responderFormulario.hash,
            }),
        })
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = responderFormulario.titulo.length > 0 ? responderFormulario.titulo + '.xlsx' : responderFormulario.hash + '.xlsx';

                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                Loading.hideLoading("ExportarFormulario");
            }
        );
    }

    const substituirDadosDuranteImportacao = (dadosImportados) => {
        return new Promise((resolve) => {
            const responderFormularioAtualizado = { ...responderFormulario };
            const perguntas = criarArrayDePonteirosDasPerguntas(responderFormularioAtualizado);

            perguntas.forEach(pergunta => {
                const respostas = pergunta.respostas;
                const respostaImportada = dadosImportados.find(ri => ri.perguntaid === pergunta.id);
                pergunta.observacao = respostaImportada.observacao;

                if (!respostaImportada) {
                    pergunta.respostaSelecionada = '';
                    pergunta.respostaSelecionadaId = 0;
                    respostas.forEach(resposta => {
                        resposta.respostaFoiSelecionada = false;
                    });
                    return;
                }

                pergunta.respostaSelecionada = respostaImportada.resposta;
                pergunta.respostaSelecionadaId = respostas.find(resposta => resposta.nomeDaResposta === respostaImportada.resposta)?.id as number;
                respostas.forEach(resposta => {
                    resposta.respostaFoiSelecionada = resposta.nomeDaResposta === respostaImportada.resposta;
                });
            });
            
            setResponderFormulario(responderFormularioAtualizado);
            resolve(true);
        });
    }

    const fetchImportacaoDeFormulario = async (formData) => {
        Loading.showLoading("ImportarFormulario");
        HttpService.fetchFile('api/ResultadoNovoApi/ImportarPlanilha/' + responderFormulario.hash + "/" + responderFormulario.formularioId, formData)
            .then(response => {
                substituirDadosDuranteImportacao(response.data)
                    .then(() => {
                        setArquivoXLSXSelecionadoParaImportacao(null);
                        salvarFormularioPosImportacao(() => {
                            setMensagemDeImportacaoConcluidaComSucesso(true);
                            Loading.hideLoading("ImportarFormulario");
                        });
                    });
            })
            .catch(error => {
                setErrosDeImportacao(error.response.data);
                setArquivoXLSXSelecionadoParaImportacao(null);
                Loading.hideLoading("ImportarFormulario");
            });
    }

    const importarFormulario = async () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '.xlsx';
        input.onchange = (event) => {
            // @ts-ignore
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('file', file);
            
            setMomentoDoModalDeImportacaoEExportacao(MomentoImportacaoExportacao.Importar);
            setArquivoXLSXSelecionadoParaImportacao(formData);
        };

        input.click();
    }

    return (
        <CaixaDeConteudo
            botoes={[
                <button className='btn bg-white mr-2' type='button' onClick={handleBack}>{getLanguageResource('back')}</button>,
            ]}
            itensParaBreadcrumb={[
                { nome: getLanguageResource('home'), url: '/' },
                { nome: getLanguageResource('forms'), url: '/marketPlace/detail/' + responderFormulario.formularioId },
                { nome: responderFormulario.titulo && responderFormulario.titulo.length > 0 ? responderFormulario.titulo : responderFormulario.hash },
            ]}
            tituloDoHeader={
                responderFormulario.titulo && responderFormulario.titulo.length > 0
                    ? responderFormulario.titulo
                    : responderFormulario.hash
            }
        >
                <div className="col-12">
        
                    { isLoading && <Carregando /> }
                    
                    <div>
                        <div className="card-body">
                            <header className="border-bottom px-0 px-md-3">
                                <h3>{responderFormulario.nome} </h3>
                                <p className="card-text mb-1">{responderFormulario.descricao}</p>
                            </header>
        
                            <MensagensDeAlerta />
        
                            <div className='mt-4 row'>
                                <div className='col-md-6 d-flex'>
                                    <section className='px-2'>
                                        {
                                            responderFormulario.permitirCompartilhar && afiliacao === null
                                                ? <BotaoComIconePadrao
                                                    classeDeIcone='fa fa-share-alt'
                                                    className='ws-outline-blue-strong mr-1'
                                                    type='button'
                                                    title={ getLanguageResource('share') }
                                                    dataTarget='#telaDeCompartilhamento'
                                                    dataToggle='modal'
                                                    onClick={() => setModalDeCompartilhamentoAberto(true)}
                                                />
                                                : <React.Fragment />
                                        }
                                        {
                                            modalDeCompartilhamentoAberto
                                                ?   (
                                                        <ModalWinSet
                                                            fecharModal={() => setModalDeCompartilhamentoAberto(false)}
                                                        >
                                                            <ModalDeCompartilhamento
                                                                nome={responderFormulario.nome}
                                                                formularioXEmpresaId={responderFormulario.formularioXEmpresaId}
                                                                hash={params.hash}
                                                                fecharModal={() => setModalDeCompartilhamentoAberto(false)}
                                                                responderFormulario={responderFormulario}
                                                                responderFormularioOriginal={responderFormularioOriginal}
                                                            />
                                                        </ModalWinSet>
                                                    )
                                                :   <React.Fragment />
                                        }
                                    </section>
                                    {
                                        afiliacao === null && (
                                            <section className='d-flex align-items-center border-left flex-column px-2'>
                                                <div className='d-flex'>
                                                    <BotaoComIconePadrao
                                                        componenteDeIcone={<Import size={16} />}
                                                        className='mx-1 ws-outline-blue-strong'
                                                        type='button'
                                                        title={ getLanguageResource('resposta_via_planilha') }
                                                        onClick={() => setModalDeImportacaoEExportacaoAberto(true)}
                                                    />
                                                </div>
                                                <span className='text-muted mx-2'>{ getLanguageResource('importation') }</span>
                                            </section>
                                        )
                                    }
                                    
                                </div>
        
                                <div className='col-md-6 d-flex justify-content-end'>
                                    <BotaoComIconePadrao
                                        classeDeIcone='fa fa-save'
                                        className='mr-2 ws-outline-blue-strong'
                                        type='button'
                                        title='Salvar'
                                        onClick={handleSave}
                                    />
        
                                    <BotaoComIconePadrao
                                        classeDeIcone='fa fa-flag-checkered'
                                        className='btn-outline-success'
                                        type='button'
                                        title='Finalizar'
                                        onClick={handleFinish}
                                    />
                                </div>
                            </div>
        
                            <form>
                                <div className="row">
                                    <div className="col-12 my-3">
                                        <div className="rounded border clearfix p-4">
                                            <div className="row px-md-4 px-0">
                                                {
                                                    responderFormulario.permitirCompartilhar
                                                        ?   <div className="col-md-12 mt-2">
                                                                <label className="mb-2">Nome do formulário</label>
                                                                <input
                                                                    type="text"
                                                                    name="titulo"
                                                                    className="form-control"
                                                                    value={responderFormulario.titulo}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                        :   responderFormulario.permitirEditarTitulo
                                                                ?   <div className="col-md-12 mt-2">
                                                                        <label className="mb-2">Nome do formulário</label>
                                                                        <input
                                                                            type="text"
                                                                            name="titulo"
                                                                            className="form-control"
                                                                            value={responderFormulario.titulo}
                                                                            onChange={handleChange}
                                                                        />
                                                                    </div>
                                                                :   <div className="col-md-12 d-flex justify-content-center mt-2">
                                                                        <h4 className="mb-2">{responderFormulario.titulo}</h4>
                                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
        
                                    {
                                        responderFormulario.cabecalhos.length > 0
                                            ?   (
                                                    <div className="col-lg-12 my-3">
                                                        <div className="rounded border clearfix p-4">
                                                            <div className="row px-md-4 px-0">
                                                                {
                                                                    responderFormulario.cabecalhos
                                                                        .map(cabecalho => (
                                                                            <CampoDeCabecalho
                                                                                key={`CABECALHO${cabecalho.id}`}
                                                                                id={cabecalho.id.toString()}
                                                                                type={cabecalho.tipoCabecalhoId.toString()}
                                                                                camposMultiplaEscolha={cabecalho.camposCabecalhoMultiplaEscolhas}
                                                                                permitirEditar={responderFormulario.permitirEditarCabecalhos || responderFormulario.permitirCompartilhar}
                                                                            />
                                                                        ))
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            :   <React.Fragment />
                                    }

                                    <div className='w-100 d-flex flex-column justify-content-md-between flex-md-row  px-3'>
                                        <BotaoComIconePadrao
                                            classeDeIcone={mostrarTodosOsCollapses ? 'fa fa-window-minimize' : 'fa fa-window-maximize'}
                                            className='mt-2 mt-md-0 ws-outline-blue-strong'
                                            type='button'
                                            title={mostrarTodosOsCollapses ? getLanguageResource('hide_all') : getLanguageResource('show_all')}
                                            onClick={() => setMostrarTodosOsCollapses(!mostrarTodosOsCollapses)}
                                            children={mostrarTodosOsCollapses ? getLanguageResource('hide_all') : getLanguageResource('show_all')}
                                        />
                                        {
                                            afiliacao === null && (
                                                <BotaoComIconePadrao
                                                    className='mt-2 mt-md-0 btn-outline-success'
                                                    type='button'
                                                    title='Salvar'
                                                    onClick={handleAplicarRespostaPadrao}
                                                    componenteDeIcone={<UiRadiosIcon fill='#000' className='mb-1' />}
                                                >
                                                    {getLanguageResource('apply_standard_response')}
                                                </BotaoComIconePadrao>
                                            )
                                        }
                                    </div>
        
                                    <div className="col-lg-12 my-3">
                                        <div className="clearfix">
                                            <QuestionarioRecursivo
                                                chaveDoObjeto='grupoDePerguntas'
                                                grupoDePerguntas={responderFormulario.grupoDePerguntas}
                                                permitirEditarRespostas={responderFormulario.permitirEditarRespostas}
                                                permitirEditarAcoes={responderFormulario.permitirEditarAcoes}
                                                permitirCompartilhar={responderFormulario.permitirCompartilhar}
                                            />
                                        </div>
                                    </div>
        
                                    {
                                        responderFormulario.rodapes.length > 0
                                            ?   (
                                                    <div className="col-lg-12 my-3">
                                                        <div className="rounded border clearfix p-4">
                                                            <div className="row px-4">
                                                                {
                                                                    responderFormulario.rodapes
                                                                        .map(rodape => (
                                                                            <CampoDeCabecalho
                                                                                key={`RODAPE${rodape.id}`}
                                                                                id={rodape.id.toString()}
                                                                                type={rodape.tipoCabecalhoId.toString()}
                                                                                rodape
                                                                                camposMultiplaEscolha={rodape.camposCabecalhoMultiplaEscolhas}
                                                                                permitirEditar={responderFormulario.permitirEditarCabecalhos || responderFormulario.permitirCompartilhar}
                                                                            />
                                                                        ))
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            :   <React.Fragment />
                                    }
                                </div >
        
                                <div className='mt-4 row'>
                                    <div className='col-md-12 d-flex justify-content-end'>
                                        <BotaoComIconePadrao
                                            classeDeIcone='fa fa-save'
                                            className='mr-2 btn-outline-primary'
                                            type='button'
                                            title='Salvar'
                                            onClick={handleSave}
                                        />
        
                                        <BotaoComIconePadrao
                                            classeDeIcone='fa fa-flag-checkered'
                                            className='btn-outline-success'
                                            type='button'
                                            title='Finalizar'
                                            onClick={handleFinish}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <button
                        className="btn p-3 d-none d-md-flex align-items-center justify-content-center shadow ws-btn-green-confirmacao"
                        onClick={handleSave}
                        id="btn-topo"
                        style={{
                            position: 'fixed',
                            bottom: '20px',
                            right: '280px',
                            height: '2.5rem',
                            borderRadius: '0.625rem 0.625rem 0 0.625rem',
                            zIndex: 100,
                        }}
                        title={ getLanguageResource('save') }
                    >
                        { getLanguageResource('save') }
                    </button>
                    <button
                        className='py-2 px-3 btn shadow ws-btn-white-outline d-none d-md-block'
                        type='button'
                        style={{
                            color: 'black',
                            position: 'fixed',
                            bottom: '20px',
                            right: '385px',
                            height: '40px',
                            cursor: 'pointer',
                            zIndex: 100,
                            borderRadius: '20px 20px 0 20px',
                        }}
                        onClick={handleProximaPergunta}
                        title={ getLanguageResource('next_question') }
                    >
                        { getLanguageResource('next_question') }
                    </button>
                    <div
                        id="controle-mobile"
                        className="d-flex d-md-none justify-content-around align-items-center shadow"
                        style={{
                            backgroundColor: 'var(--ws-blue-strong)',
                            position: 'fixed',
                            bottom: '0px',
                            right: '0px',
                            height: '7rem',
                            width: '100vw',
                            zIndex: 100,
                        }}
                    >
                        <button
                            className='btn p-3 d-flex align-items-center justify-content-center shadow ws-btn-white-outline'
                            title={ getLanguageResource('next_question') }
                            onClick={handleProximaPergunta}
                        >
                            <i className='bi bi-caret-right' style={{ fontSize: '1.5rem' }} />
                        </button>
                        <button
                            className='btn p-3 d-flex align-items-center justify-content-center shadow bg-success'
                            title={ getLanguageResource('save') }
                            onClick={handleSave}
                        >
                            <i className='fa fa-floppy-o text-white' style={{ fontSize: '1.5rem' }} />
                        </button>
                    </div>
                    {
                        modalDeConfirmacaoAberto
                            ?   (
                                    <ModalWinSet
                                    fecharModal={() => setModalDeConfirmacaoAberto(false)} 
                                    >
                                        <ModalDeConfirmacao
                                            textoDeCorpo={getLanguageResource('do_you_want_to_finish_the_form')}
                                            textoDeConfirmacao={getLanguageResource('yes')}
                                            textoDeCancelamento={getLanguageResource('no')}
                                            onClickConfirmacao={() => finalizarFormulario()}
                                            fecharModal={() => setModalDeConfirmacaoAberto(false)}
                                        />
                                    </ModalWinSet>
                                )
                            :   <React.Fragment />
                    }
                    {
                        modalDeImportacaoEExportacaoAberto && (
                                <ModalWinSet
                                    fecharModal={() => {
                                        setModalDeImportacaoEExportacaoAberto(false);
                                        setMomentoDoModalDeImportacaoEExportacao(MomentoImportacaoExportacao.Decidir);
                                        setArquivoXLSXSelecionadoParaImportacao(null);
                                    }}
                                    modalStyle={{ height: '80%', width: '100%' }}
                                >
                                    {
                                        momentoDoModalDeImportacaoEExportacao === MomentoImportacaoExportacao.Decidir && (
                                            <div className='d-flex p-4 flex-column w-100 h-100'>
                                                <h2>{ getLanguageResource("respond_to_form_via_spreadsheet") }</h2>
                                                <p className="lead">{ getLanguageResource("follow_instructions_for_accurate_processing") }:</p>
                                                <div className='barra_de_rolagem_estilizada h-100 border p-4 rounded'style={{ overflowY: 'auto' }}>
                                                    <main className='w-100' >
                                                        <ol>
                                                            <li>
                                                                <strong>{ getLanguageResource("download_template") }</strong>: { getLanguageResource("click_to_download_latest_spreadsheet") }
                                                                <ul>
                                                                    <li><span className="info p-1">{ getLanguageResource("attention") }</span>: { getLanguageResource("download_new_template_each_time") }</li>
                                                                </ul>
                                                            </li>
                                                            <li>
                                                                <strong>{ getLanguageResource("edit_spreadsheet") }</strong>: {getLanguageResource("fill_only_response_and_observation")}
                                                                <ul>
                                                                    <li><span className="highlight p-1">{ getLanguageResource("important") }</span>: { getLanguageResource("do_not_change_columns") }</li>
                                                                </ul>
                                                            </li>
                                                            <li>
                                                                <strong>{ getLanguageResource("save_spreadsheet") }</strong>: { getLanguageResource("after_filling_save_spreadsheet") }
                                                                <ul>
                                                                    <li><span className="highlight p-1">{ getLanguageResource("important") }</span>: { getLanguageResource("avoid_adding_lines_or_formulas") }</li>
                                                                </ul>
                                                            </li>
                                                            <li>
                                                                <strong>{ getLanguageResource("upload_spreadsheet") }</strong>: { getLanguageResource("click_to_upload_saved_spreadsheet") }
                                                                <ul>
                                                                    <li><span className="info p-1">{ getLanguageResource("validation") }</span>: { getLanguageResource("system_will_validate_spreadsheet") }</li>
                                                                </ul>
                                                            </li>
                                                            <li>
                                                                <strong>{ getLanguageResource("confirmation") }</strong>: { getLanguageResource("confirmation_question_after_upload") }
                                                                <ul>
                                                                    <li><span className="info p-1">{ getLanguageResource("irreversible") }</span>: { getLanguageResource("once_confirmed_import_is_irreversible") }</li>
                                                                </ul>
                                                            </li>
                                                        </ol>

                                                        <p><strong>{ getLanguageResource("remember") }</strong>: { getLanguageResource("always_download_updated_template") }</p>
                                                    </main>
                                                </div>

                                                <footer className='d-flex justify-content-around py-4'>
                                                    <button className='btn ws-outline-blue' onClick={ exportarFormulario }>{ getLanguageResource("download_template") }</button>
                                                    <button className='btn ws-outline-blue' onClick={ importarFormulario }>{ getLanguageResource("upload_spreadsheet") }</button>
                                                </footer>
                                            </div>
                                        )
                                    }
                                    {
                                        momentoDoModalDeImportacaoEExportacao === MomentoImportacaoExportacao.Importar && (
                                            <div className='d-flex flex-column w-100 h-100 p-4'>
                                                {
                                                    arquivoXLSXSelecionadoParaImportacao && (
                                                        <ModalWinSet
                                                            fecharModal={() => setArquivoXLSXSelecionadoParaImportacao(null)}
                                                        >
                                                            <div className='d-flex p-4 flex-column align-items-center justify-content-center h-100'>
                                                                <h4>{ getLanguageResource("reminder_file_changes_responses") }</h4>
                                                                <main className='d-flex mt-2 w-100 justify-content-end p-3'>
                                                                    <button className='btn btn-danger mr-2' onClick={() => {
                                                                        setArquivoXLSXSelecionadoParaImportacao(null);
                                                                        setMomentoDoModalDeImportacaoEExportacao(MomentoImportacaoExportacao.Decidir);
                                                                        setModalDeImportacaoEExportacaoAberto(false);
                                                                    }}>{ getLanguageResource("cancel") }</button>
                                                                    <button className='btn btn-success' onClick={() => fetchImportacaoDeFormulario(arquivoXLSXSelecionadoParaImportacao)}>{ getLanguageResource("continue") }</button>
                                                                </main>
                                                            </div>
                                                        </ModalWinSet>
                                                    )
                                                }
                                                {
                                                    errosDeImportacao.length > 0 && (
                                                        <div className='d-flex flex-column align-items-center w-100 h-100'>
                                                            <div className='text-warning mb-4'>
                                                                <TriangleAlert size={48} />
                                                            </div>
                                                            <h4>{ getLanguageResource("import_incomplete_errors_found") }</h4>
                                                            <h4>{ getLanguageResource("check_errors_adjust_file_and_retry") }</h4>
                                                            <button
                                                                className='btn ws-outline-blue mt-4 align-self-end'
                                                                onClick={() => {
                                                                    const blob = new Blob([errosDeImportacao.join('\n')], { type: 'text/plain' });
                                                                    const url = window.URL.createObjectURL(blob);
                                                                    const a = document.createElement('a');
                                                                    a.href = url;
                                                                    a.download = 'log_de_erros_de_importacao.txt';
                                                                    a.click();
                                                                    window.URL.revokeObjectURL(url);
                                                                }}
                                                            >
                                                                <Download size={16} className='mr-3' />
                                                                { getLanguageResource("download_problem_list") }
                                                            </button>
                                                            <div className='h-100 w-100 d-flex flex-column justify-content-between'>
                                                                <main
                                                                    className='d-flex mt-2 w-100 flex-column border-top py-2 barra_de_rolagem_estilizada'
                                                                    style={{ maxHeight: '20rem', overflowY: 'auto' }}
                                                                >
                                                                    {
                                                                        errosDeImportacao.map((erro, index) => (
                                                                            <div key={index} className="alert alert-danger">
                                                                                <span>{erro}</span>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </main>
                                                                <footer className='d-flex justify-content-end w-100'>
                                                                    <button
                                                                        className='mt-4 btn ws-outline-blue'
                                                                        onClick={() => {
                                                                            setErrosDeImportacao([]);
                                                                            setArquivoXLSXSelecionadoParaImportacao(null);
                                                                            setMomentoDoModalDeImportacaoEExportacao(MomentoImportacaoExportacao.Decidir);
                                                                        }}
                                                                    >
                                                                        { getLanguageResource("close") }
                                                                    </button>
                                                                </footer>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    mensagemDeImportacaoConcluidaComSucesso && (
                                                        <div className='d-flex p-4 flex-column align-items-center justify-content-between h-100'>
                                                            <div className='d-flex flex-column w-100 align-items-center h-100 justify-content-center'>
                                                                <div className='text-success mb-4'>
                                                                    <CircleCheck size={48} />
                                                                </div>
                                                                <h4>{ getLanguageResource("import_success") }</h4>
                                                            </div>
                                                            <footer className='w-100 d-flex justify-content-end border-top'>
                                                                <button className='mt-4 btn ws-outline-blue' onClick={() => {
                                                                    setMensagemDeImportacaoConcluidaComSucesso(false);
                                                                    setModalDeImportacaoEExportacaoAberto(false);
                                                                    setMomentoDoModalDeImportacaoEExportacao(MomentoImportacaoExportacao.Decidir);
                                                                    setArquivoXLSXSelecionadoParaImportacao(null);
                                                                }}>
                                                                    { getLanguageResource("close") }
                                                                </button>
                                                            </footer>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                </ModalWinSet>
                        )
                    }
            </div>
        </CaixaDeConteudo>
    );
}

export const NovoResponderFormulario = ({ match }) => (
    <ResponderFormularioContextProvider params={match.params}>
        <NovoResponderFormularioContent params={match.params} />
    </ResponderFormularioContextProvider>
);
