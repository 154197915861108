import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { Layout } from '../Layout';
import { HttpService } from '../Shared/HttpService';
import { Loading } from '../Shared/Loading';
import { SetorData } from '../Setores/FetchSetor';
import { RegraData, AddRegra } from './AddRegra';
import { ModalDelete } from '../Shared/ModalDelete';
import { Resource } from '../../index';
import { LanguageContext } from '../../context/LanguageContext';
import ModalWinSet from '../Shared/components/ModalWinSet';

interface ListaRegraVinculadaState {
    regras: RegraData[];
    usuariosId: number;
    minhaConta: boolean;
    modalDeDeletarEstaAberto: boolean;
    itemSelecionadoParaDeletar: RegraData | null;
}

export class ListaRegraVinculada extends React.Component<any, ListaRegraVinculadaState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        this.state = { regras: [], usuariosId: props.usuariosId, minhaConta: props.minhaConta, modalDeDeletarEstaAberto: false, itemSelecionadoParaDeletar: null };

        this.updateRegras();

        this.handleDelete = this.handleDelete.bind(this);
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;
        return this.renderUserTable(this.state.regras);
    }

    public updateRegras() {
        Loading.showLoading(this);

        HttpService.fetch(`api/UsuarioxFuncaoxSetorxPerfilApi/Lista/${this.state.usuariosId}`, {})
            .then(response => response.json() as Promise<RegraData[]>)
            .then(data => {
                this.setState({ regras: data });
                Loading.hideLoading(this);
            }).catch((e)=> {

                console.log(e);
                Loading.hideLoading(this);
            });;

    }

    private handleDelete(id: number) {
        Loading.showLoading(this);

        HttpService.fetch(`api/UsuarioxFuncaoxSetorxPerfilApi/${id}`, { method: 'DELETE' })
            .then(response => response.json() as Promise<boolean>)
            .then(data => {
                Loading.hideLoading(this);
                this.updateRegras();
            }).catch(() => {
                Loading.hideLoading(this);
            });
    }

    // Returns the HTML table to the render() method.
    private renderUserTable(regras: RegraData[]) {
        return <React.Fragment>
            {
                (this.state.minhaConta != true && this.props.modalAdicionarRegraVinculadaEstaAberto)
                    ?   (
                            <ModalWinSet fecharModal={() => this.props.handleModalAdicionarRegraVinculada(false)}>
                                <AddRegra usuariosid={this.state.usuariosId} callBack={this} fecharModal={() => this.props.handleModalAdicionarRegraVinculada(false)} />
                            </ModalWinSet>
                        )
                    :   <React.Fragment />
            }
            <table className='table table-hover'>
                <thead>
                    <tr>
                        <th>{<Resource keyString="sector" />}</th>
                        <th>{<Resource keyString="function" />}</th>
                        <th>{<Resource keyString="profile" />}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {regras.map(doc =>
                        <tr key={doc.id}>
                            <td className='align-middle text-break'>{doc.setor.nome}</td>
                            <td className='align-middle text-break'>{doc.funcao.nome}</td>
                            <td className='align-middle text-break'>{doc.perfil.nome}</td>
                            <td className='align-middle'>
                                {
                                    (this.state.minhaConta != true) ?
                                        <React.Fragment>
                                            <button
                                                className="btn btn-outline-danger mr-2 pull-right"
                                                onClick={() => this.setState({ modalDeDeletarEstaAberto: true, itemSelecionadoParaDeletar: doc })}
                                                style={{ width: '2.75rem', height: '2.75rem' }}><i className='fa fa-trash'></i>
                                            </button>
                                            {
                                                (this.state.modalDeDeletarEstaAberto && this.state.itemSelecionadoParaDeletar && (this.state.itemSelecionadoParaDeletar.id === doc.id)) && (
                                                    <ModalWinSet
                                                        fecharModal={() => this.setState({ modalDeDeletarEstaAberto: false, itemSelecionadoParaDeletar: null })}
                                                    >
                                                        <div className='p-3'>
                                                            <header className='d-flex w-100 mb-2 justify-content-center'>
                                                                <h4><Resource keyString="do_you_want_to_remove_the_rule" />?</h4>
                                                            </header>
                                                            <hr />
                                                            <main>
                                                                <p>{doc.setor.nome}</p>
                                                            </main>
                                                            <footer className='d-flex w-100 justify-content-end'>
                                                                <button className="btn mr-2 ws-outline-blue" onClick={() => this.handleDelete(doc.id)}><Resource keyString="yes" /></button>
                                                                <button className="btn btn-outline-danger" onClick={() => this.setState({ modalDeDeletarEstaAberto: false, itemSelecionadoParaDeletar: null })}><Resource keyString="no" /></button>
                                                            </footer>
                                                        </div>
                                                    </ModalWinSet>
                                                )
                                            }
                                        </React.Fragment>
                                        : null
                                }
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </React.Fragment>;
    }
}   