import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { Layout } from '../../Layout';
import { HttpService } from '../../Shared/HttpService';
import { Loading } from '../../Shared/Loading';
import { FormatoPerguntaData } from '../FormatoPergunta/FetchFormatoPergunta';
import { PerguntaData } from './FetchPergunta';
import { ModalPlanoAcao } from '../PlanoAcao/ModalPlanoAcao';
import * as ReactDOM from 'react-dom';
import $ from 'jquery';
import { ModalDocumentos } from '../../Documentos/ModalDocumentos';
import { DocumentoData } from '../../Documentos/FetchDocumentos';
import { Resource } from '../../../index';
import { SetorData } from '../../Setores/FetchSetor';
import { VisualizacaoPergunta } from './VisualizacaoPergunta';

interface VisualizacaoPerguntaCadastroState {
    pergunta: PerguntaData;
    documentovinculados: DocumentoData[];
    setor: SetorData;
    callBack: any;
}

export class VisualizacaoPerguntaCadastro extends React.Component<any, VisualizacaoPerguntaCadastroState> {
    constructor(props) {
        super(props);

        this.state = {
            pergunta: props.pergunta,
            documentovinculados: props.callBack != null ? props.callBack.resultadoXDocumento(props.pergunta.grupoperguntaid, props.pergunta.id) : [],
            callBack: props.callBack,
            setor: new SetorData()
        };

    }

    public render() {
        return <VisualizacaoPergunta setor={this.state.setor} callBack={this.state.callBack} pergunta={this.state.pergunta} />;
    }
}   