import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { Layout } from '../../Layout';
import { HttpService } from '../../Shared/HttpService';
import { HeadLayout } from '../../Shared/HeadLayout';
import { AuthService } from '../../UserAuth/AuthService';
import { Loading } from '../../Shared/Loading';
import $ from 'jquery';
import { ValidationLabelMessage, ValidationAlertMessage } from '../../Shared/ValidationMessage';
import { FetchPergunta } from '../Pergunta/FetchPergunta';
import { FetchGrupoPergunta } from '../GrupoPergunta/FetchGrupoPergunta';
import { Resource } from '../../../index';
import { LanguageContext } from '../../../context/LanguageContext';
import CaixaDeConteudo from '../../Shared/CaixaDeConteudo';

interface AddFormulaResultadoDataState {
    loading: boolean;
    formularioid: number;
    formulaResultadoData: FormulaResultadoData[];
    acabouDeSalvar: boolean;
}

export class FormulaResultadoData {
    id: number = 0;
    empresaid: number = 0;
    valor: string = "";
    formularioid: number = 0;
    error: any = null;
    isactive: boolean = true;
    formulaResultadoCondicao: Array<FormulaResultadoCondicaoData> = [];
}

export class FormulaResultadoCondicaoData {
    id: number = 0;
    empresaid: number = 0;
    nomeResposta: string = "";
    condicao: string = "";
    valor: number = 0;
    formularioid: number = 0;
    formularesultadoid: number = 0;
    error: any = null;
}

export class AddFormulaResultado extends React.Component<RouteComponentProps<{}>, AddFormulaResultadoDataState> {
    static contextType = LanguageContext;
    private _pathname;
    private _regexPaginaDeFormulaNaEdicao;
    private _estouNaPaginaDeFormulaDaEdicao;
    constructor(props) {
        super(props);
        this._pathname = window.location.pathname;
        this._regexPaginaDeFormulaNaEdicao = new RegExp(/formulario\/formularesultado\/[1-9]\d*$/);
        this._estouNaPaginaDeFormulaDaEdicao = this._regexPaginaDeFormulaNaEdicao.test(this._pathname);

        var formularioid = this.props.match.params["formularioid"];

        this.state = { loading: true, formularioid: formularioid, formulaResultadoData: [], acabouDeSalvar: false };

        // This will set state for Edit User  
        if (formularioid > 0) {

            Loading.showLoading(this);

            HttpService.fetch('api/FormulaResultadoApi/All/' + formularioid, { method: 'GET' })
                .then(response => response.json() as Promise<Array<FormulaResultadoData>>)
                .then(data => {
                    this.setState({ formulaResultadoData: data })
                    Loading.hideLoading(this);
                }).catch(() => {
                    Loading.hideLoading(this);
                    props.history.push(`/repositorio`);
                });
        }

        // This will set state for Add User  
        else {
            this.state = { loading: false, formularioid: formularioid, formulaResultadoData: [], acabouDeSalvar: false };
        }


        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleNewFormulaResultado = this.handleNewFormulaResultado.bind(this);
        this.handleNewFormulaResultadoCondicao = this.handleNewFormulaResultadoCondicao.bind(this);
        this.handleDeleteFormulaResultadoCondicao = this.handleDeleteFormulaResultadoCondicao.bind(this);
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;

        return (
            <CaixaDeConteudo
                itensParaBreadcrumb={[
                    { nome: this.context.getLanguageResource("home"), url: "/" },
                    { nome: this.context.getLanguageResource("forms"), url: "/formulario" },
                    { nome: this.context.getLanguageResource("edit_form"), url: "/formulario/edit/" + this.state.formularioid },
                    { nome: 'Editar fórmulas de resultado' },
                ]}
                tituloDoHeader="Editar fórmulas de resultado"
                botoes={[
                    <button className="btn bg-white shadow-sm mr-2" onClick={this.handleCancel}><Resource keyString="back" /></button>
                ]}
            >
                <div className='p-4'>
                    <header>
                        <h4 className="text-center">Editar fórmulas de resultado</h4>
                    </header>
                    {
                        this.state.acabouDeSalvar && (
                            <div className="alert alert-success alert-dismissible fade show" role="alert">
                                <strong>{this.context.getLanguageResource("saved_successfully")}</strong>
                                <span className="close" style={{ cursor: 'pointer' }} onClick={() => this.setState({ acabouDeSalvar: false })}>&times;</span>
                            </div>    
                        )
                    }
                    {this.renderCreateForm()}
                </div>
            </CaixaDeConteudo>
        );
    }

    private handleNewFormulaResultado() {
        var newFormulaResultado = new FormulaResultadoData();
        newFormulaResultado.formularioid = this.state.formularioid;
        newFormulaResultado.error = null;
        newFormulaResultado.formulaResultadoCondicao.push(new FormulaResultadoCondicaoData());

        var listaFormulaResultado = this.state.formulaResultadoData;
        listaFormulaResultado.push(newFormulaResultado);
        this.setState({ formulaResultadoData: listaFormulaResultado })
    }

    private handleEditFormulaResultado(e, index, valor) {
        var listaFormulaResultado = this.state.formulaResultadoData;
        listaFormulaResultado[index].valor = e.target.value;
        this.setState({ formulaResultadoData: listaFormulaResultado })
    }

    private handleEditFormulaResultadoCondicao(e, index, indexCondicao, valor) {
        var listaFormulaResultado = this.state.formulaResultadoData;
        
        if (e.target.name == "Condicao") {
            const valorHigienizado = e.target.value.replace(/^[a-zA-Z0-9\s]+$/g, '');
            listaFormulaResultado[index].formulaResultadoCondicao[indexCondicao][valor] = valorHigienizado;
        } else if (e.target.name == "Valor") {
            const valorHigienizado = e.target.value.replace(/^0+/, '');
            listaFormulaResultado[index].formulaResultadoCondicao[indexCondicao][valor] = valorHigienizado;
        } else {
            listaFormulaResultado[index].formulaResultadoCondicao[indexCondicao][valor] = e.target.value;
        }

        this.setState({ formulaResultadoData: listaFormulaResultado })
    }

    private handleNewFormulaResultadoCondicao(index) {
        var listaFormulaResultado = this.state.formulaResultadoData;
        listaFormulaResultado[index].formulaResultadoCondicao.push(new FormulaResultadoCondicaoData());
        this.setState({ formulaResultadoData: listaFormulaResultado })
    }

    private handleDeleteFormulaResultadoCondicao(index, indexCondicao) {
        var listaFormulaResultado = this.state.formulaResultadoData;
        listaFormulaResultado[index].formulaResultadoCondicao.splice(indexCondicao, 1);
        this.setState({ formulaResultadoData: listaFormulaResultado })
    }

    private handleDeleteFormulaResultado(index) {
        var listaFormulaResultado = this.state.formulaResultadoData;
        if (listaFormulaResultado[index].id > 0) {
            listaFormulaResultado[index].isactive = false;
        } else {
            listaFormulaResultado.splice(index, 1);
        }
        this.setState({ formulaResultadoData: listaFormulaResultado })
    }

    // This will handle the submit form event.  
    private handleSave(event) {
        event.preventDefault();

        var listaFormulaResultado = this.state.formulaResultadoData;

        Loading.showLoading(this);

        // PUT request for Edit User.  

        HttpService.fetch('api/FormulaResultadoApi/', {
            method: 'POST',
            body: JSON.stringify(listaFormulaResultado),
        }).then((response) => {
            return response.json() as Promise<Array<FormulaResultadoData>>
        }).then((data) => {

            this.setState({ formulaResultadoData: data });
            this.setState({ acabouDeSalvar: true });
            window.scrollTo({ top: 0, behavior: 'smooth' });
            Loading.hideLoading(this);
        }).catch(() => {
            Loading.hideLoading(this);
        })

    }

    // This will handle Cancel button click event.  
    private handleCancel(e) {
        this.props.history.push('/formulario/edit/' + this.state.formularioid);
    }

    // Returns the HTML Form to the render() method.  
    private renderCreateForm() {
        return (<form onSubmit={this.handleSave} >
            <div className="form-group row" >
                <input type="hidden" name="id" value={this.state.formularioid} />
            </div>
            <div className="card-title">
                <h4>{<Resource keyString="formulas_for_evaluating_the_result" />}</h4>
            </div>
            <div className="form-group row">
                <div className="col-md-12">
                    <button type="button" className="btn mr-2" style={{ backgroundColor: 'var(--ws-blue)' }} onClick={this.handleNewFormulaResultado}>
                        {<Resource keyString="create_new_result_formula" />}
                    </button>
                </div>
            </div>
            {this.state.formulaResultadoData
                .filter(obj => obj.isactive == true)
                .map((obj, indexObj) =>
                    <div className="border-bottom py-3 my-3" key={indexObj}>
                        <div className="d-flex w-100 align-items-end">
                            <div className='w-100'>
                                <label className="control-label  required" htmlFor="Valor">{<Resource keyString="result" />}</label>
                                <div>
                                    <ValidationLabelMessage errors={obj.error} name="Valor" >
                                        <input style={{ height: '2.813rem' }} className="form-control" type="text" maxLength={200} name="Valor" value={obj.valor}
                                            onChange={(e) => this.handleEditFormulaResultado(e, indexObj, "valor")} />
                                    </ValidationLabelMessage>
                                </div>
                            </div>
                            <div className='d-flex align-items-end px-2'>
                                <button type="button" style={{ width: '2.813rem', height: '2.813rem' }} className="btn btn-block btn-outline-danger"
                                    onClick={() => this.handleDeleteFormulaResultado(indexObj)}>
                                    <i className='fa fa-minus'></i>
                                </button>
                            </div>
                        </div>

                        {obj.formulaResultadoCondicao.map((condicao, indexCondicao) =>
                            <React.Fragment key={indexCondicao}>
                                <div className="w-100 d-flex mt-2">
                                    <div className='d-flex w-100 align-items-end'>
                                        <div className='w-50'>
                                            <label className="control-label  required" htmlFor="NomeResposta">{<Resource keyString="response_name" />}</label>
                                            <div>
                                                <ValidationLabelMessage errors={obj.error} name="NomeResposta" >
                                                    <input style={{ height: '2.813rem' }} className="form-control" type="text" name="NomeResposta" value={condicao.nomeResposta}
                                                        onChange={(e) => this.handleEditFormulaResultadoCondicao(e, indexObj, indexCondicao, "nomeResposta")} />
                                                </ValidationLabelMessage>
                                            </div>
                                        </div>
                                        <div className='px-3'>
                                            <label className="control-label  required" htmlFor="Condicao">{<Resource keyString="condition" />}</label>
                                            <div>
                                                <ValidationLabelMessage errors={obj.error} name="Condicao" >
                                                    <input style={{ height: '2.813rem' }} className="form-control" type="text" name="Condicao" value={condicao.condicao}
                                                        onChange={(e) => this.handleEditFormulaResultadoCondicao(e, indexObj, indexCondicao, "condicao")} />
                                                </ValidationLabelMessage>
                                            </div>
                                        </div>
                                        <div className='w-50'>
                                            <label className="control-label  required" htmlFor="Valor">{<Resource keyString="value" />}</label>
                                            <div>
                                                <ValidationLabelMessage errors={obj.error} name="Valor" >
                                                    <input style={{ height: '2.813rem' }} className="form-control" type="number" name="Valor" value={condicao.valor + ""}
                                                        onChange={(e) => this.handleEditFormulaResultadoCondicao(e, indexObj, indexCondicao, "valor")} />
                                                </ValidationLabelMessage>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-end px-2' style={{ width: '8rem' }}>
                                        
                                        <div className='d-flex w-100 justify-content-between'>
                                            <button type="button" style={{ width: '2.813rem', height: '2.813rem' }} className="btn ws-outline-blue"
                                                onClick={() => this.handleNewFormulaResultadoCondicao(indexObj)}>
                                                <i className='fa fa-plus'></i>
                                            </button>
                                            {(indexCondicao > 0) ?
                                                <button type="button" style={{ width: '2.813rem', height: '2.813rem' }} className="btn btn-outline-danger"
                                                    onClick={() => this.handleDeleteFormulaResultadoCondicao(indexObj, indexCondicao)}>
                                                    <i className='fa fa-minus'></i>
                                                </button>
                                                : <React.Fragment></React.Fragment>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                )}
                <div className="form-group">
                    <button type="submit" style={{ width: '2.813rem', height: '2.813rem' }} className="btn btn-outline-success mr-2"><i className='fa fa-save'></i></button>
                </div >
        </form >)
    }
}