import * as React from 'react';
import { LanguageContext } from '../../../../../context/LanguageContext';
import ArquivoDoCardRespondidoIcon from '../../../../../assets/icons/arquivo-do-card-respondido-icon.svg';
import VisualizadorDeArquivos from '../../../../Shared/components/VisualizadorDeArquivos';
import { IAnexoDeEvidencia } from '../../interfaces/IResponderFormularioResponse';
import { HttpService } from '../../../../Shared/HttpService';

interface IArquivoVigente {
    id: number;
    versao: any;
    datavencimento: string;
    empresasid: number;
    caminhovirtual: string;
}

interface IDocumentoProp {
    nome: string;
    arquivovigente: IArquivoVigente;
}

interface IProps {
    documento: IDocumentoProp;
    handleDownload: Function;
}

interface IState {
    visualizadorDeArquivoEstaAberto: boolean;
    file: IFile | null;
}

interface IFile {
    url: string;
    type: string;
}

export default class DocumentoComponente extends React.Component<IProps, IState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        this.state = {
            visualizadorDeArquivoEstaAberto: false,
            file: null
        }
    }

    apresentarVencimento = (dataVencimento) => {
        const dataAtual = new Date();
        const dataVencimentoFormatada = new Date(dataVencimento);
        if (dataVencimento == null) {
            return (
                <span className="text-muted text-right mb-0"  style={{ fontSize: '0.75rem' }}>
                    Não expira
                    <i className="bi bi-dash-circle-fill ml-2"></i>
                </span>
            );
        } else if (dataVencimentoFormatada < dataAtual) {
            return (
                <span className="text-danger text-right mb-0"  style={{ fontSize: '0.75rem' }}>
                    Vencido
                    <i className="bi bi-exclamation-diamond-fill ml-2"></i>
                </span>
            );
        } else if (dataVencimentoFormatada > dataAtual) {
            return (
                <span className="text-success text-right mb-0"  style={{ fontSize: '0.75rem' }}>
                    Vigente
                    <i className="bi bi-check-circle-fill ml-2"></i>
                </span>
            );
        }

        return null;
        
    };
    
    openViewer = async (arquivo) => {
        if (!arquivo) return;
        this.setState({ visualizadorDeArquivoEstaAberto: true });

        const urlRequest = `api/ArquivosApi/Visualizar/${arquivo.arquivoId}/${arquivo.empresaId}`;
        
        try {
            const response = await HttpService.fetch(urlRequest, { method: 'GET' });
            if (!response.ok) {
                throw new Error("Erro ao fazer download do arquivo");
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            this.setState({ file: { url, type: blob.type } });
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        const { documento, handleDownload } = this.props;
        const { nome, arquivovigente } = documento;
        const { id, versao, datavencimento } = arquivovigente;
        return (
            <React.Fragment>
                <div className="btn-group mr-2 mt-2">
                    <div>
                        <div className="btn-group">
                            <button
                                type="button"
                                className="btn ws-outline-white-to-blue-background d-flex align-items-center border"
                                title={nome}
                                onClick={ () => this.openViewer({ arquivoId: arquivovigente.id, empresaId: arquivovigente.empresasid }) }
                                style={{ backgroundColor: 'white' }}
                            >
                                <img src={ArquivoDoCardRespondidoIcon} alt="Ícone de arquivo do card respondido" />
                                <div className='d-flex flex-column align-itens-end w-100'>
                                    { this.apresentarVencimento(datavencimento) }
                                    <span className='ml-3' style={{ fontSize: '0.875rem' }}>{ nome } { arquivovigente != null && id > 0 ? `(${this.context.getLanguageResource("version")}: ${versao}) ` : "" }</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                {
                    this.state.visualizadorDeArquivoEstaAberto && (
                        <VisualizadorDeArquivos
                            fecharModal={() => this.setState({ visualizadorDeArquivoEstaAberto: false, file: null })}
                            tipo={this.state.file !== null ? this.state.file.type.split('/')[1] : null}
                            url={this.state.file !== null ? this.state.file.url : null}
                            extensao={arquivovigente.caminhovirtual.split('.')[arquivovigente.caminhovirtual.split('.').length - 1]}
                            impedirFechamentoAoClicarFora
                        />
                    )
                }
            </React.Fragment>
        );
    }
}
