import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { RespostaData } from './FetchResposta';
import { Layout } from '../../Layout';
import { HttpService } from '../../Shared/HttpService';
import { AuthService } from '../../UserAuth/AuthService';
import { Loading } from '../../Shared/Loading';
import { Login, LoginData } from '../../UserAuth/Login';
import $ from 'jquery';
import { ValidationLabelMessage, ValidationAlertMessage } from '../../Shared/ValidationMessage';
import { Resource } from '../../../index';
import { LanguageContext } from '../../../context/LanguageContext';

interface AddRespostaDataState {
    id: string;
    resposta: RespostaData;
    gruporespostaid: number;
    callBack: any;
}

export class AddResposta extends React.Component<any, AddRespostaDataState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        // This will set state for Add User  
        this.state = { resposta: new RespostaData, id: props.id, callBack: props.callBack, gruporespostaid: props.gruporespostaid };

        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);
    }

    // This will handle the submit form event.  
    private handleSave(event) {
        event.preventDefault();

        Loading.showLoading(this);

        HttpService.fetch(`api/RespostaApi`, {
            method: 'POST',
            body: HttpService.formToJson(event.target),

        }).then((response) => {

            return response.json() as Promise<RespostaData>;

        }).then((data) => {

            this.setState({ resposta: data })

            if ($.isEmptyObject(data.error)) {
                this.setState({ resposta: new RespostaData })
                //assinatura aprovado
                this.state.callBack.updateRespostas();
                this.forceUpdate();
                this.props.fecharModal();
                //alert('assinado');
            }

            Loading.hideLoading(this);

        }).catch((responseJson) => {

            console.log(responseJson);
            Loading.hideLoading(this);

        })
    }

    // Returns the HTML Form to the render() method.  
    public render() {
        return (
            <form className='d-flex flex-column align-items-center w-100 py-4' onSubmit={this.handleSave} >
                <header className='w-100 px-4'>
                    <h4 className='text-center'>{<Resource keyString="add_reply" />}</h4>
                </header>

                <hr className='w-100' />

                <main className='w-100 px-4'>
                    <input type="hidden" name="gruporespostaid" value={this.state.gruporespostaid} />
                    <div className="form-group">
                        <label>{<Resource keyString="reply" />}</label>
                        <ValidationLabelMessage errors={this.state.resposta.error} name="Nome">
                            <input className="form-control" type="text" name="Nome" placeholder={this.context.getLanguageResource("reply")} defaultValue={this.state.resposta.nome} />
                        </ValidationLabelMessage>
                    </div>
                    <div className="form-group">
                        <label>{<Resource keyString="points" />}</label>
                        <ValidationLabelMessage errors={this.state.resposta.error} name="Valor">
                            <input className="form-control" data-converterdecimal type="number" name="Valor" placeholder={this.context.getLanguageResource("worthless")} defaultValue={this.state.resposta.valor != null ? this.state.resposta.valor.toString() : ""} />
                        </ValidationLabelMessage>
                    </div>
                    <div className="form-group">
                        <label>{<Resource keyString="percentage" />} (%)</label>
                        <ValidationLabelMessage errors={this.state.resposta.error} name="valorporcentagem">
                            <input className="form-control" type="number" name="valorporcentagem" placeholder={this.context.getLanguageResource("percentage")} defaultValue={this.state.resposta.valorporcentagem.toString()} />
                        </ValidationLabelMessage>
                    </div>                            
                    <div className="form-group">
                        <label>{<Resource keyString="ordination" />}</label>
                        <ValidationLabelMessage errors={this.state.resposta.error} name="Ordenacao">
                            <input className="form-control" type="number" name="Ordenacao" placeholder={this.context.getLanguageResource("ordination")} defaultValue={this.state.resposta.ordenacao.toString()} />
                        </ValidationLabelMessage>
                    </div>
                    <ValidationAlertMessage errors={this.state.resposta.error} name="Summary" />
                </main>

                <footer className='w-100 px-4 d-flex justify-content-end'>
                    <button className="btn mr-2" style={{ width: '2.75rem', height: '2.75rem', color: 'white',  backgroundColor: 'var(--ws-yellow-brown)' }} type="submit"><i className='fa fa-save'></i></button>
                    <button type="button" className="btn btn-outline-danger" style={{ width: '2.75rem', height: '2.75rem' }} onClick={() => this.props.fecharModal()}><i className='fa fa-times'></i></button>
                </footer>
            </form>    
        )
    }

}  