import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { ModeloPlanoAcaoData } from './FetchModeloPlanoAcao';
import { HeadLayout } from '../Shared/HeadLayout';
import { Layout } from '../Layout';
import { HttpService } from '../Shared/HttpService';
import { Loading } from '../Shared/Loading';
import $ from 'jquery';
import { Resource } from '../../index';
import { ValidationLabelMessage } from '../Shared/ValidationMessage';
import { FetchCampoModeloAcao } from './CampoModeloAcao/FetchCampoModeloAcao';
import { LanguageContext } from '../../context/LanguageContext';
import CaixaDeConteudo from '../Shared/CaixaDeConteudo';

interface AddFuncaoDataState {
    loading: boolean;
    empData: ModeloPlanoAcaoData;
}

export class AddModeloPlanoAcao extends React.Component<RouteComponentProps<{}>, AddFuncaoDataState> {
    static contextType = LanguageContext;
    private _pathname;
    private _regexPaginaDeEdicao;
    private _estouNaPaginaDeEdicao;
    constructor(props) {
        super(props);
        this._pathname = window.location.pathname;
        this._regexPaginaDeEdicao = new RegExp(/modeloPlanoAcao\/edit/);
        this._estouNaPaginaDeEdicao = this._regexPaginaDeEdicao.test(this._pathname);

        this.state = { loading: true, empData: new ModeloPlanoAcaoData };

        var modeloPlanoAcaoid = this.props.match.params["modeloPlanoAcaoid"];

        // This will set state for Edit User  
        if (modeloPlanoAcaoid > 0) {

            Loading.showLoading(this);;

            HttpService.fetch('api/ModeloPlanoAcaoApi/' + modeloPlanoAcaoid, { method: 'GET' })
                .then(response => response.json() as Promise<ModeloPlanoAcaoData>)
                .then(data => {
                    this.setState({ loading: false, empData: data });
                    Loading.hideLoading(this);
                }).catch(() => {
                    Loading.hideLoading(this);
                    props.history.push("/modeloPlanoAcao");
                });
        }

        // This will set state for Add User  
        else {
            this.state = { loading: false, empData: new ModeloPlanoAcaoData };
        }


        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    private botoesDoCantoSuperiorDireito() {
        return (
            <React.Fragment>
                <button
                    className='btn bg-white shadow-sm mr-2'
                    onClick={() => window.location.href = `/campoModeloAcao/add/${this.state.empData.id}`}
                    disabled={!this._estouNaPaginaDeEdicao}
                    title={this._estouNaPaginaDeEdicao ? this.context.getLanguageResource("add") : this.context.getLanguageResource("saving_is_required_before_you_can_add_fields")}
                >
                    <Resource keyString="add" />
                </button>
                <button className="btn btn-outline-dark shadow-sm mr-2" onClick={this.handleCancel}>{<Resource keyString="back" />}</button>
            </React.Fragment>
        );
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;

        return (
            <CaixaDeConteudo
                tituloDoHeader={this._estouNaPaginaDeEdicao ? this.context.getLanguageResource("edit_action_plan_template") : this.context.getLanguageResource("create_action_plan_template")}
                botoes={this.botoesDoCantoSuperiorDireito()}
                itensParaBreadcrumb={[
                    { url: "/", nome: this.context.getLanguageResource("home") },
                    { url: "/modeloPlanoAcao", nome: this.context.getLanguageResource("action_plan_templates") },
                    { nome: this._estouNaPaginaDeEdicao ? this.context.getLanguageResource("edit_action_plan_template") : this.context.getLanguageResource("create_action_plan_template") }
                ]}
            >
                <main className='p-4'>
                    {this.renderCreateForm()}
                    {
                        this.state.empData.id > 0
                            ?   <FetchCampoModeloAcao modeloPlanoAcaoid={this.state.empData.id} />
                            :   <React.Fragment />
                    }
                </main>
            </CaixaDeConteudo>
        );
    }

    // This will handle the submit form event.  
    private handleSave(event) {
        event.preventDefault();

        let modeloPlanoAcaoid = this.state.empData.id;

        Loading.showLoading(this);;

        // PUT request for Edit User.  
        if (modeloPlanoAcaoid) {

            HttpService.fetch('api/ModeloPlanoAcaoApi/' + modeloPlanoAcaoid, {
                method: 'PUT',
                body: HttpService.formToJson(event.target),
            }).then((response) => {
                return response.json() as Promise<ModeloPlanoAcaoData>
            }).then((data) => {

                this.setState({ empData: data })
                if ($.isEmptyObject(data.error)) {
                    this.redirectPage();
                }
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            })

        } else {

            HttpService.fetch('api/ModeloPlanoAcaoApi/Salvar', {
                method: 'POST',
                body: HttpService.formToJson(event.target),
            }).then((response) => {
                return response.json() as Promise<ModeloPlanoAcaoData>
            }).then((data) => {

                this.setState({ empData: data })
                if ($.isEmptyObject(data.error)) {
                    this.redirectPage();
                }
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            })
        }
    }

    // This will handle Cancel button click event.  
    private handleCancel(e) {
        e.preventDefault();
        this.redirectPage();
    }

    private redirectPage() {
        this.props.history.push("/modeloPlanoAcao");
    }

    // Returns the HTML Form to the render() method.  
    private renderCreateForm() {
        return (<form onSubmit={this.handleSave} >
            <div className="form-group row" >
                <input type="hidden" name="id" value={this.state.empData.id} />
            </div>
            <div className="form-group row">
                <div className="col-md-6">
                    <label className="control-label col-md-12 required" htmlFor="nome" >{<Resource keyString="model_name_plan_action" />}</label>
                    <div className="col-md-12">
                        <ValidationLabelMessage errors={this.state.empData.error} name="nome">
                            <input className="form-control" type="text" id="nome" name="nome" defaultValue={this.state.empData.nome} />
                        </ValidationLabelMessage>
                    </div>
                </div>
            </div > 
            <div className="form-group row">
                <div className="col-md-6">
                    <label className="control-label col-md-12 required" htmlFor="descricao" >{<Resource keyString="action_plan_model_description" />}</label>
                    <div className="col-md-12">
                        <ValidationLabelMessage errors={this.state.empData.error} name="descricao">
                            <input className="form-control" type="text" id="descricao" name="descricao" defaultValue={this.state.empData.descricao} />
                        </ValidationLabelMessage>
                    </div>
                </div>
            </div >
            <div className="form-group col-md-12" >
                <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" name="isactive" id="isactive" defaultChecked={this.state.empData.isactive} />
                    <label className="custom-control-label" htmlFor="isactive">{<Resource keyString="active" />}</label>
                </div>
            </div>
            <div className="form-group col-12 d-flex justify-content-center">
                <button type="submit" className="btn font-weight-bold" style={{ backgroundColor: 'var(--ws-blue)', width: '17.5rem' }}>{<Resource keyString="save" />}</button>
            </div >
        </form >)
    }

}  