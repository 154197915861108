import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { Layout } from '../Layout';
import { HttpService } from '../Shared/HttpService';
import { AuthService } from '../UserAuth/AuthService';
import { Loading } from '../Shared/Loading';
import { Login, LoginData } from '../UserAuth/Login';
import { SetorData } from '../Setores/FetchSetor';
import $ from 'jquery';
import { ValidationLabelMessage, ValidationAlertMessage } from '../Shared/ValidationMessage';
import { Resource } from '../../index';
import { LanguageContext } from '../../context/LanguageContext';

interface VinculoDataState {
    vinculo: DiretorioXDocumentoXSetorData;
    setores: SetorData[];
    callBack: any;
}

export class DiretorioXDocumentoXSetorData {
    documentosid: number = 0;
    diretoriosid: number = 0;
    setoresid: number = 0;
    id: number = 0;
    error: Array<any> = [];
}

export class VincularSetor extends React.Component<any, VinculoDataState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        var vinculo = new DiretorioXDocumentoXSetorData;
        vinculo.documentosid = props.documentoid;
        vinculo.diretoriosid = props.diretorioid;

        // This will set state for Add User  
        this.state = { vinculo: vinculo, setores: [], callBack: props.callBack };

        Loading.showLoading(this);;
        HttpService.fetch('api/SetorApi', {})
            .then(response => response.json() as Promise<SetorData[]>)
            .then(data => {
                this.setState({ setores: data });
                Loading.hideLoading(this);
            });

        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);
    }

    // This will handle the submit form event.  
    private handleSave(event) {
        event.preventDefault();



        Loading.showLoading(this);;

        var url = `api/DiretoriosXSetoresApi`;
        if (this.state.vinculo.documentosid > 0) {
            url = `api/DocumentosxSetoresApi`;
        }

        HttpService.fetch(url, {
            method: 'POST',
            body: HttpService.formToJson(event.target),

        }).then((response) => {

            return response.json() as Promise<DiretorioXDocumentoXSetorData>;

        }).then((data) => {

            if ($.isEmptyObject(data.error)) {
                //assinatura aprovado
                this.setState({ vinculo: data })
                this.state.callBack.updateVinculos();
                $('#vincularSetor [data-dismiss="modal"]').click();
                //alert('assinado');
            } else {
                this.setState({ vinculo: data })
            }
            Loading.hideLoading(this);
            this.props.fecharModal();

        }).catch((responseJson) => {

            console.log(responseJson);
            Loading.hideLoading(this);

        })
    }

    // Returns the HTML Form to the render() method.  
    public render() {
        return (
            <form onSubmit={this.handleSave} >
                <div className="modal-header">
                    <h4 className="w-100 text-center">{<Resource keyString="link_sector" />}</h4>
                </div>

                <div className="modal-body">
                    <input type="hidden" name="documentosid" value={this.state.vinculo.documentosid} />
                    <input type="hidden" name="diretoriosid" value={this.state.vinculo.diretoriosid} />
                    <div className="form-group">
                        <label className="required">{<Resource keyString="sector" />}</label>
                        <ValidationLabelMessage errors={this.state.vinculo.error} name="SetorId">
                            <select className="form-control" data-val="true" name="setoresId">
                                <option value="">{this.context.getLanguageResource("select_sector")}</option>
                                {this.state.setores.map(item => item ? <option key={item.id} value={item.id}>{item.nome}</option> : <React.Fragment />)}
                            </select>
                        </ValidationLabelMessage>
                    </div>
                    <ValidationAlertMessage errors={this.state.vinculo.error} name="Summary" />
                </div>

                <div className="modal-footer">
                    <button className="btn ws-outline-blue" style={{ width: '2.75rem', height: '2.75rem' }} type="submit"><i className='fa fa-save'></i></button>
                    <button type="button" style={{ width: '2.75rem', height: '2.75rem' }} onClick={this.props.fecharModal} className="btn btn-outline-danger"><i className='fa fa-times'></i></button>
                </div>
            </form>
        )
    }
}
