import * as React from 'react';
import $ from 'jquery';
import 'gasparesganga-jquery-loading-overlay';
import { Component } from 'react';

export class Loading {
    static count = 0;
    static listComponent: Array<Component | string> = [];
    static showLoading(component: Component | string) {
        this.listComponent.push(component);
        //Loading.count++;
        $('body').LoadingOverlay("show", {
            image: "",
            fontawesome: "fa fa-circle-o-notch fa-spin fa-1x",
            fontawesomeColor: '#007bff',
            fontawesomeResizeFactor: '0.50',
            progressSpeed: 100
        });
    }

    static removerDaLista(component: Component | string, forceUpdate: boolean) {
        var array = this.listComponent; // make a separate copy of the array
        var index = array.indexOf(component)
        if (index >= 0) {
            array.splice(index, 1);
            this.listComponent = array;
            if (forceUpdate == true && component instanceof Component) {
                component.forceUpdate();
            }
        }
    }

    private static hideModalLoading() {
        $('body').LoadingOverlay("hide", {
            image: "",
            fontawesome: "fa fa-circle-o-notch fa-spin fa-1x",
            fontawesomeColor: '#007bff',
            fontawesomeResizeFactor: '0.50',
            progressSpeed: 100
        });
    }

    static hideLoading(component: Component | string, forceUpdate: boolean = true) {
        this.removerDaLista(component, forceUpdate)
        if (this.listComponent.length == 0) {
            this.hideModalLoading();
            window.scrollTo(0, 0);
        }
    }

    static hideLoadingWithoutScroll(component: Component | string, forceUpdate: boolean = true) {
        this.removerDaLista(component, forceUpdate)
        if (this.listComponent.length == 0) {
            this.hideModalLoading();
        }
    }

    static isLoading(component: Component | string) {
        return this.listComponent.some(item => component === item);
    }
}
