import React, { createContext, useState } from "react";

export interface IFormularioRespondidoContext {
    mostrarTodosOsCollapses: boolean;
    setMostrarTodosOsCollapses: (mostrarTodosOsCollapses: boolean) => void;
}

export const FormularioRespondidoContext = createContext<IFormularioRespondidoContext>({
    mostrarTodosOsCollapses: false,
    setMostrarTodosOsCollapses: () => { },
});

export const FormularioRespondidoProvider = ({ children }) => {
    const [mostrarTodosOsCollapses, setMostrarTodosOsCollapses] = useState(false);

    const values: IFormularioRespondidoContext = {
        mostrarTodosOsCollapses,
        setMostrarTodosOsCollapses,
    };

    return (
        <FormularioRespondidoContext.Provider value={values}>
            {children}
        </FormularioRespondidoContext.Provider>
    );
};
