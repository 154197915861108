import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { HttpService } from '../../Shared/HttpService';
import { HeadLayout } from '../../Shared/HeadLayout';
import { AuthService } from '../AuthService';
import { Loading } from '../../Shared/Loading';
import { Link } from 'react-router-dom';
import { ValidationLabelMessage, ValidationAlertMessage } from '../../Shared/ValidationMessage';
import { UsuarioData } from '../../Usuarios/FetchUsuarios';
import { Resource } from '../../../index';
import { LanguageContext } from '../../../context/LanguageContext';
import LogoWinset from '../../../assets/img/winset-logo.png';
import ChaveSombreada from '../../../assets/svg/chave-sombreada.svg';
import SmartphoneWinset from '../../../assets/img/smartphone-winset.png';
import EmpresasERotas from '../../../assets/json/empresasERotas.json';
import LoginForm from './components/LoginForm';
import $ from 'jquery';

interface LoginDataState {
    login: LoginData;
    esconderSenha: boolean;
    imagensDinamicas: { logo: any, icon: any, whitelogo: any };
}

export class LoginData {
    email: string = "";
    senha: string = "";
    usuario: UsuarioData = new UsuarioData();
    mensagem: string[] = [];
    error: Array<any> = [];
}

export class Login extends React.Component<RouteComponentProps<{}>, LoginDataState> {
    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        new AuthService().logout();
        this.state = { login: new LoginData(), esconderSenha: true, imagensDinamicas: { logo: '', icon: '', whitelogo: '' } };
        this.handleLogin = this.handleLogin.bind(this);
    }

    // This will handle the submit form event.  
    private handleLogin(event) {
        event.preventDefault();

        Loading.showLoading(this);

        HttpService.fetch('api/Authentication/login', {
            method: 'POST',
            body: HttpService.formToJson(event.target),

        }).then((response) => {

            return response.json() as Promise<LoginData>;

        }).then((data) => {
            this.setState({ login: data })

            //#region [Aceleragora] - Redirecionar para o formulário iniciado em caso de receber a mensagem de redirecionamento do back-end
            if (!$.isEmptyObject(this.state.login.mensagem)) {
                AuthService.setUsuario(data);
                if (this.state.login.mensagem[0].startsWith("redirect_fi")) {
                    const split = this.state.login.mensagem[0].split(":");
                    window.location.href = `/formulario/responder/${split[1]}/${split[2]}`;
                    Loading.hideLoading(this);
                    return;
                }
            }
            //#endregion

            if ($.isEmptyObject(this.state.login.error)) {
                AuthService.setUsuario(data);
                window.location.href = "/";
            }
            Loading.hideLoading(this);

        }).catch((responseJson) => {
            Loading.hideLoading(this);

        })
    }

    private aRotaEstaListadaNoJson() {
        // verificar o que está escrito entre http:// ou https:// e .winset.com.br
        const url = window.location.href as string;
        const regex = /https?:\/\/([^.]*)/;
        const match = url.match(regex) ? url.match(regex)[1] : null;

        // transformar o json em array
        const empresasERotas = JSON.parse(JSON.stringify(EmpresasERotas)) as Array<any>;
        if (empresasERotas.length === 0) return false;
        return empresasERotas.some(empresa => match?.includes(empresa.nome));
    }

    private retornaRotasDasImagensDaEmpresa() {
        const outraEmpresa = this.aRotaEstaListadaNoJson();
        
        if (outraEmpresa) {
            const rotaAtual = window.location.href.match(/https?:\/\/([^.]*)/)[1];
            const empresasERotas = JSON.parse(JSON.stringify(EmpresasERotas)) as Array<any>;
            const empresa = empresasERotas.find(empresa => rotaAtual.includes(empresa.nome));
            this.setState({ imagensDinamicas: { logo: empresa.logo, icon: empresa.icon, whitelogo: empresa.whitelogo } });
        }
    }

    componentDidMount(): void {
        this.retornaRotasDasImagensDaEmpresa();
    }

    public render() {
        return (
            <React.Fragment>
                <HeadLayout title="Acessar conta" />
                <div
                    className='d-flex justify-content-end align-items-start'
                    style={{ backgroundColor: 'var(--ws-blue)', width: '100vw', height: '100vh', position: 'relative', overflow: 'hidden' }}
                >
                    {
                        // verificar a url, se conter "aceleragora" antes de winset.com.br, exibir o logo da aceleragora
                        !this.aRotaEstaListadaNoJson() && (
                            <img src={SmartphoneWinset} alt='Smartphone win.set' style={{ height: '120vh', position: 'absolute', top: '-20vh', left: '10vw' }} />
                        )
                    }
                    <LoginForm outraEmpresa={this.aRotaEstaListadaNoJson()} >
                        <header className='d-flex justify-content-center mb-3'>
                            <img
                                src={this.aRotaEstaListadaNoJson() ? `${process.env.PUBLIC_URL}${this.state.imagensDinamicas.logo}` : LogoWinset}
                                alt="Logo win.set"
                                style={{ width: "40%", minWidth: "16.25rem" }}
                            />
                        </header>

                        <main style={{ maxWidth: '23.25rem' }}>
                            {
                                !this.aRotaEstaListadaNoJson() && (
                                    <h2><Resource keyString="log_in_wink" /></h2>
                                )
                            }
                            <form onSubmit={this.handleLogin}>
                                <div>
                                    <label className='mb-0 font-weight-bold mt-3'>{<Resource keyString="email" />}</label>
                                    <ValidationLabelMessage errors={this.state.login.error} name="Email">
                                        <input className='w-100 border rounded p-1 form-control' style={{ borderColor: 'var(--ws-blue)' }} type="text" name="email" placeholder={this.context.getLanguageResource("email")} defaultValue={this.state.login.email} />
                                    </ValidationLabelMessage>
                                </div>
                                <div>
                                    <label className='mb-0 font-weight-bold mt-3'>{<Resource keyString="password" />}</label>
                                    <ValidationLabelMessage errors={this.state.login.error} name="Senha" >
                                        <div>
                                            <input className='w-100 border rounded form-control' style={{ borderColor: 'var(--ws-blue)', display: 'inline', padding: '.5rem 2.5rem .5rem .5rem' }} type={ this.state.esconderSenha ? 'password' : 'text' } name="senha" placeholder={this.context.getLanguageResource("password")} defaultValue={this.state.login.senha} />
                                            <div className='w-100 p-0 m-0' style={{ height: '0px' }}>
                                                <span className={this.state.esconderSenha ? "bi bi-eye" : "bi bi-eye-slash"} onClick={() => this.setState({ esconderSenha: !this.state.esconderSenha })} style={{ cursor: 'pointer', position: 'relative', fontSize: '1.4rem', left: 'calc(100% - 2rem)', top: '-1.85rem'}}></span>
                                            </div>
                                        </div>
                                    </ValidationLabelMessage>
                                    <div className='w-100 d-flex justify-content-end py-1'>
                                        <Link to="/recoveryPassword"><Resource keyString="forgot_password" /></Link>
                                    </div>
                                </div>
                                <ValidationAlertMessage errors={this.state.login.error} name="Summary"/>
                                <button type="submit" className='btn w-100 mt-2' style={{ backgroundColor: 'var(--ws-blue)', color: this.aRotaEstaListadaNoJson() ? "white" : "black" }}><Resource keyString="access_the_system" /></button>
                                <section className='my-2'>
                                    <div>
                                        <a className='font-weight-bold' href='/registergrj'><Resource keyString="register_and_try_for_15_days_free" /></a>
                                    </div>
                                </section>
                            </form>
                        </main>

                        <footer>

                        </footer>
                    </LoginForm>
                </div>
                {
                    !this.aRotaEstaListadaNoJson() && (
                        <img
                            className='d-none d-md-block'
                            src={ChaveSombreada}
                            alt="O caractere de abertura de chave"
                            style={{
                                position: "absolute",
                                top: "50vh",
                                left: "50vw",
                                transform: "translate(-50%, -50%)",
                                width: "9rem",
                                zIndex: 100,
                            }}
                        />
                    )
                }
                <p style={{ position: "absolute", bottom: "0", right: "75vw", transform: 'translate(50%)' , zIndex: 150 }} className='text-dark font-weight-bold mb-0'>Powered by win.win {new Date().getFullYear()}</p>
            </React.Fragment>
        );
    }
}
