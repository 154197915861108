import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { ConfiguracaoFormularioData } from './FetchConfiguracaoFormulario';
import { HeadLayout } from '../../Shared/HeadLayout';
import { Layout } from '../../Layout';
import { HttpService } from '../../Shared/HttpService';
import { Loading } from '../../Shared/Loading';
import $ from 'jquery';
import { Resource } from '../../../index';
import { ValidationLabelMessage } from '../../Shared/ValidationMessage';
import { FormularioData } from '../Formulario/FetchFormulario';
import { FetchConfiguracaoFormularioXPergunta } from '../ConfiguracaoFormularioXPergunta/FetchConfiguracaoFormularioXPergunta';
import { LanguageContext } from '../../../context/LanguageContext';

interface AddConfiguracaoFormularioDataState {
    title: string;
    loading: boolean;
    empData: ConfiguracaoFormularioData;
    setores: SetorData[];
    setorId: number;
}

export class SetorData {
    id: number = 0;
    nome: string = "";
    setorpaiid: string = "";
    setor: any = {};
    error: Array<any> = [];
}

export class AddConfiguracaoFormulario extends React.Component<RouteComponentProps<{}>, AddConfiguracaoFormularioDataState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        var configurarFormularioid = this.props.match.params["configurarFormularioid"];
        var configuracaoFormulario = new ConfiguracaoFormularioData;
        configuracaoFormulario.formularioid = this.props.match.params["formularioid"];

        this.state = {
            title: "", loading: true,
            empData: configuracaoFormulario, setores: [], setorId: 0
        };

        // This will set state for Edit User  
        if (configurarFormularioid > 0) {

            Loading.showLoading(this);
            HttpService.fetch('api/ConfiguracaoFormularioApi/' + configurarFormularioid, { method: 'GET' })
                .then(response => response.json() as Promise<ConfiguracaoFormularioData>)
                .then(data => {
                    this.setState({ title:this.context.getLanguageResource("edit_form_configuration"), loading: false, empData: data });
                    Loading.hideLoading(this);
                }).catch(() => {
                    Loading.hideLoading(this);
                    props.history.push(`/configuracaoFormulario/${(this.state.empData.formularioid)}`);
                });

            Loading.showLoading(this);
            HttpService.fetch('api/SetorApi/ListSetores', {})
                .then(response => response.json() as Promise<SetorData[]>)
                .then(data => {
                    this.setState({ setores: data, loading: false });
                    Loading.hideLoading(this);
                });
        }

        // This will set state for Add User  
        else {
            this.state = {
                title: this.context.getLanguageResource("create_form_configuration"), loading: false,
                empData: configuracaoFormulario, setores: [], setorId: 0
            };
        }

        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    public render() {
        let contents = Loading.isLoading(this)
            ? <p><em></em></p>
            : this.renderCreateForm();

        return <div className="col-lg-12">
            <HeadLayout title={this.state.title} />
            <div className="card">
                <div className="card-header"><a href="/">{<Resource keyString="home" />}</a> / <a href="/marketPlace">{<Resource keyString="form_store" />}</a> / {<Resource keyString="form_configuration" />} / {this.state.title}</div>
                <div className="card-body">
                    <div className="card-title">
                        <h3 className="text-center title-2">{this.state.title}</h3>
                    </div>
                    <hr />
                    {contents}
                </div>
            </div>
        </div>;
    }

    // This will handle the submit form event.  
    private handleSave(event) {
        event.preventDefault();

        let configuracaoFormularioId = this.state.empData.id;

        Loading.showLoading(this);;

        // PUT request for Edit User.  
        if (configuracaoFormularioId) {

            HttpService.fetch('api/ConfiguracaoFormularioApi/' + configuracaoFormularioId, {
                method: 'PUT',
                body: HttpService.formToJson(event.target),
            }).then((response) => {
                return response.json() as Promise<ConfiguracaoFormularioData>
            }).then((data) => {

                this.setState({ empData: data })
                if ($.isEmptyObject(data.error)) {
                    this.redirectPage();
                }
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            })

        } else {

            HttpService.fetch('api/ConfiguracaoFormularioApi', {
                method: 'POST',
                body: HttpService.formToJson(event.target),
            }).then((response) => {
                return response.json() as Promise<ConfiguracaoFormularioData>
            }).then((data) => {

                this.setState({ empData: data })
                if ($.isEmptyObject(data.error)) {
                    this.redirectPage();
                }
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            })
        }
    }

    // This will handle Cancel button click event.  
    private handleCancel(e) {
        e.preventDefault();
        this.props.history.push(`/configuracaoFormulario/${(this.state.empData.formularioid)}`);
    }

    private updateSetorValue(evt) {
        this.setState({
            setorId: evt.target.value
        });
    }

    private redirectPage() {
        window.location.href = (`/configuracaoFormulario/edit/${this.state.empData.formularioid}/${this.state.empData.id}`);
       // this.props.history.push(`/configuracaoFormulario/${(this.state.empData.formularioid)}`);
    }

    // Returns the HTML Form to the render() method.  
    private renderCreateForm() {
        return (
            <React.Fragment>
                <form onSubmit={this.handleSave} >
                    <div className="form-group row" >
                        <input type="hidden" name="id" value={this.state.empData.id} />
                        <input type="hidden" name="formularioid" value={this.state.empData.formularioid} />
                    </div>
                    <div className="form-group row">
                        <div className="col-md-6">
                            <label className="control-label col-md-12 required" htmlFor="Nome" >{<Resource keyString="name" />}</label>
                            <div className="col-md-12">
                                <ValidationLabelMessage errors={this.state.empData.error} name="Nome">
                                    <input className="form-control" type="text" id="Nome" name="Nome" defaultValue={this.state.empData.nome} />
                                </ValidationLabelMessage>
                            </div>
                        </div>

                        {(this.state.empData.id > 0) ?
                            <div className="col-md-6">
                                <label className="control-label col-md-12" htmlFor="Nome" >{<Resource keyString="sector" />}</label>
                                <div className="col-md-12">
                                    <select className="form-control col-md-12" data-val="true" id="setorid" name="setorid"
                                        onChange={evt => this.updateSetorValue(evt)}>
                                        <option value="">-- {this.context.getLanguageResource("select")} --</option>
                                        {this.state.setores.map(x =>
                                            <option key={x.id} value={x.id}>{x.nome}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            : null}
                    </div >
                    <div className="form-group col-md-12">
                        <button type="submit" className="btn btn-primary mr-2"><i className='fa fa-save'></i> {<Resource keyString="save" />}</button>
                        <button className="btn btn-link" onClick={this.handleCancel}><i className='fa fa-arrow-left'></i> {<Resource keyString="back" />}</button>
                    </div >
                </form >
                {(this.state.empData.id > 0) ?
                    <div className="col-sm-12">
                        <FetchConfiguracaoFormularioXPergunta setores={this.state.setores} setorId={this.state.setorId} ConfiguracaoFormulario={this.state.empData} />
                    </div>
                    : null}
            </React.Fragment>)
    }

}  