import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { DiretorioData } from './FetchDiretorio';
import { HttpService } from '../Shared/HttpService';
import { Loading } from '../Shared/Loading';
import $ from 'jquery';
import { ValidationLabelMessage } from '../Shared/ValidationMessage';
import { Resource } from '../../index';
import { LanguageContext } from '../../context/LanguageContext';
import CaixaDeConteudo from '../Shared/CaixaDeConteudo';
import { SelectDeDiretorio } from '../Shared/components/SelectDeDiretorio';

interface AddDiretorioDataState {
    loading: boolean;
    empData: DiretorioData;
    diretorios: DiretorioData[];
}

export class AddDiretorio extends React.Component<RouteComponentProps<{}>, AddDiretorioDataState> {
    static contextType = LanguageContext;
    private _pathname;
    private _regexPaginaDeEdicao;
    private _estouNaPaginaDeEdicao;
    constructor(props) {
        super(props);
        this._pathname = window.location.pathname;
        this._regexPaginaDeEdicao = new RegExp(/diretorio\/edit/);
        this._estouNaPaginaDeEdicao = this._regexPaginaDeEdicao.test(this._pathname);

        var diretorioid = this.props.match.params["diretorioid"];

        var diretorio = new DiretorioData;
        diretorio.diretoriospaiid = this.props.match.params["repositorioid"];

        this.state = { loading: true, empData: diretorio, diretorios: [] };

        
        // This will set state for Edit User  
        if (diretorioid > 0) {

            Loading.showLoading(this);;

            HttpService.fetch('api/DiretoriosApi/' + diretorioid, { method: 'GET' })
                .then(response => response.json() as Promise<DiretorioData>)
                .then(data => {
                    this.setState({ loading: false, empData: data });
                    Loading.hideLoading(this);
                }).catch(() => {
                    Loading.hideLoading(this);
                    props.history.push("/repositorio");
                });
        }

        // This will set state for Add User  
        else {
            this.state = { loading: false, empData: diretorio, diretorios: [] };
        }


        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    private botoesDoCantoSuperiorDireito() {
        return (
            <React.Fragment>
                <button className="btn bg-white shadow-sm mr-2" onClick={this.handleCancel}>{<Resource keyString="back" />}</button>
            </React.Fragment>
        );
    }

    componentDidMount(): void {
        Loading.showLoading(this);
        HttpService.fetch('api/Repositorio/TodosOsDiretorios', { method: 'GET' })
            .then(response => response.json() as Promise<any[]>)
            .then(data => {
                this.setState({ loading: false, diretorios: data });
                Loading.hideLoading(this);
            });
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;

        return (
            <CaixaDeConteudo
                tituloDoHeader={this._estouNaPaginaDeEdicao ? this.context.getLanguageResource("edit_directory") : this.context.getLanguageResource("create_directory")}
                botoes={this.botoesDoCantoSuperiorDireito()}
                itensParaBreadcrumb={[
                    { nome: this._estouNaPaginaDeEdicao ? this.context.getLanguageResource("edit_directory") : this.context.getLanguageResource("create_directory") }
                ]}
                breadcrumbDinamico={{ id: this.state.empData.diretoriospaiid, tipo: "diretorio" }}
            >
                <main className='p-4'>
                    {this.renderCreateForm()}
                </main>
            </CaixaDeConteudo>
        );
    }

    // This will handle the submit form event.  
    private handleSave(event) {
        event.preventDefault();

        let diretorioId = this.state.empData.id;
        const payload = HttpService.formatData(event.target) as DiretorioData;
        payload.isactive = this.state.empData.isactive;

        Loading.showLoading(this);

        // PUT request for Edit User.  
        if (diretorioId) {

            HttpService.fetch('api/DiretoriosApi/' + diretorioId, {
                method: 'PUT',
                body: JSON.stringify(payload),
            }).then((response) => {
                return response.json() as Promise<DiretorioData>
            }).then((data) => {

                if ($.isEmptyObject(data.error)) {
                    this.redirectPage();
                }

                if (data.id === 0) {
                    this.setState({ empData: { ...this.state.empData, error: data.error } } );
                    Loading.hideLoading(this);
                    return;
                };

                this.setState({ empData: data })
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            })

        } else {

            HttpService.fetch('api/DiretoriosApi', {
                method: 'POST',
                body: JSON.stringify(payload),
            }).then((response) => {
                return response.json() as Promise<DiretorioData>
            }).then((data) => {

                if ($.isEmptyObject(data.error)) {
                    this.redirectPage();
                }
                this.setState({ empData: data })
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            })
        }
    }

    // This will handle Cancel button click event.  
    private handleCancel(e) {
        e.preventDefault();
        this.redirectPage();
    }

    private redirectPage() {
        if (this.state.empData.id > 0)
            this.props.history.push(`/diretorio/detail/${this.state.empData.id}`);
        else
            this.props.history.push(`/repositorio/${this.state.empData.diretoriospaiid}`);
    }

    handleSelectChange = (event) => {
        this.setState({ empData: { ...this.state.empData, diretoriospaiid: event.target.value } });
    }

    // Returns the HTML Form to the render() method.  
    private renderCreateForm() {
        return (<form onSubmit={this.handleSave} >
            <div className="form-group row" >
                <input type="hidden" name="userId" value={this.state.empData.id} />
                <input type="hidden" name="diretoriospaiid" value={this.state.empData.diretoriospaiid} />
                <input type="hidden" name="isactive" />
            </div>
            <div className="form-group row" >
                <label className=" control-label col-md-12 required" htmlFor="Name">{<Resource keyString="directory_name" />}</label>
                <div className="col-md-4">
                    <ValidationLabelMessage errors={this.state.empData.error} name="Nome">
                        <input className="form-control" type="text" name="Nome" defaultValue={this.state.empData.nome} />
                    </ValidationLabelMessage>
                </div>
            </div >
            <div className="form-group row">
                <div className="col-md-6">
                    <label className=" control-label" htmlFor="Diretoriospaiid">{<Resource keyString="directory" />}</label>
                    <SelectDeDiretorio
                        diretorios={this.state.diretorios}
                        diretorioSelecionado={this.state.empData.diretoriospaiid}
                        name={"diretoriospaiid"}
                        meuId={this.state.empData.id}
                    />
                </div>
            </div>
            <div className="form-group col-md-12 d-flex justify-content-center">
                <button type="submit" className="btn font-weight-bold" style={{ backgroundColor: 'var(--ws-blue)', width: '17.5rem' }}>{<Resource keyString="save" />}</button>
            </div >
        </form >)
    }

}  