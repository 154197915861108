import { IAnotacao, ICabecalho, IGrupoDePerguntas, IPergunta } from "../../interfaces/IResponderFormularioResponse"
import { ICargaAnotacao, ICargaCabecalho, ICargaGrupoDePerguntas, ICargaPergunta, ICargaRodape } from "../../interfaces/ICargaResponderFormulario"

export const montarCargaDeCabecalho = (cabecalho: ICabecalho[]): ICargaCabecalho[] => {
	return cabecalho.map(c => ({ id: c.id, titulo: c.titulo, valor: c.valor }))
};

export const montarCargaDeRodape = (rodape: ICabecalho[]): ICargaRodape[] => {
	return rodape.map(r => ({ id: r.id, titulo: r.titulo, valor: r.valor }))
};

const montarCargaDeAnotacoes = (anotacoes: IAnotacao[]): ICargaAnotacao[] => {
	return anotacoes.map(a => ({ id: a.id, dataHora: a.dataHora, mensagem: a.mensagem, usuario: a.usuario }))
}

const montarCargaDePerguntas = (perguntas: IPergunta[]): ICargaPergunta[] => {
	return perguntas.map(p => ({
		id: p.id,
		grupoPerguntaId: p.grupoPerguntaId,
		planoDeAcaoId: p.planoDeAcaoId,
		respostaSelecionada: p.respostaSelecionada,
		respostaSelecionadaId: p.respostaSelecionadaId,
		anotacoes: montarCargaDeAnotacoes(p.anotacoes),
		anotacao: p.anotacao,
		observacao: p.observacao,
		evidenciaAnexada: p.evidenciaAnexada,
		imagens: p.imagens,
		acoes: p.acoes
	}))
};

export const montarCargaDeGrupoDePerguntas = (grupoDePerguntas: IGrupoDePerguntas[]): ICargaGrupoDePerguntas[] => {
	return grupoDePerguntas.map(grupo => ({
		id: grupo.id,
		perguntas: montarCargaDePerguntas(grupo.perguntas),
		grupoDePerguntas: montarCargaDeGrupoDePerguntas(grupo.grupoDePerguntas),
	}))
};
