import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { HttpService } from '../Shared/HttpService';
import { Loading } from '../Shared/Loading';
import { Resource } from '../../index';
import { LanguageContext } from '../../context/LanguageContext';
import CaixaDeConteudo from '../Shared/CaixaDeConteudo';

interface FetchModeloPlanoAcaoState {
    modeloPlanoAcaoList: ModeloPlanoAcaoData[];
    loading: boolean;
}

export class ModeloPlanoAcaoData {
    id: number = 0;
    nome: string = "";
    descricao: string = "";
    empresasid: number = 0;
    isactive: boolean = true;
    datecreated: string = "";
    usercreatedid: number = 0;
    datemodified: string = "";
    usermodifiedid: number = 0;
    error: Array<any> = [];
}

export class FetchModeloPlanoAcao extends React.Component<RouteComponentProps<{}>, FetchModeloPlanoAcaoState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        this.state = {
            modeloPlanoAcaoList: [],
            loading: true
        };

        Loading.showLoading(this);;
        HttpService.fetch('api/ModeloPlanoAcaoApi', {})
            .then(response => response.json())
            .then(data => {
                this.setState({ modeloPlanoAcaoList: data, loading: false });
                this.forceUpdate();
                Loading.hideLoading(this);
            });

        this.handleEdit = this.handleEdit.bind(this);

    }

    handleDuplicaModeloPlanoAcao = (id) => {
        Loading.showLoading(this);

        const response = HttpService.fetch(`api/ModeloPlanoAcaoApi/DuplicaModeloPlanoAcao/${id}`, { method: 'GET' });

        setTimeout(() => {
            alert(this.context.getLanguageResource("this_operation_can_take_a_while_please_dont_close_your_browser"));
        }, 3000);

        response
            .then(res => {
                return res.json() as Promise<ModeloPlanoAcaoData>;
            })
            .then(data => {
                console.log(data);
                Loading.hideLoading(this);
                this.props.history.push(`/ModeloPlanoAcao/edit/${data.id}`);
            })
            .catch(() => {
                alert(this.context.getLanguageResource("something_went_wrong_when_duplicating"));
                Loading.hideLoading(this);
            })
    };

    private botoesDoCantoSuperiorDireito() {
        return (
            <button className='btn bg-white shadow-sm mr-2' onClick={() => this.props.history.push('/modeloPlanoAcao/add')}>
                <Resource keyString="add" />
            </button>
        );
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;

        return (
            <CaixaDeConteudo
                tituloDoHeader={this.context.getLanguageResource('action_plan_templates')}
                botoes={this.botoesDoCantoSuperiorDireito()}
                itensParaBreadcrumb={[
                    { nome: this.context.getLanguageResource('home'), url: "/" },
                    { nome: this.context.getLanguageResource('action_plan_templates') },
                ]}
            >
                <React.Fragment>
                    <header className='d-flex align-items-center justify-content-center w-100 p-4'>
                        <h3>{<Resource keyString="action_plan_templates" />}</h3>
                    </header>
                    <main className='p-4'>
                        {this.renderUserTable(this.state.modeloPlanoAcaoList)}
                    </main>
                </React.Fragment>
            </CaixaDeConteudo>
        );
    }


    private handleEdit(id: number) {
        this.props.history.push("/modeloPlanoAcao/edit/" + id);
    }

    // Returns the HTML table to the render() method.  
    private renderUserTable(modeloPlanoAcaoList: ModeloPlanoAcaoData[]) {
        return <table className='table'>
            <thead>
                <tr>
                    <th>{<Resource keyString="model_name" />}</th>
                    <th>{<Resource keyString="description" />}</th>
                    <th>{<Resource keyString="active" />}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {modeloPlanoAcaoList.map(doc =>
                    <tr key={doc.id}>
                        <td className='align-middle'>{doc.nome}</td>
                        <td className='align-middle'>{doc.descricao}</td>
                        <td className='align-middle'>
                            {
                                doc.isactive
                                    ?   <p className='mb-0 font-weight-bold text-success'><Resource keyString="yes" /></p>
                                    :   <p className='mb-0 font-weight-bold text-danger'><Resource keyString="no" /></p>
                            }
                        </td>
                        <td className='align-middle'>
                            <button className="btn ws-outline-green-brown mr-2 pull-right" style={{ width: '2.75rem', height: '2.75rem' }} onClick={() => this.handleEdit(doc.id)}><i className='fa fa-edit'></i></button>
                            <button className="btn btn-outline-primary mr-2 pull-right" style={{ width: '2.75rem', height: '2.75rem' }} onClick={() => this.handleDuplicaModeloPlanoAcao(doc.id)}><i className='fa fa-files-o'></i></button>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>;
    }
}   