import React, { useState, useEffect, useContext } from "react";
import ModalWinSet from "./components/ModalWinSet";
import { HttpService } from "./HttpService";
import { LoggedInContext } from "../../context/LoggedInContext";
import { TextoDeTermosDeAceite } from "./components/TextoDeTermosDeAceite";
import { LanguageContext } from "../../context/LanguageContext";
import { ILocalStoragePreferences } from "../../interfaces/ILocalStoragePreferences";

export const AceiteDeTermos = () => {
    const preferenciasLocalStorage = localStorage.getItem('preferencias');
    const preferencias: ILocalStoragePreferences = preferenciasLocalStorage ? JSON.parse(preferenciasLocalStorage) : {};
    const { termosAceitos, setTermosAceitos, cadastroNaoEstaCompleto } = useContext(LoggedInContext);
    const { getLanguageResource } = useContext(LanguageContext);
    const [naoMostrarNovamente, setNaoMostrarNovamente] = useState(preferencias.naoMostrarModalDePreenchimentoDeCamposIncompletos || false);
    const [flagDeAceiteDeTermos, setFlagDeAceiteDeTermos] = useState(false);
    const [modalDeCadastroNaoEstaCompletoEstaAberto, setModalDeCadastroNaoEstaCompletoEstaAberto] = useState(false);

    const aceitarTermosDeUso = async () => {
        if (flagDeAceiteDeTermos) {
            const response = await HttpService.fetch('api/Authentication/AceitarTermos', { method: 'POST' });
            const data = await response.json();
            setTermosAceitos(data.termosAceitos);
        } else {
            const caixaDeFeedbackInvalido = document.getElementById('feedback-invalido') as HTMLDivElement;
            caixaDeFeedbackInvalido.innerHTML = getLanguageResource('you_must_accept_the_terms_of_use_to_use_win_set');
        }
    };

    const handleCheckboxChange = (event) => {
        const caixaDeFeedbackInvalido = document.getElementById('feedback-invalido') as HTMLDivElement;
        caixaDeFeedbackInvalido.innerHTML = '';
        setFlagDeAceiteDeTermos(event.target.checked);
    };

    useEffect(() => {
        const preferenciasLocalStorage = localStorage.getItem('preferencias');
        const preferencias: ILocalStoragePreferences = preferenciasLocalStorage ? JSON.parse(preferenciasLocalStorage) : {};
        preferencias.naoMostrarModalDePreenchimentoDeCamposIncompletos = naoMostrarNovamente;
        localStorage.setItem('preferencias', JSON.stringify(preferencias));
    }, [naoMostrarNovamente]);

    useEffect(() => {
        const preferenciasLocalStorage = localStorage.getItem('preferencias');
        const preferencias: ILocalStoragePreferences = preferenciasLocalStorage ? JSON.parse(preferenciasLocalStorage) : {};

        if (termosAceitos && cadastroNaoEstaCompleto && !window.location.href.includes('minhaEmpresa') && !preferencias.naoMostrarModalDePreenchimentoDeCamposIncompletos) {
            setModalDeCadastroNaoEstaCompletoEstaAberto(true);
        }
    }, [cadastroNaoEstaCompleto, termosAceitos]);

    return (
        <React.Fragment>
            {
                termosAceitos
                    ?   <React.Fragment />
                    :   (
                            <ModalWinSet
                                fecharModal={() => {}}
                                zIndex={1001}
                            >
                                <div className="d-flex flex-column p-4" style={{ maxWidth: '40rem', minWidth: '30rem', maxHeight: '80vh' }}>
                                    <section className="m-2 p-4 border rounded barra_de_rolagem_estilizada" style={{ overflowY: 'auto' }}>
                                        <TextoDeTermosDeAceite />
                                    </section>
                                    <section className="d-flex flex-column align-items-center m-2">
                                        <div>
                                            <input type="checkbox" name="aceitarTermos" id="aceitarTermos" checked={flagDeAceiteDeTermos} onChange={handleCheckboxChange} />
                                            <label htmlFor="aceitarTermos" className="ml-2">{ getLanguageResource('i_have_read_and_accept_the_terms_of_use') }</label>
                                        </div>
                                        <div className="text-danger" id="feedback-invalido" style={{ height: '2rem', fontSize: '.75rem' }} />
                                        <div className="d-flex justify-content-center py-2 w-100">
                                            <button className="btn ws-outline-blue" onClick={() => aceitarTermosDeUso()}>{ getLanguageResource('confirm') }</button>
                                        </div>
                                    </section>
                                </div>
                            </ModalWinSet>
                        )
            }
            {
                modalDeCadastroNaoEstaCompletoEstaAberto
                    ?   (
                            <ModalWinSet
                                fecharModal={() => setModalDeCadastroNaoEstaCompletoEstaAberto(false)}
                                zIndex={1000}
                            >
                                <div className="d-flex flex-column p-4" style={{ maxWidth: '40rem', minWidth: '30rem', maxHeight: '80vh' }}>
                                    <section>
                                        { getLanguageResource('your_registration_has_incomplete_fields_click_the_button_below_to_complete_your_registration') }
                                    </section>
                                    <section className="my-2">
                                        <input type="checkbox" id="naoMostrarNovamente" checked={ naoMostrarNovamente } onClick={ () => setNaoMostrarNovamente(!naoMostrarNovamente) } />
                                        <label htmlFor="naoMostrarNovamente" className="ml-2">{ getLanguageResource('do_not_show_again') }</label>
                                    </section>
                                    <div className="d-flex justify-content-center py-2 w-100">
                                        <button className="btn mr-2 ws-outline-blue" onClick={() => setModalDeCadastroNaoEstaCompletoEstaAberto(false)}>
                                            <i className="bi bi-x-lg mr-2" />
                                            { getLanguageResource('close') }
                                        </button>
                                        <button className="btn ws-outline-blue" onClick={() => window.location.href = '/minhaEmpresa'}>
                                            { getLanguageResource('confirm_data') }
                                        </button>
                                    </div>
                                </div>
                            </ModalWinSet>
                        )
                    :   <React.Fragment />
            }
        </React.Fragment>
    );
};
