import React from "react";
import ExclamationCircleIcon from "../../../icons/ExclamationCircleIcon";

const AcaoDeData = ({ id, titulo, valor, className, idDaPergunta, funcaoModificadora, obrigatorio }) => (
    <div className='form-group ContainerDeAcao'>
        <label>{titulo}</label>
        {
            obrigatorio && !valor
                ? <ExclamationCircleIcon fill='#ef0000' className='ml-1' />
                : <React.Fragment />
        }
        <input
            className={className}
            name='acao'
            id={`item-de-acao-${id}-da-pergunta-${idDaPergunta}`}
            type="date"
            value={valor}
            onChange={(event) => funcaoModificadora(event)}
        />
    </div>
);

export default AcaoDeData;