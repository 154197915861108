import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { Layout } from '../Layout';
import { HttpService } from '../Shared/HttpService';
import { HeadLayout } from '../Shared/HeadLayout';
import { Loading } from '../Shared/Loading';
import $ from 'jquery';
import { Resource } from '../../index';
import { Pointer } from 'highcharts';

interface IState {
    formulariosCompartilhados: Array<any>;
    search: "";
    dataInicial: any;
    dataFinal: any;
    isFormularioChecked: boolean;
    isUsuarioChecked: boolean;
    nomeUsuario: any;
    filterArrayValue: Array<any>;
    arrayTemp: any;

    lastColumnSort: string;
    paginationNumber: number;
    paginationCount: number;
    data: Array<any>;
    dataRender: Array<any>;
    filterValues: any;
}

export class DadosRelatorioFormularioCompartilhadoData {
    NomeCompartilhado: any;
    DataCriacao: any;
    NomeFormulario: any;
    NomeUsuario: any;
}

export class RelatorioGeralLeo extends React.Component<RouteComponentProps<{}>, IState> {
    constructor(props) {
        super(props);

        var arrayDados = [
            { 'Name': 'Abc', 'Age': '15', 'Age1': '15', 'Age2': '15', 'Location': 'Bangalore' },
            { 'Name': 'Def', 'Age': '43', 'Age1': '43', 'Age2': '43', 'Location': 'Mumbai' },
            { 'Name': 'Uff', 'Age': '30', 'Age1': '30', 'Age2': '30', 'Location': 'Chennai' },
            { 'Name': 'Ammse', 'Age': '87', 'Age1': '87', 'Age2': '87', 'Location': 'Delhi' },
            { 'Name': 'Yysse', 'Age': '28', 'Age1': '28', 'Age2': '28', 'Location': 'Hyderabad' },
            { 'Name': 'Abc', 'Age': '15', 'Age1': '15', 'Age2': '15', 'Location': 'Bangalore' },
            { 'Name': 'Def', 'Age': '43', 'Age1': '43', 'Age2': '43', 'Location': 'Mumbai' },
            { 'Name': 'Uff', 'Age': '30', 'Age1': '30', 'Age2': '30', 'Location': 'Chennai' },
            { 'Name': 'Ammse', 'Age': '87', 'Age1': '87', 'Age2': '87', 'Location': 'Delhi' },
            { 'Name': 'Yysse', 'Age': '28', 'Age1': '28', 'Age2': '28', 'Location': 'Hyderabad' },
            { 'Name': 'Abc', 'Age': '15', 'Age1': '15', 'Age2': '15', 'Location': 'Bangalore' },
            { 'Name': 'Def', 'Age': '43', 'Age1': '43', 'Age2': '43', 'Location': 'Mumbai' },
            { 'Name': 'Uff', 'Age': '30', 'Age1': '30', 'Age2': '30', 'Location': 'Chennai' },
            { 'Name': 'Ammse', 'Age': '87', 'Age1': '87', 'Age2': '87', 'Location': 'Delhi' },
            { 'Name': 'Yysse', 'Age': '28', 'Age1': '28', 'Age2': '28', 'Location': 'Hyderabad' },
            { 'Name': 'Abc', 'Age': '15', 'Age1': '15', 'Age2': '15', 'Location': 'Bangalore' },
            { 'Name': 'Def', 'Age': '43', 'Age1': '43', 'Age2': '43', 'Location': 'Mumbai' },
            { 'Name': 'Uff', 'Age': '30', 'Age1': '30', 'Age2': '30', 'Location': 'Chennai' },
            { 'Name': 'Ammse', 'Age': '87', 'Age1': '87', 'Age2': '87', 'Location': 'Delhi' },
            { 'Name': 'Yysse', 'Age': '28', 'Age1': '28', 'Age2': '28', 'Location': 'Hyderabad' },
            { 'Name': 'Abc', 'Age': '15', 'Age1': '15', 'Age2': '15', 'Location': 'Bangalore' },
            { 'Name': 'Def', 'Age': '43', 'Age1': '43', 'Age2': '43', 'Location': 'Mumbai' },
            { 'Name': 'Uff', 'Age': '30', 'Age1': '30', 'Age2': '30', 'Location': 'Chennai' },
            { 'Name': 'Ammse', 'Age': '87', 'Age1': '87', 'Age2': '87', 'Location': 'Delhi' },
            { 'Name': 'Yysse', 'Age': '28', 'Age1': '28', 'Age2': '28', 'Location': 'Hyderabad' },
            { 'Name': 'Abc', 'Age': '15', 'Age1': '15', 'Age2': '15', 'Location': 'Bangalore' },
            { 'Name': 'Def', 'Age': '43', 'Age1': '43', 'Age2': '43', 'Location': 'Mumbai' },
            { 'Name': 'Uff', 'Age': '30', 'Age1': '30', 'Age2': '30', 'Location': 'Chennai' },
            { 'Name': 'Ammse', 'Age': '87', 'Age1': '87', 'Age2': '87', 'Location': 'Delhi' },
            { 'Name': 'Yysse', 'Age': '28', 'Age1': '28', 'Age2': '28', 'Location': 'Hyderabad' },
            { 'Name': 'Abc', 'Age': '15', 'Age1': '15', 'Age2': '15', 'Location': 'Bangalore' },
            { 'Name': 'Def', 'Age': '43', 'Age1': '43', 'Age2': '43', 'Location': 'Mumbai' },
            { 'Name': 'Uff', 'Age': '30', 'Age1': '30', 'Age2': '30', 'Location': 'Chennai' },
            { 'Name': 'Ammse', 'Age': '87', 'Age1': '87', 'Age2': '87', 'Location': 'Delhi' },
            { 'Name': 'Yysse', 'Age': '28', 'Age1': '28', 'Age2': '28', 'Location': 'Hyderabad' },
        ];

        this.state = {
            formulariosCompartilhados: [],
            search: "",
            dataInicial: "",
            dataFinal: "",
            isFormularioChecked: true,
            isUsuarioChecked: false,
            nomeUsuario: "",
            filterArrayValue: [],
            arrayTemp: {},

            lastColumnSort: "",
            paginationNumber: 1,
            paginationCount: 10,
            filterValues: {},
            data: arrayDados,
            dataRender: arrayDados,
        };

        this.Pesquisar = this.Pesquisar.bind(this);
        this.FormatarData = this.FormatarData.bind(this);
        this.handleDataIncio = this.handleDataIncio.bind(this);
        this.handleDataFinal = this.handleDataFinal.bind(this);
        //this.handleNomeFormulario = this.handleNomeFormulario.bind(this); 
        this.handleNomeUsuario = this.handleNomeUsuario.bind(this);
        this.dataPradraoParaInputDate = this.dataPradraoParaInputDate.bind(this);

        this.getHeader = this.getHeader.bind(this);
        this.getRowsData = this.getRowsData.bind(this);
        this.getKeys = this.getKeys.bind(this);

        this.filterTable = this.filterTable.bind(this);
    }

    Pesquisar(event) {
        event.preventDefault();
        Loading.showLoading(this);;

        HttpService.fetch('api/RelatorioFormulariosCompartilhadosApi/ListarRelatorioFormulariosCompartilhados', {
            method: 'Post',
            body: HttpService.formToJson(event.target)
        })
            .then((response) => {
                return response.json();
            })
            .then(data => {
                this.setState({ formulariosCompartilhados: data });
                Loading.hideLoading(this);
            }
            );


    }

    componentDidMount() {
        // this.dataPradraoParaInputDate();
    }

    FormatarData(data: any) {
        var dataFormatada = data.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1');
        return dataFormatada;
    }

    dataPradraoParaInputDate() {
        var data = new Date(),
            dia = data.getDate().toString(),
            diaF = (dia.length == 1) ? '0' + dia : dia,
            mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
            mesF = (mes.length == 1) ? '0' + mes : mes,
            anoF = data.getFullYear(),
            dataFormatada = anoF + "-" + mesF + "-" + diaF;

        this.setState({ dataInicial: dataFormatada, dataFinal: dataFormatada });

        return dataFormatada
    }

    handleDataIncio(e: any) {
        var dateInicial = new Date(e.target.value.split('/').reverse().join('/'));
        var dateFinal = new Date(this.state.dataFinal.split('/').reverse().join('/'));

        if (dateInicial > dateFinal) {
            this.setState({ dataInicial: this.state.dataFinal }); //A dataInicial vai receber a dataFinal se a dataInicial for maior que dataFinal.
        }
        else {
            this.setState({ dataInicial: e.target.value });
        }


    }

    handleDataFinal(e: any) {

        var dateFinal = new Date(e.target.value.split('/').reverse().join('/'));
        var dateInicial = new Date(this.state.dataFinal.split('/').reverse().join('/'));

        if (dateFinal < dateInicial) {
            this.setState({ dataFinal: this.state.dataInicial }); //A dataFinal vai receber a dataInicail se a dataFinal for menor que dataInicial.
        }
        else {
            this.setState({ dataFinal: e.target.value });
        }

    }

    handleNomeUsuario(e: any) {
        this.setState({ nomeUsuario: e.target.value });
    }

    getKeys = () => {
        return Object.keys(this.state.data[0]);
    }

    orderBy = (key) => {
        var dataRender = this.state.dataRender;
        dataRender.sort(function (a, b) { return (a[key] < b[key]) ? -1 : 1 });
        if (key == this.state.lastColumnSort) {
            dataRender.reverse();
            key = "";
        }
        this.setState({ dataRender: dataRender, paginationNumber: 1, lastColumnSort: key });
    }

    getHeader = () => {
        var keys = this.getKeys();
        return keys.map((key, index) => {
            return <th key={key}>
                <span onClick={() => this.orderBy(key)}>{key.toUpperCase()}</span>
                <div className="form-group">
                    <input type="text" className="form-control form-control-sm" onChange={(e) => this.filterTable(e, key)} />
                </div>
            </th>
        })
    }

    filterTable(e, key) {
        var filterValues = this.state.filterValues;
        filterValues[key] = e.target.value;

        this.applyFilter();
    }

    applyFilter = () => {
        var data = [...this.state.data];

        var keysFilterValues = Object.keys(this.state.filterValues);

        var dataRender: Array<any> = [];

        data.filter((o, i) => {
            var deuMatch = true;
            for (var i = 0; i < keysFilterValues.length; i++) {
                var key = keysFilterValues[i];
                var value = this.state.filterValues[key];

                if (!(o[key].toLowerCase().indexOf(value.toLowerCase()) >= 0)) {
                    deuMatch = false;
                    break;
                }
            }

            if (deuMatch == true) {
                dataRender.push(o);
            }

        });

        this.setState({ dataRender: dataRender, paginationNumber: 1 });

    }

    filterByPaginate = () => {
        var paginationNumber = this.state.paginationNumber;
        var paginationCount = this.state.paginationCount;
        var dataRender = this.state.dataRender;

        var dataTemp: Array<any> = [];
        var initial = ((paginationNumber - 1) * paginationCount);
        var max = (((paginationNumber - 1) * paginationCount) + paginationCount);
        for (var i = initial; i < max; i++) {
            dataTemp.push(dataRender[i]);
        }

        return dataTemp;
    }

    RenderRow = (item) => {
        return this.getKeys().map((key, index) => {
            return <td key={"td"+index}>{item[key]}</td>
        })
    }

    getRowsData = () => {
        var items = this.filterByPaginate();
        return items.map((item, index) => {
            if (!!item) {
                return <tr key={"tr"+index}>{this.RenderRow(item)}</tr>
            }
        })
    }

    handleChangePaginationNumber = (number) => {
        this.setState({ paginationNumber: number });
    }

    handleChangePaginationCount = (count) => {
        this.setState({ paginationCount: parseInt(count) });
    }

    getPaginationNumber = () => {
        var elements: Array<any> = [];
        var paginationCount = this.state.paginationCount;
        var countDataRender = this.state.dataRender.length;
        if (countDataRender > 0) {
            for (let i = 1; i <= Math.ceil(countDataRender / paginationCount); i++) {
                elements.push(<button className="btn btn-sm btn-info m-1" onClick={() => this.handleChangePaginationNumber(i)}>{i}</button>);
            }
        }
        return elements
    }

    exportCSV = () => {
        let csvContent = "data:text/csv;charset=utf-8,";
        var data = this.state.dataRender;
        var dataRender = this.state.dataRender;
        var divisor = ";";

        var obj = data[0];
        var row = "";
        Object.keys(obj).forEach((o, i) => {
            if (row) {
                row += divisor;
            }
            row += o;
        });
        csvContent += row + "\r\n";

        dataRender.forEach(function (item) {
            var row = "";
            Object.keys(item).forEach((o, i) => {
                if (row) {
                    row += divisor;
                }
                row += item[o];
            });
            csvContent += row + "\r\n";
        });

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "my_data.csv");
        document.body.appendChild(link); // Required for FF

        link.click(); // This will download the data file named "my_data.csv".
    }

    public render() {

        return <div className="col-lg-12">
            <HeadLayout title="Relatório Geral" />

            <div className="card">
                <div className="card-header"><a href="/">{<Resource keyString="home" />}</a> / Relatório Geral</div>
                <div className="card-body">
                    <div className="tableRelatorioGeral">

                        <div className="row mb-2 p-1">
                            <div className="col-2">
                                <button className="btn btn-primary btn-sm mr-1 btn-block" onClick={() => this.exportCSV()}>Exportar CSV</button>
                            </div>
                            <select className="form-control form-control-sm col-1" onChange={(e) => this.handleChangePaginationCount(e.target.value)}>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>

                        <table className="table table-striped text-center w-100">
                            <thead>
                                <tr>{this.getHeader()}</tr>
                            </thead>
                            <tbody>
                                {this.getRowsData()}
                            </tbody>
                        </table>
                    </div>
                    <div className="float-right">
                        {this.getPaginationNumber()}
                    </div>
                </div>

            </div>

        </div>;
    }
}
