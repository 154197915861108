import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { Layout } from '../Layout';
import { HttpService } from '../Shared/HttpService';
import { HeadLayout } from '../Shared/HeadLayout';
import { Loading } from '../Shared/Loading';
import $ from 'jquery';
import { Resource } from '../../index';
import { Pointer } from 'highcharts';
import Select from 'react-select';
import { LanguageContext } from '../../context/LanguageContext';

import "./styles.css"
import { ValidationLabelMessage } from '../Shared/ValidationMessage';
import CaixaDeConteudo from '../Shared/CaixaDeConteudo';

interface IState {
    listFormName: Array<any>;
    lastColumnSort: string;
    paginationNumber: number;
    paginationCount: number;
    data: Array<any>;
    dataRender: Array<any>;
    filterValues: any;
    hiddenTable: boolean;
    error: Array<any>;
}

export class RelatorioGeral extends React.Component<RouteComponentProps<{}>, IState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        this.state = {
            listFormName: [],
            lastColumnSort: "",
            paginationNumber: 1,
            paginationCount: 10,
            filterValues: {},
            data: [],
            dataRender: [],
            hiddenTable: true,
            error: [],
        };

        Loading.showLoading(this);
        HttpService.fetch('api/FormularioXEmpresaApi/FormularioComprado', {
            method: 'Get',
        })
            .then((response) => {
                return response.json();
            })
            .then(data => {
                this.setState({ listFormName: data });
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            });

        this.Pesquisar = this.Pesquisar.bind(this);

        this.getHeader = this.getHeader.bind(this);
        this.getRowsData = this.getRowsData.bind(this);
        this.getKeys = this.getKeys.bind(this);

        this.filterTable = this.filterTable.bind(this);
    }

    Pesquisar(event) {
        event.preventDefault();
        Loading.showLoading(this);;

        HttpService.fetch('api/RelatorioGeral/ListaRelatorioGeral', {
            method: 'Post',
            body: HttpService.formToJson(event.target)
        })
            .then((response) => {
                return response.json();
            })
            .then(data => {
                this.setState({ data: data, dataRender: data, error: data.error, hiddenTable: false });
                Loading.hideLoading(this);
            }
            );
    }

    getKeys = () => {
        return Object.keys(this.state.data[0] != null ? this.state.data[0] : "");
    }

    orderBy = (key) => {
        var dataRender = this.state.dataRender;
        dataRender.sort(function (a, b) { return (a[key] < b[key]) ? -1 : 1 });
        if (key == this.state.lastColumnSort) {
            dataRender.reverse();
            key = "";
        }
        this.setState({ dataRender: dataRender, paginationNumber: 1, lastColumnSort: key });
    }

    getHeader = () => {
        var keys = this.getKeys();
        return keys.map((key, index) => {
            return <th key={key}>
                <div className="form-group">
                    <label
                        style={{ cursor: "pointer", whiteSpace: 'nowrap' }}
                        onClick={() => this.orderBy(key)}
                    >
                        {this.context.getLanguageResource(key).toUpperCase()}
                    </label>
                    <input
                        type="text"
                        className="form-control form-control-sm"
                        onChange={(e) => this.filterTable(e, key)}
                    />
                </div>
            </th>
        })
    }

    filterTable(e, key) {
        var filterValues = this.state.filterValues;
        filterValues[key] = e.target.value;

        this.applyFilter();
    }

    applyFilter = () => {
        var data = [...this.state.data];

        var keysFilterValues = Object.keys(this.state.filterValues);

        var dataRender: Array<any> = [];

        data.filter((o, i) => {
            var deuMatch = true;
            for (var i = 0; i < keysFilterValues.length; i++) {
                var key = keysFilterValues[i];
                var value = this.state.filterValues[key];

                if (!(o[key].toLowerCase().indexOf(value.toLowerCase()) >= 0)) {
                    deuMatch = false;
                    break;
                }
            }

            if (deuMatch == true) {
                dataRender.push(o);
            }

        });

        this.setState({ dataRender: dataRender, paginationNumber: 1 });

    }

    filterByPaginate = () => {
        var paginationNumber = this.state.paginationNumber;
        var paginationCount = this.state.paginationCount;
        var dataRender = this.state.dataRender;

        var dataTemp: Array<any> = [];
        var initial = ((paginationNumber - 1) * paginationCount);
        var max = (((paginationNumber - 1) * paginationCount) + paginationCount);
        for (var i = initial; i < max; i++) {
            dataTemp.push(dataRender[i]);
        }

        return dataTemp;
    }

    RenderRow = (item) => {
        return this.getKeys().map((key, index) => {
            return <td style={{ minWidth: item[key].length > 70 ? '25rem' : '' }}  key={"td" + index}>{item[key]}</td>
        })
    }

    getRowsData = () => {
        var items = this.filterByPaginate();
        return items.map((item, index) => {
            if (!!item) {
                return <tr key={"tr" + index}>{this.RenderRow(item)}</tr>
            }
        })
    }

    handleChangePaginationNumber = (number) => {
        this.setState({ paginationNumber: number });
    }

    handleChangePaginationCount = (count) => {
        this.setState({ paginationCount: parseInt(count) });
    }

    nextHandler = () => {
        var paginationCount = this.state.paginationCount;
        var countDataRender = this.state.dataRender.length
        var total = Math.ceil(countDataRender / paginationCount);

        if (this.state.paginationNumber < total) {
            let active = this.state.paginationNumber;
            this.setState({ paginationNumber: active + 1 });
        }
    };

    previousHandler = () => {
        if (this.state.paginationNumber > 1) {
            let active = this.state.paginationNumber;
            this.setState({ paginationNumber: active - 1 });
        }
    };

    getPaginationNumber = () => {
        var elements: Array<any> = [];
        var paginationCount = this.state.paginationCount;
        var countDataRender = this.state.dataRender.length;
        var quantidadeElements = Math.ceil(countDataRender / paginationCount);

        var posicaoInicial = this.state.paginationNumber;
        var numeroExibidoPorVez = quantidadeElements >= 10 ? 10 : quantidadeElements;

        var quantidadeElementosPaginacao = 0;

        if (posicaoInicial > (quantidadeElements - numeroExibidoPorVez)) {
            quantidadeElementosPaginacao = quantidadeElements + 1;
            posicaoInicial = (quantidadeElements - numeroExibidoPorVez) + 1;
        }
        else {
            quantidadeElementosPaginacao = numeroExibidoPorVez + posicaoInicial;
        }

        if (countDataRender > 0) {
            for (let i = posicaoInicial; i < quantidadeElementosPaginacao; i++) {
                if (i == this.state.paginationNumber) {
                    elements.push(
                        <button
                            className="btn"
                            style={{ backgroundColor: 'var(--ws-blue-light)' }}
                            onClick={() => this.handleChangePaginationNumber(i)}
                        >
                            {i}
                        </button>
                    );
                }
                else {
                    elements.push(
                        <button
                            className="btn"
                            style={{ backgroundColor: 'var(--ws-blue)' }}
                            onClick={() => this.handleChangePaginationNumber(i)}
                        >
                            {i}
                        </button>
                    );
                }
            }
        }

        return elements
    }

    exportCSV = () => {
        let csvContent = "data:text/csv;charset=utf-8,\uFEFF";
        var data = this.state.dataRender;
        var dataRender = this.state.dataRender;
        var divisor = ";";

        var obj = data[0];
        var row = "";
        Object.keys(obj).forEach((o, i) => {
            if (row) {
                row += divisor;
            }
            row += this.context.getLanguageResource(o);
        });
        csvContent += row + "\r\n";

        dataRender.forEach(function (item) {
            var row = "";
            Object.keys(item).forEach((o, i) => {
                if (row) {
                    row += divisor;
                }
                row += item[o];
            });
            csvContent += row + "\r\n";
        });

        var encodedUri = encodeURI(csvContent);
        var fixedEncodedURI = encodedUri.replace(/#/g, '%23');
        var link = document.createElement("a");
        link.setAttribute("href", fixedEncodedURI);
        link.setAttribute("download", "my_data.csv");
        document.body.appendChild(link); // Required for FF

        link.click(); // This will download the data file named "my_data.csv".
    }

    componentDidUpdate(): void {
        const conteudoDaPesquisa = document.getElementById("conteudo-da-pesquisa");
        if (conteudoDaPesquisa) {
            conteudoDaPesquisa.onwheel = (e) => {
                e.preventDefault();
                conteudoDaPesquisa.scrollLeft += e.deltaY;

                // precisa definir que se o scroll
            };
        }
    }

    returnFirstDayFrom6MonthsAgo = () => {
        const date = new Date();
        date.setMonth(date.getMonth() - 6);
        date.setDate(1);
        return date.toISOString().split('T')[0];
    }

    public render() {
        return (
            <CaixaDeConteudo
                tituloDoHeader={this.context.getLanguageResource("general_report")}
                itensParaBreadcrumb={[
                    { url: "/", nome: this.context.getLanguageResource("home") },
                    { nome: this.context.getLanguageResource("general_report") }
                ]}
                botoes={[]}
            >
                <div className='p-4'>
                    <form onSubmit={this.Pesquisar} className='w-100'>
                        <div className='row'>
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="m-0 required">{<Resource keyString="form_name" />}</label>
                                    <ValidationLabelMessage errors={this.state.error} name="NomeFormulario">
                                        <Select
                                            placeholder={<Resource keyString="select" />}
                                            options={this.state.listFormName.map((x, i) => ({ label: x.nome, value: x.id }))}
                                            name="NomeFormularioId"
                                        />
                                    </ValidationLabelMessage>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="m-0">{<Resource keyString="creative_auditor" />}</label>
                                    <input className="form-control rounded" type="text" id="dataPrimeiraResposta" name="NomeAuditor" />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group ">
                                    <label className="m-0">{<Resource keyString="last_reply_date" />}</label>
                                    <input className="form-control rounded" id="dataUltimaResposta" name="DataUltimaResposta" type="date" />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label className="m-0">{<Resource keyString="initial_date_of_completion_of_the_form" />}</label>
                                    <input className="form-control rounded" defaultValue={this.returnFirstDayFrom6MonthsAgo()} type="date" id="dataPrimeiraResposta" name="DataInicialFinalizarFormulario" required />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label className="m-0">{<Resource keyString="final_form_final_date" />}</label>
                                    <input className="form-control rounded" defaultValue={new Date().toISOString().split('T')[0]} type="date" id="dataPrimeiraResposta" name="DataFinalFinalizarFormulario" required />
                                </div>
                            </div>
                            <div className="form-group d-flex justify-content-center my-3 mx-1 w-100">
                                <button
                                    type="submit"
                                    className="btn btn-default font-weight-bold mr-2"
                                    style={{ backgroundColor: 'var(--ws-blue)', width: '17.5rem', borderRadius: '0.3rem' }}
                                >
                                    <Resource keyString="search" />
                                </button>
                            </div >
                        </div>
                    </form>

                    <hr />

                    <div className="w-100" hidden={this.state.hiddenTable}>
                        {
                            this.state.data.length > 0
                                ?   (
                                        <div className="w-100">
                                            <div className="d-flex my-3">
                                                <div className='w-50 d-flex align-items-center'>
                                                    <button className="btn btn-default font-weight-bold mr-4" style={{ backgroundColor: 'var(--ws-blue)', width: '17.5rem', borderRadius: '0.3rem', whiteSpace: 'nowrap' }} onClick={() => this.exportCSV()}>{<Resource keyString="export_CSV" />}</button>
                                                    <label className='border-left pl-3 mr-3 mb-0' style={{ whiteSpace: 'nowrap' }}>Itens por página:</label>
                                                    <select className="form-control" onChange={(e) => this.handleChangePaginationCount(e.target.value)}>
                                                        <option value="10">10</option>
                                                        <option value="25">25</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='barra_de_rolagem_estilizada' id='conteudo-da-pesquisa' style={{ overflowX: 'auto' }}>
                                                <table className="table text-center">
                                                    <thead className="table-info">
                                                        <tr>{this.getHeader()}</tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.getRowsData()}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )
                                :   (
                                        <div className="alert alert-info" role="alert">
                                            {<Resource keyString="no_data_was_found_for_this_result" />}
                                        </div>
                                    )
                        }

                        <div className='w-100 d-flex justify-content-center my-3'>
                            <div className="btn-group shadow">
                                <button className="btn" style={{ backgroundColor: 'var(--ws-blue-soft)' }} onClick={() => this.previousHandler()}>anterior</button>
                                {this.getPaginationNumber()}
                                <button className="btn" style={{ backgroundColor: 'var(--ws-blue-soft)' }} onClick={() => this.nextHandler()}>proximo</button>
                            </div>
                        </div>
                    </div>
                </div>
            </CaixaDeConteudo>
        );
    }
}
