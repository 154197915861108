import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { Layout } from '../Layout';
import { HttpService } from '../Shared/HttpService';
import { Loading } from '../Shared/Loading';
import { SetorData } from '../Setores/FetchSetor';
import { RegraData, AddRegra } from './AddRegra';
import { ModalDelete } from '../Shared/ModalDelete';
import { Resource } from '../../index';
import { LanguageContext } from '../../context/LanguageContext';

interface ListaRegraVinculadaState {
    regras: RegraData[];
    usuariosId: number;
    minhaConta: boolean;
}

export class ListaDeRegrasVinculadasMinhaConta extends React.Component<any, ListaRegraVinculadaState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        this.state = { regras: [], usuariosId: props.usuariosId, minhaConta: props.minhaConta };

        this.updateRegras();

        this.handleDelete = this.handleDelete.bind(this);
    }

    public render() {
        return (
            <React.Fragment>
                {
                    (!this.state.minhaConta)
                        ?   <AddRegra usuariosid={this.state.usuariosId} callBack={this} />
                        :   <React.Fragment />
                }

                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th className='text-center font-weight-light' style={{ fontSize: '1.6rem' }}>{<Resource keyString="sector" />}</th>
                            <th className='text-center font-weight-light' style={{ fontSize: '1.6rem' }}>{<Resource keyString="function" />}</th>
                            <th className='text-center font-weight-light' style={{ fontSize: '1.6rem' }}>{<Resource keyString="profile" />}</th>
                            <th className='text-center font-weight-light' style={{ fontSize: '1.6rem' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.regras.map(doc =>
                            <tr key={doc.id}>
                                <td className='text-center'>{doc.setor.nome}</td>
                                <td className='text-center'>{doc.funcao.nome}</td>
                                <td className='text-center'>{doc.perfil.nome}</td>
                                <td className='text-center'>
                                    {
                                        (this.state.minhaConta != true) ?
                                            <React.Fragment>
                                                <button className="btn btn-sm btn-outline-danger mr-2 pull-right" data-toggle="modal" data-target={`#delete${doc.id}`}><i className='fa fa-trash'></i> {<Resource keyString="delete" />}</button>
                                                <ModalDelete id={doc.id} callBack={this} message={`${this.context.getLanguageResource("do_you_want_to_remove_the_rule")}: ${doc.setor.nome}?`} />
                                            </React.Fragment>
                                            : null
                                    }
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </React.Fragment>
        );
    }

    public updateRegras() {
        Loading.showLoading(this);;

        HttpService.fetch(`api/UsuarioxFuncaoxSetorxPerfilApi/Lista/${this.state.usuariosId}`, {})
            .then(response => response.json() as Promise<RegraData[]>)
            .then(data => {
                this.setState({ regras: data });
                Loading.hideLoading(this);
            }).catch((e)=> {

                console.log(e);
                Loading.hideLoading(this);
            });;

    }

    private handleDelete(id: number) {
        HttpService.fetch(`api/UsuarioxFuncaoxSetorxPerfilApi/${id}`, { method: 'DELETE' })
            .then(response => response.json() as Promise<boolean>)
            .then(data => {
                Loading.hideLoading(this);
                this.updateRegras();
            }).catch(() => {
                Loading.hideLoading(this);
            });
    }
}   