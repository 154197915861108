import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { Layout } from '../Layout';
import { HttpService } from '../Shared/HttpService';
import { Loading } from '../Shared/Loading';
import { HeadLayout } from '../Shared/HeadLayout';
import { Util } from '../Shared/Util';
import { PerfilData } from '../Perfil/FetchPerfil';
import { ModalDelete } from '../Shared/ModalDelete';
import { AuthService } from '../UserAuth/AuthService';
import { Resource } from '../../index';
import { LanguageContext } from '../../context/LanguageContext';
import CaixaDeConteudo from '../Shared/CaixaDeConteudo';
import ModalWinSet from '../Shared/components/ModalWinSet';

interface FetchUserDataState {
    usuarioList: UsuarioData[];
    data: UsuarioData[];
    loading: boolean;
    modalDeDeletarEstaAberto: boolean;
    itemSelecionadoParaDeletar: UsuarioData | null;
}

export class UsuarioData {
    nome: string = "";
    empresasid: number = 0;
    id: number = 0;
    email: string = "";
    documento: string = "";
    senha: string = "";
    usercreatedid: string = "";
    datecreated: string = "";
    isactive: boolean = true;
    perfil: PerfilData = new PerfilData();
    error: Array<any> = [];
}

const pageValues = {
    userAuthId: AuthService.getStorage('id'),
}

export class FetchUsuarios extends React.Component<RouteComponentProps<{}>, FetchUserDataState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = { usuarioList: [], loading: true, data: [], modalDeDeletarEstaAberto: false, itemSelecionadoParaDeletar: null };

        Loading.showLoading(this);;
        HttpService.fetch('api/UsuariosApi', {})
            .then(response => response.json() as Promise<UsuarioData[]>)
            .then(data => {
                var userActive = data.filter(doc => doc.isactive == true);
                this.setState({ usuarioList: userActive, loading: false, data: data });
                Loading.hideLoading(this);
            });

        // This binding is necessary to make "this" work in the callback  
        this.handleDelete = this.handleDelete.bind(this);
        this.handleEdit = this.handleEdit.bind(this);

    }

    private botoesDoCantoSuperiorDireito() {
        return (
            <React.Fragment>
                <button className='btn bg-white shadow-sm mr-2' onClick={() => this.props.history.push('/usuarios/add')}>
                    <Resource keyString="add" />
                </button>
                <button style={{ whiteSpace: 'nowrap' }} className="btn btn-outline-dark shadow-sm mr-2" onClick={(e) => this.handleChangeUser(e)}>
                    {this.context.getLanguageResource("show_inactive_users")}
                </button>
            </React.Fragment>
        );
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;

        return (
            <CaixaDeConteudo
                tituloDoHeader={this.context.getLanguageResource('users')}
                botoes={this.botoesDoCantoSuperiorDireito()}
                itensParaBreadcrumb={[
                    { nome: this.context.getLanguageResource('home'), url: "/" },
                    { nome: this.context.getLanguageResource('users') },
                ]}
            >
                <React.Fragment>
                    <header className='d-flex align-items-center justify-content-center w-100 p-4'>
                        <h3>{<Resource keyString="users" />}</h3>
                    </header>
                    <main className='p-4'>
                        {this.renderUserTable(this.state.usuarioList)}
                    </main>
                </React.Fragment>
            </CaixaDeConteudo>
        );
    }

    // Handle Delete request for an user  
    private handleDelete(id: number) {
            Loading.showLoading(this);;
            HttpService.fetch('api/UsuariosApi/' + id, {
                method: 'delete'
            }).then(data => {
                this.setState(
                    {
                        usuarioList: this.state.usuarioList.filter((rec) => {
                            return (rec.id != id);
                        })
                    });
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            });
    }

    private handleEdit(id: number) {
        this.props.history.push("/usuarios/edit/" + id);
    }

    private handleChangeUser(e) {
        var dataRender = this.state.data;

        if (e.target.innerText == this.context.getLanguageResource("show_inactive_users")) {
            dataRender = dataRender.filter(doc => doc.isactive == false);
            this.setState({ usuarioList: dataRender });
            e.target.innerText = this.context.getLanguageResource("show_active_users");
        }
        else {
            dataRender = dataRender.filter(doc => doc.isactive == true);
            this.setState({ usuarioList: dataRender });
            e.target.innerText = this.context.getLanguageResource("show_inactive_users");
        }

        return this.renderUserTable(this.state.usuarioList);
    }

    // Returns the HTML table to the render() method.  
    private renderUserTable(userList: UsuarioData[]) {
        return (<React.Fragment> 
            <table className='table'>
            <thead>
                <tr>
                    <th>{<Resource keyString="name" />}</th>
                    <th>{<Resource keyString="email" />}</th>
                    <th>{<Resource keyString="documents" />}</th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {userList.map(obj =>
                    <tr key={obj.id}>
                        <td className='align-middle'>{obj.nome}</td>
                        <td className='align-middle'>{obj.email}</td>
                        <td className='align-middle'>{obj.documento}</td>
                        <td className='align-middle'>
                            {
                                pageValues.userAuthId == obj.id
                                    ?   <p className='mb-0 font-weight-bold text-success'><Resource keyString="user_logged_in" /></p>
                                    :   <React.Fragment />
                            }
                        </td>
                        <td className='align-middle'>
                            <button className="btn ws-outline-green-brown mr-2 pull-right" style={{ width: '2.75rem', height: '2.75rem' }} onClick={() => this.handleEdit(obj.id)}><i className='fa fa-edit'></i></button>
                            {
                                (this.state.modalDeDeletarEstaAberto && this.state.itemSelecionadoParaDeletar && (this.state.itemSelecionadoParaDeletar.id === obj.id)) && (
                                    <ModalWinSet
                                        fecharModal={() => this.setState({ modalDeDeletarEstaAberto: false, itemSelecionadoParaDeletar: null })}
                                    >
                                        <div className='p-3'>
                                            <header className='d-flex w-100 mb-2 justify-content-center'>
                                                <h4><Resource keyString="do_you_want_to_remove_the_user" />?</h4>
                                            </header>
                                            <hr />
                                            <main>
                                                <p>{obj.nome}</p>
                                            </main>
                                            <footer className='d-flex w-100 justify-content-end'>
                                                <button className="btn mr-2 ws-outline-blue" onClick={() => this.handleDelete(obj.id)}><Resource keyString="yes" /></button>
                                                <button className="btn btn-outline-danger" onClick={() => this.setState({ modalDeDeletarEstaAberto: false, itemSelecionadoParaDeletar: null })}><Resource keyString="no" /></button>
                                            </footer>
                                        </div>
                                    </ModalWinSet>
                                )
                            }
                        </td>
                    </tr>
                )}
            </tbody>
            </table>
        </React.Fragment>)
    }
}   
