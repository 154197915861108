import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { DocumentoData } from './FetchDocumentos';
import { Layout } from '../Layout';
import { HttpService } from '../Shared/HttpService';
import { AuthService } from '../UserAuth/AuthService';
import { Loading } from '../Shared/Loading';
import { Login, LoginData } from '../UserAuth/Login';
import $ from 'jquery';
import { ValidationLabelMessage, ValidationAlertMessage } from '../Shared/ValidationMessage';
import { Resource } from '../../index';

interface ModalDocumentosDataState {
    id: string;
    documentos: DocumentoData[];
    formularioid: number;
    callBack: any;
}

export class ModalDocumentos extends React.Component<any, ModalDocumentosDataState> {
    constructor(props) {
        super(props);

        // This will set state for Add User  
        this.state = { documentos: [], id: props.id, callBack: props.callBack, formularioid: props.formularioid };

        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);
        this.handlePesquisar = this.handlePesquisar.bind(this);
    }

    // This will handle the submit form event.  
    private handleSave(event) {
        event.preventDefault();

    }
    private handleVincular(doc) {
        this.state.callBack.VincularDocumento(doc);

    }

    private handlePesquisar(event) {
        event.preventDefault();
        if (event.target["pesquisa"].value.length > 0) {
            Loading.showLoading(this);;

            HttpService.fetch(`api/documentos/pesquisar/${event.target["pesquisa"].value}`, { method: 'GET' })
                .then(response => response.json() as Promise<DocumentoData[]>)
                .then(data => {
                    this.setState({ documentos: data });
                    Loading.hideLoadingWithoutScroll(this);
                }).catch(() => {
                    Loading.hideLoadingWithoutScroll(this);
                });
        }
    }

    // Returns the HTML Form to the render() method.  
    public render() {
        return (
            <div className="modal" id={this.state.id}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">{<Resource keyString="search_current_files_documents" />}</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>

                        <div className="modal-body">
                            <form onSubmit={this.handlePesquisar} >
                                <input type="hidden" name="formularioid" value={this.state.formularioid} />
                                <div className="form-group">
                                    <button className="btn btn-secondary col-md-3 pull-right" type="submit"><i className='fa fa-search'></i> {<Resource keyString="search" />}</button>
                                    <div>
                                        <input className="border border-secondary form-control col-md-9" type="text" name="pesquisa" />
                                    </div>
                                </div>
                            </form>

                            <div className="row">
                                {this.state.documentos.map(doc =>
                                    <button className="col-md-12 btn btn-outline-light" key={`doc${doc.id}`} data-dismiss="modal" onClick={() => this.handleVincular(doc)}>
                                        <img className="mx-auto d-block" width="44" src="images/file.png" alt="{doc.nome}" style={{ float:"left" }} />
                                        <div className="text-center">
                                            <small className="card-title text-center mb-3 text-secondary" style={{ float: "left", paddingTop:"15px" }}>{doc.nome}</small>
                                        </div>
                                    </button>
                                )}
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-link" data-dismiss="modal"><i className='fa fa-times'></i> {<Resource keyString="close" />}</button>
                        </div>

                    </div>
                </div>
            </div>)
    }

}  