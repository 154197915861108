import * as React from 'react';
import { HttpService } from '../../Shared/HttpService';
import { Loading } from '../../Shared/Loading';
import { AddGrupoPergunta } from './AddGrupoPergunta';
import { FetchPergunta, PerguntaData } from '../Pergunta/FetchPergunta';
import { ModalDelete } from '../../Shared/ModalDelete';
import { Resource } from '../../../index';
import $ from 'jquery';
import ModalWinSet from '../../Shared/components/ModalWinSet';
import { LanguageContext } from '../../../context/LanguageContext';

interface FetchGrupoPerguntaState {
    grupoPerguntaList: GrupoPerguntaData[];
    todosGruposPergunta: GrupoPerguntaData[];
    grupoperguntaid: number;
    migalhas: string;
    loading: boolean;
    formularioid: number;
    callback: any;
    menu: boolean;
    modalDeEdicaoDoGrupoPerguntaEstaAberto: boolean;
    grupoPerguntaEmEdicao: GrupoPerguntaData | null;
    modalDeAdicaoDeGrupoPerguntaEstaAberto: boolean;
}

export class GrupoPerguntaData {
    id: number = 0;
    empresasid: number = 0;
    nome: string = "";
    tolerancia: number = 0;
    formularioid: number = 0;
    pontuacaoTotal: number = 0;
    grupoperguntapaiid: number = 0;
    hash: string = "";
    pergunta: Array<PerguntaData> = [];
    error: Array<any> = [];
    migalhas: string = "Migalhas";
}

export class FetchGrupoPergunta extends React.Component<any, FetchGrupoPerguntaState> {
    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        var todosGruposPergunta = !!props.todosGruposPergunta ? props.todosGruposPergunta : [];
        var grupoperguntaid = !!props.grupoperguntaid ? props.grupoperguntaid : 0;

        var grupoPerguntaList: GrupoPerguntaData[] = !!props.grupoPerguntaList ? props.grupoPerguntaList : new Array<GrupoPerguntaData>();

        if (grupoperguntaid <= 0) {
            this.state = {
                todosGruposPergunta: todosGruposPergunta,
                callback: this,
                formularioid: props.formularioid,
                migalhas: props.migalhas,
                grupoperguntaid: grupoperguntaid,
                grupoPerguntaList: grupoPerguntaList,
                loading: true,
                menu: false,
                modalDeEdicaoDoGrupoPerguntaEstaAberto: false,
                grupoPerguntaEmEdicao: null,
                modalDeAdicaoDeGrupoPerguntaEstaAberto: false,
            };
            this.carregaGrupoPerguntas();
        } else {
            this.state = {
                todosGruposPergunta:todosGruposPergunta,
                callback: props.callback,
                formularioid: props.formularioid,
                migalhas: props.migalhas,
                grupoperguntaid: grupoperguntaid,
                grupoPerguntaList: grupoPerguntaList,
                loading: false,
                menu: false,
                modalDeEdicaoDoGrupoPerguntaEstaAberto: false,
                grupoPerguntaEmEdicao: null,
                modalDeAdicaoDeGrupoPerguntaEstaAberto: false,
            };
        }

        this.handleDelete = this.handleDelete.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    toggleMenu(e) {
        if ($('.' + e).hasClass('show')) {
            $('.' + e).removeClass('show');
        } else {
            $('.' + e).addClass('show');
        }
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;

        return (
            <div>
                <div>
                    {!(this.state.grupoperguntaid > 0)
                        ?   (
                                <div className='d-flex justify-content-between align-items-center mb-2 border-top p-2'>
                                    <h3 className='mb-0'>{<Resource keyString="group_questions" />}</h3>
                                    <button
                                        className="btn btn-outline-dark mr-2 pull-right"
                                        onClick={() => this.setState({ modalDeAdicaoDeGrupoPerguntaEstaAberto: true })}
                                    >
                                            {<Resource keyString="add_group" />}
                                    </button>
                                </div>
                            )
                        :   <React.Fragment />
                    }
                    {
                        this.state.modalDeAdicaoDeGrupoPerguntaEstaAberto
                            ?   (
                                    <ModalWinSet
                                        fecharModal={() => this.setState({ modalDeAdicaoDeGrupoPerguntaEstaAberto: false, grupoPerguntaEmEdicao: null })}
                                    >
                                        <AddGrupoPergunta
                                            fecharModal={() => this.setState({ modalDeAdicaoDeGrupoPerguntaEstaAberto: false, grupoPerguntaEmEdicao: null })}
                                            todosGruposPergunta={this.state.todosGruposPergunta}
                                            key={"editGrupoPergunta" + 0}
                                            edicao={true}
                                            formularioId={this.state.formularioid}
                                            grupopergunta={this.state.grupoPerguntaEmEdicao}
                                            id={"editGrupoPergunta" + 0}
                                            callBack={this.state.callback}
                                        />
                                    </ModalWinSet>
                                )
                            :   <React.Fragment />
                    }
                    {
                        this.state.modalDeEdicaoDoGrupoPerguntaEstaAberto && this.state.grupoPerguntaEmEdicao != null
                            ?   (
                                    <ModalWinSet
                                        fecharModal={() => this.setState({ modalDeEdicaoDoGrupoPerguntaEstaAberto: false, grupoPerguntaEmEdicao: null })}
                                    >
                                        <AddGrupoPergunta
                                            fecharModal={() => this.setState({ modalDeEdicaoDoGrupoPerguntaEstaAberto: false, grupoPerguntaEmEdicao: null })}
                                            todosGruposPergunta={this.state.todosGruposPergunta}
                                            key={"editGrupoPergunta" + this.state.grupoPerguntaEmEdicao?.id}
                                            edicao={true}
                                            formularioId={this.state.formularioid}
                                            grupopergunta={this.state.grupoPerguntaEmEdicao}
                                            id={"editGrupoPergunta" + this.state.grupoPerguntaEmEdicao?.id}
                                            callBack={this.state.callback}
                                        />
                                    </ModalWinSet>
                                )
                            :   <React.Fragment />
                    }
                    {
                        this.state.modalDeAdicaoDeGrupoPerguntaEstaAberto
                            ?   (
                                    <ModalWinSet
                                        fecharModal={() => this.setState({ modalDeAdicaoDeGrupoPerguntaEstaAberto: false })}
                                    >
                                        <AddGrupoPergunta
                                            fecharModal={() => this.setState({ modalDeAdicaoDeGrupoPerguntaEstaAberto: false, grupoPerguntaEmEdicao: null })}
                                            todosGruposPergunta={this.state.todosGruposPergunta}
                                            key={"addGrupoPergunta" + this.state.grupoPerguntaEmEdicao?.id}
                                            formularioId={this.state.formularioid}
                                            grupopergunta={this.state.grupoPerguntaEmEdicao}
                                            id={"addGrupoPergunta" + this.state.grupoPerguntaEmEdicao?.id}
                                            callBack={this.state.callback}
                                        />
                                    </ModalWinSet>
                                )
                            :   <React.Fragment />
                    }
                </div>
                <div>
                    {this.renderUserTable(this.state.grupoPerguntaList)}
                </div>
            </div>
        );
    }

    public carregaGrupoPerguntas() {
        Loading.showLoading(this);;
        HttpService.fetch('api/GrupoPerguntaApi/All/' + this.state.formularioid, {})
            .then(response => response.json() as Promise<GrupoPerguntaData[]>)
            .then(data => {
                this.setState({ grupoPerguntaList: data, todosGruposPergunta: data, loading: false });
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            });
    }

    public updateGrupoPerguntas() {
        this.setState({ grupoPerguntaList: [], loading: true });
        this.forceUpdate();
        this.carregaGrupoPerguntas();
    }

    private handleDelete(id: number) {
        var url = `api/GrupoPerguntaApi/${id}`;
        HttpService.fetch(url, { method: 'DELETE' })
            .then(response => response.json() as Promise<boolean>)
            .then(data => {
                Loading.hideLoading(this);
                this.state.callback.updateGrupoPerguntas();
            }).catch(() => {
                Loading.hideLoading(this);
            });
    }

    private MontaListaGrupoPerguntas(grupoPerguntaList, doc: GrupoPerguntaData, index) {
        if (doc.grupoperguntapaiid == this.state.grupoperguntaid || !(doc.grupoperguntapaiid > 0) && !(this.state.grupoperguntaid > 0)) {

            var list = new Array<GrupoPerguntaData>();
            grupoPerguntaList.map((item, index) => {
                if (((item.hash != null
                    && (item.hash.toString().startsWith(doc.id + "|")
                        || item.hash.toString().startsWith(doc.hash + "|" + doc.id + "|")))
                    || (item.grupoperguntapaiid == doc.id))
                    && item.id != doc.id && item.hash != doc.hash)
                    list.push(item);
            }
            );

            var botaoDeletar = <React.Fragment></React.Fragment>;
            if (list.length == 0 && doc.pergunta.length == 0) {
                botaoDeletar = <React.Fragment>
                    <button className="btn btn-sm btn-outline-danger mr-2 pull-right" data-toggle="modal" data-target={`#delete${doc.id}`}><i className="fa fa-trash"></i> {<Resource keyString="delete" />}</button>
                    <ModalDelete id={doc.id} callBack={this} message={`${this.context.getLanguageResource("do_you_want_to_remove_the_group")}: ${doc.nome}?`} />
                </React.Fragment>;
            }

            return <div className="mb-1" key={doc.id}>
                <div className="p-2 rounded" style={{ cursor: 'pointer', backgroundColor: 'var(--ws-blue-light)' }} onClick={() => this.toggleMenu('gp' + doc.id)}>
                    <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                            <strong className='mb-0'>{doc.nome} <i className="fa fa-info-circle mr-3" title={(this.state.migalhas ? this.state.migalhas : "") + doc.nome}></i></strong> ({<Resource keyString="total_score" />}: {doc.pontuacaoTotal})
                        </div>

                        <div className="d-flex justify-content-end">
                            {botaoDeletar}

                            <button className="btn ws-outline-green-brown mr-2"
                                style={{ width: '2.5rem', height: '2.5rem' }}
                                onClick={() => this.setState({ modalDeEdicaoDoGrupoPerguntaEstaAberto: true, grupoPerguntaEmEdicao: doc })}
                            >
                                <i className="fa fa-edit"></i>
                            </button>
                            
                            
                            <button className="btn btn-sm btn-outline-dark mr-2"
                                style={{ minWidth: '2.5rem', height: '2.5rem', whiteSpace: 'nowrap' }}
                                onClick={() => this.setState({
                                    modalDeEdicaoDoGrupoPerguntaEstaAberto: true,
                                    grupoPerguntaEmEdicao: {
                                        ...doc,
                                        grupoperguntapaiid: doc.id,
                                        id: 0
                                    }
                                })}
                            >
                                <i className="fa fa-plus mr-2"></i>{<Resource keyString="add_subgroup" />}
                            </button>
                            

                            <button
                                onClick={() => window.location.href = `/Pergunta/add/${doc.formularioid}/${doc.id}`}
                                className='btn btn-outline-dark'
                                style={{ minWidth: '2.5rem', height: '2.5rem', whiteSpace: 'nowrap' }}
                            >
                                <i className='fa fa-plus'></i> {<Resource keyString="add_question" />}
                            </button>

                        </div>
                    </div>
                </div>
                <div className={"collapse navbar-collapse gp" + doc.id}>
                    {
                        list.length == 0 && doc.pergunta.length == 0
                            ?   (
                                    <div className='w-100 text-center py-3'>{ this.context.getLanguageResource('there_are_no_questions_or_subgroups') }</div>
                                )
                            :   <FetchPergunta formularioId={doc.formularioid} grupoRespostaId={doc.id} pergunta={doc.pergunta} />
                    }
                    
                    {
                        (list.length > 0) ?
                            <div className="py-1 mb-1 ml-4">
                                <FetchGrupoPergunta migalhas={(this.state.migalhas ? this.state.migalhas : "") + doc.nome + " > "} callback={this.state.callback} formularioid={doc.formularioid} grupoperguntaid={doc.id} grupoPerguntaList={list}
                                    todosGruposPergunta={this.state.todosGruposPergunta} />
                            </div>
                            : null
                    }
                </div>
            </div>
        } else {
            return <React.Fragment key={index}></React.Fragment>
        }
    }

    // Returns the HTML table to the render() method.  
    private renderUserTable(grupoPerguntaList: GrupoPerguntaData[]) {
        return <React.Fragment>
            {grupoPerguntaList.map((doc, index) =>
                this.MontaListaGrupoPerguntas(grupoPerguntaList, doc, index)
            )}
        </React.Fragment>;
    }
}