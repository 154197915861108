import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Loading } from '../../Shared/Loading';
import { HttpService } from '../../Shared/HttpService';
import { LanguageContext } from '../../../context/LanguageContext';
import CaixaDeConteudo from '../../Shared/CaixaDeConteudo';

interface DashboardBiMachineDataState {
    token: string;
}

export class DashboardBiMachine extends React.Component<RouteComponentProps<{}>, DashboardBiMachineDataState> {
    static contextType = LanguageContext;
    constructor(props) {

        super(props);

        this.state = {
            token: ""
        }

        HttpService.fetch('api/DashboardBiMachineApi/GetResponse', {
            method: 'GET'
        }).then((response) => {
            return response.json()
        }).then((data) => {
            var obj = JSON.parse(data);
            this.setState({ token: obj.token })
            Loading.hideLoading(this);
        }).catch((response) => {
            Loading.hideLoading(this);
        })
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;
        return (
            <CaixaDeConteudo
                tituloDoHeader='Dashboard'
                botoes={[]}
                itensParaBreadcrumb={[
                    { nome: this.context.getLanguageResource("home"), url: "/" },
                    { nome: 'Dashboard' }
                ]}
            >
                {this.renderUserTable()}
            </CaixaDeConteudo>
        );
    }

    private renderUserTable() {
        return <React.Fragment>
            <iframe width="100%" height="600px" src={"https://app.bimachine.com/spr/publisher/dashboard?content=%2Fgrx%2FSWIFT%2FPAGED%2FDASHBOARD%2FVisaoGeralGGQSwift.dashboard&removeFrame=true&appToken=" + this.state.token}></iframe>
        </React.Fragment>
    }
}