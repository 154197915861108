import * as React from 'react';

const { useState } = React;

const style: React.CSSProperties = {
  cursor: 'pointer',
  borderBottom: '1px solid #e6e6e6',
}

interface IProps {
  titulo: string;
  children: React.ReactNode;
}

const CollapseDeConteudo = ({ children, titulo }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <React.Fragment>
      <div
        style={ style }
        className="card-title"
        onClick={ () => setIsOpen(!isOpen) }
      >
        <div className="d-flex flex-row ml-3">
          <div style={ { width: '1.1rem' } }>
            <h3 style={ { lineHeight: '1.5' } }><i className={`fa ${isOpen ? 'fa-caret-down' : 'fa-caret-right'}`} /></h3>
          </div>
          <h3 className="text-center title-section-paged mt-2 ml-1">{ titulo }</h3>
        </div>
      </div>
      <div style={ { display: `${isOpen ? '' : 'none'}` } }>
        { children }
      </div>
    </React.Fragment>
  );
}

export default CollapseDeConteudo;
