import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { HttpService } from '../Shared/HttpService';
import { AuthService } from './AuthService';
import { HeadLayout } from '../Shared/HeadLayout';
import { Loading } from '../Shared/Loading';
import { UsuarioData } from '../Usuarios/FetchUsuarios';
import { LanguageContext } from '../../context/LanguageContext';
import NotebookWinset from '../../assets/img/notebook-winset.png';
import LogoWinset from '../../assets/img/winset-logo.png';
import ChaveSombreada from '../../assets/svg/chave-sombreada.svg';
import '../../css/win.set.css';
import { Link } from 'react-router-dom';
import ModalWinSet from '../Shared/components/ModalWinSet';
import EmpresasERotas from '../../assets/json/empresasERotas.json';

interface LoginDataState {
    modalDeMensagemEstaAberto: boolean;
    statusDaRequisicao: number;
    imagensDinamicas: { logo: any, icon: any, whitelogo: any };
}

export class LoginData {
    email: string = "";
    senha: string = "";
    usuario: UsuarioData = new UsuarioData();
    error: Array<any> = [];
}

export class RecoveryPassword extends React.Component<RouteComponentProps<{}>, LoginDataState> {
    static contextType = LanguageContext;

    constructor(props) {
        super(props);
        new AuthService().logout();

        this.state = {
            modalDeMensagemEstaAberto: false,
            statusDaRequisicao: 0,
            imagensDinamicas: { logo: '', icon: '', whitelogo: ''},
        };

        this.handleRecovery = this.handleRecovery.bind(this);
        this.aRotaEstaListadaNoJson = this.aRotaEstaListadaNoJson.bind(this);
        this.retornaRotasDasImagensDaEmpresa = this.retornaRotasDasImagensDaEmpresa.bind(this);
    }

    // This will handle the submit form event.  
    private handleRecovery(event) {
        event.preventDefault();
        Loading.showLoading(this);
        HttpService.fetch('api/Authentication/RecoveryPassword', {
            method: 'POST',
            body: HttpService.formToJson(event.target),
        }).then((response) => {
            this.setState({ modalDeMensagemEstaAberto: true, statusDaRequisicao: response.status, imagensDinamicas: { logo: '', icon: '', whitelogo: '' } });
            Loading.hideLoading(this);
        }).catch((_response) => {
            this.setState({ modalDeMensagemEstaAberto: true, statusDaRequisicao: 0, imagensDinamicas: { logo: '', icon: '', whitelogo: '' } });
            Loading.hideLoading(this);
        })
    }

    private aRotaEstaListadaNoJson() {
        // verificar o que está escrito entre http:// ou https:// e .winset.com.br
        const url = window.location.href as string;
        const regex = /https?:\/\/([^.]*)/;
        const match = url.match(regex) ? url.match(regex)[1] : null;

        // transformar o json em array
        const empresasERotas = JSON.parse(JSON.stringify(EmpresasERotas)) as Array<any>;
        if (empresasERotas.length === 0) return false;
        return empresasERotas.some(empresa => match?.includes(empresa.nome));
    }

    private retornaRotasDasImagensDaEmpresa() {
        const outraEmpresa = this.aRotaEstaListadaNoJson();
        
        if (outraEmpresa) {
            const rotaAtual = window.location.href.match(/https?:\/\/([^.]*)/)[1];
            const empresasERotas = JSON.parse(JSON.stringify(EmpresasERotas)) as Array<any>;
            const empresa = empresasERotas.find(empresa => rotaAtual.includes(empresa.nome));
            this.setState({ imagensDinamicas: { logo: empresa.logo, icon: empresa.icon, whitelogo: empresa.whitelogo } });
        }
    }

    componentDidMount(): void {
        this.retornaRotasDasImagensDaEmpresa();
    }

    public render() {
        return (
            <React.Fragment>
                <HeadLayout title="Recuperar senha" />
                {
                    this.state.modalDeMensagemEstaAberto && (
                        <ModalWinSet fecharModal={() => this.setState({ modalDeMensagemEstaAberto: false })}>
                            <div style={{ maxWidth: '31.25rem' }}>
                                {
                                    this.state.statusDaRequisicao === 200
                                        ?
                                            (
                                                <div className='d-flex flex-column justify-content-center align-items-center p-4'>
                                                    <i className='fa fa-check-circle text-success mb-3' style={{ fontSize: '3rem' }}></i>
                                                    <p>{ this.context.getLanguageResource('email_recovery_instructions_if_email_registered_in_system_you_will_receive_an_email_with_password_recovery_instructions') }</p>
                                                    <button className='btn' style={{ backgroundColor: 'var(--ws-blue)' }} onClick={() => this.setState({ modalDeMensagemEstaAberto: false })}>Fechar</button>
                                                </div>
                                            )
                                        :   (
                                                <div className='d-flex flex-column justify-content-center align-items-center p-4'>
                                                    <i className='fa fa-times-circle text-danger mb-3' style={{ fontSize: '3rem' }}></i>
                                                    <p>{ this.context.getLanguageResource('request_processing_error_an_error_occurred_while_processing_your_request_please_try_again_later') }</p>
                                                    <button className='btn' style={{ backgroundColor: 'var(--ws-blue)' }} onClick={() => this.setState({ modalDeMensagemEstaAberto: false })}>Fechar</button>
                                                </div>
                                            )
                                }
                            </div>
                        </ModalWinSet>
                    )
                }

                <div
                    className='d-flex flex-column justify-content-start align-items-end'
                    style={{ backgroundColor: 'var(--ws-blue)', width: '100vw', height: '100vh', position: 'relative', overflow: 'hidden' }}
                >
                    {
                        !this.aRotaEstaListadaNoJson() && (
                            <img src={NotebookWinset} alt='Smartphone win.set' style={{ height: '100vh', position: 'absolute', top: '5vh', left: 0 }} />
                        )
                    }
                    <div
                        className='bg-white d-flex flex-column justify-content-center align-items-center'
                        style={{ width: 'calc(50% + 4.5rem)', height: '100%', clipPath: 'polygon(9rem 0%, 100% 0, 100% 100%, 0% 100%)', padding: '0 9rem 0 9rem', position: 'absolute' }}
                    >
                        <header className='d-flex justify-content-center mb-3'>
                            <img
                                src={this.aRotaEstaListadaNoJson() ? `${process.env.PUBLIC_URL}${this.state.imagensDinamicas.logo}` : LogoWinset}
                                alt="Logo win.set"
                                style={{ width: "40%", minWidth: "16.25rem" }}
                            />
                        </header>

                        <main style={{ maxWidth: '23.25rem' }}>
                            <p>{ this.context.getLanguageResource('email_recovery_request_please_enter_your_email_and_we_will_send_you_a_link_to_recover_your_password') }</p>
                            <form onSubmit={this.handleRecovery}>
                                <div className='mb-2 w-100'>
                                    <label className='font-weight-bold required'>E-mail</label>
                                    <input className='form-control border rounded w-100 p-2' style={{ borderColor: "#22C9F7" }} type="email" name="email" placeholder="E-mail" />
                                </div>
                                <button className='w-100 rounded p-2 btn' style={{ background: "var(--ws-blue)", color: this.aRotaEstaListadaNoJson() ? "white" : "black" }} type="submit">Recuperar a senha</button>
                                <div className='w-100 d-flex justify-content-end'>
                                    <Link to="/login" className='text-right mt-2' style={{ color: "var(--ws-blue)" }}>Voltar a página inicial</Link>
                                </div>
                            </form>
                        </main>

                        <footer>

                        </footer>
                    </div>
                </div>

                <img
                    src={ChaveSombreada}
                    alt="O caractere de abertura de chave"
                    style={{
                        position: "absolute",
                        top: "50vh",
                        left: "50vw",
                        transform: "translate(-50%, -50%)",
                        width: "9rem",
                        zIndex: 50,
                    }}
                />
                <p style={{ position: "absolute", bottom: "0", right: "75vw", transform: 'translate(50%)' , zIndex: 150 }} className='text-dark font-weight-bold mb-0'>Powered by win.win 2022</p>
            </React.Fragment>
        );
    }
}
