import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { HttpService } from '../Shared/HttpService';
import { Loading } from '../Shared/Loading';
import { Resource } from '../../index';
import { LanguageContext } from '../../context/LanguageContext';
import CaixaDeConteudo from '../Shared/CaixaDeConteudo';

interface FetchModeloCadastroState {
    modeloCadastroList: ModeloCadastroData[];
    loading: boolean;
}

export class ModeloCadastroData {
    id: number = 0;
    nome: string = "";
    empresasid: number = 0;
    isactive: boolean = true;
    datecreated: string = "";
    usercreatedid: number = 0;
    datemodified: string = "";
    usermodifiedid: number = 0;
    error: Array<any> = [];
}

export class FetchModeloCadastro extends React.Component<RouteComponentProps<{}>, FetchModeloCadastroState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        this.state = {
            modeloCadastroList: [],
            loading: true
        };

        Loading.showLoading(this);;
        HttpService.fetch('api/ModeloCadastroApi', {})
            .then(response => response.json())
            .then(data => {
                this.setState({ modeloCadastroList: data, loading: false });
                this.forceUpdate();
                Loading.hideLoading(this);
            });

        this.handleEdit = this.handleEdit.bind(this);
        this.handleVisualizarDados = this.handleVisualizarDados.bind(this);
    }

    private handleEdit(id: number) {
       this.props.history.push("/modeloCadastro/edit/" + id);
    }

    private handleVisualizarDados(id: number) {
        this.props.history.push("/modeloCadastroVisualizarDados/" + id);
    }

    private botoesDoCantoSuperiorDireito() {
        return (
            <button className='btn bg-white shadow-sm mr-2' onClick={() => this.props.history.push('/modeloCadastro/add')}>
                <Resource keyString="add" />
            </button>
        );
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;

        return (
            <CaixaDeConteudo
                tituloDoHeader='Modelo de Cadastro'
                botoes={this.botoesDoCantoSuperiorDireito()}
                itensParaBreadcrumb={[
                    { nome: this.context.getLanguageResource('home'), url: "/" },
                    { nome: 'Modelo de Cadastro' },
                ]}
            >
                <React.Fragment>
                    <header className='d-flex align-items-center justify-content-center w-100 p-4'>
                        <h3>Modelo de Cadastro</h3>
                    </header>
                    <main className='p-4'>
                        {this.renderUserTable(this.state.modeloCadastroList)}
                    </main>
                </React.Fragment>
            </CaixaDeConteudo>
        );
    }

    private renderUserTable(modeloCadastroList: ModeloCadastroData[]) {
        return <table className='table'>
            <thead>
                <tr>
                    <th>{<Resource keyString="model_name" />}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {modeloCadastroList.map(doc =>
                    <tr key={doc.id}>
                        <td className='align-middle'>{doc.nome}</td>
                        <td className='align-middle'>
                            <button className="btn btn-outline-primary mr-2 pull-right" style={{ width: '2.75rem', height: '2.75rem' }} onClick={(id) => this.handleVisualizarDados(doc.id)}><i className='fa fa-book' /></button>
                            <button className="btn ws-outline-green-brown mr-2 pull-right" style={{ width: '2.75rem', height: '2.75rem' }} onClick={() => this.handleEdit(doc.id)}><i className='fa fa-edit'></i></button>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>;
    }
}