import * as React from "react";

const { useRef, useState } = React;

interface IAlerta {
    id: number;
    mensagem: string;
    tipoMensagem: string;
}

interface IProps {
    mensagensDeAlerta: IAlerta[];
}

const TarjaDeAlertas = ({mensagensDeAlerta}: IProps) => {
    const [estaAberto, setEstaAberto] = useState(false);
    const badgeRef = useRef<HTMLSpanElement>(null);

    const ALTURA_DO_BADGE = badgeRef.current && badgeRef.current.offsetHeight;

    const handleIconRender = (msg: IAlerta) => {
        if (msg.tipoMensagem === "danger") {
            return `zmdi zmdi-alert-polygon text-${msg.tipoMensagem}`;
        } else if (msg.tipoMensagem === "warning") {
            return `zmdi zmdi-alert-triangle text-${msg.tipoMensagem}`;
        } else if (msg.tipoMensagem === "info") {
            return `zmdi zmdi-info text-${msg.tipoMensagem}`;
        }
    };

    return (
        <React.Fragment>
            {
                mensagensDeAlerta.length > 0 &&
                (
                    <div className={`alert alert-${mensagensDeAlerta[0].tipoMensagem} d-flex justify-content-between m-3 small`} style={{ maxWidth: '600px' }} role="alert">
                        {
                            <span>{mensagensDeAlerta[0].mensagem}</span>
                        }
                        {
                            mensagensDeAlerta.length > 1
                                &&  (
                                        <div className='ml-2 d-flex'>
                                            <section>
                                                {
                                                    mensagensDeAlerta.length > 0 && (
                                                        <span
                                                            className="badge badge-light d-flex align-items-center px-1 h-100"
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => setEstaAberto(!estaAberto)}
                                                            ref={badgeRef}
                                                        >
                                                            {`+ ${mensagensDeAlerta.slice(1).length} alertas`}
                                                        </span>
                                                    )
                                                }
                                            </section>
                                            <section
                                                style={
                                                    {
                                                        position: 'absolute',
                                                        zIndex: 9998,
                                                        opacity: estaAberto ? 0.97 : 0,
                                                        pointerEvents: estaAberto ? 'all' : 'none',
                                                        top: `${ALTURA_DO_BADGE ? ALTURA_DO_BADGE * 2 : 0}px`,
                                                        left: `200px`,
                                                    }
                                                }
                                            >
                                                <div className="d-flex align-items-center flex-column">
                                                    <div className="bg-white" style={{ clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)", height: "18px", width: "26px" }} />
                                                    <div className="bg-white rounded">
                                                        {
                                                            mensagensDeAlerta.slice(1).map((msg, index, array) => {
                                                                return (
                                                                    <div
                                                                        className={`d-flex align-items-center ${index === array.length - 1 ? '' : 'border-bottom'}`}
                                                                        style={{ width: "350px" }} key={msg.id}
                                                                    >
                                                                        <div className="d-flex align-items-center justify-content-center m-3">
                                                                            <i className={handleIconRender(msg)} style={{ fontSize: '40px' }}></i>
                                                                        </div>
                                                                        <div>
                                                                            <p className="text-dark p-2">{msg.mensagem}</p>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    )
                        }
                    
                    </div>
                )
            }
        </React.Fragment>
    );
};

export default TarjaDeAlertas;