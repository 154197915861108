import styled from 'styled-components';

const LoginForm = styled.div`
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: ${({ outraEmpresa }) => outraEmpresa ? "100%" : "calc(50% + 4.5rem)"};
    clip-path: ${({ outraEmpresa }) => outraEmpresa ? "" : "polygon(9rem 0%, 100% 0, 100% 100%, 0% 100%)"};
    padding: 0 9rem 0 9rem;
    position: absolute;

    @media (max-width: 768px) {
        width: 100%;
        padding: 0 2rem 0 2rem;
        clip-path: none;
    }
`;

export default LoginForm;