function formatarTokenConfirmacaoDeEmail(codigo: string): string {
    codigo = codigo.replace(/[^a-zA-Z0-9]/g, '');
    codigo = codigo.toUpperCase();
    codigo = codigo.substring(0, 8);
    if (codigo.length >= 5) {
        codigo = codigo.substring(0, 4) + '-' + codigo.substring(4, 8);
    }
    return codigo;
}

export default formatarTokenConfirmacaoDeEmail;