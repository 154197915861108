import React, { useEffect, useState } from "react";
// import FileViewer from 'react-file-viewer';

interface IProps {
    fecharModal: () => void;
    zIndex?: number;
    aoMontarModal?: () => void;
    impedirFechamentoAoClicarFora?: boolean;
    tipo: string | null;
    url: string | null;
    extensao: string | null;
}

const CLASSE_DO_OVERLAY = "d-flex justify-content-center align-items-center overlay";

const VisualizadorDeArquivos = ({ fecharModal, zIndex = 10000, aoMontarModal, impedirFechamentoAoClicarFora, tipo, url, extensao }: IProps) => {
    const [possuiBarraDeFerramentas, setPossuiBarraDeFerramentas] = useState(false);
    const handleFecharModal = () => {
        fecharModal();
    };

    const handleClickOutside = (event: any) => {
        if (impedirFechamentoAoClicarFora) return;
        if (event.target.className === CLASSE_DO_OVERLAY) {
            handleFecharModal();
        }
    };

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                handleFecharModal();
            }
        };

        if (aoMontarModal) {
            aoMontarModal();
        }
        
        document.body.style.overflow = "hidden";
        window.addEventListener("keydown", handleKeyDown);

        return () => {
            document.body.style.overflow = "auto";
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    let visualizador = (): React.ReactNode => {
        if (!url) return (
            <React.Fragment>
                <div className="d-flex w-100 h-100 align-items-center justify-content-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </React.Fragment>
        );
        
        switch (tipo) {
            case "pdf":
                return (
                    <React.Fragment>
                        <object data={url} type="application/pdf" width="100%" height="100%">
                            <p className="text-center mb-0">Não foi possível abrir o PDF.</p>
                            <p className="text-center">Clique no botão abaixo para fazer o download.</p>
                            <a href={url} download>
                                <button className="btn ws-outline-blue" type="button">Download</button>
                            </a>
                        </object>
                    </React.Fragment>
                );
            case "png":
            case "jpg":
            case "jpeg":
                if (possuiBarraDeFerramentas === false) setPossuiBarraDeFerramentas(true);
                return (
                    <React.Fragment>
                        <div className="d-flex justify-content-center align-items-center w-100 h-100 p-3">
                            <img src={url} alt="Imagem" />
                        </div>
                    </React.Fragment>
                );
            case "mp3":
            case "mpeg":
            case "wav":
                if (possuiBarraDeFerramentas === false) setPossuiBarraDeFerramentas(true);
                return (
                    <React.Fragment>
                        <div className="d-flex justify-content-center align-items-center w-100 h-100 p-3">
                            <audio controls>
                                <source src={url} type={`audio/${tipo}`} />
                            </audio>
                        </div>
                    </React.Fragment>
                );
            case "mp4":
            case "webm":
                if (possuiBarraDeFerramentas === false) setPossuiBarraDeFerramentas(true);
                return (
                    <React.Fragment>
                        <div className="d-flex justify-content-center align-items-center w-100 h-100 p-3">
                            <video controls>
                                <source src={url} type={`video/${tipo}`} />
                            </video>
                        </div>
                    </React.Fragment>
                );
            default:
                return (
                    <React.Fragment>
                        <div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center">
                            <p className="text-center mb-0">Nosso visualizador não suporta o tipo de arquivo selecionado (.{extensao}).</p>
                            <p className="text-center">Extensões suportadas: .pdf, .png, .jpg, .jpeg, .mp3, .wav, .mp4, .webm.</p>
                            <p className="text-center">Clique no botão abaixo para fazer o download.</p>
                            <a href={url} download>
                                <button className="btn ws-outline-blue" type="button">Download</button>
                            </a>
                        </div>
                    </React.Fragment>
                );
        }
    };

    return (
        <React.Fragment>
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(0, 0, 0, 0.5)",
                    zIndex: zIndex,
                    overflowY: "auto",
                }}
                onClick={handleClickOutside}
                className={CLASSE_DO_OVERLAY}
            >
                <div
                    className='d-flex flex-column justify-content-start bg-white shadow rounded'
                    style={{
                        position: "absolute",
                        zIndex: zIndex + 1,
                        overflow: "hidden",
                        width: "calc(100vw - 4rem)",
                        height: "calc(100vh - 8rem)",
                    }}
                >
                    <div className='w-100 shadow-sm' style={{ background: 'var(--ws-blue' }}>
                        <div className="w-100 d-flex justify-content-end">
                            <button className="btn" onClick={handleFecharModal}>
                                <i className="bi bi-x-lg" />
                            </button>
                        </div>
                    </div>
                    {
                        possuiBarraDeFerramentas && (
                            <div className="d-flex justify-content-end shadow-sm w-100 p-2" style={{ backgroundColor: "#f3f3f3" }}>
                                <a href={url as string} download>
                                    <button className="btn border rounded d-flex align-items-center justify-content-center" style={{ height: '2rem', width: '2rem', background: 'white' }} type="button">
                                        <i className="bi bi-download" />
                                    </button>
                                </a>
                            </div>
                        )
                    }
                    <div className='w-100 barra_de_rolagem_estilizada' style={{ height: 'calc(100% - 1rem)', overflow: 'auto' }}>
                        <div className="text-center">
                            { visualizador() }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default VisualizadorDeArquivos;
