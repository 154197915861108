import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { FormatoPerguntaData } from './FetchFormatoPergunta';
import { Layout } from '../../Layout';
import { HttpService } from '../../Shared/HttpService';
import { HeadLayout } from '../../Shared/HeadLayout';
import { Loading } from '../../Shared/Loading';
import { ValidationLabelMessage, ValidationAlertMessage } from '../../Shared/ValidationMessage';
import $ from 'jquery';
import { Resource } from '../../../index';

interface AddFormatoPerguntaDataState {
    title: string;
    loading: boolean;
    formatoPergunta: FormatoPerguntaData;
}

export class AddFormatoPergunta extends React.Component<RouteComponentProps<{}>, AddFormatoPerguntaDataState> {
    constructor(props) {
        super(props);

        this.state = { title: "Editar Layout da Pergunta", loading: true, formatoPergunta: new FormatoPerguntaData };


        var formatoPerguntaId = this.props.match.params["formatoPerguntaId"];

        // This will set state for Edit User  
        if (formatoPerguntaId > 0) {

            Loading.showLoading(this);;

            HttpService.fetch('api/FormatoPerguntaApi/' + formatoPerguntaId, { method: 'GET' })
                .then(response => response.json() as Promise<FormatoPerguntaData>)
                .then(data => {
                    this.setState({ title: "Editar Layout da Pergunta", loading: false, formatoPergunta: data });
                    Loading.hideLoading(this);
                }).catch(() => {
                    Loading.hideLoading(this);
                    props.history.push("/FormatoPergunta");
                });
        }

        // This will set state for Add User  
        else {
            this.state = { title: "Criar Layout da Pergunta", formatoPergunta: new FormatoPerguntaData, loading: false };
        }


        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    public render() {
        let contents = Loading.isLoading(this)
            ? <p><em></em></p>
            : this.renderCreateForm(this.state.formatoPergunta);

        return <div className="col-lg-12">
            <HeadLayout title={this.state.title} />
            <div className="card">
                <div className="card-header"><a href="/">{<Resource keyString="home" />}</a> / <a href="/">{<Resource keyString="audit_configuration" />}</a> / <a href="/formatoPergunta">Layout da Pergunta</a> / {this.state.title}</div>
                <div className="card-body">
                    <div className="card-title">
                        <h3 className="text-center title-2">{this.state.title}</h3>
                    </div>
                    <hr />
                    {contents}
                </div>
            </div>
        </div>;
    }

    // This will handle the submit form event.  
    private handleSave(event) {
        event.preventDefault();

        let FormatoPerguntaId = this.state.formatoPergunta.id;

        Loading.showLoading(this);;

        // PUT request for Edit User.  
        if (FormatoPerguntaId) {

            HttpService.fetch('api/FormatoPerguntaApi/' + FormatoPerguntaId, {
                method: 'PUT',
                body: HttpService.formToJson(event.target),
            }).then((response) => response.json() as Promise<FormatoPerguntaData>)
                .then((data) => {
                    this.setState({ formatoPergunta: data })

                    if ($.isEmptyObject(this.state.formatoPergunta.error)) {
                        this.props.history.push("/FormatoPergunta");
                    }
                    Loading.hideLoading(this);
                }).catch(() => {
                    Loading.hideLoading(this);
                })

        } else {

            HttpService.fetch('api/FormatoPerguntaApi', {
                method: 'POST',
                body: HttpService.formToJson(event.target),
            }).then((response) => response.json() as Promise<FormatoPerguntaData>)
                .then((data) => {
                    this.setState({ formatoPergunta: data })

                    if ($.isEmptyObject(this.state.formatoPergunta.error)) {
                        this.props.history.push("/FormatoPergunta");
                    }
                    Loading.hideLoading(this);
                }).catch(() => {
                    Loading.hideLoading(this);
                })
        }
    }

    // This will handle Cancel button click event.  
    private handleCancel(e) {
        e.preventDefault();
        this.props.history.push("/FormatoPergunta");
    }

    private handleHtmlChange(event) {
        var formato = this.state.formatoPergunta.html;
        this.setState({ formatoPergunta: event.target.value });
        this.forceUpdate();
    }

    // Returns the HTML Form to the render() method.  
    private renderCreateForm(FormatoPerguntaes) {
        return (<form onSubmit={this.handleSave} >
            <div className="row">
                <input type="hidden" name="id" value={this.state.formatoPergunta.id} />

                <div className="form-group col-md-12">
                    <label className="required">Nome do Layout da Pergunta</label>
                    <ValidationLabelMessage errors={this.state.formatoPergunta.error} name="Nome">
                        <input className="au-input au-input--full" type="text" name="Nome" placeholder="Nome" defaultValue={this.state.formatoPergunta.nome} />
                    </ValidationLabelMessage>
                </div>

                <div className="form-group col-md-12">
                    <label className="required">Layout da Pergunta</label>
                    <ValidationLabelMessage errors={this.state.formatoPergunta.error} name="Html">
                        <textarea className="au-input au-input--full" name="Html" placeholder=""
                            style={{ "height": "300px" }}
                            onChange={e => {
                                var formato = this.state.formatoPergunta;
                                formato.html = e.target.value;
                                this.setState({ formatoPergunta: formato });
                            }} defaultValue={this.state.formatoPergunta.html} >
                        </textarea>
                    </ValidationLabelMessage>
                </div>

                <div className="form-group col-md-12">
                    <label>{<Resource keyString="result" />}</label>
                    <div className="col-md-12" dangerouslySetInnerHTML={{ __html: this.state.formatoPergunta.html }}>
                    </div>
                </div>

            </div >
            <div className="form-group col-md-12">
                <ValidationAlertMessage errors={this.state.formatoPergunta.error} name="Summary" />
                <button type="submit" className="btn btn-primary mr-2"><i className='fa fa-save'></i> {<Resource keyString="save" />}</button>
                <button className="btn btn-link" onClick={this.handleCancel}><i className='fa fa-arrow-left'></i> {<Resource keyString="back" />}</button>
            </div >
        </form >)
    }

}  