import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { DocumentoData, ResultadoXDocumentoData } from './FetchDocumentos';
import { HttpService } from '../Shared/HttpService';
import { Loading } from '../Shared/Loading';
import { FetchArquivo } from '../Arquivos/FetchArquivo';
import { ListaSetorVinculado } from '../Setores/ListaSetorVinculado';
import { Resource } from '../../index';
import { LanguageContext } from '../../context/LanguageContext';
import CaixaDeConteudo from '../Shared/CaixaDeConteudo';

interface DetailDocumentoDataState {
    title: string;
    loading: boolean;
    empData: DocumentoData;
    resultadoXDocumento: ResultadoXDocumentoData;
}

export class DetailDocumento extends React.Component<RouteComponentProps<{}>, DetailDocumentoDataState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        var documentoid = this.props.match.params["documentoid"];

        this.state = { title: "", loading: true, empData: new DocumentoData, resultadoXDocumento: new ResultadoXDocumentoData };

        Loading.showLoading(this);

        HttpService.fetch('api/Documentos/' + documentoid, { method: 'GET' })
            .then(response => response.json() as Promise<DocumentoData>)
            .then(data => {
                this.setState({ title: this.context.getLanguageResource("edit_document"), loading: false, empData: data });
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
                props.history.push(`/repositorio`);
            });

        Loading.showLoading(this);

        HttpService.fetch('api/ResultadoXDocumentoApi/' + documentoid, { method: 'GET' })
            .then(response => response.json() as Promise<ResultadoXDocumentoData>)
            .then(data => {
                this.setState({  loading: false, resultadoXDocumento: data});
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
                this.setState({ loading: false, resultadoXDocumento: new ResultadoXDocumentoData });
                //props.history.push(`/repositorio`);
            });

        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    handleRestaurar = () => {
        Loading.showLoading(this);

        var diretorioid = this.props.match.params["diretorioid"];

        HttpService.fetch('api/DocumentosApi/Restaurar/' + diretorioid, { method: 'POST' })
            .then(response => response.json() as Promise<DocumentoData>)
            .then(data => {
                this.props.history.push(`/repositorio/${diretorioid}`);
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
                this.props.history.push(`/repositorio`);
            });
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;

        return (
            <CaixaDeConteudo
                tituloDoHeader={this.state.title}
                botoes={[
                    <button className='btn bg-white shadow-sm mr-2' onClick={this.handleCancel}>{this.context.getLanguageResource("back")}</button>,
                ]}
                breadcrumbDinamico={{ tipo: 'documento', id: this.state.empData.id }}
            >
                <div className='p-4'>
                    <form onSubmit={this.handleSave} >
                        <div>
                            <input type="hidden" name="userId" value={this.state.empData.id} />
                            <input type="hidden" name="diretoriosid" defaultValue={this.state.empData.diretoriosid + ""} />
                        </div>
                        <div className='d-flex'>
                            <section className='w-50'>
                                <div className='my-2'>
                                    <label className='mb-0'>{<Resource keyString="document_name" />}</label>
                                    <div>
                                        <strong>{this.state.empData.nome}</strong>
                                    </div>
                                </div>
                                <div className='my-2'>
                                    <label className='mb-0'>{<Resource keyString="tags_keyword" />}</label>
                                    <div>
                                        <strong>{this.state.empData.tags}</strong>
                                    </div>
                                </div>
                                {
                                    (this.state.empData.arquivovigente != null && this.state.empData.arquivovigente.datavencimento != null)
                                        ?   (
                                                <div className='my-2'>
                                                    <label className='mb-0'>{<Resource keyString="expiry_date" />}</label>
                                                    <div>
                                                        <strong>{new Date(this.state.empData.arquivovigente.datavencimento).toLocaleDateString()} {new Date(this.state.empData.arquivovigente.datavencimento).toLocaleTimeString()}</strong>
                                                    </div>
                                                </div>
                                            )
                                        :   <React.Fragment />
                                }
                            </section>
                            <section className='w-50'>
                                <div className='my-2'>
                                    <label className='mb-0'>{<Resource keyString="description_name" />}</label>
                                    <div>
                                        <strong>{this.state.empData.descricao}</strong>
                                    </div>
                                </div>
                                <div className='my-2'>
                                    <label className='mb-0'>{<Resource keyString="deadline_days" />}</label>
                                    <div>
                                        <strong>{this.state.empData.prazoemdias}</strong>
                                    </div>
                                </div>
                            </section>
                        </div >
                        {
                            this.state.resultadoXDocumento.id <= 0
                                ?   this.state.empData.isactive
                                        ?   <button type="button" onClick={this.handleDelete} className="btn btn-outline-danger mr-2"  style={{ width: '2.75rem', height: '2.75rem' }}><i className='fa fa-trash'></i></button>
                                        :   <button type='button' onClick={this.handleRestaurar} className='btn ws-outline-blue mr-2' style={{ width: '2.75rem', height: '2.75rem' }}><i className='fa fa-undo'></i></button>
                                :   (
                                        <div className="alert alert-warning" role="alert">
                                            {<Resource keyString="this_file_is_already_linked" />}
                                        </div>
                                    )
                        }
                        {
                            this.state.empData.perfil.escrita == true
                                ?   <button type="submit" className="btn ws-outline-green-brown mr-2"  style={{ width: '2.75rem', height: '2.75rem' }}><i className='fa fa-edit'></i></button>
                                :   <React.Fragment />
                        }
                    </form >
                    <hr />
                    <div className='d-flex '>
                        {
                            this.state.empData.perfil.escrita == true
                                ?   <ListaSetorVinculado documentoid={this.state.empData.id} />
                                :   <React.Fragment />
                        }

                        <FetchArquivo documentoid={this.state.empData.id} documento={this.state.empData} />
                    </div>
                </div>
            </CaixaDeConteudo>
        );
    }

    // This will handle the submit form event.  
    private handleSave(event) {
        event.preventDefault();

        var diretoriosid = this.state.empData.diretoriosid > 0 ? this.state.empData.diretoriosid : 0;
        this.props.history.push(`/documentos/edit/${diretoriosid}/${this.state.empData.id}`);
    }

    private handleDelete(event) {
        event.preventDefault();

        var confirme = window.confirm(this.context.getLanguageResource("do_you_really_want_to_delete_that_document"));

        if (confirme == true) {
            var documentoid = this.props.match.params["documentoid"];

            Loading.showLoading(this);

            HttpService.fetch('api/Documentos/' + documentoid, { method: 'DELETE' })
                .then(response => response.json() as Promise<DocumentoData>)
                .then(data => {
                    this.props.history.push(`/repositorio/${this.state.empData.diretoriosid}`);
                    Loading.hideLoading(this);
                }).catch(() => {
                    Loading.hideLoading(this);
                    this.props.history.push(`/repositorio`);
                });
        }
    }

    // This will handle Cancel button click event.  
    private handleCancel(e) {
        e.preventDefault();
        this.redirectPage();
    }

    private redirectPage() {
        this.props.history.push(`/repositorio/${this.state.empData.diretoriosid}`);
    }

    calcularPrazoEmDias = (dataVencimento) => {
        const vencimento = new Date(dataVencimento);
        const dataAtual = new Date();

        const diferenca = vencimento.getTime() - dataAtual.getTime();
        
        return Math.ceil(diferenca / (1000 * 3600 * 24));
    };
}