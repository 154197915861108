import * as React from 'react';
import EmpresasERotas from '../../assets/json/empresasERotas.json';

interface HeadLayoutState {
    title: string;
}
export class HeadLayout extends React.Component<any, HeadLayoutState> {
    constructor(props) {
        super(props);
        document.title = `Win.set ${props.title ? '-' : ''} ${props.title}`
    }

    private aRotaEstaListadaNoJson() {
        const url = window.location.href as string;
        const regex = /https?:\/\/([^.]*)/;
        // @ts-ignore
        const match = url.match(regex) ? url.match(regex)[1] : null;
        const empresasERotas = JSON.parse(JSON.stringify(EmpresasERotas)) as Array<any>;
        if (empresasERotas.length === 0) return false;
        return empresasERotas.some(empresa => match?.includes(empresa.nome));
    }

    public componentDidMount() {
        if (this.aRotaEstaListadaNoJson()) {
            // @ts-ignore
            const rotaAtual = window.location.href.match(/https?:\/\/([^.]*)/)[1];
            const empresasERotas = JSON.parse(JSON.stringify(EmpresasERotas)) as Array<any>;
            const empresa = empresasERotas.find(empresa => rotaAtual.includes(empresa.nome));
            document.title = `${empresa.title} ${this.props.title ? '-' : ''} ${this.props.title}`
        }
    }

    public render() {
        return null;
    }
}