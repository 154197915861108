import React, { useState } from "react";
import { NavLink } from "react-router-dom";

interface IProps {
    texto?: string;
    icone?: string | React.ReactNode;
    destino: string;
    aBarraLateralEstaAberta: boolean;
    alterarEstadoDaBarraLateralPara: (estado: boolean) => void;
    onClick?: () => void;
}

const LinkDeNavegacao = ({ texto, icone, destino, aBarraLateralEstaAberta, alterarEstadoDaBarraLateralPara, onClick }: IProps) => {
    const [mouseEntered, setMouseEntered] = useState(false);

    return (
        <NavLink className="w-100" to={destino} onClick={onClick}>
            <li
                className={`
                    d-flex
                    align-items-center
                    p-2
                    w-100
                    ${aBarraLateralEstaAberta ? '' :'justify-content-center'}
                `}
                style={{ gap: "14px", backgroundColor: (mouseEntered && aBarraLateralEstaAberta) ? "var(--ws-blue-highlight-menu)" : "", listStyleType: "none" }}
                onMouseEnter={() => setMouseEntered(true)}
                onMouseLeave={() => setMouseEntered(false)}
            >
                {
                    icone && (
                        <div
                            className={`
                                d-flex
                                align-items-center
                                justify-content-center
                                p-1
                                ${aBarraLateralEstaAberta ? '' : 'bg-white rounded shadow'}
                            `}
                            style={{ height: "2.125rem", width: "2.125rem", transition: "none" }}
                        >
                            {
                                (icone && typeof icone === 'string') && <img src={icone} alt={`Ícone ${texto}`} />
                            }
                            {
                                (icone && typeof icone !== 'string') && icone
                            }
                        </div>
                    )
                }
                {
                    aBarraLateralEstaAberta && <span className='text-dark' style={{ fontWeight: "100" }}>{ texto }</span>
                }
            </li>
        </NavLink> 
    );
};

export default LinkDeNavegacao;
