import styled from 'styled-components';

const HolderButtons = styled.div`
    display: flex;
    justify-content: flex-start;
    min-width: 6.5rem;
    flex-shrink: 0;

    @media (min-width: 768px) {
        display: none;
    }
`;

export default HolderButtons;
