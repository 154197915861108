import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { HeadLayout } from '../../Shared/HeadLayout';
import { Layout } from '../../Layout';
import { HttpService } from '../../Shared/HttpService';
import $ from 'jquery';
import { Loading } from '../../Shared/Loading';
import { Resource } from '../../../index';
import { ValidationLabelMessage } from '../../Shared/ValidationMessage';
import { ModeloCadastroColunaData } from '../ModeloCadastroColuna/FetchModeloCadastroColuna';
import { ModeloCadastroVisualizarDadosData } from './FetchVisualizarDadosModeloCadastro';
import { LanguageContext } from '../../../context/LanguageContext';
import CaixaDeConteudo from '../../Shared/CaixaDeConteudo';

interface AddVisualizarDadosModeloCadastroState {
    title: string;
    loading: boolean;
    empData: ModeloCadastroVisualizarDadosData;
    modeloCadastroId: number;
    modeloCadastroColuna: Array<any>;
}

export class AddVisualizarDadosModeloCadastro extends React.Component<RouteComponentProps<{}>, AddVisualizarDadosModeloCadastroState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        var modeloCadastroId = this.props.match.params["modeloCadastroId"];

        this.state = {
            title: "Editar Cadastro Preenchido",
            loading: true,
            empData: new ModeloCadastroVisualizarDadosData,
            modeloCadastroId: modeloCadastroId,
            modeloCadastroColuna: []
        };

        var modeloCadastroVisualizarDadosId = this.props.match.params["modeloCadastroVisualizarDadosId"];

        // This will set state for Edit User  
        if (modeloCadastroVisualizarDadosId > 0) {

            Loading.showLoading(this);

            HttpService.fetch('api/ModeloCadastroVisualizarDadosApi/GetModeloCadastroTipoCampoExist/' + modeloCadastroVisualizarDadosId + '/' + this.state.modeloCadastroId, { method: 'GET' })
                .then(response => response.json())
                .then(data => {
                    this.setState({ modeloCadastroColuna: data });
                    Loading.hideLoading(this);
                }).catch(() => {
                    Loading.hideLoading(this);
                });
        }

        // This will set state for Add User  
        else {
            this.state = {
                title: "Preencher Modelo de Cadastro",
                loading: false,
                empData: new ModeloCadastroVisualizarDadosData,
                modeloCadastroId: modeloCadastroId,
                modeloCadastroColuna: []
            };

            this.getModeloCadastroTipoCampo(this.state.modeloCadastroId);
        }

        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    private getModeloCadastroTipoCampo(modeloCadastroId) {
        Loading.showLoading(this);

        HttpService.fetch('api/ModeloCadastroVisualizarDadosApi/GetModeloCadastroTipoCampo/' + modeloCadastroId, { method: 'GET' })
            .then(response => response.json())
            .then(data => {
                this.setState({ modeloCadastroColuna: data });
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            });
    }

    private handleCancel(e) {
        e.preventDefault();
        this.redirectPage();
    }

    private redirectPage() {
        this.props.history.push("/modeloCadastroVisualizarDados/" + this.state.modeloCadastroColuna[0].modeloCadastroId);
    }

    private handleSave(event) {
        event.preventDefault();

        var modeloCadastroVisualizarDadosId = this.props.match.params["modeloCadastroVisualizarDadosId"];
        // PUT request for Edit User.  
        if (modeloCadastroVisualizarDadosId) {
            Loading.showLoading(this);
            HttpService.fetch('api/ModeloCadastroVisualizarDadosApi/' + modeloCadastroVisualizarDadosId, {
                method: 'PUT',
                body: JSON.stringify(this.state.modeloCadastroColuna),
            }).then((response) => {
                return response.json() as Promise<ModeloCadastroVisualizarDadosData>
            }).then((data) => {

                this.setState({ empData: data })
                if ($.isEmptyObject(data.error)) {
                    this.redirectPage();
                }
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            })

        }
        else {
            Loading.showLoading(this);
            HttpService.fetch('api/ModeloCadastroVisualizarDadosApi/Salvar', {
                method: 'POST',
                body: JSON.stringify(this.state.modeloCadastroColuna),
            }).then((response) => {
                return response.json()
            }).then((data) => {

                this.setState({ empData: data })
                if ($.isEmptyObject(data.error)) {
                    this.redirectPage();
                }
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            })
        }
    }

    private handleChange(e) {
        var targetInput = e.target.id;
        var id = targetInput.split("valor");

        var targetCheckbox = e.target.name;
        var idCheckbox = targetCheckbox.split("valor");

        var valorCheckbox = e.target.checked == true ? "Verdadeiro" : "Falso";
        
        this.state.modeloCadastroColuna.map(doc =>
            doc.modeloCadastroColunaId == idCheckbox[1] && e.target.type == "checkbox"
                ?
                doc.valor = valorCheckbox
                :
                doc.modeloCadastroColunaId == id[1]
                    ?
                    doc.valor = e.target.value
                    :
                    ""
        )
    }

    private botoesDoCantoSuperiorDireito() {
        return (
            <React.Fragment>
                <button className="btn bg-white shadow-sm mr-2" onClick={this.handleCancel}>{<Resource keyString="back" />}</button>
            </React.Fragment>
        );
    }

    public render() {
        const { getLanguageResource } = this.context;
        let contents = Loading.isLoading(this)
            ? <p><em></em></p>
            : this.renderCreateForm();
        if (Loading.isLoading(this)) return <React.Fragment />;

        return (
            <CaixaDeConteudo
                tituloDoHeader={this.state.title}
                botoes={this.botoesDoCantoSuperiorDireito()}
                itensParaBreadcrumb={[
                    { url: "/", nome: this.context.getLanguageResource("home") },
                    { nome: this.state.title }
                ]}
            >
                <main className='p-4'>
                    {this.renderCreateForm()}
                </main>
            </CaixaDeConteudo>
        );
    }

    private renderCreateForm() {
        const { getLanguageResource } = this.context;
        return (<form onSubmit={this.handleSave} >
            <div className="form-group" >
                <input type="hidden" name="id" value={this.state.empData.id} />
            </div>
            {this.state.modeloCadastroColuna.map(doc =>
                doc.modeloCadastroTipoCampoId == 1
                    ?
                    <div className="form-group">
                        <div className="col">
                            <label className="control-label col-md-12" htmlFor="valor">{doc.nome}</label>
                            <div className="col-md-12">
                                <input className="form-control" type="text" onChange={this.handleChange} id={"valor" + doc.modeloCadastroColunaId} name="valor" defaultValue={doc.valor} />
                            </div>
                        </div>
                    </div >
                    :
                    doc.modeloCadastroTipoCampoId == 2
                        ?
                        <div className="form-group">
                            <div className="col">
                                <label className="control-label col-md-12" htmlFor="valor">{doc.nome}</label>
                                <div className="col-md-12">
                                    <input className="form-control" type="number" onChange={this.handleChange} id={"valor" + doc.modeloCadastroColunaId} name="valor" defaultValue={doc.valor} />
                                </div>
                            </div>
                        </div >
                        :
                        doc.modeloCadastroTipoCampoId == 3
                            ?
                            <div className="form-group">
                                <div className="col">
                                    <label className="control-label col-md-12" htmlFor="valor">{doc.nome}</label>
                                    <div className="col-md-12">
                                        <input className="form-control" type="date" onChange={this.handleChange} id={"valor" + doc.modeloCadastroColunaId} name="valor" defaultValue={doc.valor} />
                                    </div>
                                </div>
                            </div >
                            :
                            doc.modeloCadastroTipoCampoId == 4
                                ?
                                <div className="form-group">
                                    <div className="col">
                                        <label className="control-label col-md-12" htmlFor="valor">{doc.nome}</label>
                                        <div className="col-md-12">
                                            <input className="form-control" type="time" onChange={this.handleChange} id={"valor" + doc.modeloCadastroColunaId} name="valor" defaultValue={doc.valor} />
                                        </div>
                                    </div>
                                </div >
                                :
                                doc.modeloCadastroTipoCampoId == 5
                                    ?
                                    <div className="form-group px-5" >
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" onChange={this.handleChange} id="valor" name={"valor" + doc.modeloCadastroColunaId} defaultChecked={doc.valor} />
                                            <label className="custom-control-label" htmlFor="valor">{doc.nome}</label>
                                        </div>
                                    </div>
                                    :
                                    doc.modeloCadastroTipoCampoId == 6
                                        ?
                                        <div className="form-group">
                                            <div className="col">
                                                <label className="control-label col-md-12" htmlFor="valor">{doc.nome}</label>
                                                <div className="col-md-12">
                                                    <select className="form-control col-md-12" name="valor" id={"valor" + doc.modeloCadastroColunaId}
                                                        defaultValue={(doc.valor) ? doc.valor : ''}
                                                        onChange={this.handleChange}>
                                                        <option value="">-- Selecione --</option>
                                                        {doc.modeloCadastroCamposMultiplaEscolha.map((item) =>
                                                            <option key={item.id} value={item.nome}>{item.nome}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                        </div >
                                        :
                                        ""
            )}
            <div className="form-group col-md-12 d-none" >
                <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" name="isactive" id="isactive" defaultChecked={this.state.empData.isactive} />
                    <label className="custom-control-label" htmlFor="isactive">{<Resource keyString="active" />}</label>
                </div>
            </div>
            <div className="form-group col-12 d-flex justify-content-center">
                <button type="submit" className="btn font-weight-bold" style={{ backgroundColor: 'var(--ws-blue)', width: '17.5rem' }}>{<Resource keyString="save" />}</button>
            </div >
        </form >)
    }
}