import * as React from 'react';
import { useContext } from 'react';
import { ResponderFormularioContext } from '../../contexts/ResponderFormularioContext';
import { IAnexoDeImagem } from '../../interfaces/IResponderFormularioResponse';
import { criarArrayDePonteirosDasPerguntas } from '../../utils/geradoresDeArrayDePonteiros';

interface IProps {
	anexoDeImagem: IAnexoDeImagem;
	idDaPergunta: number;
};

const CardDeImagemParaUpload = ({ anexoDeImagem, idDaPergunta }: IProps) => {
	const { responderFormulario, setResponderFormulario } = useContext(ResponderFormularioContext);

	const responderFormularioAtualizado = { ...responderFormulario };
	const ponteirosDasPerguntasDeResponderFormulario = criarArrayDePonteirosDasPerguntas(responderFormularioAtualizado);
	const ponteiroDaPergunta = ponteirosDasPerguntasDeResponderFormulario.find(pergunta => pergunta.id === idDaPergunta);

	const handleRemove = () => {
		const imagemASerRemovida = ponteiroDaPergunta?.imagens[ponteiroDaPergunta.imagens.indexOf(anexoDeImagem)];

		if (imagemASerRemovida) {
			imagemASerRemovida.status = 'Removido';
        }
		//if (ponteiroDaPergunta) ponteiroDaPergunta.imagens.splice(ponteiroDaPergunta.imagens.indexOf((anexoDeImagem), 1));
		setResponderFormulario(responderFormularioAtualizado);
	};

	return (
		<div className='mx-1 mb-2' style={{ position: 'relative' }}>
			<img
				className='rounded'
				style={{ width: '120px', height: '120px' }}
				src={`${process.env.REACT_APP_URL_API}api/ResultadoXArquivosApi/DownloadFile/${anexoDeImagem.arquivo}`}
				alt="Imagem anexada"
			/>
			<i
				className='fa fa-times text-danger'
				aria-hidden="true"
				style={{ position: 'absolute', top: '5px', right: '5px', cursor: 'pointer' }}
				onClick={handleRemove}
			/>
		</div>
	);
};

export default CardDeImagemParaUpload;
