import * as React from 'react';
import { PerguntaData } from './FetchPergunta';
import { ModalPlanoAcao } from '../PlanoAcao/ModalPlanoAcao';
import * as ReactDOM from 'react-dom';
import $ from 'jquery';
import { ModalDocumentos } from '../../Documentos/ModalDocumentos';
import { DocumentoData } from '../../Documentos/FetchDocumentos';
import { Resource } from '../../../index';
import { SetorData } from '../../Setores/FetchSetor';
import { ResultadoXArquivo } from '../Formulario/FormularioRespondido';
import ModalWinSet from '../../Shared/components/ModalWinSet';

interface VisualizacaoPerguntaState {
    pergunta: PerguntaData;
    documentovinculados: DocumentoData[];
    resultadoXArquivos: ResultadoXArquivo[];
    setor: SetorData;
    callBack: any;
    modalDePlanoDeAcaoAberto: boolean;
}

export class VisualizacaoPergunta extends React.Component<any, VisualizacaoPerguntaState> {
    constructor(props) {
        super(props);

        this.state = {
            pergunta: props.pergunta,
            documentovinculados: props.callBack != null ? props.callBack.resultadoXDocumento(props.pergunta.grupoperguntaid, props.pergunta.id) : [],
            resultadoXArquivos: props.callBack != null ? props.callBack.resultadoXArquivos(props.pergunta.id, props.setor.id) : [],
            callBack: props.callBack,
            setor: props.setor,
            modalDePlanoDeAcaoAberto: false,
        };

    }

    montaClassNameDoInputDasRespostas(permitirobservacaoobrigatoria, permitiranexarevidenciaobrigatoria, permitirplanoacaoobrigatoria, permitiranexarfotoappobrigatoria) {

        var string = "ipt-radio-resposta";

        if (permitirobservacaoobrigatoria == true) {
            string += " permitirobservacaoobrigatoria";
        }

        if (permitiranexarevidenciaobrigatoria == true) {
            string += " permitiranexarevidenciaobrigatoria";
        }

        if (permitirplanoacaoobrigatoria == true) {
            string += " permitirplanoacaoobrigatoria";
        }

        if (permitiranexarfotoappobrigatoria == true) {
            string += " permitiranexarfotoappobrigatoria";
        }

        return string;
    }

    private montaPergunta() {
        if (this.state.pergunta.ajuda == null)
            this.state.pergunta.ajuda = "";

        var pergunta = this.state.pergunta;

        return <React.Fragment>
            <h4>{this.state.pergunta.titulo}</h4>
            <small>{this.state.pergunta.descricao}</small>
            <hr />
            {
                this.state.pergunta.ajuda != ""
                    ?   (
                            <blockquote className='d-flex border p-2 rounded'>
                                <div>
                                    <i className="fa fa-info-circle m-3" aria-hidden="true" />
                                </div>
                                <div className='d-flex align-items-center justify-content-stretch'>
                                    <p className='mb-0'>{this.state.pergunta.ajuda}</p>
                                </div>
                            </blockquote>
                        )
                    :   ""
            }
            <div className="btn-group btn-group-toggle btn-resposta" data-idpergunta={this.state.pergunta.id} data-toggle="buttons">
                {this.state.pergunta.respostas.sort((a, b) => a.ordenacao - b.ordenacao).map(element =>

                    <label className="btn btn-outline-dark" htmlFor={this.state.pergunta.id + ";" + this.state.setor.id}
                        key={"resp" + element.id + this.state.pergunta.grupoperguntaid + this.state.pergunta.id + this.state.setor.id}>
                        <input
                            type="radio"
                            className={this.montaClassNameDoInputDasRespostas(element.permitirobservacaoobrigatoria, element.permitiranexarevidenciaobrigatoria, element.permitirplanoacaoobrigatoria, element.permitiranexarfotoappobrigatoria)}
                            name={this.state.pergunta.grupoperguntaid + ";" + this.state.pergunta.id + ";" + this.state.setor.id}
                            data-ordenacao={element.ordenacao}
                            id={this.state.pergunta.id + ";" + this.state.setor.id}
                            value={element.id}
                        />
                        {element.nome}
                    </label>
                )}
            </div>
            {this.state.pergunta.permitirobservacao == true ?
                <div className="w-100 observacao my-2">
                    {<Resource keyString="observation" />}
                    <textarea className="border w-100 form-control" name={"obs" + pergunta.grupoperguntaid + ";" + pergunta.id + ";" + this.state.setor.id} maxLength={5000} ></textarea>
                </div>
                : ""
            }
            {this.state.pergunta.permitiranotacao == true ?
                <div className="w-100 my-2">
                    {<Resource keyString="annotation" />}<textarea className="border w-100 form-control"
                        name={"anotacao" + pergunta.grupoperguntaid + ";" + pergunta.id + ";" + this.state.setor.id} maxLength={254}></textarea>
                </div>
                : ""
            }
            <span className="pull-left anexarECriar">
                {this.state.pergunta.permitiranexarevidencia == true ?
                    <button className='btn btn-outline-dark' id="anexarEvidencia" data-toggle="modal" data-target={"#modalEvidencia" + this.state.pergunta.id + "-" + this.state.setor.id}
                        type="button" style={{ marginRight: "5px" }}><i className='mr-2  fa fa-paperclip'></i>{<Resource keyString="attach_evidence" />}</button>
                    : ""
                }
                {this.state.pergunta.permitircriaracao == true ?
                    <button className='btn btn-outline-dark' id="planoAcao" data-toggle="modal" data-target={"#modalPlanoAcao" + this.state.pergunta.id + "-" + this.state.setor.id}
                        onClick={() => { this.handleModalPlanoAcao.handleRender() }}
                        type="button"><i className='mr-2     fa fa-plus'></i>{<Resource keyString="create_action" />}</button>
                    : ""
                }
            </span>
            <div className="clearfix"></div>

        </React.Fragment>
    }

    private DesvincularDocumento(doc: DocumentoData) {
        var valorAnterior = $(`form [name="${this.state.pergunta.grupoperguntaid};${this.state.pergunta.id};${this.state.setor.id}"]:checked`).val();
        var documentos: DocumentoData[] = [];
        this.state.documentovinculados.map(x => {
            if (x != doc) {
                documentos.push(x);
            }
        });
        this.setState({ documentovinculados: documentos })
        this.forceUpdate();
        var classeResponderForm = this.state.callBack;
        classeResponderForm.simularClickNaResposta(this.state.pergunta.id, this.state.setor.id, valorAnterior);
        //adiciona o elemento a uma lista e renderiza na pergunta
    }

    private DesvincularResultadoXArquivo(resultado: ResultadoXArquivo) {
        var valorAnterior = $(`form [name="${this.state.pergunta.grupoperguntaid};${this.state.pergunta.id};${this.state.setor.id}"]:checked`).val();
        var resultadoXArquivo: ResultadoXArquivo[] = [];
        this.state.resultadoXArquivos.map(x => {
            if (x != resultado) {
                resultadoXArquivo.push(x);
            }
        });
        this.setState({ resultadoXArquivos: resultadoXArquivo })
        this.forceUpdate();
        var classeResponderForm = this.state.callBack;
        classeResponderForm.simularClickNaResposta(this.state.pergunta.id, this.state.setor.id, valorAnterior);
        //adiciona o elemento a uma lista e renderiza na pergunta
    }

    static LinhaDocumento(grupoperguntaid, perguntaid, setorid, documento: DocumentoData) {
        return <p key={grupoperguntaid + perguntaid + documento.id}>
            <input type="hidden" name={`doc${grupoperguntaid};${perguntaid};${setorid}`} value={documento.id} />
            <strong>
                {documento.nome} {documento.arquivovigente != null && documento.arquivovigente.id > 0 ? `(${<Resource keyString="version" />}: ${documento.arquivovigente.versao}` : ""}
            </strong>
        </p >;
    }

    public render() {
        var id = "modalPlanoAcao" + this.state.pergunta.id + "-" + this.state.setor.id;
        var modal = <React.Fragment>
            {
                this.state.modalDePlanoDeAcaoAberto
                    ?   (
                            <ModalWinSet
                                fecharModal={() => { this.setState({ modalDePlanoDeAcaoAberto: false }) }}
                            >
                                <ModalPlanoAcao classe={this} key={id} id={id} formularioid={this.state.pergunta.formularioid} perguntaid={this.state.pergunta.id} hash={this.state.callBack != undefined ? this.state.callBack.state.hash : ""} />
                            </ModalWinSet>

                        )
                    :   <React.Fragment />
            }
            <ModalDocumentos key={`modalEvidencia${this.state.pergunta.id}-${this.state.setor.id}`} id={`modalEvidencia${this.state.pergunta.id}-${this.state.setor.id}`} formularioid={this.state.pergunta.formularioid} callBack={this} />
        </React.Fragment>;

        $(`div[id="div${id}"]`).remove();
        $('body').append(`<div id="div${id}"></div>`);
        ReactDOM.render(modal, document.getElementById('div' + id));

        //<div dangerouslySetInnerHTML={{ __html: this.montaHtmlPergunta() }} />
        return <React.Fragment>
            {this.montaPergunta()}
            {this.state.documentovinculados != null ?
                <div id={"evidencias" + this.state.pergunta.id} className="mb-2 mt-2">
                    <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                        {
                            this.state.documentovinculados.map(doc =>
                                <div className="btn-group mr-2" role="group" aria-label="First group">
                                    <div key={this.state.pergunta.grupoperguntaid + this.state.pergunta.id + doc.id}>
                                        <input type="hidden" name={`doc${this.state.pergunta.grupoperguntaid};${this.state.pergunta.id};${doc.id}`} value={doc.id} />
                                        <div className="btn-group" role="group" aria-label="Basic example">
                                            <button type="button" className="btn btn-square btn-light" id="anexoVinculado" title={doc.nome}><i className='fa fa-file'></i> {doc.nome} {doc.arquivovigente != null && doc.arquivovigente.id > 0 ? `(${<Resource keyString="version" />}: ${doc.arquivovigente.versao}) ` : ""}</button>
                                            <button className="btn btn-square btn-light" type="button" onClick={() => this.DesvincularDocumento(doc)}><i className='fa fa-times'></i></button>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
                : ""
            }
            {this.state.resultadoXArquivos != null ?
                <div id={"evidencias" + this.state.pergunta.id} className="mb-2 mt-2">
                    <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                        {
                            this.state.resultadoXArquivos.map((resultado, index) =>
                                <div className="btn-group mr-2" role="group" aria-label="First group">
                                    <div key={this.state.pergunta.grupoperguntaid + this.state.pergunta.id + resultado.id}>
                                        <input type="hidden" name={`arquivo;${this.state.pergunta.id};${resultado.setorid > 0 ? resultado.setorid : ''};${resultado.caminhovirtual.split('.')[0]}`} value="-" />
                                        <div className="btn-group" role="group" aria-label="Basic example">
                                            <img src={"api/ResultadoXArquivosApi/DownloadFile/" + resultado.caminhovirtual} width="50" />
                                            <button type="button" className="btn btn-square btn-light" id="anexoVinculado" title={"Foto " + index}><i className='fa fa-file'></i>Foto {index}</button>
                                            <button className="btn btn-square btn-light" type="button" onClick={() => this.DesvincularResultadoXArquivo(resultado)}><i className='fa fa-times'></i></button>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
                : ""
            }
        </React.Fragment >;
    }

    public handleModalPlanoAcao: any = {};
}