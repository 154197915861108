import * as React from 'react';

const { useRef, useState, useEffect } = React;

interface IProps {
  titulo: string;
  textoDeAjuda: string;
  posicionamento: 'superior-esquerdo' | 'superior-direito' | 'inferior-esquerdo' | 'inferior-direito';
  style?: React.CSSProperties;
}

const IconeDeAjuda = ({ titulo, textoDeAjuda, posicionamento, style }: IProps) => {
  const [mostrarAjuda, setMostrarAjuda] = useState(false);
  const [posicao, setPosicao] = useState({ x: 0, y: 0 });
  const caixaDeAjuda = useRef<HTMLDivElement>(null);

  const definirPosicao = (dimensoesDoTarget = { width: 0, height: 0 }) => {
    const LARGURA_DO_ICONE = dimensoesDoTarget.width;
    const ALTURA_DO_ICONE = dimensoesDoTarget.height;
    const balaoDeAjuda = {
      width: caixaDeAjuda.current!.offsetWidth,
      height: caixaDeAjuda.current!.offsetHeight,
    }

    switch (posicionamento) {
      case 'superior-esquerdo':
        setPosicao({ x: LARGURA_DO_ICONE - balaoDeAjuda.width, y: -balaoDeAjuda.height });
        break;
      case 'superior-direito':
        setPosicao({ x: LARGURA_DO_ICONE, y: -balaoDeAjuda.height });
        break;
      case 'inferior-esquerdo':
        setPosicao({ x: LARGURA_DO_ICONE - balaoDeAjuda.width, y: ALTURA_DO_ICONE * 1.5 });
        break;
      case 'inferior-direito':
        setPosicao({ x: LARGURA_DO_ICONE, y: ALTURA_DO_ICONE * 1.5 });
        break;
      default:
        setPosicao({ x: 0, y: 0 });
        break;
    }
  }

  useEffect(() => {
    definirPosicao()
  }, []);

  const handleMouseOver = ({ target }) => {
    const dimensoesDoTarget = { width: target.offsetWidth, height: target.offsetHeight };

    definirPosicao(dimensoesDoTarget);
    setMostrarAjuda(true);
  }

  const handleMouseOut = () => setMostrarAjuda(false);

  return (
    <div style={ { position: 'relative' } }>
      <div
        style={ {
          display: 'block',
          position: 'absolute',
          width: '340px',
          zIndex: 100,
          pointerEvents: 'none',
          padding: '0.5rem',
          fontSize: '0.8rem',
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
          color: 'white',
          fontWeight: 400,
          borderRadius: '0.4rem',
          fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
          top: posicao.y,
          left: posicao.x,
          opacity: mostrarAjuda ? 1 : 0,
        } }
        ref={ caixaDeAjuda }
      >
        <div className="d-flex flex-column align-items-center border-bottom border-secondary">
          <h5 className='mb-2' style={ { color: 'white' } } >{ titulo }</h5>
        </div>
        <div>
          { textoDeAjuda }
        </div>
      </div>
      <i
        className="fa fa-info-circle text-primary"
        aria-hidden="true"
        onMouseOver={ handleMouseOver }
        onMouseOut={ handleMouseOut }
        style={ { cursor: 'pointer', fontSize: '1rem', ...style } }
      />
    </div>
  );
}

export default IconeDeAjuda;
