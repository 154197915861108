import * as React from 'react';
import { ArquivoData } from './FetchArquivo';
import { HttpService } from '../Shared/HttpService';
import { Loading } from '../Shared/Loading';
import { LoginData } from '../UserAuth/Login';
import $ from 'jquery';
import { ValidationLabelMessage, ValidationAlertMessage } from '../Shared/ValidationMessage';
import { Resource } from '../../index';
import { LanguageContext } from '../../context/LanguageContext'; 

interface AddArquivoDataState {
    id: string;
    arquivo: ArquivoData;
    login: LoginData;
    assinar: boolean;
    arquivar: boolean;
    tornarVigente: boolean;
    callBack: any;
}

export class AssinarArquivo extends React.Component<any, AddArquivoDataState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        // This will set state for Add User  
        this.state = {
            arquivo: props.arquivo,
            id: props.id,
            login: new LoginData,
            callBack: props.callBack,
            assinar: props.assinar,
            arquivar: props.arquivar,
            tornarVigente: props.tornarVigente
        };

        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);
    }

    // This will handle the submit form event.  
    private handleSave(event) {
        event.preventDefault();

        var url = "";
        switch (true) {
            case this.state.assinar:
                url = `api/ArquivosApi/Assinar/Assinar/${this.state.arquivo.id}`;
                break;
            case this.state.arquivar:
                url = `api/ArquivosApi/Assinar/Arquivar/${this.state.arquivo.id}`;
                break;
            case this.state.tornarVigente:
                url = `api/ArquivosApi/Assinar/TornarVigente/${this.state.arquivo.id}`;
                event.target.append('dataVencimento', this.state.arquivo.datavencimento);
                break;
        }

        Loading.showLoading(this);;

        HttpService.fetch(url, {
            method: 'POST',
            body: HttpService.formToJson(event.target),

        }).then((response) => {

            return response.json() as Promise<LoginData>;

        }).then((data) => {

            if ($.isEmptyObject(data.error)) {
                //assinatura aprovado
                this.setState({ login: new LoginData })
                this.state.callBack.updateArquivos();
                $('#'+this.state.id + ' [data-dismiss="modal"]').click();
                //alert('assinado');
            } else {
                this.setState({ login: data })
            }
            Loading.hideLoading(this);

        }).catch((responseJson) => {

            console.log(responseJson);
            Loading.hideLoading(this);

        })
    }

    public handleMinDate() {
        var n = new Date();
        n.setDate(n.getDate() + 1);
        return n.toISOString().split("T")[0];
    };

    // Returns the HTML Form to the render() method.  
    public render() {
        const { getLanguageResource } = this.context;
        return (
            <div className="modal" id={this.state.id}>
                <div className="modal-dialog">
                    <div className="modal-content">

                        <form onSubmit={this.handleSave} >
                            <div className="modal-header">
                                <h4 className="modal-title">{<Resource keyString="to_sign_version" />} {this.state.arquivo.versao}</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>

                            <div className="modal-body">
                                <div className="form-group">
                                    <label>{<Resource keyString="email" />}</label>
                                    <ValidationLabelMessage errors={this.state.login.error} name="Email">
                                        <input className="form-control" type="email" name="email" placeholder={getLanguageResource("email")} required/>
                                    </ValidationLabelMessage>
                                </div>
                                <div className="form-group">
                                    <label>{<Resource keyString="password" />}</label>
                                    <ValidationLabelMessage errors={this.state.login.error} name="Senha" >
                                        <input className="form-control" type="password" name="senha" placeholder={getLanguageResource("password")} required/>
                                    </ValidationLabelMessage>
                                </div>
                                {
                                    this.state.tornarVigente && (
                                        <div className="form-group">
                                            <label>{<Resource keyString="expired_date" />}</label>
                                            <input
                                                className="form-control"
                                                type="date"
                                                name="dataVencimento"
                                                placeholder={getLanguageResource("expired_date")}
                                                onChange={(e) => this.setState({ arquivo: { ...this.state.arquivo, datavencimento: e.target.value } })}
                                                value={this.state.arquivo.datavencimento}
                                                min={this.handleMinDate()}
                                                required
                                            />
                                        </div>
                                    )
                                }
                                <ValidationAlertMessage errors={this.state.login.error} name="Summary" />
                            </div>

                            <div className="modal-footer">
                                <button className="btn btn-success" type="submit"><i className='fa fa-save'></i> {<Resource keyString="to_sign" />}</button>
                                <button type="button" className="btn btn-link" data-dismiss="modal"><i className='fa fa-times'></i> {<Resource keyString="close" />}</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>)
    }

}  