import React from "react";

interface IDadosDaEmpresa {
    nomeFantasia: string;
    cnpj: string;
    razaoSocial: string;
}

interface IProps {
    dadosDaEmpresa: IDadosDaEmpresa;
}

const ConteudoDoTooltipDeInformacaoDaEmpresa = ({ dadosDaEmpresa }: IProps) => {
    return (
        <div className="d-flex flex-column p-2">
            <div className="w-100 text-left">
                <strong>Nome fantasia:</strong> {dadosDaEmpresa.nomeFantasia}
            </div>
            <div className="w-100 text-left">
                <strong>Razão social:</strong> {dadosDaEmpresa.razaoSocial}
            </div>
            <div className="w-100 text-left">
                <strong>CNPJ:</strong> {dadosDaEmpresa.cnpj}
            </div>
        </div>    
    );
};

export default ConteudoDoTooltipDeInformacaoDaEmpresa;
