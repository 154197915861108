import * as React from 'react';
import { PlanoAcaoData } from './FetchPlanoAcao';
import { HttpService } from '../../Shared/HttpService';
import { Loading } from '../../Shared/Loading';
import $ from 'jquery';
import { ValidationLabelMessage, ValidationAlertMessage } from '../../Shared/ValidationMessage';
import { Resource } from '../../../index';
import { CampoModeloAcaoData } from '../../ModelosPlanoAcao/CampoModeloAcao/FetchCampoModeloAcao';
import { LanguageContext } from '../../../context/LanguageContext';
import { ToggleSwitch } from '../../Shared/components/ToggleSwitch';

interface ModalPlanoAcaoDataState {
    id: string;
    planoAcao: PlanoAcaoData;
    formularioid: number;
    perguntaid: number;
    hash: string;
    callBack: any;
    listaCampoModeloAcao: CampoModeloAcaoData[];
    listaCampoModeloAcaoXValor: Array<any>;
}

export class ModalPlanoAcao extends React.Component<any, ModalPlanoAcaoDataState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        var planoAcao = new PlanoAcaoData;

        if (props.planoAcao != null) {
            planoAcao = props.planoAcao;
        }

        // This will set state for Add User  
        this.state = {
            planoAcao: planoAcao,
            id: props.id,
            callBack: props.callBack,
            formularioid: props.formularioid,
            perguntaid: props.perguntaid,
            listaCampoModeloAcao: [],
            listaCampoModeloAcaoXValor: [],
            hash: props.hash
        };

        //Mapeamento para preencher o plano de acao padrao com os valores salvos no banco.
        if (this.state.planoAcao.campomodeloacaoXValor != undefined) {
            this.state.planoAcao.campomodeloacaoXValor.map((x, i) => {
                if ("Oque" == x.campo) {
                    this.state.planoAcao.oque = x.valor;
                }
                else if ("Porque" == x.campo) {
                    this.state.planoAcao.porque = x.valor;
                }
                else if ("Onde" == x.campo) {
                    this.state.planoAcao.onde = x.valor;
                }
                else if ("Quando" == x.campo) {
                    this.state.planoAcao.quando = x.valor;
                }
                else if ("Quem" == x.campo) {
                    this.state.planoAcao.quem = x.valor;
                }
                else if ("Como" == x.campo) {
                    this.state.planoAcao.como = x.valor;
                }
                else {
                    this.state.planoAcao.quantocusta = x.valor;
                }
            });
        }

        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);

        if (props.classe) {
            props.classe.handleModalPlanoAcao = this;
        }
    }

    componentDidMount(): void {
        Loading.showLoading(this);
        const url = 'api/CampoModeloAcaoApi/ListarCampoPorFormulario/' + this.props.formularioid;
        let campos;
        HttpService.fetch(url, {})
            .then(response => response.json() as Promise<CampoModeloAcaoData[]>)
            .then(data => campos = data)
            .then(() => {
                HttpService.fetch(`api/CampoModeloAcaoXValorApi/ListarValoresANivelDeFormulario/${this.props.formularioid}/${this.props.hash}`, { method: 'GET' })
                    .then(response => response.json() as Promise<any[]>)
                    .then(campoModeloAcaoXValorData => {
                        campoModeloAcaoXValorData.forEach(element => {
                            const campo = campos.find(x => x.id === element.campomodeloacao_id);
                            if (element.valor !== null && element.formatocampomodeloacao_id === 4) {
                                campo.isChecked = element.valor === campo.valorAtivoToggle;
                            } else {
                                campo.isChecked = campo.valorPadraoToggle;
                            }
                        });
                        this.setState({ listaCampoModeloAcao: campos, listaCampoModeloAcaoXValor: campoModeloAcaoXValorData });
                        Loading.hideLoading(this);
                    });
            })
    }

    // This will handle the submit form event.  
    private handleSave(event) {
        event.preventDefault();

        Loading.showLoading(this);
        if (this.state.listaCampoModeloAcaoXValor.length > 0) {

            HttpService.fetch('api/PlanoAcaoApi/aNivelDoFormulario/' + this.state.formularioid + '/' + this.state.hash, {
                method: 'PUT',
                body: HttpService.formToJsonWithAllData(event.target),
            }).then((response) => {
                return response.json() as Promise<PlanoAcaoData>;
            }).then((data) => {
                this.setState({ planoAcao: data })
                
                if ($.isEmptyObject(data.error)) {
                    //assinatura aprovado
                    //this.state.callBack.updatePlanoAcao();
                    $('#' + this.state.id + ' [data-dismiss="modal"]').click();
                    $('.modal-backdrop.show').remove();
                    $('body').removeClass('modal-open');
                    this.forceUpdate();
                    //alert('assinado');
                }
                Loading.hideLoadingWithoutScroll(this);
                window.location.reload();
                this.props.fecharModal();
            }).catch((responseJson) => {
                console.log(responseJson);
                Loading.hideLoadingWithoutScroll(this);
            })
        } else {

            HttpService.fetch(`api/PlanoAcaoApi`, {
                method: 'POST',
                body: HttpService.formToJsonWithAllData(event.target),
            }).then((response) => {
                return response.json() as Promise<PlanoAcaoData>;
            }).then((data) => {
                this.setState({ planoAcao: data })

                if ($.isEmptyObject(data.error)) {
                    this.setState({ planoAcao: new PlanoAcaoData })
                    //assinatura aprovado
                    $('#' + this.state.id + ' [data-dismiss="modal"]').click();
                    $('.modal-backdrop.show').remove();
                    $('body').removeClass('modal-open');
                    this.forceUpdate();
                    //alert('assinado');
                }
                Loading.hideLoadingWithoutScroll(this);
                window.location.reload();
                this.props.fecharModal();
            }).catch((responseJson) => {
                console.log(responseJson);
                Loading.hideLoadingWithoutScroll(this);
            })
        }
    }

    // Returns the HTML Form to the render() method.  
    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;
        return (
            <form className='p-3' onSubmit={this.handleSave} >
                <header className='w-100 d-flex justify-content-center my-3'>
                    <h3>{<Resource keyString="add_action_plan" />}</h3>
                </header>

                <div>
                <input type="hidden" name="formularioid" value={this.state.formularioid} />
                {
                    (this.state.hash) ?
                        <input type="hidden" name="hash" value={this.state.hash} />
                        :
                        <React.Fragment></React.Fragment>
                }
                {
                    (this.state.perguntaid) ?
                        <input type="hidden" name="perguntaid" value={this.state.perguntaid} />
                        :
                        <React.Fragment></React.Fragment>
                }
                <input type="hidden" name="id" value={this.state.planoAcao.id} />

                {
                    (this.state.listaCampoModeloAcao && this.state.listaCampoModeloAcao.length > 0)
                        ?   this.renderModeloAcao()
                        :   this.renderPlanoAcaoPadrao()
                }   

                <ValidationAlertMessage errors={this.state.planoAcao.error} name="Summary" />
            </div>

                <footer className='d-flex justify-content-end mt-3'>
                    <button className="btn mx-2" style={{ backgroundColor: 'var(--ws-blue)' }} type="submit"><i className='fa fa-save'></i> {<Resource keyString="save" />}</button>
                    <button type="button" className="btn ws-outline-blue" onClick={() => this.props.fecharModal()}><i className='fa fa-times'></i> {<Resource keyString="close" />}</button>
                </footer>
            </form>
        );
    }

    private renderPlanoAcaoPadrao() {
        return <React.Fragment>
            <div className="form-row mx-0 px-0">
                <div className="col mt-3">
                    <p className='mb-0'>{<Resource keyString="what" />}</p>
                    <ValidationLabelMessage errors={this.state.planoAcao.error} name="mock_1_Oque">
                        <textarea className="form-control" name="mock_1_Oque" placeholder={this.context.getLanguageResource("what")}
                            defaultValue={this.state.planoAcao.oque}>
                        </textarea>
                    </ValidationLabelMessage>
                </div>
                <div className="col mt-3">
                    <p className='mb-0'>{<Resource keyString="why" />}</p>
                    <ValidationLabelMessage errors={this.state.planoAcao.error} name="mock_1_Porque">
                        <textarea className="form-control" name="mock_1_Porque" placeholder={this.context.getLanguageResource("why")} defaultValue={this.state.planoAcao.porque}></textarea>
                    </ValidationLabelMessage>
                </div>
            </div>
            <div className="form-row mx-0 px-0">
                <div className="col mt-3">
                    <p className='mb-0'>{<Resource keyString="where" />}</p>
                    <ValidationLabelMessage errors={this.state.planoAcao.error} name="mock_1_Onde">
                        <textarea className="form-control" name="mock_1_Onde" placeholder={this.context.getLanguageResource("where")} defaultValue={this.state.planoAcao.onde} ></textarea>
                    </ValidationLabelMessage>
                </div>
                <div className="col mt-3">
                    <p className='mb-0'>{<Resource keyString="when" />}</p>
                    <ValidationLabelMessage errors={this.state.planoAcao.error} name="mock_1_Quando">
                        <textarea className="form-control" name="mock_1_Quando" placeholder={this.context.getLanguageResource("when")} defaultValue={this.state.planoAcao.quando}></textarea>
                    </ValidationLabelMessage>
                </div>
            </div>
            <div className="form-row mx-0 px-0">
                <div className="col mt-3">
                    <p className='mb-0'>{<Resource keyString="who" />}</p>
                    <ValidationLabelMessage errors={this.state.planoAcao.error} name="mock_1_Quem">
                        <textarea className="form-control" name="mock_1_Quem" placeholder={this.context.getLanguageResource("who")} defaultValue={this.state.planoAcao.quem}></textarea>
                    </ValidationLabelMessage>
                </div>
                <div className="col mt-3">
                    <p className='mb-0'>{<Resource keyString="how" />}</p>
                    <ValidationLabelMessage errors={this.state.planoAcao.error} name="mock_1_Como">
                        <textarea className="form-control" name="mock_1_Como" placeholder={this.context.getLanguageResource("how")} defaultValue={this.state.planoAcao.como}></textarea>
                    </ValidationLabelMessage>
                </div>
            </div>
            <div className="form-row mx-0 px-0">
                <div className="col mt-3">
                    <p className='mb-0'>{<Resource keyString="how_much" />}</p>
                    <ValidationLabelMessage errors={this.state.planoAcao.error} name="mock_1_Quantocusta">
                        <textarea className="form-control" name="mock_1_quantocusta" placeholder={this.context.getLanguageResource("how_much")} defaultValue={this.state.planoAcao.quantocusta}></textarea>
                    </ValidationLabelMessage>
                </div>
            </div>
        </React.Fragment>;
    }

    private renderModeloAcao() {
        return <React.Fragment> {
            this.state.listaCampoModeloAcao.map((o, i) => {
                switch (o.formatocampomodeloacao_id) {
                    case 1:
                        return (
                            <div className="col form-group">
                                <label>{o.nome} {o.obrigatorio ? "*" : ""}</label>
                                <ValidationLabelMessage errors={this.state.planoAcao.error} name={"campo_" + o.id}>
                                    {this.state.listaCampoModeloAcaoXValor.length == 0 ?
                                        <textarea
                                            className="form-control"
                                            name={"campo_" + o.id}
                                            placeholder={o.nome}
                                            defaultValue={this.state.planoAcao["campo_" + o.id]}
                                            required={o.obrigatorio}
                                        ></textarea>
                                        :
                                        this.montaTextAreaComValor(o,this.state.listaCampoModeloAcaoXValor)
                                    }
                                </ValidationLabelMessage>
                            </div>
                        );

                    case 2:
                        return (
                            <div className="col form-group">
                                <label>{o.nome} {o.obrigatorio ? "*" : ""}</label>
                                <ValidationLabelMessage errors={this.state.planoAcao.error} name={"campo_" + o.id}>
                                    {this.state.listaCampoModeloAcaoXValor.length == 0 ?
                                        <input
                                            className="form-control"
                                            name={"campo_" + o.id}
                                            type="date"
                                            placeholder={o.nome}
                                            defaultValue={this.state.planoAcao["campo_" + o.id]}
                                            required={o.obrigatorio}
                                        />
                                        :
                                        this.montaInputComValor(o, this.state.listaCampoModeloAcaoXValor)
                                    }
                                </ValidationLabelMessage>
                            </div>
                        );

                    case 3:
                        return (
                            <div className="col form-group">
                                <label>{o.nome} {o.obrigatorio ? "*" : ""}</label>
                                <ValidationLabelMessage errors={this.state.planoAcao.error} name={"campo_" + o.id}>
                                    {this.state.listaCampoModeloAcaoXValor.length == 0 ?
                                        <select
                                            className="form-control"
                                            name={"campo_" + o.id}
                                            placeholder={o.nome}
                                            defaultValue={this.state.planoAcao["campo_" + o.id]}
                                            required={o.obrigatorio}
                                        >
                                            <option value="">Selecione</option>
                                            {
                                                o.camposmultiplicaescolha.map((opcoes, i) =>
                                                    <option value={opcoes.nome}>{opcoes.nome}</option>
                                                )
                                            }
                                        </select>
                                        :
                                        this.montaSelectBoxComValor(o, this.state.listaCampoModeloAcaoXValor)
                                    }
                                </ValidationLabelMessage>
                            </div>
                        );

                    case 4:
                        const handleChange = (event) => {
                            this.setState({ listaCampoModeloAcaoXValor: this.state.listaCampoModeloAcaoXValor.map((element) => {
                                if (element.campomodeloacao_id === o.id) {
                                    o.isChecked = event.target.checked;
                                }
                                return element;
                            }) })
                        };

                        return (
                            <div className='col form-group'>
                                <label>{o.nome} {o.obrigatorio ? "*" : ""}</label>
                                <div className="d-flex">
                                    <ToggleSwitch
                                        id={"campo_" + o.id}
                                        checked={o.isChecked}
                                        onChange={handleChange}
                                    />
                                    <label className='mb-0 ml-2 mt-1' htmlFor={`"campo_"${o.id}`}>{o.isChecked ? o.valorAtivoToggle : o.valorInativoToggle}</label>
                                </div>
                            </div>
                        );

                    default:
                        return <div>Tipo não informado</div>
                }
            })

        }
        </React.Fragment>;
    }

    private montaTextAreaComValor(o, campoModeloAcaoData) {
        var textArea;
        campoModeloAcaoData.map((x, i) => {
            if (x.campomodeloacao_id == o.id) {
                textArea = <textarea className="form-control" name={"campo_" + o.id}
                    defaultValue={x.valor} ></textarea>
            }
        })

        return textArea;
    }

    private montaInputComValor(o, campoModeloAcaoData) {
        var input;
        campoModeloAcaoData.map((x, i) => {
            if (x.campomodeloacao_id == o.id) {
                input = <input className="form-control" name={"campo_" + o.id}
                    type="date"
                    defaultValue={x.valor} />
            }
        })
        
        return input;
    }

    private montaSelectBoxComValor(o, campoModeloAcaoData) {
        var select;

        campoModeloAcaoData.map((x, i) => {
            if (x.campomodeloacao_id == o.id) {
                select = <select className="form-control" name={"campo_" + o.id}
                    defaultValue={x.valor}>
                    <option value="">Selecione</option>
                    {
                        o.camposmultiplicaescolha.map((opcoes, i) =>
                            <option value={opcoes.nome}>{opcoes.nome}</option>
                        )
                    }
                </select>
            }
        })
        
        return select;
    }
}  