import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { Layout } from '../../Layout';
import { HttpService } from '../../Shared/HttpService';
import { HeadLayout } from '../../Shared/HeadLayout';
import { Loading } from '../../Shared/Loading';
import { ModalDelete } from '../../Shared/ModalDelete';
import { Resource } from '../../../index';
import { FormularioData } from '../Formulario/FetchFormulario';
import { ConfiguracaoFormularioData } from '../ConfiguracaoFormulario/FetchConfiguracaoFormulario';
import { PerguntaData } from '../Pergunta/FetchPergunta';
import { SetorData } from '../../Setores/FetchSetor';
import { GrupoPerguntaData } from '../GrupoPergunta/FetchGrupoPergunta';
import { LanguageContext } from '../../../context/LanguageContext';

interface FetchConfiguracaoFormularioXPerguntaState {
    ConfiguracaoFormularioXPerguntaList: ConfiguracaoFormularioXPerguntaData[];
    configuracaoFormulario: ConfiguracaoFormularioData;
    loading: boolean;
    setores: SetorData[];
    setoresId: number[];
    setorId: number;
    grupoPerguntaList: GrupoPerguntaData[];
}

export class ConfiguracaoFormularioXPerguntaData {
    id: number = 0;
    pergunta: PerguntaData = new PerguntaData;
    setor: SetorData = new SetorData;
    perguntaid: number = 0;
    setorid: number = 0;
    configuracaoformularioid: number = 0;
    error: Array<any> = [];
}

export class FetchConfiguracaoFormularioXPergunta extends React.Component<any, FetchConfiguracaoFormularioXPerguntaState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        let configuracaoFormulario: ConfiguracaoFormularioData = props.ConfiguracaoFormulario;
        this.state = {
            ConfiguracaoFormularioXPerguntaList: [], loading: true,
            configuracaoFormulario: configuracaoFormulario, setores: props.setores,
            setorId: props.setorId, grupoPerguntaList: [],
            setoresId: []
        };

        Loading.showLoading(this);
        HttpService.fetch(`api/GrupoPerguntaApi/All/${configuracaoFormulario.formularioid}`, {})
            .then(response => response.json() as Promise<GrupoPerguntaData[]>)
            .then(data => {
                this.setState({ grupoPerguntaList: data, loading: false });
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            });

        Loading.showLoading(this);
        HttpService.fetch(`api/ConfiguracaoFormularioXPerguntaApi?configuracaoFormularioId=${configuracaoFormulario.id}`, { method: 'GET' })
            .then(response => response.json() as Promise<ConfiguracaoFormularioXPerguntaData[]>)
            .then(data => {
                this.setState({ ConfiguracaoFormularioXPerguntaList: data, loading: false });
                Loading.hideLoading(this);
            });

        this.handleEdit = this.handleEdit.bind(this);

    }

    public render() {
        let contents = Loading.isLoading(this)
            ? <p><em></em></p>
            : this.renderUserTable(this.state.ConfiguracaoFormularioXPerguntaList);

        return <div className="col-lg-12">
            <HeadLayout title={this.context.getLanguageResource("form_configuration")} />
            <div className="card">
                <div className="card-body">
                    <div className="card-title">
                        <h3 className="text-center title-2">{<Resource keyString="form_configuration" />}</h3>
                    </div>
                    <hr />
                    {contents}
                </div>
            </div>
        </div>;
    }

    private handleSave() {

        Loading.showLoading(this);

        let configuracaoArray: any[] = [];
        this.state.ConfiguracaoFormularioXPerguntaList.map(
            config => {
                configuracaoArray.push({
                    setorid: config.setorid,
                    perguntaid: config.perguntaid,
                    configuracaoformularioid: config.configuracaoformularioid
                });
            }
        );

        HttpService.fetch(`api/ConfiguracaoFormularioXPerguntaApi/${this.state.configuracaoFormulario.id}`, {
            method: 'POST',
            body: JSON.stringify(configuracaoArray),
        }).then((response) => {
            return response.json() as Promise<ConfiguracaoFormularioData>
        }).then((data) => {

            Loading.hideLoadingWithoutScroll(this);
        }).catch(() => {
            Loading.hideLoadingWithoutScroll(this);
        })
    }

    private handleEdit(formularioid: number, id: number) {
        this.props.history.push("/ConfiguracaoFormularioXPergunta/edit/" + formularioid + "/" + id);
    }

    private updateConfiguracaoFormularioXPergunta(pergunta: PerguntaData, evt) {
        let config = new ConfiguracaoFormularioXPerguntaData();

        config.setorid = this.state.setoresId[pergunta.id];
        config.perguntaid = pergunta.id;
        config.pergunta = pergunta;
        config.configuracaoformularioid = this.state.configuracaoFormulario.id;
        config.setor = this.state.setores.filter(x => x.id == config.setorid)[0];

        if (!(config.setorid > 0))
            return;

        this.state.ConfiguracaoFormularioXPerguntaList.push(config);
        this.forceUpdate();
        //this.handleSave(config);
    }

    private updateSetorValue(pergunta: PerguntaData, evt) {
        let setorid = parseInt(evt.target.value);
        this.state.setoresId[pergunta.id] = setorid;
    }

    private removerSetorDaPergunta(config: ConfiguracaoFormularioXPerguntaData) {
        this.setState({ ConfiguracaoFormularioXPerguntaList: this.state.ConfiguracaoFormularioXPerguntaList.filter(x => x != config) });
    }

    // Returns the HTML table to the render() method.  
    private renderUserTable(ConfiguracaoFormularioXPerguntaList: ConfiguracaoFormularioXPerguntaData[]) {
        return <table className='table'>
            <thead>
                <tr>
                    <th>{<Resource keyString="name" />}
                        <button className="d-block pull-right btn btn-success" onClick={() => this.handleSave()}>
                            {<Resource keyString="save" />}
                        </button>
                    </th>
                </tr>
            </thead>
            <tbody>
                {this.state.grupoPerguntaList.map(doc =>
                    <tr key={doc.id}>
                        <td>
                            <div className="bg-light">
                                <strong>
                                    {doc.nome}
                                </strong>
                            </div>
                            <br />
                            {doc.pergunta.map(pergunta =>
                                <div key={pergunta.id} className="col-sm-12">
                                    <strong>{pergunta.titulo}</strong>
                                    <br />
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <select className="form-control col-md-12" data-val="true"
                                                id="setorid" name="setorid"
                                                onChange={evt => this.updateSetorValue(pergunta, evt)}>
                                                <option value="">-- {this.context.getLanguageResource("select")} --</option>
                                                {this.state.setores.map(x =>
                                                    <option key={pergunta.id + "-" + x.id} value={x.id}>{x.nome}</option>
                                                )}
                                            </select>
                                        </div>
                                        <div className="col-sm-6">
                                            <button className="btn btn-primary"
                                                onClick={evt => this.updateConfiguracaoFormularioXPergunta(pergunta, evt)}>
                                                +
                                            </button>
                                        </div>
                                        <div className="col-sm-12">
                                            {this.state.ConfiguracaoFormularioXPerguntaList
                                                .filter(x => x.perguntaid == pergunta.id).map(config =>
                                                    <button key={config.id} className="p-1 m-1 pull-left d-block btn btn-danger"
                                                        onClick={() => this.removerSetorDaPergunta(config)}>
                                                        {this.state.setores.filter(x => x.id == config.setorid)[0].nome}
                                                        [X]
                                                    </button>
                                                )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </td>
                    </tr>
                )}
            </tbody>
        </table>;
    }
}   