export class ValidadorDeSenha {
    static possuiTamanhoMinimo(senha: string): boolean {
        return senha.length >= 8;
    };

    static possuiLetraMaiuscula(senha: string): boolean {
        return /[A-Z]/.test(senha);
    }

    static possuiLetraMinuscula(senha: string): boolean {
        return /[a-z]/.test(senha);
    }

    static possuiNumero(senha: string): boolean {
        return /[0-9]/.test(senha);
    }

    static possuiCaractereEspecial(senha: string): boolean {
        return /[^a-zA-Z0-9]/.test(senha);
    }
}