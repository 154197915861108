import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { UsuarioData } from './FetchUsuarios';
import { HttpService } from '../Shared/HttpService';
import { Loading } from '../Shared/Loading';
import { ValidationLabelMessage } from '../Shared/ValidationMessage';
import $ from 'jquery';
import { ListaRegraVinculada } from './ListaRegraVinculada';
import { ChangePassword } from '../UserAuth/ChangePassword';
import { Resource } from '../../index';
import { LanguageContext } from '../../context/LanguageContext';
import CaixaDeConteudo from '../Shared/CaixaDeConteudo';
import ModalWinSet from '../Shared/components/ModalWinSet';

interface AddUserDataState {
    loading: boolean;
    empData: UsuarioData;
    modalAlterarSenhaEstaAberto: boolean;
    modalAdicionarRegraVinculadaEstaAberto: boolean;
}

export class EditUsuarios extends React.Component<RouteComponentProps<{}>, AddUserDataState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        this.state = { loading: true, empData: new UsuarioData, modalAlterarSenhaEstaAberto: false, modalAdicionarRegraVinculadaEstaAberto: false };

        var userid = this.props.match.params["userid"];

        // This will set state for Edit User  
        if (userid > 0) {
            Loading.showLoading(this);;
            HttpService.fetch('api/UsuariosApi/' + userid, { method: 'GET' })
                .then(response => response.json() as Promise<UsuarioData>)
                .then(data => {
                    this.setState({ loading: false, empData: data });
                    Loading.hideLoading(this);
                }).catch(() => {
                    Loading.hideLoading(this);
                    props.history.push("/usuarios");
                });
        }

        // This will set state for Add User  
        else {
            props.history.push("/usuarios");
        }

        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleModalAdicionarRegraVinculada = this.handleModalAdicionarRegraVinculada.bind(this);
        this.handleModalAlterarSenha = this.handleModalAlterarSenha.bind(this);
    }

    handleModalAdicionarRegraVinculada(boolean) {
        this.setState({ modalAdicionarRegraVinculadaEstaAberto: boolean });
    }

    handleModalAlterarSenha(boolean) {
        this.setState({ modalAlterarSenhaEstaAberto: boolean });
    }

    botoesDoCantoSuperiorDireito() {
        const _auth = JSON.parse(localStorage.getItem('auth') as string);
        return (
            <React.Fragment>
                {
                    _auth.tipodeconta === 0
                        ?   (
                                <button className="btn btn-light shadow-sm mr-2 text-nowrap" style={{ whiteSpace: 'nowrap' }} title='Para utilizar esse recurso é necessário um upgrade no plano contratado' disabled><Resource keyString="add_rule" /></button>
                            )
                        :   (
                                <button className="btn btn-light shadow-sm mr-2 text-nowrap" style={{ whiteSpace: 'nowrap' }} onClick={() => this.handleModalAdicionarRegraVinculada(true)}><Resource keyString="add_rule" /></button>
                            )
                }
                <button className="btn btn-outline-dark mr-2 text-nowrap" style={{ whiteSpace: 'nowrap' }} onClick={() => this.handleModalAlterarSenha(true)}><i className='fa fa-lock'></i> {<Resource keyString="change_password" />}</button>
                <button className='btn btn-outline-dark mr-2 text-nowrap' onClick={() => window.location.href = '/usuarios'}><Resource keyString='back' /></button>
            </React.Fragment>
        );
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;

        return (
            <CaixaDeConteudo
                tituloDoHeader={this.context.getLanguageResource("edition_users")}
                botoes={this.botoesDoCantoSuperiorDireito()}
                itensParaBreadcrumb={[
                    { nome: this.context.getLanguageResource("home"), url: "/" },
                    { nome: this.context.getLanguageResource("users"), url: "/usuarios" },
                    { nome: this.context.getLanguageResource("edition_users") }
                ]}
            >
                <div className='p-3'>
                    <header>
                        <h3 className="text-center title-2">{this.context.getLanguageResource("edition_users")}</h3>
                    </header>
                    <form onSubmit={this.handleSave} >
                        <div className="form-group row" >
                            <input type="hidden" name="Id" value={this.state.empData.id} />
                            <input type="hidden" name="Empresasid" value={this.state.empData.id} />
                        </div>
                        < div className="form-group row" >
                            <div className="col-md-6" >
                                <label className=" control-label col-md-12 required" htmlFor="Nome">{<Resource keyString="name" />}</label>
                                <div className="col-md-12">
                                    <ValidationLabelMessage errors={this.state.empData.error} name="Nome">
                                        <input className="form-control" type="text" name="Nome" id="Nome" defaultValue={this.state.empData.nome} />
                                    </ValidationLabelMessage>
                                </div>
                            </div >
                            <div className="col-md-6" >
                                <label className="control-label col-md-12" htmlFor="Documento" >CPF</label>
                                <div className="col-md-12">
                                    <input className="form-control cpf" type="text" name="Documento" id="Documento" defaultValue={this.state.empData.documento} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6">
                                <label className="control-label col-md-12 required" htmlFor="Email" >{<Resource keyString="email" />}</label>
                                <div className="col-md-12">
                                    <ValidationLabelMessage errors={this.state.empData.error} name="Email">
                                        <input className="form-control" type="email" name="Email" id="Email" defaultValue={this.state.empData.email} />
                                    </ValidationLabelMessage>
                                </div>
                            </div>
                        </div >
                        <div className="form-group col-md-12" >
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" name="Isactive" id="Isactive" defaultChecked={this.state.empData.isactive} />
                                <label className="form-check-label" htmlFor="Isactive">{<Resource keyString="active" />}</label>
                            </div>
                        </div>
                        <div className="form-group col-12 d-flex justify-content-center">
                            <button type="submit" className="btn font-weight-bold" style={{ backgroundColor: 'var(--ws-blue)', width: '17.5rem' }}>{<Resource keyString="save" />}</button>
                        </div >
                    </form >
                    {
                        this.state.modalAlterarSenhaEstaAberto && (
                            <ModalWinSet
                                fecharModal={() => this.handleModalAlterarSenha(false)}
                            >
                                <ChangePassword usuariosId={this.state.empData.id} fecharModal={() => this.handleModalAlterarSenha(false)} />
                            </ModalWinSet>
                        )
                    }
                    
                    <ListaRegraVinculada usuariosId={this.state.empData.id} modalAdicionarRegraVinculadaEstaAberto={this.state.modalAdicionarRegraVinculadaEstaAberto} handleModalAdicionarRegraVinculada={this.handleModalAdicionarRegraVinculada} />
                </div>
            </CaixaDeConteudo>
        );
    }

    // This will handle the submit form event.  
    private handleSave(event) {
        event.preventDefault();

        var id = this.state.empData.id;

        Loading.showLoading(this);;
        // PUT request for Edit User.  
        if (id > 0) {
            HttpService.fetch('api/UsuariosApi/' + id, {
                method: 'PUT',
                body: HttpService.formToJson(event.target),
            }).then((response) => {

                return response.json() as Promise<UsuarioData>

            }).then((data) => {
                this.setState({ empData: data });
                if ($.isEmptyObject(this.state.empData.error)) {
                    this.props.history.push("/usuarios");
                }
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            })
        }
    }

    // This will handle Cancel button click event.  
    private handleCancel(e) {
        e.preventDefault();
        this.props.history.push("/usuarios");
    }
}  