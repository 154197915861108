import { IGrupoDePerguntas, IPergunta, IResponderFormulario, IRespostaPossivel } from "../interfaces/IResponderFormularioResponse";

export const criarArrayDePonteirosDasPerguntas = (formulario: IResponderFormulario) => {
  let arrayDePonteiros: IPergunta[] = [];
  const gruposDePerguntasDoFormulario = formulario.grupoDePerguntas;
  
  const retornarArrayDePerguntasRecursivo = (agrupamentoDeGrupoDePerguntas: IGrupoDePerguntas[]): IPergunta[] => {
    agrupamentoDeGrupoDePerguntas.forEach(subAgrupamentoDePerguntas => {
      subAgrupamentoDePerguntas.perguntas.forEach(pergunta => {
        arrayDePonteiros.push(pergunta);
      });
      retornarArrayDePerguntasRecursivo(subAgrupamentoDePerguntas.grupoDePerguntas);
    });
    return arrayDePonteiros;
  };

  return retornarArrayDePerguntasRecursivo(gruposDePerguntasDoFormulario);
}

export const criarArrayDePonteirosDasRespostasPossiveis = (perguntas: IPergunta[]): IRespostaPossivel[] => {
  let arrayDePonteiros: IRespostaPossivel[] = [];
  perguntas.forEach(pergunta => {
    pergunta.respostas.forEach(respostaPossivel => {
      arrayDePonteiros.push(respostaPossivel);
    });
  });
  return arrayDePonteiros;
}

export const criarArrayDePonteirosDosGruposDePerguntas = (formulario: IResponderFormulario) => {
  let arrayDePonteiros: IGrupoDePerguntas[] = [];
  const gruposDePerguntasDoFormulario = formulario.grupoDePerguntas;
  
  const retornarArrayDeGruposDePerguntasRecursivo = (agrupamentoDeGrupoDePerguntas: IGrupoDePerguntas[]): IGrupoDePerguntas[] => {
    agrupamentoDeGrupoDePerguntas.forEach(subAgrupamentoDePerguntas => {
      arrayDePonteiros.push(subAgrupamentoDePerguntas);
      retornarArrayDeGruposDePerguntasRecursivo(subAgrupamentoDePerguntas.grupoDePerguntas);
    });
    return arrayDePonteiros;
  };

  return retornarArrayDeGruposDePerguntasRecursivo(gruposDePerguntasDoFormulario);
}
