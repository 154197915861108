import React from 'react';
import styled from 'styled-components';

export const BotaoEstilizado = styled.button`
    height: 8rem;

    div {
        height: 3rem;
        i {
            font-size: 2.3rem;
            transition: .2s ease-in-out;
        }
    }

    &:hover {
        div {
            i {
                font-size: 2.75rem;
            }
        }
    }
`;

export const Botao = ({ onClick, texto, bootstrapIcon }) => {
    return (
        <BotaoEstilizado onClick={onClick} className='btn rounded border w-100 m-2 d-flex align-items-center justify-content-around flex-column'>
            <div>
                <i className={`bi ${bootstrapIcon}`}></i>
            </div>
            <span className='mb-0'>{texto}</span>
        </BotaoEstilizado>
    )
}