import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Layout } from '../../Layout';
import * as Axios from 'axios';
import $ from 'jquery';
import { Link, NavLink } from 'react-router-dom';

import * as ReactDom from 'react-dom';
import * as Highcharts from 'highcharts';
import 'ol/ol.css';
import * as ol from 'ol';
import Map from 'ol/map';
import View from 'ol/View';
import * as layer from 'ol/layer';
import * as source from 'ol/source';
import Feature from 'ol/Feature';
import * as geom from 'ol/geom';
import { Style, Icon } from 'ol/style';
import * as proj from 'ol/proj';
import { Loading } from '../../Shared/Loading';
import { HttpService } from '../../Shared/HttpService';

interface DashboardDataState {
    map: any;
    listaDashboardMapViewModel: DashboardMapViewModel[];
    listaDashBoardRankingPorUnit: DashBoardRankingPorUnit[];
    listaDashBoardNCPorUnidade: DashBoardNCPorUnidade[];
    listaDashBoardNCPorGrupoPergunta: DashBoardNCPorGrupoPergunta[];
    listaDashBoardNCPorPergunta: DashBoardNCPorPergunta[];
    listaDashBoardPizza: DashBoardPizza[];
}

export class DashboardMapViewModel {
    unitId: number = 0;
    unitName: string = "";
    unitSigla: string = "";
    longitude: string = "";
    latitude: string = "";
    isConform: boolean = false;
}

export class DashBoardRankingPorUnit {
    empresaId: number = 0;
    empresaName: string = "";
    unitId: number = 0;
    unitName: string = "";
    unitSigla: string = "";
    nota: number = 0;
    isActive: boolean = false;
}

export class DashBoardNCPorUnidade {
    unitId: number = 0;
    unitName: string = "";
    unitSigla: string = "";
    maxNC: number = 0;
    minNC: number = 0;
}

export class DashBoardNCPorGrupoPergunta {
    grupoPerguntaId: number = 0;
    grupoPerguntaName: string = "";
    maxNC: number = 0;
    minNC: number = 0;
}

export class DashBoardNCPorPergunta {
    perguntaId: number = 0;
    perguntaName: string = "";
    maxNC: number = 0;
    minNC: number = 0;
}

export class DashBoardPizza {
    isConform: string = "";
    quantidade: number = 0;
};


export class Dashboard extends React.Component<RouteComponentProps<{}>, DashboardDataState> {

    constructor(props) {
        super(props);
        this.state = {
            map: undefined,
            listaDashboardMapViewModel: [],
            listaDashBoardRankingPorUnit: [],
            listaDashBoardNCPorUnidade: [],
            listaDashBoardNCPorGrupoPergunta: [],
            listaDashBoardNCPorPergunta: [],
            listaDashBoardPizza: []
        };
    }

    public montaGraficoPorGrupoPergunta() {

        Loading.showLoading(this);
        HttpService.fetch(`api/Dashboard/GetNCPorGrupoPergunta`, { method: 'POST', body: "{}" })
            .then(response => response.json() as Promise<DashBoardNCPorGrupoPergunta[]>)
            .then(data => {
                this.setState({ listaDashBoardNCPorGrupoPergunta: data });
                var listaNCMenores = data.map(obj => obj.minNC);
                var listaNCMaior = data.map(obj => obj.maxNC);
                var listaGrupoPerguntaNome = data.map(obj => obj.grupoPerguntaName);
                Highcharts.chart('graficoPorGrupo', {
                    chart: {
                        type: 'bar',
                        zoomType: 'x',
                        backgroundColor: 'transparent',
                        style: { "fontFamily": "\"Helvetica Neue\", Helvetica, Arial, sans-serif" }
                    },
                    title: {
                        text: 'Não conformidades por grupo de perguntas'
                    },
                    subtitle: {
                        text: ''
                    },
                    xAxis: {
                        categories: listaGrupoPerguntaNome,
                        title: {
                            text: null
                        }
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: 'Número de não conformidades',
                            align: 'high'
                        },
                        labels: {
                            overflow: 'justify'
                        }
                    },
                    tooltip: {
                        valueSuffix: ''
                    },
                    plotOptions: {
                        bar: {
                            dataLabels: {
                                enabled: true
                            }
                        }
                    },
                    legend: {
                        layout: 'vertical',
                        align: 'right',
                        verticalAlign: 'top',
                        x: -40,
                        y: 80,
                        floating: true,
                        borderWidth: 1,
                        backgroundColor: '#FFFFFF',
                        shadow: true
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        type: 'bar',
                        name: 'NC Menores',
                        data: listaNCMenores,
                        color: '#F26E57'
                    }, {
                        type: 'bar',
                        name: 'NC Maiores',
                        data: listaNCMaior,
                        color: '#a01700'
                    }]
                });
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            });
    }

    public montaGraficoPorPergunta() {

        Loading.showLoading(this);
        HttpService.fetch(`api/Dashboard/GetNCPorPergunta`, { method: 'POST', body: "{}" })
            .then(response => response.json() as Promise<DashBoardNCPorPergunta[]>)
            .then(data => {
                this.setState({ listaDashBoardNCPorPergunta: data });
                var listaNCMenores = data.map(obj => obj.minNC);
                var listaNCMaior = data.map(obj => obj.maxNC);
                var listaPerguntaNome = data.map(obj => obj.perguntaName);
                Highcharts.chart('graficoPorPergunta', {
                    chart: {
                        type: 'bar',
                        zoomType: 'x',
                        backgroundColor: 'transparent',
                        style: { "fontFamily": "\"Helvetica Neue\", Helvetica, Arial, sans-serif" }
                    },
                    title: {
                        text: 'Não conformidades por perguntas'
                    },
                    subtitle: {
                        text: ''
                    },
                    xAxis: {
                        categories: listaPerguntaNome,
                        title: {
                            text: null
                        }
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: 'Número de não conformidades',
                            align: 'high'
                        },
                        labels: {
                            overflow: 'justify'
                        }
                    },
                    tooltip: {
                        valueSuffix: ''
                    },
                    plotOptions: {
                        bar: {
                            dataLabels: {
                                enabled: true
                            }
                        }
                    },
                    legend: {
                        layout: 'vertical',
                        align: 'right',
                        verticalAlign: 'top',
                        x: -40,
                        y: 80,
                        floating: true,
                        borderWidth: 1,
                        backgroundColor: '#FFFFFF',
                        shadow: true
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        type: 'bar',
                        name: 'NC Menores',
                        data: listaNCMenores,
                        color: '#F26E57'
                    }, {
                        type: 'bar',
                        name: 'NC Maiores',
                        data: listaNCMaior,
                        color: '#a01700'
                    }]
                });
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            });
    }

    public montaGraficoPorUnidade() {

        Loading.showLoading(this);
        HttpService.fetch(`api/Dashboard/GetNCPorUnit`, { method: 'POST', body: "{}" })
            .then(response => response.json() as Promise<DashBoardNCPorUnidade[]>)
            .then(data => {
                this.setState({ listaDashBoardNCPorUnidade: data });
                var listaNCMenores = data.map(obj => obj.minNC);
                var listaNCMaior = data.map(obj => obj.maxNC);
                var listaUnidadeNome = data.map(obj => obj.unitName);
                var listaUnidadeSigla = data.map(obj => obj.unitName);

                Highcharts.chart('graficoPorUnidade', {
                    chart: {
                        type: 'column',
                        zoomType: 'x',
                        backgroundColor: 'transparent',
                        style: { "fontFamily": "\"Helvetica Neue\", Helvetica, Arial, sans-serif" }
                    },
                    title: {
                        text: 'Não confomidades por Unidade'
                    },
                    subtitle: {
                        text: ''
                    },
                    xAxis: {
                        categories: listaUnidadeSigla,
                        crosshair: true
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: 'Número de Não conformidades'
                        }
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:15px">{point.key}</span><table>',
                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                            '<td style="padding:0"><b>{point.y:.0f}</b></td></tr>',
                        footerFormat: '</table>',
                        shared: true,
                        useHTML: true
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0
                        }
                    },
                    legend: {
                        layout: 'vertical',
                        align: 'right',
                        verticalAlign: 'top',
                        x: -40,
                        y: 80,
                        floating: true,
                        borderWidth: 1,
                        backgroundColor: '#FFFFFF',
                        shadow: true
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        type: 'column',
                        name: 'NC Menores',
                        data: listaNCMenores,
                        color: '#F26E57'

                    }, {
                        type: 'column',
                        name: 'NC Maiores',
                        data: listaNCMaior,
                        color: '#a01700'

                    }]
                });
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            });
    }

    public montaGraficoPorEmpresa() {

        Loading.showLoading(this);
        HttpService.fetch(`api/Dashboard/GetPizza`, { method: 'POST', body: "{}" })
            .then(response => response.json() as Promise<DashBoardPizza[]>)
            .then(data => {
                this.setState({ listaDashBoardPizza: data });

                var dataSeries = data.map(obj => {
                    return ({
                        name: obj.isConform,
                        y: obj.quantidade,
                        sliced: true,
                        selected: true
                    })
                });

                /*Highcharts.chart('graficoPorEmpresa', {
                    chart: {
                        plotBackgroundColor: "",
                        plotBorderWidth: 0,
                        plotShadow: false,
                        type: 'pie',
                        backgroundColor: 'transparent',
                        style: { "fontFamily": "\"Helvetica Neue\", Helvetica, Arial, sans-serif" }
                    },
                    title: {
                        text: 'Aprovação da Empresa (JBS)'
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.y:.0f}</b>'
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true
                            },
                            //showInLegend: true
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        type: 'pie',
                        name: 'Número de unidades',
                        colorByPoint: true,
                        data: dataSeries,
                        colors: ['#23A543', '#F26E57']
                    }]
                });*/
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            });
    }

    public montaMapa() {

        Loading.showLoading(this);
        HttpService.fetch(`api/Dashboard/GetMap`, { method: 'POST', body: "{}" })
            .then(response => response.json() as Promise<DashboardMapViewModel[]>)
            .then(data => {
                this.setState({ listaDashboardMapViewModel: data });

                var showIcon = (isConform) => {
                    if (isConform) {
                        return 'http://maps.google.com/mapfiles/ms/micons/green.png';
                    }
                    return 'http://maps.google.com/mapfiles/ms/micons/red.png';
                }

                var listaPlaces = data.map(obj => [parseFloat(obj.longitude.replace(',', '.')), parseFloat(obj.latitude.replace(',', '.')), showIcon(obj.isConform)]);

                if (this.state.map != undefined) {
                    this.state.map.setSource(undefined);
                }
                var lamarin = proj.fromLonLat([-50, -15]);

                var view = new View({
                    center: lamarin,
                    zoom: 4.5
                });

                var vectorSource = new source.Vector({});

                var places = listaPlaces;

                var features = [];

                for (var i = 0; i < places.length; i++) {

                    var iconFeature = new Feature({
                        geometry: new geom.Point(proj.transform([places[i][0], places[i][1]], 'EPSG:4326', 'EPSG:3857')),
                    });

                    var iconStyle = new Style({
                        image: new Icon({
                            anchor: [0.5, 0.5],
                            anchorXUnits: 'fraction',
                            anchorYUnits: 'fraction',
                            src: places[i][2],
                            color: places[i][3],
                            crossOrigin: 'anonymous',
                        })
                    });

                    iconFeature.setStyle(iconStyle);
                    vectorSource.addFeature(iconFeature);

                }

                var vectorLayer = new layer.Vector({
                    source: vectorSource,
                    updateWhileAnimating: true,
                    updateWhileInteracting: true,
                });

                var map = new ol.Map({
                    target: 'map',
                    view: view,
                    layers: [
                        new layer.Tile({
                            preload: 3,
                            source: new source.OSM(),
                        }),
                        vectorLayer
                    ],
                    loadTilesWhileAnimating: true,
                });

                var element = document.getElementById('popup');

                var popup = new ol.Overlay({
                    element: element,
                    positioning: 'bottom-center',
                    stopEvent: false,
                    offset: [0, -50]
                });


                map.addOverlay(popup);

                // display popup on click
                map.on('click', function (evt) {
                    var feature = map.forEachFeatureAtPixel(evt.pixel,
                        function (feature) {
                            return feature;
                        });
                    if (feature) {
                        var coordinates = feature.getGeometry().getCoordinates();
                        popup.setPosition(coordinates);
                        $(element).popover({
                            placement: 'top',
                            html: true,
                            content: feature.get('name')
                        });
                        $(element).popover('show');
                    } else {
                        $(element).popover('destroy');
                    }
                });

                // change mouse cursor when over marker
                map.on('pointermove', function (e) {
                    if (e.dragging) {
                        $(element).popover('destroy');
                        return;
                    }
                    var pixel = map.getEventPixel(e.originalEvent);
                    var hit = map.hasFeatureAtPixel(pixel);
                    //map.getTarget().style.cursor = hit ? 'pointer' : '';
                });

                this.setState({ map: map });

                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            });
    }

    public montaTabela() {

        Loading.showLoading(this);
        HttpService.fetch(`api/Dashboard/GetRankingPorUnit`, { method: 'POST', body: "{}" })
            .then(response => response.json() as Promise<DashBoardRankingPorUnit[]>)
            .then(data => {
                this.setState({ listaDashBoardRankingPorUnit: data });
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            });
    }

    public componentDidMount() {
        document.body.style.backgroundColor = "#fff";

        this.montaGraficoPorGrupoPergunta();

        this.montaGraficoPorPergunta();

        this.montaGraficoPorUnidade();

        this.montaGraficoPorEmpresa();

        this.montaMapa();

        this.montaTabela();
    }

    public render() {
        // React supports function components as a simple way to write components that
        // only contain a render method without any state (the App component in this
        // example).

        return <React.Fragment>
            <div className="row p-1">
                <div className="col-sm_12 p-3" style={{ marginTop: '-30px' }}>
                    <h2>Painel de controle - Selo da Cadeia de Fornecimento</h2>
                </div>
            </div>

            <div className="row p-1">
                <div className="col-sm-6 p-3">
                    <style>
                        .map {"{"}
                        height: 415px;
                        width: 100 %;
                        {"}"}
                    </style>
                    <div id="map" className="map"><div id="popup"></div></div>
                    <div id="info" style={{ display: 'none' }}></div>
                </div>
                <div className="col-sm-6 p-3 border rounded-0" >
                    Ranking das Empresas
                <div id="dashboard" style={{ height: '380px', width: '100%', overflowY: 'auto' }}>
                        <table className="table table-hover" >
                            <thead>
                                <tr>
                                    <th className="p-0"><small>Empresa</small></th>
                                    <th className="p-0"><small>Unidade</small></th>
                                    <th className="p-0"><small>Nota</small></th>
                                    <th className="p-0"><small>Habilitado</small></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.listaDashBoardRankingPorUnit.sort(obj => obj.nota + obj.nota).map((obj, index) =>
                                    <React.Fragment key={index}>
                                        <tr>
                                            <td className="p-0"><small>{obj.empresaName}</small></td>
                                            <td className="p-0"><small>{obj.unitName}</small></td>
                                            <td className="p-0"><small>{obj.nota}</small></td>
                                            <td className="p-0"><small>{
                                                obj.isActive ?
                                                    <button className="btn btn-sm btn-block btn-success"></button> :
                                                    <button className="btn btn-sm btn-block btn-danger"></button>
                                            }
                                            </small></td>
                                        </tr>
                                    </React.Fragment>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="row p-1">
                <div className="col-sm-4 p-3">
                    <div className="border rounded" id="graficoPorEmpresa" style={{ minWidth: '310px', height: '400px', margin: '0 auto' }}></div>
                </div>
                <div className="col-sm-8 p-3">
                    <div className="border  rounded-0" id="graficoPorUnidade" style={{ minWidth: '310px', height: '400px', margin: '0 auto' }}></div>
                </div>
            </div>

            <div className="row p-1">
                <div className="col-sm-6 p-3">
                    <div className="border rounded-0" id="graficoPorGrupo" style={{ minWidth: '310px', height: '400px', margin: '0 auto' }}>
                    </div>
                </div>
                <div className="col-sm-6 p-3">
                    <div className="border rounded-0" id="graficoPorPergunta" style={{ minWidth: '310px', height: '400px', margin: '0 auto' }}>
                    </div>
                </div>
            </div>
        </React.Fragment >;
    }
}
