import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Layout } from '../Layout';
import axios from 'axios';
import { PerfilData } from '../Perfil/FetchPerfil';
import { SetorData } from '../Setores/FetchSetor';

export class Auth {
    token: string = "";
    id: number = 0;
    nome: string = "";
    empresasid: number = 0;
    acesso: any = {};
    perfil: PerfilData = new PerfilData();
    setor: SetorData = new SetorData();
    tipodeconta: number = 0;
}

export class Preferencias {
    corDoTopoDaAplicacao: string = "";
    corPrincipal: string = "";
}

export class AuthService {

    static loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getStorage('token')
        return !!token // handwaiving here
    }

    static setUsuario(dados) {
        var auth = new Auth();
        auth.token = dados['token'];
        auth.id = dados['id'];
        auth.nome = dados['nome'];
        auth.empresasid = dados['empresasid'];
        auth.acesso = dados['acesso']
        auth.perfil = dados['perfil'];
        auth.setor = dados['setores'];
        auth.tipodeconta = dados['tipodeconta'];

        var preferencias = new Preferencias();

        localStorage.setItem('auth', JSON.stringify(auth));
        localStorage.setItem('preferencias', JSON.stringify(preferencias));
    }

    logout() {
        // Clear user token and profile data from localStorage
        localStorage.removeItem('auth');
    }

    static getStorage(field: string) {
        // Retrieves the profile data from localStorage
        var json = localStorage.getItem('auth');
        return json != null && json != "null" ? JSON.parse(json)[field] : null;
    }

    static isAuthorized(perfil: string) {
        var perfilStorage = AuthService.getStorage('perfil');
        return !!perfilStorage ? (perfilStorage as PerfilData)[perfil] : false;
    }

    static contaPremium() {
        var tipoDeContaStorage = AuthService.getStorage('tipodeconta');
        return tipoDeContaStorage === 1;
    }
}
