import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { HeadLayout } from '../Shared/HeadLayout';
import { Layout } from '../Layout';
import { HttpService } from '../Shared/HttpService';
import { Loading } from '../Shared/Loading';
import $ from 'jquery';
import { Resource } from '../../index';
import { LanguageContext } from '../../context/LanguageContext';

interface PagamentoDataState {
    list: PagamentoData[];
    loading: boolean;
}

export class PagamentoData {
    id: number = 0;
    empresasid: number = 0;

    reference: string = "";
    observacao: string = "";
    url: string = "";
    transacao: string = "";
    status: number = 0;
    valor: number = 0;
    datecreated: string = "";
    situacao: string = "";
    data: string = "";
    error: Array<any> = [];
}

export class Pagamento extends React.Component<RouteComponentProps<{}>, PagamentoDataState>{
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = { list: [], loading: true };

        Loading.showLoading(this);;
        HttpService.fetch('api/PagamentosApi', {})
            .then(response => response.json() as Promise<PagamentoData[]>)
            .then(data => {
                this.setState({ list: data, loading: false });
                Loading.hideLoading(this);
            });

        this.handlePagar = this.handlePagar.bind(this);

    }

    public render() {
        let contents = Loading.isLoading(this)
            ? <p><em></em></p>
            : this.renderUserTable(this.state.list);

        return <div className="col-lg-12">
            <HeadLayout title={this.context.getLanguageResource("my_payments")} />
            <div className="card">
                <div className="card-header"><a href="/">{<Resource keyString="home" />}</a> / {<Resource keyString="my_payments" />}</div>
                <div className="card-body">
                    <div className="card-title">
                        <h3 className="text-center title-2">{<Resource keyString="my_payments" />}
                        </h3>
                    </div>
                    <hr />
                    <div className="alert alert-success row">
                        <div className="col-sm-12">
                            <strong>{<Resource keyString="perform_the_payment_of_your_license_in_the_amount_of" />} <span className="btn-warning">{<Resource keyString="us_dolar" />} XX,00</span></strong>
                            <button className="btn btn-sm btn-success pull-right" type="button" onClick={() => this.handlePagar()}>{<Resource keyString="make_payment" />}</button>
                        </div>
                    </div>
                    {contents}
                </div>
            </div>
        </div>;
    }

    // Returns the HTML table to the render() method.
    private renderUserTable(setorList: PagamentoData[]) {
        return <table className='table table-striped'>
            <thead>
                <tr>
                    <th></th>
                    <th>{<Resource keyString="reference" />}</th>
                    <th>{<Resource keyString="date" />}</th>
                    <th>{<Resource keyString="situation" />}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {setorList.map(doc =>
                    <tr key={doc.id}>
                        <td></td>
                        <td>{doc.reference}</td>
                        <td>{doc.data}</td>
                        <td>{doc.situacao}</td>
                        <td>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>;
    }

    // This will handle the submit form event.
    private handlePagar() {

        Loading.showLoading(this);;

        HttpService.fetch('api/PagamentosApi', {
            method: 'POST',
            body: null,
        }).then((response) => {
            return response.json() as Promise<PagamentoData>
        }).then((data) => {

            if ($.isEmptyObject(data.error)) {
                window.location.href = data.url;
                //this.props.history.push("/");
            } else {
                Loading.hideLoading(this);
            }
        }).catch(() => {
            Loading.hideLoading(this);
        })
    }
}   