import React, { useState, useContext } from 'react';
import CollapseDoQuestionario from '../CollapseDoQuestionario';
import GrupoDeBotoesRadio from '../GrupoDeBotoesRadio';
import { IGrupoDePerguntas } from '../../interfaces/IResponderFormularioResponse';
import CampoDeObservacao from '../CampoDeObservacao';
import CampoDeAnotacao from '../CampoDeAnotacao';
import CampoDeAnexoDeEvidencia from '../CampoDeAnexoDeEvidencia';
import CampoDePreenchimentoDeAcao from '../CampoDePreenchimentoDeAcao';
import CampoDeAnexoDeImagens from '../CampoDeAnexoDeImagens';
import { Tooltip } from 'react-tippy';
import { LanguageContext } from '../../../../../context/LanguageContext';

interface IProps {
    grupoDePerguntas: IGrupoDePerguntas[];
    chaveDoObjeto: string;
    permitirEditarRespostas: boolean;
    permitirEditarAcoes: boolean;
    permitirCompartilhar: boolean;
}

enum CampoDePreenchimento {
    Observacao,
    Anotacao,
    Acoes,
}

enum CampoDeAnexo {
    Imagem,
    Evidencia,
}

const QuestionarioRecursivo = ({ grupoDePerguntas, chaveDoObjeto, permitirEditarRespostas, permitirEditarAcoes, permitirCompartilhar }: IProps): any => {
    const grupoDePerguntasTemConteudo = grupoDePerguntas && grupoDePerguntas.length > 0;
    const { getLanguageResource } = useContext(LanguageContext)
    const [campoDePreenchimentoAtivo, setCampoDePreenchimentoAtivo] = useState<CampoDePreenchimento | null>(null);
    const [campoDeAnexoAtivo, setCampoDeAnexoAtivo] = useState<CampoDeAnexo | null>(null);

    const handleClickTabs = (event: React.MouseEvent<HTMLAnchorElement>): void => {
        event.preventDefault();
        const textoDoLink = event.currentTarget.textContent;

        if (textoDoLink === getLanguageResource('observation') && campoDePreenchimentoAtivo === CampoDePreenchimento.Observacao) {
            setCampoDePreenchimentoAtivo(null);
            return;
        } else if (textoDoLink === getLanguageResource('annotation') && campoDePreenchimentoAtivo === CampoDePreenchimento.Anotacao) {
            setCampoDePreenchimentoAtivo(null);
            return;
        } else if (textoDoLink === getLanguageResource('actions') && campoDePreenchimentoAtivo === CampoDePreenchimento.Acoes) {
            setCampoDePreenchimentoAtivo(null);
            return;
        }

        if (textoDoLink === getLanguageResource('observation')) {
            setCampoDePreenchimentoAtivo(CampoDePreenchimento.Observacao);
        } else if (textoDoLink === getLanguageResource('annotation')) {
            setCampoDePreenchimentoAtivo(CampoDePreenchimento.Anotacao);
        } else if (textoDoLink === getLanguageResource('actions')) {
            setCampoDePreenchimentoAtivo(CampoDePreenchimento.Acoes);
        }
    }

    const handleAttachmentFieldsButtons = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        const iconeClicado = event.currentTarget.querySelector('i')?.classList[1];

        if (iconeClicado === 'bi-paperclip' && campoDeAnexoAtivo === CampoDeAnexo.Evidencia) {
            setCampoDeAnexoAtivo(null);
            return;
        } else if (iconeClicado === 'bi-camera' && campoDeAnexoAtivo === CampoDeAnexo.Imagem) {
            setCampoDeAnexoAtivo(null);
            return;
        }

        if (iconeClicado === 'bi-paperclip') {
            setCampoDeAnexoAtivo(CampoDeAnexo.Evidencia);
        } else if (iconeClicado === 'bi-camera') {
            setCampoDeAnexoAtivo(CampoDeAnexo.Imagem);
        }
    }

    if (grupoDePerguntasTemConteudo) {
        
        return grupoDePerguntas.map((item) => {
            return (
                <CollapseDoQuestionario
                    titulo={item.nome}
                    key={item.id}
                    id={`grupo-de-perguntas-${item.id}`}
                >
                    <div className='ml-0 ml-md-3'>
                        {
                            item.perguntas.map(pergunta => (
                                <div className='mb-3 px-3 pt-1 rounded border'>

                                    <div className='border-bottom d-flex flex-column my-3 px-1'>
                                        <div className='d-flex flex-row align-items-start'>
                                            <h5>{pergunta.tituloDaPergunta}</h5>
                                            {
                                                pergunta.textoDeAjuda && pergunta.textoDeAjuda.length > 0
                                                    ?   (
                                                            <Tooltip
                                                                html={
                                                                    <div className='d-flex flex-column p-1'>
                                                                        <strong className='border-bottom'>{ getLanguageResource('help') }</strong>
                                                                        <div className='mt-2' style={{ whiteSpace: 'pre-line', background: '#' }}>
                                                                            { pergunta.textoDeAjuda }
                                                                        </div>
                                                                    </div>
                                                                }
                                                                arrow
                                                                interactive
                                                                delay={500}
                                                            >
                                                                <i className='bi bi-info-circle-fill text-primary ml-2' />
                                                            </Tooltip>
                                                        )
                                                    : <React.Fragment />
                                            }

                                        </div>
                                        <div style={{ whiteSpace: 'pre-line' }}><i>{pergunta.descricao}</i></div>
                                    </div>

                                    <div>
                                        <GrupoDeBotoesRadio idDaPergunta={pergunta.id} permitirEditar={permitirEditarRespostas || permitirCompartilhar} />
                                    </div>

                                    <div className='row mt-4 px-0'>
                                        <ul className="nav nav-tabs w-100">
                                            {
                                                pergunta.permitirObservacao && (
                                                    <li className="nav-item">
                                                        <a className={`text-dark nav-link ${campoDePreenchimentoAtivo === CampoDePreenchimento.Observacao ? "active" : ""}`} href="/" onClick={handleClickTabs}>{getLanguageResource('observation')}</a>
                                                    </li>
                                                )
                                            }
                                            {
                                                pergunta.permitirAnotacao && (
                                                    <li className="nav-item">
                                                        <a className={`text-dark nav-link ${campoDePreenchimentoAtivo === CampoDePreenchimento.Anotacao ? "active" : ""}`} href="/" onClick={handleClickTabs}>{getLanguageResource('annotation')}</a>
                                                    </li>
                                                )
                                            }
                                            {
                                                pergunta.permitirPlanoDeAcao && pergunta.acoes[0].campoDasAcoes.length > 0 && (
                                                    <li className="nav-item">
                                                        <a className={`text-dark nav-link ${campoDePreenchimentoAtivo === CampoDePreenchimento.Acoes ? "active" : ""}`} href="/" onClick={handleClickTabs}>{getLanguageResource('actions')}</a>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                        {
                                            pergunta.permitirObservacao && campoDePreenchimentoAtivo === CampoDePreenchimento.Observacao
                                                ?   <CampoDeObservacao idDaPergunta={pergunta.id} observacao={pergunta.observacao} />
                                                :   <React.Fragment />
                                        }

                                        {
                                            pergunta.permitirAnotacao && campoDePreenchimentoAtivo === CampoDePreenchimento.Anotacao
                                                ?   <CampoDeAnotacao idDaPergunta={pergunta.id} anotacao={pergunta.anotacao} anotacoes={pergunta.anotacoes} />
                                                :   <React.Fragment />
                                        }

                                        {
                                            pergunta.permitirPlanoDeAcao && pergunta.acoes[0].campoDasAcoes.length > 0 && campoDePreenchimentoAtivo === CampoDePreenchimento.Acoes
                                                ?   <CampoDePreenchimentoDeAcao pergunta={pergunta} permitirEditar={permitirEditarAcoes || permitirCompartilhar} />
                                                :   <React.Fragment />
                                        }
                                    </div>
                                    <div className='row px-0 my-4'>
                                        <div className='d-flex w-100 justify-content-center'>
                                            {
                                                pergunta.permitirAnexarEvidencia && (
                                                    <button className='btn btn-primary rounded-circle m-2 d-flex align-items-center justify-content-center p-2' type='button' onClick={handleAttachmentFieldsButtons}><i style={{ fontSize: '1.5rem' }} className="bi bi-paperclip" /></button>
                                                )
                                            }
                                            {
                                                pergunta.permitirAnexarImagem && (
                                                    <button className='btn btn-primary rounded-circle m-2 d-flex align-items-center justify-content-center p-2' type='button' onClick={handleAttachmentFieldsButtons}><i style={{ fontSize: '1.5rem' }} className="bi bi-camera" /></button>
                                                )
                                            }
                                        </div>
                                        {
                                            pergunta.permitirAnexarImagem && campoDeAnexoAtivo === CampoDeAnexo.Imagem
                                                ?   <CampoDeAnexoDeImagens pergunta={pergunta} />
                                                :   <React.Fragment />
                                        }

                                        {
                                            pergunta.permitirAnexarEvidencia && campoDeAnexoAtivo === CampoDeAnexo.Evidencia
                                                ?   <CampoDeAnexoDeEvidencia idDaPergunta={pergunta.id} evidenciasAnexadas={pergunta.evidenciaAnexada} />
                                                :   <React.Fragment />
                                        }
                                    </div>
                                </div>
                            ))
                        }
                        <QuestionarioRecursivo
                            grupoDePerguntas={item[chaveDoObjeto]}
                            chaveDoObjeto={chaveDoObjeto}
                            permitirEditarRespostas={permitirEditarRespostas}
                            permitirEditarAcoes={permitirEditarAcoes}
                            permitirCompartilhar={permitirCompartilhar}
                        />
                    </div>
                </CollapseDoQuestionario>
            )
        })
    }

    return <React.Fragment />;
}

export default QuestionarioRecursivo;
