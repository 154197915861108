import React, { useState, useEffect, useContext } from 'react';
import BotaoComIconePadrao from '../BotaoComIconePadrao';
import { HttpService } from '../../../../Shared/HttpService';
import { UsuarioData } from '../../../../Usuarios/FetchUsuarios';
import { EmpresaData } from '../../../../Empresas/FetchEmpresa';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import { LanguageContext } from '../../../../../context/LanguageContext';
import { Badge } from './Badge';
import { IResponderFormulario } from '../../interfaces/IResponderFormularioResponse';
import ModalWinSet from '../../../../Shared/components/ModalWinSet';
import _ from 'lodash';

interface IError {
	Summary: string[];
}

interface ICamposDoFormulario {
	email: string;
	permitirresponder: boolean;
	visualizarresultado: boolean;
	permitiralterarnomedoformulario: boolean;
	permitireditarcabecalhos: boolean;
	permitireditarrespostas: boolean;
	permitireditaracoes: boolean;
}

interface ListaDeUsuariosEEmpresas {
	usuarios: UsuarioData | null;
	empresas: EmpresaData | null;
	formularioxempresaid: number;
	permitirresponder: boolean;
	visualizarresultado: boolean;
	permitiralterarnomedoformulario: boolean;
	permitireditarcabecalhos: boolean;
	permitireditarrespostas: boolean;
	permitireditaracoes: boolean;
	id: number;
	hash: string;
	error: IError | null;
}

interface IProps {
	nome: string;
	hash: string;
	formularioXEmpresaId: number;
	fecharModal: () => void;
	responderFormulario?: IResponderFormulario;
	responderFormularioOriginal?: IResponderFormulario;
}

const ModalDeCompartilhamento = ({ nome, hash, formularioXEmpresaId, fecharModal, responderFormulario, responderFormularioOriginal }: IProps) => {
	const { getLanguageResource } = useContext(LanguageContext);
	const [estaCarregando, setEstaCarregando] = useState(true);
	const [modalDeConfirmacaoDeSalvamentoDoFormularioEstaAberto, setModalDeConfirmacaoDeSalvamentoDoFormularioEstaAberto] = useState(false);
	const [modalDeConfirmacaoSeEmailJaExisteEstaAberto, setModalDeConfirmacaoSeEmailJaExisteEstaAberto] = useState(false);
	const [informacoesDeCompartilhamento, setInformacoesDeCompartilhamento] = useState<ListaDeUsuariosEEmpresas[]>([]);
	const [classeDeFeedbackDePreenchimento, setClasseDeFeedbackDePreenchimento] = useState('');
	const [erros, setErros] = useState<string[] | null>(null);
	const [camposDoFormulario, setCamposDoFormulario] = useState<ICamposDoFormulario>({
		email: '',
		permitirresponder: false,
		visualizarresultado: false,
		permitiralterarnomedoformulario: false,
		permitireditarcabecalhos: false,
		permitireditarrespostas: false,
		permitireditaracoes: false,
	});
	const listaDeUsuarios = informacoesDeCompartilhamento.filter(item => item.usuarios !== null);
	const listaDeEmpresas = informacoesDeCompartilhamento.filter(item => item.empresas !== null);

	const fetchListaDeCompartilhados = () => {
		setEstaCarregando(_state => true);
		HttpService.fetch('api/FormularioCompartilhadoApi/All/' + hash, {})
			.then(response => response.json() as Promise<ListaDeUsuariosEEmpresas[]>)
			.then(data => setInformacoesDeCompartilhamento(data))
			.then(() => setEstaCarregando(_state => false));
	};

	const fetchInformacoesDeCompartilhamento = () => {
		fetchListaDeCompartilhados();
		
		return () => {
			setInformacoesDeCompartilhamento([]);
			setEstaCarregando(_state => false);
		}
	}

	const atualizarInformacoesDeCompartilhamento = (id: number): void => {
		setEstaCarregando(_state => true);
		HttpService.fetch('api/FormularioCompartilhadoApi/' + id, {
			method: 'PUT',
			body: JSON.stringify({
				...informacoesDeCompartilhamento.find(item => item.id === id),
				permitirresponder: camposDoFormulario.permitirresponder,
				visualizarresultado: camposDoFormulario.visualizarresultado,
				permitiralterarnomedoformulario: camposDoFormulario.permitiralterarnomedoformulario,
				permitireditarcabecalhos: camposDoFormulario.permitireditarcabecalhos,
				permitireditarrespostas: camposDoFormulario.permitireditarrespostas,
				permitireditaracoes: camposDoFormulario.permitireditaracoes,
			}),
		})
			.then((_response) => {
				setEstaCarregando(_state => false);
				fetchListaDeCompartilhados();
			});
	};

	const oCompartilhamentoJaExiste = () => informacoesDeCompartilhamento
		.some(item => item.usuarios !== null && (item.usuarios.email === camposDoFormulario.email || item.usuarios.empresasid.toString() === camposDoFormulario.email));

	const enviarRequisicaoDeCompartilhamento = () => {
		if (!estaCarregando) setEstaCarregando(true);
		
		if (camposDoFormulario.email.length === 0) {
			setClasseDeFeedbackDePreenchimento('is-invalid');
			setEstaCarregando(false);
		}
		else {
			setClasseDeFeedbackDePreenchimento('');
	
			HttpService.fetch('api/FormularioCompartilhadoApi', {
				method: 'POST',
				body: JSON.stringify({
					hash,
					email: camposDoFormulario.email,
					formularioxempresaid: formularioXEmpresaId,
					permitirresponder: camposDoFormulario.permitirresponder,
					visualizarresultado: camposDoFormulario.visualizarresultado,
					permitiralterarnomedoformulario: camposDoFormulario.permitiralterarnomedoformulario,
					permitireditarcabecalhos: camposDoFormulario.permitireditarcabecalhos,
					permitireditarrespostas: camposDoFormulario.permitireditarrespostas,
					permitireditaracoes: camposDoFormulario.permitireditaracoes,
				}),
			})
			.then((response) => response.json() as Promise<ListaDeUsuariosEEmpresas>)
			.then((data) => {
				if (data.error) {
					const inputEmailOuID = document.getElementById('emailOuIdDaEmpresa') as HTMLInputElement;
					inputEmailOuID.classList.add('is-invalid');
					setErros(data.error.Summary)
				};
				fetchInformacoesDeCompartilhamento();
			});
		}
	}

	const verificarInformacoesDeEmailExistente = () => {
		const emailExistente = informacoesDeCompartilhamento.find(item => item.usuarios?.email === camposDoFormulario.email || item.empresas?.id.toString() === camposDoFormulario.email);
		if (emailExistente) {
			return {
				id: emailExistente.id,
				permitirresponder: emailExistente.permitirresponder,
				visualizarresultado: emailExistente.visualizarresultado,
				permitiralterarnomedoformulario: emailExistente.permitiralterarnomedoformulario,
				permitireditarcabecalhos: emailExistente.permitireditarcabecalhos,
				permitireditarrespostas: emailExistente.permitireditarrespostas,
				permitireditaracoes: emailExistente.permitireditaracoes,
			}
		}

		return {
			id: 0,
			permitirresponder: false,
			visualizarresultado: false,
			permitiralterarnomedoformulario: false,
			permitireditarcabecalhos: false,
			permitireditarrespostas: false,
			permitireditaracoes: false,
		}
	};

	const handleSave = (event) => {
		event.preventDefault();
		if (oCompartilhamentoJaExiste() && !modalDeConfirmacaoSeEmailJaExisteEstaAberto) {
			setModalDeConfirmacaoSeEmailJaExisteEstaAberto(true);
			return;
		}
		
		if (responderFormulario && responderFormularioOriginal && !_.isEqual(responderFormulario, responderFormularioOriginal)) {

			setModalDeConfirmacaoDeSalvamentoDoFormularioEstaAberto(true);
			return;
		}

		enviarRequisicaoDeCompartilhamento();
	}

	const handleRemove = (id: number, itemHash: string) => {
		if (!estaCarregando) setEstaCarregando(true);
		HttpService.fetch(`api/FormularioCompartilhadoApi/${id}/${itemHash}`, { method: 'DELETE' })
			.then(() => fetchInformacoesDeCompartilhamento());
	}

	const handleChange = (event) => {
		setClasseDeFeedbackDePreenchimento('');
		const { name, value } = event.target;
		const inputEmailOuID = document.getElementById('emailOuIdDaEmpresa') as HTMLInputElement;
		inputEmailOuID.classList.remove('is-invalid');
		setCamposDoFormulario({ ...camposDoFormulario, [name]: value });
	};

	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, checked } = event.target;
		setCamposDoFormulario({ ...camposDoFormulario, [name]: checked });
	};


	useEffect(fetchInformacoesDeCompartilhamento, []);

	useDidUpdateEffect(() => {
		setCamposDoFormulario({
			...camposDoFormulario,
			permitiralterarnomedoformulario: camposDoFormulario.permitirresponder,
			permitireditarcabecalhos: camposDoFormulario.permitirresponder,
			permitireditarrespostas: camposDoFormulario.permitirresponder,
			permitireditaracoes: camposDoFormulario.permitirresponder,
		});
	}, [camposDoFormulario.permitirresponder]);

	return (
		<>
			<form className='p-4'>
				<div className="form-group">
					<label htmlFor="emailOuIdDaEmpresa">{getLanguageResource('enter_your_user_email_or_company_id')}</label>
					<input
						type="text"
						className={`form-control ${classeDeFeedbackDePreenchimento}`}
						id="emailOuIdDaEmpresa"
						name="email"
						placeholder={getLanguageResource('email_company_id')}
						value={camposDoFormulario.email}
						onChange={handleChange}
					/>
					{
						(erros && erros.length > 0) &&
						<div className="invalid-feedback">
							{erros}
						</div>
					}
				</div>

				<div className="form-check">
					<input
						className="form-check-input"
						name='permitirresponder'
						type="checkbox"
						id="permitirresponder"
						onChange={handleCheckboxChange}
						checked={camposDoFormulario.permitirresponder}
					/>
					<label className="form-check-label" htmlFor="permitirresponder">
						{getLanguageResource('allow_reply')}
					</label>
				</div>
				<div className="form-check ml-4">
					<input
						className="form-check-input"
						name='permitiralterarnomedoformulario'
						type="checkbox"
						id="permitiralterarnomedoformulario"
						onChange={handleCheckboxChange}
						checked={camposDoFormulario.permitiralterarnomedoformulario}
						disabled={!camposDoFormulario.permitirresponder}
					/>
					<label
						className="form-check-label"
						htmlFor="permitiralterarnomedoformulario"
						style={{ textDecoration: camposDoFormulario.permitirresponder ? 'none' : 'line-through' }}
					>
						{getLanguageResource('allow_edit_form_name')}
					</label>
				</div>
				<div className="form-check ml-4">
					<input
						className="form-check-input"
						name='permitireditarcabecalhos'
						type="checkbox"
						id="permitireditarcabecalhos"
						onChange={handleCheckboxChange}
						checked={camposDoFormulario.permitireditarcabecalhos}
						disabled={!camposDoFormulario.permitirresponder}
					/>
					<label
						className="form-check-label"
						htmlFor="permitireditarcabecalhos"
						style={{ textDecoration: camposDoFormulario.permitirresponder ? 'none' : 'line-through' }}
					>
						{getLanguageResource('allow_edit_headers_and_footers')}
					</label>
				</div>
				<div className="form-check ml-4">
					<input
						className="form-check-input"
						name='permitireditarrespostas'
						type="checkbox"
						id="permitireditarrespostas"
						onChange={handleCheckboxChange}
						checked={camposDoFormulario.permitireditarrespostas}
						disabled={!camposDoFormulario.permitirresponder}
					/>
					<label
						className="form-check-label"
						htmlFor="permitireditarrespostas"
						style={{ textDecoration: camposDoFormulario.permitirresponder ? 'none' : 'line-through' }}
					>
						{getLanguageResource('allow_edit_responses')}
					</label>
				</div>
				<div className="form-check ml-4">
					<input
						className="form-check-input"
						name='permitireditaracoes'
						type="checkbox"
						id="permitireditaracoes"
						onChange={handleCheckboxChange}
						checked={camposDoFormulario.permitireditaracoes}
						disabled={!camposDoFormulario.permitirresponder}
					/>
					<label
						className="form-check-label"
						htmlFor="permitireditaracoes"
						style={{ textDecoration: camposDoFormulario.permitirresponder ? 'none' : 'line-through' }}
					>
						{getLanguageResource('allow_edit_actions')}
					</label>
				</div>
				<div className="form-check">
					<input
						className="form-check-input"
						name='visualizarresultado'
						type="checkbox"
						id="visualizarresultado"
						onChange={handleCheckboxChange}
						checked={camposDoFormulario.visualizarresultado}
					/>
					<label className="form-check-label" htmlFor="visualizarresultado">
						{getLanguageResource('allow_viewing_result')}
					</label>
				</div>

				<div className="row">
					<div className="col-md-12 pb-3 border-bottom d-flex justify-content-end">
						<BotaoComIconePadrao
							classeDeIcone='fa fa-share-alt'
							className='btn-outline-success'
							title={getLanguageResource('share')}
							type='button'
							onClick={handleSave}
						/>
					</div>
				</div>

				{
					estaCarregando
						? <div className='d-flex align-items-center justify-content-center' style={{ minHeight: '7rem' }}><div className="spinner-border text-primary" role="status" /></div>
						: (
							<div>
								<div className="my-2">
									<div>
										<h5 className="mb-2">{getLanguageResource('users_with_access')}:</h5>
										<div className="card">
											<ul className="list-group list-group-flush">
												{
													listaDeUsuarios.length === 0
														? <li className="list-group-item">{getLanguageResource('no_user_with_access')}</li>
														: listaDeUsuarios.map(item => (
															<li className='d-flex flex-row list-group-item' key={`compartilhamento-${item.id}`}>
																<div className='d-flex flex-column w-100 justify-content-between'>
																	<h5>{item.usuarios && item.usuarios.nome}</h5>
																	<div style={{ width: '20rem' }}>
																		{ item.permitirresponder && <Badge texto={getLanguageResource('allow_reply')} /> }
																		{ item.visualizarresultado && <Badge texto={getLanguageResource('allow_viewing_result')} /> }
																		{ item.permitiralterarnomedoformulario && <Badge texto={getLanguageResource('allow_edit_form_name')} /> }
																		{ item.permitireditarcabecalhos && <Badge texto={getLanguageResource('allow_edit_headers_and_footers')} /> }
																		{ item.permitireditarrespostas && <Badge texto={getLanguageResource('allow_edit_responses')} /> }
																		{ item.permitireditaracoes && <Badge texto={getLanguageResource('allow_edit_actions')} /> }
																	</div>
																</div>
																<div className='d-flex align-items-center'>
																	<button
																		className='btn btn-outline-danger ml-3'
																		style={{ width: '2.75rem', height: '2.75rem' }}
																		type='button'
																		onClick={() => handleRemove(item.id, item.hash)}
																	>
																		<i className='fa fa-trash'></i>
																	</button>
																</div>
															</li>
														))
												}
											</ul>
										</div>
									</div>
								</div>

								<div className="my-2">
									<div>
										<h5 className="mb-2">{getLanguageResource('companies_with_access')}:</h5>
										<div className="card">
											<ul className="list-group list-group-flush">
												{
													listaDeEmpresas.length === 0
														? <li className="list-group-item">{getLanguageResource('no_company_with_access')}</li>
														: listaDeEmpresas.map(item => (
															<li className='d-flex flex-row list-group-item'>
																<div className='d-flex flex-column w-100 justify-content-between'>
																	<h5>{item.empresas && item.empresas.nome}</h5>
																	<div style={{ width: '20rem' }}>
																		{ item.permitirresponder && <Badge texto={getLanguageResource('allow_reply')} /> }
																		{ item.visualizarresultado && <Badge texto={getLanguageResource('allow_viewing_result')} /> }
																		{ item.permitiralterarnomedoformulario && <Badge texto={getLanguageResource('allow_edit_form_name')} /> }
																		{ item.permitireditarcabecalhos && <Badge texto={getLanguageResource('allow_edit_headers_and_footers')} /> }
																		{ item.permitireditarrespostas && <Badge texto={getLanguageResource('allow_edit_responses')} /> }
																		{ item.permitireditaracoes && <Badge texto={getLanguageResource('allow_edit_actions')} /> }
																	</div>
																</div>
																<div className='d-flex align-items-center'>
																	<button
																		className="btn btn-outline-danger ml-3"
																		style={{ width: '2.75rem', height: '2.75rem' }}
																		type='button'
																		onClick={() => handleRemove(item.id, item.hash)}
																	>
																		<i className='fa fa-trash'></i>
																	</button>
																</div>
															</li>
														))
												}
											</ul>
										</div>
									</div>
								</div>
							</div>
						)
				}
			</form>
			{
				modalDeConfirmacaoSeEmailJaExisteEstaAberto && (
					<ModalWinSet
						fecharModal={() => setModalDeConfirmacaoSeEmailJaExisteEstaAberto(false)}
					>
						<div className='d-flex flex-column p-4'>
							<header className='text-center'>
								<p>{ getLanguageResource("sharing_already_exists_for_this_form_and_user_do_you_want_to_update_permissions") }</p>
							</header>
							<main>
								<div className='d-flex align-items-center justify-content-around'>
									<section className='d-flex flex-column border rounded p-4'>
										<h3 className='text-center'>Antes</h3>
										<div className='w-100 py-1'>
											<span
												className={`badge p-2 ${verificarInformacoesDeEmailExistente().permitirresponder ? '' : 'text-muted'}`}
												style={{ backgroundColor: verificarInformacoesDeEmailExistente().permitirresponder ? 'var(--ws-blue)' : '#eeeeee' }}
											>
												{ getLanguageResource('allow_reply') }
											</span>
										</div>
										<div className='w-100 py-1 pl-4'>
											<span
												className={`badge p-2 ${verificarInformacoesDeEmailExistente().permitiralterarnomedoformulario ? '' : 'text-muted'}`}
												style={{ backgroundColor: verificarInformacoesDeEmailExistente().permitiralterarnomedoformulario ? 'var(--ws-blue)' : '#eeeeee' }}
											>
												{ getLanguageResource('allow_edit_form_name') }
											</span>
										</div>
										<div className='w-100 py-1 pl-4'>
											<span
												className={`badge p-2 ${verificarInformacoesDeEmailExistente().permitireditarcabecalhos ? '' : 'text-muted'}`}
												style={{ backgroundColor: verificarInformacoesDeEmailExistente().permitireditarcabecalhos ? 'var(--ws-blue)' : '#eeeeee' }}
											>
												{ getLanguageResource('allow_edit_headers_and_footers') }
											</span>
										</div>
										<div className='w-100 py-1 pl-4'>
											<span
												className={`badge p-2 ${verificarInformacoesDeEmailExistente().permitireditarrespostas ? '' : 'text-muted'}`}
												style={{ backgroundColor: verificarInformacoesDeEmailExistente().permitireditarrespostas ? 'var(--ws-blue)' : '#eeeeee' }}
											>
												{ getLanguageResource('allow_edit_responses') }
											</span>
										</div>
										<div className='w-100 py-1 pl-4'>
											<span
												className={`badge p-2 ${verificarInformacoesDeEmailExistente().permitireditaracoes ? '' : 'text-muted'}`}
												style={{ backgroundColor: verificarInformacoesDeEmailExistente().permitireditaracoes ? 'var(--ws-blue)' : '#eeeeee' }}
											>
												{ getLanguageResource("allow_edit_actions") }
											</span>
										</div>
										<div className='w-100 py-1'>
											<span
												className={`badge p-2 ${verificarInformacoesDeEmailExistente().visualizarresultado ? '' : 'text-muted'}`}
												style={{ backgroundColor: verificarInformacoesDeEmailExistente().visualizarresultado ? 'var(--ws-blue)' : '#eeeeee' }}
											>
												{ getLanguageResource('allow_viewing_result') }
											</span>
										</div>
									</section>
									<i className="bi bi-arrow-right mx-2" style={{ fontSize: '2rem' }} />
									<section className='d-flex flex-column border rounded p-4'>
										<h3 className='text-center'>Depois</h3>
										<div className='w-100 py-1'>
											<span
												className={`badge p-2 ${camposDoFormulario.permitirresponder ? '' : 'text-muted'}`}
												style={{ backgroundColor: camposDoFormulario.permitirresponder ? 'var(--ws-blue)' : '#eeeeee' }}
											>
												{ getLanguageResource('allow_reply') }
											</span>
										</div>
										<div className='w-100 py-1 pl-4'>
											<span
												className={`badge p-2 ${camposDoFormulario.permitiralterarnomedoformulario ? '' : 'text-muted'}`}
												style={{ backgroundColor: camposDoFormulario.permitiralterarnomedoformulario ? 'var(--ws-blue)' : '#eeeeee' }}
											>
												{ getLanguageResource('allow_edit_form_name') }
											</span>
										</div>
										<div className='w-100 py-1 pl-4'>
											<span
												className={`badge p-2 ${camposDoFormulario.permitireditarcabecalhos ? '' : 'text-muted'}`}
												style={{ backgroundColor: camposDoFormulario.permitireditarcabecalhos ? 'var(--ws-blue)' : '#eeeeee' }}
											>
												{ getLanguageResource('allow_edit_headers_and_footers') }
											</span>
										</div>
										<div className='w-100 py-1 pl-4'>
											<span
												className={`badge p-2 ${camposDoFormulario.permitireditarrespostas ? '' : 'text-muted'}`}
												style={{ backgroundColor: camposDoFormulario.permitireditarrespostas ? 'var(--ws-blue)' : '#eeeeee' }}
											>
												{ getLanguageResource('allow_edit_responses') }
											</span>
										</div>
										<div className='w-100 py-1 pl-4'>
											<span
												className={`badge p-2 ${camposDoFormulario.permitireditaracoes ? '' : 'text-muted'}`}
												style={{ backgroundColor: camposDoFormulario.permitireditaracoes ? 'var(--ws-blue)' : '#eeeeee' }}
											>
												{ getLanguageResource("allow_edit_actions") }
											</span>
										</div>
										<div className='w-100 py-1'>
											<span
												className={`badge p-2 ${camposDoFormulario.visualizarresultado ? '' : 'text-muted'}`}
												style={{ backgroundColor: camposDoFormulario.visualizarresultado ? 'var(--ws-blue)' : '#eeeeee' }}
											>
												{ getLanguageResource('allow_viewing_result') }
											</span>
										</div>
									</section>
								</div>
							</main>
							<footer className='text-center mt-4'>
								<button className='btn ws-outline-blue' onClick={() => setModalDeConfirmacaoSeEmailJaExisteEstaAberto(false)}>
									{ getLanguageResource("cancel") }
								</button>

								<button className='btn ws-outline-blue ml-3' onClick={() => {
									atualizarInformacoesDeCompartilhamento(verificarInformacoesDeEmailExistente().id);
									setModalDeConfirmacaoSeEmailJaExisteEstaAberto(false);
								}}>
									{ getLanguageResource("change_permissions") }
								</button>
							</footer>
						</div>
					</ModalWinSet>
				)
			}
			{
				modalDeConfirmacaoDeSalvamentoDoFormularioEstaAberto && (
					<ModalWinSet
						fecharModal={() => setModalDeConfirmacaoDeSalvamentoDoFormularioEstaAberto(false)}
					>
						<div className='d-flex flex-column p-4'>
							<header className='text-center'>
								<i className="bi bi-exclamation-triangle-fill text-warning" style={{ fontSize: '2rem' }} />
								<h3>{ getLanguageResource("attention") }!</h3>
							</header>
							<main>
								<p>{ getLanguageResource("there_are_still_unsaved_changes_do_you_want_to_share_anyway") }</p>
							</main>
							<footer className='text-center'>
								<button className='btn ws-outline-blue' onClick={() => setModalDeConfirmacaoDeSalvamentoDoFormularioEstaAberto(false)}>
									{ getLanguageResource("no") }
								</button>

								<button className='btn ws-outline-blue ml-3' onClick={() => {
									enviarRequisicaoDeCompartilhamento();
									setModalDeConfirmacaoDeSalvamentoDoFormularioEstaAberto(false);
								}}>
									{ getLanguageResource("yes") }
								</button>
							</footer>
						</div>
					</ModalWinSet>
				)
			}
		</>
	);
};

export default ModalDeCompartilhamento;
