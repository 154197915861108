import { ValidationAlertMessage, ValidationLabelMessage } from "components/Shared/ValidationMessage";
import { LanguageContext } from "context/LanguageContext";
import { Resource } from "index";
import React, { useContext, useState, useEffect } from "react";
import LogoWinset from '../../assets/svg/logo.svg';
import EmpresasERotas from '../../assets/json/empresasERotas.json';
import { HttpService } from "components/Shared/HttpService";
import { TextoDeTermosDeAceite } from "components/Shared/components/TextoDeTermosDeAceite";
import ModalWinSet from "components/Shared/components/ModalWinSet";

const PreRegistro = () => {
    const { getLanguageResource } = useContext(LanguageContext);
    const [resetPassword, setResetPassword] = useState({ novaSenha: '', confirmarNovaSenha: '', error: null });
    const [esconderSenha, setEsconderSenha] = useState(true);
    const [esconderConfirmarSenha, setEsconderConfirmarSenha] = useState(true);
    const [estruturaDaSenha, setEstruturaDaSenha] = useState({ oitoCaracteres: false, umaLetraMaiuscula: false, umaLetraMinuscula: false, umNumero: false, umCaractereEspecial: false });
    const [imagensDinamicas, setImagensDinamicas] = useState({ logo: '' });
    const [code, setCode] = useState('');
    const [modalTermosDeAceiteAberto, setModalTermosDeAceiteAberto] = useState(false);
    const [dados, setDados] = useState<{
        nome: string,
        email: string,
        senha: string,
        confirmarSenha: string,
        documento: string,
        cliente_NomeFantasia: string,
        cliente_RazaoSocial: string,
        cliente_Documento: string,
        cliente_NomePropriedade: string,
        cliente_Telefone: string,
        cliente_Celular: string,
        cliente_Cep: string,
        cliente_Endereco: string,
        cliente_Numero: string,
        cliente_Complemento: string,
        cliente_Bairro: string,
        cliente_Cidade: string,
        cliente_Uf: string,
        cliente_Cargo: string,
        cliente_Tipoetamanho: string,
        cliente_Seguimento: string,
        cliente_Departamento: string,
        cliente_CelularConfirmado: string,
        cliente_EmailConfirmado: string,
        termosAceitos: string,
        tipoDeAuditor: string,
        usuario: string,
        error: string,
    } | null>(null);

    const aRotaEstaListadaNoJson = () => {
        // verificar o que está escrito entre http:// ou https:// e .winset.com.br
        const url = window.location.href as string;
        const regex = /https?:\/\/([^.]*)/;
        const match = url.match(regex) ? url.match(regex)[1] : null;

        // transformar o json em array
        const empresasERotas = JSON.parse(JSON.stringify(EmpresasERotas)) as Array<any>;
        if (empresasERotas.length === 0) return false;
        return empresasERotas.some(empresa => match?.includes(empresa.nome));
    };

    useEffect(() => {
        //http://aceleragora.localhost:3000/preregistro/123123123
        // pegar apenas o final da url
        const codigoToken = window.location.href.split('/').pop();
        setCode(codigoToken || '');
        HttpService.fetch(`api/Authentication/PreRegistro/${codigoToken}`, { method: 'POST', body: JSON.stringify({}) })
            .then((response) => response.json())
            .then((data) => {
                setDados(data);
            })

    }, []);

    useEffect(() => {
        const senha = resetPassword.novaSenha;
        const oitoCaracteres = senha.length >= 8;
        const umaLetraMaiuscula = /[A-Z]/.test(senha);
        const umaLetraMinuscula = /[a-z]/.test(senha);
        const umNumero = /[0-9]/.test(senha);
        const umCaractereEspecial = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(senha);
        setEstruturaDaSenha({ oitoCaracteres, umaLetraMaiuscula, umaLetraMinuscula, umNumero, umCaractereEspecial });
    }, [resetPassword.novaSenha]);


    const retornarRotasDasImagensDaEmpresa = () => {
        const outraEmpresa = aRotaEstaListadaNoJson();

        if (outraEmpresa) {
            const rotaAtual = window.location.href.match(/https?:\/\/([^.]*)/)[1];
            const empresasERotas = JSON.parse(JSON.stringify(EmpresasERotas)) as Array<any>;
            const empresa = empresasERotas.find(empresa => rotaAtual.includes(empresa.nome));
            setImagensDinamicas({ logo: empresa.logo });
        }
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        HttpService.fetch('api/Authentication/Register', {
            method: 'POST',
            body: JSON.stringify({
                ...dados,
                senha: resetPassword.novaSenha,
                confirmarSenha: resetPassword.confirmarNovaSenha,
                termosAceitos: true,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    window.location.href = '/login';
                }
            })
    }

    useEffect(() => {
        retornarRotasDasImagensDaEmpresa();
    }, []);

    return (
        <div className='d-flex flex-column p-3 align-items-center justify-content-center' style={{ minHeight: "100vh", minWidth: "100vw", background: 'var(--ws-blue-background)' }}>
            <img
                src={aRotaEstaListadaNoJson() ? `${process.env.PUBLIC_URL}${imagensDinamicas.logo}` : LogoWinset}
                alt="Logo win.set"
                style={{ width: "300px", height: "auto" }}
                className="my-3"
            />
            {
                dados === null
                    ?   <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden"></span>
                        </div>
                    :   (
                            <div
                                className='d-flex flex-column justify-content-center bg-white shadow rounded'
                                style={{ maxWidth: "1000px" }}
                            >
                                <div className='w-100' style={{ background: 'var(--ws-blue)', height: "16px" }} />
                                <main className='w-100 d-flex flex-column flex-md-row align-items-left justify-content-center px-2 py-4'>
                                    <div className="container">
                                        <h4>Confirmação de Dados</h4>
                                        <div className="row">
                                            <input type="hidden" name="Code" value={code} />
                                            <div className="col-md-6 mb-2">
                                                <div className="">
                                                    <label className="form-label mb-0 font-weight-bold">Nome do usuário:</label>
                                                    <p className="form-control-plaintext">{dados.nome}</p>
                                                    <input type="hidden" name="Nome" value={dados.nome} />
                                                </div>
                                            </div>

                                            <div className="col-md-6 mb-2">
                                                <div className="">
                                                    <label className="form-label mb-0 font-weight-bold">Endereço de email do usuário:</label>
                                                    <p className="form-control-plaintext">{dados.email}</p>
                                                    <input type="hidden" name="Email" value={dados.email} />
                                                </div>
                                            </div>

                                            <div className="col-md-6 mb-2">
                                                <div className="">
                                                    <label className="form-label mb-0 font-weight-bold">CPF do usuário:</label>
                                                    <p className="form-control-plaintext">{dados.documento || 'Não informado'}</p>
                                                    <input type="hidden" name="Documento" value={dados.documento} />
                                                </div>
                                            </div>

                                            <div className="col-md-6 mb-2">
                                                <div className="">
                                                    <label className="form-label mb-0 font-weight-bold">Nome fantasia:</label>
                                                    <p className="form-control-plaintext">{dados.cliente_NomeFantasia || 'Não informado'}</p>
                                                    <input type="hidden" name="Cliente_NomeFantasia" value={dados.cliente_NomeFantasia} />
                                                </div>
                                            </div>

                                            <div className="col-md-6 mb-2">
                                                <div className="">
                                                    <label className="form-label mb-0 font-weight-bold">Razão social:</label>
                                                    <p className="form-control-plaintext">{dados.cliente_RazaoSocial || 'Não informado'}</p>
                                                    <input type="hidden" name="Cliente_RazaoSocial" value={dados.cliente_RazaoSocial} />
                                                </div>
                                            </div>

                                            <div className="col-md-6 mb-2">
                                                <div className="">
                                                    <label className="form-label mb-0 font-weight-bold">CNPJ:</label>
                                                    <p className="form-control-plaintext">{dados.cliente_Documento || 'Não informado'}</p>
                                                    <input type="hidden" name="Cliente_Documento" value={dados.cliente_Documento} />
                                                </div>
                                            </div>

                                            <div className="col-md-6 mb-2">
                                                <div className="">
                                                    <label className="form-label mb-0 font-weight-bold">Telefone:</label>
                                                    <p className="form-control-plaintext">{dados.cliente_Telefone || 'Não informado'}</p>
                                                    <input type="hidden" name="Cliente_Telefone" value={dados.cliente_Telefone} />
                                                </div>
                                            </div>

                                            <div className="col-md-6 mb-2">
                                                <div className="">
                                                    <label className="form-label mb-0 font-weight-bold">Celular:</label>
                                                    <p className="form-control-plaintext">{dados.cliente_Celular || 'Não informado'}</p>
                                                    <input type="hidden" name="Cliente_Celular" value={dados.cliente_Celular} />
                                                </div>
                                            </div>

                                            <div className="col-md-6 mb-2">
                                                <div className="">
                                                    <label className="form-label mb-0 font-weight-bold">CEP do endereço:</label>
                                                    <p className="form-control-plaintext">{dados.cliente_Cep || 'Não informado'}</p>
                                                    <input type="hidden" name="Cliente_Cep" value={dados.cliente_Cep} />
                                                </div>
                                            </div>

                                            <div className="col-md-6 mb-2">
                                                <div className="">
                                                    <label className="form-label mb-0 font-weight-bold">Número do endereço:</label>
                                                    <p className="form-control-plaintext">{dados.cliente_Numero || 'Não informado'}</p>
                                                    <input type="hidden" name="Cliente_Numero" value={dados.cliente_Numero} />
                                                </div>
                                            </div>

                                            <div className="col-md-6 mb-2">
                                                <div className="">
                                                    <label className="form-label mb-0 font-weight-bold">Complemento do endereço:</label>
                                                    <p className="form-control-plaintext">{dados.cliente_Complemento || 'Não informado'}</p>
                                                    <input type="hidden" name="Cliente_Complemento" value={dados.cliente_Complemento} />
                                                </div>
                                            </div>

                                            <div className="col-md-6 mb-2">
                                                <div className="">
                                                    <label className="form-label mb-0 font-weight-bold">Bairro do endereço:</label>
                                                    <p className="form-control-plaintext">{dados.cliente_Bairro || 'Não informado'}</p>
                                                    <input type="hidden" name="Cliente_Bairro" value={dados.cliente_Bairro} />
                                                </div>
                                            </div>

                                            <div className="col-md-6 mb-2">
                                                <div className="">
                                                    <label className="form-label mb-0 font-weight-bold">Cidade do endereço:</label>
                                                    <p className="form-control-plaintext">{dados.cliente_Cidade || 'Não informado'}</p>
                                                    <input type="hidden" name="Cliente_Cidade" value={dados.cliente_Cidade} />
                                                </div>
                                            </div>

                                            <div className="col-md-6 mb-2">
                                                <div className="">
                                                    <label className="form-label mb-0 font-weight-bold">Estado (UF) do endereço:</label>
                                                    <p className="form-control-plaintext">{dados.cliente_Uf || 'Não informado'}</p>
                                                    <input type="hidden" name="Cliente_Uf" value={dados.cliente_Uf} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        resetPassword.error
                                            ? <ValidationAlertMessage errors={resetPassword.error} name="Summary" />
                                            : (
                                                <div className="container">
                                                    <form className="h-100" onSubmit={handleSubmit}>
                                                        <div className="d-flex flex-column">
                                                            <div>
                                                                <h4 className="">Definir senha</h4>
                                                                <div className=''>
                                                                    <label>{<Resource keyString="password" />}</label>
                                                                    <ValidationLabelMessage errors={resetPassword.error} name="Senha" >
                                                                        <input type={esconderSenha ? 'password' : 'text'} className='form-control' name="Senha" placeholder={getLanguageResource("password")} value={resetPassword.novaSenha} onChange={(e) => setResetPassword({ ...resetPassword, novaSenha: e.target.value })} />
                                                                        <div className='w-100 p-0 m-0' style={{ height: '0px' }}>
                                                                            <span className={esconderSenha ? "bi bi-eye" : "bi bi-eye-slash"} onClick={() => setEsconderSenha(!esconderSenha)} style={{ cursor: 'pointer', position: 'relative', fontSize: '1.4rem', left: 'calc(100% - 2rem)', top: '-1.85rem' }}></span>
                                                                        </div>
                                                                    </ValidationLabelMessage>
                                                                </div>
                                                                <div className='w-100 pl-1 pt-1 pb-3'>
                                                                    <p className='mb-0'>{estruturaDaSenha.oitoCaracteres ? <i className='bi bi-check-circle-fill mr-2 text-success' /> : <i className='bi bi-x-circle-fill mr-2 text-danger' />}<Resource keyString="minimum_8_characters" /></p>
                                                                    <p className='mb-0'>{estruturaDaSenha.umaLetraMaiuscula ? <i className='bi bi-check-circle-fill mr-2 text-success' /> : <i className='bi bi-x-circle-fill mr-2 text-danger' />}<Resource keyString="at_least_one_uppercase_letter" /></p>
                                                                    <p className='mb-0'>{estruturaDaSenha.umaLetraMinuscula ? <i className='bi bi-check-circle-fill mr-2 text-success' /> : <i className='bi bi-x-circle-fill mr-2 text-danger' />}<Resource keyString="at_least_one_lowercase_letter" /></p>
                                                                    <p className='mb-0'>{estruturaDaSenha.umNumero ? <i className='bi bi-check-circle-fill mr-2 text-success' /> : <i className='bi bi-x-circle-fill mr-2 text-danger' />}<Resource keyString="at_least_one_number" /></p>
                                                                    <p className='mb-0'>{estruturaDaSenha.umCaractereEspecial ? <i className='bi bi-check-circle-fill mr-2 text-success' /> : <i className='bi bi-x-circle-fill mr-2 text-danger' />}<Resource keyString="at_least_one_special_character" /></p>
                                                                </div>
                                                                <div className=''>
                                                                    <label>{<Resource keyString="confirm_password" />}</label>
                                                                    <ValidationLabelMessage errors={resetPassword.error} name="ConfirmarSenha" >
                                                                        <input type={esconderConfirmarSenha ? 'password' : 'text'} name="ConfirmarSenha" className='form-control' id="ConfirmarSenha" placeholder={getLanguageResource("confirm_password")} value={resetPassword.confirmarNovaSenha} onChange={(e) => setResetPassword({ ...resetPassword, confirmarNovaSenha: e.target.value })} />
                                                                        <div className='w-100 p-0 m-0' style={{ height: '0px' }}>
                                                                            <span
                                                                                className={esconderConfirmarSenha ? "bi bi-eye" : "bi bi-eye-slash"}
                                                                                onClick={() => setEsconderConfirmarSenha(!esconderConfirmarSenha)}
                                                                                style={{ cursor: 'pointer', position: 'relative', fontSize: '1.4rem', left: 'calc(100% - 2rem)', top: '-1.85rem' }}
                                                                            ></span>
                                                                        </div>
                                                                    </ValidationLabelMessage>
                                                                </div>
                                                                <div className='w-100' style={{ height: "2rem" }}>
                                                                    {
                                                                        resetPassword.novaSenha !== resetPassword.confirmarNovaSenha && (
                                                                            <p className='mb-0 text-danger'>As senhas devem coincidir</p>
                                                                        )
                                                                    }
                                                                </div>
                                                                <div className='form-group d-flex justify-content-center align-items-center my-3'>
                                                                    <input type="checkbox" name="TermosAceitos" id="TermosAceitos" value="true" onChange={(e) => setDados({ ...dados, termosAceitos: e.target.checked.toString() })} />
                                                                    <p className="mb-0 ml-2">
                                                                    <Resource keyString="i_have_read_and_accept_the" /> <a href='#' onClick={() => setModalTermosDeAceiteAberto(true)}> <Resource keyString="terms_of_use" /></a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <footer className='d-flex w-100 justify-content-end mt-3'>
                                                                    <button
                                                                        className="btn"
                                                                        style={{ background: 'var(--ws-blue)', color: aRotaEstaListadaNoJson() ? "white" : "black" }}
                                                                        type="submit"
                                                                        disabled={
                                                                            !estruturaDaSenha.oitoCaracteres ||
                                                                            !estruturaDaSenha.umaLetraMaiuscula ||
                                                                            !estruturaDaSenha.umaLetraMinuscula ||
                                                                            !estruturaDaSenha.umNumero ||
                                                                            !estruturaDaSenha.umCaractereEspecial ||
                                                                            resetPassword.novaSenha !== resetPassword.confirmarNovaSenha ||
                                                                            !dados.termosAceitos
                                                                        }
                                                                    >
                                                                            Finalizar cadastro
                                                                    </button>
                                                                    {
                                                                        modalTermosDeAceiteAberto && (
                                                                            <ModalWinSet
                                                                                fecharModal={() => setModalTermosDeAceiteAberto(false)}
                                                                            >
                                                                                <div className="d-flex flex-column p-4" style={{ maxWidth: '40rem', minWidth: '30rem', maxHeight: '80vh' }}>
                                                                                    <section className="m-2 p-4 border rounded barra_de_rolagem_estilizada" style={{ overflowY: 'auto' }}>
                                                                                        <TextoDeTermosDeAceite />
                                                                                    </section>
                                                                                    <section className='w-100 d-flex justify-content-center mb-2'>
                                                                                        <button className='btn ws-outline-blue' onClick={() => setModalTermosDeAceiteAberto(false)}><Resource keyString="close" /></button>
                                                                                    </section>
                                                                                </div>
                                                                            </ModalWinSet>
                                                                        )
                                                                    }
                                                                </footer>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            )
                                    }
                                </main>
                            </div>
                        )
            }
        </div>
    );
};

export default PreRegistro;
