import * as React from 'react';
import { ResponderFormularioContext } from '../../contexts/ResponderFormularioContext';
import { ICampoDeAcao } from '../../interfaces/IResponderFormularioResponse';
import { criarArrayDePonteirosDasPerguntas } from '../../utils/geradoresDeArrayDePonteiros';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import ExclamationCircleIcon from '../../icons/ExclamationCircleIcon';
import AcaoDeAlternacao from './Campos/AcaoDeAlternacao';
import AcaoDeRadio from './Campos/AcaoDeRadio';
import AcaoDeData from './Campos/AcaoDeData';
import AcaoDeTexto from './Campos/AcaoDeTexto';

const { useContext, useState } = React;

interface IProps {
    acao: ICampoDeAcao;
    idDaPergunta: number;
    checarSeTodosOsCamposForamPreenchidos: () => void;
    permitirEditar: boolean;
}

const InputDeAcao = ({ acao, idDaPergunta, checarSeTodosOsCamposForamPreenchidos, permitirEditar }: IProps) => {
    const [classeDeFeedbackDePreenchimento, setClasseDeFeedbackDePreenchimento] = useState('');
    const { responderFormulario, setResponderFormulario, ultimaTentativaDeFinalizar } = useContext(ResponderFormularioContext);

    const responderFormularioAtualizado = { ...responderFormulario };
    const ponteirosDasPerguntasDeResponderFormulario = criarArrayDePonteirosDasPerguntas(responderFormularioAtualizado);

    const pergunta = ponteirosDasPerguntasDeResponderFormulario.find((pergunta) => pergunta.id === idDaPergunta);
    if (pergunta === undefined) return <React.Fragment />;

    const ponteiroDaAcao = pergunta.acoes[0].campoDasAcoes.find((acaoDoArray) => acao.id === acaoDoArray.id);
    if (ponteiroDaAcao === undefined) return <React.Fragment />;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!permitirEditar) return;
        ponteiroDaAcao.valor = event.target.value;
        setClasseDeFeedbackDePreenchimento('');
        setResponderFormulario(responderFormularioAtualizado);
        checarSeTodosOsCamposForamPreenchidos();
    };

    const respostaSelecionada = pergunta.respostas.find((resposta) => resposta.nomeDaResposta === pergunta.respostaSelecionada);

    const algumCampoDeAcaoEstaPreenchido = pergunta.acoes[0].campoDasAcoes.some((acao) => acao.valor);
    const oPreenchimentoDesseCampoDeAcaoEObrigatorio = acao.obrigatorio && (algumCampoDeAcaoEstaPreenchido || respostaSelecionada?.planoDeAcaoObrigatoria);

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!permitirEditar) return;
        setClasseDeFeedbackDePreenchimento('');

        if(ponteiroDaAcao.opcaoMultiplaEscolhaDoCampoDasAcoes === null) return;

        ponteiroDaAcao.opcaoMultiplaEscolhaDoCampoDasAcoes.forEach((opcao) => {
            if (`opcao-${opcao.id}-da-acao-${ponteiroDaAcao.id}-da-pergunta-${idDaPergunta}` === event.target.id) {
                opcao.selecionada = true;
                ponteiroDaAcao.valor = opcao.valor;
                ponteiroDaAcao.opcaoMultiplaEscolhaId = opcao.id;
            } else {
                opcao.selecionada = false;
            }
        })

        setResponderFormulario(responderFormularioAtualizado);
    };

    const handleToggleChangeValue = (estaMarcado, valorAtivo, valorInativo) => ponteiroDaAcao.valor = estaMarcado ? valorAtivo : valorInativo;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useDidUpdateEffect(() => {
        if (!algumCampoDeAcaoEstaPreenchido) {
            setClasseDeFeedbackDePreenchimento('');
            return;
        }
    }, [algumCampoDeAcaoEstaPreenchido]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useDidUpdateEffect(() => {
        if (algumCampoDeAcaoEstaPreenchido && !ponteiroDaAcao.valor && ponteiroDaAcao.obrigatorio) {
            setClasseDeFeedbackDePreenchimento('is-invalid');
        } else if (!ponteiroDaAcao.obrigatorio) {
            setClasseDeFeedbackDePreenchimento('');
        }
    }, [ultimaTentativaDeFinalizar]);
    
    switch (ponteiroDaAcao.tipo) {
        case '4':
            return (
                <AcaoDeAlternacao
                    id={ponteiroDaAcao.id}
                    titulo={ponteiroDaAcao.titulo}
                    valor={ponteiroDaAcao.valor}
                    idDaPergunta={idDaPergunta}
                    funcaoModificadora={handleToggleChangeValue}
                    obrigatorio={oPreenchimentoDesseCampoDeAcaoEObrigatorio}
                    valorAtivo={ponteiroDaAcao.valorAtivoToggle}
                    valorInativo={ponteiroDaAcao.valorInativoToggle}
                    valorPadrao={ponteiroDaAcao.valorPadraoToggle}
                />
            );

        case '3':
            return (
                <div className='form-group ContainerDeAcao custom-control custom-radio p-0'>
                    <label>{ponteiroDaAcao.titulo}</label>
                    {
                        (oPreenchimentoDesseCampoDeAcaoEObrigatorio && !ponteiroDaAcao.valor)
                            ? <ExclamationCircleIcon fill='#ef0000' className='ml-1' />
                            : <React.Fragment />
                    }
                    {
                        ponteiroDaAcao.opcaoMultiplaEscolhaDoCampoDasAcoes && ponteiroDaAcao.opcaoMultiplaEscolhaDoCampoDasAcoes
                            .map((opcao) => (
                                <AcaoDeRadio
                                    key={`opcao-${opcao.id}-da-acao-${ponteiroDaAcao.id}-da-pergunta${idDaPergunta}`}
                                    id={opcao.id}
                                    idDaAcao={ponteiroDaAcao.id}
                                    valor={opcao.valor}
                                    selecionada={ponteiroDaAcao.opcaoMultiplaEscolhaId}
                                    funcaoModificadora={handleRadioChange}
                                    className={`custom-control-input ${classeDeFeedbackDePreenchimento}`}
                                    idDaPergunta={idDaPergunta}
                                />
                            ))
                    }
                </div>
            );

        case '2':
            return (
                <AcaoDeData
                    id={ponteiroDaAcao.id}
                    titulo={ponteiroDaAcao.titulo}
                    valor={ponteiroDaAcao.valor}
                    funcaoModificadora={handleChange}
                    className={`form-control ${classeDeFeedbackDePreenchimento}`}
                    idDaPergunta={idDaPergunta}
                    obrigatorio={oPreenchimentoDesseCampoDeAcaoEObrigatorio}
                />
            );

        default:
            return (
                <AcaoDeTexto
                    id={ponteiroDaAcao.id}
                    titulo={ponteiroDaAcao.titulo}
                    valor={ponteiroDaAcao.valor}
                    funcaoModificadora={handleChange}
                    className={`form-control ${classeDeFeedbackDePreenchimento}`}
                    idDaPergunta={idDaPergunta}
                    obrigatorio={oPreenchimentoDesseCampoDeAcaoEObrigatorio}
                />
            );
    }
};

export default InputDeAcao;
