import React, { useState } from 'react';
import { useContext } from 'react';
import { ResponderFormularioContext } from '../../contexts/ResponderFormularioContext';
import { criarArrayDePonteirosDasPerguntas } from '../../utils/geradoresDeArrayDePonteiros';
import { IAnexoDeEvidencia } from '../../interfaces/IResponderFormularioResponse';
import { HttpService } from '../../../../Shared/HttpService';
import VisualizadorDeArquivos from '../../../../Shared/components/VisualizadorDeArquivos';

interface IFile {
    url: string;
    type: string;
}

const BotaoDeArquivo = ({ evidencia, idDaPergunta }) => {
    const [file, setFile] = useState<IFile | null>(null);
    const [modalDeVisualizacaoEstaAberto, setModalDeVisualizacaoEstaAberto] = useState(false);
    const { responderFormulario, setResponderFormulario } = useContext(ResponderFormularioContext);

    const responderFormularioAtualizado = { ...responderFormulario };
    const ponteirosDasPerguntasDeResponderFormulario = criarArrayDePonteirosDasPerguntas(responderFormularioAtualizado);
    const ponteiroDaPergunta = ponteirosDasPerguntasDeResponderFormulario.find(pergunta => pergunta.id === idDaPergunta);

    const handleRemove = () => {
        const evidenciaASerRemovida = ponteiroDaPergunta?.evidenciaAnexada[ponteiroDaPergunta.evidenciaAnexada.indexOf(evidencia)];
        if (evidenciaASerRemovida) {
            evidenciaASerRemovida.status = 'Removido';
        }
        setResponderFormulario(responderFormularioAtualizado);
    }
    
    const openViewer = async (arquivo: IAnexoDeEvidencia) => {
        if (!arquivo) return;
        setModalDeVisualizacaoEstaAberto(true);
        const urlRequest = `api/ArquivosApi/Visualizar/${arquivo.arquivoId}/${arquivo.empresaId}`;
        
        try {
            const response = await HttpService.fetch(urlRequest, { method: 'GET' });
            if (!response.ok) {
                throw new Error("Erro ao fazer download do arquivo");
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            setFile({ url, type: blob.type });
        } catch (error) {
            console.log(error);
        }
    }
    
    const handleDownload = (arquivo: IAnexoDeEvidencia) => {
        const urlRequest = `${window.location.protocol}//${process.env.REACT_APP_HOST_API}/api/ArquivosApi/Download/${arquivo.arquivoId}/${arquivo.empresaId}`;
        if (arquivo != null) {
            window.open(urlRequest, '_blank');
        }
    }

    return (
        <React.Fragment>
            <div className="btn-group mb-2 w-100" role="group" aria-label="Basic mixed styles example">
                <button type="button" className="btn btn-success w-75" onClick={() => openViewer(evidencia)}>
                    <i className="fa fa-file-o mr-2" />
                    {evidencia.nome}
                </button>
                <button type="button" className="btn btn-success active w-25">
                    <span>Versão: {evidencia.versao}</span>
                </button>
                <button type="button" className="btn btn-danger" onClick={handleRemove}>
                    <i className="fa fa-trash-o" aria-hidden="true" />
                </button>
            </div>
            {
                modalDeVisualizacaoEstaAberto && (
                    <VisualizadorDeArquivos
                        fecharModal={() => setModalDeVisualizacaoEstaAberto(false)}
                        tipo={file !== null ? file.type.split('/')[1] : null}
                        url={file !== null ? file.url : null}
                        impedirFechamentoAoClicarFora
                        extensao={evidencia.arquivo.split('.')[evidencia.arquivo.split('.').length - 1]}
                    />
                )
            }
        </React.Fragment>
    )
}

export default BotaoDeArquivo;
