import React, { useEffect, useState, useContext } from "react";
import { HeadLayout } from "./HeadLayout";
import { HttpService } from "./HttpService";
import { Loading } from "./Loading";
import { LanguageContext } from "../../context/LanguageContext";

interface IBreadcrumbDinamico {
    tipo: 'documento' | 'diretorio';
    id: number;
}

interface IItemDeBreadcrumbPadrao {
    nome: string;
    url?: string;
}

interface IProps {
    children: React.ReactNode;
    itensParaBreadcrumb?: IItemDeBreadcrumbPadrao[];
    botoes: React.ReactNode;
    tituloDaPagina?: string;
    style?: React.CSSProperties;
    tituloDoHeader: string;
    breadcrumbDinamico?: IBreadcrumbDinamico;
}

const CaixaDeConteudo = ({ children, itensParaBreadcrumb, botoes, tituloDaPagina, style, tituloDoHeader, breadcrumbDinamico }: IProps) => {
    const { getLanguageResource } = useContext(LanguageContext);
    const [itemsDeBreadcrumbDinamico, setItemsDeBreadcrumbDinamico] = useState<any[]>([]);

    const gerarBreadcrumb = () => {
        if (!itensParaBreadcrumb && !breadcrumbDinamico) return null;

        let sufixoDoBreadcrumb: IItemDeBreadcrumbPadrao[] = [];
        const inicioEDocumentar = [{ nome: getLanguageResource('to_document'), url: '/repositorio/0' }, { nome: getLanguageResource('home'), url: '/' }];

        if (itensParaBreadcrumb) sufixoDoBreadcrumb = [...itensParaBreadcrumb];

        if (breadcrumbDinamico) return gerarBreadcrumbPorMeioDeArray([...sufixoDoBreadcrumb, ...itemsDeBreadcrumbDinamico, ...inicioEDocumentar]);
        if (itensParaBreadcrumb) return gerarBreadcrumbPorMeioDeArray(itensParaBreadcrumb);
    };

    const gerarBreadcrumbPorMeioDeArray = (itemsDoBreadcrumb) => {
        if (itemsDoBreadcrumb) {
            if (breadcrumbDinamico) itemsDoBreadcrumb.reverse();
            
            const elementosDoBreadcrumb = itemsDoBreadcrumb.map((item, index, array) => {
                if (item.url || (index !== array.length - 1)) return <a href={item.url || `/repositorio/${item.id}`} className="text-muted"><ins style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.nome}</ins></a>;
                return <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.nome}</span>;
            });

    
            return (
                <nav className="d-flex flex-wrap w-100">
                    {
                        elementosDoBreadcrumb.map((elemento, index) => {
                            return (
                                <React.Fragment key={index}>
                                    {elemento}
                                    {index < elementosDoBreadcrumb.length - 1 && <span className="mx-1">/</span>}
                                </React.Fragment>
                            );
                        })
                    }
                </nav>
            );
        }
    }

    const buscarPreferencias = () => {
        const preferencias = localStorage.getItem("preferencias");
        if (preferencias) {
            return JSON.parse(preferencias);
        }
        return {};
    }

    useEffect(() => {
        if (breadcrumbDinamico) {
            Loading.showLoading('CaixaDeConteudo');
            HttpService.fetch(`api/Breadcrumb/${breadcrumbDinamico.tipo}/${breadcrumbDinamico.id}`, 'GET')
                .then(res => res.json())
                .then(data => {
                    setItemsDeBreadcrumbDinamico(data);
                    Loading.hideLoading('CaixaDeConteudo');
                });
        }
    }, []);

    return (
        <React.Fragment>
            <HeadLayout title={ tituloDaPagina ? tituloDaPagina : tituloDoHeader } />
            <div
                className='mx-3'
                style={{ ...style }}
            >
                <div className='d-none d-md-flex align-items-center justify-content-start text-muted _IMPRIMIR_OMITIR_'>
                    <small className="w-100">{ gerarBreadcrumb() }</small>
                </div>
                <div className="shadow">
                    { /* @ts-ignore */  }
                    <header className='d-flex align-items-center justify-content-between w-100 py-2 _IMPRIMIR_OMITIR_' style={{ background: buscarPreferencias().corPrincipal, height: '3.375rem' }}>
                        <h5 className="mb-0 mx-4 w-100" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{ tituloDoHeader }</h5>
                        <section className='d-flex justify-content-end'>
                            { botoes }
                        </section>
                    </header>
                    <main className='bg-white'>
                        {children}  
                    </main>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CaixaDeConteudo;
