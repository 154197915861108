import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { Layout } from '../../Layout';
import { HttpService } from '../../Shared/HttpService';
import { HeadLayout } from '../../Shared/HeadLayout';
import { Loading } from '../../Shared/Loading';
import { ModalDelete } from '../../Shared/ModalDelete';
import { Resource } from '../../../index';
import { LanguageContext } from '../../../context/LanguageContext';
import ModalWinSet from '../../Shared/components/ModalWinSet';

interface FetchModeloCadastroColunaState {
    modeloCadastroColunaList: ModeloCadastroColunaData[];
    loading: boolean;
    modeloCadastroid: number;
    modalDeDeletarEstaAberto: boolean;
    itemSelecionadoParaDeletar: ModeloCadastroColunaData | null;
}

export class ModeloCadastroColunaData {
    id: number = 0;
    empresasid: number = 0;
    modelocadastroid: number = 0;
    modelocadastrotipocampoid: number = 0;
    nome: string = "";
    ordenacao: string = "";
    obrigatorio: boolean = true;
    isactive: boolean = true;
    datecreated: string = "";
    usercreatedid: number = 0;
    datemodified: string = "";
    usermodifiedid: number = 0;
    modeloCadastroCamposMultiplaEscolha: OpcaoCampoMultiplaEscolhaData[] = [];
    formatocamposelecionado_id: number = 0;
    error: Array<any> = [];
}

export class OpcaoCampoMultiplaEscolhaData {
    id: number = 0;
    nome: string = "";
    isactive: boolean = true;
}

export class FetchModeloCadastroColuna extends React.Component<any, FetchModeloCadastroColunaState>{
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        this.state = {
            modeloCadastroColunaList: [],
            loading: true,
            modeloCadastroid: props.modeloCadastroid,
            modalDeDeletarEstaAberto: false,
            itemSelecionadoParaDeletar: null
        };

        this.getModeloCadastroColuna();
        this.handleDelete = this.handleDelete.bind(this);
    }

    public getModeloCadastroColuna() {
        Loading.showLoading(this);
        HttpService.fetch('api/ModeloCadastroColunaApi/Listar/' + this.state.modeloCadastroid, { method: 'GET' })
            .then(response => response.json() as Promise<ModeloCadastroColunaData[]>)
            .then(data => {
                this.setState({ modeloCadastroColunaList: data, loading: false });
                Loading.hideLoading(this);
            });
    }

    private handleDelete(id: number) {
        Loading.showLoading(this);
        HttpService.fetch('api/ModeloCadastroColunaApi/' + id, {
            method: 'delete'
        }).then(data => {
            this.setState(
                {
                    modeloCadastroColunaList: this.state.modeloCadastroColunaList.filter((rec) => {
                        return (rec.id != id);
                    })
                });
            Loading.hideLoading(this);
        }).catch(() => {
            Loading.hideLoading(this);
        });
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;
        return this.renderUserTable(this.state.modeloCadastroColunaList);
    }

    private renderUserTable(modeloCadastroColunaList: ModeloCadastroColunaData[]) {
        return <table className='table'>
            <thead>
                <tr>
                    <th>{<Resource keyString="field" />}</th>
                    <th>{<Resource keyString="format" />}</th>
                    <th>{<Resource keyString="ordination" />}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {modeloCadastroColunaList.map(doc =>
                    <tr key={doc.id}>
                        <td className='align-middle'>{doc.nome}</td>
                        <td className='align-middle'>
                            {doc.modelocadastrotipocampoid == 1
                                ?   <Resource keyString="text" />
                                :   doc.modelocadastrotipocampoid == 2
                                    ?   "Numérico"
                                    :   doc.modelocadastrotipocampoid == 3
                                        ?   <Resource keyString="date" />
                                        :   doc.modelocadastrotipocampoid == 4
                                            ?   "Hora"
                                            :   doc.modelocadastrotipocampoid == 5
                                                ?   "Flag(CheckBox)"
                                                :   this.context.getLanguageResource("multiple_choice")}
                        </td>
                        <td className='align-middle'>{doc.ordenacao}</td>
                        <td className='align-middle'>
                            <div className='d-flex justify-content-end'>
                                <button
                                    className="btn btn-outline-danger mr-2"
                                    onClick={() => this.setState({ modalDeDeletarEstaAberto: true, itemSelecionadoParaDeletar: doc })}
                                    style={{ width: '2.75rem', height: '2.75rem' }}
                                >
                                    <i className='fa fa-trash'></i>
                                </button>
                                <button className="btn ws-outline-green-brown mr-2" style={{ width: '2.75rem', height: '2.75rem' }} onClick={() => window.location.href = `/modeloCadastroColuna/edit/${doc.id}`}><i className='fa fa-edit'></i></button>
                            </div>
                            {
                                (this.state.modalDeDeletarEstaAberto && this.state.itemSelecionadoParaDeletar && (this.state.itemSelecionadoParaDeletar.id === doc.id)) && (
                                    <ModalWinSet
                                        fecharModal={() => this.setState({ modalDeDeletarEstaAberto: false, itemSelecionadoParaDeletar: null })}
                                    >
                                        <div className='p-3'>
                                            <header className='d-flex w-100 mb-2 justify-content-center'>
                                                <h4>Ao excluir este campo, TODOS os dados já preenchidos serão apagados. Deseja realmente apagar o campo?</h4>
                                            </header>
                                            <hr />
                                            <main>
                                                <p>{doc.nome}</p>
                                            </main>
                                            <footer className='d-flex w-100 justify-content-end'>
                                                <button className="btn mr-2 ws-outline-blue" onClick={() => this.handleDelete(doc.id)}><Resource keyString="yes" /></button>
                                                <button className="btn btn-outline-danger" onClick={() => this.setState({ modalDeDeletarEstaAberto: false, itemSelecionadoParaDeletar: null })}><Resource keyString="no" /></button>
                                            </footer>
                                        </div>
                                    </ModalWinSet>
                                    
                                )
                            }
                        </td>
                    </tr>
                )}
            </tbody>
        </table>;
    }
}