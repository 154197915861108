import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { EmpresaData } from './FetchEmpresa';
import { HeadLayout } from '../Shared/HeadLayout';
import { Layout } from '../Layout';
import { HttpService } from '../Shared/HttpService';
import { Loading } from '../Shared/Loading';
import $ from 'jquery';
import { Resource } from '../../index';
import { LanguageContext } from '../../context/LanguageContext';

interface AddEmpresaDataState {
    title: string;
    loading: boolean;
    empData: EmpresaData;
}

export class AddEmpresa extends React.Component<RouteComponentProps<{}>, AddEmpresaDataState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        this.state = { title: "", loading: true, empData: new EmpresaData };

        var empresaid = this.props.match.params["empresaid"];

        // This will set state for Edit Empresa  
        HttpService.fetch('api/EmpresasApi/' + empresaid, { method: 'GET' })
            .then(response => response.json() as Promise<EmpresaData>)
            .then(data => {
                this.setState({ title: this.context.getLanguageResource("edit_company"), loading: false, empData: data });
            });

        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    public render() {
        let contents = Loading.isLoading(this)
            ? <p><em></em></p>
            : this.renderCreateForm();

        return <div className="col-lg-12">
            <HeadLayout title={this.state.title} />
            <div className="card">
                <div className="card-header"><a href="/">{<Resource keyString="home" />}</a> / <a href="/">{<Resource keyString="menu_register" />}</a> / <a href="/">{<Resource keyString="company" />}</a> / {this.state.title}</div>
                <div className="card-body">
                    <div className="card-title">
                        <h3 className="text-center title-2">{this.state.title}</h3>
                    </div>
                    <hr />
                    {contents}
                </div>
            </div>
        </div>;
    }

    // This will handle the submit form event.  
    private handleSave(event) {
        event.preventDefault();

        let empresaId = parseInt(this.props.match.params["empresaid"])

        // PUT request for Edit Empresa.  
        HttpService.fetch('api/EmpresasApi/' + empresaId, {
            method: 'PUT',
            body: HttpService.formToJson(event.target),
        }).then((response) => {
            return response.json() as Promise<EmpresaData>
        }).then((data) => {
            Loading.showLoading(this);;

            this.setState({ empData: data })
            if ($.isEmptyObject(data.error)) {
                this.props.history.push("/");
            }
            Loading.hideLoading(this);
        }).catch(() => {
            Loading.hideLoading(this);
        })
    }

    // This will handle Cancel button click event.  
    private handleCancel(e) {
        e.preventDefault();
        this.props.history.push("/");
    }

    // Returns the HTML Form to the render() method.  
    private renderCreateForm() {
        return (
            <form onSubmit={this.handleSave} >
                <div className="form-group row" >
                    <input type="hidden" name="userId" value={this.state.empData.id} />
                </div>
                < div className="form-group row" >
                    <div className="col-md-6" >
                        <label className=" control-label col-md-12 required" htmlFor="Nome">{<Resource keyString="name" />}</label>
                        <div className="col-md-12">
                                <input className="form-control" type="text" name="Nome" id="Nome" defaultValue={this.state.empData.nome} />
                        </div>
                    </div>
                    <div className="col-md-6" >
                        <label className="control-label col-md-12 required" htmlFor="Documento">{<Resource keyString="documents" />}</label>
                        <div className="col-md-12">
                                <input className="form-control" type="text" name="Documento" id="Documento" defaultValue={this.state.empData.documento} />
                        </div>
                    </div>
                </div>
                <div className="form-group row" >
                        <div className="col-md-6">
                        <label className="control-label col-md-12 required" htmlFor="Descricao" >{<Resource keyString="description" />}</label>
                            <div className="col-md-12">
                                <input className="form-control" type="text" name="Descricao" id="Descricao" defaultValue={this.state.empData.descricao} />
                            </div>
                        </div>
                        <div className="col-md-6">
                        <label className="control-label col-md-12 required" htmlFor="Email" >{<Resource keyString="email" />}</label>
                            <div className="col-md-12">
                                <input className="form-control" type="text" name="Email" id="Email" defaultValue={this.state.empData.email} />
                            </div>
                        </div>
                </div>
                <div className="form-group row" >
                    <div className="col-md-6">
                        <label className="control-label col-md-12" htmlFor="Nomefantasia" >{<Resource keyString="fantasy_name" />}</label>
                        <div className="col-md-12">
                            <input className="form-control" type="text" name="Nomefantasia" id="Nomefantasia" defaultValue={this.state.empData.nomefantasia} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <label className="control-label col-md-12 " htmlFor="Razaosocial" >{<Resource keyString="company_name" />}</label>
                        <div className="col-md-12">
                            <input className="form-control" type="text" name="Razaosocial" id="Razaosocial" defaultValue={this.state.empData.razaosocial} />
                        </div>
                    </div>
                </div>
                <div className="form-group row" >
                    <div className="col-md-6">
                        <label className="control-label col-md-12 " htmlFor="Telefone" >{<Resource keyString="phone" />}</label>
                        <div className="col-md-12">
                            <input className="form-control" type="text" name="Telefone" id="Telefone" defaultValue={this.state.empData.telefone} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <label className="control-label col-md-12 " htmlFor="Cep" >Cep</label>
                        <div className="col-md-12">
                            <input className="form-control" type="text" name="Cep" id="Cep" defaultValue={this.state.empData.cep} />
                        </div>
                    </div>
                </div>
                <div className="form-group row" >
                        <div className="col-md-6">
                        <label className="control-label col-md-12 " htmlFor="Endereco" >{<Resource keyString="address" />}</label>
                            <div className="col-md-12">
                                <input className="form-control" type="text" name="Endereco" id="Endereco" defaultValue={this.state.empData.endereco} />
                            </div>
                        </div>
                        <div className="col-md-6">
                        <label className="control-label col-md-12 " htmlFor="Numero" >{<Resource keyString="number" />}</label>
                            <div className="col-md-12">
                                <input className="form-control" type="text" name="Numero" id="Numero" defaultValue={this.state.empData.numero} />
                            </div>
                        </div>
                </div>
                <div className="form-group row" >
                        <div className="col-md-6">
                        <label className="control-label col-md-12 " htmlFor="Complemento" >{<Resource keyString="complement" />}</label>
                            <div className="col-md-12">
                                <input className="form-control" type="text" name="Complemento" id="Complemento" defaultValue={this.state.empData.complemento} />
                            </div>
                        </div>
                        <div className="col-md-6">
                        <label className="control-label col-md-12 " htmlFor="Bairro" >{<Resource keyString="neighborhood" />}</label>
                            <div className="col-md-12">
                                <input className="form-control" type="text" name="Bairro" id="Bairro" defaultValue={this.state.empData.bairro} />
                            </div>
                        </div> 
                </div>
                <div className="form-group row">
                        <div className="col-md-6">
                        <label className="control-label col-md-12 " htmlFor="Cidade" >{<Resource keyString="city" />}</label>
                            <div className="col-md-12">
                                <input className="form-control" type="text" name="Cidade" id="Cidade" defaultValue={this.state.empData.cidade} />
                            </div>
                        </div>
                        <div className="col-md-6">
                        <label className="control-label col-md-12 " htmlFor="UF">{<Resource keyString="federated_unit" />}</label>
                            <div className="col-md-12">
                                <input className="form-control" type="text" name="Uf" id="Uf" defaultValue={this.state.empData.uf} />
                            </div>
                        </div>
                </div>
                <div className="form-group">
                    <button type="submit" className="btn btn-default btn-primary mr-2"><i className='fa fa-save'></i> {<Resource keyString="save" />}</button>
                    <button className="btn" onClick={this.handleCancel}><i className='fa fa-arrow-left'></i> {<Resource keyString="back" />}</button>
                </div >
            </form >
        )
    }

}  