import React from "react";

const CaixaDeIniciais = ({ nome }) => {
    const iniciais = nome.split(" ")
        .map((palavra, _index, array) => {
            if (array.length > 1) {
                return palavra[0];
            } else {
                return palavra.slice(0, 2);
            }
        })
        .join("")
        .slice(0, 2)
        .toUpperCase();

    return (
        <div
            className='d-flex align-items-center justify-content-center rounded bg-white p-3 shadow font-weight-bold text-muted'
            style={{ height: "35px", width: "35px" }}
        >
            {iniciais}
        </div>
    );
};

export default CaixaDeIniciais;