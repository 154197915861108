import * as React from 'react';

const CardDeCabecalho = ({ cardId, cardName, cardValue }) => {
  return (
    <div
      key={cardId}
			style={{ backgroundColor: '#e9f4f7', flexGrow: 1, flexBasis: 'content', minWidth: 'calc(33.33% - 1rem)' }} // 1rem = m-2, se alterar m-2, alterar aqui também
			className='p-2 m-2 rounded shadow-sm d-flex flex-column justify-content-start align-items-center _IMPRIMIR_COM_BORDA_ROUNDED_'
    >
			<p className='text-muted mb-2 text-break'>{cardName}</p>
			<p className="m-0 font-weight-bold text-break">{cardValue}</p>
    </div>
  );
}

export default CardDeCabecalho;
