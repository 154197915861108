import React, { useState, useEffect, useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import { HttpService } from '../../Shared/HttpService';
import { FormularioData } from './FetchFormulario';
import { Resource } from '../../../index';
import { LanguageContext } from '../../../context/LanguageContext';
import CaixaDeConteudo from '../../Shared/CaixaDeConteudo';
import FormularioIcon from '../../../assets/icons/formulario-icon.svg';
import { Loading } from '../../Shared/Loading';

const MarketPlaceFormulario = (props: RouteComponentProps<{}>) => {
    const [formularioList, setFormularioList] = useState<FormularioData[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const { getLanguageResource } = useContext(LanguageContext);

    useEffect(() => {
        setIsLoading(true);
        Loading.showLoading('marketPlaceFormulario');
        HttpService.fetch('api/FormularioAPI/MarketPlace', {})
            .then(response => response.json() as Promise<FormularioData[]>)
            .then((data: FormularioData[]) => {
                setFormularioList(data);
                setIsLoading(false);
                Loading.hideLoading('marketPlaceFormulario');
            });
    }, []);

    const handleDetail = (id, valor, jaPossui) => {
        if (valor === 0 && !jaPossui) {
            Loading.showLoading('marketPlaceFormulario');
            HttpService.fetch('api/Pagamentos/Pagar', {
                method: 'POST',
                body: JSON.stringify({
                    CupomDeDesconto: "",
                    FormularioId: id,
                    QuantidadeDeFormularios: 1,
                })
            })
                .then(response => response.json())
                .then(data => {
                    if (data.hash !== undefined) {
                        window.location.href = `/formulario/responder/${id}/${data.hash}`;
                        Loading.hideLoading('marketPlaceFormulario');
                    } else {
                        console.error("O formulário não é gratuito e não foi possível gerar o hash.")
                        Loading.hideLoading('marketPlaceFormulario');
                    }
                });
        } else {
            props.history.push(`/marketPlace/detail/${id}`);
        }

    }

    return (
        <CaixaDeConteudo
            itensParaBreadcrumb={[
                { nome: getLanguageResource("home"), url: "/" },
                { nome: getLanguageResource("form_store") },
            ]}
            tituloDoHeader={ getLanguageResource("form_store") }
            botoes={[]}
        >
            <div className="p-4 row">
                {
                    !isLoading && (
                        <React.Fragment>
                            {formularioList.sort((a, b) => Date.parse(b.datemodified) - Date.parse(a.datemodified)).map((formulario, index) =>
                                <div
                                    className="col-md-2 d-flex flex-column btn btn-outline-light"
                                    data-placement="bottom"
                                    title={formulario.nome}
                                    key={`form${formulario.id}`}
                                    onClick={() => handleDetail(formulario.id, formulario.valor, formulario.possuiesseformulario)}
                                >
                                    <img className="mx-auto mb-1" width="64" src={FormularioIcon} alt={formulario.nome} />
                                    <div className="h-100 text-center d-flex flex-column align-items-between justify-content-between">
                                        <p
                                            className="text-center text-secondary mb-0"
                                            style={{
                                                overflowWrap: 'break-word',
                                            }}
                                        >
                                            {formulario.nome}
                                        </p>
                                        {
                                            formulario.possuiesseformulario
                                                ?   <div className="text-center badge badge-pill badge-primary">{ getLanguageResource("purchased") }</div>
                                                :   <div className={formulario.valor == 0 ? 'text-center badge badge-pill badge-success' : 'text-center badge badge-pill badge-secondary'}><i className="fa fa-shopping-cart" aria-hidden="true"></i> {formulario.valor == 0 ? <Resource keyString="free" /> : getLanguageResource("us_dolar") + formulario.valor.toFixed(2)}</div>
                                        }
                                    </div>
                                </div>
                            )}
                        </React.Fragment>
                    )
                }
            </div>
        </CaixaDeConteudo>
    );
}

export default MarketPlaceFormulario;