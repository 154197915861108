import * as React from 'react';
import { ResponderFormularioContext } from '../../contexts/ResponderFormularioContext';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';

const { useState, useContext } = React;

const buscarPreferencias = () => {
  const preferencias = localStorage.getItem("preferencias");
  if (preferencias) {
      return JSON.parse(preferencias);
  }
  return {};
}

const style: React.CSSProperties = {
  cursor: 'pointer',
  // @ts-ignore
  backgroundColor: buscarPreferencias().corPrincipal,

}

interface IProps {
  titulo: string;
  children: React.ReactNode;
  id: string;
}

const CollapseDoQuestionario = ({ children, titulo, id }: IProps) => {
  const { mostrarTodosOsCollapses, idsDosCollapsesQueVaoSerAbertos } = useContext(ResponderFormularioContext);
  const [isOpen, setIsOpen] = useState(false);

  useDidUpdateEffect(() => setIsOpen(mostrarTodosOsCollapses), [mostrarTodosOsCollapses]);
  useDidUpdateEffect(() => {
    if (idsDosCollapsesQueVaoSerAbertos.some(idDoCollapse => `grupo-de-perguntas-${idDoCollapse}` === id)) {
      setIsOpen(true);
    }
  }, [idsDosCollapsesQueVaoSerAbertos]);

  return (
    <React.Fragment>
      <div
        style={ style }
        className="mb-2 rounded d-flex align-items-center justify-content-between py-2"
        onClick={ () => setIsOpen(!isOpen) }
      >
        <div className="d-flex flex-row ml-3">
          <div style={ { width: '1.1rem' } } className="d-flex align-items-center">
            <h3 className='mb-0'><i className={`mb-0 fa ${isOpen ? 'fa-caret-down' : 'fa-caret-right'}`} /></h3>
          </div>
          <h4 className="mb-0">{ titulo }</h4>
        </div>
      </div>
      <div style={ { display: `${isOpen ? '' : 'none'}` } } id={id}>
        { children }
      </div>
    </React.Fragment>
  );
}

export default CollapseDoQuestionario;
