import React, { useState, useContext } from 'react';
import { HttpService } from '../../../../Shared/HttpService';
import { ResponderFormularioContext } from '../../contexts/ResponderFormularioContext';
import { LanguageContext } from '../../../../../context/LanguageContext';
import { criarArrayDePonteirosDasPerguntas } from '../../utils/geradoresDeArrayDePonteiros';
import { IAnexoDeEvidencia } from '../../interfaces/IResponderFormularioResponse';
import { Botao } from './Botao';
import { InputFile } from './InputFile';

interface IArquivo {
	id: number;
	nome: string;
	arquivo: string;
	arquivoId: number;
	perguntaId: number;
	resultadoXDocumentoId: number;
	status: string;
	versao: string;
	empresaId: number;
}

enum Pagina {
	ESCOLHER_MODO_DE_ANEXO,
	UPLOAD_DE_ARQUIVO,
	SELECIONAR_DO_BANCO_DE_DOCUMENTOS,
	FALHA_NO_UPLOAD,
}

const ModalDeAnexoDeEvidencia = ({ id, setModalState, evidenciasJaAnexadas }) => {
	const { responderFormulario, setResponderFormulario } = useContext(ResponderFormularioContext);
	const { getLanguageResource } = useContext(LanguageContext);
	const [arquivoSelecionado, setArquivoSelecionado] = useState<IAnexoDeEvidencia>();
	const [valorNaBarraDePesquisa, setValorNaBarraDePesquisa] = useState('');
	const [documentos, setDocumentos] = useState<IArquivo[]>([]);
	const [estaCarregando, setEstaCarregando] = useState(false);
	const [paginaAtual, setPaginaAtual] = useState(Pagina.ESCOLHER_MODO_DE_ANEXO);
	const [userId] = useState(JSON.parse(localStorage.getItem('auth') as string).id);
	const [arquivoSelecionadoNoModoUpload, setArquivoSelecionadoNoModoUpload] = useState<string | Blob>();
	const [nomeDoDocumento, setNomeDoDocumento] = useState('');
	const [versaoDoDocumento, setVersaoDoDocumento] = useState('');
	const [dataVencimento, setDataVencimento] = useState('');

	const fetchAnexosDeEvidencia = (termoDaPesquisa) => {
		if (termoDaPesquisa === '') {
			document.querySelector('#barraDePesquisa')?.classList.add('is-invalid');
			return;
		};

		setArquivoSelecionado(undefined);

		setEstaCarregando(_state => true);
		HttpService.fetch(`api/documentos/buscar/${termoDaPesquisa}`, {})
			.then((response) => response.json())
			.then((response) => setDocumentos(response))
			.then(() => setEstaCarregando(_state => false));
	}

	const handleClickModal = (event: any) => {
		if (event.target.id === `telaDeAnexoDeEvidencia${id}`) {
			setModalState(false);
		}
	};

	const handleChange = ({ target }: any) => {
		document.querySelector('#barraDePesquisa')?.classList.remove('is-invalid');
		setValorNaBarraDePesquisa(target.value)
	};

	const handleSelect = (event: any) => {
		event.preventDefault();
		const { target } = event;
		const idDoDocumento = Number(target.id.split('-')[1]);
		const documentoNaSelecao = documentos.find(documento => documento.id === idDoDocumento);

		const elementoSelecionado = document.getElementsByClassName('active');
		const elementos = Array.from(elementoSelecionado);
		elementos.forEach((elemento) => elemento.classList.remove('active'));
		target.classList.add('active');

		if (documentoNaSelecao) {
			setArquivoSelecionado({
				id: documentoNaSelecao.id,
				nome: documentoNaSelecao.nome,
				arquivo: documentoNaSelecao.arquivo,
				arquivoId: documentoNaSelecao.arquivoId,
				resultadoXDocumentoId: documentoNaSelecao.resultadoXDocumentoId,
				status: "Adicionado",
				versao: documentoNaSelecao.versao,
				empresaId: documentoNaSelecao.empresaId,
			});
		}
	}

	const handleConfirmar = (event: any) => {
		event.preventDefault();
		const responderFormularioAtualizado = { ...responderFormulario };
		const ponteirosDasPerguntas = criarArrayDePonteirosDasPerguntas(responderFormularioAtualizado);
		const pergunta = ponteirosDasPerguntas.find(pergunta => pergunta.id === id);
		
		if (pergunta && arquivoSelecionado) {
			pergunta.evidenciaAnexada.push(arquivoSelecionado);
		}

		setResponderFormulario(responderFormularioAtualizado);
		setModalState(false);
	}

	const handleSelectFile = (listaDeArquivos) => {
		if (!listaDeArquivos) return;
		const arquivo = listaDeArquivos[0];
		setArquivoSelecionadoNoModoUpload(arquivo);
	};

	const receberValoresDosInputs = (valores) => {
		setNomeDoDocumento(valores.nomeDoDocumento);
		setVersaoDoDocumento(valores.versaoDoDocumento);
		setDataVencimento(valores.dataVencimento);
	}

	const handleUpload = (event: any) => {
		event.preventDefault();

		const ponteirosDasPerguntas = criarArrayDePonteirosDasPerguntas(responderFormulario);
		const pergunta = ponteirosDasPerguntas.find(pergunta => pergunta.id === id);

        const data = new FormData();
		data.append('file', arquivoSelecionadoNoModoUpload ? arquivoSelecionadoNoModoUpload : '');
		data.append('userId', userId);
		data.append('nomeDoDocumento', nomeDoDocumento);
		data.append('versao', versaoDoDocumento);
		data.append('dataVencimento', dataVencimento);
		data.append('nomeDoFormulario', responderFormulario.nome);
		data.append('tituloDaPergunta', pergunta ? pergunta.tituloDaPergunta : id);

		if (!nomeDoDocumento) {
			document.querySelector('#nomeDoDocumento')?.classList.add('is-invalid');
			return;
		}

		if (!versaoDoDocumento) {
			document.querySelector('#versaoDoDocumento')?.classList.add('is-invalid');
			return;
		}

		if (!dataVencimento || new Date(dataVencimento) < new Date()) {
			document.querySelector('#dataVencimento')?.classList.add('is-invalid');
			return;
		}

        setEstaCarregando(_state => true);
        return HttpService.fetchFile(`api/ArquivosApi/UploadDireto`, data)
            .then((response) => response.data)
			.then((dataResponse) => {
				const responderFormularioAtualizado = { ...responderFormulario };
				const ponteirosDasPerguntas = criarArrayDePonteirosDasPerguntas(responderFormularioAtualizado);
				const pergunta = ponteirosDasPerguntas.find(pergunta => pergunta.id === id);
				
				if (pergunta) {
					pergunta.evidenciaAnexada.push({
						id: dataResponse.id,
						nome: dataResponse.nome,
						arquivo: dataResponse.arquivo,
						arquivoId: dataResponse.arquivoId,
						resultadoXDocumentoId: dataResponse.resultadoXDocumentoId,
						status: "Adicionado",
						versao: dataResponse.versao,
						empresaId: dataResponse.empresaId,
					});
				}

				setResponderFormulario(responderFormularioAtualizado);
				setModalState(false);
			})
			.catch(() => {
				setPaginaAtual(Pagina.FALHA_NO_UPLOAD);
				setEstaCarregando(_state => false);
            });
	};

	const handlePage = () => {
		switch (paginaAtual) {
			case Pagina.ESCOLHER_MODO_DE_ANEXO:
				return (
					<React.Fragment>
						<div className='modal-body d-flex w-100 justify-content-center'>
							<div className='w-75 d-flex justify-content-space-around'>
								<Botao
									onClick={(_e) => setPaginaAtual(Pagina.UPLOAD_DE_ARQUIVO)}
									bootstrapIcon='bi-cloud-upload'
									texto='Fazer upload'
								/>
								<Botao
									onClick={(_e) => setPaginaAtual(Pagina.SELECIONAR_DO_BANCO_DE_DOCUMENTOS)}
									bootstrapIcon='bi-archive'
									texto='Buscar no GED'
								/>
							</div>
						</div>
						<div className="modal-footer">
							<button className='btn ws-outline-blue' onClick={(e) => {e.preventDefault(); setModalState(false)}}>Cancelar</button>
						</div>
					</React.Fragment>
				);
			case Pagina.UPLOAD_DE_ARQUIVO:
				return (
					<React.Fragment>
						<div className='modal-body d-flex align-items-center w-100 flex-column'>
							<input type="hidden" name='userId' value={userId} />
							<InputFile onChange={handleSelectFile} enviarValores={receberValoresDosInputs}  />
						</div>
						<div className="modal-footer">
							<button className='btn ws-outline-blue' onClick={(e) => {e.preventDefault(); setPaginaAtual(Pagina.ESCOLHER_MODO_DE_ANEXO)}}>Voltar</button>
							<button className='btn' type='submit' style={{ background: 'var(--ws-blue)', color: 'white' }} onClick={(e) => handleUpload(e)}>Confirmar</button>
						</div>
					</React.Fragment>
				);
			case Pagina.SELECIONAR_DO_BANCO_DE_DOCUMENTOS:
				return (
					<React.Fragment>
						<div className="modal-body">
							<div className="input-group pb-3">
								<input
									type="text"
									className="form-control"
									id="barraDePesquisa"
									name="barraDePesquisa"
									placeholder={getLanguageResource('enter_your_search_term_here')}
									value={valorNaBarraDePesquisa}
									onChange={handleChange}
								/>
								<div className="input-group-append">
									<button className="btn btn-outline-primary" type="button" onClick={() => fetchAnexosDeEvidencia(valorNaBarraDePesquisa)}>
										<i className="fa fa-search" aria-hidden="true" />
									</button>
								</div>
							</div>
							{
								estaCarregando
									? 	 <div className='d-flex align-items-center justify-content-center' style={{ minHeight: '100px' }}><div className="spinner-border text-primary" role="status" /></div>
									:	(
											<div className="mt-1 border rounded p-2" style={{ overflowY: 'auto', maxHeight: '350px', minHeight: '100px' }}>
												<div className='list-group'>
													{
														documentos.length > 0
															?	documentos.map((documento) => {
																	if (evidenciasJaAnexadas.find(evidencia => evidencia.arquivoId === documento.arquivoId)) {
																		return (
																			<a href="#" id={`documento-${documento.id}`} className="list-group-item text-muted bg-light disabled" onClick={(e) => e.preventDefault()}>
																				<div className='d-flex justify-content-between'>
																					<section>
																						<i className="fa fa-file mr-3" aria-hidden="true" />
																						<s>{ documento.nome }</s>
																					</section>
																					<section>
																						<i className="fa fa-thumb-tack ml-3" aria-hidden="true" />
																					</section>
																				</div>
																			</a>
																		)
																	}
																	return (
																		<a href="#" id={`documento-${documento.id}`} className="list-group-item list-group-item-action" onClick={handleSelect}>
																			<i className="fa fa-file mr-3" aria-hidden="true" />{ documento.nome }
																		</a>
																	);
																})
															: 	<div>{getLanguageResource('search_for_a_term_to_fill_the_list')}</div>
													}
												</div>
											</div>
										)
							}
						</div>
						<div className="modal-footer">
							<button className='btn ws-outline-blue' onClick={(e) => {e.preventDefault(); setPaginaAtual(Pagina.ESCOLHER_MODO_DE_ANEXO)}}>Voltar</button>
							<button onClick={handleConfirmar} className='btn' style={{ background: 'var(--ws-blue)', color: 'white', height: '2.3rem' }} title='Confirmar' data-dismiss='modal'><i className='fa fa-check' /></button>
						</div>
					</React.Fragment>
				);
			case Pagina.FALHA_NO_UPLOAD:
				return (
					<React.Fragment>
						<div className='modal-body d-flex justify-content-center w-100'>
							<div className='d-flex flex-column align-items-center'>
								<i className='fa fa-exclamation-triangle fa-3x text-danger' />
								<h5 className='text-danger'>{getLanguageResource('error')}</h5>
								<p>{getLanguageResource('upload_error')}</p>
							</div>
						</div>
						<div className="modal-footer">
							<button className='btn ws-outline-blue' onClick={(e) => {e.preventDefault(); setPaginaAtual(Pagina.ESCOLHER_MODO_DE_ANEXO)}}>Voltar</button>
							<button className='btn' style={{ background: 'var(--ws-blue)', color: 'white' }} onClick={(e) => {e.preventDefault(); setModalState(false)}}>Confirmar</button>
						</div>
					</React.Fragment>
				);
			
			default:
				return <div></div>;
		};
	};
	
	return (
		<div
			id={ `telaDeAnexoDeEvidencia${id}` }
			onClick={handleClickModal}
			style={ {
				position: 'fixed',
				top: 0,
				left: 0,
				width: '100vw',
				height: '100vh',
				backgroundColor: 'rgba(0,0,0,0.5)',
				zIndex: 9998,
			} }
			className='d-flex align-items-center justify-content-center'
		>
			<div style={ {
				zIndex: 9999,
			} }>
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id={ `telaDeAnexoDeEvidencia${id}Label` }>
							{ getLanguageResource('search_current_files_documents') }
						</h5>
						<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setModalState(false)}>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					{
						estaCarregando
							?	<div className='d-flex align-items-center justify-content-center' style={{ minHeight: '100px' }}><div className="spinner-border text-primary" role="status" /></div>
							:	handlePage()	
					}
				</div>
			</div>
		</div>
	);
};

export default ModalDeAnexoDeEvidencia;
