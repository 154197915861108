import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { Layout } from '../Layout';
import { HttpService } from '../Shared/HttpService';
import { Loading } from '../Shared/Loading';
import { Resource } from '../../index';
import $ from 'jquery';

interface RegraNotificacaoPorEmailState {
    usuariosId: number;
    usuarioxRegraNotificacaoPorEmail: RegraNotificacaoPorEmailData;
}

export class RegraNotificacaoPorEmailData {
    id: number = 0;
    empresasid: number = 0;
    notificarPorEmailFormRespondidoFinalizado: boolean = true;
    notificarPorEmailFormCompartilhado: boolean = true;
    notificarPorEmailFormSalvo: boolean = true;
    notificarPorEmailDiretorio: boolean = true;
    notificarPorEmailSetorVinculadoDesvinculado: boolean = true;
    notificarPorEmailArquivo: boolean = true;
    error: Array<any> = [];
}

export class RegraNotificacaoPorEmail extends React.Component<any, RegraNotificacaoPorEmailState>{
    constructor(props) {
        super(props);

        this.state = {
            usuariosId: props.usuariosId,
            usuarioxRegraNotificacaoPorEmail: props.usuarioxRegraNotificacaoPorEmail
        }

        this.handleSaveNotificacao = this.handleSaveNotificacao.bind(this);
    }

    // This will handle the submit form event.  
    private handleSaveNotificacao(event) {
        event.preventDefault();

        var id = this.state.usuarioxRegraNotificacaoPorEmail.id;

        Loading.showLoading(this);
        // PUT request for Edit User.  
        if (id > 0) {
            HttpService.fetch('api/UsuariosxRegraReceberNotificacaoPorEmailApi/AlterarRegraParaReceberNotificacaoPorEmail', {
                method: 'PUT',
                body: HttpService.formToJson(event.target),
            }).then((response) => {
                return response.json() as Promise<RegraNotificacaoPorEmailData>
            }).then((data) => {
                this.setState({ usuarioxRegraNotificacaoPorEmail: data });
                if ($.isEmptyObject(this.state.usuarioxRegraNotificacaoPorEmail.error)) {
                    this.props.history.push("/minhaConta");
                }
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            })
        }
    }

    public render() {
        return (
            <section className='d-flex flex-column align-items-center h-100'>
                <header className='my-3'>
                    <h4 className="font-weight-light m-0 p-0">Notificações</h4>
                </header>
                <form className='w-100 h-100 d-flex flex-column justify-content-between align-items-between' onSubmit={this.handleSaveNotificacao}>
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" name="NotificarPorEmailFormRespondidoFinalizado" id="NotificarPorEmailFormRespondidoFinalizado" defaultChecked={this.state.usuarioxRegraNotificacaoPorEmail.notificarPorEmailFormRespondidoFinalizado} />
                        <label className="custom-control-label" htmlFor="NotificarPorEmailFormRespondidoFinalizado"> {<Resource keyString="notify_by_email_after_completing_a_form" />}</label>
                    </div>

                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" name="NotificarPorEmailFormCompartilhado" id="NotificarPorEmailFormCompartilhado" defaultChecked={this.state.usuarioxRegraNotificacaoPorEmail.notificarPorEmailFormCompartilhado} />
                        <label className="custom-control-label" htmlFor="NotificarPorEmailFormCompartilhado"> {<Resource keyString="notify_by_email_after_a_form_is_shared" />}</label>
                    </div>

                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" name="NotificarPorEmailFormSalvo" id="NotificarPorEmailFormSalvo" defaultChecked={this.state.usuarioxRegraNotificacaoPorEmail.notificarPorEmailFormSalvo} />
                        <label className="custom-control-label" htmlFor="NotificarPorEmailFormSalvo"> {<Resource keyString="notify_by_email_after_a_form_is_saved" />}</label>
                    </div>

                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" name="NotificarPorEmailDiretorio" id="NotificarPorEmailDiretorio" defaultChecked={this.state.usuarioxRegraNotificacaoPorEmail.notificarPorEmailDiretorio} />
                        <label className="custom-control-label" htmlFor="NotificarPorEmailDiretorio"> {<Resource keyString="notify_by_email_after_a_link_is_created/deleted_from_a_directory" />}</label>
                    </div>

                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" name="NotificarPorEmailArquivo" id="NotificarPorEmailArquivo" defaultChecked={this.state.usuarioxRegraNotificacaoPorEmail.notificarPorEmailArquivo} />
                        <label className="custom-control-label" htmlFor="NotificarPorEmailArquivo"> {<Resource keyString="notify_by_email_after_creating_new_files/versions" />}</label>
                    </div>
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" name="NotificarPorEmailSetorVinculadoDesvinculado" id="NotificarPorEmailSetorVinculadoDesvinculado" defaultChecked={this.state.usuarioxRegraNotificacaoPorEmail.notificarPorEmailSetorVinculadoDesvinculado} />
                        <label className="custom-control-label" htmlFor="NotificarPorEmailSetorVinculadoDesvinculado"> {<Resource keyString="notify_by_email_after_a_Link_is_created/deleted_from_a_document" />}</label>
                    </div>
                    <div className="pt-3">
                        <button type="submit" className="btn mr-2" style={{ background: 'var(--ws-blue)' }}>{<Resource keyString="save" />}</button>
                    </div>
                </form>
            </section>
        );
    }
}