import * as React from 'react';
import $ from 'jquery';
import { Resource } from '../../index';
import { HeadLayout } from '../Shared/HeadLayout';
import { RouteComponentProps } from 'react-router';
import { ValidationLabelMessage, ValidationAlertMessage } from '../Shared/ValidationMessage';
import { Loading } from '../Shared/Loading';
import { HttpService } from '../Shared/HttpService';
import { LanguageContext } from '../../context/LanguageContext';
import LogoWinset from '../../assets/svg/logo.svg';
import { ValidadorDeSenha } from '../../helpers/validadorDeSenha';
import EmpresasERotas from '../../assets/json/empresasERotas.json';

interface IEstruturaDaSenha {
    oitoCaracteres: boolean;
    umaLetraMaiuscula: boolean;
    umaLetraMinuscula: boolean;
    umNumero: boolean;
    umCaractereEspecial: boolean;
}

interface ResetPasswordDataState {
    resetPassword: IResetPasswordData;
    esconderSenha: boolean;
    senha: string;
    confirmarSenha: string;
    esconderConfirmarSenha: boolean;
    estruturaDaSenha: IEstruturaDaSenha;
    imagensDinamicas: { logo: any, icon: any, whitelogo: any };
}

interface IResetPasswordData {
    id: number;
    novaSenha: string;
    confirmarNovaSenha: string;
    hash: string;
    error: Array<any>;
}

export class ResetPassword extends React.Component<RouteComponentProps<{}>, ResetPasswordDataState>{
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        this.state = {
            resetPassword: {
                id: 0,
                novaSenha: "",
                confirmarNovaSenha: "",
                hash: "",
                error: [],
            },
            estruturaDaSenha: {
                oitoCaracteres: false,
                umaLetraMaiuscula: false,
                umaLetraMinuscula: false,
                umNumero: false,
                umCaractereEspecial: false,
            },
            senha: "",
            confirmarSenha: "",
            esconderSenha: true,
            esconderConfirmarSenha: true,
            imagensDinamicas: { logo: '', icon: '', whitelogo: '' }
        };

        const url = document.URL;
        const decode = url.substring(url.lastIndexOf("/") + 1);

        HttpService.fetch('api/Authentication/VerificaValidadeDoEmailDeRedefinirSenha/' + decode, {
            method: 'GET',
        }).then((response) => {
            return response.json() as Promise<IResetPasswordData>
        }).then((data) => {
            data.novaSenha = "";
            data.confirmarNovaSenha = "";
            this.setState({ resetPassword: data })
            this.forceUpdate();
            Loading.hideLoading(this);

        }).catch(() => {
            Loading.hideLoading(this);
        });

        this.handleSave = this.handleSave.bind(this);
    }

    private handleSave(event) {
        event.preventDefault();

        if (this.state.resetPassword.novaSenha !== this.state.resetPassword.confirmarNovaSenha) {
            return;
        }

        if (!ValidadorDeSenha.possuiTamanhoMinimo(this.state.resetPassword.novaSenha)
            || !ValidadorDeSenha.possuiLetraMaiuscula(this.state.resetPassword.novaSenha)
            || !ValidadorDeSenha.possuiLetraMinuscula(this.state.resetPassword.novaSenha)
            || !ValidadorDeSenha.possuiNumero(this.state.resetPassword.novaSenha)
            || !ValidadorDeSenha.possuiCaractereEspecial(this.state.resetPassword.novaSenha)) {
            return;
        }

        Loading.showLoading(this);
        HttpService.fetch('api/Authentication/RedefinirPassword', {
            method: 'POST',
            body: HttpService.formToJson(event.target),
        }).then((response) => {
            return response.json() as Promise<IResetPasswordData>
        }).then((data) => {

            this.setState({ resetPassword: data })
            if ($.isEmptyObject(data.error)) {
                window.location.href = "/login";
            }
            this.forceUpdate();
            Loading.hideLoading(this);

        }).catch(() => {
            Loading.hideLoading(this);
        });
    }

    private aRotaEstaListadaNoJson() {
        // verificar o que está escrito entre http:// ou https:// e .winset.com.br
        const url = window.location.href as string;
        const regex = /https?:\/\/([^.]*)/;
        const match = url.match(regex) ? url.match(regex)[1] : null;

        // transformar o json em array
        const empresasERotas = JSON.parse(JSON.stringify(EmpresasERotas)) as Array<any>;
        if (empresasERotas.length === 0) return false;
        return empresasERotas.some(empresa => match?.includes(empresa.nome));
    }

    private retornaRotasDasImagensDaEmpresa() {
        const outraEmpresa = this.aRotaEstaListadaNoJson();
        
        if (outraEmpresa) {
            const rotaAtual = window.location.href.match(/https?:\/\/([^.]*)/)[1];
            const empresasERotas = JSON.parse(JSON.stringify(EmpresasERotas)) as Array<any>;
            const empresa = empresasERotas.find(empresa => rotaAtual.includes(empresa.nome));
            this.setState({ imagensDinamicas: { logo: empresa.logo, icon: empresa.icon, whitelogo: empresa.whitelogo } });
        }
    }

    componentDidMount(): void {
        this.retornaRotasDasImagensDaEmpresa();
    }

    componentDidUpdate(_prevProps: Readonly<RouteComponentProps<{}>>, prevState: Readonly<ResetPasswordDataState>, _snapshot?: any): void {
        if (prevState.resetPassword.novaSenha !== this.state.resetPassword.novaSenha) {
            this.setState({
                estruturaDaSenha: {
                    oitoCaracteres: ValidadorDeSenha.possuiTamanhoMinimo(this.state.resetPassword.novaSenha),
                    umaLetraMaiuscula: ValidadorDeSenha.possuiLetraMaiuscula(this.state.resetPassword.novaSenha),
                    umaLetraMinuscula: ValidadorDeSenha.possuiLetraMinuscula(this.state.resetPassword.novaSenha),
                    umNumero: ValidadorDeSenha.possuiNumero(this.state.resetPassword.novaSenha),
                    umCaractereEspecial: ValidadorDeSenha.possuiCaractereEspecial(this.state.resetPassword.novaSenha),
                }
            });
        }
    }

    public render() {
        const url = document.URL;

        return (
            <div className='d-flex flex-column align-items-center justify-content-center' style={{ height: "100vh", width: "100vw", background: 'var(--ws-blue-background)' }}>
                <img
                    src={this.aRotaEstaListadaNoJson() ? `${process.env.PUBLIC_URL}${this.state.imagensDinamicas.logo}` : LogoWinset}
                    alt="Logo win.set"
                    style={{ width: "300px", height: "auto" }}
                    className="mb-5"
                />
                <div
                    className='d-flex flex-column justify-content-center bg-white shadow rounded'
                    style={{ overflow: "hidden" }}
                >
                    <div className='w-100' style={{ background: 'var(--ws-blue)', height: "16px" }} />
                    <main className='w-100 d-flex flex-column align-items-center justify-content-center p-5'>
                    {
                        this.state.resetPassword.error
                            ?   <ValidationAlertMessage errors={this.state.resetPassword.error} name="Summary" />
                            :   (
                                <div>
                                    <form onSubmit={this.handleSave}>
                                        <h4>Redefinir senha</h4>
                                        <ValidationLabelMessage errors={this.state.resetPassword.error} name="Id" >
                                            <input type="hidden" value={url} name="Hash" />
                                        </ValidationLabelMessage>
                                        <div className='p-3'>
                                            <label>{<Resource keyString="new_password" />}</label>
                                            <ValidationLabelMessage errors={this.state.resetPassword.error} name="NovaSenha" >
                                                <input type={this.state.esconderSenha ? 'password' : 'text'} className='form-control' name="NovaSenha" placeholder={this.context.getLanguageResource("new_password")} value={this.state.resetPassword.novaSenha} onChange={(e) => this.setState({ resetPassword: { ...this.state.resetPassword, novaSenha: e.target.value } })} />
                                                <div className='w-100 p-0 m-0' style={{ height: '0px' }}>
                                                    <span className={this.state.esconderSenha ? "bi bi-eye" : "bi bi-eye-slash"} onClick={() => this.setState({ esconderSenha: !this.state.esconderSenha })} style={{ cursor: 'pointer', position: 'relative', fontSize: '1.4rem', left: 'calc(100% - 2rem)', top: '-1.85rem'}}></span>
                                                </div>
                                            </ValidationLabelMessage>
                                        </div>
                                        <div className='w-100'>
                                            <p className='mb-0'>{this.state.estruturaDaSenha.oitoCaracteres ? <i className='bi bi-check-circle-fill mr-2 text-success' /> : <i className='bi bi-x-circle-fill mr-2 text-danger' />}<Resource keyString="minimum_8_characters" /></p>
                                            <p className='mb-0'>{this.state.estruturaDaSenha.umaLetraMaiuscula ? <i className='bi bi-check-circle-fill mr-2 text-success' /> : <i className='bi bi-x-circle-fill mr-2 text-danger' />}<Resource keyString="at_least_one_uppercase_letter" /></p>
                                            <p className='mb-0'>{this.state.estruturaDaSenha.umaLetraMinuscula ? <i className='bi bi-check-circle-fill mr-2 text-success' /> : <i className='bi bi-x-circle-fill mr-2 text-danger' />}<Resource keyString="at_least_one_lowercase_letter" /></p>
                                            <p className='mb-0'>{this.state.estruturaDaSenha.umNumero ? <i className='bi bi-check-circle-fill mr-2 text-success' /> : <i className='bi bi-x-circle-fill mr-2 text-danger' />}<Resource keyString="at_least_one_number" /></p>
                                            <p className='mb-0'>{this.state.estruturaDaSenha.umCaractereEspecial ? <i className='bi bi-check-circle-fill mr-2 text-success' /> : <i className='bi bi-x-circle-fill mr-2 text-danger' />}<Resource keyString="at_least_one_special_character" /></p>
                                        </div>
                                        <div className='p-3'>
                                            <label>{<Resource keyString="confirm_new_password" />}</label>
                                            <ValidationLabelMessage errors={this.state.resetPassword.error} name="ConfirmarNovaSenha" >
                                                <input type={this.state.esconderConfirmarSenha ? 'password' : 'text'} name="ConfirmarNovaSenha" className='form-control' id="ConfirmarNovaSenha" placeholder={this.context.getLanguageResource("confirm_new_password")} value={this.state.resetPassword.confirmarNovaSenha} onChange={(e) => this.setState({ resetPassword: { ...this.state.resetPassword, confirmarNovaSenha: e.target.value } })} />
                                                <div className='w-100 p-0 m-0' style={{ height: '0px' }}>
                                                    <span className={this.state.esconderConfirmarSenha ? "bi bi-eye" : "bi bi-eye-slash"} onClick={() => this.setState({ esconderConfirmarSenha: !this.state.esconderConfirmarSenha })} style={{ cursor: 'pointer', position: 'relative', fontSize: '1.4rem', left: 'calc(100% - 2rem)', top: '-1.85rem'}}></span>
                                                </div>
                                            </ValidationLabelMessage>
                                        </div>
                                        <div className='w-100' style={{ height: "2rem" }}>
                                            {
                                                this.state.resetPassword.novaSenha !== this.state.resetPassword.confirmarNovaSenha && (
                                                    <p className='mb-0 text-danger'>As senhas devem coincidir</p>
                                                )
                                            }
                                        </div>
                                        <footer className='d-flex w-100 justify-content-end mt-3'>
                                            <button className="btn" style={{ background: 'var(--ws-blue)', color: this.aRotaEstaListadaNoJson() ? "white" : "black"  }} type="submit">Redefinir Senha</button>
                                        </footer>
                                    </form>
                                </div>
                            )
                    }
                    </main>
                </div>
            </div>
        );
    }
}