import * as React from 'react';
import { Resource } from '../../../../../index';
import { ResponderFormularioContext } from '../../contexts/ResponderFormularioContext';
import MSG from './mensagens';

const { useContext } = React;

const MensagensDeAlerta = () => {
	const { listaDeMensagensDeAlerta, setListaDeMensagensDeAlerta } = useContext(ResponderFormularioContext);
	return (
		<React.Fragment>
			{
				listaDeMensagensDeAlerta.map((tipo: string) => {
					return (
						<div
							className={`alert alert-${MSG[tipo].padraoDeCorBootstrap} alert-dismissible fade show text-center m-3`}
							role="alert"
						>
							<Resource keyString={MSG[tipo].codigoParaResourceDeIdioma} />
						</div>
					)
				})
			}
		</React.Fragment>
	)
}

export default MensagensDeAlerta;
