import React, { useContext, useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { HttpService } from '../../Shared/HttpService';
import { Loading } from '../../Shared/Loading';
import { FormularioData } from './FetchFormulario';
import CaixaDeConteudo from '../../Shared/CaixaDeConteudo';
import FormularioIcon from '../../../assets/icons/formulario-icon.svg'
import { LanguageContext } from '../../../context/LanguageContext';
import ModalWinSet from 'components/Shared/components/ModalWinSet';

interface FetchFormularioState {
    formularioList: FormularioData[];
    modalDeAssociacaoEstaAberto: boolean;
    formularioSelecionadoParaAssociar: number | null;
}

export const MarketPlaceFormularioInterno: React.FC<RouteComponentProps<{}>> = (props) => {
    const [formularioList, setFormularioList] = useState<FormularioData[]>([]);
    const [modalDeAssociacaoEstaAberto, setModalDeAssociacaoEstaAberto] = useState<boolean>(false);
    const [formularioSelecionadoParaAssociar, setFormularioSelecionadoParaAssociar] = useState<number | null>(null);
    const context = useContext(LanguageContext);

    const associarFormulario = (id) => {
        Loading.showLoading("AssociarFormulario");
        HttpService.fetch('api/FormularioApi/AssociarInterno/' + id, {})
            .then(response => response.json())
            .then(data => {
                Loading.hideLoading("AssociarFormulario");
                window.location.href = '/marketPlace/detail/' + data.formularioid;
            });
    }

    useEffect(() => {
        Loading.showLoading("MarketPlaceFormularioInterno");
        HttpService.fetch('api/FormularioAPI/MarketPlaceInterno', {})
            .then(response => response.json() as Promise<FormularioData[]>)
            .then(data => {
                setFormularioList(data);
                Loading.hideLoading("MarketPlaceFormularioInterno");
            });
    }, []);

    return (
        <CaixaDeConteudo
            itensParaBreadcrumb={[
                { nome: context.getLanguageResource("home"), url: "/" },
                { nome: context.getLanguageResource("form_store") },
            ]}
            tituloDoHeader={context.getLanguageResource("form_store")}
            botoes={[]}
        >
            <div className='d-flex flex-column flex-md-row p-4'>
                {
                    formularioList.length === 0 &&
                    <p className='text-center mb-0'>{context.getLanguageResource("no_form_available")}</p>
                }
                {formularioList.sort((a, b) => Date.parse(b.datemodified) - Date.parse(a.datemodified)).map((formulario, index) =>
                    <div
                        className="col-md-2 d-flex flex-column btn btn-outline-light"
                        data-placement="bottom"
                        title={formulario.nome}
                        key={`form${formulario.id}`}
                        onClick={() => {
                            if (formulario.possuiesseformulario) {
                                window.location.href = '/marketPlace/detail/' + formulario.id;
                                return;
                            }
                            setModalDeAssociacaoEstaAberto(true);
                            setFormularioSelecionadoParaAssociar(formulario.id);
                        }}
                    >
                        <img className="mx-auto mb-1" width="64" src={FormularioIcon} alt={formulario.nome} />
                        <div className="h-100 text-center d-flex flex-column align-items-between justify-content-between">
                            <p
                                className="text-center text-secondary"
                                style={{
                                    overflowWrap: 'break-word',
                                }}
                            >
                                {formulario.nome}
                            </p>
                        </div>
                        {
                            formulario.possuiesseformulario && <p style={{ overflowWrap: 'break-word' }} className="text-center text-success">{context.getLanguageResource("associated_form")}</p>
                        }
                    </div>
                )}
            </div>
            {
                modalDeAssociacaoEstaAberto && (
                    <ModalWinSet fecharModal={() => {
                        setModalDeAssociacaoEstaAberto(false);
                        setFormularioSelecionadoParaAssociar(null);
                    }}>
                        <div className='d-flex flex-column p-4'>
                            <main className='text-center'>
                                <p>{context.getLanguageResource("form_not_enabled_yet_prompt")}</p>
                            </main>
                            <footer className='d-flex justify-content-end'>
                                <button className='btn btn-primary mr-2' onClick={() => associarFormulario(formularioSelecionadoParaAssociar)}>{context.getLanguageResource("yes")}</button>
                                <button
                                    className='btn btn-secondary'
                                    onClick={() => {
                                        setModalDeAssociacaoEstaAberto(false);
                                        setFormularioSelecionadoParaAssociar(null);
                                    }}
                                >
                                    {context.getLanguageResource("no")}
                                </button>
                            </footer>
                        </div>
                    </ModalWinSet>
                )
            }
        </CaixaDeConteudo>
    );
}