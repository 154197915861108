import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { HttpService } from '../Shared/HttpService';
import { HeadLayout } from '../Shared/HeadLayout';
import { Loading } from '../Shared/Loading';
import { ValidationLabelMessage } from '../Shared/ValidationMessage';
import { ListaSetorVinculado } from '../Setores/ListaSetorVinculado';
import { Resource } from '../../index';
import { DiretorioData } from './FetchDiretorio';
import { LanguageContext } from '../../context/LanguageContext';
import CaixaDeConteudo from '../Shared/CaixaDeConteudo';

interface DetailDiretorioDataState {
    loading: boolean;
    empData: DiretorioData;
    isVinculed: boolean;
}

export class DetailDiretorio extends React.Component<RouteComponentProps<{}>, DetailDiretorioDataState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        var diretorioid = this.props.match.params["diretorioid"];

        this.state = { loading: true, empData: new DiretorioData, isVinculed: false};

        Loading.showLoading(this);

        HttpService.fetch('api/DiretoriosApi/' + diretorioid, { method: 'GET' })
            .then(response => response.json() as Promise<DiretorioData>)
            .then(data => {
                this.setState({ loading: false, empData: data });
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
                props.history.push(`/repositorio`);
            });

        HttpService.fetch('api/DiretoriosApi/GetVinculoDiretorioxDocumento/' + diretorioid, { method: 'GET' })
            .then(response => response.json() as Promise<boolean>)
            .then(data => {
                this.setState({ isVinculed: data });
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
                props.history.push(`/repositorio`);
            });

        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;

        return (
            <CaixaDeConteudo
                botoes={[
                    <button type="submit" className="btn bg-white shadow-sm mr-2" onClick={this.handleCancel}>{this.context.getLanguageResource("back")}</button>,
                ]}
                itensParaBreadcrumb={[
                    { nome: this.context.getLanguageResource("directory_details") }
                ]}
                breadcrumbDinamico={{ id: this.state.empData.id, tipo: "diretorio" }}
                tituloDoHeader={this.context.getLanguageResource("directory_details")}
            >
                {
                    this.renderCreateForm()
                }
            </CaixaDeConteudo>
        );
    }

    // This will handle the submit form event.  
    private handleSave(event) {
        event.preventDefault();

        var diretoriospaiid = this.state.empData.diretoriospaiid > 0 ? this.state.empData.diretoriospaiid : 0;
        this.props.history.push(`/diretorio/edit/${diretoriospaiid}/${this.state.empData.id}`);
    }

    private handleDelete(event) {
        event.preventDefault();

        var confirme = window.confirm(this.context.getLanguageResource("do_you_really_want_to_delete_that_directory"));

        if (confirme == true) {
            var diretorioid = this.props.match.params["diretorioid"];

            HttpService.fetch('api/DiretoriosApi/' + diretorioid, { method: 'DELETE' })
                .then(response => response.json() as Promise<DiretorioData>)
                .then(data => {
                    this.props.history.push(`/repositorio/${diretorioid}`);
                    Loading.hideLoading(this);
                }).catch(() => {
                    Loading.hideLoading(this);
                    this.props.history.push(`/repositorio`);
                });
        }
    }

    handleRestaurar = () => {
        Loading.showLoading(this);

        var diretorioid = this.props.match.params["diretorioid"];

        HttpService.fetch('api/DiretoriosApi/Restaurar/' + diretorioid, { method: 'POST' })
            .then(response => response.json() as Promise<DiretorioData>)
            .then(data => {
                this.props.history.push(`/repositorio/${diretorioid}`);
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
                this.props.history.push(`/repositorio`);
            });
    }

    // This will handle Cancel button click event.  
    private handleCancel(e) {
        e.preventDefault();
        this.redirectPage();
    }

    private redirectPage() {
        this.props.history.push(`/repositorio/${this.state.empData.id}`);
    }

    // Returns the HTML Form to the render() method.  
    private renderCreateForm() {
        return (
            <div className='px-4'>
                <header className='py-4 w-100 d-flex justify-content-center border-bottom'>
                    <h4>{this.context.getLanguageResource("directory_details")}</h4>
                </header>
                <div className='d-flex mt-4'>
                    <div className="card-body py-0 mt-0">
                        <form onSubmit={this.handleSave} >
                            <div className='p-1 pl-2 ' style={{ backgroundColor: 'var(--ws-blue-light)' }}>
                                <label className="control-label mb-0" htmlFor="Name">{<Resource keyString="directory_name" />}</label>
                                <div>
                                    <ValidationLabelMessage errors={this.state.empData.error} name="Nome" >
                                        <strong>{this.state.empData.nome}</strong>
                                    </ValidationLabelMessage>
                                </div>
                            </div>
                            <div className='mt-2'>
                                {
                                    this.state.empData.perfil.escrita == true
                                        ?   <button type="submit" className="btn ws-outline-blue mr-2" style={{ width: '2.75rem', height: '2.75rem' }}><i className='fa fa-edit'></i></button>
                                        :   <React.Fragment />
                                }
                                {
                                    this.state.isVinculed != true
                                        ?   this.state.empData.isactive
                                                ?   <button type="button" onClick={this.handleDelete} className="btn btn-outline-danger mr-2" style={{ width: '2.75rem', height: '2.75rem' }}><i className='bi bi-archive-fill'></i></button>
                                                :   <button type='button' onClick={this.handleRestaurar} className='btn ws-outline-blue mr-2' style={{ width: '2.75rem', height: '2.75rem' }}><i className='fa fa-undo'></i></button>
                                        :   (
                                                <div className="alert alert-warning" role="alert">
                                                    {<Resource keyString="this_directory_contains_linked_files" />}
                                                </div>
                                            )
                                }
                            </div >
                            <div>
                                <input type="hidden" name="userId" value={this.state.empData.id} />
                                <input type="hidden" name="diretoriosid" value={this.state.empData.diretoriospaiid} />
                            </div>
                        </form >
                    </div>
                    {
                        this.state.empData.perfil.escrita
                            ?   <ListaSetorVinculado diretorioid={this.state.empData.id} />
                            :   <React.Fragment />
                    }
                </div>
            </div>
        );
    }

}  