import * as React from "react";
import BotaoComIconePadrao from "../BotaoComIconePadrao";
const { useState, useEffect } = React;

interface IProps {
    textoDeTitulo?: string;
    textoDeCorpo?: string;
    textoDeConfirmacao?: string;
    textoDeCancelamento?: string;
    onClickConfirmacao?: (...args: any) => any;
    onClickCancelamento?: (...args: any) => any;
    fecharModal: () => void;
}

const ModalDeConfirmacao = ({
    textoDeCorpo,
    textoDeConfirmacao,
    textoDeCancelamento,
    onClickCancelamento,
    onClickConfirmacao,
    fecharModal,
}: IProps) => {
    const _onClickConfirmacao = (e) => {
        if (onClickConfirmacao) {
            onClickConfirmacao(e);
        }
    };
    const _onClickCancelamento = (e) => {
        if (onClickCancelamento) {
            onClickCancelamento(e);
        }
        fecharModal();
    };
    const _textoDeCorpo = textoDeCorpo || "";
    const _textoDeConfirmacao = textoDeConfirmacao || "";
    const _textoDeCancelamento = textoDeCancelamento || "";

    const handleBackgroundClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.target === e.currentTarget) {
            fecharModal();
        }
    };

    return (
        <React.Fragment>
            <div className="card-body text-center">
                <p className="card-text">{_textoDeCorpo}</p>
            </div>
            <div className="card-footer d-flex justify-content-end">
                <BotaoComIconePadrao
                    title={_textoDeCancelamento}
                    onClick={_onClickCancelamento}
                    type="button"
                    classeDeIcone="fa fa-times"
                    className="btn-outline-danger mr-2"
                />
                <BotaoComIconePadrao
                    title={_textoDeConfirmacao}
                    onClick={_onClickConfirmacao}
                    type="button"
                    classeDeIcone="fa fa-check"
                    className="btn-outline-success"
                />
            </div>
        </React.Fragment>
    );
};

export default ModalDeConfirmacao;