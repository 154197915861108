import React, { createContext, useEffect, useState } from 'react';
import { HttpService } from '../components/Shared/HttpService';

interface ILoggedInContext {
    cadastroNaoEstaCompleto: boolean;
    termosAceitos: boolean;
    setTermosAceitos: (termosAceitos: boolean) => void;
}

export const LoggedInContext = createContext<ILoggedInContext>({
    cadastroNaoEstaCompleto: false,
    termosAceitos: false,
    setTermosAceitos: () => { },
});

export const LoggedInProvider = ({ children }) => {
    const [cadastroNaoEstaCompleto, setCadastroCompleto] = useState(false);
    const [termosAceitos, setTermosAceitos] = useState(true);

    useEffect(() => {
        const fetchVerificarSeOCadastroEstaCompleto = async () => {
            const response = await HttpService.fetch('api/Authentication/VerificarSeOCadastroNaoEstaCompleto', { method: 'GET' });
            const data = await response.json();
            setCadastroCompleto(data.cadastroNaoEstaCompleto);
        };

        fetchVerificarSeOCadastroEstaCompleto();
    }, []);

    useEffect(() => {
        const fetchTermosAceitos = async () => {
            const response = await HttpService.fetch('api/Authentication/VerificarAceiteDeTermos', { method: 'GET' })
            const data = await response.json();
            setTermosAceitos(data.termosAceitos);
        };

        fetchTermosAceitos();
    }, []);

    const value = {
        cadastroNaoEstaCompleto,
        termosAceitos,
        setTermosAceitos,
    };

    return (
        <LoggedInContext.Provider value={value}>
            {children}
        </LoggedInContext.Provider>
    );
};