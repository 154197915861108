import * as React from 'react';
import { Route, Switch, RouteProps } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import PlanoAuth, { Plano } from './components/UserAuth/PlanoAuth';

import { Login } from './components/UserAuth/Login';
import { Register } from './components/UserAuth/Register';
import { RegisterPecuarista } from './components/UserAuth/RegisterPecuarista';
import { RecoveryPassword } from './components/UserAuth/RecoveryPassword';
import { ResetPassword } from './components/UserAuth/ResetPassword';

import { FetchUser } from './components/User/FetchUser';
import { AddUser } from './components/User/AddUser';
import { AddUsuarios } from './components/Usuarios/AddUsuarios';
import { FetchUsuarios } from './components/Usuarios/FetchUsuarios';

import { AddEmpresa } from './components/Empresas/AddEmpresa';

import { FetchDocumentos } from './components/Documentos/FetchDocumentos';
import { AddDocumentos } from './components/Documentos/AddDocumentos';
import { DetailDocumento } from './components/Documentos/DetailDocumento';

import { FetchPerfil, PerfilData } from './components/Perfil/FetchPerfil';
import { AddPerfil } from './components/Perfil/AddPerfil';

import { FetchSetor } from './components/Setores/FetchSetor';
import { AddSetor } from './components/Setores/AddSetor';

import { FetchFuncao } from './components/Funcoes/FetchFuncao';
import { AddFuncao } from './components/Funcoes/AddFuncao';

import { FetchDiretorio } from './components/Diretorios/FetchDiretorio';
import { AddDiretorio } from './components/Diretorios/AddDiretorio';
import { DetailDiretorio } from './components/Diretorios/DetailDiretorio';

import { FetchArquivo } from './components/Arquivos/FetchArquivo';
import { AddArquivo } from './components/Arquivos/AddArquivo';

import { Repositorio } from './components/Repositorio/Repositorio';
import { PesquisaRepositorio } from './components/Repositorio/PesquisaRepositorio';
import { EditUsuarios } from './components/Usuarios/EditUsuarios';

// * PLANO DE INSPECAO * //

import { FetchFormatoPergunta } from './components/PA/FormatoPergunta/FetchFormatoPergunta';
import { AddFormatoPergunta } from './components/PA/FormatoPergunta/AddFormatoPergunta';

import { AddPergunta } from './components/PA/Pergunta/AddPergunta';
import { FetchPergunta } from './components/PA/Pergunta/FetchPergunta';
import { AddFormulario } from './components/PA/Formulario/AddFormulario';
import { FetchFormulario } from './components/PA/Formulario/FetchFormulario';
import { AddGrupoResposta } from './components/PA/GrupoResposta/AddGrupoResposta';
import { FetchGrupoResposta } from './components/PA/GrupoResposta/FetchGrupoResposta';
import { AddResposta } from './components/PA/Resposta/AddResposta';
import { FetchResposta } from './components/PA/Resposta/FetchResposta';
import MarketPlaceFormulario from './components/PA/Formulario/MarketPlaceFormulario';
import { MarketPlaceDetail } from './components/PA/Formulario/MarketPlaceDetail';
import { FormularioRespondido } from './components/PA/Formulario/FormularioRespondido';
import { FormularioRespondidoFormula } from './components/PA/Formulario/FormularioRespondidoFormula';
import { MeusFormularios } from './components/PA/Formulario/MeusFormularios';
import { Authorize } from './components/Shared/Authorize';
import { MinhaConta } from './components/Usuarios/MinhaConta';
import { MInhaEmpresa } from './components/Empresas/MInhaEmpresa';
import { FetchPlanoAcao } from './components/PA/PlanoAcao/FetchPlanoAcao';
import { Pagamento } from './components/Pagamentos/Pagamento';
import { Policies } from './components/PrivacyPolicy/Policies';
import { FormularioCompartilhado } from './components/PA/Formulario/FormularioCompartilhado';
import { FetchConfiguracaoFormulario } from './components/PA/ConfiguracaoFormulario/FetchConfiguracaoFormulario';
import { AddConfiguracaoFormulario } from './components/PA/ConfiguracaoFormulario/AddConfiguracaoFormulario';
import { MarketPlaceFormularioInterno } from './components/PA/Formulario/MarketPlaceFormularioInterno';
import { AddFormulaResultado } from './components/PA/FormulaResultado/AddFormulaResultado';
import { Dashboard } from './components/PA/Dashboard/Dashboard';
import { DashboardBiMachine } from './components/PA/Dashboard/DashboardBiMachine';
import { DashboardBiMachineJBS } from './components/PA/Dashboard/DashboardBiMachineJBS';
import { DashboardBiMachineComercialGRX } from './components/PA/Dashboard/DashboardBiMachineComercialGRX';
import { AddCabecalho } from './components/PA/Cabecalho/AddCabecalho';
import { RelatorioFormularioCompartilhado } from './components/PA/Formulario/RelatorioFormularioCompartilhado';
import { RelatorioGeral } from './components/RelatorioGeral/RelatorioGeral';
import { RelatorioGeralLeo } from './components/RelatorioGeral/RelatorioGeralLeo';
import { RelatorioGeralTransportadora } from './components/RelatorioGeral/RelatorioGeralTransportadora';
import { RelatorioAcoes } from './components/RelatorioGeral/RelatorioAcoes';

// * MODELO PLANO ACAO * //
import { AddModeloPlanoAcao } from './components/ModelosPlanoAcao/AddModeloPlanoAcao';
import { FetchModeloPlanoAcao } from './components/ModelosPlanoAcao/FetchModeloPlanoAcao'; 

// * CAMPO PLANO ACAO * //
import { AddCampoModeloAcao} from './components/ModelosPlanoAcao/CampoModeloAcao/AddCampoModeloAcao';
import { FetchCampoModeloAcao } from './components/ModelosPlanoAcao/CampoModeloAcao/FetchCampoModeloAcao';

// * MODELO CADASTRO * //
import { FetchModeloCadastro } from './components/ModeloCadastro/FetchModeloCadastro';
import { AddModeloCadastro } from './components/ModeloCadastro/AddModeloCadastro';
import { AddModeloCadastroColuna } from './components/ModeloCadastro/ModeloCadastroColuna/AddModeloCadastroColuna';
import { FetchVisualizarDadosModeloCadastro } from './components/ModeloCadastro/VisualizarDados/FetchVisualizarDadosModeloCadastro';
import { AddVisualizarDadosModeloCadastro } from './components/ModeloCadastro/VisualizarDados/AddVisualizarDadosModeloCadastro';
import { DashboardOperacao } from './components/PA/Dashboard/DashboardOperacao';
import { NovoResponderFormulario } from './components/PA/Formulario/NovoResponderFormulario';
import PaginaDeDownload from './pages/PaginaDeDownload';
import Inicio from './components/Inicio';
import Vendas from './pages/Vendas';
import Compras from './pages/Compras';
import DashboardBiPowerBi from 'components/PA/Dashboard/DashboardBiPowerBi';
import PreRegistro from 'components/UserAuth/PreRegistro';


// <Route path='/register' component={Register} />
export const AppRoutes = () => (
    <Switch>
        <Route path='/login' component={Login} />
        <Route path='/registergrj' component={RegisterPecuarista} />
        <Route path='/preRegistro/:code' component={PreRegistro} />
        <Route path='/recoveryPassword' component={RecoveryPassword} />
        <Route path='/resetPassword' component={ResetPassword} />
        <Route path='/policies' component={Policies} />
        <Route path='/download/:referencia' component={PaginaDeDownload} />
        <Layout>
                <PlanoAuth plano={Plano.Free}>
                    <Switch>
                        {Authorize.Authorize("manterusuario", null, <Route path='/usuarios/add' component={AddUsuarios} />)}
                        {Authorize.Authorize("manterusuario", null, <Route path='/usuarios/edit/:userid' component={EditUsuarios} />)}
                        {Authorize.Authorize("manterusuario", null, <Route path='/usuarios' component={FetchUsuarios} />)}

                        <Route path='/minhaConta' component={MinhaConta} />

                        <Route path='/empresa/edit/:empresaid' component={AddEmpresa} />
                        {Authorize.Authorize("manterempresa", null, <Route path='/minhaEmpresa' component={MInhaEmpresa} />)}

                        <Route path='/repositorio/pesquisar/:pesquisa' component={PesquisaRepositorio} />
                        
                        {Authorize.Authorize("leitura", null, <Route path='/repositorio/:repositorioid' component={Repositorio} />)}

                        <Route exact path='/repositorio' component={Repositorio} />

                        <Route exact path='/' component={Inicio} />

                        {Authorize.Authorize("escrita", null, <Route path='/diretorio/add/:repositorioid' component={AddDiretorio} />)}
                        {Authorize.Authorize("escrita", null, <Route path='/diretorio/edit/:repositorioid/:diretorioid' component={AddDiretorio} />)}
                        {Authorize.Authorize("leitura", null, <Route path='/diretorio/detail/:diretorioid' component={DetailDiretorio} />)}

                        {Authorize.Authorize("escrita", null, <Route path='/documentos/add/:repositorioid' component={AddDocumentos} />)}
                        {Authorize.Authorize("escrita", null, <Route path='/documentos/edit/:repositorioid/:documentoid' component={AddDocumentos} />)}
                        {Authorize.Authorize("leitura", null, <Route path='/documentos/detail/:documentoid' component={DetailDocumento} />)}

                        {Authorize.Authorize("escrita", null, <Route path='/arquivo/add/:documentoid' component={AddArquivo} />)}
                        
                        {Authorize.Authorize("responder", null, <Route path='/meusFormularios' component={MeusFormularios} />)}
                        
                        {Authorize.Authorize("responder", null, <Route path='/formulario/respondido/:formularioid' component={FormularioRespondido} />)}
                        
                        {Authorize.Authorize("responder", null, <Route path='/formulario/responder/:formularioid/:hash' component={NovoResponderFormulario} />)}

                        {Authorize.Authorize("responder", null, <Route path='/formularioCompartilhado' component={FormularioCompartilhado} />)}
                        
                        {Authorize.Authorize("responder", null, <Route path='/marketPlace/Detail/:formularioid' component={MarketPlaceDetail} />)}
                        {Authorize.Authorize("responder", null, Authorize.Authorize("mostrarlojaexterna", null, <Route path='/marketPlace' component={MarketPlaceFormulario} />))}
                        {Authorize.Authorize("responder", null, <Route path='/marketPlaceInterno' component={MarketPlaceFormularioInterno} />)}
                    </Switch>
                </PlanoAuth>

                <PlanoAuth plano={Plano.Premium}>
                    <Switch>
                        {Authorize.Authorize("manterusuario", null, <Route path='/usuarios/add' component={AddUsuarios} />)}
                        {Authorize.Authorize("manterusuario", null, <Route path='/usuarios/edit/:userid' component={EditUsuarios} />)}
                        {Authorize.Authorize("manterusuario", null, <Route path='/usuarios' component={FetchUsuarios} />)}

                        <Route path='/minhaConta' component={MinhaConta} />

                        <Route path='/empresa/edit/:empresaid' component={AddEmpresa} />
                        {Authorize.Authorize("manterempresa", null, <Route path='/minhaEmpresa' component={MInhaEmpresa} />)}

                        {Authorize.Authorize("manterperfil", null, <Route path='/perfil/add' component={AddPerfil} />)}
                        {Authorize.Authorize("manterperfil", null, <Route path='/perfil/edit/:perfilid' component={AddPerfil} />)}
                        {Authorize.Authorize("manterperfil", null, <Route path='/perfil' component={FetchPerfil} />)}

                        {Authorize.Authorize("mantersetor", null, <Route path='/setor/add' component={AddSetor} />)}
                        {Authorize.Authorize("mantersetor", null, <Route path='/setor/edit/:setorid' component={AddSetor} />)}
                        {Authorize.Authorize("mantersetor", null, <Route path='/setor' component={FetchSetor} />)}

                        {Authorize.Authorize("manterfuncao", null, <Route path='/funcao/add' component={AddFuncao} />)}
                        {Authorize.Authorize("manterfuncao", null, <Route path='/funcao/edit/:funcaoid' component={AddFuncao} />)}
                        {Authorize.Authorize("manterfuncao", null, <Route path='/funcao' component={FetchFuncao} />)}

                        {/*Reposit�rio de arquivos*/}
                        <Route path='/repositorio/pesquisar/:pesquisa' component={PesquisaRepositorio} />

                        {Authorize.Authorize("leitura", null, <Route path='/repositorio/:repositorioid' component={Repositorio} />)}

                        <Route exact path='/' component={Inicio} />
                        <Route exact path='/repositorio' component={Repositorio} />

                        {Authorize.Authorize("escrita", null, <Route path='/diretorio/add/:repositorioid' component={AddDiretorio} />)}
                        {Authorize.Authorize("escrita", null, <Route path='/diretorio/edit/:repositorioid/:diretorioid' component={AddDiretorio} />)}
                        {Authorize.Authorize("leitura", null, <Route path='/diretorio/detail/:diretorioid' component={DetailDiretorio} />)}

                        {Authorize.Authorize("escrita", null, <Route path='/documentos/add/:repositorioid' component={AddDocumentos} />)}
                        {Authorize.Authorize("escrita", null, <Route path='/documentos/edit/:repositorioid/:documentoid' component={AddDocumentos} />)}
                        {Authorize.Authorize("leitura", null, <Route path='/documentos/detail/:documentoid' component={DetailDocumento} />)}

                        {Authorize.Authorize("escrita", null, <Route path='/arquivo/add/:documentoid' component={AddArquivo} />)}

                        {/*PLANO DE INSPE��O*/}
                        {Authorize.Authorize("responder", null, <Route path='/marketPlace/Detail/:formularioid' component={MarketPlaceDetail} />)}
                        {Authorize.Authorize("responder", null, <Route path='/formulario/respondido/:formularioid' component={FormularioRespondido} />)}
                        {Authorize.Authorize("responder", null, <Route path='/formulario/respondidoformula/:formularioid' component={FormularioRespondidoFormula} />)}
                        {Authorize.Authorize("responder", null, <Route path='/formulario/responder/:formularioid/:hash' component={NovoResponderFormulario} />)}
                        {Authorize.Authorize("responder", null, Authorize.Authorize("mostrarlojaexterna", null, <Route path='/marketPlace' component={MarketPlaceFormulario} />))}
                        {Authorize.Authorize("responder", null, <Route path='/marketPlaceInterno' component={MarketPlaceFormularioInterno} />)}
                        {Authorize.Authorize("responder", null, <Route path='/formularioCompartilhado' component={FormularioCompartilhado} />)}
                        
                        {Authorize.Authorize("responder", null, <Route path='/meusFormularios' component={MeusFormularios} />)}

                        {Authorize.Authorize("manterresposta", null, <Route path='/grupoResposta/add' component={AddGrupoResposta} />)}
                        {Authorize.Authorize("manterresposta", null, <Route path='/grupoResposta/edit/:grupoRespostaid' component={AddGrupoResposta} />)}
                        {Authorize.Authorize("manterresposta", null, <Route path='/grupoResposta' component={FetchGrupoResposta} />)}

                        {Authorize.Authorize("manterformulario", null, <Route path='/formulario/add' component={AddFormulario} />)}
                        {Authorize.Authorize("manterformulario", null, <Route path='/formulario/edit/:formularioid' component={AddFormulario} />)}
                        {Authorize.Authorize("manterformulario", null, <Route path='/RelatorioFormularioCompartilhado' component={RelatorioFormularioCompartilhado} />)}

                        {Authorize.Authorize("manterformulario", null, <Route path='/formulario/formularesultado/:formularioid' component={AddFormulaResultado} />)}
                        {Authorize.Authorize("manterformulario", null, <Route path='/formulario/cabecalho/:formularioid' component={AddCabecalho} />)}

                        {Authorize.Authorize("manterformulario", null, <Route path='/formulario' component={FetchFormulario} />)}

                        {Authorize.Authorize("manterformulario", null, <Route path='/pergunta/add/:formularioid/:grupoperguntaid' component={AddPergunta} />)}
                        {Authorize.Authorize("manterformulario", null, <Route path='/pergunta/edit/:formularioid/:grupoperguntaid/:perguntaid' component={AddPergunta} />)}

                        {Authorize.Authorize("manterformulario", null, <Route path='/vendas' component={Vendas} />)}
                        {Authorize.Authorize("manterformulario", null, <Route path='/compras' component={Compras} />)}

                        {Authorize.Authorize("manterformatopergunta", null, <Route path='/formatoPergunta/add' component={AddFormatoPergunta} />)}
                        {Authorize.Authorize("manterformatopergunta", null, <Route path='/formatoPergunta/edit/:formatoPerguntaId' component={AddFormatoPergunta} />)}
                        {Authorize.Authorize("manterformatopergunta", null, <Route path='/formatoPergunta' component={FetchFormatoPergunta} />)}

                        {Authorize.Authorize("manteracao", null, <Route path='/minhasAcoes' component={FetchPlanoAcao} />)}

                        {Authorize.Authorize("manteracao", null, <Route path='/configuracaoFormulario/add/:formularioid' component={AddConfiguracaoFormulario} />)}
                        {Authorize.Authorize("manteracao", null, <Route path='/configuracaoFormulario/edit/:formularioid/:configurarFormularioid' component={AddConfiguracaoFormulario} />)}
                        {Authorize.Authorize("manteracao", null, <Route path='/configuracaoFormulario/:formularioid' component={FetchConfiguracaoFormulario} />)}
                        
                        <Route path='/modeloPlanoAcao/add' component={AddModeloPlanoAcao} />
                        <Route path='/modeloPlanoAcao/edit/:modeloPlanoAcaoid' component={AddModeloPlanoAcao} />
                        <Route path='/modeloPlanoAcao/' component={FetchModeloPlanoAcao} />
            
                        <Route path='/campoModeloAcao/add/:modeloPlanoAcaoid' component={AddCampoModeloAcao} />
                        <Route path='/campoModeloAcao/edit/:campoModeloAcaoid' component={AddCampoModeloAcao} />

                        <Route path='/modeloCadastro/add' component={AddModeloCadastro} />
                        <Route path='/modeloCadastro/edit/:modeloCadastroId' component={AddModeloCadastro} />
                        <Route path='/modeloCadastro/' component={FetchModeloCadastro} />

                        <Route path='/modeloCadastroVisualizarDados/:modeloCadastroId/add' component={AddVisualizarDadosModeloCadastro} />
                        <Route path='/modeloCadastroVisualizarDados/edit/:modeloCadastroVisualizarDadosId/:modeloCadastroId' component={AddVisualizarDadosModeloCadastro} />
                        <Route path='/modeloCadastroVisualizarDados/:modeloCadastroId' component={FetchVisualizarDadosModeloCadastro} />

                        <Route path='/modeloCadastroColuna/add/:modeloCadastroId' component={AddModeloCadastroColuna} />
                        <Route path='/modeloCadastroColuna/edit/:modeloCadastroColunaid' component={AddModeloCadastroColuna} />

                        <Route path='/relatorioGeral' component={RelatorioGeral} />
                        <Route path='/relatorioGeralTransportadora' component={RelatorioGeralTransportadora} />
                        <Route path='/relatorioAcoes' component={RelatorioAcoes} />
                        <Route path='/relatorioGeralLeo' component={RelatorioGeralLeo} />
            
                        <Route path='/dashboard' component={Dashboard} />
                        <Route path='/dashboardBiPowerBI' component={DashboardBiPowerBi} />
                        <Route path='/dashboardBiMachine' component={DashboardBiMachine} />
                        <Route path='/dashboardBiMachineJBS' component={DashboardBiMachineJBS} />
                        <Route path='/dashboardBiMachineComercialGRX' component={DashboardBiMachineComercialGRX} />
                        <Route path='/dashboardOperacao' component={DashboardOperacao} />
                        <Route path='/pagamento' component={Pagamento} />
                    </Switch>
                </PlanoAuth>
        </Layout>
    </Switch>
);
