import React from "react"

const AcaoDeRadio = ({ id, idDaAcao, valor, selecionada, className, idDaPergunta, funcaoModificadora }) => (
    <div className='form-check ml-4' key={`opcao-${id}-da-acao-${idDaAcao}-da-pergunta-${idDaPergunta}`}>
        <input
            className={className}
            name={`acao-${idDaAcao}-da-pergunta-${idDaPergunta}`}
            id={`opcao-${id}-da-acao-${idDaAcao}-da-pergunta-${idDaPergunta}`}
            type="radio"
            value={valor}
            onChange={(event) => funcaoModificadora(event)}
            checked={id === selecionada}
        />
        <label className='custom-control-label' htmlFor={`opcao-${id}-da-acao-${idDaAcao}-da-pergunta-${idDaPergunta}`}>{valor}</label>
    </div>
)

export default AcaoDeRadio