import React from "react";

export const TextoDeTermosDeAceite = () => {
    return (
        <article>
            <h2 className="text-center">Termos de Uso</h2>
            <p className="text-justify">
                TERMO DE ACEITE PARA SOFTWARE GERENCIADOR ELETRÔNICO DE DOCUMENTOS
            </p>
            <p className="text-justify">
                Este Termo de Aceite ("Termo") estabelece os termos e condições para o uso do software gerenciador eletrônico de documentos ("Software") fornecido pela [Nome da Empresa Fornecedora], doravante denominada "Empresa Contratada", e sua aceitação por parte do usuário, doravante denominado "Usuário".
            </p>
            <p className="text-justify">
                Confidencialidade dos Dados 1.1. O Usuário reconhece e concorda que todas as informações e dados inseridos no Software são confidenciais e de sua exclusiva responsabilidade. 1.2. A Empresa Contratada assume o compromisso de adotar medidas técnicas e organizacionais adequadas para garantir a confidencialidade dos dados do Usuário, conforme exigido pela legislação vigente, incluindo a Lei Geral de Proteção de Dados (Lei nº 13.709/2018 - "LGPD").
            </p>
            <p className="text-justify">
                Responsabilidade do Usuário 2.1. O Usuário será o único responsável pelo conteúdo e pelos dados inseridos no Software. 2.2. O Usuário declara que possui todos os direitos e autorizações necessárias para utilizar e inserir os dados no Software, não infringindo quaisquer direitos de terceiros.
            </p>
            <p className="text-justify">
                Cumprimento da LGPD 3.1. A Empresa Contratada compromete-se a cumprir todas as obrigações previstas na LGPD em relação aos dados pessoais tratados por meio do Software. 3.2. O Usuário reconhece que, para o cumprimento das obrigações da LGPD, é necessário que a Empresa Contratada colete, processe e armazene determinadas informações pessoais. 3.3. O Usuário consente expressamente com a coleta, processamento e armazenamento de seus dados pessoais pela Empresa Contratada, nos termos da LGPD e da Política de Privacidade disponibilizada pela Empresa Contratada.
            </p>
            <p className="text-justify">
                Uso dos Dados para Contatos Comerciais 4.1. O Usuário autoriza expressamente a Empresa Contratada a utilizar os dados fornecidos no cadastro para contatos comerciais, incluindo o envio de informações sobre produtos, serviços, promoções, atualizações e eventos relacionados ao Software e às atividades da Empresa Contratada. 4.2. O Usuário poderá optar por não receber tais comunicações comerciais, através dos mecanismos disponibilizados pela Empresa Contratada.
            </p>
            <p className="text-justify">
                Disposições Gerais 5.1. O presente Termo entra em vigor a partir do momento em que o Usuário realiza o cadastro no Software e aceita seus termos. 5.2. O Usuário declara ter lido e compreendido integralmente os termos e condições deste Termo e concorda em cumpri-los integralmente. 5.3. O Usuário reconhece que o não cumprimento das disposições deste Termo poderá acarretar a rescisão do contrato e a suspensão ou encerramento do acesso ao Software. 5.4. Este Termo é regido pelas leis do [país/estado], ficando eleito o foro da comarca [nome da comarca] para dirimir quaisquer questões decorrentes de sua interpretação, validade, execução ou cumprimento. 5.5. Caso alguma disposição deste Termo seja considerada inválida, ilegal ou inexequível por qualquer motivo, tal invalidade, ilegalidade ou inexequibilidade não afetará as demais disposições deste Termo, que permanecerão em pleno vigor e efeito. 5.6. A Empresa Contratada reserva-se o direito de modificar este Termo a qualquer momento, mediante prévio aviso ao Usuário por meio do Software ou de outros meios de comunicação disponibilizados. O uso contínuo do Software após as alterações constitui aceitação dos termos modificados.
            </p>
            <p className="text-justify">
                Ao aceitar este Termo, o Usuário declara estar de acordo com todos os termos e condições aqui estabelecidos, inclusive em relação à confidencialidade dos dados, à responsabilidade pelo seu conteúdo, ao cumprimento da LGPD e ao uso dos dados para contatos comerciais.
            </p>
        </article>
    );
};