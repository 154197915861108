import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Loading } from '../../Shared/Loading';
import { HttpService } from '../../Shared/HttpService';
import { LanguageContext } from '../../../context/LanguageContext';
import CaixaDeConteudo from '../../Shared/CaixaDeConteudo';

const DashboardBiPowerBi = () => {
    const { getLanguageResource } = React.useContext(LanguageContext);

    return (
        <CaixaDeConteudo
            tituloDoHeader='Dashboard'
            botoes={[]}
            itensParaBreadcrumb={[
                { nome: getLanguageResource("home"), url: "/" },
                { nome: 'Dashboard' }
            ]}
        >
            <iframe
                title="Dashboard BRC - Win.Set"
                width="100%"
                height="800px"
                src="https://app.powerbi.com/view?r=eyJrIjoiMGMyM2U3MGYtMzkzZi00ZDViLWExYzQtNDJlN2QzMGE5Yzk2IiwidCI6IjdlYzkwNWU3LTkwZGEtNDY4Yy05ZTY2LTczODYzMmQ2OGU1NyJ9&pageName=ReportSection"
                frameBorder={0}
                allowFullScreen={true}
            />
        </CaixaDeConteudo>
    )
}

export default DashboardBiPowerBi;