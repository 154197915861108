import * as React from 'react';
import { HttpService } from '../../Shared/HttpService';
import { Loading } from '../../Shared/Loading';
import { Resource } from '../../../index';
import { FormularioData } from './FetchFormulario';
import CheckIcon from '../../../assets/icons/check-icon.svg';

interface RealizarFormularioDataState {
    formulario: FormularioData;
    formularioIniciado: FormularioIniciadoData[];
    configuracaoFormularioDataId: number;
    inputValue: number;
    history: any;
}

export class FormularioIniciadoData {
    hash: string = "";
    titulo: string = "";
    formularioid: number = 0;
    configuracaoformularioid: number = 0;
    rascunho: boolean = true;
    datecreated: Date = new Date();
    isresultadoparcial: boolean = false;
}

export class RealizarFormulario extends React.Component<any, RealizarFormularioDataState>{
    constructor(props) {
        super(props);

        this.state = {
            formulario: props.formulario,
            formularioIniciado: [],
            configuracaoFormularioDataId: props.configuracaoFormularioDataId,
            inputValue: 1,
            history: props.history,
        }
    }

    public handleRealizarFormulario(inputValue) {

        var formularioIniciado = new FormularioIniciadoData();
        formularioIniciado.formularioid = this.state.formulario.id;
        formularioIniciado.configuracaoformularioid = this.state.configuracaoFormularioDataId;

        Loading.showLoading(this);
        HttpService.fetch(`api/FormularioIniciadoApi/Realizar/${inputValue}`, {
            method: 'POST',
            body: JSON.stringify(formularioIniciado),
        }).then((response) => {
            return response.json() as Promise<FormularioIniciadoData>
        }).then((data) => {
            if (inputValue == 1) {
                this.props.history.push(`/formulario/responder/${data.formularioid}/${data.hash}`)
                Loading.hideLoading(this);
            }
            else {
                alert("Os formulários foram criados!");
                Loading.hideLoading(this);
                this.props.history.go(0)
            }
        }).catch((e) => {
            alert("Ocorreu um erro ao criar os formulários!");
            Loading.hideLoading(this);
        })
    }

    private handleInputValue(e) {
        if (e.target.value != "" && (e.target.value < 1 || e.target.value > 10)) {
            this.setState({ inputValue: 1 })
        }
        else {
            this.setState({ inputValue: e.target.value })
        }
    }

    private formatInput = (e) => {
        let checkIfNum;

        if (e.key !== undefined) {
            checkIfNum = e.key === "e" || e.key === "E" || e.key === "." || e.key === "," || e.key === "+" || e.key === "-";
        }

        return checkIfNum && e.preventDefault();
    }

    public render() {
        return (
            <React.Fragment>
                <div className='d-flex flex-column align-items-center p-4'>
                    <h3 className='m-4'>Realizar Formulários</h3>
                    <div>
                        <p>Digite a quantidade de formularios que deseja criar entre 1 à 10: </p>
                                <input className="form-control rounded" type="number" name="InputValue" value={this.state.inputValue} onChange={e => this.handleInputValue(e)} onKeyDown={ this.formatInput } />
                            </div>
                    <div className='d-flex justify-content-end w-100 my-3'>
                        <button type="button" className="btn mr-3" style={{ backgroundColor: 'var(--ws-blue)' }} onClick={() => this.handleRealizarFormulario(this.state.inputValue)} disabled={!this.state.inputValue}>
                            <img src={CheckIcon} alt="check" className="mr-2" />
                            Criar
                        </button>
                        <button type="button" className="btn btn-outline-danger" onClick={this.props.fecharModal}><i className='fa fa-times'></i> {<Resource keyString="close" />}</button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}