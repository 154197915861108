import React, { useState, useContext } from 'react';
import { Resource } from '../../../../../index';
import MenuArrow from '../../../../../assets/icons/menu-arrow.svg';
import { FormularioRespondidoContext } from '../../../../../context/FormularioRespondidoContext';
import useDidUpdateEffect from '../../../../../hooks/useDidUpdateEffect';

interface IProps {
  alvo: string;
  resource: string;
}

const HeaderDeCollapseEspecifico = ({ alvo, resource }: IProps) => {
	const { mostrarTodosOsCollapses } = useContext(FormularioRespondidoContext);
	const [isOpened, setIsOpened] = useState(false);
  
	const alvoDoCollapse = document.getElementById(alvo);

	useDidUpdateEffect(() => setIsOpened(mostrarTodosOsCollapses), [mostrarTodosOsCollapses]);
	useDidUpdateEffect(() => {
		if (alvoDoCollapse) {
			if (isOpened) {
				alvoDoCollapse.classList.remove('d-none');
			} else {
				alvoDoCollapse.classList.add('d-none');
    }
  }
	}, [isOpened]);

  return (
    <div
		className={`py-2 px-4 rounded d-flex align-items-center justify-content-between ${isOpened ? '' : '_IMPRIMIR_OMITIR_'}`}
		style={{ cursor: 'pointer', backgroundColor: 'var(--ws-blue-light)' }}
      	id={`heading-contagem-resposta`}
		onClick={() => setIsOpened(!isOpened)}
    >
		<strong><Resource keyString={resource} /></strong>
		<img
			src={MenuArrow}
			alt="arrow-sidebar-button"
			className="fechado _IMPRIMIR_OMITIR_"
			style={{
				transform: isOpened ? 'rotate(180deg)' : '',
				transition: 'transform 550ms ease',
			}}
		/>
    </div>
  );
}

export default HeaderDeCollapseEspecifico;
