import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { HttpService } from '../../Shared/HttpService';
import { Loading } from '../../Shared/Loading';
import { Compartilhar } from './CompartilharFormulario';
import { Resource } from '../../../index';
import { LanguageContext } from '../../../context/LanguageContext';
import CaixaDeConteudo from '../../Shared/CaixaDeConteudo';

interface FormularioCompartilhadoState {
    formulariosCompartilhados: Compartilhar[];
    search: string;
}

export class FormularioCompartilhado extends React.Component<RouteComponentProps<{}>, FormularioCompartilhadoState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = { formulariosCompartilhados: [], search: "" };

        Loading.showLoading(this);;
        HttpService.fetch('api/FormularioCompartilhadoApi/ListarFormulariosCompartilhados', {})
            .then(response => response.json() as Promise<Compartilhar[]>)
            .then(data => {
                this.setState({ formulariosCompartilhados: data });
                Loading.hideLoading(this);
            });
    }

    filterForms = (): Compartilhar[] => {
        const escaparCaracteresEspeciais = (string) => {
            return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        }

        let formulariosCompartilhados = this.state.formulariosCompartilhados;

        const searchHigienizado = this.state.search.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");

        if (searchHigienizado !== "") {
            const regex = new RegExp(escaparCaracteresEspeciais(searchHigienizado), 'i');
            formulariosCompartilhados = formulariosCompartilhados.filter((doc) => {
                return regex.test(doc.nomedoformulario)
                    || regex.test(doc.nomedaempresa)
                    || regex.test(doc.titulo)
                    || regex.test(doc.hash);
            });
        }

        return formulariosCompartilhados;
    };

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />

        return (
            <CaixaDeConteudo
                tituloDoHeader={this.context.getLanguageResource("shared_form")}
                itensParaBreadcrumb={[
                    { nome: this.context.getLanguageResource("home"), url: "/" },
                    { nome: this.context.getLanguageResource("shared_form") }
                ]}
                botoes={[]}
            >
                <div className='p-4'>
                    <div>
                        <input
                            className="form-control mb-3 w-auto"
                            type="text"
                            placeholder={this.context.getLanguageResource("search_for_your_shared_form")}
                            value={this.state.search}
                            onChange={(event) => this.setState({ search: event.target.value })}
                            style={{ minWidth: '23.75rem' }}
                        />
                    </div>

                    <main className='d-flex flex-column w-100'>
                        {
                            this.filterForms().map(doc => (
                                <div key={'compartilhamento' + doc.id} className='row d-flex w-100 justify-content-between align-items-center border rounded p-3 mx-0 my-2'>
                                    <section className='col-4'>
                                        <h6 className='font-weight-light text-break'><strong className='font-weight-bold'><Resource keyString="company" />:</strong> {doc.nomedaempresa}</h6>
                                        <h6 className='font-weight-light text-break'><strong className='font-weight-bold'><Resource keyString="form" />:</strong> {doc.titulo != null ? doc.titulo : doc.hash} ({doc.nomedoformulario})</h6>
                                    </section>

                                    <section className='d-flex justify-content-end col-5'>
                                        {
                                            doc.visualizarresultado
                                                ? <div className='p-2 m-3 d-flex align-items-center justify-content-center flex-column rounded' style={{ minWidth: '8.75rem', boxShadow: '0 0 1rem var(--ws-blue-soft)' }} >
                                                    <h2>{doc.atingimento.toFixed(0)}%</h2>
                                                    <p className='mb-0'>% <Resource keyString="result" /></p>
                                                </div>
                                                : <React.Fragment />
                                        }
                                        {
                                            doc.permitirresponder
                                                ? <div className='p-2 m-3 d-flex align-items-center justify-content-center flex-column rounded' style={{ minWidth: '8.75rem', boxShadow: '0 0 1rem var(--ws-blue-soft)' }} >
                                                    <h2>{doc.porcentagemRespondido.toFixed(0)}%</h2>
                                                    <p className='mb-0'>% <Resource keyString="answered" /></p>
                                                </div>
                                                : <React.Fragment />
                                        }
                                    </section>

                                    <section className='col-3 d-flex flex-column align-items-end'>
                                        {
                                            (doc.permitirresponder && doc.rascunho)
                                                ? <button
                                                    onClick={(x) => this.props.history.push(`/formulario/responder/${doc.formularioid}/${doc.hash}`)}
                                                    className="btn mb-2"
                                                    style={{ width: '110px', cursor: 'pointer', backgroundColor: 'var(--ws-blue)' }}
                                                >
                                                    <Resource keyString="answerButton" />
                                                </button>
                                                : <React.Fragment />
                                        }
                                        {
                                            doc.visualizarresultado && doc.atingimento > 0
                                                ? <button
                                                    onClick={(x) => this.props.history.push(`/formulario/respondido/${doc.hash}`)}
                                                    className="btn btn-outline-dark"
                                                    style={{ width: '110px', cursor: 'pointer', color: 'var(--ws-green-brown)', borderColor: 'var(--ws-green-brown)' }}
                                                >
                                                    <Resource keyString="visualize" />
                                                </button>
                                                : <React.Fragment />
                                        }
                                    </section>
                                </div>
                            )
                            )}
                    </main>
                </div>
            </CaixaDeConteudo>
        );
    }
}