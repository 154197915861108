import React, { createContext } from "react";
import { HttpService } from "../components/Shared/HttpService";

interface ILanguageObject {
    key: string;
    value: string;
}

export interface ILanguageContext {
    languageResources: ILanguageObject[];
    setLanguageResources: (languageResources: ILanguageObject[]) => void;
    getLanguageResource: (key: string) => string;
    setLanguage: (language: string) => void;
}

export const LanguageContext = createContext<ILanguageContext>({
    languageResources: [],
    setLanguageResources: () => {},
    getLanguageResource: () => "",
    setLanguage: () => {}
});

export const LanguageProvider = ({ children }) => {
    const [languageResources, setLanguageResources] = React.useState<ILanguageObject[]>([]);
    const [language, setLanguage] = React.useState<string>(
        localStorage.getItem("pagedLanguage") || "pt-br"
    );

    const getLanguageResource = (key: string): string => {
        const resource = languageResources[key]
        if (!resource && process.env.REACT_APP_DEBUG_MODE) return `[:::${key}:::]`;
        return resource ? resource : "";
    }
    
    React.useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        let langParam = urlParams.get('lang');
        localStorage.setItem("pagedLanguage", langParam || language);
        if (langParam === null) langParam = language;
        const fetchLanguage = async () => {
            const response = await HttpService.fetch(`api/GetLanguageResources`, {
                method: "POST",
                body: JSON.stringify({ languageRequest: langParam })
            })
            setLanguageResources(await response.json());
        }
        fetchLanguage();
    }, [language]);

    const values: ILanguageContext = {
        languageResources,
        setLanguageResources,
        getLanguageResource,
        setLanguage,
    };

    return (
        <LanguageContext.Provider value={values}>
            {children}
        </LanguageContext.Provider>
    )
};
