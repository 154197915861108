import * as React from 'react';
import { AuthService } from '../UserAuth/AuthService';

export interface AuthorizeState {
    role: string;
    acesso: number;
}

export class Authorize extends React.Component<any, AuthorizeState> {

    constructor(props) {
        super(props);
        this.state = { role: props.role, acesso: props.acesso };
    }

    public render() {
        return Authorize.Authorize(this.state.role, this.state.acesso, this.props.children);
    }

    public static Authorize(role: string, acesso: any, component: any) {
        return AuthService.isAuthorized(role)
            && ((acesso != null && acesso.includes(AuthService.getStorage('acesso'))) || acesso == null) ? component : null;
    }
}