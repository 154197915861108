import React, { createContext, useState, useEffect } from 'react';
import { HttpService } from '../components/Shared/HttpService';

interface ILoggedGlobalContext {
    nomeDoUsuario: string;
    razaoSocialDaEmpresa: string;
}

export const LoggedGlobalContext = createContext<ILoggedGlobalContext>({
    nomeDoUsuario: '',
    razaoSocialDaEmpresa: '',
});

export const LoggedGlobalProvider = ({ children }) => {
    const [nomeDoUsuario, setNomeDoUsuario] = useState('');
    const [razaoSocialDaEmpresa, setRazaoSocialDaEmpresa] = useState('');

    useEffect(() => {
        HttpService.fetch('api/GlobalLoggedInfo', { method: 'GET' })
            .then(response => response.json())
            .then(data => {
                setNomeDoUsuario(data.nomeDoUsuario);
                setRazaoSocialDaEmpresa(data.razaoSocialDaEmpresa);
            });
    }, []);

    const value = {
        nomeDoUsuario,
        razaoSocialDaEmpresa,
    };

    return (
        <LoggedGlobalContext.Provider value={value}>
            {children}
        </LoggedGlobalContext.Provider>
    );
};