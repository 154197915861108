import * as React from 'react';
import { HttpService } from '../../Shared/HttpService';
import { Loading } from '../../Shared/Loading';
import $ from 'jquery';
import { ValidationLabelMessage, ValidationAlertMessage } from '../../Shared/ValidationMessage';
import { FormularioData } from './FetchFormulario';
import { UsuarioData } from '../../Usuarios/FetchUsuarios';
import { EmpresaData } from '../../Empresas/FetchEmpresa';
import { Resource } from '../../../index';
import { LanguageContext } from '../../../context/LanguageContext';
import ModalWinSet from '../../Shared/components/ModalWinSet';

interface CompartilharFormularioDataState {
    formulario: FormularioData;
    compartilhar: Compartilhar;
    listaCompartilhado: Compartilhar[];
    permitirresponderestamarcado: boolean;
    permitireditartituloestamarcado: boolean;
    classeDeAlerta: string;
    modalDelete: boolean;
    modalDeleteId: number;
}

export class Compartilhar {
    id: number = 0;
    formularioid: number = 0;
    usuariosid: number = 0;
    empresasid: number = 0;
    usercreatedid: number = 0;
    porcentagemRespondido: number = 0;
    email: string = "";
    titulo: string = "";
    hash: string = "";
    nomedaempresa: string = "";
    nomedoformulario: string = "";
    permitirresponder: boolean = false;
    visualizarresultado: boolean = false;
    permitiralterarnomedoformulario: boolean = true;
    usuarios: UsuarioData = new UsuarioData();
    empresas: EmpresaData = new EmpresaData();
    formularioXEmpresa: FormularioXEmpresa = new FormularioXEmpresa();
    error: Array<any> = [];
    atingimento: number = 0;
    respondido: number = 0;
    rascunho: boolean = true;
}

export class FormularioXEmpresa {
    empresasid: number = 0;
    id: number = 0;
    empresas: EmpresaData = new EmpresaData();
    formulario: FormularioData = new FormularioData();
    error: Array<any> = [];
}

export class CompartilharFormulario extends React.Component<any, CompartilharFormularioDataState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        var compartilhar = new Compartilhar();
        compartilhar.hash = props.hash;

        // This will set state for Add User  
        this.state = {
            formulario: props.formulario,
            compartilhar: compartilhar,
            listaCompartilhado: [],
            permitirresponderestamarcado: false,
            permitireditartituloestamarcado: true,
            classeDeAlerta: "",
            modalDelete: false,
            modalDeleteId: 0,
        };

        this.updateListaCompartilhado();
        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);
    }

    private updateListaCompartilhado() {
        Loading.showLoading(this);;
        HttpService.fetch('api/FormularioCompartilhadoApi/All/' + this.state.compartilhar.hash, {})
            .then(response => response.json() as Promise<Compartilhar[]>)
            .then(data => {
                this.setState({ listaCompartilhado: data });
                Loading.hideLoading(this);
            });
    }

    // This will handle the submit form event.  
    private handleSave(event) {
        event.preventDefault();

        var url = `api/FormularioCompartilhadoApi`;
        var input = document.getElementById("email") as HTMLInputElement;

        Loading.showLoading(this);

        if (!input.value || input.value == "") {
            this.setState({ classeDeAlerta: 'is-invalid' });
            Loading.hideLoading(this);
        }
        else {
            this.setState({ classeDeAlerta: '' });

            HttpService.fetch(url, {
                method: 'POST',
                body: HttpService.formToJson(event.target),

            }).then((response) => {

                return response.json() as Promise<Compartilhar>;

            }).then((data) => {
                if ($.isEmptyObject(data.error)) {
                    data.email = "";
                    this.updateListaCompartilhado();
                    $("#CompartilharFormulario input[name=email]").val('');
                }
                this.setState({ compartilhar: data });
                Loading.hideLoading(this);

            }).catch((responseJson) => {
                Loading.hideLoading(this);

            })
        }
    }

    // Handle Delete request for an user
    private handleDelete(id: number, hash: string) {
        Loading.showLoading(this);;
        HttpService.fetch('api/FormularioCompartilhadoApi/' + id + '/' + hash, {
            method: 'delete'
        }).then(data => {
            this.updateListaCompartilhado();
            Loading.hideLoading(this);
        }).catch(() => {
            Loading.hideLoading(this);
        });
        this.setState({ modalDelete: false });
    }

    // Returns the HTML Form to the render() method.  
    public render() {
        return (
            <React.Fragment>
                <form className='p-3' onSubmit={this.handleSave} >
                    <header style={{ height: '10%' }}>
                        <h3 className='mt-4 mb-2'>{<Resource keyString="share_form" />} ({this.state.formulario.nome})</h3>
                    </header>

                    <hr className='my-0' />

                    <main style={{ height: 'calc(90% - 4rem)' }}>
                        <section className='h-50'>
                            <div className="form-group">
                                <input type="hidden" name="formularioxempresaid" value={this.state.formulario.formularioXEmpresaId} />
                                <input type="hidden" name="hash" value={this.state.compartilhar.hash} />
                                <p className='my-3'>{<Resource keyString="enter_your_user_email_or_company_id" />}</p>
                                <ValidationLabelMessage errors={this.state.compartilhar.error} name="Email">
                                    <input className="form-control my-0" type="text" name="email" placeholder={this.context.getLanguageResource("email_company_id")} />
                                </ValidationLabelMessage>

                                <div className="form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        name="permitirresponder"
                                        id="permitirresponder"
                                        checked={this.state.permitirresponderestamarcado}
                                        defaultChecked={this.state.compartilhar.permitirresponder}
                                        onChange={() => this.setState({ permitirresponderestamarcado: !this.state.permitirresponderestamarcado })}
                                    />
                                    <label className="form-check-label" htmlFor="permitirresponder">{this.context.getLanguageResource("allow_reply")}</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="mt-2 form-check">
                                    <input type="checkbox" className="form-check-input" name="permitirresponder" id="permitirresponder" defaultChecked={this.state.compartilhar.permitirresponder} />
                                    <label className="form-check-label" htmlFor="permitirresponder">{<Resource keyString="allow_reply" />}</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="mt-2 form-check">
                                    <input type="checkbox" className="form-check-input" name="visualizarresultado" id="visualizarresultado" defaultChecked={this.state.compartilhar.visualizarresultado} />
                                    <label className="form-check-label" htmlFor="visualizarresultado">{<Resource keyString="view_result" />}</label>
                                </div>

                            </div>
                            <div className="form-group mt-2">
                                <button className="btn d-flex align-items-center justify-content-center" style={{ backgroundColor: 'var(--ws-blue)', width: '2.4rem', height: '2.4rem' }} type="submit"><i className='fa fa-share-alt'></i></button>
                            </div>
                            <ValidationAlertMessage errors={this.state.compartilhar.error} name="Summary" />
                        </section>

                        <section className='h-50'>
                            <div className='h-50'>
                                <hr />
                                <p className='font-weight-bold mb-0'>{<Resource keyString="users_with_access" />} ({this.state.listaCompartilhado.filter((o) => o.usuariosid > 0).length})</p>
                                <div className='barra_de_rolagem_estilizada' style={{ overflowY: 'auto', maxHeight: '8.438rem' }}>
                                    <div>
                                        {this.state.listaCompartilhado.filter((o) => o.usuariosid > 0).map((o, i) =>
                                            <div style={{ height: '2.813rem', backgroundColor: 'var(--ws-blue-highlight-menu)' }} className="rounded p-2 d-flex justify-content-between align-items-center my-2 mr-1">
                                                <div>
                                                    <p className='mb-0 font-weight-bold' style={{ fontSize: '0.75rem' }}>{o.usuariosid > 0 ? o.usuarios.nome : o.empresas.nome}</p>
                                                    <small>
                                                        {o.permitirresponder ? <Resource keyString="allow_reply" /> : ""}
                                                        {o.permitirresponder && o.visualizarresultado ? " | " : ""}
                                                        {o.visualizarresultado ? <Resource keyString="view_result" /> : ""}
                                                    </small>
                                                </div>
                                                <button type="button" className="btn btn-sm btn-outline-danger mr-2 pull-right" style={{ width: '1.875rem', height: '1.875rem' }} onClick={() => this.setState({ modalDeleteId: o.id, modalDelete: true })}><i className='fa fa-trash'></i></button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='h-50'>
                                <hr />
                                <p className='font-weight-bold mb-0'>{<Resource keyString="companies_with_access" />} ({this.state.listaCompartilhado.filter((o) => o.empresasid > 0).length})</p>
                                <div className='barra_de_rolagem_estilizada' style={{ overflowY: 'auto', maxHeight: '8.438rem' }}>
                                    <div>
                                        {this.state.listaCompartilhado.filter((o) => o.empresasid > 0).map((o, i) =>
                                            <div style={{ height: '2.813rem', backgroundColor: 'var(--ws-blue-highlight-menu)' }} className="rounded p-2 d-flex justify-content-between align-items-center my-2 mr-1">
                                                <div>
                                                    <p className='mb-0 font-weight-bold' style={{ fontSize: '0.75rem' }}>{o.usuariosid > 0 ? o.usuarios.nome : o.empresas.nome}</p>
                                                    <small>
                                                        {o.permitirresponder ? <Resource keyString="allow_reply" /> : ""}
                                                        {o.permitirresponder && o.visualizarresultado ? " | " : ""}
                                                        {o.visualizarresultado ? <Resource keyString="view_result" /> : ""}
                                                    </small>
                                                </div>
                                                <button type="button" className="btn btn-sm btn-outline-danger mr-2 pull-right" style={{ width: '1.875rem', height: '1.875rem' }} onClick={() => this.setState({ modalDeleteId: o.id, modalDelete: true })}><i className='fa fa-trash'></i></button>
                                            </div>
                                        )}
                                    </div>

                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-link" data-dismiss="modal"><i className='fa fa-times'></i> {this.context.getLanguageResource("close")}</button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>

                    <footer className='w-100 d-flex justify-content-end' style={{ maxHeight: '4rem' }}>
                        <button
                            type="button"
                            className="btn btn-outline-danger"
                            onClick={() => this.props.fecharModal()}
                        >
                            <i className='fa fa-times'></i> {<Resource keyString="close" />}
                        </button>
                    </footer>
                </form>

                {
                    this.state.modalDelete
                        ? (
                            <ModalWinSet
                                fecharModal={() => this.setState({ modalDelete: false })}
                            >
                                <div className='d-flex flex-column align-items-center p-3'>
                                    <header>
                                        <h3>Deseja desfazer esse compartilhamento?</h3>
                                    </header>
                                    <footer className='d-flex justify-content-end w-100'>
                                        <button type="button" className="btn mr-2" style={{ backgroundColor: "var(--ws-blue)" }} onClick={() => this.handleDelete(this.state.modalDeleteId, this.state.compartilhar.hash)}>Sim</button>
                                        <button type="button" className="btn btn-outline-danger" onClick={() => this.setState({ modalDelete: false })}>Não</button>
                                    </footer>
                                </div>
                            </ModalWinSet>
                        )
                        : <React.Fragment />
                }
            </React.Fragment>
        )
    }

}  