import * as React from 'react';

const PlanoDeAcaoModeloPersonalizado = ({ acao }) => {
	const { campomodeloacaoXValor } = acao;

	return (
		<table>
			<tbody style={{ fontSize: '0.75rem' }}>
				{
					campomodeloacaoXValor.map(({ id, campo, valor }) => {
						return (
							<tr key={"campo" + id}>
								<th className='p-2 align-top'><strong>{campo}</strong></th>
								<td className='p-2 align-top'>{valor}</td>
							</tr>
						);
					})
				}
			</tbody>
		</table>
	);
};

export default PlanoDeAcaoModeloPersonalizado;
