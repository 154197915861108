import * as React from 'react';
import CardDeCabecalho from '../CardDeCabecalho';

interface IProps {
  resultadoComCabecalho: any;
  rodape?: boolean;
}

const CabecalhoResultado = ({ resultadoComCabecalho, rodape }: IProps) => {
  return (
    <div className='w-100 d-flex flex-wrap'>
      {
        resultadoComCabecalho.length > 0
          ? resultadoComCabecalho.map((obj) =>
            <CardDeCabecalho
              key={`card-de-cabecalho-ou-rodape-${obj.id}`}
              cardId={obj.id}
              cardName={obj.nome}
              cardValue={obj.valor}
            />
          )
          : (
            <div className='w-100 d-flex justify-content-center align-items-center'>
              <p className='text-muted m-0'>
                {
                  rodape
                    ? "Não há rodapés"
                    : "Não há cabeçalhos"
                }
              </p>
            </div>
          )

      }
    </div>
  );
}

export default CabecalhoResultado;
