import * as React from 'react';
import { HttpService } from '../Shared/HttpService';
import { Loading } from '../Shared/Loading';
import { ValidationLabelMessage, ValidationAlertMessage } from '../Shared/ValidationMessage';
import $ from 'jquery';
import { Resource } from '../../index';
import { LanguageContext } from '../../context/LanguageContext';
import { ValidadorDeSenha } from '../../helpers/validadorDeSenha';

interface ChangePasswordDataState {
    changePassword: ChangePassworData;
    mensagem: string;
    estruturaDaSenha: IEstruturaDaSenha;
}

export class ChangePassworData {
    id: number = 0;
    senha: string = "";
    novaSenha: string = "";
    confirmarNovaSenha: string = "";
    error: Array<any> = [];
}

interface IEstruturaDaSenha {
    oitoCaracteres: boolean;
    umaLetraMaiuscula: boolean;
    umaLetraMinuscula: boolean;
    umNumero: boolean;
    umCaractereEspecial: boolean;
}

export class ChangePassword extends React.Component<any, ChangePasswordDataState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        var changePassword = new ChangePassworData
        changePassword.id = props.usuariosId

        this.state = {
            changePassword: changePassword,
            mensagem: "",
            estruturaDaSenha: {
                oitoCaracteres: false,
                umaLetraMaiuscula: false,
                umaLetraMinuscula: false,
                umNumero: false,
                umCaractereEspecial: false,
            } as IEstruturaDaSenha,
        };

        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);
    }

    // This will handle the submit form event.  
    private handleSave(event) {
        event.preventDefault();

        if (!this.aSenhaEhSegura()) return;

        if (this.state.changePassword.novaSenha != this.state.changePassword.confirmarNovaSenha) {
            document.getElementById("confirmarNovaSenha")?.classList.add("is-invalid");
            document.getElementById("novaSenha")?.classList.add("is-invalid");
            this.setState({ mensagem: this.context.getLanguageResource("passwords_do_not_match") })
            this.forceUpdate();
            return;
        }

        Loading.showLoading(this);
        HttpService.fetch('api/Authentication/ChangePassword', {
            method: 'POST',
            body: HttpService.formToJson(event.target),
        }).then((response) => {
            if (response.status === 401) {
                Loading.hideLoading(this);
                this.setState({ mensagem: this.context.getLanguageResource("incorrect_password") })
            }
            return response.json() as Promise<ChangePassworData>
        }).then((data) => {
            this.setState({ changePassword: data })
            this.forceUpdate();
            Loading.hideLoading(this);
            this.props.fecharModal()

        }).catch(() => {
            Loading.hideLoading(this);
        });
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        target.classList.remove("is-invalid");

        var changePassword = this.state.changePassword;
        changePassword[name] = value;

        var estruturaMomentaneaDaSenha = this.state.estruturaDaSenha;

        if (event.target.id == "novaSenha") {
            estruturaMomentaneaDaSenha = {
                oitoCaracteres: ValidadorDeSenha.possuiTamanhoMinimo(value),
                umaLetraMaiuscula: ValidadorDeSenha.possuiLetraMaiuscula(value),
                umaLetraMinuscula: ValidadorDeSenha.possuiLetraMinuscula(value),
                umNumero: ValidadorDeSenha.possuiNumero(value),
                umCaractereEspecial: ValidadorDeSenha.possuiCaractereEspecial(value),
            } as IEstruturaDaSenha;
        }

        this.setState({
            changePassword: changePassword,
            estruturaDaSenha: event.target.id == "novaSenha" ? estruturaMomentaneaDaSenha : this.state.estruturaDaSenha,
        });
    };

    aSenhaEhSegura = () => {
        return this.state.estruturaDaSenha.oitoCaracteres &&
            this.state.estruturaDaSenha.umaLetraMaiuscula &&
            this.state.estruturaDaSenha.umaLetraMinuscula &&
            this.state.estruturaDaSenha.umNumero &&
            this.state.estruturaDaSenha.umCaractereEspecial &&
            this.state.changePassword.novaSenha === this.state.changePassword.confirmarNovaSenha;
    };

    // Returns the HTML Form to the render() method.  
    public render() {
        return (
            <form className='w-100 d-flex flex-column py-4' style={{ padding: '0 3rem 0 3rem' }} onSubmit={this.handleSave} >
                <header>
                    <h4 className='text-center'>{<Resource keyString="change_password" />}</h4>
                </header>

                <main>
                    {
                        this.state.mensagem != ""
                            ?   (
                                    <div className="alert alert-danger text-center" role="alert">
                                        {this.state.mensagem}
                                    </div>
                                )
                            :   null
                    }
                    <input type="hidden" name="Id" value={this.state.changePassword.id} />
                    <div className="form-group">
                        <label className="required">{<Resource keyString="present_password" />}</label>
                        <ValidationLabelMessage errors={this.state.changePassword.error} name="Senha" >
                            <input className="form-control" type="password" name="senha" id="senha" onChange={this.handleChange} placeholder={this.context.getLanguageResource("present_password")} defaultValue={this.state.changePassword.senha} />
                        </ValidationLabelMessage>
                    </div>
                    <div className="form-group">
                        <label className="required">{<Resource keyString="new_password" />}</label>
                        <ValidationLabelMessage errors={this.state.changePassword.error} name="novaSenha" >
                            <input className="form-control" type="password" name="novaSenha" id="novaSenha" onChange={this.handleChange} placeholder={this.context.getLanguageResource("new_password")} value={this.state.changePassword.novaSenha} />
                        </ValidationLabelMessage>
                        <div className='mt-2 ml-1'>
                            <p className='mb-0'>{this.state.estruturaDaSenha.oitoCaracteres ? <i className='bi bi-check-circle-fill mr-2 text-success' /> : <i className='bi bi-x-circle-fill mr-2 text-danger' />}<Resource keyString="minimum_8_characters" /></p>
                            <p className='mb-0'>{this.state.estruturaDaSenha.umaLetraMaiuscula ? <i className='bi bi-check-circle-fill mr-2 text-success' /> : <i className='bi bi-x-circle-fill mr-2 text-danger' />}<Resource keyString="at_least_one_uppercase_letter" /></p>
                            <p className='mb-0'>{this.state.estruturaDaSenha.umaLetraMinuscula ? <i className='bi bi-check-circle-fill mr-2 text-success' /> : <i className='bi bi-x-circle-fill mr-2 text-danger' />}<Resource keyString="at_least_one_lowercase_letter" /></p>
                            <p className='mb-0'>{this.state.estruturaDaSenha.umNumero ? <i className='bi bi-check-circle-fill mr-2 text-success' /> : <i className='bi bi-x-circle-fill mr-2 text-danger' />}<Resource keyString="at_least_one_number" /></p>
                            <p className='mb-0'>{this.state.estruturaDaSenha.umCaractereEspecial ? <i className='bi bi-check-circle-fill mr-2 text-success' /> : <i className='bi bi-x-circle-fill mr-2 text-danger' />}<Resource keyString="at_least_one_special_character" /></p>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="required">{<Resource keyString="confirm_new_password" />}</label>
                        <ValidationLabelMessage errors={this.state.changePassword.error} name="confirmarNovaSenha" >
                            <input className="form-control" type="password" name="confirmarNovaSenha" id="confirmarNovaSenha" onChange={this.handleChange} placeholder={this.context.getLanguageResource("confirm_new_password")} value={this.state.changePassword.confirmarNovaSenha} />
                        </ValidationLabelMessage>
                            <div className='mt-2 ml-1' style={{ height: '2rem' }}>
                                {
                                    this.state.changePassword.novaSenha !== this.state.changePassword.confirmarNovaSenha && (
                                        <p className='mb-0 text-danger'><Resource keyString="passwords_do_not_match" /></p>
                                    )
                                }
                            </div>
                    </div>
                    <ValidationAlertMessage errors={this.state.changePassword.error} name="Summary" />
                </main>

                <footer className='d-flex justify-content-between'>
                    <button className="btn" style={{ backgroundColor: 'var(--ws-blue)' }} type="submit"><Resource keyString="change_password" /></button>
                    <button type="button" className="btn btn-outline-danger" data-dismiss="modal" onClick={() => this.props.fecharModal()}><i className='fa fa-times'></i> {<Resource keyString="close" />}</button>
                </footer>
            </form>
        )
    }

}  