import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { Layout } from '../Layout';
import { HttpService } from '../Shared/HttpService';
import { HeadLayout } from '../Shared/HeadLayout';
import { Loading } from '../Shared/Loading';
import { ModalDelete } from '../Shared/ModalDelete';
import { PerfilData } from '../Perfil/FetchPerfil';
import { Resource } from '../../index';
import { LanguageContext } from '../../context/LanguageContext';

interface FetchDiretorioState {
    diretorioList: DiretorioData[];
    loading: boolean;
}

export class DiretorioData {
    id: number = 0;
    empresaid: number = 0;
    nome: string = "";
    diretoriospaiid: number = 0;
    perfil: PerfilData = new PerfilData;
    error: Array<any> = [];
    isactive: boolean = true;
}

export class FetchDiretorio extends React.Component<RouteComponentProps<{}>, FetchDiretorioState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = { diretorioList: [], loading: true };

        Loading.showLoading(this);;
        HttpService.fetch('api/DiretoriosApi', {})
            .then(response => response.json() as Promise<DiretorioData[]>)
            .then(data => {
                this.setState({ diretorioList: data, loading: false });
                Loading.hideLoading(this);
            });

        this.handleDelete = this.handleDelete.bind(this);
        this.handleEdit = this.handleEdit.bind(this);

    }

    public render() {
        const { getLanguageResource } = this.context;
        let contents = Loading.isLoading(this)
            ? <p><em></em></p>
            : this.renderUserTable(this.state.diretorioList);

        return <div className="col-lg-12">
            <HeadLayout title={getLanguageResource("list_directory")} />
            <div className="card">
                <div className="card-header"><a href="/">{<Resource keyString="home" />}</a> / <a href="/repositorio">{<Resource keyString="repositories" />}</a> / {<Resource keyString="list_directory" />}</div>
                <div className="card-body">
                    <div className="card-title">
                        <h3 className="text-center title-2">{<Resource keyString="list_directory" />}
                            <Link to="/diretorios/add" className="action btn btn-primary pull-right" data-loading-text="<i className='fa fa-spinner fa-spin '></i> Processing Order">+ {<Resource keyString="add" />}</Link></h3>
                    </div>
                    <hr />
                    {contents}
                </div>
            </div>
        </div>;
    }

    // Handle Delete request for an user  
    private handleDelete(event, id: number) {
            Loading.showLoading(this);;
            HttpService.fetch('api/DiretoriosApi/' + id, {
                method: 'delete'
            }).then(data => {
                this.setState(
                    {
                        diretorioList: this.state.diretorioList.filter((rec) => {
                            return (rec.id != id);
                        })
                    });
                Loading.hideLoading(this);
                }).catch(() => {
                    Loading.hideLoading(this);
                });
    }

    private handleEdit(id: number) {
        this.props.history.push("/diretorios/edit/" + id);
    }

    // Returns the HTML table to the render() method.  
    private renderUserTable(diretorioList: DiretorioData[]) {
        return <table className='table'>
            <thead>
                <tr>
                    <th></th>
                    <th>Id</th>
                    <th>{<Resource keyString="name" />}</th>
                </tr>
            </thead>
            <tbody>
                {diretorioList.map(doc =>
                    <tr key={doc.id}>
                        <td></td>
                        <td>{doc.nome}</td>
                        <td>
                            <button className="btn btn-outline-danger mr-2 pull-right" data-toggle="modal" data-target={`#delete${doc.id}`}><i className='fa fa-trash'></i> {<Resource keyString="delete" />}</button>
                            <button className="btn btn-outline-success mr-2" onClick={(id) => this.handleEdit(doc.id)}><i className='fa fa-edit'></i> {<Resource keyString="edit" />}</button>
                            <ModalDelete id={doc.id} callBack={this} message={`Deseja remover o diretório: ${doc.nome}?`} />
                        </td>
                    </tr>
                )}
            </tbody>
        </table>;
    }
}   