import React, { useContext, useEffect, useState } from "react";
import { AuthService } from "../UserAuth/AuthService";
import LinkDeNavegacao from "../Shared/components/LinkDeNavegacao";
import GrupoDeLinksDeNavegacao from "../Shared/components/GrupoDeLinksDeNavegacao";
import { Authorize } from "../Shared/Authorize";
import { GlobalContext } from "../../context/GlobalContext";
import { LanguageContext } from "../../context/LanguageContext";
import EmpresasERotas from '../../assets/json/empresasERotas.json';

const SidebarMenu = ({ isMobileSidebarOpened, setIsMobileSidebarOpened }) => {
    const { tipodeconta, afiliacao } = useContext(GlobalContext);
    const { getLanguageResource } = useContext(LanguageContext);
    const [imagensDinamicas, setImagensDinamicas] = useState({ logo: '', icon: '', whitelogo: '' });

    const aRotaEstaListadaNoJson = () => {
        // verificar o que está escrito entre http:// ou https:// e .winset.com.br
        const url = window.location.href as string;
        const regex = /https?:\/\/([^.]*)/;
        const match = url.match(regex) ? url.match(regex)[1] : null;

        // transformar o json em array
        const empresasERotas = JSON.parse(JSON.stringify(EmpresasERotas)) as Array<any>;
        if (empresasERotas.length === 0) return false;
        return empresasERotas.some(empresa => match?.includes(empresa.nome));
    }

    const retornaRotasDasImagensDaEmpresa = () => {
        const outraEmpresa = aRotaEstaListadaNoJson();
        const rotaAtual = window.location.href.match(/https?:\/\/([^.]*)/)[1];
        
        if (outraEmpresa) {
            const empresasERotas = JSON.parse(JSON.stringify(EmpresasERotas)) as Array<any>;
            const empresa = empresasERotas.find(empresa => rotaAtual.includes(empresa.nome));
            setImagensDinamicas({ logo: empresa.logo, icon: empresa.icon, whitelogo: empresa.whitelogo });
        }
    }

    useEffect(() => {
        retornaRotasDasImagensDaEmpresa();
    }, []);

    return (
        <div className={`${isMobileSidebarOpened ? '' : 'd-none'} position-absolute d-md-none bg-white w-100`} style={{ zIndex: 200, height: '100vh' }}>
            <div className="d-flex justify-content-between p-2" style={{ backgroundColor: JSON.parse(localStorage.getItem("preferencias")).corDoTopoDaAplicacao }}>
                <img
                    src={aRotaEstaListadaNoJson() ? `${process.env.PUBLIC_URL}${imagensDinamicas.icon}` : process.env.PUBLIC_URL + '/images/icons/icon-winset-white.png'}
                    style={{ width: '3rem', height: '3rem' }}
                    alt="Ícone do Winset"
                />
                <button className="btn">
                    <i className="bi bi-x" style={{ cursor: 'pointer', fontSize: '2rem' }} onClick={() => setIsMobileSidebarOpened(false)} />
                </button>
            </div>
            <div>
                <section>
                    <nav>
                    <div className='w-100'>
                        <LinkDeNavegacao
                            onClick={() => setIsMobileSidebarOpened(false)}
                            aBarraLateralEstaAberta={true}
                            alterarEstadoDaBarraLateralPara={() => {}}
                            texto={getLanguageResource("home")}
                            destino="/"
                        />
                        {
                            parseInt(AuthService.getStorage('id')) === 4
                                ?   <LinkDeNavegacao
                                    onClick={() => setIsMobileSidebarOpened(false)}
                                        aBarraLateralEstaAberta={true}
                                        alterarEstadoDaBarraLateralPara={() => {}}
                                        texto="Dashboard"
                                        destino="/dashboard"
                                    />
                                :   <React.Fragment />
                        }

                        <GrupoDeLinksDeNavegacao
                            aBarraLateralEstaAberta={true}
                            alterarEstadoDaBarraLateralPara={() => {}}
                            texto={getLanguageResource("answerButton")}
                        >
                            <Authorize role="responder">
                                <LinkDeNavegacao
                                    onClick={() => setIsMobileSidebarOpened(false)}
                                    aBarraLateralEstaAberta={true}
                                    alterarEstadoDaBarraLateralPara={() => {}}
                                    texto={getLanguageResource("forms")}
                                    destino='/meusFormularios'
                                />
                                {
                                    afiliacao === null && (
                                        <>
                                            <LinkDeNavegacao
                                                onClick={() => setIsMobileSidebarOpened(false)}
                                                aBarraLateralEstaAberta={true}
                                                alterarEstadoDaBarraLateralPara={() => {}}
                                                texto={getLanguageResource("shareds")}
                                                destino='/formularioCompartilhado'
                                            />
                                            <LinkDeNavegacao
                                                onClick={() => setIsMobileSidebarOpened(false)}
                                                aBarraLateralEstaAberta={true}
                                                alterarEstadoDaBarraLateralPara={() => {}}
                                                texto={getLanguageResource("internal_store")}
                                                destino='/marketPlaceInterno'
                                            />
                                        </>
                                    )
                                }
                                <LinkDeNavegacao
                                    onClick={() => setIsMobileSidebarOpened(false)}
                                    aBarraLateralEstaAberta={true}
                                    alterarEstadoDaBarraLateralPara={() => {}}
                                    texto={getLanguageResource("store")}
                                    destino='/marketPlace'
                                />
                            </Authorize>
                        </GrupoDeLinksDeNavegacao>
                        
                        {
                            tipodeconta === 1
                                ?   afiliacao === null && (
                                        <GrupoDeLinksDeNavegacao
                                            aBarraLateralEstaAberta={true}
                                            alterarEstadoDaBarraLateralPara={() => {}}
                                            texto={getLanguageResource("create")}
                                        >
                                            <Authorize role="manterformulario">
                                                <LinkDeNavegacao
                                                    onClick={() => setIsMobileSidebarOpened(false)}
                                                    aBarraLateralEstaAberta={true}
                                                    alterarEstadoDaBarraLateralPara={() => {}}
                                                    texto={getLanguageResource("form")}
                                                    destino='/formulario'
                                                />
                                            </Authorize>

                                            <Authorize role="manterresposta">
                                                <LinkDeNavegacao
                                                    onClick={() => setIsMobileSidebarOpened(false)}
                                                    aBarraLateralEstaAberta={true}
                                                    alterarEstadoDaBarraLateralPara={() => {}}
                                                    texto={getLanguageResource("response_model")}
                                                    destino='/grupoResposta'
                                                />
                                            </Authorize>

                                            <LinkDeNavegacao
                                                onClick={() => setIsMobileSidebarOpened(false)}
                                                aBarraLateralEstaAberta={true}
                                                alterarEstadoDaBarraLateralPara={() => {}}
                                                texto={getLanguageResource("action_model")}
                                                destino='/modeloPlanoAcao'
                                            />
                                            <LinkDeNavegacao
                                                onClick={() => setIsMobileSidebarOpened(false)}
                                                aBarraLateralEstaAberta={true}
                                                alterarEstadoDaBarraLateralPara={() => {}}
                                                texto={"Modelo de Cadastro"}
                                                destino='/modeloCadastro'
                                            />
                                        </GrupoDeLinksDeNavegacao>
                                    )
                                :   <React.Fragment />
                        }

                        {
                            tipodeconta === 1
                                ?   afiliacao === null && (
                                        <GrupoDeLinksDeNavegacao
                                            aBarraLateralEstaAberta={true}
                                            alterarEstadoDaBarraLateralPara={() => {}}
                                            texto={getLanguageResource("analyze")}
                                        >
                                            <LinkDeNavegacao
                                                onClick={() => setIsMobileSidebarOpened(false)}
                                                aBarraLateralEstaAberta={true}
                                                alterarEstadoDaBarraLateralPara={() => {}}
                                                texto={getLanguageResource("sales_report")}
                                                destino='/vendas'
                                            />
                                            <LinkDeNavegacao
                                                onClick={() => setIsMobileSidebarOpened(false)}
                                                aBarraLateralEstaAberta={true}
                                                alterarEstadoDaBarraLateralPara={() => {}}
                                                texto={getLanguageResource("purchase_report")}
                                                destino='/compras'
                                            />
                                            <LinkDeNavegacao
                                                onClick={() => setIsMobileSidebarOpened(false)}
                                                aBarraLateralEstaAberta={true}
                                                alterarEstadoDaBarraLateralPara={() => {}}
                                                texto={getLanguageResource("response_report")}
                                                destino='/relatorioGeral'
                                            />
                                            <LinkDeNavegacao
                                                onClick={() => setIsMobileSidebarOpened(false)}
                                                aBarraLateralEstaAberta={true}
                                                alterarEstadoDaBarraLateralPara={() => {}}
                                                texto={getLanguageResource("actions_report")}
                                                destino='/relatorioAcoes'
                                            />
                                            <LinkDeNavegacao
                                                onClick={() => setIsMobileSidebarOpened(false)}
                                                aBarraLateralEstaAberta={true}
                                                alterarEstadoDaBarraLateralPara={() => {}}
                                                texto={getLanguageResource("sharing_report")}
                                                destino='/RelatorioFormularioCompartilhado'
                                            />

                                            { AuthService.getStorage('empresasid') == 260 && (
                                                <LinkDeNavegacao
                                                    onClick={() => setIsMobileSidebarOpened(false)}
                                                    aBarraLateralEstaAberta={true}
                                                    alterarEstadoDaBarraLateralPara={() => {}}
                                                    texto={"Rel. da Transportadora JBS"}
                                                    destino='/relatorioGeralTransportadora'
                                                />
                                            )}

                                            {
                                                AuthService.getStorage('id') === 274
                                                    ?   <LinkDeNavegacao
                                                        onClick={() => setIsMobileSidebarOpened(false)}
                                                            aBarraLateralEstaAberta={true}
                                                            alterarEstadoDaBarraLateralPara={() => {}}
                                                            texto={"Dashboard"}
                                                            destino='/dashboardBiMachine'
                                                        />
                                                    :   AuthService.getStorage('id') === 887
                                                        ?   <LinkDeNavegacao
                                                            onClick={() => setIsMobileSidebarOpened(false)}
                                                                aBarraLateralEstaAberta={true}
                                                                alterarEstadoDaBarraLateralPara={() => {}}
                                                                texto={"Dashboard"}
                                                                destino='/dashboardBiMachine'
                                                            />
                                                        :   AuthService.getStorage('id') === 44
                                                            ?   <LinkDeNavegacao
                                                                onClick={() => setIsMobileSidebarOpened(false)}
                                                                    aBarraLateralEstaAberta={true}
                                                                    alterarEstadoDaBarraLateralPara={() => {}}
                                                                    destino='/dashboardBiMachine'
                                                                />
                                                            :   <React.Fragment />
                                            }

                                            { AuthService.getStorage('empresasid') === 4 && (
                                                <LinkDeNavegacao
                                                    onClick={() => setIsMobileSidebarOpened(false)}
                                                    aBarraLateralEstaAberta={true}
                                                    alterarEstadoDaBarraLateralPara={() => {}}
                                                    texto={"Dashboard"}
                                                    destino='/dashboardBiPowerBI'
                                                />
                                            )}

                                            {
                                                AuthService.getStorage('empresasid') === 608 
                                                    ?   <LinkDeNavegacao
                                                        onClick={() => setIsMobileSidebarOpened(false)}
                                                            aBarraLateralEstaAberta={true}
                                                            alterarEstadoDaBarraLateralPara={() => {}}
                                                            texto={"Dashboard"}
                                                            destino='/dashboardBiMachineComercialGRX'
                                                        />
                                                    :   AuthService.getStorage('empresasid') === 611
                                                        ?   <LinkDeNavegacao
                                                            onClick={() => setIsMobileSidebarOpened(false)}
                                                                aBarraLateralEstaAberta={true}
                                                                alterarEstadoDaBarraLateralPara={() => {}}
                                                                texto={"Dashboard"}
                                                                destino='/dashboardBiMachineComercialGRX'
                                                            />
                                                        :   AuthService.getStorage('empresasid') === 615
                                                            ?   <LinkDeNavegacao
                                                                onClick={() => setIsMobileSidebarOpened(false)}
                                                                    aBarraLateralEstaAberta={true}
                                                                    alterarEstadoDaBarraLateralPara={() => {}}
                                                                    texto={"Dashboard"}
                                                                    destino='/dashboardBiMachineComercialGRX'
                                                                />
                                                            :   AuthService.getStorage('empresasid') === 627
                                                                ?   <LinkDeNavegacao
                                                                    onClick={() => setIsMobileSidebarOpened(false)}
                                                                        aBarraLateralEstaAberta={true}
                                                                        alterarEstadoDaBarraLateralPara={() => {}}
                                                                        texto={"Dashboard"}
                                                                        destino='/dashboardBiMachineComercialGRX'
                                                                    />
                                                                :   <React.Fragment />
                                            }

                                            { AuthService.getStorage('empresasid') === 397 && (
                                                <LinkDeNavegacao
                                                    onClick={() => setIsMobileSidebarOpened(false)}
                                                    aBarraLateralEstaAberta={true}
                                                    alterarEstadoDaBarraLateralPara={() => {}}
                                                    texto={"Dashboard"}
                                                    destino='/dashboardOperacao'
                                                    />
                                                )}
                                        </GrupoDeLinksDeNavegacao>
                                    )
                                :   <React.Fragment />
                        }

                        {
                            afiliacao === null && (
                                <LinkDeNavegacao
                                    onClick={() => setIsMobileSidebarOpened(false)}
                                    aBarraLateralEstaAberta={true}
                                    alterarEstadoDaBarraLateralPara={() => {}}
                                    texto={getLanguageResource("to_document")}
                                    destino='/repositorio/0'
                                />
                            )
                        }

                        <GrupoDeLinksDeNavegacao
                            aBarraLateralEstaAberta={true}
                            alterarEstadoDaBarraLateralPara={() => {}}
                            texto={getLanguageResource("register")}
                        >
                            {
                                tipodeconta === 1
                                    ?   afiliacao === null && (
                                            <React.Fragment>
                                                <Authorize role="manterperfil">
                                                    <LinkDeNavegacao
                                                        onClick={() => setIsMobileSidebarOpened(false)}
                                                        aBarraLateralEstaAberta={true}
                                                        alterarEstadoDaBarraLateralPara={() => {}}
                                                        texto={getLanguageResource("profile")}
                                                        destino='/perfil'
                                                    />
                                                </Authorize>

                                                <Authorize role="mantersetor">
                                                    <LinkDeNavegacao
                                                        onClick={() => setIsMobileSidebarOpened(false)}
                                                        aBarraLateralEstaAberta={true}
                                                        alterarEstadoDaBarraLateralPara={() => {}}
                                                        texto={getLanguageResource("sector")}
                                                        destino='/setor'
                                                    />
                                                </Authorize>

                                                <Authorize role="manterfuncao">
                                                    <LinkDeNavegacao
                                                        onClick={() => setIsMobileSidebarOpened(false)}
                                                        aBarraLateralEstaAberta={true}
                                                        alterarEstadoDaBarraLateralPara={() => {}}
                                                        texto={getLanguageResource("office")}
                                                        destino='/funcao'
                                                    />
                                                </Authorize>
                                            </React.Fragment>
                                        )
                                    :   <React.Fragment />
                            }

                            <Authorize role="manterusuario">
                                <LinkDeNavegacao
                                    onClick={() => setIsMobileSidebarOpened(false)}
                                    aBarraLateralEstaAberta={true}
                                    alterarEstadoDaBarraLateralPara={() => {}}
                                    texto={getLanguageResource("user")}
                                    destino='/usuarios'
                                />
                            </Authorize>
                        </GrupoDeLinksDeNavegacao>
                    </div>
                    </nav>
                </section>
                <hr />
                <section>
                    <LinkDeNavegacao
                        onClick={() => setIsMobileSidebarOpened(false)}
                        aBarraLateralEstaAberta={true}
                        alterarEstadoDaBarraLateralPara={() => {}}
                        texto={getLanguageResource("my_account")}
                        destino='/minhaConta'
                    />
                    
                    <LinkDeNavegacao
                        onClick={() => setIsMobileSidebarOpened(false)}
                        aBarraLateralEstaAberta={true}
                        alterarEstadoDaBarraLateralPara={() => {}}
                        texto={getLanguageResource("my_company")}
                        destino='/minhaEmpresa'
                    />

                    <GrupoDeLinksDeNavegacao
                        aBarraLateralEstaAberta={true}
                        alterarEstadoDaBarraLateralPara={() => {}}
                        texto={getLanguageResource("language")}
                    >
                        <LinkDeNavegacao
                            onClick={() => setIsMobileSidebarOpened(false)}
                            aBarraLateralEstaAberta={true}
                            alterarEstadoDaBarraLateralPara={() => {}}
                            texto={getLanguageResource("portuguese")}
                            destino='?lang=pt-br'
                        />
                        <LinkDeNavegacao
                            onClick={() => setIsMobileSidebarOpened(false)}
                            aBarraLateralEstaAberta={true}
                            alterarEstadoDaBarraLateralPara={() => {}}
                            texto={getLanguageResource("english")}
                            destino='?lang=en-us'
                        />
                        <LinkDeNavegacao
                                onClick={() => setIsMobileSidebarOpened(false)}
                            aBarraLateralEstaAberta={true}
                            alterarEstadoDaBarraLateralPara={() => {}}
                            texto={getLanguageResource("spanish")}
                            destino='?lang=es'
                        />
                    </GrupoDeLinksDeNavegacao>
                    
                    <LinkDeNavegacao
                        onClick={() => setIsMobileSidebarOpened(false)}
                        aBarraLateralEstaAberta={true}
                        alterarEstadoDaBarraLateralPara={() => {}}
                        texto={getLanguageResource("log_out")}
                        destino='/login'
                    />
                </section>
            </div>
        </div>
    );
};

export default SidebarMenu;
