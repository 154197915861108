import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { UsuarioData } from './FetchUsuarios';
import { Layout } from '../Layout';
import { HttpService } from '../Shared/HttpService';
import { HeadLayout } from '../Shared/HeadLayout';
import { Loading } from '../Shared/Loading';
import { AuthService } from '../UserAuth/AuthService';
import { ValidationLabelMessage, ValidationAlertMessage } from '../Shared/ValidationMessage';
import { UserData } from '../User/FetchUser';
import { AddRegra } from './AddRegra';
import $ from 'jquery';
import { ListaRegraVinculada } from './ListaRegraVinculada';
import { ChangePassword } from '../UserAuth/ChangePassword';
import { Resource } from '../../index';
import { LanguageContext } from '../../context/LanguageContext';
import CaixaDeConteudo from '../Shared/CaixaDeConteudo';

interface AddUserDataState {
    loading: boolean;
    empData: UsuarioData;
}

export class AddUsuarios extends React.Component<RouteComponentProps<{}>, AddUserDataState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        // This will set state for Edit User  
        this.state = { loading: false, empData: new UsuarioData };

        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    
    private botoesDoCantoSuperiorDireito() {
        return (
            <React.Fragment>
                <button className="btn bg-white shadow-sm mr-2" onClick={this.handleCancel}>{<Resource keyString="back" />}</button>
            </React.Fragment>
        );
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;

        return (
            <CaixaDeConteudo
                tituloDoHeader={this.context.getLanguageResource("creation_users")}
                botoes={this.botoesDoCantoSuperiorDireito()}
                itensParaBreadcrumb={[
                    { url: "/", nome: this.context.getLanguageResource("home") },
                    { url: "/usuarios", nome: this.context.getLanguageResource("user_registration") },
                    { nome: this.context.getLanguageResource("creation_users") }
                ]}
            >
                <main className='p-4'>
                    <form onSubmit={this.handleSave} autoComplete="off">
                        <div className="form-group row" >
                            <input type="hidden" name="Id" value={this.state.empData.id} />
                            <input type="hidden" name="Empresasid" value={this.state.empData.id} />
                        </div>
                        < div className="form-group row" >
                            <div className="col-md-6" >
                                <label className=" control-label col-md-12 required" htmlFor="Nome">{<Resource keyString="username" />}</label>
                                <div className="col-md-12">
                                    <ValidationLabelMessage errors={this.state.empData.error} name="Nome">
                                        <input className="form-control" type="text" name="Nome" id="Nome" defaultValue={this.state.empData.nome} />
                                    </ValidationLabelMessage>
                                </div>
                            </div >
                            <div className="col-md-6" >
                                <label className="control-label col-md-12" htmlFor="Documento" >{<Resource keyString="user_cpf" />}</label>
                                <div className="col-md-12">
                                    <input className="form-control cpf" type="text" name="Documento" id="Documento" defaultValue={this.state.empData.documento} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6">
                                <label className="control-label col-md-12 required" htmlFor="Email" >{<Resource keyString="system_access_email" />}</label>
                                <div className="col-md-12">
                                    <ValidationLabelMessage errors={this.state.empData.error} name="Email">
                                        <input className="form-control" type="email" name="Email" id="Email" defaultValue={this.state.empData.email} />
                                    </ValidationLabelMessage>
                                </div>
                            </div>
                            <div className="col-md-6" >
                                <label className=" control-label col-md-12 required" htmlFor="Senha">{<Resource keyString="system_access_password" />}</label>
                                <div className="col-md-12">
                                    <ValidationLabelMessage errors={this.state.empData.error} name="Senha">
                                        <input className="form-control" type="password" name="Senha" id="Senha" defaultValue={this.state.empData.senha} />
                                    </ValidationLabelMessage>
                                </div>
                            </div >
                        </div>
                        <div className="form-group col-md-12" >
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" name="Isactive" id="Isactive" defaultChecked={this.state.empData.isactive} />
                                <label className="form-check-label" htmlFor="Isactive">{<Resource keyString="this_user_is_active" />}</label>
                            </div>
                        </div>
                        <div className="form-group col-md-12 d-flex justify-content-center">
                            <button type="submit" className="btn font-weight-bold" style={{ backgroundColor: 'var(--ws-blue)', width: '17.5rem' }}>{<Resource keyString="save" />}</button>
                        </div >
                    </form >
                </main>
            </CaixaDeConteudo>
        );
    }

    // This will handle the submit form event.  
    private handleSave(event) {
        event.preventDefault();

        Loading.showLoading(this);

        HttpService.fetch('api/UsuariosApi', {
            method: 'POST',
            body: HttpService.formToJson(event.target),
        }).then((response) => {
            return response.json() as Promise<UsuarioData>
        }).then((data) => {
            this.setState({ empData: data });

            if ($.isEmptyObject(this.state.empData.error)) {
                this.redirectPage();
            }

            Loading.hideLoading(this);
        }).catch(() => {
            Loading.hideLoading(this);
        });
    }

    // This will handle Cancel button click event.  
    private handleCancel(e) {
        e.preventDefault();
        this.redirectPage();
    }

    private redirectPage() {
        this.props.history.push("/usuarios");
    }
}  