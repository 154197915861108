import * as React from 'react';
import { LanguageContext } from '../../context/LanguageContext';

export class BotaoImprimir extends React.Component<any, any> {
    static contextType = LanguageContext;
    
    public render() {
        return (
            <React.Fragment>
                <button className="btn ws-outline-blue mr-1" id="btnImprimir" style={ { height: '3rem', minWidth: '3rem' } } title={ this.context.getLanguageResource("print") } onClick={() => window.print() }>
                    <i className="fa fa-print" style={ { fontSize: '1.2rem', lineHeight: '1.2rem' } } aria-hidden="true" />
                </button>
            </React.Fragment>
        )
    }

}  