import * as React from 'react';
import { ResponderFormularioContext } from '../../contexts/ResponderFormularioContext';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import ExclamationCircleIcon from '../../icons/ExclamationCircleIcon';
import { IOpcaoDeCabecalho } from '../../interfaces/IResponderFormularioResponse';

const { useContext, useState } = React;

interface IProps {
    type: string;
    id: string;
    rodape?: boolean;
    camposMultiplaEscolha: IOpcaoDeCabecalho[];
    permitirEditar: boolean;
}

const CampoDeCabecalho = ({ type, id, rodape, camposMultiplaEscolha, permitirEditar }: IProps) => {
    const cabecalhoOuRodape = rodape ? 'rodapes' : 'cabecalhos';
    const [classeDeFeedbackDePreenchimento, setClasseDeFeedbackDePreenchimento] = useState('');
    const { responderFormulario, setResponderFormulario, ultimaTentativaDeFinalizar } = useContext(ResponderFormularioContext);
    let tipo = "";

    switch (type) {
        case '1':
            tipo = "text";
            break;
        case '2':
            tipo = "date";
            break;
        case '3':
            tipo = "number";
            break;
        case '4':
            tipo = "time";
            break;
        case '5':
            tipo = "select";
            break;
        default:
            tipo = "help";
            break;
    }

    const responderFormularioAtualizado = { ...responderFormulario };
    const cabecalhoOuRodapeAtual = responderFormularioAtualizado[cabecalhoOuRodape]
        .find(itemDeCabecalhoOuRodape => itemDeCabecalhoOuRodape.id.toString() === id);

    const handleInputValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (cabecalhoOuRodapeAtual) {
            cabecalhoOuRodapeAtual.valor = value;
            event.target.classList.remove('is-invalid');
            setClasseDeFeedbackDePreenchimento('');
            setResponderFormulario({ ...responderFormularioAtualizado });
        }
    };

    const handleSelectValue = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        if (cabecalhoOuRodapeAtual) {
            cabecalhoOuRodapeAtual.valor = value;
            event.target.classList.remove('is-invalid');
            setClasseDeFeedbackDePreenchimento('');
            setResponderFormulario({ ...responderFormularioAtualizado });
        }
    };

    useDidUpdateEffect(() => {
        const cabecalhoEstaPreenchido = cabecalhoOuRodapeAtual && cabecalhoOuRodapeAtual.valor && cabecalhoOuRodapeAtual.valor.length === 0 || !cabecalhoOuRodapeAtual?.valor;
        if (cabecalhoEstaPreenchido && cabecalhoOuRodapeAtual?.cabecalhoObrigatorio) {
            setClasseDeFeedbackDePreenchimento('is-invalid');
        }
    }, [ultimaTentativaDeFinalizar]);

    if (!cabecalhoOuRodapeAtual) return <React.Fragment />;

    const opcoesCabecalho = (camposMultiplaEscolha).map((opcao, i) =>
        <option value={opcao.opcoesDeEcolha}>{opcao.opcoesDeEcolha}</option>
    );
    
    if (permitirEditar) return (
        <div className="form-group col-sm-6">
            <label
                htmlFor={id}
            >
                {
                    cabecalhoOuRodapeAtual.cabecalhoObrigatorio
                        ? <strong>{cabecalhoOuRodapeAtual.titulo}</strong>
                        : cabecalhoOuRodapeAtual.titulo
                }
                {
                    (!cabecalhoOuRodapeAtual.valor || cabecalhoOuRodapeAtual.valor.length === 0) && cabecalhoOuRodapeAtual.cabecalhoObrigatorio
                        ? <ExclamationCircleIcon fill='#ef0000' className='ml-2 mb-2' />
                        : <React.Fragment />
                }
            </label>
            {
                tipo === "help"
                    ?   <div>{cabecalhoOuRodapeAtual.ajuda}</div>
                    :   tipo === "select"
                        ?   <select className="form-control" onChange={handleSelectValue} defaultValue={cabecalhoOuRodapeAtual.valor ? cabecalhoOuRodapeAtual.valor : ''}>
                                <option value="">Selecione</option>
                                {opcoesCabecalho}
                            </select>
                        :   <input
                                type={tipo}
                                className={`form-control ${classeDeFeedbackDePreenchimento}`}
                                id={id}
                                name={id}
                                value={cabecalhoOuRodapeAtual.valor ? cabecalhoOuRodapeAtual.valor : ''}
                                onChange={handleInputValue}
                                { ...tipo === 'date' ? { max: '9999-12-31' } : {} }
                            />
            }
        </div>
    );

    return (
        <div className="form-group col-sm-6">
            <div className='shadow-sm d-flex flex-column justify-content-start align-items-center px-3 py-2 border rounded h-100' style={{ backgroundColor: '#e9f4f7' }}>
                <label className='text-muted text-break'>{cabecalhoOuRodapeAtual.titulo}</label>
                <div className='w-100 text-break'>{tipo === 'help' ? cabecalhoOuRodapeAtual.ajuda : cabecalhoOuRodapeAtual.valor}</div>
            </div>
        </div>
    );
}

export default CampoDeCabecalho;
