function formatarCNPJ(cnpj: string): string {
    // Remover caracteres não numéricos
    cnpj = cnpj.replace(/[^\d]/g, '');

    if (cnpj.length <= 2) {
        // Não aplicar máscara
    } else if (cnpj.length <= 5) {
        cnpj = cnpj.replace(/^(\d{2})(\d{0,3})?/, '$1.$2');
    } else if (cnpj.length <= 8) {
        cnpj = cnpj.replace(/^(\d{2})(\d{0,3})?(\d{0,3})?/, '$1.$2.$3');
    } else if (cnpj.length <= 12) {
        cnpj = cnpj.replace(/^(\d{2})(\d{0,3})?(\d{0,3})?(\d{0,4})?/, '$1.$2.$3/$4');
    } else if (cnpj.length <= 14) {
        cnpj = cnpj.replace(/^(\d{2})(\d{0,3})?(\d{0,3})?(\d{0,4})?(\d{0,2})?(\d*)/, '$1.$2.$3/$4-$5$6');
    }

    return cnpj;
};

export default formatarCNPJ;