import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { Layout } from '../../Layout';
import { HttpService } from '../../Shared/HttpService';
import { HeadLayout } from '../../Shared/HeadLayout';
import { Loading } from '../../Shared/Loading';
import { RespostaData } from '../Resposta/FetchResposta';
import { GrupoRespostaData } from '../GrupoResposta/FetchGrupoResposta';
import { PerguntaData } from '../Pergunta/FetchPergunta';
import { ResultadoData } from './ResponderFormulario';
import { GrupoPerguntaData } from '../GrupoPergunta/FetchGrupoPergunta';
import { FormularioData } from './FetchFormulario';
import { PlanoAcaoData } from '../PlanoAcao/FetchPlanoAcao';
import { CompartilharFormulario } from './CompartilharFormulario';
import { AuthService } from '../../UserAuth/AuthService';
import { Resource } from '../../../index';
import { SetorData } from '../ConfiguracaoFormulario/AddConfiguracaoFormulario';
import { Util } from '../../Shared/Util';
import { BotaoImprimir } from '../../Shared/BotaoImprimir';
import { LanguageContext } from '../../../context/LanguageContext';

interface FetchGrupoRespostaState {
    respostas: ResultadoGeral;
    loading: boolean;
    formularioid: number;
    formulario: FormularioData;
    hash: string;
}

class FormularioIniciadoData {
    hash: string = "";
    titulo: string = "";
    formularioid: number = 0;
    configuracaoformularioid: number = 0;
    rascunho: boolean = true;
    datecreated: Date = new Date();
    isresultadoparcial: boolean = false;
}

class ConsolidadoResposta {
    quantidade: number = 0;
    peso: number = 0;
    nome: string = "";
}

class ResultadoXCabecalho {
    id: number = 0;
    nome: string = "";
    valor: string = "";
}

export class ResultadoXArquivo {
    id: number = 0;
    perguntaid: number = 0;
    caminhovirtual: string = "";
    setorid: number = 0;
}

class ResultadoGeral {
    resultadogrupopergunta: Resultado[] = [];
    resultadoformulario: Resultado = new Resultado();
    resultadoagrupadoporgrupo: ResultadoAgrupadoPorGrupoPergunta[] = [];
    resultadoplanoacao: PlanoAcaoData[] = [];
    formularioiniciado: FormularioIniciadoData = new FormularioIniciadoData();
    resultadoxcabecalho: ResultadoXCabecalho[] = [];
    resultadoxarquivos: ResultadoXArquivo[] = [];
}

class ResultadoAgrupadoPorGrupoPergunta {
    pontuacao: number = 0;
    pontuacaoMax: number = 0;
    grupoPergunta: GrupoPerguntaData = new GrupoPerguntaData();
    respostas: ResultadoData[] = [];
    setor: SetorData = new SetorData();
}

class Resultado {
    id: number = 0;
    situacao: boolean = false;
    situacaopontos: boolean = false;
    valor: number = 0;
    toleranciapontos: number = 0;
    toleranciaporcentagem: number = 0;
    tolerancia: number = 0;
    valorporcentagem: number = 0;
    pontuacaototal: number = 0;
    hash: string = "";
    datecreated: string = "";
    grupoPergunta: GrupoPerguntaData = new GrupoPerguntaData();
    consolidadoResposta: ConsolidadoResposta[] = [];
    notaPelaFormulaResultado: string = ""
}

export class FormularioRespondidoFormula extends React.Component<RouteComponentProps<{}>, FetchGrupoRespostaState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        var formularioid = this.props.match.params["formularioid"];
        this.state = {
            respostas: new ResultadoGeral(), loading: true,
            formularioid: 0, formulario: new FormularioData(), hash: formularioid
        };

        Loading.showLoading(this);
        HttpService.fetch(`api/ResultadoApi/All/${formularioid}`, {})
            .then(response => response.json() as Promise<ResultadoGeral>)
            .then(data => {
                this.setState({ respostas: data, loading: true, formularioid: data.resultadoagrupadoporgrupo.length > 0 ? data.resultadoagrupadoporgrupo[0].respostas[0].formularioid : 0 });

                if (this.state.formularioid > 0) {

                    HttpService.fetch('api/FormularioAPI/' + this.state.formularioid + '?hash=' + this.state.hash + '&situacao=respondido', { method: 'GET' })
                        .then(response => response.json() as Promise<FormularioData>)
                        .then(data => {
                            this.setState({ loading: false, formulario: data });
                            Loading.hideLoading(this);
                        }).catch((e) => {
                            Loading.hideLoading(this);
                            props.history.push(`/marketPlace`);
                        });
                }
            }).catch((e) => {
                Loading.hideLoading(this);
                props.history.push(`/marketPlace`);
            });
    }

    public render() {
        let contents = Loading.isLoading(this)
            ? <p><em></em></p>
            : this.renderUserTable(this.state.respostas);

        return <div className="col-lg-12">
            <HeadLayout title={this.context.getLanguageResource("answered_form")} />
            <div className="card">
                <div className="card-header hide-print"><a href="/">{<Resource keyString="home" />}</a> / <a href="/">{<Resource keyString="audit_configuration" />}</a> / <a href={`/marketPlace/detail/${this.state.formularioid}`}>{<Resource keyString="forms" />}</a> / {<Resource keyString="answered_form" />}</div>
                <div className="card-body">
                    <div className="card-title">
                        <button className="btn btn-link mr-2 pull-left hide-print"
                            onClick={(x) => this.props.history.push(`/marketPlace/detail/${this.state.formularioid}`)}>
                            <i className='fa fa-arrow-left'></i> {<Resource keyString="back" />}
                        </button>
                        {(this.state.respostas.formularioiniciado.rascunho == true)
                            ?
                            <h3 className="text-center title-2">Formulário Respondido Parcialmente</h3>
                            :
                            <h3 className="text-center title-2">{<Resource keyString="answered_form" />}</h3>
                        }
                    </div>
                    <hr />
                    {contents}
                </div>
            </div>
        </div>;
    }

    private renderArquivos(perguntaId, setorId) {
        let resultadoXArquivo = this.state.respostas.resultadoxarquivos.map((resultado, index) => {
            if (resultado.perguntaid == perguntaId &&
                ((setorId > 0 && resultado.setorid == setorId)
                    || (!(setorId > 0) && !(resultado.setorid > 0)))) {
                return <div className="btn-group mr-2" role="group" aria-label="First group">
                    <div key={"arquivo" + resultado.id + index}>
                        <div className="btn-group">
                            <img src={"api/ResultadoXArquivosApi/DownloadFile/" + resultado.caminhovirtual} width="50" />
                        </div>
                    </div>
                </div>
            }
        }
        );
        return resultadoXArquivo;
    }

    // Returns the HTML table to the render() method.  
    private renderUserTable(respostas: ResultadoGeral) {

        /*if (this.state.processado == false) {
            var valoresDasRespostasTemp = new Array<ConsolidadoResposta>();
            respostas.resultadoagrupadoporgrupo.map((doc, index) =>
                doc.respostas.map(resposta => {
                    if (!valoresDasRespostasTemp.some(x => x.nome == resposta.resposta)) {
                        valoresDasRespostasTemp.push({ nome: resposta.resposta, quantidade: 0 });
                    }
                    valoresDasRespostasTemp.filter(x => x.nome == resposta.resposta)[0].quantidade++;
                })
            );
            this.setState({ valoresDasRespostas: valoresDasRespostasTemp, processado: true });
            return;
        }*/

        let resultadoXCabecalho = respostas.resultadoxcabecalho.map((obj, index) =>
            <div className="row card-row" key={obj.id}>
                <div className="col-md-4 col-sm-3 col-xs-4 card-catergory">
                    <span className="Details">
                        <strong>({obj.nome})</strong>
                    </span>
                </div>
                <div className="col-md-8 col-sm-9 col-xs-8 card-data">
                    <span className="earnings-descgroup pull-right">
                        {obj.valor}
                    </span>
                </div>
            </div>
        );


        var a = AuthService.getStorage('empresasid');
        return <div className="accordion" id="accordion">

            <div className="col-md-12 hide-print">
                <BotaoImprimir />
                {
                    (this.state.formulario.empresaQuePossuiOFormularioId == AuthService.getStorage('empresasid')) ?

                        <React.Fragment>
                            <button className="btn btn-outline-primary mx-1" data-toggle="modal" data-target="#CompartilharFormulario">{<Resource keyString="share" />}</button>
                            <CompartilharFormulario formulario={this.state.formulario} hash={this.state.hash}></CompartilharFormulario>

                        </React.Fragment>
                        :
                        <React.Fragment>
                        </React.Fragment>
                }
            </div>

            <div className="col-lg-12">

                <div className="border-left border-bottom border-right clearfix">

                    <div className="row card-row">
                        <div className="col-sm-3 col-md-3 text-left name-date">
                            <div className="guest-name">
                                {this.state.formulario.nome}
                            </div>
                            <div className="guest-name">
                                <small>{this.state.respostas.formularioiniciado.titulo != null ? this.state.respostas.formularioiniciado.titulo : this.state.respostas.formularioiniciado.hash}</small>
                            </div>
                            <div className="date-range d-none">
                                <i className='fa fa-calendar'></i> {new Date(this.state.respostas.resultadoformulario.datecreated).toLocaleDateString() + " " + new Date(this.state.respostas.resultadoformulario.datecreated).toLocaleTimeString()}
                            </div>
                        </div>

                        <div className="col-sm-3 col-md-3 row">
                            <h2 className="text-center col-sm-12">
                                {this.state.respostas.resultadoformulario.pontuacaototal > 0 ? <span>{this.state.respostas.resultadoformulario.valor}/{this.state.respostas.resultadoformulario.pontuacaototal}</span> : '0'}
                            </h2>
                            <span className="col-sm-12 text-center mt-0 pt-0 mb-0 pb-0">
                                Consolidado da Pontuação
                            </span>
                        </div>

                        <div className="col-sm-3 col-md-3 row">
                            <h2 className="text-center col-sm-12">
                                {this.state.respostas.resultadoformulario.valorporcentagem.toFixed(2)}%
                            </h2>
                            <span className="col-sm-12 text-center mt-0 pt-0 mb-0 pb-0">
                                Consolidado da Porcentagem
                            </span>
                        </div>

                        <div className="col-sm-3 col-md-3 row">
                            {this.state.respostas.resultadoformulario.notaPelaFormulaResultado != null ?
                                <React.Fragment>
                                    <h2 className="text-center col-sm-12">
                                        {this.state.respostas.resultadoformulario.notaPelaFormulaResultado}
                                    </h2>
                                    <span className="col-sm-12 text-center mt-0 pt-0 mb-0 pb-0">
                                        Resultado
                                    </span>
                                </React.Fragment> :
                                null
                            }
                        </div>
                    </div>

                    <div className="row card-row">
                        <div className="bg-light p-2 border col-sm-12" style={{ cursor: 'pointer' }}
                            id={`heading-contagem-resposta`} onClick={() => Util.toggleCollapse(`#collapse-contagem-resposta`)}>
                            <strong>Contagem das respostas</strong>
                        </div>

                        <div id={`collapse-contagem-resposta`} className="border col-sm-12 d-none">
                            {this.state.respostas.resultadoformulario.consolidadoResposta.map((o, i) =>
                                <div className="row card-row" key={i}>
                                    <div className="col-md-3 col-sm-3 col-xs-4 card-catergory">
                                        <span className="Details">
                                            {o.nome} (Peso: {o.peso})
                                </span>
                                    </div>
                                    <div className="col-md-9 col-sm-9 col-xs-8 card-data">
                                        <span className="earnings-descgroup pull-right">
                                            {o.quantidade}
                                        </span>
                                    </div>
                                </div>
                            )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <hr />

            <div className="col-lg-12">

                <div className="border-left border-bottom border-right clearfix">

                    <div className="row card-row">
                        <div className="col-sm-3 col-md-4 text-left name-date">
                            <div className="guest-name">
                                Cabeçalhos
                            </div>
                        </div>
                    </div>

                    {resultadoXCabecalho}

                </div>
            </div>  

            <hr />
            {respostas.resultadoagrupadoporgrupo.map((doc, index) =>
                <div className="mb-1" key={doc.grupoPergunta.id} style={{ pageBreakInside: 'avoid' }} >
                    <div className="bg-light p-2 border" style={{ cursor: 'pointer' }}
                        id={`heading-${index}`} onClick={() => Util.toggleCollapse(`#collapse-${index}`)}>
                        <strong>[{doc.setor && doc.setor.id > 0 ? doc.setor.nome : "-"}] </strong> {doc.grupoPergunta.nome} {doc.pontuacaoMax == 0 || doc.pontuacao == null ? <strong>(NA)</strong> : <strong>({<Resource keyString="punctuation" />}: {((100 * doc.pontuacao) / doc.pontuacaoMax).toFixed(2)}% )</strong>}
                    </div>

                    <div id={`collapse-${index}`} className="border d-none" >
                        {doc.respostas.map(resposta =>
                            <div className="border p-1 mb-1" key={doc.grupoPergunta.id}>
                                <h5 className="">{resposta.pergunta.ordenacao} - {resposta.pergunta.titulo} {(resposta.ko) ? <span className="bg-danger p-1">KO</span> : ""}</h5>
                                <h6 className="mt-2 mb-2">{<Resource keyString="response_value" />}: <span className="bg-warning p-1">{resposta.resposta}</span> {resposta.valor != null ? <span>({resposta.valor})</span> : ''}</h6>
                                <h6 className="mt-2 mb-2">Respondido Por: <span className="p-1">{resposta.usuario.nome}</span></h6>
                                <h6 className="mt-2 mb-2">{<Resource keyString="observation" />}: <span className="p-1">{resposta.observacao}</span></h6>
                                <p className="">{resposta.pergunta.descricao}</p>

                                {resposta.documentos != null ?
                                    resposta.documentos.map(documento =>
                                        <div className="btn-group mr-2 mt-2">
                                            <div>
                                                <div className="btn-group">
                                                    <button type="button" className="btn btn-square btn-light" title={documento.nome}><i className='fa fa-file'></i> {documento.nome} {documento.arquivovigente != null && documento.arquivovigente.id > 0 ? `(Versão: ${documento.arquivovigente.versao}) ` : ""}</button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    : ""}

                                {this.renderArquivos(resposta.pergunta.id, resposta.setorid)}

                                {respostas.resultadoplanoacao != null && respostas.resultadoplanoacao.some(e => e.perguntaid == resposta.perguntaid) ?

                                    <div className="row mt-2 justify-content-center">
                                        <div className="col-sm-11 border bg-secondary text-white"><strong>{<Resource keyString="actions" />}</strong></div>
                                        {respostas.resultadoplanoacao.map(acao => {
                                            if (acao.perguntaid == resposta.perguntaid) {
                                                return <div className="mt-2 col-sm-12">
                                                    <div className="row justify-content-center">
                                                        <div className="col-sm-11 border">
                                                            <div className="row">
                                                                <div className="col-sm-6 border"><strong>{<Resource keyString="what" />}:</strong> {acao.oque}</div>
                                                                <div className="col-sm-6 border"><strong>{<Resource keyString="why" />}:</strong> {acao.porque}</div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-6 border"><strong>{<Resource keyString="where" />}:</strong> {acao.onde}</div>
                                                                <div className="col-sm-6 border"><strong>{<Resource keyString="how" />}:</strong> {acao.como}</div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-4 border"><strong>{<Resource keyString="who" />}:</strong> {acao.quem}</div>
                                                                <div className="col-sm-4 border"><strong>{<Resource keyString="when" />}:</strong> {acao.quando}</div>
                                                                <div className="col-sm-4 border"><strong>{<Resource keyString="how_much" />}:</strong> {acao.quantocusta}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            } else {
                                                return "";
                                            }
                                        })}
                                    </div>
                                    : ""}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>;
    }
}   