import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { Layout } from '../../Layout';
import { HttpService } from '../../Shared/HttpService';
import { HeadLayout } from '../../Shared/HeadLayout';
import { Loading } from '../../Shared/Loading';
import { ModalDelete } from '../../Shared/ModalDelete';
import { Resource } from '../../../index';
import { AddCampoModeloAcao } from './AddCampoModeloAcao';
import { LanguageContext } from '../../../context/LanguageContext';
import CaixaDeConteudo from '../../Shared/CaixaDeConteudo';

interface FetchCampoModeloAcaoState {
    campoModeloAcaoList: CampoModeloAcaoData[];
    loading: boolean; 
    modeloPlanoAcaoid: number;
    campomodeloPlanoAcaoid: number;
}

interface ICampoModeloAcaoToggle {
    id: number;
    campoModeloAcao_Id: number;
    valorPadrao: boolean;
    valorQuandoInativo: string;
    valorQuandoAtivo: string;
}

export class CampoModeloAcaoData {
    id: number = 0;
    nome: string = "";
    modeloacao_id: number = 0;
    formatocampomodeloacao_id: number = 0;
    ordenacao: string = "";
    obrigatorio: boolean = true;
    empresasid: number = 0;
    isactive: boolean = true;
    datecreated: string = "";
    usercreatedid: number = 0;
    datemodified: string = "";
    usermodifiedid: number = 0;
    camposmultiplicaescolha: OpcaoCampoMultiplaEscolhaData[] = [];
    formatocampomodeloacao: FormatoCampoModeloAcaoData = new FormatoCampoModeloAcaoData;
    error: Array<any> = [];
    fixo: boolean = false;
    campoModeloAcaoToggle: ICampoModeloAcaoToggle = new CampoModeloAcaoToggle;
    valorAtivoToggle: string = "";
    valorInativoToggle: string = "";
    valorPadraoToggle: boolean = false;
    isChecked: boolean = false;
}

export class CampoModeloAcaoToggle {
    id: number = 0;
    campoModeloAcao_Id: number = 0;
    valorPadrao: boolean = false;
    valorQuandoInativo: string = "";
    valorQuandoAtivo: string = "";
}

export class OpcaoCampoMultiplaEscolhaData {
    id: number = 0;
    nome: string = "";
    isactive: boolean = true;
    //error: Array<any> = [];
}

export class FormatoCampoModeloAcaoData {
    id: number = 0;
    nome: string = "";
    isactive: boolean = true;
}

export class FetchCampoModeloAcao extends React.Component<any, FetchCampoModeloAcaoState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = {
            campoModeloAcaoList: [],
            loading: true,
            modeloPlanoAcaoid: props.modeloPlanoAcaoid,
            campomodeloPlanoAcaoid: 0
        };
         
        this.carregaModeloPlanoAcao(); 
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;
        return this.renderUserTable(this.state.campoModeloAcaoList);
    }

    public carregaModeloPlanoAcao() {
        Loading.showLoading(this);
        HttpService.fetch('api/CampoModeloAcaoApi/Listar/' + this.state.modeloPlanoAcaoid, { method: 'GET' })
            .then(response => response.json() as Promise<CampoModeloAcaoData[]>)
            .then(data => {
                this.setState({ campoModeloAcaoList: data, loading: false });
                Loading.hideLoading(this);
            }); 
    }
 

    public updateModeloPlanoAcao() {
        this.setState({ campoModeloAcaoList: [], loading: true });
        this.forceUpdate();
        this.carregaModeloPlanoAcao();
    }
     
    private renderUserTable(campoModeloAcaoList: CampoModeloAcaoData[]) {
        return <table className='table'>
            <thead>
                <tr> 
                    <th>{<Resource keyString="field" />}</th>
                    <th>{<Resource keyString="format" />}</th>
                    <th>{<Resource keyString="ordination" />}</th>
                    <th>{<Resource keyString="required" />}</th>
                    <th>{<Resource keyString="active" />}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {campoModeloAcaoList.map(doc =>
                    <tr key={doc.id}>
                        <td className='align-middle'>{doc.nome}</td>
                        <td className='align-middle'>{
                            doc.formatocampomodeloacao.id == 1
                                ? <Resource keyString="text" />
                                : doc.formatocampomodeloacao.id == 2
                                    ? <Resource keyString="date" />
                                    : doc.formatocampomodeloacao.id == 3
                                        ? this.context.getLanguageResource("multiple_choice")
                                        : this.context.getLanguageResource("toggle")
                        }</td>
                        <td className='align-middle'>{doc.ordenacao}</td>
                        <td className='align-middle'>
                            {
                                doc.obrigatorio
                                    ?   <p className='mb-0 font-weight-bold text-success'><Resource keyString='yes' /></p>
                                    :   <p className='mb-0 font-weight-bold text-danger'><Resource keyString='no' /></p>
                            }
                        </td>
                        <td className='align-middle'>
                            {
                                doc.isactive
                                    ?   <p className='mb-0 font-weight-bold text-success'><Resource keyString='yes' /></p>
                                    :   <p className='mb-0 font-weight-bold text-danger'><Resource keyString='no' /></p>
                            }
                        </td>
                        <td className='align-middle'>
                            <button className="btn ws-outline-green-brown mr-2 pull-right" style={{ width: '2.75rem', height: '2.75rem' }} onClick={() => window.location.href = `/campoModeloAcao/edit/${doc.id}`}><i className='fa fa-edit'></i></button>
                         </td>
                    </tr>
                )}  
            </tbody>
        </table>;
    }
}   