import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { Layout } from '../../Layout';
import { HeadLayout } from '../../Shared/HeadLayout';
import { HttpService } from '../../Shared/HttpService';
import { Loading } from '../../Shared/Loading';
import { ModalDelete } from '../../Shared/ModalDelete';
import { Resource } from '../../../index';
import { LanguageContext } from '../../../context/LanguageContext';

interface FetchFormatoPerguntaState {
    FormatoPerguntaList: FormatoPerguntaData[];
    loading: boolean;
}

export class FormatoPerguntaData {
    id: number = 0;
    nome: string = "";
    html: string = "";
    error: Array<any> = [];
}

export class FetchFormatoPergunta extends React.Component<RouteComponentProps<{}>, FetchFormatoPerguntaState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = { FormatoPerguntaList: [], loading: true };

        Loading.showLoading(this);;
        HttpService.fetch('api/FormatoPerguntaApi', {})
            .then(response => response.json() as Promise<FormatoPerguntaData[]>)
            .then(data => {
                this.setState({ FormatoPerguntaList: data, loading: false });
                Loading.hideLoading(this);
            });

        this.handleDelete = this.handleDelete.bind(this);
        this.handleEdit = this.handleEdit.bind(this);

    }

    public render() {
        let contents = Loading.isLoading(this)
            ? <p><em></em></p>
            : this.renderUserTable(this.state.FormatoPerguntaList);

        return <div className="col-lg-12">
            <HeadLayout title="Layout da Pergunta" />
            <div className="card">
                <div className="card-header"><a href="/">{<Resource keyString="home" />}</a> / <a href="/">{<Resource keyString="audit_configuration" />}</a> / Layout da Pergunta</div>
                <div className="card-body">
                    <div className="card-title">
                        <h3 className="text-center title-2">Layout da Pergunta
                            <Link to="/FormatoPergunta/add" className="action btn btn-primary pull-right" data-loading-text="<i className='fa fa-spinner fa-spin '></i> Processing Order"><i className='fa fa-plus '></i> {<Resource keyString="add" />}</Link></h3>
                    </div>
                    <hr />
                    {contents}
                </div>
            </div>
        </div>;
    }

    // Handle Delete request for an user  
    private handleDelete(id: number) {
            Loading.showLoading(this);;
            HttpService.fetch('api/FormatoPerguntaApi/' + id, {
                method: 'delete'
            }).then(data => {
                this.setState(
                    {
                        FormatoPerguntaList: this.state.FormatoPerguntaList.filter((rec) => {
                            return (rec.id != id);
                        })
                    });
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            });
    }

    private handleEdit(id: number) {
        this.props.history.push("/FormatoPergunta/edit/" + id);
    }

    // Returns the HTML table to the render() method.  
    private renderUserTable(FormatoPerguntaList: FormatoPerguntaData[]) {
        return <table className='table'>
            <thead>
                <tr>
                    <th></th>
                    <th>Nome do Layout da Pergunta</th>
                    <th>{<Resource keyString="visualization" />}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {FormatoPerguntaList.map(doc =>
                    <tr key={doc.id}>
                        <td></td>
                        <td>{doc.nome}</td>
                        <td dangerouslySetInnerHTML={{ __html: doc.html }}></td>
                        <td>
                            <button className="btn btn-outline-danger mr-2 pull-right" data-toggle="modal" data-target={`#delete${doc.id}`}><i className='fa fa-trash'></i> {<Resource keyString="delete" />}</button>
                            <button className="btn btn-outline-success mr-2 pull-right" onClick={(id) => this.handleEdit(doc.id)}><i className='fa fa-edit'></i> {<Resource keyString="edit" />}</button>
                            <ModalDelete id={doc.id} callBack={this} message={`${this.context.getLanguageResource("do_you_want_to_remove_the_format_question")}: ${doc.nome}?`} />
                        </td>
                    </tr>
                )}
            </tbody>
        </table>;
    }
}   