import * as React from 'react';

interface IProps {
    nome: string;
    createdDateTime: string;
    anotacao: string;
}

export default class AnotacaoInfo extends React.Component<IProps> {
    constructor(props) {
        super(props);
    }

    render() {
        const { nome, createdDateTime, anotacao } = this.props;
        return (
            <div className="mb-2 rounded shadow-sm p-3" style={{ backgroundColor: 'var(--ws-blue-background)' }}>
                <h5>
                    {nome}
                    <small className="ml-3 text-muted">{createdDateTime}</small>
                </h5>
                <p className="mb-0 ws-small">{anotacao}</p>
            </div>
        );
    }
}
