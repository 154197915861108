import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink, Route } from 'react-router-dom';
import { Layout } from '../Layout';
import { HttpService } from '../Shared/HttpService';
import { HeadLayout } from '../Shared/HeadLayout';
import { Loading } from '../Shared/Loading';

import { FetchDocumentos, DocumentoData } from '../Documentos/FetchDocumentos';
import { FetchDiretorio, DiretorioData } from '../Diretorios/FetchDiretorio';
import { RepositorioData } from './Repositorio';
import { Component } from 'react';

interface RepositorioDataState {
    repositorio: RepositorioData;
    pesquisa: string;
    loading: boolean;
}

const pageValues = {
    listTitle: "Pesquisa no repositório",
}

export class PesquisaRepositorio extends React.Component<RouteComponentProps<{}>, RepositorioDataState> {
    constructor(props) {
        super(props);
        this.state = { repositorio: new RepositorioData, pesquisa: this.props.match.params["pesquisa"], loading: true };

        this.handleOpen();

        this.handleOpen = this.handleOpen.bind(this);
    }

    public updateScreen() {
    }

    public render() {
        this.updateScreen();

        let contents = Loading.isLoading(this)
            ? <p><em></em></p>
            : this.renderUserTable(this.state.repositorio);

        let title = <React.Fragment>
            <div className="card-title">
                <h4 className="text-center">
                    Resultado da Pesquisa por "{this.state.pesquisa}"
                </h4>
            </div>
            <hr />
        </React.Fragment>;

        return <div className="col-lg-12">
            <HeadLayout title={'Resultado da Pesquisa por "' + this.state.pesquisa + '"'} />
            <div className="card">
                <div className="card-header">
                    {pageValues.listTitle}
                </div>
                <div className="card-body">
                    {title}
                    {contents}
                </div>
            </div>
        </div>;
    }

    private handleOpen() {
        Loading.showLoading(this);;

        HttpService.fetch(`api/Repositorio/pesquisar/${this.state.pesquisa}`, {})
            .then(response => response.json() as Promise<RepositorioData>)
            .then(data => {
                this.setState({
                    repositorio: data, loading: false
                });
                Loading.hideLoading(this);
            });
    }

    private handleOpenDocument(id: number) {
        this.props.history.push(`/documentos/detail/${id}`);
    }

    private handleOpenDiretorio(id: number) {
        Loading.showLoading(this);;

        HttpService.fetch(`api/Repositorio/${id}`, {})
            .then(response => response.json() as Promise<RepositorioData>)
            .then(data => {
                this.setState({
                    repositorio: data, loading: false
                });
                this.props.history.push(`/repositorio/${(id < 0 ? 0 : id)}`);
                Loading.hideLoading(this);
            });
    }

    // Returns the HTML table to the render() method.  
    private renderUserTable(repositorio: RepositorioData) {
        return <div>
            <div className="row">
                {repositorio.diretorios.map(doc =>
                    <div className="col-md-2 btn btn-outline-light" key={`dir${doc.id}`} onClick={() => this.handleOpenDiretorio(doc.id)}>
                        <img className="mx-auto d-block" width="64" src="images/folder.png" alt="{doc.nome}" />
                        <div className="text-center">
                            <small className="card-title text-center mb-3 text-secondary">{doc.nome}</small>
                        </div>
                    </div>
                )}
                {repositorio.documentos.map(doc =>
                    <div className="col-md-2 btn btn-outline-light" key={`doc${doc.id}`} onClick={() => this.handleOpenDocument(doc.id)}>
                        <img className="mx-auto d-block" width="64" src="images/file.png" alt="{doc.nome}" />
                        <div className="text-center">
                            <small className="card-title text-center mb-3 text-secondary">{doc.nome}</small>
                        </div>
                    </div>
                )}
            </div>
        </div >;
    }
}   