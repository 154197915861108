import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { Layout } from '../Layout';
import { HttpService } from '../Shared/HttpService';
import { Loading } from '../Shared/Loading';
import { ModalDelete } from '../Shared/ModalDelete';
import { HeadLayout } from '../Shared/HeadLayout';
import { ArquivoData } from '../Arquivos/FetchArquivo';
import { PerfilData } from '../Perfil/FetchPerfil';
import { Resource } from '../../index';
import { LanguageContext } from '../../context/LanguageContext';

interface FetchDocumentosState {
    documentoList: DocumentoData[];
    loading: boolean;
}

export class DocumentoData {
    id: number = 0;
    empresaid: number = 0;
    nome: string = "";
    descricao: string = "";
    tags: string = "";
    diretoriosid: number = 0;
    prazoemdias: number = 0;
    error: Array<any> = [];
    perfil: PerfilData = new PerfilData;
    arquivovigente: ArquivoData = new ArquivoData;
    isactive: boolean = true;
}

export class ResultadoXDocumentoData {
    id: number = 0;
    hash: string = "";
    documentoid: number = 0;
    arquivoid: number = 0;
    perguntaid: number = 0;
    formularioid: number = 0;
    datecreated: string = "";
    setorid: number = 0;
    //error: Array<any> = [];
}

const pageValues = {
    listTitle: "Lista de Documentos",
}

export class FetchDocumentos extends React.Component<RouteComponentProps<{}>, FetchDocumentosState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = { documentoList: [], loading: true };

        Loading.showLoading(this);;
        HttpService.fetch('api/Documentos', {})
            .then(response => response.json() as Promise<DocumentoData[]>)
            .then(data => {
                this.setState({ documentoList: data, loading: false });
                Loading.hideLoading(this);
            });

        this.handleDelete = this.handleDelete.bind(this);
        this.handleEdit = this.handleEdit.bind(this);

    }

    public render() {
        const { getLanguageResource } = this.context;
        let contents = Loading.isLoading(this)
            ? <p><em></em></p>
            : this.renderUserTable(this.state.documentoList);

        return <div className="col-lg-12">
            <HeadLayout title={getLanguageResource("documents_list")} />
            <div className="card">
                <div className="card-header"><a href="/">{<Resource keyString="home" />}</a> / <a href="/repositorio">{<Resource keyString="repositories" />}</a> / {<Resource keyString="documents_list" />}</div>
                <div className="card-body">
                    <div className="card-title">
                        <h3 className="text-center title-2">{<Resource keyString="documents_list" />}
                            <Link to="/documentos/add" className="action btn btn-primary pull-right" data-loading-text="<i className='fa fa-spinner fa-spin '></i> Processing Order">+ {<Resource keyString="add" />}</Link></h3>
                    </div>
                    <hr />
                    {contents}
                </div>
            </div>
        </div>;
    }

    // Handle Delete request for an user  
    private handleDelete(id: number) {
        Loading.showLoading(this);;
        HttpService.fetch('api/Documentos/' + id, {
            method: 'delete'
        }).then(data => {
            this.setState(
                {
                    documentoList: this.state.documentoList.filter((rec) => {
                        return (rec.id != id);
                    })
                });
            Loading.hideLoading(this);
        }).catch(() => {
            Loading.hideLoading(this);
        });
    }

    private handleEdit(id: number) {
        this.props.history.push("/documentos/edit/" + id);
    }

    // Returns the HTML table to the render() method.  
    private renderUserTable(documentoList: DocumentoData[]) {
        return <table className='table'>
            <thead>
                <tr>
                    <th></th>
                    <th>Id</th>
                    <th>{<Resource keyString="name" />}</th>
                    <th>{<Resource keyString="description" />}</th>
                    <th>Tags</th>
                </tr>
            </thead>
            <tbody>
                {documentoList.map(doc =>
                    <tr key={doc.id}>
                        <td></td>
                        <td>{doc.nome}</td>
                        <td>{doc.descricao}</td>
                        <td>{doc.tags}</td>
                        <td>
                            <button className="btn btn-outline-danger mr-2 pull-right" data-toggle="modal" data-target={`#delete${doc.id}`}><i className='fa fa-trash'></i> {<Resource keyString="delete" />}</button>
                            <button className="btn btn-outline-success mr-2 pull-right" onClick={(id) => this.handleEdit(doc.id)}><i className='fa fa-edit'></i> {<Resource keyString="edit" />}</button>
                            <ModalDelete id={doc.id} callBack={this} message={`${<Resource keyString="do_you_want_to_remove_the_company" />}: ${doc.nome}?`} />
                        </td>
                    </tr>
                )}
            </tbody>
        </table>;
    }
}   