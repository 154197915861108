import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { CampoModeloAcaoData, CampoModeloAcaoToggle, OpcaoCampoMultiplaEscolhaData } from './FetchCampoModeloAcao';
import { HttpService } from '../../Shared/HttpService';
import { Loading } from '../../Shared/Loading';
import $ from 'jquery';
import { Resource } from '../../../index';
import { ValidationLabelMessage } from '../../Shared/ValidationMessage';
import { LanguageContext } from '../../../context/LanguageContext';
import CaixaDeConteudo from '../../Shared/CaixaDeConteudo';

interface AddCamposDataState {
    id: string;
    loading: boolean;
    empData: CampoModeloAcaoData;
    callBack: any;
    valorSelecionado: any;
    listaDadoSelectFormatoCampo: any;
    modeloPlanoAcaoid: any;
    formatoCampoSelecionado: any;
    fixo: boolean;
}

export class objcampoModeloData {
    nome: string = "";
    formatocampomodeloacao_id: number = 0;
    ordenacao: string = "";
    obrigatorio: boolean = true;
    listaopcaoMultiplaecolha: any = [];
}

export class AddCampoModeloAcao extends React.Component<RouteComponentProps<{}>, AddCamposDataState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            empData: new CampoModeloAcaoData,
            id: props.id,
            modeloPlanoAcaoid: "",
            callBack: props.callBack,
            valorSelecionado: "",
            listaDadoSelectFormatoCampo: [],
            formatoCampoSelecionado: "",
            fixo: false
        };

        var campoModeloAcaoid = this.props.match.params["campoModeloAcaoid"];
        var modeloPlanoAcaoid = this.props.match.params["modeloPlanoAcaoid"];

        // This will set state for Edit User  
        if (campoModeloAcaoid > 0) {
            Loading.showLoading(this);
            HttpService.fetch('api/CampoModeloAcaoApi/' + campoModeloAcaoid, { method: 'GET' })
                .then(response => response.json() as Promise<CampoModeloAcaoData>)
                .then(data => {
                    if (data.campoModeloAcaoToggle === null) data.campoModeloAcaoToggle = new CampoModeloAcaoToggle();
                    this.setState({
                        loading: false,
                        empData: data,
                        modeloPlanoAcaoid: modeloPlanoAcaoid,
                        fixo: data.fixo,
                    });

                    Loading.hideLoading(this);
                }).catch(() => {
                    Loading.hideLoading(this);

                });
        }

        // This will set state for Add User  
        else {
            this.state = {
                loading: false,
                empData: new CampoModeloAcaoData,
                id: props.id,
                callBack: props.callBack,
                valorSelecionado: "",
                listaDadoSelectFormatoCampo: [],
                modeloPlanoAcaoid: modeloPlanoAcaoid,
                formatoCampoSelecionado: "",
                fixo: false
            };
        }

        Loading.showLoading(this);
        HttpService.fetch('api/CampoModeloAcaoApi/GetDadoSelectFormatoCampo', { method: 'GET' })
            .then(response => response.json())
            .then(data => {
                this.setState({ loading: false, listaDadoSelectFormatoCampo: data });
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
                this.props.history.push("/modeloPlanoAcao");
            });


        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);
        this.handleFormatoCampoSelecionado = this.handleFormatoCampoSelecionado.bind(this);
        this.handleMontarObjetoOpcoes = this.handleMontarObjetoOpcoes.bind(this);
        this.handleRemoverElementoDoObjetoOpcoes = this.handleRemoverElementoDoObjetoOpcoes.bind(this);
        this.handleCancel = this.handleCancel.bind(this);

    }

    private botoesDoCantoSuperiorDireito() {
        return (
            <React.Fragment>
                <button className="btn bg-white shadow-sm mr-2" onClick={this.handleCancel}>{<Resource keyString="back" />}</button>
            </React.Fragment>
        );
    }

    public render() {
        const _estouNaPaginaDeEdicao = window.location.href.indexOf("edit") > -1;
        if (Loading.isLoading(this)) return <React.Fragment />;

        return (
            <CaixaDeConteudo
                tituloDoHeader={this.context.getLanguageResource("action_plan_templates")}
                botoes={this.botoesDoCantoSuperiorDireito()}
                itensParaBreadcrumb={[
                    { url: "/", nome: this.context.getLanguageResource("home") },
                    { nome: _estouNaPaginaDeEdicao ? this.context.getLanguageResource("create_field") : this.context.getLanguageResource("edit_field") }
                ]}
            >
                <main className='p-4'>
                    {this.renderUserTable()}
                </main>
            </CaixaDeConteudo>
        );
    }

    private handleCancel(e) {
        e.preventDefault();
        this.redirectPage();
    }

    private redirectPage() {
        var id = this.state.empData.modeloacao_id > 0 ? this.state.empData.modeloacao_id : this.state.modeloPlanoAcaoid;
        this.props.history.push("/modeloPlanoAcao/edit/" + id);
    }

    private renderUserTable() {
        return <form onSubmit={this.handleSave} >
            <div className="form-group row" >
                <input type="hidden" name="id" value={this.state.empData.id} />
                <input type="hidden" name="modeloacao_id" value={this.state.empData.modeloacao_id > 0 ? this.state.empData.modeloacao_id : this.state.modeloPlanoAcaoid} />
            </div>
            <div className="form-group row">
                <div className="col-md-12">
                    <label className="control-label col-md-12 required" htmlFor="nome" >{<Resource keyString="field_name" />}</label>
                    <div className="col-md-12">
                        <ValidationLabelMessage errors={this.state.empData.error} name="Nome">
                            <input className="form-control" type="text" id="nome" name="nome" defaultValue={this.state.empData.nome} />
                        </ValidationLabelMessage>
                    </div>
                </div>
            </div >
            {
                this.state.fixo
                    ?   <input type="hidden" name="formatocampomodeloacao_id" id="formatocampomodeloacao_id" value={this.state.empData.formatocampomodeloacao_id} />
                    :   (
                            <div className="form-group row">
                                <div className="col-md-12">
                                    <label className="control-label col-md-12 " htmlFor="formatocampomodeloacao_id" >{<Resource keyString="field_format" />}</label>
                                    <div className="col-md-12">
                                        <select className="form-control col-md-12" name="formatocampomodeloacao_id" id="formatocampomodeloacao_id"
                                            value={(this.state.empData.formatocampomodeloacao_id) ? this.state.empData.formatocampomodeloacao_id.toString() : ''}
                                            onChange={this.handleFormatoCampoSelecionado}>
                                            {this.state.listaDadoSelectFormatoCampo.map((item) => {
                                                if (item.id == 1) {
                                                    return <option key={item.id} value={item.id}>{this.context.getLanguageResource("text")}</option>
                                                }
                                                else if (item.id == 2) {
                                                    return <option key={item.id} value={item.id}>{this.context.getLanguageResource("date")}</option>
                                                }
                                                else if (item.id == 3) {
                                                    return <option key={item.id} value={item.id}>{this.context.getLanguageResource("multiple_choice")}</option>
                                                } else {
                                                    return <option key={item.id} value={item.id}>{this.context.getLanguageResource("toggle")}</option>
                                                }
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div >

                        )
            }
            {
                this.state.empData.formatocampomodeloacao_id == 3
                    ?   (
                            <div className="form-group row">
                                <div className="col-md-12">
                                    <ValidationLabelMessage errors={this.state.empData.error} name="Opcoes">
                                        <label className="control-label col-md-12 " htmlFor="nomeOpcoes" >{<Resource keyString="options" />}</label>
                                        <div className="col-md-12 d-flex pb-2">
                                            <input className="form-control mr-2" type="text" id="nomeOpcoes" name="nomeOpcoes" />
                                            <button className="btn btn-primary pull-right" type="button"
                                                onClick={this.handleMontarObjetoOpcoes}>
                                                <i className='fa fa-plus'></i>
                                            </button>
                                        </div>
                                    </ValidationLabelMessage>
                                </div>
                                <div className="col-md-12 px-4">
                                    <table className='table border'>
                                        <tbody >
                                            {this.state.empData.camposmultiplicaescolha
                                                .filter((x) => { return x.isactive == true })
                                                .map((doc: any, index) =>
                                                    <tr key={index}>
                                                        <td className=" p-1">{doc.nome}</td>
                                                        <td className=" p-1">
                                                            <button className="btn btn-outline-danger mr-2 pull-right" type="button"
                                                                onClick={() => this.handleRemoverElementoDoObjetoOpcoes(index)} >
                                                                <i className='fa fa-trash'></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )
                    :   <React.Fragment />
            }

            {
                this.state.empData.formatocampomodeloacao_id == 4 && this.state.fixo
                    ?   (
                            <React.Fragment>
                                <input type="hidden" name="valorQuandoInativo" id="valorQuandoInativo" value={this.state.empData.campoModeloAcaoToggle.valorQuandoInativo} />
                                <input type="hidden" name="valorQuandoAtivo" id="valorQuandoAtivo" value={this.state.empData.campoModeloAcaoToggle.valorQuandoAtivo} />
                            </React.Fragment>
                        )
                    :   <React.Fragment />
            }

            {
                this.state.empData.formatocampomodeloacao_id == 4 && !this.state.fixo
                    ?   (
                            <React.Fragment>
                                <div className='row'>
                                    <div className="col-md-6">
                                        <label className="control-label col-md-12" htmlFor="valorQuandoInativo" >{<Resource keyString="inactive_value" />}</label>
                                        <div className="col-md-12">
                                            <ValidationLabelMessage errors={this.state.empData.error} name="valorQuandoInativo">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    id="valorQuandoInativo"
                                                    name="valorQuandoInativo"
                                                    onChange={(e) => {
                                                        e.target.classList.remove('is-invalid');
                                                        this.setState({ 
                                                            empData: {
                                                                ...this.state.empData,
                                                                campoModeloAcaoToggle: {
                                                                    ...this.state.empData.campoModeloAcaoToggle,
                                                                    valorQuandoInativo: e.target.value
                                                                }
                                                            }
                                                        })
                                                    }}
                                                    value={this.state.empData.campoModeloAcaoToggle.valorQuandoInativo}
                                                />
                                                <input
                                                    type="radio"
                                                    name="valorPadrao"
                                                    id="valorPadraoInativo"
                                                    value="0"
                                                    onChange={(_event) => this.setState({
                                                        empData: {
                                                            ...this.state.empData,
                                                            campoModeloAcaoToggle: {
                                                                ...this.state.empData.campoModeloAcaoToggle,
                                                                valorPadrao: false
                                                            }}
                                                        })}
                                                    checked={!this.state.empData.campoModeloAcaoToggle.valorPadrao}
                                                />
                                                <label className='ml-2 mt-2' htmlFor='valorPadraoInativo'>Valor inicial</label>
                                            </ValidationLabelMessage>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="control-label col-md-12" htmlFor="valorQuandoAtivo" >{<Resource keyString="active_value" />}</label>
                                        <div className="col-md-12">
                                            <ValidationLabelMessage errors={this.state.empData.error} name="valorQuandoAtivo">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    id="valorQuandoAtivo"
                                                    name="valorQuandoAtivo"
                                                    onChange={(e) => {
                                                        e.target.classList.remove('is-invalid');
                                                        this.setState({ 
                                                            empData: {
                                                                ...this.state.empData,
                                                                campoModeloAcaoToggle: {
                                                                    ...this.state.empData.campoModeloAcaoToggle,
                                                                    valorQuandoAtivo: e.target.value
                                                                }
                                                            }
                                                        })
                                                    }}
                                                    value={this.state.empData.campoModeloAcaoToggle.valorQuandoAtivo}
                                                />
                                                <input
                                                    type="radio"
                                                    name="valorPadrao"
                                                    id="valorPadraoAtivo"
                                                    value="1"
                                                    onChange={(_event) => this.setState({
                                                        empData: {
                                                            ...this.state.empData,
                                                            campoModeloAcaoToggle: {
                                                                ...this.state.empData.campoModeloAcaoToggle,
                                                                valorPadrao: true
                                                            }}
                                                        })}
                                                    checked={this.state.empData.campoModeloAcaoToggle.valorPadrao}
                                                />
                                                <label className='ml-2 mt-2' htmlFor='valorPadraoAtivo'>Valor inicial</label>
                                            </ValidationLabelMessage>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                    :   <React.Fragment />
            }
            <div className="form-group row">
                <div className="col-md-12">
                    <label className="control-label col-md-12" htmlFor="ordenacao" >{<Resource keyString="ordination" />}</label>
                    <div className="col-md-12">
                        <ValidationLabelMessage errors={this.state.empData.error} name="ordenacao">
                            <input className="form-control" type="text" id="ordenacao" name="ordenacao" defaultValue={this.state.empData.ordenacao} />
                        </ValidationLabelMessage>
                    </div>
                </div>
            </div >
            {
                this.state.fixo
                    ?   <React.Fragment />
                    :   (
                            <React.Fragment>
                                <div className="form-group col-md-12" >
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" name="obrigatorio" id="obrigatorio" defaultChecked={this.state.empData.obrigatorio} />
                                        <label className="custom-control-label" htmlFor="obrigatorio">{<Resource keyString="required" />}</label>
                                    </div>
                                </div>
                                <div className="form-group col-md-12" >
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" name="isactive" id="isactive" defaultChecked={this.state.empData.isactive} />
                                        <label className="custom-control-label" htmlFor="isactive">{<Resource keyString="active" />}</label>
                                    </div>
                                </div>
                            </React.Fragment>
                        )
            }
            <div className="form-group col-12 d-flex justify-content-center">
                <button type="submit" className="btn font-weight-bold" style={{ backgroundColor: 'var(--ws-blue)', width: '17.5rem' }}>{<Resource keyString="save" />}</button>
            </div>
        </form >
    }

    private handleMontarObjetoOpcoes(event) {
        event.preventDefault();
        const campoNome = document.getElementById("nomeOpcoes") as HTMLInputElement;
        var opcao: OpcaoCampoMultiplaEscolhaData = new OpcaoCampoMultiplaEscolhaData();
        opcao.nome = campoNome.value;
        campoNome.value = "";
        this.state.empData.camposmultiplicaescolha.push(opcao as any);
        this.setState({ empData: this.state.empData });
    }

    private handleRemoverElementoDoObjetoOpcoes(index) {
        this.state.empData.camposmultiplicaescolha
            .filter((x) => { return x.isactive == true })
            .map((item, oindex) => {
                if (oindex == index) {
                    item.isactive = false;
                }
            });
        this.setState({ empData: this.state.empData });
    }

    private handleFormatoCampoSelecionado(event) {
        event.preventDefault();

        this.state.empData.formatocampomodeloacao_id = event.target.value;
        this.setState({ empData: this.state.empData });
        this.forceUpdate();
    }

    // This will handle the submit form event.  
    private handleSave(event) {
        event.preventDefault();
        let modeloPlanoAcaoid = this.state.empData.id;

        Loading.showLoading(this);

        var obj: any = HttpService.formatData(event.target);
        obj['camposmultiplicaescolha'] = this.state.empData.camposmultiplicaescolha;
        obj['campoModeloAcaoToggle'] = this.state.empData.campoModeloAcaoToggle;

        if (obj.formatocampomodeloacao_id == 4 && (obj.campoModeloAcaoToggle.valorQuandoAtivo == "" || obj.campoModeloAcaoToggle.valorQuandoInativo == "")) {
            Loading.hideLoading(this);
            const inputValorQuandoAtivo = document.getElementById("valorQuandoAtivo") as HTMLInputElement;
            const inputValorQuandoInativo = document.getElementById("valorQuandoInativo") as HTMLInputElement;

            obj.campoModeloAcaoToggle.valorQuandoAtivo == "" && inputValorQuandoAtivo.classList.add("is-invalid");
            obj.campoModeloAcaoToggle.valorQuandoInativo == "" && inputValorQuandoInativo.classList.add("is-invalid");
            return;
        }

        // PUT request for Edit User.  
        if (modeloPlanoAcaoid) {
            HttpService.fetch('api/CampoModeloAcaoApi/' + modeloPlanoAcaoid, {
                method: 'PUT',
                body: JSON.stringify(obj),
            }).then((response) => {
                return response.json() as Promise<CampoModeloAcaoData>
            }).then((data) => {

                this.setState({ empData: data })
                if ($.isEmptyObject(data.error)) {
                    this.redirectPage();
                }
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            })

        } else {
            HttpService.fetch('api/CampoModeloAcaoApi/Salvar', {
                method: 'POST',
                body: JSON.stringify(obj),
            }).then((response) => {
                return response.json() as Promise<CampoModeloAcaoData>
            }).then((data) => {

                this.setState({ empData: data })
                if ($.isEmptyObject(data.error)) {
                    this.redirectPage();
                }
                Loading.hideLoading(this);
            }).catch(() => {
                Loading.hideLoading(this);
            });
        }
    }
}  