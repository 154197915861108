import React, { useRef, useEffect, useState } from "react";
import { IAnotacao, IPergunta, IResponderFormulario } from "../../interfaces/IResponderFormularioResponse";
import { HttpService } from "../../../../Shared/HttpService";
import { criarArrayDePonteirosDasPerguntas } from "../../utils/geradoresDeArrayDePonteiros";
import { parse } from 'date-fns';

interface IProps {
    anotacoes: IAnotacao[];
    formularioIniciadoId: number;
    pergunta: IPergunta;
    responderFormulario: IResponderFormulario;
    setResponderFormulario: (responderFormulario: IResponderFormulario) => void;
    hash: string;
}

interface IAnotacaoEmEdicao {
    id: number;
    mensagem: string;
    datecreated: string;
}

const ListaDeAnotacoes = ({ anotacoes, pergunta, formularioIniciadoId, responderFormulario, setResponderFormulario, hash }: IProps) => {
    const [anotacaoEmEdicao, setAnotacaoEmEdicao] = useState<IAnotacaoEmEdicao | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const divRef = useRef<HTMLDivElement>(null);

    const idDoUsuario = JSON.parse(localStorage.getItem('auth') || '{}').id;

    const editarAnotacao = (anotacaoEmEdicao: IAnotacaoEmEdicao): void => {
        setIsLoading(true);
        const dateCreated = parse(anotacaoEmEdicao.datecreated, 'dd/MM/yyyy HH:mm:ss', new Date());

        HttpService.fetch('api/ResultadoNovoApi/EditarAnotacao', {
            method: 'PUT',
            body: JSON.stringify({
                userCreatedId: idDoUsuario,
                id: anotacaoEmEdicao.id,
                hash: hash,
                grupoPerguntaId: pergunta.grupoPerguntaId,
                mensagem: anotacaoEmEdicao.mensagem,
                formularioIniciadoId: formularioIniciadoId,
                perguntaId: pergunta.id,
                dateCreated: dateCreated,
                userModifiedId: idDoUsuario,
            })
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            const responderFormularioAtualizado = { ...responderFormulario };
            const ponteirosDasPerguntasDeResponderFormulario = criarArrayDePonteirosDasPerguntas(responderFormularioAtualizado);
            const perguntaSendoAlterada = ponteirosDasPerguntasDeResponderFormulario.find(p => p.id === pergunta.id) as IPergunta;
            perguntaSendoAlterada.anotacoes = data;

            setAnotacaoEmEdicao(null);
            setIsLoading(false);
            setResponderFormulario(responderFormularioAtualizado);
        });
    };

    useEffect(() => {
        const div = divRef.current;
        if (div) div.scrollTop = div.scrollHeight;
    }, [anotacoes]);

    return (
        <div className='border-bottom bg-white rounded-top barra_de_rolagem_estilizada' style={ { overflowY: 'auto', height: '100%' } } ref={divRef}>
            {
                anotacoes.length === 0
                    ?   <div style={ { height: '100%', minHeight: '140px' } } className='w-100 d-flex justify-content-center align-items-center'><span>Nenhuma anotação encontrada</span></div>
                    :   <div>
                            {
                                anotacoes
                                    .map(item => (
                                    <div key={`${item.id} | ${item.usuario} | ${item.dataHora}`} className="d-flex justify-content-between align-items-center bg-light rounded border py-1 px-2 m-2">
                                        <div className="w-100">
                                            <div>
                                                {
                                                    idDoUsuario === item.usuarioId
                                                        ?   <strong>{item.usuario}</strong>
                                                        :   <span>{item.usuario}</span>
                                                }
                                                <small className='text-muted ml-3'>{item.dataHora.slice(0, -3)}</small>
                                                { item.dataHora !== item.dataHoraModificado && <small className='text-muted ml-3'>Editado</small> }
                                            </div>
                                            {
                                                anotacaoEmEdicao !== null && anotacaoEmEdicao.id === item.id
                                                    ?   <div className="w-100">
                                                            <input type="text" className="form-control w-100" style={{ paddingRight: '5rem' }} value={anotacaoEmEdicao.mensagem} onChange={(e) => setAnotacaoEmEdicao({ id: item.id, mensagem: e.target.value, datecreated: item.dataHora })} />
                                                            <div className='w-100 p-0 m-0' style={{ height: '0px' }}>
                                                                {
                                                                    isLoading
                                                                        ?   <span className="spinner-border spinner-border-sm text-primary" style={{ cursor: 'pointer', position: 'relative', fontSize: '1.4rem', left: 'calc(100% - 2rem)', top: '-1.85rem'}} role="status" aria-hidden="true"></span>
                                                                        :   <React.Fragment>
                                                                                <span className="bi bi-check" onClick={() => editarAnotacao({ id: item.id, mensagem: anotacaoEmEdicao.mensagem, datecreated: item.dataHora })} style={{ cursor: 'pointer', position: 'relative', fontSize: '1.4rem', left: 'calc(100% - 2rem)', top: '-1.85rem'}}></span>
                                                                                <span className="bi bi-x" onClick={() => setAnotacaoEmEdicao(null)} style={{ cursor: 'pointer', position: 'relative', fontSize: '1.4rem', left: 'calc(100% - 5rem)', top: '-1.85rem'}}></span>
                                                                            </React.Fragment>
                                                                }
                                                            </div>
                                                        </div>
                                                    :   <div>
                                                            {item.mensagem}
                                                        </div>
                                            }
                                        </div>
                                        {
                                            idDoUsuario === item.usuarioId && anotacaoEmEdicao?.id !== item.id && (
                                                <div>
                                                    <button type="button" className="btn" onClick={() => setAnotacaoEmEdicao({ id: item.id, mensagem: item.mensagem, datecreated: item.dataHora })}>
                                                        <i className='bi bi-pencil-square' />
                                                    </button>
                                                </div>
                                            )
                                        }
                                    </div>
                                ))   
                            }
                        </div>
            }
        </div>
    );
};

export default ListaDeAnotacoes;
