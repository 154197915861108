import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { HttpService } from '../Shared/HttpService';
import { Loading } from '../Shared/Loading';
import { Resource } from '../../index';
import { LanguageContext } from '../../context/LanguageContext';
import CaixaDeConteudo from '../Shared/CaixaDeConteudo';
import ModalWinSet from '../Shared/components/ModalWinSet';

interface FetchSetorState {
    setorList: SetorData[];
    loading: boolean;
    modalDeDeletarEstaAberto: boolean;
    itemSelecionadoParaDeletar: SetorData | null;
}

export class SetorData {
    id: number = 0;
    nome: string = "";
    setorpaiid: string = "";
    setor: any = {};
    error: Array<any> = [];
}

export class FetchSetor extends React.Component<RouteComponentProps<{}>, FetchSetorState> {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = { setorList: [], loading: true, modalDeDeletarEstaAberto: false, itemSelecionadoParaDeletar: null };

        Loading.showLoading(this);;
        HttpService.fetch('api/SetorApi/ListSetores', {})
            .then(response => response.json() as Promise<SetorData[]>)
            .then(data => {
                this.setState({ setorList: data, loading: false });
                Loading.hideLoading(this);
            });

        this.handleDelete = this.handleDelete.bind(this);
        this.handleEdit = this.handleEdit.bind(this);

    }

    private botoesDoCantoSuperiorDireito() {
        return (
            <React.Fragment>
                <button
                    onClick={() => this.props.history.push('/setor/add')}
                    className="btn bg-white mr-2 shadow"
                >
                    {<Resource keyString="add" />}
                </button>
            </React.Fragment>
        );
    }

    public render() {
        if (Loading.isLoading(this)) return <React.Fragment />;

        return (
            <CaixaDeConteudo
                botoes={this.botoesDoCantoSuperiorDireito()}
                tituloDoHeader={this.context.getLanguageResource("sector")}
                itensParaBreadcrumb={[
                    { url: "/", nome: this.context.getLanguageResource("home") },
                    { nome: this.context.getLanguageResource("response_model") },
                ]}
            >
                <React.Fragment>
                    <header className='d-flex align-items-center justify-content-center w-100 p-4'>
                        <h3>{<Resource keyString="sectors" />}</h3>
                    </header>
                    <main className='p-4'>
                        {this.renderUserTable(this.state.setorList)}
                    </main>
                </React.Fragment>
            </CaixaDeConteudo>
        );
    }

    // Handle Delete request for an user  
    private handleDelete(id: number) {
        Loading.showLoading(this);;
        HttpService.fetch('api/SetorApi/' + id, {
            method: 'delete'
        }).then(data => {
            this.setState(
                {
                    setorList: this.state.setorList.filter((rec) => {
                        return (rec.id != id);
                    })
                });
            Loading.hideLoading(this);
        }).catch(() => {
            Loading.hideLoading(this);
        });
    }

    private handleEdit(id: number) {
        this.props.history.push("/setor/edit/" + id);
    }

    // Returns the HTML table to the render() method.  
    private renderUserTable(setorList: SetorData[]) {       
        return <table className='table'>
            <thead>
                <tr>
                    <th>{<Resource keyString="sector_name" />}</th>
                    <th>{<Resource keyString="sector_father" />}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {setorList.map(doc =>
                    <tr key={doc.id}>
                        <td className='align-middle text-break'>{doc.nome}</td>
                        <td className='align-middle text-break'>{doc.setor != null ? doc.setor.nome : ""}</td>
                        <td className='align-middle'>
                            <div className='d-flex justify-content-end'>
                                <button
                                    className="btn btn-outline-danger mr-2 pull-right"
                                    onClick={() => this.setState({ modalDeDeletarEstaAberto: true, itemSelecionadoParaDeletar: doc })}
                                    style={{ width: '2.75rem', height: '2.75rem' }}
                                >
                                    <i className='fa fa-trash'></i>
                                </button>
                                {
                                    (this.state.modalDeDeletarEstaAberto && this.state.itemSelecionadoParaDeletar && (this.state.itemSelecionadoParaDeletar.id === doc.id)) && (
                                        <ModalWinSet
                                            fecharModal={() => this.setState({ modalDeDeletarEstaAberto: false, itemSelecionadoParaDeletar: null })}
                                        >
                                            <div className='p-3'>
                                                <header className='d-flex w-100 mb-2 justify-content-center'>
                                                    <h4><Resource keyString="do_you_want_to_remove_the_sector" />?</h4>
                                                </header>
                                                <hr />
                                                <main>
                                                    <p>{doc.nome}</p>
                                                </main>
                                                <footer className='d-flex w-100 justify-content-end'>
                                                    <button className="btn mr-2 ws-outline-blue" onClick={() => this.handleDelete(doc.id)}><Resource keyString="yes" /></button>
                                                    <button className="btn btn-outline-danger" onClick={() => this.setState({ modalDeDeletarEstaAberto: false, itemSelecionadoParaDeletar: null })}><Resource keyString="no" /></button>
                                                </footer>
                                            </div>
                                        </ModalWinSet>
                                    )
                                }
                                <button className="btn ws-outline-green-brown mr-2 pull-right" style={{ width: '2.75rem', height: '2.75rem' }} onClick={() => this.handleEdit(doc.id)}><i className='fa fa-edit'></i></button>
                            </div>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>;
    }
}   