import React, { useState, useEffect } from "react";
import ExclamationCircleIcon from "../../../icons/ExclamationCircleIcon";
import { ToggleSwitch } from '../../../../../Shared/components/ToggleSwitch'

const AcaoDeAlternacao = ({ id, titulo, valor, idDaPergunta, funcaoModificadora, obrigatorio, valorAtivo, valorInativo, valorPadrao }) => {
    if (valor === null) valor = valorPadrao ? valorAtivo : valorInativo;
    let valorEstaInativo = valor === valorInativo;
    const [isChecked, setIsChecked] = useState(!valorEstaInativo);

    useEffect(() => {
        funcaoModificadora(isChecked, valorAtivo, valorInativo);
    }, []);

    return (
        <div className='form-group ContainerDeAcao d-flex flex-column'>
            <label>{titulo}</label>
            {
                obrigatorio && !valor
                    ? <ExclamationCircleIcon fill='#ef0000' className='ml-1' />
                    : <React.Fragment />
            }
            <div className='d-flex align-itens-center'>
                <ToggleSwitch
                    onChange={(event) => {
                        setIsChecked(event.target.checked);
                        funcaoModificadora(event.target.checked, valorAtivo, valorInativo);
                    }}
                    checked={isChecked}
                    id={`item-de-acao-${id}-da-pergunta-${idDaPergunta}`}
                    widthEmRem="3.3rem"
                />
                <label className='mb-0 ml-2 mt-1' htmlFor={`item-de-acao-${id}-da-pergunta-${idDaPergunta}`}>{isChecked ? valorAtivo : valorInativo}</label>
            </div>
        </div>
    );
}

export default AcaoDeAlternacao;