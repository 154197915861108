import * as React from 'react';
import PlanoDeAcaoModeloPersonalizado from '../PlanoDeAcaoModeloPersonalizado';
import PlanoDeAcaoModeloPadrao from '../PlanoDeAcaoModeloPadrao';

const PlanoAcaoFormulario = ({ resultadoPlanoAcao }) => {
  return (
    <div>
        {
          resultadoPlanoAcao
            .map((acao, index) => {
              const { campomodeloacaoXValor } = acao;
              const possuiAcaoPersonalizada = campomodeloacaoXValor && campomodeloacaoXValor.length > 0;

              return (
                possuiAcaoPersonalizada
                  ? <PlanoDeAcaoModeloPersonalizado
                      key={ index }
                      acao={ acao }
                    />
                  : <PlanoDeAcaoModeloPadrao
                      key={ index }
                      acao={ acao }
                    />
              )
            })
        }
    </div>
  );
};

export default PlanoAcaoFormulario;
