import React, { useState, useContext } from 'react';
import { ResponderFormularioContext } from '../../contexts/ResponderFormularioContext';
import { LanguageContext } from '../../../../../context/LanguageContext';
import { criarArrayDePonteirosDasPerguntas } from '../../utils/geradoresDeArrayDePonteiros';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import ExclamationCircleIcon from '../../icons/ExclamationCircleIcon';
import ChevronDownIcon from '../../icons/ChevronDownIcon';
import ChevronUpIcon from '../../icons/ChevronUpIcon';

const CLASSE_DO_CARD = 'card';
const CLASSE_DO_CARD_NAO_PREEENCHIDO = 'card border-danger';
const CLASSE_DO_TEXTAREA = 'form-control h-100 border-0';
const CLASSE_DO_TEXTAREA_NAO_PREEENCHIDO = 'form-control h-100 border-0 is-invalid';

const CampoDeObservacao = ({ observacao, idDaPergunta }) => {
    const { 
        responderFormulario,
        setResponderFormulario,
        ultimaTentativaDeFinalizar,
        mostrarTodosOsCollapses,
    } = useContext(ResponderFormularioContext);
    const { getLanguageResource } = useContext(LanguageContext);

    const [classeDoTextArea, setClasseDoTextArea] = useState(CLASSE_DO_TEXTAREA);
    const [classeDoCard, setClasseDoCard] = useState(CLASSE_DO_CARD);
    const [isOpen, setIsOpen] = useState(true);

    const responderFormularioAtualizado = { ...responderFormulario };
    const ponteirosDasPerguntasDeResponderFormulario = criarArrayDePonteirosDasPerguntas(responderFormularioAtualizado);
    const pergunta = ponteirosDasPerguntasDeResponderFormulario.find(pergunta => pergunta.id === idDaPergunta);

    const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        if (!pergunta) return;
        pergunta[event.target.name] = event.target.value;
        setClasseDoCard(CLASSE_DO_CARD);
        setClasseDoTextArea(CLASSE_DO_TEXTAREA);
        setResponderFormulario(responderFormularioAtualizado);
    }

    const esteCampoEObrigatorioENaoEstaPreenchido = (): boolean => {
        if (pergunta && pergunta.observacao === null) {
            pergunta.observacao = '';
        }
        const respostaSelecionada = pergunta && pergunta.respostas.find((resposta) => resposta.respostaFoiSelecionada);
        const campoNaoEstaPreenchido = (pergunta && pergunta.observacao === "" && pergunta.observacao.length === 0) ? true : false;
        if (pergunta !== undefined) {
            if (respostaSelecionada === undefined) return false;
            return respostaSelecionada.observacaoObrigatoria && campoNaoEstaPreenchido;
        }
        return false;
    }

    useDidUpdateEffect(() => {
        const respostaSelecionada = pergunta && pergunta.respostas.find((resposta) => resposta.respostaFoiSelecionada);
        const observacaoObrigatoria = respostaSelecionada && respostaSelecionada.observacaoObrigatoria;

        if (pergunta?.observacao?.length === 0 && observacaoObrigatoria) {
            setClasseDoCard(CLASSE_DO_CARD_NAO_PREEENCHIDO);
            setClasseDoTextArea(CLASSE_DO_TEXTAREA_NAO_PREEENCHIDO);
        } else if (!observacaoObrigatoria) {
            setClasseDoCard(CLASSE_DO_CARD);
            setClasseDoTextArea(CLASSE_DO_TEXTAREA);
        }
    }, [ultimaTentativaDeFinalizar]);

    useDidUpdateEffect(() => setIsOpen(mostrarTodosOsCollapses), [mostrarTodosOsCollapses]);

    return (
        <div className='col-12 mb-4 border border-top-0 px-0' style={ { maxHeight: '350px' } }>
            <div className={`${classeDoCard} ${isOpen && 'h-100'}`}>
                {/* <div
                    className={`d-flex justify-content-between p-2 ${isOpen ? 'rounded-top' : 'rounded'}`}
                    onClick={() => setIsOpen(!isOpen)}
                    style={{ cursor: 'pointer', backgroundColor: 'var(--ws-blue-light)' }}
                >
                    <div>
                        <span>{getLanguageResource('observation')}</span>
                        {
                            esteCampoEObrigatorioENaoEstaPreenchido()
                                ? <ExclamationCircleIcon className='ml-2' fill='#ef0000' />
                                : <React.Fragment />
                        }
                    </div>
                    <div>
                        {
                            isOpen
                                ? <ChevronDownIcon className='ml-2' fill='#000' />
                                : <ChevronUpIcon className='ml-2' fill='#000' />
                        }
                    </div>
                </div> */}
                <div className={`card-body p-0 ${isOpen ? '' : 'd-none'}`}>
                    <textarea
                        className={`barra_de_rolagem_estilizada ${classeDoTextArea}`}
                        name="observacao"
                        value={observacao === null ? '' : observacao}
                        id={`observacao-${idDaPergunta}`}
                        onChange={handleTextareaChange}
                        placeholder={getLanguageResource('insert_your_comment_here')}
                        style={{ resize: 'none', minHeight: '110px' }}
                    />
                </div>
            </div>
        </div>
    );
};

export default CampoDeObservacao;