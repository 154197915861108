import * as React from 'react';
import { IPergunta } from '../../interfaces/IResponderFormularioResponse';
import InputDeAcao from '../InputDeAcao';
import { ResponderFormularioContext } from '../../contexts/ResponderFormularioContext';
import { LanguageContext } from '../../../../../context/LanguageContext';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import ChevronDownIcon from '../../icons/ChevronDownIcon';
import ChevronUpIcon from '../../icons/ChevronUpIcon';
import ExclamationCircleIcon from '../../icons/ExclamationCircleIcon';

const { useState, useContext } = React;

interface IProps {
  	pergunta: IPergunta;
    permitirEditar: boolean;
};

const CampoDePreenchimentoDeAcao = ({ pergunta, permitirEditar }: IProps) => {
	const { mostrarTodosOsCollapses, ultimaTentativaDeFinalizar } = useContext(ResponderFormularioContext);
    const { getLanguageResource } = useContext(LanguageContext);

	const [isOpen, setIsOpen] = useState(true);
    const [classeDoCard, setClasseDoCard] = useState('card');

    const quantidadeDeCamposDeAcao = pergunta.acoes[0].campoDasAcoes.length;

    const verificaQuantidadeDeCamposRespondidos = () => {
        let quantidadeDeCamposRespondidos = 0;
        pergunta.acoes[0].campoDasAcoes.forEach(campo => {
            if (campo.valor) {
                quantidadeDeCamposRespondidos++;
            }
        });
        return quantidadeDeCamposRespondidos;
    };

    const esteCampoEObrigatorioENaoEstaPreenchido = (): boolean => {
        const respostaSelecionada = pergunta && pergunta.respostas.find((resposta) => resposta.respostaFoiSelecionada);
        const campoDeAcaoEstaPreenchido = verificaQuantidadeDeCamposRespondidos() > 0;
        return respostaSelecionada !== undefined && respostaSelecionada.planoDeAcaoObrigatoria && !campoDeAcaoEstaPreenchido;
    };

    const lidarComOPreenchimentoDosCampos = () => {
        if (quantidadeDeCamposDeAcao === verificaQuantidadeDeCamposRespondidos()) {
            setClasseDoCard('card');
        }
    };

    useDidUpdateEffect(() => {
        const respostaSelecionada = pergunta && pergunta.respostas.find((resposta) => resposta.respostaFoiSelecionada);

        if (respostaSelecionada?.planoDeAcaoObrigatoria && verificaQuantidadeDeCamposRespondidos() === 0 && respostaSelecionada !== undefined) {
            setClasseDoCard('card border-danger');
        } else if (!respostaSelecionada?.planoDeAcaoObrigatoria || verificaQuantidadeDeCamposRespondidos() > 0) {
            setClasseDoCard('card');
        }
    }, [ultimaTentativaDeFinalizar]);

  	useDidUpdateEffect(() => setIsOpen(mostrarTodosOsCollapses), [mostrarTodosOsCollapses]);
    
    if (pergunta.acoes.length === 0) return <React.Fragment />;
    return (
        <div className='col-12 mb-4 border border-top-0 px-0' style={ { maxHeight: '350px' } }>
            <div className={`${classeDoCard} ${isOpen && 'h-100'}`}>
                {/* <div
                    className={`d-flex justify-content-between p-2 ${isOpen ? 'rounded-top' : 'rounded'}`}
                    onClick={() => setIsOpen(!isOpen)}
                    style={{ cursor: 'pointer', backgroundColor: 'var(--ws-blue-light)' }}
                >
                    <div>
                        <span>{getLanguageResource('actions')}</span>
                        {
                            esteCampoEObrigatorioENaoEstaPreenchido()
                                ? <ExclamationCircleIcon className='ml-2' fill='#ef0000' />
                                : <React.Fragment />
                        }
                    </div>
                    <div>
                        {
                            isOpen
                                ? <ChevronDownIcon className='ml-2' fill='#000' />
                                : <ChevronUpIcon className='ml-2' fill='#000' />
                        }
                    </div>
                </div> */}
                <div className={`card-body barra_de_rolagem_estilizada ${isOpen ? '' : 'd-none'}`} style={{ overflowY: 'auto' }}>
                {
                    pergunta.acoes[0].campoDasAcoes
                        .sort((a, b) => a.ordenacao - b.ordenacao)
                        .map(acao => {
                            if (acao.ativo) {
                                return <InputDeAcao acao={ acao } idDaPergunta={ pergunta.id } checarSeTodosOsCamposForamPreenchidos={lidarComOPreenchimentoDosCampos} permitirEditar={permitirEditar} />;
                            }
                            return <React.Fragment />;
                        })
                }
                
                </div>
            </div>
        </div>
    );
};

export default CampoDePreenchimentoDeAcao;
